import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './FlexboxPane.module.scss'
import Flexbox from '../Flexbox'

const FlexboxPaneChild = ({
    children,
    className,
    flexboxChild,
    noTopPadding,
    noRightPadding,
    noBottomPadding,
    noLeftPadding,
    ...props
}) => {
    return (
        <Flexbox.Child
            className={cx(
                styles.flexboxPaneChild,
                noTopPadding && 'pt-0',
                noRightPadding && 'pr-0',
                noBottomPadding && 'pb-0',
                noLeftPadding && 'pl-0',
                className,
            )}
            {...flexboxChild}
            {...props}
        >
            {children}
        </Flexbox.Child>
    )
}

FlexboxPaneChild.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    flexboxChild: PropTypes.object,
    noTopPadding: PropTypes.bool,
    noRightPadding: PropTypes.bool,
    noBottomPadding: PropTypes.bool,
    noLeftPadding: PropTypes.bool
}

FlexboxPaneChild.defaultProps = {
    flexboxChild: {
        flexFill: true
    },
    noTopPadding: false,
    noRightPadding: false,
    noBottomPadding: false,
    noLeftPadding: false,
}

export default FlexboxPaneChild
