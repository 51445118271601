import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import { format } from 'piconetworks/pkg-currency'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanMembership = ({
    Link,
    changeMenuRoute,
    currentBillingIntervalTotalPaid,
    currentProductOptionId,
    currentProductOptionCurrency,
    currentPurchaseCanceled,
    currentSubscriptionId,
    currentSubscriptionStart,
    keepPlan,
    next,
    nextProductOptionPrice,
    nextStatusNext,
    nextSubscriptionEnd,
    nextSubscriptionStart,
    onClickChangePlan,
    originalEnrollmentDate,
    userLoader,
    guestLoader,
    publisherId,
    router,
    closeMenu,
    utmRootUrl,
    queryOverride,
    priceText
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const {
        query: {
            company_slug,
        } = {},
    } = router

    const isWhitelistedReader = currentSubscriptionId === 'WHITELIST'
    const nextPaymentDate = nextStatusNext ? nextSubscriptionStart : nextSubscriptionEnd

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Membership</h3>
            </div>
            <div className={style.container}>
                <ul className={style.menuContent} style={{ color: linkColor,
                    borderBottom: '1px solid #ddd' }}>
                    {isWhitelistedReader && (<li className={style.list}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                You have access to {publisherName} until
                                {' '}
                                <strong>{dayjs(nextSubscriptionEnd).format('LL')}</strong>
                            </div>
                        </div>
                    </li>)}
                    {!isWhitelistedReader && <li className={style.list}>
                        <p className={style.listTitle}>Membership Since</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {dayjs(originalEnrollmentDate).format('LL')}
                            </div>
                        </div>
                    </li>}
                    {!isWhitelistedReader && <li className={style.list}>
                        <p className={style.listTitle}>Last Payment</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {`${priceText(format({
                                    amount: currentBillingIntervalTotalPaid,
                                    currencyCode: currentProductOptionCurrency,
                                    symbol: false,
                                }), currentProductOptionCurrency)} on ${dayjs(currentSubscriptionStart).format('LL')}`}
                            </div>
                        </div>
                    </li>}
                    {(
                        !isWhitelistedReader &&
                        (!currentPurchaseCanceled
                        || (
                            currentPurchaseCanceled
                            && nextStatusNext
                        ))
                    ) && (
                        <li className={style.list}>
                            <p className={style.listTitle}>Next Payment</p>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    {`${priceText(format({
                                        amount: nextProductOptionPrice !== next?.subscription_price ? next?.subscription_price : nextProductOptionPrice,
                                        currencyCode: next?.product_option_currency,
                                        symbol: false,
                                    }), next?.product_option_currency)} on ${dayjs(nextPaymentDate).format('LL')}`}
                                </div>
                            </div>
                        </li>
                    )}
                    {(
                        !isWhitelistedReader &&
                        (currentPurchaseCanceled
                        && (
                            !next
                            || (
                                !nextStatusNext
                            )
                        ))
                    ) && (
                        <li className={style.list}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    Your subscription is set to end on
                                    {' '}
                                    <strong>{dayjs(nextSubscriptionEnd).format('LL')}</strong>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
                <ul className={classNames(style.menuContainer, style.planOption, style.btnLinkGroup)}>
                    {(
                        !isWhitelistedReader &&
                        (!currentPurchaseCanceled
                        || (
                            currentPurchaseCanceled
                            && nextStatusNext
                        ))
                    ) && ( 
                        <li className={style.mainMenuItem}>
                            <Boutton
                                icon={<Picon icon='icon_switch' size='24' color={linkColor} />}
                                variant="link"
                                onClick={onClickChangePlan ? () => onClickChangePlan(currentProductOptionId) : () => router.push('/checkout')}
                            >
                                Change plan
                            </Boutton>
                        </li>                                    
                    )}
                    {(
                        !isWhitelistedReader &&
                        (!currentPurchaseCanceled
                        || (
                            currentPurchaseCanceled
                            && nextStatusNext
                        ))
                    ) && (
                        <Link
                            href="/cards"
                            as={'/cards'}
                        >
                            <a
                                onClick={() => {
                                    closeMenu()
                                }}
                            >
                                <li className={style.mainMenuItem}>
                                    <Boutton
                                        icon={<Picon icon='icon_card' size='24' color={linkColor} />}
                                        variant="link"
                                    >
                                        Change payment method
                                    </Boutton>
                                </li>
                            </a>
                        </Link>
                    )}
                    {(
                        !isWhitelistedReader &&
                        (!currentPurchaseCanceled
                        || (
                            currentPurchaseCanceled
                            && nextStatusNext
                        ))
                    ) && (
                        <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/subscription/stopSubscription') }}>
                            <Boutton
                                icon={<Picon icon='icon_negative' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Cancel membership
                            </Boutton>
                        </li>
                    )}
                    {(
                        !isWhitelistedReader &&
                        (currentPurchaseCanceled
                        && (
                            !next
                            || (
                                !nextStatusNext
                            )
                        ))
                    ) && (
                        <li
                            className={style.mainMenuItem}
                            onClick={() => {
                                keepPlan({
                                    productOptionId: currentProductOptionId,
                                    publisherId,
                                    subscriptionId: currentSubscriptionId,
                                })
                            }}
                        >
                            <Boutton
                                icon={<Picon icon='icon_undo' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Keep subscription
                            </Boutton>
                        </li>
                    )}
                </ul>
            </div>
        </MenuWrapper>
    )
}

ManagePlanMembership.defaultProps = {

    publisherId: null,
}

ManagePlanMembership.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    next: PropTypes.shape({
        product_option_currency: PropTypes.string.isRequired,
        subscription_start: PropTypes.string,
    }).isRequired,
    currentProductOptionCurrency: PropTypes.string.isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
    priceText: PropTypes.func.isRequired,
    guestLoader: PropTypes.object,
    userLoader: PropTypes.object
}

export default ManagePlanMembership
