const types = [
    'GET_LOADER',
    'GET_LOADER_SUCCESS',
    'GET_LOADER_ERROR',
    'REMOVE_LOADER',
    'REMOVE_LOADER_SUCCESS',
    'REMOVE_LOADER_ERROR',
    'UPDATE_LOADER',
    'UPDATE_LOADER_PRICING_MONETIZATION_TIERS',
    'UPDATE_LOADER_GROUP_BILLING_STATUS',
    'UPDATE_LOADER_USER',
    'UPDATE_LOADER_USER_NEWSLETTERS',
    'LOADER_IS_FETCHING',
    'LOADER_IS_NOT_FETCHING',
    'FETCH_LOADER_POPUP_SUCCESS',
]

const creators = (duck) => ({
    getLoader: (payload) => ({
        type: duck.types.GET_LOADER,
        ...payload,
    }),
    getLoaderSuccess: (payload) => ({
        type: duck.types.GET_LOADER_SUCCESS,
        payload,
    }),
    removeLoader: (payload) => ({
        type: duck.types.REMOVE_LOADER,
        ...payload,
    }),
    updateLoader: (payload) => ({
        type: duck.types.UPDATE_LOADER,
        ...payload,
    }),
    updateLoaderPricingMonetizationTiers: (payload) => ({
        type: duck.types.UPDATE_LOADER_PRICING_MONETIZATION_TIERS,
        ...payload,
    }),
    updateLoaderGroupBillingStatus: (payload) => ({
        type: duck.types.UPDATE_LOADER_GROUP_BILLING_STATUS,
        ...payload,
    }),
    updateLoaderUser: (payload) => ({
        type: duck.types.UPDATE_LOADER_USER,
        ...payload,
    }),
    updateLoaderUserNewsletters: (payload) => ({
        type: duck.types.UPDATE_LOADER_USER_NEWSLETTERS,
        ...payload,
    }),
    loaderIsFetching: (payload) => ({
        type: duck.types.LOADER_IS_FETCHING,
        ...payload,
    }),
    loaderIsNotFetching: (payload) => ({
        type: duck.types.LOADER_IS_NOT_FETCHING,
        ...payload,
    }),
    fetchLoaderPopupSuccess: (payload) => ({
        type: duck.types.FETCH_LOADER_POPUP_SUCCESS,
        ...payload,
    }),
})

export {
    types,
    creators,
}
