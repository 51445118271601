import { call, put } from 'redux-saga/effects'
import {
    ME
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* deleteCurrentUser({
    types,
    payload
}) {
    try {
        const { status } = yield call(picoAPIHelper, {
            url: ME(),
            method: 'delete'
        })

        if (status === 200) {
            yield put({
                type: types.DELETE_CURRENT_USER_SUCCESS,
                payload,
            })
        } else {
            throw new Error('unable to delete current user')
        }
    } catch (error) {
        yield put({
            type: types.DELETE_CURRENT_USER_ERROR,
            error: error?.message || 'DELETE_CURRENT_USER_ERROR',
        })
    }
}

export default deleteCurrentUser
