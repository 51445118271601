import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './FormSearch.module.scss'
import Icon from 'piconetworks/pkg-icon'
import Boutton from 'piconetworks/pkg-boutton'

const FormSearch = ({
    children,
    className,
    isDirty = false,
    isSearching = false,
    onClear,
    ...props
}) => {
    const classes = cx(styles.container, {
        [styles.searching]: isSearching,
        [styles.isDirty]: isDirty
    },
    className)
    return (
        <div className={classes} {...props}>
            {React.cloneElement(children, {
                isInlineLeft: true,
                isInlineRight: true
            })}
            {isDirty && !isSearching && <Boutton className={styles.pseudoInline} onClick={onClear} size='sm' icon={<Icon.Default.Clear width={15} height={15} />} variant='secondary'>Clear search</Boutton>}
        </div>
    )
}

FormSearch.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.element,
    isDirty: PropTypes.bool,
    isSearching: PropTypes.bool,
    onClear: PropTypes.func
}

export default FormSearch
