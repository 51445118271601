import Avatar from './Avatar'
import CoverPhoto from './CoverPhoto'
import Img from './Img'

const Image = {
    Avatar,
    CoverPhoto,
    Img
}

export default Image
