import { produce } from 'immer'

export const initialState = {
    fetched: false,
    newsletters: [],
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_NEWSLETTERS_SUCCESS:
            draftState.newsletters = payload
            draftState.fetched = true
            break

        case duck.types.GET_NEWSLETTERS_ERROR:
            draftState.error = error
            draftState.fetched = true
            break

        default:
        // do nothing
    }
})

export default reducer
