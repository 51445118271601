import React from 'react'

export default ({ color = '#1DA1F2', width = '100', height = '100' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd">
            <polygon points="0 0 100 0 100 100 0 100" />
            <path
                d="M38.405,75.3975 C61.99,75.3975 74.89,55.8575 74.89,38.9125 C74.89,38.3575 74.89,37.805 74.8525,37.255 C77.3620784,35.4397771 79.5283887,33.1921985 81.25,30.6175 C78.9096976,31.6545044 76.4270732,32.3345716 73.885,32.635 C76.56186,31.0324551 78.5653088,28.51193 79.5225,25.5425 C77.0053937,27.0361383 74.2516153,28.0887801 71.38,28.655 C67.4052069,24.4284857 61.0892984,23.3940315 55.9738732,26.1316998 C50.858448,28.8693682 48.2156875,34.6983143 49.5275,40.35 C39.2172393,39.8331238 29.6111895,34.9633082 23.1,26.9525 C19.6965569,32.8116118 21.4349697,40.3071487 27.07,44.07 C25.029353,44.0095189 23.0331984,43.4590329 21.25,42.465 L21.25,42.6275 C21.2516703,48.7314824 25.5543787,53.9888379 31.5375,55.1975 C29.649674,55.7123501 27.6689515,55.7876107 25.7475,55.4175 C27.4273698,60.6410571 32.2414364,64.2194597 37.7275,64.3225 C33.1868376,67.8910799 27.5776488,69.8283193 21.8025,69.8225 C20.7822589,69.8205414 19.7630102,69.7587687 18.75,69.6375 C24.6140899,73.4006914 31.4372835,75.3967923 38.405,75.3875"
                fill={color}
                fillRule="nonzero"
            />
        </g>
    </svg>
)
