import { colors } from 'piconetworks/pkg-functions'

// need to change css variables blockButtonBackgroundColorRgb and blockButtonColor to use this function

import { ThemePageContrastColor, FormPageContrastColor } from './PageContrastColor'
import PropertyType from '../Modifiers/PropertyType'

const makePageButtonContrastColor = (property) => {
    const value = property.getCssVariableValue()
    const hasBeenModified = property.getHasBeenModified()
    if (value !== undefined && hasBeenModified) {
        return colors.getContrastColor(value)
    } else {
        return
    }
}

const makeCssPageButtonContrastColor = (property) => {
    const color = makePageButtonContrastColor(property)
    if (color !== undefined) {
        return `background-color: ${color};`
    } else {
        return
    }
}

const ThemePageButtonContrastColor = new PropertyType(
    {
        name: 'PageButtonContrastColor',
        propertyFromApi: 'page-button-contrast-color',
        convertValueToCssPropertiesFunction: (value) => makeCssPageButtonContrastColor(ThemePageContrastColor),
        isCompounded: true,
        selector: '.page > .btn',
        priority: 7,
        cssVariable: '--page-button-contrast-color',
        cssVariableFunction: (value: string) => makePageButtonContrastColor(ThemePageContrastColor),
        convertToRgb: true,
    },
    'theme'
)

const FormPageButtonContrastColor = new PropertyType(
    {
        name: 'PageButtonContrastColor',
        propertyFromApi: 'page-button-contrast-color',
        convertValueToCssPropertiesFunction: (value) => makeCssPageButtonContrastColor(FormPageContrastColor),
        isCompounded: true,
        selector: '.page > .btn',
        priority: 8,
        cssVariable: '--page-button-contrast-color',
        cssVariableFunction: (value: string) => makePageButtonContrastColor(FormPageContrastColor),
        convertToRgb: true,
    },
    'form'
)

export {
    ThemePageButtonContrastColor,
    FormPageButtonContrastColor,
}