import { call, put } from 'redux-saga/effects'
import {
    SOCIAL_LINKS
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* deleteLink({
    payload,
    types,
    publisherId,
}) {
    const { social_links } = payload
    try {
        const { status } = yield call(picoAPIHelper, {
            url: `${SOCIAL_LINKS({ scope: 'creator' })}`,
            method: 'delete',
            headers: {
                publisherid: publisherId,
            },
            data: social_links
        })

        if (status === 200) {
            yield put({
                type: types.DELETE_LINK_SUCCESS,
                payload: linkId,
            })
        } else {
            throw new Error('unable to delete link')
        }
    } catch (error) {
        yield put({
            type: types.DELETE_LINK_ERROR,
            error: error?.message,
        })
    }
}

export default deleteLink
