import { call, put } from 'redux-saga/effects'
import {
    ME_PAYMENT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getPayments({
    types,
    publisherId,
} = {}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            headers: {
                publisherid: publisherId,
            },
            url: ME_PAYMENT(),
            method: 'get',
        })

        if (data) {
            yield put({
                type: types.GET_PAYMENTS_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to get payments')
        }
    } catch (error) {
        yield put({
            type: types.GET_PAYMENTS_ERROR,
            error: error?.message || 'GET_PAYMENTS_ERROR',
        })
    }
}

export default getPayments
