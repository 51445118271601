import { combineReducers } from 'redux'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './reducers'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        '_network',
        '_menu',
        'account',
        'auth',
        'landingPage',
        'loader',
        'payment',
        'router',
        'client',
        'me',
        'blocks',
        'links',
        'newsletter',
        'iframely',
        'text',
        'utils'
    ],
    // whitelist: ['client'],
    stateReconciler: autoMergeLevel2,
}

const networkMigrations = {
    0: (state) => {
        // migration clear out device state
        console.log('state', state)

        if (state.scope === 'setup') {
            return {}
        }
        return state
    }
}

const authPersistConfig = {
    key: '_network',
    storage,
    blacklist: ['access_token'],
    version: 1,
    migrate: createMigrate(networkMigrations, { debug: true }),
}

export const combinedRootReducer = combineReducers({
    ...rootReducer,
    _network: persistReducer(authPersistConfig, rootReducer._network),
})

export default persistReducer(persistConfig, combinedRootReducer)
