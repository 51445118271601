import React from 'react'
import PropTypes from 'prop-types'
const Email = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_email">
                <polygon
                    id="bounds"
                    fill="#EFF8FF"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M22,9 C24.209139,9 26,10.790861 26,13 L26,20 C26,22.209139 24.209139,24 22,24 L10,24 C7.790861,24 6,22.209139 6,20 L6,13 C6,10.790861 7.790861,9 10,9 L22,9 Z M23.9736063,12.2001753 C23.9266735,12.1319094 23.8332865,12.1146155 23.7650207,12.1615483 L23.7650207,12.1615483 L17.1330576,16.7210229 C16.4505536,17.1902444 15.5494464,17.1902444 14.8669424,16.7210229 L14.8669424,16.7210229 L8.23497932,12.1615483 C8.20997457,12.1443575 8.18034403,12.1351546 8.15,12.1351546 C8.06715729,12.1351546 8,12.2023119 8,12.2851546 L8,12.2851546 L8,20 C8,21.1045695 8.8954305,22 10,22 L10,22 L22,22 C23.1045695,22 24,21.1045695 24,20 L24,20 L24,12.2851546 C24,12.2548105 23.9907971,12.22518 23.9736063,12.2001753 Z"
                    id="Combined-Shape"
                    fill="#2899F7"
                />
            </g>
        </g>
    </svg>
)

Email.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Email
