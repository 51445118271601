/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import cx from 'classnames'

import { useDispatch, useSelector } from 'react-redux'

import selectors from './text/selectors'
import { creators } from './text/actions'

import styles from './scss/RemoteMarkdown.module.scss'

const RemoteMarkdown = ({
    url,
    options,
    onClickLink,
    className,
}) => {
    const dispatch = useDispatch()

    const markdownFile = useSelector((state) => selectors.getMarkdownFile(state, url), isEqual)
    const isFetching = useSelector((state) => selectors.isFetchingMarkdown(state, url), isEqual)

    useEffect(() => {
        if (url && (!markdownFile && !markdownFile?.error) && !isFetching) {
            dispatch(creators.fetchMarkdown({
                payload: {
                    url,
                    options,
                },
            }))
        }
    }, [
        url,
        isFetching
    ])

    if (!markdownFile || markdownFile.error || !markdownFile.html) {
        return null
    }

    return (
        <div
            className={cx(styles.container, className)}
            dangerouslySetInnerHTML={{
                __html: markdownFile.html,
            }}
            onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()

                const href = event?.target?.href

                onClickLink(href)
            }}
        />
    )
}

RemoteMarkdown.defaultProps = {
    onClickLink: () => { },
    options: {},
}

RemoteMarkdown.propTypes = {
    url: PropTypes.string.isRequired,
    onClickLink: PropTypes.func,
    options: PropTypes.object,
    className: PropTypes.string,
}

export default RemoteMarkdown
