import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select, { components } from 'react-select'

import { customStyles } from './dropdownStyle'
import './FormDropdown.module.scss'
import FormField, { FormFieldInputPropTypes, FormFieldPropTypes } from '../FormField'
import { onlyWarnInDevelopmentMode, warnOfDeprecation, warnOnFieldProps } from '../utils/propWarnings'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className={classNames('icon-dropdown-indicator')}>
                <span className="icon-chevron-down" />
            </span>
        </components.DropdownIndicator>
    )
}
export class Dropdown extends React.Component {
    rSelecRef = null

    render() {
        const {
            autoFocus,
            changed,
            className,
            classNamePrefix,
            components,
            contrastColor,
            disabled,
            formatGroupLabel,
            formatOptionLabel,
            formFieldClassName,
            fieldProps,
            group,
            input,
            insideOfModal,
            isDisabled,
            isMulti,
            isSearchable,
            label,
            menuPlacement,
            meta,
            noOptionsMessage,
            options,
            placeholder,
            required,
            styles,
            themeColor,
            ...props
        } = this.props
        let valueObject

        if (insideOfModal) {
            props.menuPortalTarget = document.querySelector('.ReactModal__Overlay--after-open')
        }

        if (group) {
            valueObject = options?.find((option) => {
                return option?.options?.find((element) => element.value === input.value)
            })
        }
        const formApi = {
            ...(!group && input),
            ...(group
                ? {
                    value: valueObject?.options?.find((element) => element.value === input.value)
                }
                : {
                    value: options?.find((option) => option.value === input.value)
                })
        }

        onlyWarnInDevelopmentMode(() => warnOnFieldProps(fieldProps))
        onlyWarnInDevelopmentMode(() => warnOfDeprecation({ ...formFieldClassName && {'formFieldClassName': formFieldClassName} }))

        return (
            <FormField label={label} input={input} required={required} meta={meta} className={formFieldClassName} {...fieldProps}>
                <Select
                    {...formApi}
                    autoFocus={autoFocus}
                    className={className}
                    classNamePrefix={classNamePrefix || 'react-select'}
                    components={{
                        DropdownIndicator,
                        ...components
                    }}
                    contrastColor={contrastColor}
                    error={meta.error}
                    formatGroupLabel={formatGroupLabel}
                    formatOptionLabel={formatOptionLabel}
                    isDisabled={disabled || isDisabled}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    menuPlacement={menuPlacement}
                    noOptionsMessage={noOptionsMessage}
                    onChange={(e) => {
                        if (changed) {
                            changed(e)
                        }
                        if (input.onChange) {
                            input.onChange(e.value)
                        }
                        if (input.onBlur) {
                            input.onBlur(e.value)
                        }
                        this.rSelecRef.blur()
                    }}
                    options={options}
                    placeholder={placeholder || 'Select one'}
                    ref={(item) => (this.rSelecRef = item)}
                    styles={styles || customStyles}
                    themeColor={themeColor}
                    touched={meta.touched}
                    {...props}
                />
            </FormField>
        )
    }
}

Dropdown.defaultProps = {
    changed: null,
    contrastColor: '#FFF',
    isSearchable: false,
    menuPlacement: 'auto',
    options: [],
    themeColor: '#7A34FF'
}

Dropdown.propTypes = {
    autoFocus: PropTypes.bool,
    /** Passes the event just prior to firing onChange and onBlur */
    changed: PropTypes.func,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** If provided, all inner components will be given a prefixed className attribute */
    classNamePrefix: PropTypes.string,
    /** If you wish to overwrite a component, pass in an object with the appropriate namespace: https://react-select.com/components */
    components: PropTypes.object,
    fieldProps: PropTypes.shape(FormFieldPropTypes),
    formatGroupLabel: PropTypes.func,
    formatOptionLabel: PropTypes.func,
    /** This will be deprecated eventually. Instead pass className to the fieldProps */
    formFieldClassName: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    group: PropTypes.bool,
    /** Either disabled can be set here or preferable, `isDisabled` */
    disabled: PropTypes.bool,
    options: PropTypes.array,
    input: PropTypes.shape(FormFieldInputPropTypes),
    /** If this is true, we want to target the overlay (because it's not scrollable) */
    insideOfModal: PropTypes.bool,
    /** Either disabled can be set here or via `disabled` */
    isDisabled: PropTypes.bool,
    /** Support multiple selected options */
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    /** Text to display when there are no options */
    noOptionsMessage: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
    placeholder: PropTypes.string,
    menuPlacement: PropTypes.oneOf([ 'auto', 'top', 'bottom' ]),
    required: PropTypes.bool,
    styles: PropTypes.object,
    contrastColor: PropTypes.string,
    themeColor: PropTypes.string
}

export default Dropdown
