import Joi from 'joi'

const LandingPageSignupToCreator = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        segment: Joi.boolean().default(true).required().description('An indicator that event was sent to segment')
    })
    .description('landing_page:signup_to_creator event attributes ')
    .meta({ name: 'LandingPageSignupToCreator', filename: 'landing_page:signup_to_creator.model' })

export default LandingPageSignupToCreator
