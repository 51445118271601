import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.CREATE_GIFT, sagas.createGift),
    takeEvery(types.REDEEM_GIFT, sagas.redeemGift),
    takeEvery(types.GET_GIFT, sagas.getGift),
    takeEvery(types.CONVERT_GIFT, sagas.convertGift),
    takeEvery(types.GET_GIFT_PRODUCT, sagas.getGiftProduct),
])

export default takes
