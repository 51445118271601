import React from 'react'

export default ({ color = '#28BF6F', width = '20', height = '20' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm5.6845058 5.83742051c-.4263673-.44719343-1.1076892-.44894255-1.5283684-.01082225l-5.57959797 5.93530224-2.720151-2.88602764c-.42484987-.44123137-1.11454898-.44356653-1.5379643-.0025967-.42636728.44404418-.42303888 1.15631524.00084078 1.59776864l3.4897725 3.6966451c.42737369.4450923 1.11133946.4412294 1.53548443-.0005003l6.33866276-6.72583741c.419652-.43705052.4247365-1.15983441.0013212-1.60393168z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
