import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    products: new Duck.Selector(() => (state) => state?.products),
    getProductById: new Duck.Selector((selectors) => createSelector(
        selectors.products,
        (_, productId) => productId,
        ({ data } = {}, productId) =>
            data[productId] || null
    )),
    getProductByProductOptionId: new Duck.Selector((selectors) => createSelector(
        selectors.products,
        (_, productOptionId) => productOptionId,
        ({ data } = {}, productOptionId) => {
            let result
            Object.values(data).some(product => {
                if (product.options.find(option => option.id === productOptionId)) {
                    result = product
                    return true
                }
                return false
            })
            return result
        }
    )),
    getProductOptionById: new Duck.Selector((selectors) => createSelector(
        selectors.products,
        (_, productOptionId) => productOptionId,
        ({ data } = {}, productOptionId) => {
            let result
            Object.values(data).some(product => {
                const productOption = product.options.find(option => option.id === productOptionId)
                if (productOption) {
                    result = productOption
                    return true
                }
                return false
            })
            return result
        }
    )),
})

export default selectors
