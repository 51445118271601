import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    downloads: new Duck.Selector(() => (state) => state?.downloads),
    getDownloadableFiles: new Duck.Selector((selectors) => createSelector(
        selectors.downloads,
        ({ files }) =>
            files || []
    )),
})

export default selectors
