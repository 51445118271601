import { produce } from 'immer'

import { types } from './actions'

export const initialState = {
    isFetchingMarkdown: null,
    markdownFiles: {},
    isFetchingJson: null,
    jsonFiles: {},
}

const reducer = (state = initialState, { type, payload, error }) => produce(state, (draftState) => {
    switch (type) {
        case types.FETCH_MARKDOWN:
            draftState.isFetchingMarkdown = true
            break

        case types.FETCH_MARKDOWN_SUCCESS:
            draftState.isFetchingMarkdown = false
            draftState.markdownFiles[payload.url] = payload
            break

        case types.FETCH_MARKDOWN_ERROR:
            draftState.isFetchingMarkdown = false
            draftState.markdownFiles[payload.url] = {
                error,
            }
            break

        case types.FETCH_JSON:
            draftState.isFetchingJson = true
            break

        case types.FETCH_JSON_SUCCESS:
            draftState.isFetchingJson = false
            draftState.jsonFiles[payload.url] = payload.json
            break

        case types.FETCH_JSON_ERROR:
            draftState.isFetchingJson = false
            draftState.jsonFiles[payload.url] = {
                error,
            }
            break

        default:
        // do nothing
    }

    return undefined
})

export default reducer
