const types = [
    'CREATE_ACCOUNT',
    'CREATE_ACCOUNT_SUCCESS',
    'CREATE_ACCOUNT_ERROR',
    'GET_HAS_PASSWORD',
    'GET_HAS_PASSWORD_SUCCESS',
    'GET_HAS_PASSWORD_ERROR',
    'HAS_PASSWORD_RESET_AUTH',
    'REQUEST_LOGIN_LINK',
    'REQUEST_LOGIN_LINK_SUCCESS',
    'REQUEST_LOGIN_LINK_ERROR',
    'REQUEST_ONE_TIME_CODE',
    'REQUEST_ONE_TIME_CODE_SUCCESS',
    'REQUEST_ONE_TIME_CODE_ERROR',
    'RESET_ONE_TIME_CODE',
    'CONFIRM_ONE_TIME_CODE',
    'CONFIRM_ONE_TIME_CODE_SUCCESS',
    'CONFIRM_ONE_TIME_CODE_ERROR',
    'SET_LOGIN_EMAIL',
    'SET_LOGIN_EMAIL_SUCCESS',
    'SET_LOGIN_EMAIL_ERROR',
    'SET_LOGIN_PHONE',
    'SET_LOGIN_PHONE_SUCCESS',
    'SET_LOGIN_PHONE_ERROR',
    'REQUEST_PASSWORD_RESET',
    'REQUEST_PASSWORD_RESET_SUCCESS',
    'REQUEST_PASSWORD_RESET_ERROR',
    'NEWSLETTER_SUBSCRIBE',
    'NEWSLETTER_SUBSCRIBE_SUCCESS',
    'NEWSLETTER_SUBSCRIBE_ERROR',
    'USER_JOIN_GROUP',
    'USER_JOIN_GROUP_SUCCESS',
    'USER_JOIN_GROUP_ERROR',
    'UPDATE',
    'UPDATE_SUCCESS',
    'UPDATE_ERROR',
    'CONFIRMATION_EMAIL',
    'DELETE_ACCOUNT',
    'DELETE_ACCOUNT_SUCCESS',
    'DELETE_ACCOUNT_ERROR',
    'NEW_USER_REGISTRATION',
    'CROSS_VERIFICATION',
    'CROSS_VERIFICATION_SUCCESS',
    'CROSS_VERIFICATION_ERROR',
]

const creators = (duck) => ({
    createAccount: (payload) => ({
        type: duck.types.CREATE_ACCOUNT,
        ...payload,
    }),
    getHasPassword: (payload) => ({
        type: duck.types.GET_HAS_PASSWORD,
        ...payload,
    }),
    hasPasswordResetAuth: (payload) => ({
        type: duck.types.HAS_PASSWORD_RESET_AUTH,
        ...payload,
    }),
    requestLoginLink: (payload) => ({
        type: duck.types.REQUEST_LOGIN_LINK,
        ...payload,
    }),
    requestOneTimeCode: (payload) => ({
        type: duck.types.REQUEST_ONE_TIME_CODE,
        ...payload,
    }),
    resetOneTimeCode: (payload) => ({
        type: duck.types.RESET_ONE_TIME_CODE,
        ...payload,
    }),
    confirmOneTimeCode: (payload) => ({
        type: duck.types.CONFIRM_ONE_TIME_CODE,
        ...payload,
    }),
    setLoginEmail: (payload) => ({
        type: duck.types.SET_LOGIN_EMAIL,
        ...payload,
    }),
    setLoginPhone: (payload) => ({
        type: duck.types.SET_LOGIN_PHONE,
        ...payload,
    }),
    requestPasswordReset: (payload) => ({
        type: duck.types.REQUEST_PASSWORD_RESET,
        ...payload,
    }),
    newsletterSubscribe: (payload) => ({
        type: duck.types.NEWSLETTER_SUBSCRIBE,
        ...payload,
    }),
    userJoinGroup: (payload) => ({
        type: duck.types.USER_JOIN_GROUP,
        ...payload,
    }),
    update: (payload) => ({
        type: duck.types.UPDATE,
        ...payload,
    }),
    signupConfirmationEmail: (payload) => ({
        type: duck.types.CONFIRMATION_EMAIL,
        ...payload
    }),
    deleteAccount: (payload) => ({
        type: duck.types.DELETE_ACCOUNT,
        ...payload,
    }),
    crossVerification: (payload) => ({
        type: duck.types.CROSS_VERIFICATION,
        ...payload,
    }),
})

export {
    types,
    creators,
}
