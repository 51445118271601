import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'
import MenuWrapper from './components/MenuWrapper'

import style from './scss/MenuRoutes.module.scss'

const EditProfileDeleteAccount = ({
    changeMenuRoute,
    deleteAccount,
    publisherId,
    userId,
    accountDeleted,
    router,
    userLoader,
    guestLoader,
    utmRootUrl,
    queryOverride,
    canDeleteAccount,
}) => {
    const { company_slug: companySlug } = router?.query || {}

    useEffect(() => {
        if (!accountDeleted) {
            return
        }

        if (companySlug) {
            router.replace('/[company_slug]/logout', `/${companySlug}/logout`)
        } else {
            router.replace(
                {
                    pathname: '/logout',
                },
                {
                    pathname: '/logout',
                },
            )
        }
    }, [companySlug, accountDeleted])

    if (accountDeleted) {
        return null
    }

    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const messageText = canDeleteAccount ? `Are you sure you want to delete your account? ${publisherName} will no longer have access to any of your account information.`
        : 'Your account cannot be deleted, since you have a subscription that is still active.'

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/editProfile') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Delete account</h3>
            </div>
            <div className={style.container}>
                <div className={style.menuDescription}>
                    <p>{messageText}</p>
                </div>
                {
                    canDeleteAccount ? (
                        <div data-menu_route="/logOut">
                            <div className={style.approveButtonGroup}>
                                <Boutton
                                    className={style.cancel}
                                    onClick={() => { changeMenuRoute('/') }} 
                                    variant="link"
                                >
                                    Cancel
                                </Boutton>
                                <Boutton
                                    onClick={() => {
                                        deleteAccount({
                                            userId,
                                            publisherId,
                                        })
                                    }}
                                    variant="danger"
                                >
                                    Delete account
                                </Boutton>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </MenuWrapper>
    )
}

EditProfileDeleteAccount.defaultProps = {
    canDeleteAccount: true
}

EditProfileDeleteAccount.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    canDeleteAccount: PropTypes.bool.isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride: PropTypes.object,
}

export default EditProfileDeleteAccount
