import { _network } from 'piconetworks/pkg-network-middleware'
import { _menu } from 'piconetworks/pkg-menu-middleware'

import account from './account'
import auth from './auth'
import claim from './claim'
import gift from './gift'
import loader from './loader'
import router from './router'
import payment from './payment'
import wizard from './wizard'
import plan from './plan'
import landingPage from './landing-page'
import client from './client'
import me from './me'
import paymentV2 from './paymentv2'
import article from './article'
import forms from './forms'
import purchase from './purchase'
import text from './text'
import downloads from './downloads'
import utm from './utm'
import links from './links'
import featureFlags from './feature-flags'
import newsletter from './newsletter'
import blocks from './blocks'
import iframely from './iframely'
import products from './products'
import utils from './utils'

export const initialAppState = {
    account: account.initialState,
    auth: auth.initialState,
    claim: claim.initialState,
    gift: gift.initialState,
    loader: loader.initialState,
    router: router.initialState,
    payment: payment.initialState,
    wizard: wizard.initialState,
    plan: plan.initialState,
    landingPage: landingPage.initialState,
    client: client.initialState,
    me: me.initialState,
    paymentv2: paymentV2.initialState,
    article: article.initialState,
    forms: forms.initialState,
    purchase: purchase.initialState,
    text: text.initialState,
    downloads: downloads.initialState,
    links: links.initialState,
    featureFlags: featureFlags.initialState,
    utm: utm.initialState,
    newsletter: newsletter.initialState,
    blocks: blocks.initialState,
    iframely: iframely.initialState,
    products: products.initialState,
    utils: utils.initialState,
}

export default {
    _menu,
    _network,
    [account.store]: account.reducer,
    [auth.store]: auth.reducer,
    [claim.store]: claim.reducer,
    [gift.store]: gift.reducer,
    [loader.store]: loader.reducer,
    [router.store]: router.reducer,
    [payment.store]: payment.reducer,
    [wizard.store]: wizard.reducer,
    [plan.store]: plan.reducer,
    [landingPage.store]: landingPage.reducer,
    [client.store]: client.reducer,
    [me.store]: me.reducer,
    [paymentV2.store]: paymentV2.reducer,
    [article.store]: article.reducer,
    [forms.store]: forms.reducer,
    [purchase.store]: purchase.reducer,
    [text.store]: text.reducer,
    [downloads.store]: downloads.reducer,
    [links.store]: links.reducer,
    [featureFlags.store]: featureFlags.reducer,
    [utm.store]: utm.reducer,
    [newsletter.store]: newsletter.reducer,
    [blocks.store]: blocks.reducer,
    [iframely.store]: iframely.reducer,
    [products.store]: products.reducer,
    [utils.store]: utils.reducer,
}
