import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './GridContainer.module.scss'

const GridContainer = React.forwardRef(
    ({ fluid, className, ...props }, ref) => {
        const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid'
        return (
            <div
                className={cx(
                    fluid ? style[`container${suffix}`] : style['container'],
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    }
)

export const GridContainerPropTypes = {
    fluid: PropTypes.oneOf([ true, false, 'sm', 'md', 'lg', 'xl', 'xxl' ]),
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])
}

GridContainer.propTypes = GridContainerPropTypes

GridContainer.defaultProps = {
    fluid: false
}

GridContainer.displayName = 'GridContainer'

export default GridContainer
