import { useAnalytics } from 'use-analytics'

import PicoAnalyticsModule from './module'

import { PicoAnalytics, PicoAnalyticsProvider } from './Analytics'

export {
    useAnalytics,
    PicoAnalyticsModule,
    PicoAnalytics,
    PicoAnalyticsProvider
}
