import produce from 'immer'

export const initialState = {
    xVerifyEmail: null,
    xVerifyPhone: null,
    verifyInProgress: false
}

const reducer = (
    state,
    { type, payload },
    duck
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.SET_VERIFICATION_INFO:
            draftState.xVerifyEmail = payload.xVerifyEmail
            draftState.xVerifyPhone = payload.xVerifyPhone
            draftState.verifyInProgress = true
            break

    

        default:
      // do nothing
    }
})

export default reducer
