import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Img from '../Img'
// import defaultAvatarImage from './avatar.png'
import styles from './Avatar.module.scss'

const Avatar = ({ className, src, height, width, ...props }) => {

    return (
        <div
            className={cx(styles.avatar, className)}
            {...props}
        >
            {src && <Img src={src} height={height} width={width} />}
        </div>
    )
}

Avatar.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
}

Avatar.defaultProps = {
    height: 200,
    // src: defaultAvatarImage,
    width: 200
}

export default Avatar
