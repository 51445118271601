import Joi from 'joi'
import signInMethods from '../utils/authMethods'

const LandingPageUserLogin = Joi.object()
    .keys({
        email: Joi.string().email({ tlds: { allow: false } }).allow(null).description('End user email'),
        phone: Joi.string().allow(null).optional().description('End user phone number if they authed with SMS'),
        new_account: Joi.boolean().required().description('Is user signing up when logging in'),
        paying: Joi.boolean().required().description('Is user already a paying customer'),
        sign_in_method: Joi.string().valid(...signInMethods).optional().description('The auth method user used to log in'),
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        theme_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the theme for this landing page'),
        has_password: Joi.boolean().required().description('Is the user logging in with a password?'),
        otp_verified: Joi.boolean().required().description('Is the user logging in via OTP?')
    })
    .description('landing_page:user_login event attributes ')
    .meta({ name: 'LandingPageUserLogin', filename: 'landing_page:user_login.model' })

export default LandingPageUserLogin
