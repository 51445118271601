import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const SignupPageAbandon = Joi.object()
    .keys({
        page_number: Joi.number().min(1).required().description('Step in Onboarding Wizard'),
        experiments: experimentsSchema,
        name: Joi.string().optional().description('Creator Name'),
        username: Joi.string().optional().description('Creator Username'),
        publisher_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).optional().description('Publisher user logged into their account from'),
    })
    .description('Fires when a user navigates away from a step without completing it. This will fires when the step component unmounts.')
    .meta({ name: 'SignupPageAbandon', filename: 'signup:page_abandon.model' })

export default SignupPageAbandon
