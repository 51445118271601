import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_PRODUCT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getProductByOption({
    types,
    publisherId,
    optionId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${PURCHASE_PRODUCT()}/${optionId}`,
            method: 'get',
            headers: {
                publisherid: publisherId,
            },
        })

        if (data) {
            yield put({
                type: types.GET_PRODUCT_BY_OPTION_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch product')
        }
    } catch (error) {
        yield put({
            type: types.GET_PRODUCT_BY_OPTION_ERROR,
            error: error?.message,
        })
    }
}

export default getProductByOption
