const types = [
    'PUSH',
    'REPLACE',
    'UPDATE',
]

const creators = (duck) => ({
    push: (payload) => ({
        type: duck.types.PUSH,
        ...payload,
    }),
    replace: (payload) => ({
        type: duck.types.REPLACE,
        ...payload,
    }),
    update: (payload) => ({
        type: duck.types.UPDATE,
        payload,
    }),

})

export {
    types,
    creators,
}
