import Joi from 'joi'
import signUpMethods from '../utils/authMethods'

const LandingPageAccountSubmit = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        end_user_email: Joi.string().email({ tlds: { allow: false } }).allow(null).description('End user email if they authed with email'),
        end_user_phone: Joi.string().allow(null).description('End user phone number if they authed with SMS'),
        sign_up_method: Joi.string().valid(...signUpMethods).required().description('Auth method used for account'),
    })
    .description('landing_page:account_submit event attributes ')
    .meta({ name: 'LandingPageAccountSubmit', filename: 'landing_page:account_submit.model' })

export default LandingPageAccountSubmit
