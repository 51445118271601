import { call, put } from 'redux-saga/effects'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'
import { FEATURE_FLAGS } from 'piconetworks/pkg-endpoints'

function* getFeatureFlags({
    types,
    publisherId,
    guest = false,
    userId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${FEATURE_FLAGS()}${guest ? '/guest' : ''}`,
            method: 'post',
            headers: {
                publisherid: publisherId,
            },
            data: {
                user_id: userId,
            },
        })

        if (data) {
            yield put({
                type: types.GET_FEATURE_FLAGS_SUCCESS,
                payload: {
                    ...data
                }
            })
        }
    } catch (e) {
        yield put({
            type: types.GET_FEATURE_FLAGS_ERRORS,
            error: e
        })
    }
}

export default getFeatureFlags
