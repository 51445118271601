import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = ({ store }) => ({
    getState: new Duck.Selector(() => (state) => state[store]),
    getError: new Duck.Selector(() => (state) => state[store]?.error),
    getBlocksByFormId: new Duck.Selector((selectors) => createSelector(
        selectors.getState,
        (_, formId) => formId,
        ({ data }, formId) =>
            (data?.[formId] || []).length
                ? ([...data[formId]] || []).sort((a, b) => a.order - b.order)
                : [],
    )),
    getLoading: new Duck.Selector((selectors) => createSelector(
        selectors.getState,
        ({ loading } = {}) => loading
    )),
})

export default selectors
