import { getTemplateOrDefault, compile } from '../utils'

import donationPlan from '../defaultTemplates/donationPlan'
import subscriptionPlan from '../defaultTemplates/subscriptionPlan'
import passPlan from '../defaultTemplates/passPlan'

const DONATION = 'donation'
const SUBSCRIPTION = 'subscription'
const PASS = 'pass'

export const retrievePlanPrompt = ({
    planTemplates,
    userFirstName,
    monetizationType,
    hasPurchased,
}) => {
    const values = {
        title: '',
        subTitle: '',
        description: '',
    }
    if (monetizationType === DONATION) {
        if (hasPurchased) {
            if (userFirstName) {
                values.title = compile(
                    getTemplateOrDefault(
                        planTemplates,
                        donationPlan,
                        'customerPromptWithName.title',
                    ),
                    { first_name: userFirstName },
                )
                values.subTitle = compile(
                    getTemplateOrDefault(
                        planTemplates,
                        donationPlan,
                        'customerPromptWithName.subtitle',
                    ),
                    { first_name: userFirstName },
                )
                values.description = compile(
                    getTemplateOrDefault(
                        planTemplates,
                        donationPlan,
                        'customerPromptWithName.description',
                    ),
                    { first_name: userFirstName },
                )
            } else {
                values.title = getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'customerPromptWithoutName.title',
                )
                values.subTitle = getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'customerPromptWithoutName.subtitle',
                )
                values.description = getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'customerPromptWithoutName.description',
                )
            }
        } else if (userFirstName) {
            values.title = compile(
                getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'nonDonorPromptWithName.title',
                ),
                { first_name: userFirstName },
            )
            values.subTitle = compile(
                getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'nonDonorPromptWithName.subtitle',
                ),
                { first_name: userFirstName },
            )
            values.description = compile(
                getTemplateOrDefault(
                    planTemplates,
                    donationPlan,
                    'nonDonorPromptWithName.description',
                ),
                { first_name: userFirstName },
            )
        } else {
            values.title = getTemplateOrDefault(
                planTemplates,
                donationPlan,
                'nonDonorPromptWithoutName.title',
            )
            values.subTitle = getTemplateOrDefault(
                planTemplates,
                donationPlan,
                'nonDonorPromptWithoutName.subtitle',
            )
            values.description = getTemplateOrDefault(
                planTemplates,
                donationPlan,
                'nonDonorPromptWithoutName.description',
            )
        }
    } else if (monetizationType === SUBSCRIPTION) {
        if (userFirstName) {
            values.title = compile(
                getTemplateOrDefault(
                    planTemplates,
                    subscriptionPlan,
                    'promptWithName.title',
                ),
                { first_name: userFirstName },
            )
            values.subTitle = compile(
                getTemplateOrDefault(
                    planTemplates,
                    subscriptionPlan,
                    'promptWithName.subtitle',
                ),
                { first_name: userFirstName },
            )
            values.description = compile(
                getTemplateOrDefault(
                    planTemplates,
                    subscriptionPlan,
                    'promptWithName.description',
                ),
                { first_name: userFirstName },
            )
        } else {
            values.title = getTemplateOrDefault(
                planTemplates,
                subscriptionPlan,
                'promptWithoutName.title',
            )
            values.subTitle = getTemplateOrDefault(
                planTemplates,
                subscriptionPlan,
                'promptWithoutName.subtitle',
            )
            values.description = getTemplateOrDefault(
                planTemplates,
                subscriptionPlan,
                'promptWithoutName.description',
            )
        }
    } else if (monetizationType === PASS) {
        if (userFirstName) {
            values.title = compile(
                getTemplateOrDefault(
                    planTemplates,
                    passPlan,
                    'promptWithName.title',
                ),
                { first_name: userFirstName },
            )
            values.subTitle = compile(
                getTemplateOrDefault(
                    planTemplates,
                    passPlan,
                    'promptWithName.subtitle',
                ),
                { first_name: userFirstName },
            )
            values.description = compile(
                getTemplateOrDefault(
                    planTemplates,
                    passPlan,
                    'promptWithName.description',
                ),
                { first_name: userFirstName },
            )
        } else {
            values.title = getTemplateOrDefault(
                planTemplates,
                passPlan,
                'promptWithoutName.title',
            )
            values.subTitle = getTemplateOrDefault(
                planTemplates,
                passPlan,
                'promptWithoutName.subtitle',
            )
            values.description = getTemplateOrDefault(
                planTemplates,
                passPlan,
                'promptWithoutName.description',
            )
        }
    }

    return values
}

export const retrieveRecurringConfirmation = ({
    planTemplates,
    userFirstName,
    monetizationType,
}) => {
    let templateString = ''

    if (monetizationType === DONATION) {
        if (userFirstName) {
            templateString = getTemplateOrDefault(
                planTemplates,
                donationPlan,
                'recurringConfirmationWithName',
            )
        } else {
            templateString = getTemplateOrDefault(
                planTemplates,
                donationPlan,
                'recurringConfirmationWithoutName',
            )
        }
    } else if (monetizationType === SUBSCRIPTION) {
        if (userFirstName) {
            templateString = getTemplateOrDefault(
                planTemplates,
                subscriptionPlan,
                'confirmationWithName',
            )
        } else {
            templateString = getTemplateOrDefault(
                planTemplates,
                subscriptionPlan,
                'confirmationWithoutName',
            )
        }
    }

    return templateString
}

export const retrieveSingleConfirmation = ({
    planTemplates,
    userFirstName,
}) => {
    let templateString = ''

    if (userFirstName) {
        templateString = getTemplateOrDefault(
            planTemplates,
            donationPlan,
            'singleConfirmationWithName',
        )
    } else {
        templateString = getTemplateOrDefault(
            planTemplates,
            donationPlan,
            'singleConfirmationWithoutName',
        )
    }

    return templateString
}

export const retrieveTrialConfirmation = ({
    planTemplates,
    userFirstName,
}) => {
    let templateString = ''

    if (userFirstName) {
        templateString = getTemplateOrDefault(
            planTemplates,
            subscriptionPlan,
            'trialConfirmationWithName',
        )
    } else {
        templateString = getTemplateOrDefault(
            planTemplates,
            subscriptionPlan,
            'trialConfirmationWithoutName',
        )
    }

    return templateString
}
