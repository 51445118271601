const types = [
    'EXCHANGE_CLAIM',
    'EXCHANGE_CLAIM_SUCCESS',
    'EXCHANGE_CLAIM_ERROR',
    'GENERATE_CLAIM',
    'GENERATE_CLAIM_SUCCESS',
    'GENERATE_CLAIM_ERROR',
]

const creators = (duck) => ({
    exchangeClaim: (payload) => ({
        type: duck.types.EXCHANGE_CLAIM,
        ...payload,
    }),
    generateClaim: (payload) => ({
        type: duck.types.GENERATE_CLAIM,
        ...payload,
    }),
})

export {
    types,
    creators,
}
