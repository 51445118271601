import Mailchimp from './mailchimp'
import Stripe from './stripe'
import Webhooks from './webhooks'
import WordPress from './wordpress'
import Zapier from './zapier'
// make sure you add fill="currentcolor" to svg that should match the text color

const BrandIcon = {
    Mailchimp,
    Zapier,
    WordPress,
    Webhooks,
    Stripe
}

export default BrandIcon
