import React from 'react'
import PropTypes from 'prop-types'
const Renewal = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_renewal">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M8.37981954,11.2089614 L9.84109435,12.6703297 C9.30462875,13.660542 9,14.7946961 9,16 C9,19.8656591 12.1334649,22.9994582 15.9989974,22.9999999 L15.9999992,20.9046807 C15.9999992,20.6099957 16.1170624,20.3273803 16.3254361,20.1190065 C16.759352,19.6850907 17.4628686,19.6850907 17.8967845,20.1190065 L17.8967845,20.1190065 L20.7698803,22.9921023 C21.2037962,23.4260182 21.2037962,24.1295348 20.7698803,24.5634507 L20.7698803,24.5634507 L17.8967845,27.4365465 C17.6884108,27.6449202 17.4057953,27.7619834 17.1111103,27.7619834 C16.4974606,27.7619834 15.9999992,27.264522 15.9999992,26.6508723 L15.9999992,26.6508723 L15.999,24.999015 L15.7346377,24.9961629 C10.8868137,24.8557914 7,20.8818027 7,16 C7,14.2391016 7.50571039,12.5963182 8.37981954,11.2089614 Z M14.8888881,4.238015 C15.5025378,4.238015 15.9999992,4.73547639 15.9999992,5.34912611 L15.9999992,5.34912611 L15.998999,7.00000005 C15.9993326,7.00000002 15.9996663,7 16,7 C20.9705627,7 25,11.0294373 25,16 C25,17.7608984 24.4942896,19.4036818 23.6201805,20.7910386 L22.1589056,19.3296703 C22.6953712,18.339458 23,17.2053039 23,16 C23,12.1340068 19.8659932,9 16,9 L15.999,8.999015 L15.9999992,11.0953177 C15.9999992,11.3479048 15.9139936,11.5916245 15.7581998,11.7873247 L15.6745623,11.8809919 C15.2406464,12.3149077 14.5371298,12.3149077 14.1032139,11.8809919 L14.1032139,11.8809919 L11.2301181,9.00789609 C10.7962022,8.57398021 10.7962022,7.87046357 11.2301181,7.43654769 L11.2301181,7.43654769 L14.1032139,4.56345191 C14.3115876,4.35507816 14.5942031,4.238015 14.8888881,4.238015 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                />
            </g>
        </g>
    </svg>
)

Renewal.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Renewal
