import { put, select, call } from 'redux-saga/effects'
import { types } from '../actions'
import logger from 'lib/logger'
import isEmpty from 'lodash/isEmpty'

function* preSubmitHooksFunction(preSubmitHooks) {
    try {
        for (const item of preSubmitHooks) {
            yield put({type: item.type, payload: item.payload})
        }
    } catch (error) {
        logger.error('Error in preSubmitHooksFunction', {}, error)
    }
}

function* submitForm({
    payload: { id },
}) {
    try {
        const state = yield select()
        const { submit, preSubmitHooks } = state.wizard.wizards[id]

        if (!isEmpty(preSubmitHooks)) {
            yield call(preSubmitHooksFunction, preSubmitHooks)
        }


        yield put({
            ...submit,
        })

        yield put({
            type: types.SUBMIT_FORM_SUCCESS,
            payload: {},
        })
    } catch (error) {
        logger.error('SUBMIT_FORM_ERROR', {}, error)
        yield put({
            type: types.SUBMIT_FORM_ERROR,
            error,
        })
    }
}

export default submitForm
