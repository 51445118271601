import React from 'react'
import PropTypes from 'prop-types'
const Signup = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_signup">
                <polygon
                    id="bounds"
                    fill="#F9F4FF"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M15.6633692,21.8874086 L10.8548345,24.9031997 C10.5605608,25.0877607 10.1712916,25.0005574 9.98537735,24.7084256 C9.89290311,24.5631185 9.86471563,24.3864452 9.90743227,24.2198819 L11.3107313,18.7480505 C11.3707383,18.5140676 11.2897708,18.2666892 11.102684,18.1124076 L6.72754714,14.5044376 C6.45979662,14.2836363 6.42304917,13.8891667 6.64546942,13.6233651 C6.75610185,13.4911546 6.91664975,13.4099468 7.0894226,13.3988062 L12.7652438,13.0328195 C13.0079496,13.0171694 13.2199251,12.8642812 13.3099186,12.6399705 L15.4144699,7.39433136 C15.5432647,7.07330766 15.9098228,6.91671534 16.2332003,7.04457263 C16.3940491,7.10816918 16.5214607,7.23465331 16.5855235,7.39433136 L18.6900749,12.6399705 C18.7800684,12.8642812 18.9920439,13.0171694 19.2347497,13.0328195 L24.9105708,13.3988062 C25.2579209,13.4212039 25.5212137,13.7188941 25.4986518,14.0637159 C25.4874294,14.2352311 25.4056261,14.3946104 25.2724463,14.5044376 L20.8973094,18.1124076 C20.7102226,18.2666892 20.6292552,18.5140676 20.6892622,18.7480505 L22.0925612,24.2198819 C22.1784406,24.5547481 21.9746064,24.8953231 21.6372848,24.9805774 C21.4695002,25.0229831 21.2915315,24.9950008 21.1451589,24.9031997 L16.3366242,21.8874086 C16.1310048,21.7584492 15.8689887,21.7584492 15.6633692,21.8874086 Z"
                    id="Star"
                    fill="#7A34FF"
                />
            </g>
        </g>
    </svg>
)

Signup.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Signup
