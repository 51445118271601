import exchangeClaim from './exchangeClaim'
import generateClaim from './generateClaim'

const sagas = (duck) => ({
    * exchangeClaim(payload) {
        yield exchangeClaim({ ...payload, ...duck })
    },
    * generateClaim(payload) {
        yield generateClaim({ ...payload, ...duck })
    }
})

export default sagas
