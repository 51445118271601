import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    payment: new Duck.Selector(() => (state) => state?.payment),
    paymentMethodErrors: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ paymentMethodErrors }) => paymentMethodErrors,
    )),
    defaultPaymentMethodErrors: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ defaultPaymentMethodErrors }) => defaultPaymentMethodErrors,
    )),
    isDefaultBeingSet: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ isDefaultBeingSet }) => isDefaultBeingSet,
    )),
    isDefaultNotBeingSet: new Duck.Selector((selectors) => createSelector(
        selectors.isDefaultBeingSet,
        (isDefaultBeingSet) => !isDefaultBeingSet,
    )),    
    paymentMethods: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ paymentMethods }) => paymentMethods,
    )),
    getPaymentMethod: new Duck.Selector((selectors) => (state, id) => {
        return selectors.paymentMethods(state)?.[id]
    }),
    doesPaymentMethodExist: new Duck.Selector((selectors) => createSelector(
        selectors.getPaymentMethod,
        (paymentMethod) => !!paymentMethod,
    )),
    getPaymentMethodError: new Duck.Selector((selectors) => (state, id) => {
        return selectors.paymentMethodErrors(state)?.[id]
    }),
    doesPaymentMethodErrorExist: new Duck.Selector((selectors) => createSelector(
        selectors.getPaymentMethodError,
        (paymentMethodError) => !!paymentMethodError,
    )),
})

export default selectors

