import React from 'react'
import PropTypes from 'prop-types'

import Account from './index'

const EditPassword = ({
    publisherId,
    userLoader,
    onUpdateSuccess = () => {},
    account,
    router,
    WizardRedux,
    update,
    initialize,
}) => (
    <Account
        publisherId={publisherId}
        userLoader={userLoader}
        skipWizardSubmit
        type="password"
        hideDefaultSubmitButtons
        onUpdateSuccess={onUpdateSuccess}
        account={account}
        router={router}
        update={update}
        WizardRedux={WizardRedux}
        initialize={initialize}
    />
)

EditPassword.defaultProps = {
    publisherId: null,
}

EditPassword.propTypes = {
    publisherId: PropTypes.string,
    account: PropTypes.any.isRequired,
    userLoader: PropTypes.any.isRequired,
    onUpdateSuccess: PropTypes.func,
    router: PropTypes.any.isRequired,
    WizardRedux: PropTypes.any.isRequired,
    update: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
}


export default EditPassword
