import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './GridCol.module.scss'

const GridCol = React.forwardRef(
    ({ className, ...props }, ref) => {

        const DEVICE_SIZES = [
            'xl',
            'lg',
            'md',
            'sm',
            'xs'
        ]
        const prefix = 'col'
        const spans = []
        const classes = []

        DEVICE_SIZES.forEach((breakpoint) => {
            const propValue = props[breakpoint]
            delete props[breakpoint]

            let span
            let offset
            let order

            if (typeof propValue === 'object' && propValue != null) {
                ({ span = true, offset, order } = propValue)
            } else {
                span = propValue
            }

            const infix = breakpoint !== 'xs' ? `-${breakpoint}` : ''

            if (span) {
                spans.push(
                    span === true ? style[`${prefix}${infix}`] : style[`${prefix}${infix}-${span}`]
                )
            }

            if (order != null) classes.push(style[`order${infix}-${order}`])
            if (offset != null) classes.push(style[`offset${infix}-${offset}`])

        })

        if (!spans.length) {
            spans.push(style[prefix]) // just plain 'col'
        }

        return (
            <div
                {...props}
                ref={ref}
                className={cx(
                    className,
                    ...spans,
                    ...classes
                )}
            />
        )
    }
)

const colSize = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['auto']),
])

const stringOrNumber = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
])

const column = PropTypes.oneOfType([
    colSize,
    PropTypes.shape({
        size: colSize,
        order: stringOrNumber,
        offset: stringOrNumber,
    })
])

export const GridColPropTypes = {
    /**
   * The number of columns to span on extra small devices
   */
    xs: column,
    /**
   * The number of columns to span on small devices
   */
    sm: column,
    /**
   * The number of columns to span on medium devices
   */
    md: column,
    /**
   * The number of columns to span on large devices
   */
    lg: column,
    /**
   * The number of columns to span on extra large devices
   */
    xl: column,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
}

GridCol.propTypes = GridColPropTypes

GridCol.displayName = 'GridCol'

export default GridCol
