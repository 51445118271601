import Loader from 'modules/loader'
import Plan from 'modules/plan'

import storeSubscriber from '../storeSubscriber'

const confirmOneTimeDonation = async ({
    dispatch,
    payload,
    action,
    trackPaymentEvent = () => { },
}) => {
    const { productOptionId } = payload

    dispatch(action(payload))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                const isVerifyingPayment = Plan.selectors.isVerifyingPayment(state)
                const isVerifyingPaymentSucess = Plan.selectors.isVerifyingPaymentSucess(state)
                const isVerifyingPaymentError = Plan.selectors.isVerifyingPaymentError(state)
                const errorMessage = Plan.selectors.isVerifyingPaymentErrorMessage(state)

                const isOneTimeDonationSuccess = Plan.selectors.isOneTimeDonationSuccess(state)

                if (!isOneTimeDonationSuccess) {
                    return false
                }

                if (isVerifyingPayment) {
                    return false
                }

                if (isVerifyingPaymentSucess === productOptionId) {
                    return true
                }

                if (isVerifyingPaymentError === productOptionId) {
                    throw new Error(errorMessage)
                }

                return false
            },
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }

    trackPaymentEvent({
        product_option_id: productOptionId,
    })
}

export default confirmOneTimeDonation
