const types = {
    TRACK_EVENT: 'PICO_ANALYTICS/TRACK_EVENT',
    IDENTIFY_USER: 'PICO_ANALYTICS/IDENTIFY_USER',
    PAGE_VISIT: 'PICO_ANALYTICS/PAGE_VISIT',
    RESET: 'PICO_ANALYTICS/RESET',
    SET_GUEST: 'PICO_ANALYTICS/SET_GUEST',
    SET_USER: 'PICO_ANALYTICS/SET_USER',
}

const creators = {
    trackEvent: (payload) => ({
        type: types.TRACK_EVENT,
        payload,
    }),
    identifyUser: (payload) => ({
        type: types.IDENTIFY_USER,
        payload,
    }),
    pageVisit: (payload) => ({
        type: types.PAGE_VISIT,
        payload,
    }),
    reset: () => ({
        type: types.RESET,
    }),
    setGuest: (payload) => ({
        type: types.SET_GUEST,
        payload,
    }),
    setUser: (payload) => ({
        type: types.SET_USER,
        payload,
    })
}

export {
    types,
    creators,
}
