import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './GridRow.module.scss'

const GridRow = React.forwardRef(
    ({ noGutters, className, ...props }, ref) => {

        const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs']
        const sizePrefix = 'row-cols'
        const classes = []

        DEVICE_SIZES.forEach((breakpoint) => {
            const propValue = props[breakpoint]
            delete props[breakpoint]

            let cols
            if (propValue != null && typeof propValue === 'object') {
                ({ cols } = propValue)
            } else {
                cols = propValue
            }

            const infix = breakpoint !== 'xs' ? `-${breakpoint}` : ''

            if (cols != null) classes.push(style[`${sizePrefix}${infix}-${cols}`])
        })

        return (
            <div
                ref={ref}
                {...props}
                className={cx(
                    noGutters && style['no-gutters'],
                    style.row,
                    className,
                    ...classes,
                )}
            />
        )
    }
)

const rowColWidth = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
])

const rowColumns = PropTypes.oneOfType([
    rowColWidth,
    PropTypes.shape({
        cols: rowColWidth,
    }),
])

export const GridRowPropTypes = {
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    /** Removes the gutter spacing between Cols as well as any added negative margins. */
    noGutters: PropTypes.bool,
    xs: rowColumns,
    sm: rowColumns,
    md: rowColumns,
    lg: rowColumns,
    xl: rowColumns,
}

GridRow.propTypes = GridRowPropTypes

GridRow.defaultProps = {
    noGutters: false
}

GridRow.displayName = 'GridRow'

export default GridRow
