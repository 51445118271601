import React from 'react'
import ScrollableTemplate from './ScrollableTemplate'

const createTemplate = (styleClass) => ({ children, ...props }) => (
    <ScrollableTemplate styleClass={styleClass} {...props}>
        {children}
    </ScrollableTemplate>
)

const Scrollable = {
    Wrapper: createTemplate('scrollableWrapper'),
    Header: createTemplate('scrollableHeader'),
    Container: createTemplate('scrollableContainer')
}

export default Scrollable
