const standardPayload = ({ config, instance, payload = {} }) => {
    // It is possible for the user to change their publisher once they have logged in,
    // so we need to save the publisher and occasionally change it when they switch.
    const publisher_id = instance.storage.getItem('publisherId') || config.publisherId || payload?.publisher_id

    const {
        last: {
            properties: pageData = {}
        } = {}
    } = instance.getState('page')

    const {
        userId,
        anonymousId,
    } = instance.getState('user')

    const {
        sessionId: session_id,
        locale,
        campaign,
        referrer
    } = instance.getState('context')

    return {
        user_id: userId || anonymousId,
        publisher_id,
        ...pageData,
        session_id,
        locale,
        campaign,
        referrer,
        context: config.context
    }
}

export default standardPayload
