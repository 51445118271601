import GadgetAccountCreate from './account_create.model'
import GadgetOtpGenerated from './otp_generated.model'
import GadgetOtpRedeemed from './otp_redeemed.model'
import GadgetOtpRequested from './otp_requested.model'
import GadgetPageView from './page_view.model'
import GadgetPasswordCreated from './password_created.model'
import GadgetPasswordUpdated from './password_updated.model'
import GadgetPromptConverted from './prompt_converted.model'
import GadgetPromptShown from './prompt_shown.model'
import GadgetUserLogin from './user_login.model'
import GadgetUserPayment from './user_payment.model'

const schemas = {
    prompt_shown: GadgetPromptShown,
    prompt_converted: GadgetPromptConverted,
    account_create: GadgetAccountCreate,
    user_login: GadgetUserLogin,
    user_payment: GadgetUserPayment,
    page_view: GadgetPageView,
    otp_requested: GadgetOtpRequested,
    otp_generated: GadgetOtpGenerated,
    otp_redeemed: GadgetOtpRedeemed,
    password_created: GadgetPasswordCreated,
    password_updated: GadgetPasswordUpdated
}

export default schemas
