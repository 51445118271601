import Me from 'modules/me'

import storeSubscriber from '../storeSubscriber'

const updateUserProperties = async ({
    dispatch,
    payload: {
        customProperties,
        publisherId,
    },
}) => {
    dispatch(Me.creators.updateUserProperties({
        payload: {
            customProperties,
            publisherId,
        },
    }))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                const errorMessage = Me.selectors.getUserPropertiesError(state)
                const data = Me.selectors.getUserPropertiesData(state)

                if (errorMessage) {
                    throw new Error(errorMessage)
                }

                if (data) {
                    return true
                }

                return false
            },
            (state) => !Me.selectors.getUserPropertiesLoading(state),
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default updateUserProperties
