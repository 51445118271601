import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getSvgImage } from 'piconetworks/pkg-functions'
import MenuWrapper from './components/MenuWrapper'
import Otp from 'piconetworks/pkg-field-otp'
import { Form as ReactFinalForm } from 'react-final-form'
import cx from 'classnames'
import styles from './scss/MenuRoutes.module.scss'

const LoginOTP = ({
    changeMenuRoute = () => { },
    sendExternalLink,
    publisherName,
    utmRootUrl,
    queryOverride,
    linkColor,
    menuPayload: { email, phone, redirect, },
    checkEmail = () => { },
    contrastColor,
    fieldContainerProps,
    oneTimeCodeError,
    oneTimeCodeSent,
    resendOneTimeCode,
    publisherId,
    origin,
    requestOneTimeCode,
    confirmLoginCode,
    backRedirect,
}) => {
    const [codeSent, setCodeSent] = useState(false)

    if (!email && !phone) {
        changeMenuRoute('/login')
    }

    useEffect(() => {
        if (codeSent === false) {
            requestOneTimeCode({
                email,
                phone,
                publisherId,
                origin,
            })
            setCodeSent(true)
        }

        return () => setCodeSent(false)
    }, [])

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
            customFooter={null}
            hasCustomFooter
            className={styles.loginHeight}
        >
            <div
                className={classNames(styles.menuHeader, styles.noBottomMargin)}
            >
                <div
                    className={styles.backIcon}
                    onClick={() => {
                        if (backRedirect) {
                            backRedirect()
                        } else {
                            changeMenuRoute('/login')
                        }
                    }}
                    style={{ color: linkColor }}
                >
                    {getSvgImage(linkColor, 'back-icon')}
                </div>

                <h3>
                    {email
                        ? 'Verify Email Address'
                        : phone
                            ? 'Verify Phone Number'
                            : ''}
                </h3>
            </div>
            <ReactFinalForm
                onSubmit={() => {
                    return {}
                }}
                keepDirtyOnResubmit={true}
                keepDirtyOnReinitialize
                render={() => (
                    <form>
                        <div
                            className={cx(styles.loginContainer, styles.white)}
                        >
                            <Otp
                                checkPhone={({ phone }) => resendOneTimeCode({ phone })}
                                checkEmail={checkEmail}
                                confirmCode={({ code }) => confirmLoginCode({ code, email, phone, origin, publisherId, redirect })}
                                contrastColor={contrastColor}
                                email={email}
                                phoneNumber={phone}
                                fieldContainerProps={fieldContainerProps}
                                linkColor={linkColor}
                                oneTimeCodeError={oneTimeCodeError}
                                oneTimeCodeSent={oneTimeCodeSent}
                                resendOneTimeCode={resendOneTimeCode}
                                allowSkipOtp={false}
                                isNewRegistration
                            />
                        </div>
                    </form>
                )}
            />
        </MenuWrapper>
    )
}

LoginOTP.defaultProps = {
    backRedirect: null,
    sendExternalLink: null,
    utmRootUrl: null,
}

LoginOTP.propTypes = {
    backRedirect: PropTypes.func,
    changeMenuRoute: PropTypes.func.isRequired,
    checkEmail: PropTypes.func,
    confirmLoginCode: PropTypes.func,
    contrastColor: PropTypes.string,
    fieldContainerProps: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkColor: PropTypes.string,
    menuPayload: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string,
        redirect: PropTypes.string,
    }),
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    origin: PropTypes.string,
    publisherId: PropTypes.string,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    redirect: PropTypes.string,
    requestOneTimeCode: PropTypes.func,
    resendOneTimeCode: PropTypes.func,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string,
}




export default LoginOTP
