import React from 'react'
import cn from 'classnames'
import { getSvgImage } from 'piconetworks/pkg-functions'
import style from './scss/Account.module.scss'

const ShowPassword = ({
    onClick = () => {},
    linkColor = '#333',
    isShown = false,
}) => (
    <div
        style={{ color: linkColor }}
        className={cn(style.showPassword)}
        onClick={onClick}
    >
        {getSvgImage(linkColor, (isShown ? 'eye-icon-crossed' : 'eye-icon'))}
        <span>{`${isShown ? 'Hide' : 'Show'} Password`}</span>
    </div>
)

export default ShowPassword
