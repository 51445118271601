import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './FormFieldError.module.scss'

const FormFieldError = ({ className, name, meta: { modified, touched, error, dirtySinceLastSubmit, submitError, submitting }, ...props }) => {
    return (
        <React.Fragment>
            {(error || (submitError && !dirtySinceLastSubmit && !submitting)) && (modified || touched) && (
                <div
                    className={cx(
                        style.description,
                        {
                            [style.error]: error || submitError
                        },
                        className)}
                    {...props}
                >
                    {(error || submitError) && (
                        <span>{error || submitError}</span>
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

FormFieldError.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    meta: PropTypes.shape({
        dirtySinceLastSubmit: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitError: PropTypes.string,
        submitting: PropTypes.bool,
        modified: PropTypes.bool,
        touched: PropTypes.bool
    }).isRequired
}

export default FormFieldError
