import { call, put, take } from 'redux-saga/effects'

const exchangeClaimFactory = (ClaimModule) => function* exchangeClaim(claim) {
    if (!claim) {
        throw new Error('no claim provided')
    }

    yield put(
        yield call(
            ClaimModule.creators.exchangeClaim,
            {
                claim,
            },
        )
    )

    const claimResult = yield take([
        ClaimModule.types.EXCHANGE_CLAIM_ERROR,
        ClaimModule.types.EXCHANGE_CLAIM_SUCCESS,
    ])

    if (claimResult.type === ClaimModule.types.EXCHANGE_CLAIM_ERROR) {
        throw new Error(claimResult.error)
    }

    return claimResult.payload
}

export default exchangeClaimFactory