import getPayments from './getPayments'
import addPayment from './addPayment'
import updateDefaultPayment from './updateDefaultPayment'
import deletePayment from './deletePayment'

const sagas = (duck) => ({
    * getPayments(payload) {
        yield getPayments({ ...payload, ...duck })
    },
    * addPayment(payload) {
        yield addPayment({ ...payload, ...duck })
    },
    * updateDefaultPayment(payload) {
        yield updateDefaultPayment({ ...payload, ...duck })
    },
    * deletePayment(payload) {
        yield deletePayment({ ...payload, ...duck })
    },
})

export default sagas
