import {
    put
} from 'redux-saga/effects'

function* resetOneTimeCode({
    types,
}) {
    try {
        yield put({
            type: types.RESET_ONE_TIME_CODE_SUCCESS,
        })
    } catch (error) {
        if (error?.response?.data?.message) {
            yield put({
                type: types.RESET_ONE_TIME_CODE_ERROR,
                error: error.response.data,
            })
        } else {
            yield put({
                type: types.RESET_ONE_TIME_CODE_ERROR,
                error: error.message,
            })
        }
    }
}

export default resetOneTimeCode
