import React, { Component } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import PopupWindow from './PopupWindow'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const capitalize = (s) => {
    if (typeof s !== 'string') {
        return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
}

class SocialLoginButton extends Component {
    constructor(props) {
        super(props)

        if (!props.api_url) {
            throw new Error('Invalid api_url')
        }

        this.state = {
            popup: null,
            response: null
        }
    }

    static propTypes = {
        access_token: PropTypes.string,
        api_url: PropTypes.string.isRequired,
        button: PropTypes.any,
        children: PropTypes.node,
        /** We're trying to deprecate things like content in lieu of children */
        content: PropTypes.string,
        disabled: PropTypes.bool,
        onLogin: PropTypes.func,
        params: PropTypes.shape({
            article_id: PropTypes.string,
            form_id: PropTypes.string,
            newsletter_ids: PropTypes.arrayOf(PropTypes.string),
            custom_fields: PropTypes.object
        }),
        previewMode: PropTypes.bool,
        publisherId: PropTypes.string,
        referer: PropTypes.string,
        origin: PropTypes.string,
        scope: PropTypes.string,
        type: PropTypes.string.isRequired,
        userId: PropTypes.string,
        redirect_uri: PropTypes.string,
        onClick: PropTypes.func,
        utm: PropTypes.shape({
            campaign: PropTypes.string,
            source: PropTypes.string,
            medium: PropTypes.string,
            content: PropTypes.string,
            term: PropTypes.string,
        }),
        onFailure: PropTypes.func,
    }

    static defaultProps = {
        onFailure: console.error,
        onLogin: console.log,
        previewMode: false,
        disabled: false,
        origin: typeof window !== 'undefined' && window?.location.origin,
        referer: typeof document !== 'undefined' && document?.referrer,
        redirect_uri: null,
        access_token: null,
        scope: 'user',
        params: {
            newsletter_ids: []
        },
        utm: {
            campaign: null,
            source: null,
            medium: null,
            content: null,
            term: null,
        }
    }

    openPopup = (e) => {
        const { previewMode } = this.props
        e.preventDefault()

        if (previewMode) {
            return
        }

        this.setState(state => ({ ...state, popup: true }))
    }

    closePopup = () => {
        this.setState(state => ({ ...state, popup: false }))
    }

    loginResponse = (authWindow) => ({ origin: source_origin, data } = {}) => {
        const { api_url, onLogin, onFailure, type } = this.props
        const { popup } = this.state

        if (source_origin === api_url && popup !== null) {
            let response
            try {
                response = JSON.parse(data)
            } catch (error) {
                response = JSON.parse(JSON.stringify(data))
            }

            authWindow.close()

            if (response.error) {
                onFailure(response)
            } else {
                onLogin(response, {
                    social_type: type,
                })
            }
        }
    }

    isFacebookApp = () => {
        const ua = navigator.userAgent || navigator.vendor || window.opera

        return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)
    }

    getPopupUrl = () => {
        const { userId, publisherId, access_token, type, scope, origin, referer, api_url, utm, params, redirect_uri } = this.props

        const query = {
            ...params,
            redirect_uri,
            utm,
            origin,
            client_id: `${publisherId}`,
            jwt: access_token,
            user_id: userId,
            referer
        }

        if (this.isFacebookApp() && !redirect_uri) {
            query.redirect_uri = window.location.ancestorOrigins[0]
        }

        return `${api_url}/connect/${type}/${scope}?${qs.stringify(query, { skipNulls: true })}`
    }

    render() {
        const { children, type, content, button, previewMode, onClick = () => { } } = this.props
        const { popup } = this.state

        const handleClick = (e) => {
            this.openPopup(e)
            onClick()
        }

        let button_component
        if (button) {
            button_component = React.cloneElement(button, { ...(!previewMode && { onClick: handleClick }) })
        } else {
            button_component = <button onClick={handleClick}>{content || children}</button>
        }

        return (
            <ErrorBoundary>
                {popup && !previewMode && <PopupWindow
                    url={this.getPopupUrl()}
                    title={`${capitalize(type)} Login`}
                    handleResponse={this.loginResponse}
                    onClose={this.closePopup}
                />}
                {button_component}
            </ErrorBoundary>
        )
    }
}

SocialLoginButton.displayName = 'SocialLoginButton'

export default SocialLoginButton
