import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Small.module.scss'

const Small = ({children, variant, className, ...props}) => {
    return (
        <small className={cx(styles.small, styles[variant], className)} {...props}>
            {children}
        </small>
    )
}

Small.defaultProps = {
    variant: 'default'
}

Small.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
    variant: PropTypes.string
}

export default Small
