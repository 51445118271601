import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Span.module.scss'

const Span = ({children, className, ...props}) => {
    return (
        <span className={cx(styles.span, className)} {...props}>
            {children}
        </span>
    )
}


Span.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Span
