import { put, putResolve } from 'redux-saga/effects'

import {
    GET_TOKEN,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, OAUTH_USER_LOGIN,
} from 'piconetworks/pkg-endpoints'

function* login({
    types, creators, email, password, phone, publisher_id: publisherId,
}) {
    try {
        if (!(!email || !phone)) {
            throw Error('email or phone must be provided')
        }

        if (!password) {
            throw Error('password must be provided')
        }

        if (!publisherId) {
            throw Error('publisher id must be provided')
        }

        const { data } = yield putResolve({
            type: GET_TOKEN,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER_LOGIN(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                timeout: 30000,
                data: {
                    ...(email ? { email: email } : {}),
                    ...(phone ? { phone_number: phone } : {}),
                    password,
                    publisher_id: publisherId,
                },
            },
        })

        if (data) {
            yield putResolve(creators.tokenLogin({
                accessToken: data.token,
                refreshToken: data.refresh_token,
            }))
        }

        yield put({
            type: types.LOGIN_SUCCESS,
            payload: {
                ...data,
            },
        })
    } catch (error) {
        const errorResponseMessage = error.response
        && error.response.data
        && error.response.data.message

        yield put({
            type: types.LOGIN_ERROR,
            error: errorResponseMessage || error.message,
        })
    }
}

export default login
