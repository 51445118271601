const selectors = {
    wizards: (state) => state?.wizard?.wizards,
    wizardTypes: (state) => Object.keys(selectors.wizards(state)),
    wizardName: (router) => router && router.query && router.query.id,
    thisWizard: ({ state, router }) => {
        const wizards = selectors.wizards(state)
        const wizardName = selectors.wizardName(router)

        return wizards[wizardName]
    },
    getCheckoutWizard: ({ state }) => {
        const wizards = selectors.wizards(state)
        const types = Object.keys(wizards)

        for (let wizard of types) {
            if (wizard.includes('CheckoutWizard')) {
                return wizards[wizard]
            }
        }
    },
    getWizard: ({ state, type }) => {
        const wizards = selectors.wizards(state)
        const types = Object.keys(wizards)

        for (let wizard of types) {
            if (type && wizard.includes(type)) {
                return wizards[wizard]
            }
        }
    },
    thisWizardPayload: ({ state, router }) => {
        const thisWizard = selectors.thisWizard({ state, router })

        return thisWizard?.submit?.payload || {}
    },
    thisWizardPopupId: ({ state, router }) => {
        const thisWizard = selectors.thisWizard({ state, router })

        return thisWizard?.submit?.payload?.popupId || null
    },
    thisWizardPopupOptionId: ({ state, router }) => {
        let thisWizard = selectors.thisWizard({ state, router })
        if (!thisWizard) {
            thisWizard = selectors.getCheckoutWizard({ state })
        }

        return thisWizard?.submit?.payload?.optionId || null
    },
    thisWizardShortCode: ({ state, router }) => {
        let thisWizard = selectors.thisWizard({ state, router })
        if (!thisWizard) {
            thisWizard = selectors.getCheckoutWizard({ state })
        }

        return thisWizard?.submit?.payload?.short_code || null
    },
}

export default selectors
