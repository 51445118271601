/* eslint-disable max-classes-per-file */
import '../wdyr' // <--- this first import is used for debugging react rerenders

import Head from 'next/head'
import dynamic from 'next/dynamic'
import React from 'react'
import withRedux from 'next-redux-wrapper'
import withReduxSaga from 'next-redux-saga'
import axios from 'axios'

import CustomApp from '../containers/_app/CustomAppClass'
import { storeFactory } from '../modules/store'

import Router, { withRouter } from 'next/router'

import UTMParser from 'lib/hostMutations/UTMParser'

import Client from 'modules/client'
import LandingPage from 'modules/landing-page'
import Blocks from 'modules/blocks'
import Links from 'modules/links'
import Iframely from 'modules/iframely'
import Newsletter from 'modules/newsletter'

import 'react-dates/lib/css/_datepicker.css'
import 'intl-tel-input/build/css/intlTelInput.css'
import 'react-dates/lib/css/_datepicker.css'

import '../styles/global.css';

class StyleMock {
    set color(color) {
        const RegExp = /^#[0-9A-F]{6}$/i
        const isColor = RegExp.test(color)

        return isColor ? color : ''
    }
}

class OptionMock {
    style = new StyleMock()
}

global.Option = OptionMock


//This is needed for rxjs and Safari dependencies.
import Symbol_observable from 'symbol-observable'

import {
    PICO_API_URL,
    GET_CLIENT,
    GET_LANDING_PAGE,
    GET_BLOCKS,
    SOCIAL_LINKS,
} from '../lib/endpoints'
import {
    IFRAMELY_API_URL,
} from 'piconetworks/pkg-endpoints'

import getQuery from 'lib/getQuery'

import App from 'next/app'

const baseURL = PICO_API_URL().includes('api.dev.pico.tools')
    ? PICO_API_URL().replace('https', 'http')
    : PICO_API_URL()

const fetchIframely = async ({ url, store }) => {
    const iframelyData = await axios({
        method: 'get',
        url: IFRAMELY_API_URL(url),
        headers: {
            Accept: 'application/json',
        },
        timeout: 30000,
    })

    store.dispatch({
        type: Iframely.types.GET_URL_METADATA_SUCCESS,
        payload: {
            linkUrl: url,
            data: iframelyData?.data,
        },
    })
}

const checkIfShowOfferCode = async (publisherId, store) => {
    const response = await getQuery({publisherId, baseURL})

    const storeState = store.getState()
    const allProducts = response?.products?.products
    const blocks = storeState?.blocks.data || {}

    const diplayedProducts = []
    Object.entries(blocks).forEach(([key, value = []]) => {
        value?.forEach((item) => {
            if (item?.type === 'product') {
                const productId = item?.product_block?.product?.id
                diplayedProducts.push(productId)
            }
        })
    })

    const hasMultiplePages = response?.client?.has_multiple_pages
    const hasMultipePagesFeature = response?.client?.has_multiple_page_feature
    let result = false
    if (
        hasMultiplePages && hasMultipePagesFeature &&
        diplayedProducts.length < allProducts.length
        && diplayedProducts.length > 0
    ) {
        result = true
    }

    if (response?.data) {
        store.dispatch({
            type: LandingPage.types.CHECK_IF_SHOW_OFFER_CODE_SUCCESS,
            payload: result,
        })
    }
    return hasMultipePagesFeature

}

App.getInitialProps = async ({
    ctx: {
        query = {},
        store,
        res,
    },
} = {}) => {
    if (typeof window !== 'undefined') {
        return {}
    }

    let has_multiple_page_feature = false

    try {
        const {
            company_slug,
            short_code: legacy_short_code,
            short_code_route,
        } = query

        if (!company_slug) {
            return {}
        }

        const creator_username = company_slug.replace(/^@/, '')
        const short_code = short_code_route || legacy_short_code

        

        if (legacy_short_code) {
            res?.writeHead(301, { Location: `/${company_slug}/${legacy_short_code}` })
            res?.end()

            return {}
        }

        
        let data, error = 'Creator not found'
        try {
            const response = await axios({
                method: 'get',
                baseURL,
                url: GET_CLIENT({
                    publisherUsername: creator_username,
                }),
                headers: {
                    Accept: 'application/json',
                    origin: process.env.MARKETING_SITE_URL,
                    'Content-Type': 'application/json',
                },
                timeout: 30000,
            })
            data = response?.data
        } catch (err) {
            error = err
        }
        
        if (!data) {
            throw new Error(error)
        }

        
        if (data.username !== creator_username) {
            res?.writeHead(302, { Location: `/@${encodeURI(data.username)}` })
            res?.end()

            return {}
        }

        
        const setClient = Client.creators.setClient({ payload: { client: data } })
        store.dispatch(setClient)

        let landingPagePayload
        try {
            const getLandingPageParams = {
                publisherUsername: data?.username,
            }
            if (short_code) {
                getLandingPageParams.shortCode = short_code
            }

            landingPagePayload = await axios({
                method: 'get',
                baseURL,
                url: GET_LANDING_PAGE(getLandingPageParams),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: data.id,
                },
                timeout: 30000,
            })
            
            const updatedLandingPagePayload = (short_code && landingPagePayload?.data) ? {
                [short_code]: landingPagePayload?.data.landing_page,
                ...landingPagePayload?.data.default_landing_pages,
            } : landingPagePayload?.data

            
            store.dispatch({
                type: LandingPage.types.GET_LANDING_PAGE_SUCCESS,
                payload: updatedLandingPagePayload,
            })

            const storeState = store.getState()

            const landingPageId = short_code
                ? landingPagePayload?.data?.landing_page?.id
                : landingPagePayload?.data?.default_landing_page?.id

            try {
                const hasBlocks = !!(storeState.blocks?.data?.landingPageId || []).length

                if (!hasBlocks) {
                    const blocksPayload = await axios({
                        method: 'get',
                        baseURL,
                        url: GET_BLOCKS({
                            landingPageId,
                        }),
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            publisherid: data.id,
                        },
                        timeout: 30000,
                    })

                    store.dispatch({
                        type: Blocks.types.GET_BLOCKS_SUCCESS,
                        payload: {
                            data: blocksPayload?.data,
                            formId: landingPageId,
                        },
                    })

                    const linkBlocks = blocksPayload.data.filter((block) => block.type === 'link')

                    if (linkBlocks.length) {
                        const linkUrls = linkBlocks.reduce((urls, { link_block }) => (
                            [...urls, ...(link_block?.links || []).length && link_block.links[0]?.is_rich
                                ? [link_block.links[0].url]
                                : []]
                        ), [])

                        const urlData = storeState?.iframely?.url_data

                        // dont request from iframely for already requested urls
                        const filteredLinkUrls = linkUrls.filter((url) => !urlData?.[url])

                        await Promise.all(filteredLinkUrls.map((url) => (
                            fetchIframely({
                                url,
                                store,
                            })
                        )))
                    }
                }
            } catch (error) {
                console.error('Link Blocks SSR Error', {}, error)
            }

            try {
                const socialLinksResponse = await axios({
                    url: `${SOCIAL_LINKS({ scope: 'social' })}`,
                    method: 'get',
                    baseURL,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: data.id,
                    },
                })

                if (socialLinksResponse.data) {
                    store.dispatch({
                        type: Links.types.GET_SOCIAL_LINKS_SUCCESS,
                        payload: socialLinksResponse.data,
                    })
                }
            } catch (error) {
                console.error('Social Links SSR Error', {}, error)
            }

            try {
                const newslettersResponse = await axios({
                    url: '/client/newsletters',
                    method: 'get',
                    baseURL,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: data.id,
                    },
                })

                if (newslettersResponse.data) {
                    store.dispatch({
                        type: Newsletter.types.GET_NEWSLETTERS_SUCCESS,
                        payload: newslettersResponse.data,
                    })
                }
            } catch (error) {
                console.error('Newsletters SSR Error', {}, error)
            }

            try {
                const publisherId = data?.id
                has_multiple_page_feature = await checkIfShowOfferCode(publisherId, store)
            } catch (error) {
                console.error('Offer Code SSR Error', {}, error)
            }

        } catch (error) {
            console.error('General SSR Error', {}, error)

            if (error?.response?.status === 402) {
                res?.writeHead(302, { Location: `/${company_slug}` })
                res?.end()

                return {}
            }
        }

        
        const publisherName = data.name
        const publisherUsername = data.username
        const coverPhotoImg =  landingPagePayload?.data?.landing_page?.cover_image || landingPagePayload?.data?.default_landing_page?.cover_image || data?.cover_photo
        const avatarImg = data.icon

        const description = `Check out ${publisherName} on Hype. Create your own page for free at hype.co.`

        return {
            header: {
                publisherUsername,
                companySlug: company_slug,
                title: publisherName,
                description,
                coverPhotoImg,
                avatarImg,
            },
            client: {has_multiple_page_feature, ...data},
        }
    } catch (error) {
        console.error('Layout SSR Error', {}, error)
        return { error: error }
    }
}

const DefaultHead = ({
    appURL = 'https://hype.co',
    companySlug = '',
    title = 'My page title',
    description = '',
    coverPhotoImg = '',
    avatarImg = '',
}) => {
    const twitterDescription = description.length > 70 ? `${description.substring(0, 67)}...` : description

    return (
        <div>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={coverPhotoImg} />
                <meta property="og:url" content={`${appURL}/@${companySlug}`} />
                <meta property="og:site_name" content="Hype" />
                <meta property="og:type" content="website" />

                <meta property="fb:app_id" content="1034930649922496" />

                <meta name="twitter:title" content={title} />
                <meta property="twitter:description" content={twitterDescription} />
                <meta name="twitter:image" content={coverPhotoImg} />
                <meta name="twitter:card" content="summary_large_image" />

                <link id="favicon" rel="shortcut icon" type="image/png" href={avatarImg} sizes="32x32" />
                <link rel="apple-touch-icon" sizes="180x180" href={avatarImg} />

                <meta name="viewport" content="initial-scale=1.0, width=device-width" />

                {process.env.STAGE === 'production' && <script defer data-domain="hype.co" src="https://plausible.io/js/script.js"></script>}
            </Head>
        </div>
    )
}

class WithReduxBypass extends React.Component {
    constructor(props) {
        super(props)

        const { client } = props
        const { company_slug } = props?.router?.query || {}

        this.state = {
            companySlug: company_slug || client?.username,
            publisherId: client?.id,
            client,
        }

        this.getPublisherId = this.getPublisherId.bind(this)
    }

    static async getInitialProps(appContext) {
        const appProps = await App.getInitialProps(appContext)
        return { ...appProps }
    }

    componentDidMount() {
        if (window.location.pathname.startsWith('/')) {
            this.setState({
                companySlug: window.location.pathname.split('/')[1],
            })
        }
        UTMParser()
    }

    componentDidUpdate(prevProps, prevState) {
        const { companySlug } = this.state
        if (prevState.companySlug !== companySlug) {
            this.getPublisherId()
        }
    }

    async getPublisherId() {
        const { companySlug } = this.state
        try {
            if (!companySlug) {
                throw new Error('no companySlug defined')
            }

            const creatorUserName = companySlug.replace(/^@/, '')

            const { data } = await axios({
                method: 'get',
                baseURL: PICO_API_URL(),
                url: GET_CLIENT({
                    publisherUsername: creatorUserName,
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                timeout: 30000,
            })

            if (!data) {
                throw new Error('problem with request')
            }

            if (data?.username) {
                data.page_slug = `@${data.username}`
            }

            const { id: publisherId } = data

            if (publisherId) {
                this.setState({
                    publisherId,
                    client: data,
                })
            } else {
                throw new Error('no publisher id defined')
            }
        } catch (e) {
            this.setState({
                publisherId: false,
            })
        }
    }

    render() {
        const { publisherId, client } = this.state
        const { router, publisher, header, error } = this.props
        const { asPath } = router

        if (error && error?.status === 404 && typeof window !== 'undefined') {
            if (window?.location?.pathname !== '/page-404') {
                window.location.href = `${window?.origin}/page-404`
            }
        }

        if (client && !client?.has_multiple_page_feature && typeof window !== 'undefined') {
            if (decodeURI(window?.location?.pathname ?? '') !== `/@${client.username}`) {
                window.location.href = `${window?.origin}/@${client.username}`
            }
        }

        if (publisherId === false) {
            if (asPath.startsWith('/error')) {
                return <CustomApp  {...this.props} />
            }
            if (typeof window !== 'undefined') {
                Router.replace(`/error/404?path=${window.location.pathname}`)
            }
            return (
                <DefaultHead {...header} />
            )
        }

        if (publisherId) {
            return <CustomApp client={client} publisher={publisher} publisherId={publisherId} {...this.props} />
        }

        return (
            <DefaultHead {...header} />
        )
    }
}

const makeStore = storeFactory({ publisherId: null, client: null })

export default withRedux(makeStore, { debug: false })(withReduxSaga(withRouter(WithReduxBypass)))
