import Joi from 'joi'

const LandingPageOtpGenerated = Joi.object()
    .keys({
        success: Joi.boolean().required().description('Describes whether the otp generation attempt failed or succeeded'),
        verify_flow_id: Joi.string().guid({ version: ['uuidv4'] }).required().description(
            `The id that links otp events to an individual & unique verification flow (OTP Request).
            The verify flow id is a uuid created when the initial otp request associated to an event was made.`
        )
    })
    .description('This event is fired when an end user request for a one-time code resolves, and can result in either a success or failure.')
    .meta({ name: 'LandingPageOtpGenerated', filename: 'landing_page:otp_generated.model' })

export default LandingPageOtpGenerated
