import setClient from './setClient'
import getClient from './getClient'

const sagas = (duck) => ({
    * setClient(payload) {
        yield setClient({
            ...payload,
            ...duck,
        })
    },
    * getClient(payload) {
        yield getClient({
            ...payload,
            ...duck,
        })
    },
})

export default sagas
