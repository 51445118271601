import {
    types,
    creators,
} from './actions'
import sagas, { takes } from './sagas'

const apps = {
    store: 'apps',
    types,
    creators,
    sagas,
    takes,
}

export default apps
