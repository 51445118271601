const actionCreatorFactory = (type) => ({ payload, error } = {}) => ({
    type,
    ...(payload && { payload }),
    ...(error && { error }),
})

const types = {
    FETCH_MARKDOWN: 'FETCH_MARKDOWN',
    FETCH_MARKDOWN_SUCCESS: 'FETCH_MARKDOWN_SUCCESS',
    FETCH_MARKDOWN_ERROR: 'FETCH_MARKDOWN_ERROR',
    FETCH_JSON: 'FETCH_JSON',
    FETCH_JSON_SUCCESS: 'FETCH_JSON_SUCCESS',
    FETCH_JSON_ERROR: 'FETCH_JSON_ERROR',
}

const creators = {
    fetchMarkdown: actionCreatorFactory(types.FETCH_MARKDOWN),
    fetchMarkdownSuccess: actionCreatorFactory(types.FETCH_MARKDOWN_SUCCESS),
    fetchMarkdownError: actionCreatorFactory(types.FETCH_MARKDOWN_ERROR),
    fetchJson: actionCreatorFactory(types.FETCH_JSON),
    fetchJsonSuccess: actionCreatorFactory(types.FETCH_JSON_SUCCESS),
    fetchJsonError: actionCreatorFactory(types.FETCH_JSON_ERROR),
}

export {
    types,
    creators,
}