import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './Loading.module.scss'

const Loading = (props) => {
    const { className, inlineStyle } = props
    return (
        <div
            className={cx(style.PicoLoaderContainer_modal, className)}
            {...(inlineStyle && { style: inlineStyle })}
        >
            <div className={style.PicoLoader_modal} />
        </div>
    )
}

Loading.defaultProps = {
    inlineStyle: {}
}

Loading.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inlineStyle: PropTypes.shape({
        top: PropTypes.string
    })
}

export default Loading
