import { produce } from 'immer'

export const initialState = {
    methods: [],
    defaultMethod: null,
    error: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_PAYMENTS:
            draftState.methods = initialState.paymentMethods
            draftState.defaultMethod = initialState.defaultMethod
            draftState.error = initialState.error
            break

        case duck.types.GET_PAYMENTS_SUCCESS:
            draftState.methods = payload.payment_methods
            draftState.defaultMethod = payload.payment_method_default
            break

        case duck.types.ADD_PAYMENT_SUCCESS:
            draftState.methods = payload.paymentMethods
            break

        case duck.types.DELETE_PAYMENT_SUCCESS:
            const deletedIdx = draftState.methods.findIndex(payment =>
                payment?.id === payload?.source_id
            )
            if (deletedIdx !== -1) {
                draftState.methods.splice(deletedIdx, 1)
            }

            if (payload?.source_id === draftState?.defaultMethod) {
                draftState.defaultMethod = null
            }
            break

        case duck.types.UPDATE_DEFAULT_PAYMENT:
            draftState.defaultMethod = initialState.defaultMethod
            draftState.error = initialState.error
            break

        case duck.types.UPDATE_DEFAULT_PAYMENT_SUCCESS:
            draftState.defaultMethod = payload.payment_method_default
            break

        case duck.types.GET_PAYMENTS_ERROR:
        case duck.types.ADD_PAYMENT_ERROR:
        case duck.types.UPDATE_DEFAULT_PAYMENT_ERROR:
        case duck.types.DELETE_PAYMENT_ERROR:
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
