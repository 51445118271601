import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Label.module.scss'

const FormLabel = ({ children, className, name, ...props}) => {
    return (
        <label htmlFor={name} className={cx(styles.label, className)} {...props}>
            {children}
        </label>
    )
}

FormLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string
}

export default FormLabel
