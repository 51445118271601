import Joi from 'joi'

const appNames = ['discord', 'instagram', 'stripe', 'twitter', 'youtube', 'zapier']

const DashboardAppInstallChange = Joi.object()
    .keys({
        app_name: Joi.string().valid(...appNames).required().description('The name of the app integration the creator is installing/uninstalling'),
        install_change: Joi.string().valid('install', 'uninstall').required().description('Describes whether the action taken by the creator was an install or uninstall')
    })
    .description('dashboard:app_install_change event attributes ')
    .meta({ name: 'DashboardAppInstallChange', filename: 'dashboard:app_install_change.model' })

export default DashboardAppInstallChange
