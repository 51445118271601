import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form, FormSpy, Field } from 'react-final-form'

import Boutton from 'piconetworks/pkg-boutton'
import PicoForm from 'piconetworks/pkg-form'
import MenuWrapper from './components/MenuWrapper'
import ChooseNewsletter from 'piconetworks/pkg-choose-newsletter'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const ManageNewsletters = (props) => {
    const {
        changeMenuRoute,
        guestLoader,
        newsletterSubscribe,
        onNewsletterUpdate,
        publisherId,
        queryOverride,
        sendExternalLink,
        userLoader,
        userSyncNewsletters,
        utmRootUrl,
        userNewsletters: _userNewsletters,
    } = props

    const scopedLoader = userLoader || guestLoader || {}

    const {
        userNewsletters = [],
        publisher: {
            name: publisherName,
            styling: { linkColor = '' } = {}
        } = {}
    } = scopedLoader

    const subscribedNewsletters = _userNewsletters || userNewsletters?.reduce(
        (acc, { id, subscribed }) => {
            if (subscribed) {
                acc.push(id)
            }
            return acc
        },
        []
    )

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
        >
            <div className={style.menuHeader}>
                <div
                    className={style.backIcon}
                    onClick={() => {
                        changeMenuRoute('/')
                    }}
                    style={{ color: linkColor }}
                >
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Newsletters</h3>
            </div>
            <div className={style.container}>
                <Form
                    onSubmit={(props) => {
                        const sync = props.sync || []
                        const isSynced = !!sync[0]
                        const newsletterIds = props.newsletters || []

                        newsletterSubscribe({
                            newsletters: newsletterIds,
                            publisherId,
                            sync: isSynced
                        })

                        onNewsletterUpdate()
                    }}
                    initialValues={{
                        ...(userSyncNewsletters && { sync: [true] }),
                        newsletters: subscribedNewsletters
                    }}
                    render={({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={style.emailCommunications}>
                                <Field
                                    name="sync"
                                    component={PicoForm.Checkbox}
                                    type="checkbox"
                                    text={`Email communications from ${publisherName}`}
                                    value
                                    customTitleClass={classNames(
                                        style.checkboxTitle,
                                        style.dark
                                    )}
                                    style={{
                                        checkMarkOuter: {
                                            checked: {
                                                backgroundColor: linkColor
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <FormSpy>
                                {(props) => {
                                    const { values: { sync = [] } = {} } = props
                                    const isSynced = !!sync[0]

                                    return (
                                        <div className={style.newsletterList}>
                                            <div
                                                className={classNames(
                                                    style.newsletterContainer
                                                )}
                                            >
                                                <ChooseNewsletter
                                                    defaultValue={''}
                                                    disableGroup={!isSynced}
                                                    newsletterFilter="all"
                                                    newsletters={
                                                        userNewsletters
                                                    }
                                                    isReadyToMount={true}
                                                    isSingleColumn={true}
                                                    linkColor={linkColor}
                                                />

                                                <div
                                                    className={
                                                        style.buttonGroup
                                                    }
                                                >
                                                    <Boutton
                                                        className={style.cancel}
                                                        onClick={() => { changeMenuRoute('/') }}
                                                        variant="link"
                                                    >
                                                        Cancel
                                                    </Boutton>
                                                    <Boutton
                                                        type="submit"
                                                        variant="primary"
                                                    >
                                                        Update
                                                    </Boutton>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}
                            </FormSpy>
                            <button className={style.hidden} type="submit">
                                Continue
                            </button>
                            {submitError && (
                                <div className={style.error}>{submitError}</div>
                            )}
                        </form>
                    )}
                />
            </div>
        </MenuWrapper>
    )
}

ManageNewsletters.defaultProps = {
    publisherId: null,
    sendExternalLink: null,
    userNewsletters: null,
}

ManageNewsletters.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    guestLoader: PropTypes.any.isRequired,
    newsletters: PropTypes.object,
    newsletterSubscribe: PropTypes.func.isRequired,
    onNewsletterUpdate: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    sendExternalLink: PropTypes.func,
    sync: PropTypes.object,
    userLoader: PropTypes.any.isRequired,
    userNewsletters: PropTypes.array,
    userSyncNewsletters: PropTypes.bool,
    utmRootUrl: PropTypes.string,
    values: PropTypes.object
}

export default ManageNewsletters
