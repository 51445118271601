/* eslint-disable */
import React from 'react'

export const SMSToggle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65"
        height="64"
        fill="none"
        viewBox="0 0 65 64"
    >
        <path
            fill="#28BF6F"
            fillRule="evenodd"
            d="M50.5 31c0 9.389-8.06 17-18 17-1.062 0-2.102-.087-3.113-.253l-7.542 4.12a1 1 0 01-1.48-.877v-7.433C16.762 40.448 14.5 35.973 14.5 31c0-9.389 8.058-17 18-17 9.94 0 18 7.611 18 17zm-18 3a3 3 0 100-6 3 3 0 000 6zm12-3a3 3 0 11-6 0 3 3 0 016 0zm-21 3a3 3 0 100-6 3 3 0 000 6z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M23.5 34a3 3 0 100-6 3 3 0 000 6zm12-3a3 3 0 11-6 0 3 3 0 016 0zm9 0a3 3 0 11-6 0 3 3 0 016 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const EmailToggle = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="38"
        fill="none"
        viewBox="0 0 41 38"
    >
        <path
            fill="#7EC2FA"
            fillRule="evenodd"
            d="M.5 15.2L18.017 1.332a4 4 0 014.965 0L40.5 15.2V34a4 4 0 01-4 4h-32a4 4 0 01-4-4V15.2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M8.306 4.433a2 2 0 00-2 2v13.364l11.71 9.27a4 4 0 004.966 0l11.711-9.27V6.433a2 2 0 00-2-2H8.306z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#7EC2FA"
            fillRule="evenodd"
            d="M10.153 8.233a1.267 1.267 0 100 2.534h20.693a1.267 1.267 0 100-2.534H10.153zm0 5.067a1.267 1.267 0 100 2.533h20.693a1.267 1.267 0 100-2.533H10.153zm-1.266 6.333c0-.7.567-1.266 1.266-1.266h20.693a1.267 1.267 0 110 2.533H10.153c-.7 0-1.266-.567-1.266-1.267zm11.613 11.4L17.3 28.5h6.4l-3.2 2.533zm6.4-5.066H14.1l-3.2-2.534h19.2l-3.2 2.534z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#2899F7"
            fillRule="evenodd"
            d="M.5 15.2l20 15.833 20-15.833V34a4 4 0 01-4 4h-32a4 4 0 01-4-4V15.2z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#53ADF8"
            fillRule="evenodd"
            d="M40.5 15.2V34a4 4 0 01-4 4H11.708l28.79-22.8z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const SMSSubscribe = ({
    fill,
    type = 'subscribe',
}) =>
    type === 'subscribe' ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            fill="none"
            viewBox="0 0 20 19"
        >
            <path
                fill={fill || '#834EFF'}
                fillRule="evenodd"
                d="M8.105 15.214h-.05a8.57 8.57 0 01-1.393-.113l-3.297 1.801a.5.5 0 01-.74-.439v-3.237C1.012 11.835 0 9.832 0 7.607 0 3.406 3.606 0 8.054 0c3.594 0 6.638 2.223 7.675 5.292L15 4.87a1 1 0 00-1.5.866V7.16a7.003 7.003 0 00-5.395 8.053zm7.962-8.03A.625.625 0 0015 7.624v1.247a5 5 0 104.25 2.367l-.916.916a3.75 3.75 0 11-3.475-2.03l.14-.003.001 1.43a.625.625 0 001.067.442l1.963-1.964a.625.625 0 000-.884l-1.963-1.963z"
                clipRule="evenodd"
            ></path>
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            fill="none"
            viewBox="0 0 20 18"
        >
            <path
                fill="#FF4440"
                fillRule="evenodd"
                d="M8.054 15.214c.101 0 .202-.002.302-.005a7 7 0 017.587-9.146C15.187 2.603 11.943 0 8.054 0 3.606 0 0 3.406 0 7.607c0 2.225 1.012 4.228 2.625 5.619v3.237a.5.5 0 00.74.44L6.662 15.1a8.57 8.57 0 001.392.113zM20 13a5 5 0 11-10 0 5 5 0 0110 0zm-4.294 0l1.648 1.648a.5.5 0 01-.706.706L15 13.706l-1.648 1.648a.5.5 0 01-.706-.706L14.294 13l-1.648-1.648a.5.5 0 01.706-.706L15 12.294l1.648-1.648a.5.5 0 01.706.706L15.706 13z"
                clipRule="evenodd"
            ></path>
        </svg>
    )

export const EmailSubscribe = ({
    fill,
    type = 'subscribe',
}) =>
    type === 'subscribe' ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            fill="none"
            viewBox="0 0 20 18"
        >
            <path
                fill={fill || '#834EFF'}
                fillRule="evenodd"
                d="M0 4a4 4 0 014-4h11a4 4 0 014 4v2.29l-2-2v-.624a1 1 0 00-1.649-.761l-5.527 4.71a.5.5 0 01-.648 0l-5.527-4.71A1 1 0 002 3.666V9.5A2.5 2.5 0 004.5 12h3.57a7.06 7.06 0 000 2H4a4 4 0 01-4-4V4zm16.067 2.183A.625.625 0 0015 6.625v1.247a5 5 0 104.25 2.367l-.916.916a3.75 3.75 0 11-3.475-2.03l.14-.003.001 1.43a.625.625 0 001.067.442L18.03 9.03a.625.625 0 000-.884l-1.963-1.963z"
                clipRule="evenodd"
            ></path>
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="17"
            fill="none"
            viewBox="0 0 20 17"
        >
            <path
                fill="#FF4440"
                fillRule="evenodd"
                d="M4 0a4 4 0 00-4 4v6a4 4 0 004 4h4.29A7.001 7.001 0 018 12H4.5A2.5 2.5 0 012 9.5V3.666a1 1 0 011.649-.761l5.527 4.71a.5.5 0 00.648 0l5.527-4.71A1 1 0 0117 3.666V5.29a6.972 6.972 0 012 .965V4a4 4 0 00-4-4H4zm16 12a5 5 0 11-10 0 5 5 0 0110 0zm-4.294 0l1.648 1.648a.5.5 0 01-.706.706L15 12.706l-1.648 1.648a.5.5 0 01-.706-.706L14.294 12l-1.648-1.648a.5.5 0 01.706-.706L15 11.294l1.648-1.648a.5.5 0 01.706.706L15.706 12z"
                clipRule="evenodd"
            ></path>
        </svg>
    )

export const TabIcons = {
    email: EmailToggle,
    sms: SMSToggle,
}
