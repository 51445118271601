import Checkbox from './FormCheckbox'
import Dropdown from './FormDropdown'
import DynamicField from './FormDynamicField'
import Feedback from './FormFeedback'
import Field from './FormField'
import FieldError from './FormFieldError'
import FieldStatus from './FormFieldStatus'
import Typeahead from './FormTypeahead'
import Input from './FormInput'
import Label from './FormLabel'
import Radio from './FormRadio'
import Search from './FormSearch'
import Switch from './FormSwitch'
import TextArea from './FormTextArea'
import Uploader from './FormUploader'
import Validate from './FormRequirement'
import FormatInput from './FormFormatInput'
import Requirement from './FormRequirement'
import utils from './utils/index'

const Form = {
    Checkbox,
    Dropdown,
    DynamicField,
    Feedback,
    Field,
    FieldError,
    FieldStatus,
    FormatInput,
    Input,
    Label,
    Radio,
    Requirement,
    Search,
    Switch,
    TextArea,
    Typeahead,
    Uploader,
    Validate,
    utils
}
export default Form
