import Joi from 'joi'

const GadgetPromptConverted = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).required().allow(null).description('The id of the landing page in Hype Forms table'),
        prompt_type: Joi.string().valid('payment', 'signup').required().description('Whether the prompt was for signing up or payment')
    })
    .description('gadget:prompt_converted event attributes ')
    .meta({ name: 'GadgetPromptConverted', filename: 'gadget:prompt_converted.model' })

export default GadgetPromptConverted
