import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import { colors } from 'piconetworks/pkg-functions'
import { format } from 'piconetworks/pkg-currency'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const NotificationMenuPage = (props) => {
    const {
        backRoute,
        changeMenuRoute,
        linkColor,
        memberNoun,
        nextSubscriptionEnd,
        nextProductOptionPrice,
        utmRootUrl,
        queryOverride,
        currentProductOptionCurrency,
        priceText
    } = props

    const descriptionText = (
        <p>
            Thank you, your {memberNoun} will now continue uninterrupted. It will next renew on {dayjs(nextSubscriptionEnd).format('LL')}
            {` for ${priceText(format({
                amount: nextProductOptionPrice,
                currencyCode: currentProductOptionCurrency,
                symbol: false
            }), currentProductOptionCurrency)}`}
        </p>
    )

    return (
        <MenuWrapper utmRootUrl={utmRootUrl} queryOverride={queryOverride} hideBackground>
            <div className={classNames(style.menuHeader, style.shadow, style.notificationHeader)}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute(!backRoute ? '/' : backRoute) }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Notifications</h3>
            </div>
            <div className={classNames(style.background)} style={{ backgroundColor: colors.getBgColor(linkColor, 0.15) }}>
                <div className={style.notification}>
                    {descriptionText}
                </div>
            </div>
        </MenuWrapper>
    )
}

NotificationMenuPage.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
    backRoute: PropTypes.string,
    linkColor: PropTypes.string.isRequired,
    memberNoun: PropTypes.string.isRequired,
    nextSubscriptionEnd: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    nextProductOptionPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    priceText: PropTypes.func.isRequired,
}

export default NotificationMenuPage
