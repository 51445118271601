import { call, put } from 'redux-saga/effects'
import {
    ME
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* updateUserProperties({
    types,
    publisherId,
    customProperties
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${ME()}/properties`,
            method: 'put',
            headers: {
                publisherid: publisherId,
            },
            data: {
                custom_properties: customProperties
            },
        })

        if (data) {
            yield put({
                type: types.UPDATE_USER_PROPERTIES_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Unable to update custom properties')
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_USER_PROPERTIES_ERROR,
            error: 'Error updating user fields',
        })
    }
}

export default updateUserProperties
