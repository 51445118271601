import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanDonationChange = ({
    currentSubscriptionEnd,
    publisherId,
    changeMenuRoute,
    userLoader,
    guestLoader,
    router,
    purchaseSwitch,
    utmRootUrl,
    queryOverride,
    getPlanTexts,
    featureList,
    Link,
    WizardRedux,
    SelectDonation,
    isFetching,
    onDonationChangeSuccess,
    contributionLanguage = 'tip',
}) => {

    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const Something = ({ form }) => (
        <div>
            <div className={style.donationDescription}>
                <span>
                    Any change to your monthly {contributionLanguage === 'tip' ? 'tip' : 'donation'} will take effect on
                    {' '}
                    {dayjs(currentSubscriptionEnd).format('LL')}
                </span>
            </div>
            <div className={style.approveButtonGroup}>
                <Boutton
                    className={style.cancel}
                    onClick={() => { changeMenuRoute('/managePlan/donation') }} 
                    variant="link"
                >
                    Cancel
                </Boutton>
                <Boutton
                    className={style.donationSubmitButton}
                    onClick={() => { form.submit() }}
                    variant="primary"
                >
                    Update Amount
                </Boutton>
            </div>
        </div>
    )

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/managePlan/donation') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Change {contributionLanguage === 'tip' ? 'Tip' : 'Donation'} Amount</h3>
            </div>
            <SelectDonation
                userLoader={userLoader}
                router={router}
                hideCallToAction
                hideToggle
                toggleDefault="recurring-donation"
                getFormSubmission={({
                    plan_option_id: planOptionId,
                    custom_price: customPrice,
                }) => {
                    purchaseSwitch({
                        planOptionId,
                        publisherId,
                        ...(customPrice && { customPrice }),
                    })
                }}
                onDonationChangeSuccess={onDonationChangeSuccess}
                hideFormSubmit
                isChangingDonation
                getPlanTexts={getPlanTexts}
                featureList={featureList}
                Link={Link}
                WizardRedux={WizardRedux}
                isFetching={isFetching}
            >
                <Something />
            </SelectDonation>
        </MenuWrapper>
    )
}

ManagePlanDonationChange.defaultProps = {
    accessToken: null,
    publisherId: null
}

ManagePlanDonationChange.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    accessToken: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
    Link: PropTypes.any.isRequired,
    WizardRedux: PropTypes.object.isRequired,
    SelectDonation: PropTypes.element.isRequired,
    isFetching: PropTypes.bool,
    onDonationChangeSuccess: PropTypes.func,
    userLoader: PropTypes.object,
    guestLoader: PropTypes.object
}

export default ManagePlanDonationChange
