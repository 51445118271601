const types = [
    'LOGIN',
    'LOGIN_SUCCESS',
    'LOGIN_ERROR',
    'SOCIAL_LOGIN',
    'SOCIAL_LOGIN_SUCCESS',
    'SOCIAL_LOGIN_ERROR',
    'LOGOUT',
    'LOGOUT_SUCCESS',
    'LOGOUT_ERROR',
    'USE_REFRESH_TOKEN',
    'USE_REFRESH_TOKEN_SUCCESS',
    'USE_REFRESH_TOKEN_ERROR',
    'TOKEN_LOGIN',
    'TOKEN_LOGIN_SUCCESS',
    'TOKEN_LOGIN_ERROR',
    'CLAIM_MAGIC_LINK',
    'CLAIM_MAGIC_LINK_SUCCESS',
    'CLAIM_MAGIC_LINK_ERROR',
    'REGISTER_CONFIRM',
    'REGISTER_CONFIRM_SUCCESS',
    'REGISTER_CONFIRM_ERROR',
]

const creators = (duck) => ({
    login: (payload) => ({
        type: duck.types.LOGIN,
        ...payload,
    }),
    logout: (payload) => ({
        type: duck.types.LOGOUT,
        ...payload,
    }),
    useRefreshToken: () => ({
        type: duck.types.USE_REFRESH_TOKEN,
    }),
    tokenLogin: (payload) => ({
        type: duck.types.TOKEN_LOGIN,
        ...payload,
    }),
    socialLogin: (payload) => ({
        type: duck.types.SOCIAL_LOGIN,
        ...payload,
    }),
    claimMagicLink: (payload) => ({
        type: duck.types.CLAIM_MAGIC_LINK,
        payload,
    }),
    registerConfirm: (payload) => ({
        type: duck.types.REGISTER_CONFIRM,
        payload,
    }),
})

export {
    types,
    creators,
}
