import React from 'react'

export default ({ title = 'icon/arrow/up/default', color = '#120A20' }) => (
    <svg
        height="24px"
        width="24px"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="icon/arrow/up/default"
            fill="currentcolor"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <path
                id="shape"
                d="M5.29277221,8.29566543 C5.68313516,7.90530248 6.31182673,7.90530248 6.70218967,8.29566543 L12,13.5919035 L17.2978103,8.29566543 C17.6881733,7.90530248 18.3168648,7.90530248 18.7072278,8.29566543 C19.0975907,8.68602838 19.0975907,9.32471581 18.7072278,9.71507876 L12.7068146,15.7123475 C12.3164517,16.1027104 11.6835483,16.1027104 11.2931854,15.7123475 L5.29277221,9.71507876 C4.90240926,9.32471581 4.90240926,8.68602838 5.29277221,8.29566543 Z"
                fill={color}
                transform="translate(12.000000, 12.004006) rotate(-180.000000) translate(-12.000000, -12.004006) "
            />
        </g>
    </svg>
)
