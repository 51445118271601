import { call, put } from 'redux-saga/effects'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'
import {
    BLOCKS
} from 'piconetworks/pkg-endpoints'

function* getBlocks({
    scope,
    types,
    formId,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            method: 'get',
            url: `${BLOCKS({ scope })}/${formId}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                publisherid: publisherId
            }
        })

        if (data) {
            const getMarkdown = async (blockId) => {
                const { data } = await picoAPIHelper({
                    method: 'get',
                    url: `${BLOCKS({ scope })}/${formId}/data/${blockId}`,
                    headers: {
                        Accept: 'text/markdown',
                        'Content-Type': 'application/json',
                        publisherId
                    }
                })

                return data.toString('utf-8')
            }

            if (getMarkdown) {
                // fetch markdown from API urls for text blocks and signup blocks
                let blocks = []

                for (const block of data) {
                    const isText = block.type === 'text'
                    if (isText || block.type === 'signup') {
                        const blockWithType = isText ? block?.text_block : block?.signup_block

                        let markdown

                        if (isText) {
                            markdown = yield call(getMarkdown, block.id)

                        } else {
                            const richTextRegex = /(.*?)customtext(.*?)pico.tools(.*?)+/g

                            const isSubTitleRichText = !!(blockWithType?.subtitle || '').match(richTextRegex)

                            if (isSubTitleRichText) {
                                markdown = yield call(getMarkdown, block.id)
                            }
                        }

                        blocks.push({
                            ...block,
                            [isText ? 'text_block' : 'signup_block']: {
                                ...blockWithType,
                                markdown,
                            },
                        })
                    } else {
                        blocks.push(block)
                    }
                }

                yield put({
                    type: types.GET_BLOCKS_SUCCESS,
                    payload: {
                        data: blocks,
                        formId,
                    }
                })
            }
        } else {
            throw new Error('Error fetching blocks')
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: types.GET_BLOCKS_ERROR,
            error,
        })
    }
}

export default getBlocks
