import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg height={height} width={width} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_nav/apps" fill="#414044">
                <path
                    d="M26.5,11 C28.709139,11 30.5,12.790861 30.5,15 L30.5,18 L34,18 C36.209139,18 38,19.790861 38,22 L37.999,25.5 L41,25.5 C43.209139,25.5 45,27.290861 45,29.5 C45,31.709139 43.209139,33.5 41,33.5 L37.999,33.5 L38,38 C38,40.209139 36.209139,42 34,42 L29,42 L29,39 C29,37.4023191 27.75108,36.0963391 26.1762728,36.0050927 L26,36 C24.4023191,36 23.0963391,37.24892 23.0050927,38.8237272 L23,39 L23,42 L18,42 C15.790861,42 14,40.209139 14,38 L14,33 L17,33 C18.5976809,33 19.9036609,31.75108 19.9949073,30.1762728 L20,30 C20,28.4023191 18.75108,27.0963391 17.1762728,27.0050927 L17,27 L14,27 L14,22 C14,19.790861 15.790861,18 18,18 L22.5,18 L22.5,15 C22.5,12.790861 24.290861,11 26.5,11 Z"
                    id="Combined-Shape"
                />
            </g>
        </g>
    </svg>
)
