import React from 'react'

export default ({ title = 'Delete Account', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title={title}
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            fill={color}
        />
    </svg>
)
