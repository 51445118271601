/* eslint-disable max-classes-per-file */
import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import { PicoAnalyticsProvider } from 'piconetworks/pkg-pico-analytics'
import TagManager from 'react-gtm-module'
import Routes from './Routes'
import Head from 'next/head'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import Router from 'next/router'

import RouterModule from 'modules/router'
import FeatureFlags from 'modules/feature-flags'

import getAnalytics from '../../lib/analytics'

const PersistGateServer = ({ children }) => {
    return children
}

const gtmArgs = {
    gtmId: process.env.GTM_TAG,
    dataLayer: [{
        SiteType: 'landingPage',
    }],
}

class CustomApp extends App {
    constructor(props) {
        super(props)
        this.persistor = persistStore(props.store)
        this.updateRouter = this.updateRouter.bind(this)
        this.isServer = typeof window === 'undefined'
        this.PersistorGate = PersistGate
    }

    componentDidMount() {
        TagManager.initialize(gtmArgs)

        this.setFeatureFlag()
        this.setWindowObject()

        Router.events.on('routeChangeComplete', (url) => {
            if (url) {
                TagManager.dataLayer({
                    dataLayer: {
                        hitType: 'pageview',
                        page: url,
                    },
                })
            }
        })

        Router.events.on('routeChangeStart', (url) => {
            this.setFeatureFlag()
        })
    }

    updateRouter() {
        this.props.store?.dispatch(
            RouterModule.creators.update({
                asPath: Router?.router?.asPath,
                basePath: Router?.router?.basePath,
                pathname: Router?.router?.pathname,
                query: Router?.router?.query,
                route: Router?.router?.route,
            }),
        )
    }

    setWindowObject() {
        const { store: { dispatch } = {} } = this.props
        window.Pico = {
            enableFeature: (flag) => dispatch(FeatureFlags.creators.enableFeatureFlag(flag)),
            disableFeature: (flag) => dispatch(FeatureFlags.creators.disableFeatureFlag(flag)),
        }
    }

    setFeatureFlag() {
        const {
            router: { query } = {},
            store: { dispatch } = {},
        } = this.props

        const toEnable = query?.enable_feature
        const toDisable = query?.disable_feature

        if (toEnable) {
            dispatch(FeatureFlags.creators.enableFeatureFlag(toEnable))
        }
        if (toDisable) {
            dispatch(FeatureFlags.creators.disableFeatureFlag(toDisable))
        }
    }

    render() {
        const { store, publisherId, header } = this.props

        const { PersistorGate } = this

        const headTitle = header?.title ? `${header?.title} | Hype` : 'Hype'

        return (
            <>
                <Head>
                    <title>{headTitle}</title>
                    <meta name="description" content={header?.description} />

                    <meta property="og:title" content={headTitle} />
                    <meta property="og:description" content={header?.description} />
                    <meta property="og:image" content={header?.coverPhotoImg} />
                    <meta property="og:url" content={`https://hype.co/@${header?.publisherUsername}`} />
                    <meta property="og:site_name" content="Hype" />
                    <meta property="og:type" content="website" />

                    <meta property="fb:app_id" content="1034930649922496" />

                    <meta name="twitter:title" content={headTitle} />
                    <meta property="twitter:description" content={header?.description} />
                    <meta name="twitter:image" content={header?.coverPhotoImg} />
                    <meta name="twitter:card" content="summary_large_image" />

                    <link id="favicon" rel="shortcut icon" type="image/png" href={header?.avatarImg} sizes="32x32" />
                    <link rel="apple-touch-icon" sizes="180x180" href={header?.avatarImg} />
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />


                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                    <script src="//cdn.iframe.ly/embed.js" async />


                </Head>
                <Provider store={store}>
                    <PicoAnalyticsProvider instance={getAnalytics({ publisherId })}>
                        <PersistorGate
                            loading={null}
                            persistor={this.persistor}
                        >
                            <Routes />
                            {/* <AppContainer
                            client={client}
                            publisher={publisher}
                            publisherId={publisherId}
                        >
                            <Component {...pageProps} client={client} publisherId={publisherId} />
                        </AppContainer> */}
                        </PersistorGate>
                    </PicoAnalyticsProvider>
                </Provider>
            </>

        )
    }
}

export default CustomApp
