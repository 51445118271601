import React from 'react'

export default ({ title = 'Stop Subscribing', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M16.936 18.361L12 13.425l-4.936 4.936a1.008 1.008 0 1 1-1.425-1.425L10.575 12 5.639 7.064A1.008 1.008 0 1 1 7.064 5.64L12 10.575l4.936-4.936a1.008 1.008 0 1 1 1.425 1.425L13.425 12l4.936 4.936a1.008 1.008 0 1 1-1.425 1.425z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Stop Subscribing"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M16.936 18.361L12 13.425l-4.936 4.936a1.008 1.008 0 1 1-1.425-1.425L10.575 12 5.639 7.064A1.008 1.008 0 1 1 7.064 5.64L12 10.575l4.936-4.936a1.008 1.008 0 1 1 1.425 1.425L13.425 12l4.936 4.936a1.008 1.008 0 1 1-1.425 1.425z"
            fill={color}
        />
    </svg>
)
