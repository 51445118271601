import React from 'react'

export default ({ title = 'Show', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M8.115 9.164C7.091 9.826 6.135 10.772 5.25 12c1.923 2.667 4.173 4 6.75 4s4.827-1.333 6.75-4c-.886-1.228-1.84-2.174-2.865-2.836.075.23.115.506.115.836a4 4 0 1 1-8 0c0-.33.04-.606.115-.836zM12 18c-3.314 0-6.314-2-9-6 2.686-4 5.686-6 9-6s6.314 2 9 6c-2.686 4-5.686 6-9 6zm-1-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Show Password"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M8.115 9.164C7.091 9.826 6.135 10.772 5.25 12c1.923 2.667 4.173 4 6.75 4s4.827-1.333 6.75-4c-.886-1.228-1.84-2.174-2.865-2.836.075.23.115.506.115.836a4 4 0 1 1-8 0c0-.33.04-.606.115-.836zM12 18c-3.314 0-6.314-2-9-6 2.686-4 5.686-6 9-6s6.314 2 9 6c-2.686 4-5.686 6-9 6zm-1-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            fill={color}
        />
    </svg>
)
