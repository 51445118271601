import Joi from 'joi'
import pageDataSchema from '../EventRegistry/eventSchemas/utils/pageDataschema.model'

const StandardPayloadFields = {
    user_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).required().description('Anonymous Id for guests, Hype User id when authenticated'),
    publisher_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).optional().description('Publisher user logged into their account from'),
    session_id: Joi.string().guid({ version: ['uuidv4'] }).required().description('Id of current session'),
    referrer: Joi.string().uri({ allowRelative: false }).allow(null, '').description('Origin of traffic'),
    locale: Joi.string().allow(null).description('Locale of user browser'),
    campaign: Joi.object().keys({
        content: Joi.string().default(null).description('Ad Campaign content'),
        medium: Joi.string().default(null).description('Ad Campaign medium'),
        name: Joi.string().default(null).description('Ad Campaign name'),
        source: Joi.string().default(null).description('Ad Campaign source'),
        term: Joi.string().default(null).description('Ad Campaign term'),
    }).required().description('UTM Info for active Ad Campaigns'),
    context: Joi.string().valid('signup', 'dashboard', 'landing_page', 'gadget').required().description('App event call originated from'),
    ...pageDataSchema,
    form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).optional().description('Id associated to the form (Offer) the user is on.'),
}

const StandardPayloadSchema = Joi.object()
    .keys(StandardPayloadFields)
    .description(`Standard Attributes are data that we pass and collect from storage
        that we aggregate and pass with every event call, regardless of the type or purpose.`)
    .meta({ name: 'StandardPayload', filename: 'standardPayload' })

export {
    StandardPayloadFields,
    StandardPayloadSchema
}
