import reducer from './reducer'
import middleware from './middleware'
import Menu from './components/Menu'
import {
    OPEN_MENU,
    CLOSE_MENU,
    TOGGLE_MENU,
    GO_TO_MENU_ROUTE,
    GO_TO_MENU_ROUTE_AND_OPEN,
    RESET
} from './actionTypes'

import {
    closeMenu,
    goToMenuRouteAndOpen,
    resetMenu,
    openMenu,
    toggleMenu,
    goToMenuRoute,
} from './actionCreators'

const createMenuMiddleWare = ({ }) => middleware({})

export {
    reducer as _menu,
    Menu,
    OPEN_MENU,
    CLOSE_MENU,
    TOGGLE_MENU,
    GO_TO_MENU_ROUTE,
    GO_TO_MENU_ROUTE_AND_OPEN,
    RESET,
    closeMenu,
    goToMenuRouteAndOpen,
    resetMenu,
    openMenu,
    toggleMenu,
    goToMenuRoute,
}

export default createMenuMiddleWare
