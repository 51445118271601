import React from 'react'

export default ({ title = 'brand/zapier' }) => (
    <svg
        height="64px"
        width="64px"
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="Brands"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <g id="brand/zapier">
                <polygon
                    id="bounds"
                    fill="#FF4A00"
                    points="0 0 64 0 64 64 0 64"
                />
                <path
                    id="Shape"
                    d="M37.25,32.009135 C37.249055,33.569645 36.963665,35.06348 36.443495,36.442655 C35.06474,36.96272 33.57017,37.248845 32.00903,37.25 L31.99097,37.25 C30.430565,37.248845 28.936415,36.96356 27.55766,36.4436 C27.03749,35.064635 26.75105,33.570065 26.75,32.009135 L26.75,31.99097 C26.75105,30.43046 27.03686,28.93652 27.55619,27.557555 C28.93568,27.03728 30.429935,26.750945 31.99097,26.75 L32.00903,26.75 C33.57017,26.750945 35.06474,27.03728 36.443495,27.557555 C36.963665,28.93652 37.249055,30.43046 37.25,31.99097 L37.25,32.009135 Z M52.7081,28.500035 L40.44977,28.500035 L49.11752,19.83218 C48.43628,18.875315 47.67671,17.97809 46.84826,17.149745 L46.84784,17.149325 C46.020125,16.32182 45.123635,15.56309 44.16782,14.88248 L35.499965,23.55023 L35.499965,11.292005 C34.365125,11.101535 33.200045,11.00063 32.01113,11 L31.988765,11 C30.799955,11.00063 29.634875,11.101535 28.500035,11.292005 L28.500035,23.550335 L19.83218,14.88248 C18.87584,15.563405 17.979035,16.322555 17.15111,17.15048 L17.148065,17.15342 C16.321085,17.980715 15.56288,18.876785 14.882585,19.83218 L23.55044,28.500035 L11.2919,28.500035 C11.2919,28.500035 11.00042,30.80237 11,31.992335 L11,32.00735 C11.00042,33.197525 11.10143,34.364075 11.292005,35.499965 L23.55023,35.499965 L14.882585,44.167715 C16.24475,46.0805 17.919395,47.755355 19.832285,49.11752 L28.500035,40.449875 L28.500035,52.707995 C29.633615,52.89815 30.79754,52.99916 31.98509,53 L32.01512,53 C33.202565,52.99916 34.366385,52.89815 35.499965,52.7081 L35.499965,40.449665 L44.167925,49.117415 C45.12395,48.4367 46.02044,47.677865 46.84826,46.850255 L46.85036,46.84826 C47.677865,46.02044 48.4367,45.123845 49.117415,44.167925 L40.449665,35.499965 L52.707995,35.499965 C52.89815,34.366385 52.99916,33.20246 53,32.01491 L53,31.98488 C52.99916,30.797435 52.89815,29.633615 52.7081,28.500035 L52.7081,28.500035 Z"
                    fill="#FFFFFF"
                />
            </g>
        </g>
    </svg>
)
