import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    article: new Duck.Selector(() => (state) => state?.article),
    getArticle: new Duck.Selector((selectors) => createSelector(
        selectors.article,
        ({ data } = {}) => data
    )),
    getAuthArticle: new Duck.Selector((selectors) => createSelector(
        selectors.article,
        ({ auth } = {}) => auth.data
    )),
    getGuestArticle: new Duck.Selector((selectors) => createSelector(
        selectors.article,
        ({ guest } = {}) => guest.data
    )),
})

export default selectors
