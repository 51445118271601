import React from 'react'

export default ({ width = '24', height = '24', className }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        role="status"
    >
        <path
            d="M12,0 C18.627417,0 24,5.372583 24,12 C24,14.258231 23.3762203,16.3707704 22.2915649,18.1747141 L20.5763803,17.1454684 C21.4802128,15.6422098 22,13.8818063 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,13.8822261 2.52001916,15.6429951 3.42422463,17.1464744 L1.70903973,18.1757196 C0.624011557,16.3715554 0,14.2586506 0,12 C0,5.372583 5.372583,0 12,0 Z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
)
