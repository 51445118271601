const actionCreatorFactory = (type) => ({ payload, error } = {}) => ({
    type,
    ...(payload && { payload }),
    ...(error && { error }),
})

const types = {
    CREATE_WIZARD: 'CREATE_WIZARD',
    CREATE_WIZARD_SUCCESS: 'CREATE_WIZARD_SUCCESS',
    CREATE_WIZARD_ERROR: 'CREATE_WIZARD_ERROR',

    DELETE_WIZARD: 'DELETE_WIZARD',
    DELETE_WIZARD_SUCCESS: 'DELETE_WIZARD_SUCCESS',
    DELETE_WIZARD_ERROR: 'DELETE_WIZARD_ERROR',

    DELETE_WIZARDS: 'DELETE_WIZARDS',
    DELETE_WIZARDS_SUCCESS: 'DELETE_WIZARDS_SUCCESS',
    DELETE_WIZARDS_ERROR: 'DELETE_WIZARDS_ERROR',

    NEXT_STEP: 'NEXT_STEP',
    NEXT_STEP_SUCCESS: 'NEXT_STEP_SUCCESS',
    NEXT_STEP_ERROR: 'NEXT_STEP_ERROR',

    SUBMIT_FORM: 'SUBMIT_FORM',
    SUBMIT_FORM_SUCCESS: 'SUBMIT_FORM_SUCCESS',
    SUBMIT_FORM_ERROR: 'SUBMIT_FORM_ERROR',

    SET_CURRENT_STEP: 'SET_CURRENT_STEP',
    SET_CURRENT_STEP_SUCCESS: 'SET_CURRENT_STEP_SUCCESS',
    SET_CURRENT_STEP_ERROR: 'SET_CURRENT_STEP_ERROR',

    UPDATE_PAYLOAD: 'UPDATE_PAYLOAD',
    UPDATE_PAYLOAD_SUCCESS: 'UPDATE_PAYLOAD_SUCCESS',
    UPDATE_PAYLOAD_ERROR: 'UPDATE_PAYLOAD_ERROR',

    GO_TO_STEP: 'GO_TO_STEP',
    GO_TO_STEP_SUCCESS: 'GO_TO_STEP_SUCCESS',
    GO_TO_STEP_ERROR: 'GO_TO_STEP_ERROR',
}

const creators = {
    createWizard: actionCreatorFactory(types.CREATE_WIZARD),
    deleteWizard: actionCreatorFactory(types.DELETE_WIZARD),
    deleteWizards: actionCreatorFactory(types.DELETE_WIZARDS),
    nextStep: actionCreatorFactory(types.NEXT_STEP),
    goToStep: actionCreatorFactory(types.GO_TO_STEP),
    submitForm: actionCreatorFactory(types.SUBMIT_FORM),
    setCurrentStep: actionCreatorFactory(types.SET_CURRENT_STEP),
    updatePayload: actionCreatorFactory(types.UPDATE_PAYLOAD),
}

export {
    types,
    creators,
}
