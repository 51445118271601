import Joi from 'joi'

const DashboardCardDeleted = Joi.object()
    .keys({
        card_id: Joi.string().allow(null).description('The id of the card'),
        customer_id: Joi.string().allow(null).description('The id of the customer'),
    })
    .description('dashboard:card:deleted event attributes ')
    .meta({ name: 'DashboardCardDeleted', filename: 'dashboard:card:deleted.model' })

export default DashboardCardDeleted
