import validateEvent from './validate'
import { StandardPayloadFields } from '../standardPayload/standardPayload.model'
import standardPayload from '../standardPayload'

/** This function is meant to act as a wrapper for all events that are fired. The purpose of
 * this function is to call the validator before actually sending any requests. If the validator
 * throws an error, then the payload passed is invalid and we do not send any information.
 *
 * @param name string Name of the event
 * @param {{
 *  context: string,
 *  publisherId: string,
 *  send: function,
 *  eventSchemas: object
 *  ...*
 * }} config Analytics config object which provides meta data
 * @param {object} instance The instance of analytics that has been initialized and has user metadata and client host data
 * @param {object} payload Event payload to validate
 * @returns {Promise}
 */

export default ({ name = null, config, instance, payload = {} }) => {
    const appContext = config?.context

    if (!appContext) {
        console.error('Warning: Must provide context - Event will not fire')
        return null
    }

    if (!name) {
        console.error('Warning: Must provide name - Event will not fire')
        return null
    }

    let validatedEventPayload, eventPayload
    try {
        const standard = standardPayload({ config, instance, payload })
        const eventSchema = config.eventSchemas?.[config.context]?.[name]

        if (!eventSchema) {
            throw new Error('Schema for event does not exist!')
        }

        eventPayload =  {
            ...standard,
            ...payload
        }


        validatedEventPayload = validateEvent(
            eventSchema.keys(StandardPayloadFields),
            eventPayload
        )
    } catch (e) {
        console.error(`
            Error: [${e}] \n
            Event ${name} will not fire \n
            Called with: ${JSON.stringify(eventPayload)}
        `)
        return null
    }

    if (!validatedEventPayload) {
        console.error('Error: Schemas cannot be validated - Event will not fire')
        return null
    }

    return config.send({
        type: `${config.context}:${name}`,
        ...validatedEventPayload
    })
}
