import React from 'react'
import cn from 'classnames'
import style from './scss/Account.module.scss'

const PasswordStrength = ({
    strength,
    score,
}) => (
    <div className={cn(style.passwordStrength, style.PasswordStrengthColor, style[`color_${score}`])}>
        {strength || 'Password Strength'}
    </div>
)

export default PasswordStrength
