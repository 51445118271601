import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './Field.module.scss'
import FormLabel from '../FormLabel'
import FormFieldError from '../FormFieldError'

const FormField = ({ children, description, label, input, required, className, meta, ...props }) => {
    return (
        <div className={cx(style.field, className)} {...props}>
            {label && (
                <FormLabel name={input?.name} className={cx(style.name)} required={required}>
                    {label}
                    {required && (
                        <span className={cx(style.required)}>*</span>
                    )}
                </FormLabel>
            )}
            {description &&
                <span>{description}</span>
            }
            {children}
            <FormFieldError name={input?.name} meta={meta} />
        </div>
    )
}

export const FormFieldInputPropTypes = {
    name: PropTypes.string
}

export const FormFieldPropTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

FormField.propTypes = {
    ...FormFieldPropTypes,
    children: PropTypes.node,
    input: PropTypes.shape(FormFieldInputPropTypes).isRequired,
    label: PropTypes.node,
    meta: PropTypes.object,
    required: PropTypes.bool,
}

FormField.defaultProps = {
    meta: {}
}

export default FormField
