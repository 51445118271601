import React from 'react'
import PropTypes from 'prop-types'
const Gift = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_gift">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M15,17 L15,25 L9,25 C8.44771525,25 8,24.5522847 8,24 L8,17 L15,17 Z M24,17 L24,24 C24,24.5522847 23.5522847,25 23,25 L17,25 L17,17 L24,17 Z M9.35714286,12 C8.82142857,11.5 8.5,10.8 8.5,10 C8.5,8.3 9.89285714,7 11.7142857,7 C12.7857143,7 14.7142857,7.9 16,9.1 C17.2857143,7.9 19.2142857,7 20.2857143,7 C22.1071429,7 23.5,8.3 23.5,10 C23.5,10.8 23.1785714,11.5 22.6428571,12 L22.6428571,12 L24,12 C24.5522847,12 25,12.4477153 25,13 L25,15 L17,15 L17,13 L15,13 L15,15 L7,15 L7,13 C7,12.4477153 7.44771525,12 8,12 Z M11.9615385,9 C11.3846154,9 11,9.4 11,10 C11,10.6 11.3846154,11 11.9615385,11 C11.9615385,11 14.4615385,10.9 14.75,10.8 C14.2692308,10.2 12.5384615,9 11.9615385,9 Z M20.0384615,9 C19.4615385,9 17.8269231,10.1 17.25,10.8 C17.5384615,10.9 20.0384615,11 20.0384615,11 C20.6153846,11 21,10.6 21,10 C21,9.4 20.6153846,9 20.0384615,9 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                />
            </g>
        </g>
    </svg>
)

Gift.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Gift
