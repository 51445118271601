import Joi from 'joi'
import signUpMethods from '../utils/authMethods'

const GadgetAccountCreate = Joi.object()
    .keys({
        auth_method: Joi.string().valid(...signUpMethods).required().description('The auth method used for signing up'),
        email: Joi.string().email({ tlds: { allow: false } }).required().allow(null).description('End user email'),
    })
    .description('This event is similar to gadget:user_login, with the exception that this only fires the first time the user ever authenticates.')
    .meta({ name: 'GadgetAccountCreate', filename: 'gadget:account_create.model' })

export default GadgetAccountCreate
