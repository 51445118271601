import { put, select } from 'redux-saga/effects'
// import Router from 'lib/RouterAdapterDecorator'
import Router from 'lib/RouterAdapterDecorator'

import { types } from '../actions'
import logger from 'lib/logger'

function* nextStepSaga({
    payload: { id, short_code },
}) {
    try {
        const state = yield select()
        const { currentStep, steps } = state.wizard.wizards[id]
        const nextStep = currentStep + 1
        const { url, as, method = 'push' } = steps[nextStep]

        Router[method]({
            pathname: url,
            query: {
                id,
                ...(short_code && { short_code }),
            },
        }, {
            pathname: as,
            query: {
                id,
                ...(short_code && { short_code }),
            },
        })

        yield put({
            type: types.NEXT_STEP_SUCCESS,
            payload: {
                nextStep,
                id,
            },
        })
    } catch (error) {
        logger.error('nextStepSaga', {}, error)
        yield put({
            type: types.NEXT_STEP_ERROR,
            error,
        })
    }
}

export default nextStepSaga
