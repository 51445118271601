import React from 'react'

export default ({ title = 'Gift a Subscription', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="16px"
        icon="M2.686 4C2.257 3.6 2 3.04 2 2.4 2 1.04 3.114 0 4.571 0 5.43 0 6.971.72 8 1.68 9.029.72 10.571 0 11.429 0 12.886 0 14 1.04 14 2.4c0 .64-.257 1.2-.686 1.6H15a1 1 0 011 1v1H9V4H7v2H0V5a1 1 0 011-1h1.686zM1 8h6v7H2a1 1 0 01-1-1V8zm8 0h6v6a1 1 0 01-1 1H9V8zM4.77 3.2s2-.08 2.23-.16C6.615 2.56 5.23 1.6 4.77 1.6c-.462 0-.77.32-.77.8 0 .48.308.8.77.8zm6.46 0c.462 0 .77-.32.77-.8 0-.48-.308-.8-.77-.8-.46 0-1.768.88-2.23 1.44.23.08 2.23.16 2.23.16z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Gift a Subscription"
        width="16px"
        viewBox="0 0 16 16"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M2.686 4C2.257 3.6 2 3.04 2 2.4 2 1.04 3.114 0 4.571 0 5.43 0 6.971.72 8 1.68 9.029.72 10.571 0 11.429 0 12.886 0 14 1.04 14 2.4c0 .64-.257 1.2-.686 1.6H15a1 1 0 011 1v1H9V4H7v2H0V5a1 1 0 011-1h1.686zM1 8h6v7H2a1 1 0 01-1-1V8zm8 0h6v6a1 1 0 01-1 1H9V8zM4.77 3.2s2-.08 2.23-.16C6.615 2.56 5.23 1.6 4.77 1.6c-.462 0-.77.32-.77.8 0 .48.308.8.77.8zm6.46 0c.462 0 .77-.32.77-.8 0-.48-.308-.8-.77-.8-.46 0-1.768.88-2.23 1.44.23.08 2.23.16 2.23.16z"
            fill={color}
        />
    </svg>
)
