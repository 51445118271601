import Joi from 'joi'

const GadgetUserLogin = Joi.object()
    .keys({
        email: Joi.string().email({ tlds: { allow: false } }).allow(null).description('End user email'),
        phone: Joi.string().allow(null).optional().description('End user phone number if they authed with SMS'),
        new_account: Joi.boolean().required().description('Is user logging in for very first time'),
        paying: Joi.boolean().required().description('Is user a paying customer'),
        has_password: Joi.boolean().required().description('Is the user logging in with a password?'),
        otp_verified: Joi.boolean().required().description('Is the user logging in via OTP?')
    })
    .description('This event fires when a user authenticates. This can be through email or phone, as well as using a social account to OAuath with.')
    .meta({ name: 'GadgetUserLogin', filename: 'gadget:user_login.model' })

export default GadgetUserLogin
