import { colors } from 'piconetworks/pkg-functions'
import PropertyType from "../Modifiers/PropertyType"

const ThemeBlockBackgroundValue = new PropertyType(
    {
        name: 'BlockBackgroundValue',
        propertyFromApi: 'block-background-value',
        convertValueToCssPropertiesFunction: (value: string) =>{
            // console.log('theme value', value)
            return `background-color: ${value}; backdrop-filter: blur(8px);`
        },
        selector: '.block',
        defaultValue: '#FFFFFF',
        cssVariable: '--block-background-color',
        cssVariableFunction: (value: string) => value,
        convertToRgb: true,
    },
    'theme'
)

const FormBlockBackgroundValue = new PropertyType(
    {
        name: 'BlockBackgroundValue',
        propertyFromApi: 'block-background-value',
        convertValueToCssPropertiesFunction: (value: string) =>{
            // console.log('form value', value)
            return `background-color: ${value}; backdrop-filter: blur(8px);`
        },
        selector: '.block',
        defaultValue: '#FFFFFF',
        cssVariable: '--block-background-color',
        cssVariableFunction: (value: string) => value,
        convertToRgb: true,
    },
    'form'
)

export {
    ThemeBlockBackgroundValue,
    FormBlockBackgroundValue,
}
