import generateAction from 'piconetworks/pkg-generate-action'

const getNewsletters = generateAction({
    baseActionCreatorName: 'getNewsletters',
    baseActionTypeName: 'GET_NEWSLETTERS',
})

const types = [
    ...getNewsletters.types,
]

const creators = (duck) => ({
    ...getNewsletters.creators(duck),
})

export {
    types,
    creators,
}
