export const REQUEST = 'network/REQUEST'
export const REQUEST_START = 'network/REQUEST/start'
export const REQUEST_SUCCESS = 'network/REQUEST/success'
export const REQUEST_ERROR = 'network/REQUEST/error'

export const SET_SCOPE = 'network/SET_SCOPE'

export const GET_TOKEN = 'network/GET_TOKEN'
export const GET_TOKEN_START = 'network/GET_TOKEN/start'
export const GET_TOKEN_SUCCESS = 'network/GET_TOKEN/success'
export const GET_TOKEN_ERROR = 'network/GET_TOKEN/error'

export const REFRESH_TOKEN = 'network/REFRESH_TOKEN'
export const REFRESH_TOKEN_START = 'network/REFRESH_TOKEN/start'
export const REFRESH_TOKEN_SUCCESS = 'network/REFRESH_TOKEN/success'
export const REFRESH_TOKEN_ERROR = 'network/REFRESH_TOKEN/error'

export const STORE_TOKENS = 'network/STORE_TOKENS'
export const STORE_TOKENS_START = 'network/STORE_TOKENS/start'
export const STORE_TOKENS_SUCCESS = 'network/STORE_TOKENS/success'
export const STORE_TOKENS_ERROR = 'network/STORE_TOKENS/error'

export const REMOVE_TOKENS = 'network/REMOVE_TOKENS'
export const REMOVE_TOKENS_START = 'network/REMOVE_TOKENS/start'
export const REMOVE_TOKENS_SUCCESS = 'network/REMOVE_TOKENS/success'
export const REMOVE_TOKENS_ERROR = 'network/REMOVE_TOKENS/error'
