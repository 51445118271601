import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    forms: new Duck.Selector(() => (state) => state?.forms),
    getLandingPage: new Duck.Selector((selectors) => createSelector(
        selectors.forms,
        ({ landingPage } = {}) => landingPage.data
    )),
    getPopup: new Duck.Selector((selectors) => createSelector(
        selectors.forms,
        ({ popup } = {}) => popup.data
    ))
})

export default selectors
