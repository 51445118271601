import User from './user'
import Edit from './edit'
import Trash from './trash'
import SwitchPlan from './switchPlan'
import PaymentMethod from './paymentMethod'
import Close from './close'
import Back from './back'
import Keep from './keep'
import Gift from './gift'
import Eye from './eye'
import EyeCrossed from './eyeCrossed'
import Down from './down'
import Up from './up'
import Bank from './bank'
import AddPayment from './addPayment'

const CustomizableIcons = {
    User,
    Edit,
    Trash,
    SwitchPlan,
    PaymentMethod,
    Close,
    Back,
    Keep,
    Gift,
    Eye,
    EyeCrossed,
    Down,
    Up,
    Bank,
    AddPayment
}

export default CustomizableIcons