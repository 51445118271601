import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Grid from '../Grid'
import { GridColPropTypes } from '../Grid/GridCol.js'
import styles from './GridPane.module.scss'

const GridPaneChild = ({
    children,
    gridCol,
    withShadow,
    ...props
}) => {
    return (
        <Grid.Col
            className={cx(
                styles.gridPaneChild,
                withShadow && styles.withShadow
            )}
            {...gridCol}
            {...props}
        >
            {children}
        </Grid.Col>
    )
}

GridPaneChild.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gridCol: PropTypes.shape({...GridColPropTypes}),
    withShadow: PropTypes.bool
}

GridPaneChild.defaultProps = {
    withShadow: true
}

export default GridPaneChild
