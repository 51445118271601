import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import OtpInput from 'react-otp-input'

import { Small } from 'piconetworks/pkg-text'

import style from './OneTimePasscode.module.scss'

const OneTimePasscode = ({
    className,
    currentPasscode,
    errorText,
    isDisabled,
    isErrored,
    isInputNum,
    numInputs,
    setCurrentPasscode,
    shouldAutoFocus,
    ...props
}) => {
    const handleChange = (otp) => setCurrentPasscode(otp)

    return (
        <>
            <OtpInput
                containerStyle={cx(style.otpContainer, className)}
                value={currentPasscode}
                onChange={handleChange}
                numInputs={numInputs}
                inputStyle={{ width: '3.25rem' }}
                errorStyle={style.errorStyle}
                disabledStyle={style.disabledStyle}
                hasErrored={isErrored}
                isDisabled={isDisabled}
                isInputNum={isInputNum}
                shouldAutoFocus={shouldAutoFocus}
                placeholder={''.repeat(numInputs)}
                {...props}
            />
            {isErrored && (
                <Small className={style.danger}>{errorText}</Small>
            )}
        </>
    )
}

OneTimePasscode.defaultProps = {
    errorText: 'Invalid code',
    isDisabled: false,
    isErrored: false,
    isInputNum: false,
    numInputs: 6,
    shouldAutoFocus: true
}

OneTimePasscode.propTypes = {
    /** Style applied or class passed to container of inputs. */
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** value of the passcode */
    currentPasscode: PropTypes.string,
    /** error text below */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Disables all the inputs. */
    isDisabled: PropTypes.bool,
    /** Indicates there is an error in the inputs. */
    isErrored: PropTypes.bool,
    /** Restrict input to only numbers. */
    isInputNum: PropTypes.bool,
    /** Number of OTP inputs to be rendered. */
    numInputs: PropTypes.number,
    /** currentPasscode setter */
    setCurrentPasscode: PropTypes.func,
    /** Auto focuses input on initial page load. */
    shouldAutoFocus: PropTypes.bool
}

export default OneTimePasscode
