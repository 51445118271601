import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import ScriptTag from 'react-script-tag'

import { getSvgImage } from 'piconetworks/pkg-functions'

import Form from 'piconetworks/pkg-form'
import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'
import { PasswordMeter, PasswordStrength } from 'piconetworks/pkg-account'
import Boutton from 'piconetworks/pkg-boutton'

import cx from 'classnames'

import parentStyles from './scss/MenuRoutes.module.scss'
import style from './scss/EditProfile.module.scss'

// with multiple user states
// const disclaimerText = 'This password can be used to access any of the profiles associated with your Hype ID.'

const PasswordEditor = ({
    linkColor,
    changeMenuRoute,
    userHasPassword,
    submitError,
    values = {},
    userHasMultipleState,
}) => {
    const [passwordScore, setPasswordScore] = useState({})
    const [showPassword, setShowPassword] = useState({
        new: false,
        confirm: false,
    })

    const { password = '', confirm_password = '' } = values

    const isPasswordsEqual = (password && confirm_password)
        ? password === confirm_password
        : true

    const strengthConverter = (score) => {
        switch (score) {
            case 0:
                return 'Very Weak'
            case 1:
                return 'Weak'
            case 2:
                return 'Average'
            case 3:
                return 'Strong'
            case 4:
                return 'Very Strong'
            default:
                return ''
        }
    }

    const onPasswordChange = ({ target }) => {
        if (window && window?.zxcvbn) {
            const { score, feedback = {} } = window.zxcvbn(target.value) || {}
            const { warning } = feedback

            setPasswordScore({
                score,
                strength: strengthConverter(score),
                warning,
            })
        }
    }

    const showPasswordIcon = (type = 'new') => {
        const onClick = () => {
            setShowPassword({
                ...showPassword,
                [type]: !showPassword[type],
            })
        }

        return (
            <div
                className={style.showPassword}
                onClick={onClick}
            >
                {getSvgImage(
                    !!(type === 'confirm' && !isPasswordsEqual)
                        ? '#ff4440'
                        : '#000',
                    showPassword[type]
                        ? 'eye-icon'
                        : 'eye-icon-crossed'
                )}
            </div>
        )
    }

    return (
        <div className={style.passwordContainer}>
            <Field
                name='password'
            >
                {({ input, meta }) => (
                    <div className={style.passwordContainer_input}>
                        {showPasswordIcon('new')}
                        <Form.Input
                            meta={meta}
                            label='New Password'
                            className={parentStyles.formInput}
                            type={showPassword?.new ? 'text' : 'password'}
                            placeholder='●●●●●●'
                            themeColor={linkColor}
                            input={{
                                type: showPassword?.new ? 'text' : 'password',
                                onChange: (e) => {
                                    onPasswordChange(e)
                                    input.onChange(e)
                                },
                            }}
                        />
                    </div>
                )}
            </Field>
            <div className={style.strength}>
                <PasswordMeter
                    score={passwordScore?.score && passwordScore.score}
                    minScore={0}
                    maxScore={4}
                />
                <PasswordStrength
                    strength={passwordScore?.strength}
                    score={passwordScore?.score && passwordScore.score}
                />
            </div>
            <Field
                name='confirm_password'
            >
                {({ input, meta }) => (
                    <div className={style.passwordContainer_input}>
                        {showPasswordIcon('confirm')}
                        <Form.Input
                            meta={meta}
                            label='Confirm New Password'
                            className={cx(parentStyles.formInput, {
                                [style.passwordContainer_input_error]: !isPasswordsEqual,
                            })}
                            type={showPassword?.confirm ? 'text' : 'password'}
                            themeColor={isPasswordsEqual ? linkColor : '#FF4440'}
                            input={{
                                ...input,
                                type: showPassword?.confirm ? 'text' : 'password',
                            }}
                        />
                    </div>
                )}
            </Field>
            {submitError && submitError !== 'success' &&
                <div className={parentStyles.error}>{submitError}</div>
            }
            <div className={parentStyles.approveButtonGroup}>
                <Boutton
                    className={parentStyles.cancel}
                    onClick={() => { changeMenuRoute('/editProfile') }}
                    variant="link"
                >
                    Cancel
                </Boutton>
                <Boutton
                    type='submit'
                    variant='primary'
                    disabled={
                        !passwordScore.score
                        || (passwordScore?.score && passwordScore.score < 2)
                        || !isPasswordsEqual
                        || !password
                        || !confirm_password
                    }
                >
                    {userHasPassword ? 'Update' : 'Create'}
                </Boutton>
            </div>
            <div className={style.description}>
                {userHasMultipleState && (
                    <p
                        className={
                            style.disclaimer
                        }
                    >
                        <b>NOTE:</b> This password can be used to access any of the profiles associated with your Hype ID.
                    </p>
                )}
            </div>
        </div>
    )
}

const EditProfileEditPassword = ({
    publisherId,
    changeMenuRoute,
    userLoader,
    guestLoader,
    utmRootUrl,
    queryOverride,
    userHasPassword,
    WizardRedux,
    update,
    updateLoaderUser,
    onPasswordUpdateSuccess,
    userHasMultipleState,
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
        user = {},
    } = scopedLoader

    // user should not be able to create/update unless they
    // have verified their email if they have email on their account
    // this works if user first signs up with sms
    // and then adds an email because to add an email after sms
    // you need to go through OTP flow
    useEffect(() => {
        if (user?.email && !user?.verified) {
            changeMenuRoute('/editProfile')
        }
    }, [scopedLoader])

    return (
        <MenuWrapper
            publisherName={publisherName}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
        >
            <ScriptTag
                type='text/javascript'
                src='https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js'
                async
            />
            <div className={parentStyles.menuHeader}>
                <div
                    className={parentStyles.backIcon}
                    onClick={() => { changeMenuRoute('/editProfile') }}
                    parentStyles={{ color: linkColor }}
                >
                    <Picon
                        color={linkColor}
                        icon='arrow-left'
                        size='24'
                    />
                </div>
                <h3>{userHasPassword ? 'Edit' : 'Create'} Password</h3>
            </div>
            <div className={parentStyles.container}>
                <WizardRedux.Step
                    validate={() => {
                        const errors = {}
                        return errors
                    }}
                    onSubmit={({ values }) => {
                        try {
                            update({
                                updateLoaderUser,
                                publisherId,
                                ...(values.password && { password: values.password }),
                            })

                            onPasswordUpdateSuccess({
                                userHasPassword,
                                values
                            })
                            return {}
                        } catch (e) {
                            throw new Error('Error updating password.')
                        }
                    }}
                    render={({
                        handleSubmit,
                        submitError,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <PasswordEditor
                                userHasMultipleState={userHasMultipleState}
                                linkColor={linkColor}
                                changeMenuRoute={changeMenuRoute}
                                userHasPassword={userHasPassword}
                                submitError={submitError}
                                values={values}
                            />
                        </form>
                    )}
                />
            </div>
        </MenuWrapper>
    )
}

EditProfileEditPassword.defaultProps = {
    publisherId: null,
}

PasswordEditor.propTypes = {
    changeMenuRoute: PropTypes.func,
    linkColor: PropTypes.string,
    submitError: PropTypes.any,
    userHasPassword: PropTypes.bool,
    values: PropTypes.object,
}
EditProfileEditPassword.propTypes = {
    account: PropTypes.object,
    changeMenuRoute: PropTypes.func.isRequired,
    guestLoader: PropTypes.any.isRequired,
    onPasswordUpdateSuccess: PropTypes.func,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    update: PropTypes.func,
    updateLoaderUser: PropTypes.func,
    userHasMultipleState: PropTypes.bool,
    userHasPassword: PropTypes.bool,
    userLoader: PropTypes.any.isRequired,
    utmRootUrl: PropTypes.string,
    WizardRedux: PropTypes.object,
}

export default EditProfileEditPassword
