import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_GIFT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* createGift({
    types,
    payload
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: PURCHASE_GIFT(),
            method: 'post',
            data: payload
        })

        if (data) {
            yield put({
                type: types.CREATE_GIFT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to create gift')
        }
    } catch (error) {
        yield put({
            type: types.CREATE_GIFT_ERROR,
            error: error?.message || 'CREATE_GIFT_ERROR',
        })
    }
}

export default createGift
