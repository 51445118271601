import generateAction from 'piconetworks/pkg-generate-action'

const logoutAndExchangeClaim = generateAction({
    baseActionCreatorName: 'logoutAndExchangeClaim',
    baseActionTypeName: 'LOGOUT_AND_EXCHANGE_CLAIM',
})

const setVerificationInfo =generateAction({
    baseActionCreatorName: 'setVerificationInfo',
    baseActionTypeName: 'SET_VERIFICATION_INFO',
})

const verifyEmailOrPhone = generateAction({
    baseActionCreatorName: 'verifyEmailOrPhone',
    baseActionTypeName: 'VERIFY_EMAIL_OR_PHONE',
})


const types = [
    ...logoutAndExchangeClaim.types,
    ...verifyEmailOrPhone.types,
    ...setVerificationInfo.types
]

const creators = (duck, payload) => ({
    ...logoutAndExchangeClaim.creators(duck),
    ...verifyEmailOrPhone.creators(duck),
    ...setVerificationInfo.creators(duck, payload)
})

export {
    types,
    creators,
}
