import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    plan: new Duck.Selector(() => (state) => state?.plan),
    isVerifyingPayment: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({ isVerifyingPayment }) => isVerifyingPayment,
    )),
    isVerifyingPaymentSucess: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({ isVerifyingPaymentSucess }) => isVerifyingPaymentSucess,
    )),
    isVerifyingPaymentError: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({ isVerifyingPaymentError }) => isVerifyingPaymentError,
    )),
    isVerifyingPaymentErrorMessage: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({ isVerifyingPaymentErrorMessage }) => isVerifyingPaymentErrorMessage,
    )),
    isOneTimeDonationSuccess: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({ oneTimeDonationSuccess }) => oneTimeDonationSuccess,
    )),
    switchNow: new Duck.Selector((selectors) => createSelector(
        selectors.plan,
        ({
            canSwitchPlanNow,
            prorate_amount,
            next_price,
            end_date,
        }) => canSwitchPlanNow && ({
            canSwitchPlanNow,
            prorate_amount,
            next_price,
            end_date,
        }),
    )),
})

export default selectors

