import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MenuWrapper from './components/MenuWrapper'
import { colors } from 'piconetworks/pkg-functions'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const NotificationMenuPage = (props) => {
    const {
        backRoute,
        changeMenuRoute,
        currentProductOptionId,
        currentSubscriptionId,
        linkColor,
        memberNoun,
        memberType,
        publisherId,
        publisherName,
        data: {
            nextDate = '',
        } = {},
        keepPlan,
        utmRootUrl,
        queryOverride
    } = props

    const KeepButton = ({ children }) => (
        <strong
            className={style.hoverCursorPointer}
            onClick={() => {
                keepPlan({
                    productOptionId: currentProductOptionId,
                    publisherId,
                    subscriptionId: currentSubscriptionId,
                })
            }}
            style={{
                color: linkColor,
                textDecoration: 'underline',
            }}
        >
            {children}
        </strong>
    )

    const clickToKeep = () => (
        <>
            <KeepButton>Click here</KeepButton>
            {' '}
            to keep your
            {' '}
            {memberNoun}
            {' '}
            instead.
        </>
    )

    const descriptionText = (memberType === 'donor')
        ? (
            <span>
                We&#39;re disappointed to see you end your support of great content on
                {' '}
                {publisherName}
                . If you&#39;ve changed your mind, please
                {' '}
                <KeepButton>click here</KeepButton>
                .
            </span>
        )
        : (
            <span>
                You are now set to lose your current plan benefits on
                {' '}
                {nextDate}
                .  {' '}
                {currentProductOptionId && clickToKeep()}
            </span>
        )

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride} hideBackground>
            <div className={classNames(style.menuHeader, style.shadow, style.notificationHeader)}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute(!backRoute ? '/' : backRoute) }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Notifications</h3>
            </div>
            <div className={classNames(style.background)} style={{ backgroundColor: colors.getBgColor(linkColor, 0.15) }}>
                <div className={style.notification}>
                    {descriptionText}
                </div>
            </div>
        </MenuWrapper>
    )
}

NotificationMenuPage.defaultProps = {

    publisherId: null,
}

NotificationMenuPage.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
}

export default NotificationMenuPage
