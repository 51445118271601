import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Scrollable.module.scss'

const ScrollableTemplate = ({ children, styleClass, className, ...props }) => {
    return (
        <div className={cx(className, styles[styleClass])} {...props}>
            {children}
        </div>
    )
}

ScrollableTemplate.propTypes = {
    children: PropTypes.node,
    styleClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default ScrollableTemplate
