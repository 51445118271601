import {
    Applemusic,
    Applepodcasts,
    Cashapp,
    Discord,
    Email,
    Etsy,
    Facebook,
    Github,
    Google,
    Instagram,
    Linkedin,
    Pinterest,
    Snapchat,
    Spotify,
    Threads,
    Tiktok,
    Twitch,
    Twitter,
    Venmo,
    Website,
    Whatsapp,
    Youtube
} from './socialIcons'

const Social = {
    Applemusic,
    Applepodcasts,
    Cashapp,
    Discord,
    Email,
    Etsy,
    Facebook,
    Github,
    Google,
    Instagram,
    Linkedin,
    Pinterest,
    Snapchat,
    Spotify,
    Threads,
    Tiktok,
    Twitch,
    Twitter,
    Venmo,
    Website,
    Whatsapp,
    Youtube
}

export default Social

