import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { H3 } from 'piconetworks/pkg-heading'
import { P } from 'piconetworks/pkg-text'
import LandingWizardArrow from 'piconetworks/pkg-landing-wizard-arrow'

import styles from './scss/WizardSlider.module.scss'

const WizardSlider = ({
    children,
    title = null,
    description = null,
    subtitle = null,
    wizardHeaderClass = null,
    wizardContentClass = null,
    onBackButtonClick = null,
    linkColor = null,
    backButton,
    renderBackButton = true,
    markDown = null 
}) => {

    const header = !!(title || backButton || (renderBackButton && onBackButtonClick))
    return (
        <div className={styles.wizard}>
            {header && (
                <div className={classNames(
                    styles.wizardHeader, 
                    wizardHeaderClass
                )}>
                    {backButton ? backButton : renderBackButton && onBackButtonClick && (
                        <LandingWizardArrow
                            buttonType="button"
                            onClick={onBackButtonClick}
                            className={classNames(styles.backButton, {[styles.noTitle]: !title})}
                            style={{ color: linkColor }}
                            iconType="left"
                        />
                    )}
                    {title && <H3>{title}{subtitle && <small>{subtitle}</small>}</H3>}
                </div>
            )}
            
            <div className={classNames(styles.wizardContentWrapper, {[styles.margin]: !header})}>
                {description && <div className={styles.description}><P>{description}</P></div>}
                {markDown}
                <div
                    className={classNames(
                        styles.wizardContent,
                        wizardContentClass,
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

WizardSlider.propTypes = {
    backButton: PropTypes.element,
    markDown: PropTypes.element,
    children: PropTypes.element,
    description: PropTypes.string,
    subtitle: PropTypes.string,
    linkColor: PropTypes.string,
    onBackButtonClick: PropTypes.func,
    renderBackButton: PropTypes.bool,
    title: PropTypes.string,
    wizardHeaderClass: PropTypes.string,
    wizardContentClass: PropTypes.string
}

export default WizardSlider
