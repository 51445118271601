import React from 'react'

export default ({ title = 'Switch Plan', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M4.74 13.358a1 1 0 0 1 1.86-.405l1.438 2.49 5.687-3.284a1 1 0 0 1 1 1.732l-5.687 3.284 1.437 2.489a1 1 0 0 1-1.28 1.41l-4.33-1.975a1 1 0 0 1-.58-1.005l.454-4.736zM19.136 4.9a1 1 0 0 1 .58 1.005l-.455 4.736a1 1 0 0 1-1.861.405l-1.437-2.49-5.689 3.285a1 1 0 0 1-1-1.732l5.689-3.285-1.437-2.489a1 1 0 0 1 1.28-1.41l4.33 1.975z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Switch Plan"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M4.74 13.358a1 1 0 0 1 1.86-.405l1.438 2.49 5.687-3.284a1 1 0 0 1 1 1.732l-5.687 3.284 1.437 2.489a1 1 0 0 1-1.28 1.41l-4.33-1.975a1 1 0 0 1-.58-1.005l.454-4.736zM19.136 4.9a1 1 0 0 1 .58 1.005l-.455 4.736a1 1 0 0 1-1.861.405l-1.437-2.49-5.689 3.285a1 1 0 0 1-1-1.732l5.689-3.285-1.437-2.489a1 1 0 0 1 1.28-1.41l4.33 1.975z"
            fill={color}
        />
    </svg>
)
