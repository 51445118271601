import axios from 'axios'

import {
    LANG_URL,
} from 'piconetworks/pkg-endpoints'

import getLoader from './getLoader'
import removeLoader from './removeLoader'

export const fetchLang = async ({ url }) => {
    const response = await axios({
        method: 'get',
        baseURL: LANG_URL(),
        timeout: 30000,
        url,
    })

    if (!response.data) {
        throw Error('no data in response')
    }

    return response.data
}

const sagas = (duck) => ({
    * getLoader(payload) {
        yield getLoader({
            ...payload,
            ...duck
        })
    },
    * removeLoader(payload) {
        yield removeLoader({
            ...payload,
            ...duck
        })
    },
})

export default sagas
