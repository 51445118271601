import Joi from 'joi'

const GadgetPromptShown = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).required().allow(null).description('The id of the landing page in Hype Forms table'),
        prompt_type: Joi.string().valid('payment', 'signup').required().description('Whether the prompt was for signing up or payment')
    })
    .description('gadget:prompt_shown event attributes ')
    .meta({ name: 'GadgetPromptShown', filename: 'gadget:prompt_shown.model' })

export default GadgetPromptShown
