import { Client } from 'piconetworks/pkg-module-client'

import createDuck from '../baseDuck'

export default Client({
    createDuck,
})


// import createDuck from '../baseDuck'

// import {
//     creators,
//     types,
// } from './actions'

// import reducer, {
//     initialState,
// } from './reducer'

// import sagas from './sagas'
// import takes from './takes'
// import selectors from './selectors'

// const store = 'client'

// export default createDuck({
//     store,
//     initialState,
// }).extend({
//     creators,
//     types,
//     reducer,
//     sagas,
//     takes,
//     selectors,
// })

// export {
//     store,
//     creators,
//     types,
//     reducer,
//     initialState,
//     sagas,
//     takes,
// }
