import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import { currencies } from 'piconetworks/pkg-currency'

import FormInput from '../FormInput'

const FormFormatInput = React.forwardRef(
    (
        {
            currencyCode,
            symbol,
            suffix,
            formatType,
            inputFormat,
            inputPrefix,
            prefix,
            ...props
        },
        ref
    ) => {
        const currency = currencies[currencyCode] || 'USD'
        const formatTypes = {
            currency: {
                thousandSeparator: ',',
                prefix: symbol ? currency.symbol_native : '',
                suffix: suffix,
                decimalScale: currency.decimal_digits || 0,
                fixedDecimalScale: true,
                allowNegative: false
            }
        }
        return (
            <NumberFormat
                getInputRef={ref}
                customInput={FormInput}
                autoComplete="off"
                label="Pricing Input"
                onBlur={props.input.onBlur}
                onFocus={props.input.onFocus}
                value={props.input.value}
                onChange={(value) => {
                    props.input.onChange(value)
                }}
                onValueChange={({ formattedValue }) => {
                    props.input.onChange(formattedValue)
                }}
                format={inputFormat}
                {...formatTypes[formatType]}
                {...props}
                prefixValue={inputPrefix}
            />
        )
    }
)

FormFormatInput.displayName = 'FormFormatInput'

FormFormatInput.propTypes = {
    currencyCode: PropTypes.string,
    formatType: PropTypes.string,
    prefix: PropTypes.bool,
    input: PropTypes.object,
    inputPrefix: PropTypes.string,
    inputFormat: PropTypes.string,
    suffix: PropTypes.string,
    symbol: PropTypes.bool
}

FormFormatInput.defaultProps = {
    currencyCode: 'USD',
    formatType: 'currency',
    symbol: false
}

export default FormFormatInput
