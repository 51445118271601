import React from 'react'

export default ({ title = 'party_popper' }) => (
    <svg
        height="58px"
        width="60px"
        version="1.1"
        viewBox="0 0 60 58"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="party_popper"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <polygon
                id="Shape"
                fill="#F7B600"
                fillRule="nonzero"
                points="0 58 8.6 55 2.1 52"
            />
            <polygon
                id="Shape"
                fill="#FFDD7D"
                fillRule="nonzero"
                points="24.9 33.4 12.8 21.2 10.8 26.8"
            />
            <polygon
                id="Shape"
                fill="#F7B600"
                fillRule="nonzero"
                points="10.8 26.8 8.6 33.1 35.4 45.6 36.7 45.2 24.9 33.4"
            />
            <polygon
                id="Shape"
                fill="#FFDD7D"
                fillRule="nonzero"
                points="6.5 39.4 26.5 48.7 35.4 45.6 8.6 33.1"
            />
            <polygon
                id="Shape"
                fill="#F7B600"
                fillRule="nonzero"
                points="4.3 45.7 17.5 51.9 26.5 48.7 6.5 39.4"
            />
            <polygon
                id="Shape"
                fill="#FFDD7D"
                fillRule="nonzero"
                points="4.3 45.7 2.1 52 8.6 55 17.5 51.9"
            />
            <path
                id="Shape"
                d="M29.9,28.2 C36.6,34.8 40.1,42.2 37.7,44.6 C35.2,47 27.8,43.6 21,36.9 C14.3,30.3 10.8,22.9 13.2,20.5 C15.7,18.1 23.1,21.6 29.9,28.2"
                fill="#493816"
                fillRule="nonzero"
            />
            <path
                id="Shape"
                d="M21.5,11.5 C19.9,9.2 21.6,8.2 23.8,8.6 C21.7,6.1 23,4.3 26.3,5 C27.3,5.2 25.9,6.9 25,6.9 C27.7,8.9 26.2,11.1 23.3,10.6 C25.9,14.1 21.5,13.2 19.5,13.4 C19,16 22,19 21,19 C18.8,19 15.2,10.7 21.5,11.5"
                fill="#42ADE2"
                fillRule="nonzero"
            />
            <path
                id="Shape"
                d="M42.5,16.3 C41,17 36.8,10.4 42,10.3 C39,7.6 39.4,6.3 43.4,6.2 C38.8,1.6 46.1,1.33226763e-15 46.8,2.4 C47,3.1 44.6,1.8 43.8,3.1 C42.9,4.6 49.4,8.5 42.7,8.2 C45.2,10.7 45.3,11.9 41.4,12.3 C41.9,13.1 43.5,15.9 42.5,16.3"
                fill="#FF8736"
                fillRule="nonzero"
            />
            <path
                id="Shape"
                d="M44.2,31.9 L45.7,30.6 C45.7,30.6 47.1,32.7 48.1,33.5 C48.9,29.9 48.7,27.8 52.8,30.2 C50.5,24 54.3,26.3 58,28 C57.8,26.4 58,26.6 59.6,26.1 C61,31.4 57.2,29.8 54.2,28.1 C56,32.9 54.1,32.6 50.3,31 C50.2,33 49.6,35.3 48.4,35.5 C47,35.9 44.2,31.9 44.2,31.9"
                fill="#ED4C5C"
                fillRule="nonzero"
            />
            <path
                id="Shape"
                d="M33,17.1 C31.2,19.5 28.3,20.8 26.2,22.9 C24,25.1 22.7,31.1 22.7,31.1 C22.7,31.1 23.5,24.8 25.6,22.4 C27.5,20.2 30.3,18.6 31.8,16.1 C34.4,11.5 32,5.5 28.6,2 C29.3,1.4 30.3,0.6 30.8,1.77635684e-15 C34.1,4.1 36.9,12 33,17.1"
                fill="#C28FEF"
                fillRule="nonzero"
            />
            <path
                id="Shape"
                d="M36.1,22.2 C33.5,24.1 31.6,26.9 29.8,29.5 C28.2,31.8 23.1,34.7 23.1,34.7 C23.1,34.7 27.9,31.4 29.4,29 C31.2,26 33,22.9 35.8,20.7 C41.4,16.4 49.5,16.8 55.8,19.1 C55.4,20 54.7,21.9 54.7,21.9 C54.7,21.9 41.4,18.3 36.1,22.2"
                fill="#FF8736"
                fillRule="nonzero"
            />
            <g
                id="Group"
                fill="#42ADE2"
                fillRule="nonzero"
                transform="translate(1.000000, 8.000000)"
            >
                <path
                    id="Shape"
                    d="M46.2,13.7 C44.5,15.9 43.7,18.6 42.4,21.1 C41.2,23.4 39.6,25.6 37.3,26.8 C34.7,28.1 29,27.7 29,27.7 C29,27.7 34.7,27.6 37.1,26 C39.5,24.4 40.8,21.6 41.7,19 C43.5,14 45.7,8.6 50.9,6.4 C51.2,7.3 51.9,9.2 51.9,9.2 C51.9,9.2 49,10 46.2,13.7"
                />
                <polygon
                    id="Shape"
                    points="1.03847916 1.32491916 5.03844084 1.32491916 5.03844084 5.32488084 1.03847916 5.32488084"
                    transform="translate(3.038460, 3.324900) rotate(-45.008103) translate(-3.038460, -3.324900) "
                />
            </g>
            <polygon
                id="Shape"
                fill="#FF8736"
                fillRule="nonzero"
                points="6.00103918 18.1970392 10.0010008 18.1970392 10.0010008 22.1970008 6.00103918 22.1970008"
                transform="translate(8.001020, 20.197020) rotate(-45.000000) translate(-8.001020, -20.197020) "
            />
            <polygon
                id="Shape"
                fill="#ED4C5C"
                fillRule="nonzero"
                points="13.1859792 4.82197916 17.1859408 4.82197916 17.1859408 8.82194084 13.1859792 8.82194084"
                transform="translate(15.185960, 6.821960) rotate(-45.008103) translate(-15.185960, -6.821960) "
            />
            <polygon
                id="Shape"
                fill="#C28FEF"
                fillRule="nonzero"
                points="44.0329892 38.6956892 48.0329508 38.6956892 48.0329508 42.6956508 44.0329892 42.6956508"
                transform="translate(46.032970, 40.695670) rotate(-45.000000) translate(-46.032970, -40.695670) "
            />
            <polygon
                id="Shape"
                fill="#ED4C5C"
                fillRule="nonzero"
                points="37.7313292 48.3906892 41.7312908 48.3906892 41.7312908 52.3906508 37.7313292 52.3906508"
                transform="translate(39.731310, 50.390670) rotate(-45.000000) translate(-39.731310, -50.390670) "
            />
            <polygon
                id="Shape"
                fill="#FF8736"
                fillRule="nonzero"
                points="50.1069892 50.6066692 54.1069508 50.6066692 54.1069508 54.6066308 50.1069892 54.6066308"
                transform="translate(52.106970, 52.606650) rotate(-45.000000) translate(-52.106970, -52.606650) "
            />
            <g
                id="Group"
                fill="#42ADE2"
                fillRule="nonzero"
                transform="translate(47.000000, 6.000000)"
            >
                <polygon
                    id="Shape"
                    points="5.90597918 31.7313992 9.90594082 31.7313992 9.90594082 35.7313608 5.90597918 35.7313608"
                    transform="translate(7.905960, 33.731380) rotate(-45.000000) translate(-7.905960, -33.731380) "
                />
                <polygon
                    id="Shape"
                    points="1.18451918 1.82287918 5.18448082 1.82287918 5.18448082 5.82284082 1.18451918 5.82284082"
                    transform="translate(3.184500, 3.822860) rotate(-45.000000) translate(-3.184500, -3.822860) "
                />
            </g>
            <polygon
                id="Shape"
                fill="#ED4C5C"
                fillRule="nonzero"
                points="17.8728892 24.7924092 21.8728508 24.7924092 21.8728508 28.7923708 17.8728892 28.7923708"
                transform="translate(19.872870, 26.792390) rotate(-45.000000) translate(-19.872870, -26.792390) "
            />
        </g>
    </svg>
)
