import {
    put,
    putResolve,
    select,
    call,
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PROMPT_METRICS } from 'piconetworks/pkg-endpoints'
import Client from 'modules/client'
import Loader from 'modules/loader'

export default function* sendMessage({
    types,
    promptType,
    triggerId,
    endpoint = PROMPT_METRICS(),
}) {

    // disabling due to https://hypecompany.slack.com/archives/C1GJW0YT0/p1709491577826959

    // try {
    //     const publisherid = yield select(Client.selectors.id)
    //     const articleId = yield select(Loader.selectors.articleId)

    //     if (articleId && promptType && triggerId) {
    //         const response = yield putResolve({
    //             type: REQUEST,
    //             config: {
    //                 method: 'post',
    //                 baseURL: PICO_API_URL(),
    //                 url: endpoint,
    //                 headers: {
    //                     Accept: 'application/json',
    //                     'Content-Type': 'application/json',
    //                     publisherid,
    //                 },
    //                 timeout: 30000,
    //                 data: {
    //                     prompt_type: promptType.includes('signup') ? 'login' : 'purchase',
    //                     trigger_id: triggerId,
    //                     article_id: articleId,
    //                 },
    //             },
    //         })

    //         yield put({
    //             type: types.SEND_MESSAGE_SUCCESS,
    //             payload: response,
    //         })

    //         // hack for rerenders so that multiple prompts dont get fired on page load
    //         yield call(new Promise((resolve) => setTimeout(resolve, 2000)))
    //     }
    // } catch (error) {
    //     yield put({
    //         type: types.SEND_MESSAGE_ERROR,
    //         error: error?.message || 'SEND_MESSAGE_ERROR',
    //     })
    // }
}
