import { produce } from 'immer'

export const initialState = {
    id: null,
    name: null,
    username: null,
    icon: null,
    cover_photo: null,
    url: null,
    primary_urls: [],
    url_staging: null,
    url_development: null,
    url_redirect: null,
    styling: {
        linkColor: null,
    },
    taxonomies: [],
    support_email: null,
    default_currency: null,
    default_anonymous_popup_id: null,
    default_registered_popup_id: null,
    default_landing_page_id: null,
    default_payment_landing_page_id: null,
    send_receipt: false,
    hide_paragraph_number: null,
    article_html_id: null,
}


const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.SET_CLIENT:
            draftState.data = initialState.data
            draftState.error = initialState.error
            break

        case duck.types.SET_CLIENT_SUCCESS:
            for (const key in payload) {
                draftState[key] = payload[key]
            }
            break

        case duck.types.SET_CLIENT_ERROR:
            draftState.error = error
            break

        case duck.types.GET_CLIENT:
            break

        case duck.types.GET_CLIENT_SUCCESS:
            for (const key in payload) {
                draftState[key] = payload[key]
            }
            break

        case duck.types.GET_CLIENT_ERROR:
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
