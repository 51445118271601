import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import OneTimePasscode from 'piconetworks/pkg-otp'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'
import styles from './scss/Otp.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const Otp = ({
    checkPhone,
    confirmCode,
    customProperties,
    email,
    fieldContainerProps,
    newsletters,
    oneTimeCodeError,
    phoneNumber,
    publisherId,
    requestNewCode,
    resendOneTimeCode,
}) => {
    const [currentPasscode, setCurrentPasscode] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        if (showNotification) {
            setTimeout(() => setShowNotification(false), 3000)
        }
    }, [showNotification])

    let friendlyOneTimeCodeError
    if (!!oneTimeCodeError && oneTimeCodeError?.includes('The requested resource')) {
        friendlyOneTimeCodeError = 'Unable to verify email. This code is no longer valid.'
    }

    const processCode = async () => {
        try {
            await confirmCode({
                code: currentPasscode,
                email: email,
                customProperties,
                selectedNewsletters: newsletters,
                phone: phoneNumber,
            })
        } catch (error) {
            console.error(error)
        }
    }

    const onEnterCode = (e, otpInputsWithValues) => {
        if (e.key === 'Enter' || otpInputsWithValues === 6) {
            e.preventDefault()
            processCode()
        }
    }
    const otpInputsEl = document.getElementById('otp-inputs')
    const otpInputs = otpInputsEl && otpInputsEl.getElementsByTagName('input')

    const resendOTP = async () => {
        try {
            if (email) {
                await resendOneTimeCode({
                    email, publisherId
                })

                Array.from(otpInputs).forEach((input) => {
                    input.value = ''
                })
            }

            if (phoneNumber) {
                await checkPhone({
                    phone: phoneNumber,
                })
            }

            setShowNotification(true)
        } catch (error) {
            console.log(error)
            setErrorMessage(error.message)
        }
    }

    let otpInputsWithValues = 0

    useEffect(() => {
        if (otpInputs) {
            Array.from(otpInputs).forEach((input) => {
                if (input.value) {
                    otpInputsWithValues++
                }

                input.onkeyup = (e) => {
                    onEnterCode(e, otpInputsWithValues)
                }
            })
        }
    })

    const renderOTPButton = () => (
        <Boutton
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                processCode()
            }}
            disabled={!currentPasscode}
            className={styles.otpButton}
        >
            Confirm
        </Boutton>
    )

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={styles.field}>
                    <label>
                        {`Enter the code sent to ${email || phoneNumber}.
                        ${email ? 'If you don\'t see a verification code in your inbox, please check your filters for spam and updates.' : ''}`}
                    </label>
                    <Field
                        {...fieldContainerProps}
                        name="code"
                        type="tel"
                        autoComplete="one-time-code"
                    >
                        {({ meta, input, ...rest }) => (
                            <div
                                className={styles.otpInput}
                                id="otp-inputs"
                            >
                                <OneTimePasscode
                                    className="otc"
                                    isErrored={!!oneTimeCodeError}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    numInputs={6}
                                    currentPasscode={currentPasscode}
                                    setCurrentPasscode={setCurrentPasscode}
                                    input={input}
                                    meta={meta}
                                    errorText={friendlyOneTimeCodeError || oneTimeCodeError}
                                />
                            </div>
                        )}
                    </Field>
                </div>
                {showNotification &&
                    <div className={styles.notification}>
                        <div>
                            <Picon icon="icon_checkmark" size='24' color="#28bf6f" />
                            New code sent
                        </div>
                    </div>
                }
                <p className={styles.info}>
                    Didn&rsquo;t receive a code? {' '} 
                    <span
                        onClick={() => {
                            resendOTP(email || phoneNumber)
                        }}
                        className={styles.resendCode}
                    >
                        Resend code
                    </span> or {' '} 
                    <span
                        onClick={() => requestNewCode()}
                        className={styles.backLink}>
                        change contact info
                    </span>.
                </p>
                <div>
                    {renderOTPButton()}
                </div>
                {errorMessage && errorMessage}
            </div>
        </ErrorBoundary>
    )
}

Otp.propTypes = {
    allowSkipOtp: PropTypes.bool,
    checkPhone: PropTypes.func,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    email: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    linkColor: PropTypes.string,
    onSkipOtp: PropTypes.func,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    phoneNumber: PropTypes.string,
    publisherId: PropTypes.string,
    requestNewCode: PropTypes.func,
}

const MemoizedOtpField = React.memo(Otp)

MemoizedOtpField.displayName = 'FieldOtp'

export default MemoizedOtpField
