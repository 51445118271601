import produce from 'immer'
import { initial } from 'lodash'

export const initialState = {
    accountDeleted: false,
    claim: null,
    didJoinGroup: undefined,
    email: undefined,
    hasPassword: undefined,
    hasPasswordError: undefined,
    hasPasswordResetAuth: false,
    isFetching: false,
    isNewRegistration: undefined,
    loginEmail: undefined,
    loginPhone: undefined,
    loginLinkLoading: false,
    loginLinkError: null,
    loginLinkSent: false,
    crossVerificationConfirmed: false,
    crossVerificationError: false,
    crossVerificationSent: false,
    crossVerificationLoading: false,
    oneTimeCodeConfirmed: false,
    oneTimeCodeError: false,
    oneTimeCodeSent: false,
    oneTimeCodeLoading: false,
    passwordResetError: null,
    passwordResetSent: false,
    phoneNumber: undefined,
    updateAccount: {
        isUpdating: false,
        userDataUpdated: null,
        emailDataUpdated: null,
        error: false,
    },
    verifyFlowId: null
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.HAS_PASSWORD_RESET_AUTH:
            draftState.hasPasswordResetAuth = true
            break

        case duck.types.CREATE_ACCOUNT:
            // eslint-disable-next-line no-param-reassign
            draftState = {
                ...duck.initialState,
                isFetching: true,
            }
            break

        case duck.types.REQUEST_LOGIN_LINK:
            draftState.loginLinkLoading = true
            draftState.loginLinkSent = false
            draftState.isNewRegistration = initialState.isNewRegistration
            break

        case duck.types.REQUEST_LOGIN_LINK_SUCCESS:
            draftState.loginLinkLoading = false
            draftState.loginLinkSent = true
            draftState.isNewRegistration = payload.isNewRegistration
            break
        case duck.types.REQUEST_LOGIN_LINK_ERROR:
            draftState.loginLinkLoading = false
            draftState.loginLinkSent = false
            draftState.loginLinkError = error.message
            draftState.isNewRegistration = false
            break
        case duck.types.REQUEST_ONE_TIME_CODE:
            draftState.oneTimeCodeError = initialState.oneTimeCodeError
            break
        case duck.types.REQUEST_ONE_TIME_CODE_SUCCESS:
            draftState.email = payload.email
            draftState.oneTimeCodeSent = true
            draftState.oneTimeCodeError = false
            draftState.phoneNumber = payload.phone
            break
        case duck.types.REQUEST_ONE_TIME_CODE_ERROR:
            draftState.oneTimeCodeSent = false
            draftState.oneTimeCodeError = error.message || error
            break
        case duck.types.RESET_ONE_TIME_CODE:
            draftState.email = initialState.email
            draftState.oneTimeCodeSent = initialState.oneTimeCodeSent
            draftState.oneTimeCodeError = initialState.oneTimeCodeError
            draftState.phoneNumber = initialState.phoneNumber
            break
        case duck.types.RESET_ONE_TIME_CODE_SUCCESS:
            draftState.oneTimeCodeSent = false
            draftState.oneTimeCodeError = false
            break
        case duck.types.RESET_ONE_TIME_CODE_ERROR:
            draftState.oneTimeCodeError = error.message || error
            break

        case duck.types.CROSS_VERIFICATION:
            draftState.claim = null
            draftState.crossVerificationLoading = true
            draftState.crossVerificationConfirmed = initialState.crossVerificationConfirmed
            draftState.crossVerificationError = initialState.crossVerificationError
            draftState.crossVerificationSent = true
            break
        case duck.types.CROSS_VERIFICATION_SUCCESS:
            draftState.claim = payload.claim
            draftState.crossVerificationLoading = false
            draftState.crossVerificationConfirmed = true
            draftState.crossVerificationError = false
            draftState.crossVerificationSent = false
            break
        case duck.types.CROSS_VERIFICATION_ERROR:
            draftState.claim = null
            draftState.crossVerificationLoading = false
            draftState.crossVerificationConfirmed = false
            draftState.crossVerificationSent = false
            draftState.crossVerificationError = error.message || error
            break

        case duck.types.CONFIRM_ONE_TIME_CODE:
            draftState.claim = null
            draftState.oneTimeCodeLoading = true
            draftState.isNewRegistration = initialState.isNewRegistration
            draftState.oneTimeCodeConfirmed = initialState.oneTimeCodeConfirmed
            draftState.oneTimeCodeError = initialState.oneTimeCodeError
            break
        case duck.types.CONFIRM_ONE_TIME_CODE_SUCCESS:
            draftState.claim = payload.claim
            draftState.verifyFlowId = payload.verify_flow_id
            draftState.oneTimeCodeLoading = false
            draftState.isNewRegistration = payload.isNewRegistration
            draftState.oneTimeCodeConfirmed = true
            draftState.oneTimeCodeError = false
            draftState.oneTimeCodeSent = false
            break
        case duck.types.CONFIRM_ONE_TIME_CODE_ERROR:
            draftState.claim = null
            draftState.oneTimeCodeLoading = false
            draftState.oneTimeCodeConfirmed = false
            draftState.oneTimeCodeError = error.message || error
            draftState.verifyFlowId = error.verify_flow_id
            break

        case duck.types.SET_LOGIN_PHONE:
        case duck.types.SET_LOGIN_EMAIL:
            draftState.loginEmail = initialState.loginEmail
            draftState.loginEmail = initialState.loginEmail
            break

        case duck.types.SET_LOGIN_EMAIL_SUCCESS:
            draftState.loginEmail = payload.loginEmail
            break

        case duck.types.SET_LOGIN_EMAIL_ERROR:
            draftState.loginEmail = error.message
            break

        case duck.types.SET_LOGIN_PHONE_SUCCESS:
            draftState.loginPhone = payload.loginPhone
            break

        case duck.types.SET_LOGIN_PHONE_ERROR:
            draftState.loginPhone = error.loginPhone
            break

        case duck.types.REQUEST_PASSWORD_RESET:
            draftState.passwordResetSent = false
            break

        case duck.types.REQUEST_PASSWORD_RESET_SUCCESS:
            draftState.passwordResetSent = true
            break

        case duck.types.REQUEST_PASSWORD_RESET_ERROR:
            draftState.passwordResetSent = false
            draftState.passwordResetError = error.message
            break

        case duck.types.GET_HAS_PASSWORD:
            draftState.hasPassword = initialState.hasPassword
            draftState.isNewRegistration = initialState.isNewRegistration
            draftState.isFetching = true
            break

        case duck.types.GET_HAS_PASSWORD_SUCCESS:
            draftState.hasPassword = payload.has_password
            draftState.isNewRegistration = payload.is_new_registration
            draftState.isFetching = false
            break

        case duck.types.GET_HAS_PASSWORD_ERROR:
            draftState.isFetching = false
            draftState.hasPasswordError = error
            break

        case duck.types.CREATE_ACCOUNT_SUCCESS:
            draftState.isFetching = false
            draftState.isNewRegistration = payload.isNewRegistration
            break

        case duck.types.CREATE_ACCOUNT_ERROR:
            draftState.isFetching = false

            if (error.hasPassword !== undefined && error.email) {
                draftState.email = error.email
                draftState.hasPassword = error.hasPassword
            }

            if (error.isNewRegistration !== undefined && error.email) {
                draftState.email = error.email
                draftState.isNewRegistration = error.isNewRegistration
            }
            break

        case duck.types.USER_JOIN_GROUP_SUCCESS:
            draftState.didJoinGroup = true
            break

        case duck.types.USER_JOIN_GROUP_ERROR:
            draftState.didJoinGroup = false
            break

        case duck.types.UPDATE:
            draftState.updateAccount.isUpdating = true
            draftState.updateAccount.error = false

            break

        case duck.types.UPDATE_SUCCESS:
            draftState.updateAccount.isUpdating = false
            draftState.updateAccount.userDataUpdated = !!payload?.userDataUpdated
            draftState.updateAccount.emailDataUpdated = !!payload?.emailDataUpdated
            break

        case duck.types.UPDATE_ERROR:
            draftState.updateAccount.isUpdating = false
            draftState.updateAccount.userDataUpdated = !!payload?.userDataUpdated
            draftState.updateAccount.emailDataUpdated = !!payload?.emailDataUpdated

            draftState.updateAccount.error = !error?.message
                ? { message: error }
                : error
            break
        case duck.types.CONFIRMATION_EMAIL:
            draftState.email = payload?.email
            break
        case duck.types.DELETE_ACCOUNT_SUCCESS:
            draftState.accountDeleted = true
            break
        default:
        // do nothing
    }
})

export default reducer
