import fireEvent from './utils/fireEvent'

// Example Payload
// {
//     "type": "identify",
//     "userId": "userID",
//     "traits":{
//         "email": "bob@bob.com"
//     },
//     "options":{},
//     "anonymousId": "8fe448a6-1e7a-434b-8374-2bb70c960e4f",
//     "meta":{
//         "rid": "c3556c28-f981-44ac-834c-e925e98ac90c",
//         "ts": 1634856456459,
//         "hasCallback": true
//     }
// }

const identify = ({ config, instance, payload }) => {
    return fireEvent({
        name: payload?.options?.type || 'user_login',
        config,
        instance,
        payload: payload?.traits
    })
}

export default identify
