import React from 'react'
import PropTypes from 'prop-types'
const Remove = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_remove">
                <polygon
                    id="bounds"
                    fill="#FFEFEE"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M23,18 C25.209139,18 27,19.790861 27,22 C27,24.209139 25.209139,26 23,26 C20.790861,26 19,24.209139 19,22 C19,19.790861 20.790861,18 23,18 Z M25.25,21.25 L20.75,21.25 C20.3357864,21.25 20,21.5857864 20,22 C20,22.4142136 20.3357864,22.75 20.75,22.75 L20.75,22.75 L25.25,22.75 C25.6642136,22.75 26,22.4142136 26,22 C26,21.5857864 25.6642136,21.25 25.25,21.25 L25.25,21.25 Z M16,15.8571431 C17.0904797,15.8571431 18.7789385,16.1561326 20.0872464,16.7527164 C18.5235359,17.6233557 17.3896542,19.1715438 17.0829263,21.0001915 L10.8630104,21 C10.3856704,21 10,20.6153075 10,20.1407651 L10,18.8571431 C10,16.8600001 14.0028572,15.8571431 16,15.8571431 Z M10,14.5714287 C10.4844523,14.5714287 11.1459335,14.6599431 11.7914783,14.8366968 C10.0359361,15.4988728 8.46142825,16.5538855 8.08507128,17.9999403 L6.57534026,18 C6.25711357,18 6,17.7435383 6,17.4271767 L6,16.5714287 C6,15.2400001 8.66857147,14.5714287 10,14.5714287 Z M22,14.5714287 C23.3314285,14.5714287 26,15.2400001 26,16.5714287 L25.9999606,16.8026704 C25.1174494,16.2921742 24.0928452,16 23,16 C22.8146177,16 22.6311991,16.0084074 22.4501046,16.0248617 C21.8134338,15.5431477 21.0309838,15.1467927 20.208902,14.8365956 C20.8540664,14.6599431 21.5155477,14.5714287 22,14.5714287 Z M16.0000002,10 C17.4228572,10 18.562857,11.1485715 18.562857,12.5714285 C18.562857,13.9942855 17.4228572,15.1428569 16.0000002,15.1428569 C14.5771432,15.1428569 13.4285716,13.9942855 13.4285716,12.5714285 C13.4285716,11.1485715 14.5771432,10 16.0000002,10 Z M10.003339,10 C11.1118532,10 12,10.8933334 12,12 C12,13.1066666 11.1118532,14 10.003339,14 C8.89482488,14 8,13.1066666 8,12 C8,10.8933334 8.89482488,10 10.003339,10 Z M22.003339,10 C23.1118532,10 24,10.8933334 24,12 C24,13.1066666 23.1118532,14 22.003339,14 C20.8948249,14 20,13.1066666 20,12 C20,10.8933334 20.8948249,10 22.003339,10 Z"
                    id="Combined-Shape"
                    fill="#FF4440"
                />
            </g>
        </g>
    </svg>
)

Remove.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Remove
