import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Flexbox } from 'piconetworks/pkg-box'
import styles from './Boutton.module.scss'

const LoadingIcon = ({
    className,
    width = '56',
    height = '56',
}) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        role="status"
    >
        <path
            d="M12,0 C18.627417,0 24,5.372583 24,12 C24,14.258231 23.3762203,16.3707704 22.2915649,18.1747141 L20.5763803,17.1454684 C21.4802128,15.6422098 22,13.8818063 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,13.8822261 2.52001916,15.6429951 3.42422463,17.1464744 L1.70903973,18.1757196 C0.624011557,16.3715554 0,14.2586506 0,12 C0,5.372583 5.372583,0 12,0 Z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
)

LoadingIcon.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.string,
    width: PropTypes.string
}


const Boutton = React.forwardRef((
    {
        active,
        block,
        children,
        className,
        flexboxContainer,
        icon,
        size,
        submitting,
        toggle,
        type,
        variant,
        ...props
    },
    ref
) => {
    const classes = cx(
        styles.btn,
        { active: active },
        { [styles['btn-icon']]: icon },
        { [styles[`btn-${variant}`]]: variant },
        { [styles['btn-no-text']]: !children },
        { [styles['btn-block']]: block },
        { [styles[`btn-${size}`]]: size },
        { [styles.btnSubmitting]: submitting },
        { [styles['btn-toggle']]: toggle },
        className,
    )

    if (props.disabled || submitting) {
        props.disabled = true
    }

    if (toggle) {
        return (
            <label {...props} className={classes}>
                {children}
            </label>
        )
    }

    return (
        <button ref={ref} type={type} className={classes} {...props}>
            <Flexbox.Container {...flexboxContainer}>
                {icon && !submitting && (
                    <React.Fragment>
                        {icon}
                        {children && <span>{children}</span>}
                    </React.Fragment>
                )}
                {submitting && (
                    <React.Fragment>
                        <LoadingIcon className={styles.isSubmitting} variant={variant} />
                        {children && <span>{children}</span>}
                    </React.Fragment>
                )}
                {!icon && !submitting && children}
            </Flexbox.Container>
        </button>
    )
})

Boutton.displayName = 'Boutton'

Boutton.propTypes = {
    /** Manually set the visual state of the button to `:active` */
    active: PropTypes.bool,

    /** Spans the full width of the Boutton parent */
    block: PropTypes.bool,
    children: PropTypes.node,

    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    'data-testid': PropTypes.string,

    /**
     * Disables the Boutton, preventing mouse events,
     * even if the underlying component is an `<a>` element
     */
    disabled: PropTypes.bool,

    flexboxContainer: PropTypes.object,

    icon: PropTypes.any,

    /**
     * Specifies a large or small button.
     *
     * @type ('sm'|'lg')
     */
    size: PropTypes.oneOf(['sm', 'lg']),

    /**
     * Whether the loading icon shows or not
     */
    submitting: PropTypes.bool,

    // instead of a button component, we will use a label instead
    toggle: PropTypes.bool,

    /**
     * Defines HTML button type attribute.
     */
    type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

    /**
     * Bouttons may be one of a variety of visual variants
     */
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'dangerous',
        'warning',
        'info',
        'dark',
        'light',
        'link',
        'facebook',
        'github',
        'google',
        'linkedin',
        'twitter'
    ])
}

Boutton.defaultProps = {
    block: false,
    disabled: false,
    flexboxContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    icon: false,
    submitting: false,
    toggle: false,
    type: 'button',
}

export default Boutton
