/* eslint-disable react/prop-types */

import qs from 'qs'
import React from 'react'
import { createMemoryHistory } from 'history'
import { withRouter as reactWithRouter } from 'react-router-dom'
import LandingPage from 'modules/landing-page'
import { useSelector } from 'react-redux'

const DEBUG_ROUTES = process.env.STAGE !== 'production'

const _history = createMemoryHistory()

_history.listen(({ location, action }) => {

    if (!DEBUG_ROUTES) {
        return
    }

    console.group()
    console.log(
        `router The current URL, is ${location.pathname}${location.search}${location.hash}`,
    )
    console.log(`The last navigation action was ${action}`)
    console.groupEnd()
})

const useHistory = () => {
    return _history
}

const Router = {
    push: (_url, _as) => {
        if (typeof _url === 'string') {
            return _history.push(_url)
        } else if (typeof _as === 'string') {
            return _history.push(_as)
        } else {
            const { pathname, query } = _as || _url

            const isQueryPresent = pathname?.includes?.('?')
            const queryConcatenater = isQueryPresent ? '&' : '?'

            return _history.push({
                pathname,
                ...(query && {
                    search: queryConcatenater + new URLSearchParams(query).toString()
                })
            })
        }
    },
    replace: (_url, _as) => {
        if (typeof _as === 'string') {
            return _history.replace(_as)
        } else if (typeof _url === 'string') {
            return _history.replace(_url)
        } else {
            const { pathname, query } = _as || _url

            const isQueryPresent = pathname?.includes?.('?')
            const queryConcatenater = isQueryPresent ? '&' : '?'

            return _history.replace({
                pathname,
                ...(query && {
                    search: queryConcatenater + new URLSearchParams(query).toString()
                })
            })
        }
    },
}

const useRouter = () => {
    const history = useHistory()

    const {
        location: {
            search,
            pathname,
        },
    } = history

    const companySlug = pathname?.split?.('/')?.[1]
    const creatorUserName = companySlug.replace(/^@/, '')

    const parsedSearch = {
        ...(companySlug && { 'company_slug': companySlug }),
        ...(creatorUserName && { 'creator_username': creatorUserName }),
    }

    new URLSearchParams(search).forEach((value, key) => {
        parsedSearch[key] = value
    })

    const router = {
        query: parsedSearch,
        push: Router.push,
        replace: Router.replace,
        route: pathname,
    }

    return router
}

const withRouter = (Component) => {
    const RouterDecoratedComponent = (props) => {
        const router = useRouter()

        return (
            <Component
                router={router}
                {...props}
            />
        )
    }

    return reactWithRouter(RouterDecoratedComponent)
}

const Link = ({ href: _url, as: _as, children, onClick }) => (
    <>
        {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                if (child.type !== 'a') {
                    return React.cloneElement(child)
                } else {
                    return React.cloneElement(child, {
                        onClick: (e) => {
                            e.preventDefault()
                            if (onClick) {
                                onClick({
                                    _url,
                                    _as,
                                })
                            } else {
                                // options should be read to use PUSH/REPLACE
                                Router.replace(_url, _as)
                                // options should be read to use PUSH/REPLACE
                            }
                        },
                        href: _url,
                    })
                }
            }

            return child
        })}
    </>
)

function LinkDecorator(Component, options = {}) {
    const WrappedComponent = (props) => {
        const {
            href: _href,
            as: _as,
        } = props

        const router = useRouter()
        const companySlug = router?.query?.company_slug

        let decoratedHref = _href
        let decoratedAs = _as

        let shortCode
        let resetWizards
        const defaultPaymentLP = useSelector(LandingPage.selectors.getDefaultPaymentLandingPage)

        const hrefToTest = typeof _href === 'string' ? _href : _href?.pathname

        if (options?.defaultPaymentShortCode && ['/checkout', '/gift'].includes(hrefToTest)) {
            shortCode = defaultPaymentLP?.short_code
            resetWizards = true
        }

        if (companySlug) {
            if (typeof _href === 'string') {
                let path = _href.replace('/[company_slug]', '')
                const queryString = qs.stringify({
                    ...(shortCode && { short_code: shortCode }),
                    ...(resetWizards && { reset_wizards: resetWizards }),
                })

                decoratedHref = `/${companySlug}${path}${queryString && `?${queryString}`}`
            } else {
                try {
                    _href.pathname = `/[company_slug]${_href.pathname}`
                } catch (error) {
                    // do nothing
                }
            }

            if (typeof _as === 'string') {
                decoratedAs = {
                    pathname: `/${companySlug}${_as}`,
                    query: {
                        ...(shortCode && { short_code: shortCode }),
                        ...(resetWizards && { reset_wizards: resetWizards }),
                    },
                }
            } else {
                try {
                    _as.pathname = `/${companySlug}${_as.pathname}`
                } catch (error) {
                    // do nothing
                }
            }
        }

        return (
            <Component
                {...props}
                href={decoratedHref}
                as={decoratedAs}
            />
        )
    }

    return WrappedComponent
}

const LinkFactory = (context = '') => {
    if (context === 'AccountMenu') {
        return LinkDecorator(Link, { defaultPaymentShortCode: true })
    }
    return LinkDecorator(Link)
}

const DecoratedLink = LinkFactory()

export default Router

export {
    withRouter,
    DecoratedLink as Link,
    useRouter,
    useHistory,
    LinkFactory,
}

// export default Router

// export {
//     withRouter,
//     DecoratedLink as Link,
//     LinkFactory,
// }
