import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
const selectors = () => ({
    account: new Duck.Selector(() => (state) => state?.account),
    isNewRegistration: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ isNewRegistration } = {}) => isNewRegistration,
    )),
    email: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ email } = {}) => email,
    )),
    loginEmail: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ loginEmail } = {}) => loginEmail,
    )),
    loginPhone: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ loginPhone } = {}) => loginPhone,
    )),
    isFetching: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ isFetching } = {}) => isFetching,
    )),
    isNotFetching: new Duck.Selector((selectors) => createSelector(
        selectors.isFetching,
        (isFetching) => !isFetching,
    )),
    hasPassword: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ hasPassword } = {}) => hasPassword,
    )),
    crossVerificationSent: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ crossVerificationSent } = {}) => crossVerificationSent,
    )),
    crossVerificationError: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ crossVerificationError } = {}) => crossVerificationError
    )),
    crossVerificationLoading: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ crossVerificationLoading } = {}) => crossVerificationLoading
    )),
    crossVerificationConfirmed: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ crossVerificationConfirmed } = {}) => crossVerificationConfirmed,
    )),
    oneTimeCodeSent: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ oneTimeCodeSent } = {}) => oneTimeCodeSent,
    )),
    oneTimeCodeError: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ oneTimeCodeError } = {}) => oneTimeCodeError
    )),
    oneTimeCodeLoading: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ oneTimeCodeLoading } = {}) => oneTimeCodeLoading
    )),
    oneTimeCodeConfirmed: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ oneTimeCodeConfirmed } = {}) => oneTimeCodeConfirmed,
    )),
    getClaim: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ claim } = {}) => claim,
    )),
    getPhoneNumber: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ phoneNumber } = {}) => phoneNumber,
    )),
    getVerifyFlowId: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ verifyFlowId } = {}) => verifyFlowId,
    )),
    emailDataUpdated: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ updateAccount } = {}) => updateAccount?.emailDataUpdated,
    )),
    updateAccountError: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ updateAccount } = {}) => updateAccount?.error,
    )),
    loginLinkLoading: new Duck.Selector((selectors) => createSelector(
        selectors.account,
        ({ loginLinkLoading } = {}) => loginLinkLoading,
    )),
})
export default selectors
