import PropTypes from 'prop-types'
import React from 'react'
import Link from '../Link'
import ClaimIcon from './Icon'

const Claim = ({
    className,
    ...props
}) => {
    return (
        <Link 
            className={className} 
            style={{
                color: 'var(--page-button-contrast-color)',
                backgroundColor: 'var(--page-contrast-color)'
            }}
            {...props}
        >
            <ClaimIcon /> 
            <span 
                style={{
                    color: 'var(--page-button-contrast-color)',
                    marginLeft: '0.4rem'
                }}
            >
                Claim this page
            </span>
        </Link>
    )
}

Claim.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Claim.displayName = 'Claim'

export default Claim
