import React, { useEffect, useRef } from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'

import Button from 'piconetworks/pkg-boutton'
import SocialLoginButton from 'piconetworks/pkg-social-login-button'
import { getSvgImage } from 'piconetworks/pkg-functions'
import Form from 'piconetworks/pkg-form'
import { PICO_API_URL } from 'piconetworks/pkg-endpoints'

import style from './scss/SocialLogin.module.scss'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error.message, errorInfo)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError = () => (<div className='errorBoundaree'></div>) } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

const showSocialLogin = ({
    auth_providers,
    provider,
    params,
    origin,
    referer,
    access_token,
    publisherId,
    userId,
    onLogin,
    disabled,
    previewMode = false,
    handleClick,
    selected,
    linkColor,
    onFailure,
}) => {
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

    let text
    
    switch (provider) {
        case 'linkedin':
            text = 'LinkedIn'
            break
        case 'github':
            text = 'GitHub'
            break
        case 'twitter':
            text = 'X'
            break
        default:
            text = capitalizeFirstLetter(provider)
            break
    }

    const buttonElement = (
        <>
            <div className={classNames(style.text)}>{text}</div>
            <div
                className={classNames(
                    style.socialIcon,
                    style[provider],
                )}
            >
                {getSvgImage(
                    null,
                    `${provider}-icon`
                )}
            </div>
        </>
    )

    if (auth_providers.includes(provider)) {
        const isSelected = selected === text
        return (
            <ErrorBoundary>
                <SocialLoginButton
                    type={provider}
                    api_url={PICO_API_URL()}
                    access_token={access_token}
                    publisherId={publisherId}
                    origin={origin}
                    referer={referer}
                    params={params}
                    scope="user"
                    userId={userId}
                    onLogin={!previewMode
                        ? (response) => onLogin(response, {
                            social_type: provider,
                        })
                        : null
                    }
                    previewMode={previewMode}
                    button={
                        <Button
                            className={classNames(
                                style.socialButton,
                                style[provider]
                            )}
                            style={isSelected ? {
                                border: `2px solid ${linkColor}`
                            } : {}}
                            buttonType="icon"
                            type="button"
                            disabled={disabled}
                            onClick={previewMode
                                ? () => handleClick(text)
                                : undefined
                            }
                        >
                            {buttonElement}
                        </Button>
                    }
                    onFailure={onFailure}
                />
            </ErrorBoundary>
        )
    } else {
        return null
    }
}

const SocialLogin = ({
    fieldContainerProps,
    description = null,
    required,
    onLogin,
    publisherId,
    userId,
    origin,
    referer,
    params,
    access_token,
    isLoggedIn,
    disabled,
    previewMode,
    authenticationMethods = [],
    handleClick = () => { },
    selected = 'all',
    linkColor,
    onFailure,
    ...rest
} = {}) => {
    const socialLoginRef = useRef(null)

    useEffect(() => {
        if (isLoggedIn) {
            socialLoginRef.current?.click()
        }
    }, [
        isLoggedIn
    ])

    const socialProps = {
        params,
        origin,
        referer,
        publisherId,
        userId,
        access_token,
        onLogin,
        disabled,
        previewMode,
        handleClick,
        selected,
        linkColor,
        onFailure,
    }

    return (
        <ErrorBoundary>
            <Form.Field
                {...fieldContainerProps}
            >
                <div className={style.socialLogin}>
                    {authenticationMethods.includes('apple') && showSocialLogin({
                        auth_providers: ['apple'],
                        provider: 'apple',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('facebook') && showSocialLogin({
                        auth_providers: ['facebook'],
                        provider: 'facebook',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('github') && showSocialLogin({
                        auth_providers: ['github'],
                        provider: 'github',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('google') && showSocialLogin({
                        auth_providers: ['google'],
                        provider: 'google',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('linkedin') && showSocialLogin({
                        auth_providers: ['linkedin'],
                        provider: 'linkedin',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('twitch') && showSocialLogin({
                        auth_providers: ['twitch'],
                        provider: 'twitch',
                        ...socialProps
                    })}
                    {authenticationMethods.includes('twitter') && showSocialLogin({
                        auth_providers: ['twitter'],
                        provider: 'twitter',
                        ...socialProps
                    })}
                </div>

                <Field
                    ref={socialLoginRef}
                    component={Form.Input}
                    type="submit"
                    {...(required && {
                        validate: value => (value ? undefined : 'Required')
                    })}
                    {...rest}
                    style={{ display: 'none' }}
                />
            </Form.Field>
        </ErrorBoundary>
    )
}

SocialLogin.displayName = 'FieldSocialLogin'

export default SocialLogin
