import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Code.module.scss'

const Code = ({children, className, ...props}) => {
    return (
        <code className={cx(styles.code, className)} {...props}>
            {children}
        </code>
    )
}

Code.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Code
