import { put, select, all, call } from 'redux-saga/effects'

import { types } from '../actions'
import Wizard from '../index'
import logger from 'lib/logger'

function* deleteWizards({
    payload: {
        company_slug
    },
}) {
    try {
        const wizards = yield select(Wizard.selectors.wizards) || {}

        yield all([...Object.keys(wizards).map(wizardName =>
            wizardName.endsWith(`_${company_slug}`) && put({
                type: types.DELETE_WIZARD,
                payload: {
                    id: wizardName
                }
            })
        )])

        yield put({
            type: types.DELETE_WIZARDS_SUCCESS,
        })
    } catch (error) {
        logger.error('deleteWizards', {}, error)
        yield put({
            type: types.DELETE_WIZARDS_ERROR,
            error,
        })
    }
}

export default deleteWizards
