import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    LOADER,
    LOADER_GUEST,
    NEWSLETTERS,
} from 'piconetworks/pkg-endpoints'

class UnauthorizedUserError extends Error {
    constructor(error) {
        super(error)
        this.name = 'UnauthorizedUserError'
        this.error = error
        this.message = 'Unauthorized User'
    }
}

function* getLoader({
    types,
    scope = 'guest',
    shortCode,
    publisherId,
    requestPayload = {},
}) {
    try {
        let response
        try{
            response = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'post',
                    baseURL: PICO_API_URL(),
                    url: scope === 'user' ? LOADER() : LOADER_GUEST(),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    timeout: 30000,
                    params: {
                        ...(shortCode && { short_code: shortCode }),
                    },
                    data: requestPayload
                },
            })
        } catch (error) {
            if (error?.response?.status === 401) {
                throw new UnauthorizedUserError(error)
            } else {
                throw error
            }
        }

        if (!response.data) {
            throw Error('No loader data was received.')
        }

        let newsletterResponse = []
        try{
            if (scope === 'user') {
                newsletterResponse = yield putResolve({
                    type: REQUEST,
                    config: {
                        method: 'get',
                        baseURL: PICO_API_URL(),
                        url: NEWSLETTERS(),
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            publisherid: publisherId,
                        },
                        timeout: 30000
                    },
                })
            }
        } catch (error) {
            console.log(error)
        }

        yield put({
            type: types.GET_LOADER_SUCCESS,
            payload: {
                scope,
                data: {
                    ...response.data,
                    default_popup: {
                        ...response.data.default_popup,
                        fetched: true,
                    },
                    ...(newsletterResponse?.data && { userNewsletters: newsletterResponse?.data }),
                },
            },
        })
    } catch (error) {
        if (error instanceof UnauthorizedUserError) {
            const unauthedReason = error.error?.response?.data?.message
            const token = error.error?.response?.config?.headers?.Authorization

            yield put({
                type: types.GET_LOADER_ERROR,
                error: {
                    message: error.message,
                    ...(unauthedReason && { reason: unauthedReason }),
                    ...(token && { token }),
                },
            })
        } else {
            yield put({
                type: types.GET_LOADER_ERROR,
                error: {
                    message: error.message,
                },
            })
        }
    }
}

export default getLoader
