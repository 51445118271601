import { put, putResolve, all } from 'redux-saga/effects'
import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { IFRAMELY_API_URL } from 'piconetworks/pkg-endpoints'

function* getUrlsMetadata({
    duck,
    urls = []
}) {
    try {
        if (!urls.length) {
            throw new Error('Must provide urls to fetch!')
        }

        const responses = yield all(urls.map(function* (url) {
            try {
                const response = yield putResolve({
                    type: REQUEST,
                    config: {
                        method: 'get',
                        url: IFRAMELY_API_URL(url),
                        timeout: 30000,
                        headers: {
                            Accept: 'application/json',
                        }
                    }
                })

                response.raw_url = url

                return response
            } catch (error) {
                const failedUrlPayload = {
                    url,
                    error
                }
                console.log(`iFramely Fetch Error- ${JSON.stringify(failedUrlPayload)}`)
                return {
                    raw_url: url,
                    data: {
                        error: error?.message || true
                    }
                }
            }
        }))

        const payload = responses.filter(response => response?.data).reduce((datas, { data, raw_url }) => (
            [...datas, ...(data ? [{ data, linkUrl: raw_url }] : [])]
        ), [])

        yield put({
            type: duck.types.GET_URLS_METADATA_SUCCESS,
            payload,
        })

    } catch (error) {
        console.log(error)
        yield put({
            type: duck.types.GET_URLS_METADATA_ERROR,
            error,
        })
    }
}

export default getUrlsMetadata

