import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Typeahead } from 'react-bootstrap-typeahead'
// import 'react-bootstrap-typeahead/css/Typeahead.css'
import FormSearch from '../FormSearch'
import FormInput from '../FormInput'

class Sync extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState() {
        const { handleRenderMenuItemChildren, handleRenderMenu, stayOpen } = this.props
        return {
            isLoading: false,
            query: '',
            selected: [],
            ...handleRenderMenuItemChildren && { renderMenuItemChildren: handleRenderMenuItemChildren },
            ...handleRenderMenu && { renderMenu: handleRenderMenu },
            ...stayOpen && { open: stayOpen }
        }
    }

    cache = {}

    typeaheadRef = React.createRef()

    inputRef = null

    handleSelectionChange = (selected) => {
        const { labelKey } = this.props
        // not handling allowMultiple just yet
        return this.setState({ selected }, () => {
            if (selected.length > 0) {
                if (typeof labelKey == 'string') {
                    this.inputRef.value = selected[0][labelKey]
                } else {
                    // labelKey is a function
                    this.inputRef.value = labelKey(selected[0])
                }
            } else {
                this.inputRef.value = ''
            }
        })
    }

    render() {
        const { autoFocus, allowMultiple, className, debugMode, id, labelKey, minLength, options, maxResults, placeholder } = this.props
        if (debugMode) {
            console.log('Typeahead state:', this.state)
        }
        return (
            <Typeahead
                {...this.state}
                id={id}
                autoFocus={autoFocus}
                className={className}
                labelKey={labelKey}
                maxResults={maxResults}
                multiple={allowMultiple}
                minLength={minLength}
                onChange={this.handleSelectionChange}
                onInputChange={query => this.setState({ query })}
                options={options}
                placeholder={placeholder}
                ref={this.typeaheadRef}
                renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                }) => {
                    const input = {
                        autoComplete: 'off',
                        name: id,
                        onChange: inputProps.onChange,
                        onBlur: inputProps.onBlur,
                        onFocus: inputProps.onFocus,
                        onKeyDown: inputProps.onKeyDown
                    }
                    return (
                        <FormSearch
                            isDirty={!isEmpty(this.state.query)}
                            isSearching={this.state.isLoading}
                            onClear={() => {
                                this.typeaheadRef.current.clear()
                                this.inputRef.value = ''
                                this.setState(this.getInitialState)
                            }}
                        >
                            <FormInput
                                input={input}
                                ref={(node) => {
                                    inputRef(node)
                                    referenceElementRef(node)
                                    this.inputRef = node
                                }}
                            />
                        </FormSearch>
                    )
                }}
            />
        )
    }
}

Sync.defaultProps = {
    autoFocus: false,
    stayOpen: false
}

Sync.propTypes = {
    /* Whether to allow more than one item to be selected */
    allowMultiple: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /* To see helpful console logs */
    debugMode: PropTypes.bool,
    /* Unique identifier for ASync */
    id: PropTypes.string.isRequired,
    handleRenderMenu: PropTypes.func,
    handleRenderMenuItemChildren: PropTypes.func,
    /* Value to key options selected by */
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    minLength: PropTypes.number,
    maxResults: PropTypes.number,
    /* An array of objects typeahead will source it's results from */
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    /* will position the menu using fixed instead of absolute positioning */
    positionFixed: PropTypes.bool,
    /* whether we force the menu to remain open */
    stayOpen: PropTypes.bool
}

export default Sync
