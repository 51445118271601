import PropertyType from "../Modifiers/PropertyType"
import { showConsoleLogs } from "../utils"

export default class PropertyList {
    /**
     * ID of either a form or a theme
     * @param id
     */
    readonly id: string
    /**
     * Type is either form or theme
     * @param type
     */
    public readonly type: string

    properties: PropertyType[]

    _isValid: boolean

    constructor(opts: {id: string, type: string, properties: PropertyType[]}) {
        this.id = opts.id
        this.type = opts.type
        this.properties = opts.properties

        // we should write a test for this instead
        this._isValid = this.validate()
    }

    reset(id: string) {
        if (id === this.id) {
            this.properties.forEach(prop => prop.reset())
        } else {
            throw new Error(`Cannot reset properties of ${this.id} with ${id}`)
        }
        return this
    }

    validate() {
        // verify the types on each property = the type on the property list
        return this.properties.every(prop => prop.type === this.type)
    }

    getByName(name: string) {
        return this.properties.find(prop => prop.name === name)
    }

    findAllBySelector(selector: string) {
        return this.properties.filter(prop => prop.selector === selector)
    }

    getByApiPropertyName(propertyFromApi: string) {
        if (showConsoleLogs()) {
            console.groupCollapsed(`getByApiPropertyName ${propertyFromApi}`)
            console.log('this.properties', this.properties)
            console.groupEnd()
        }
        const property = this.properties.find(prop => prop.propertyFromApi === propertyFromApi)
        if (property) {
            return property
        } else {
            throw new Error(`Property ${propertyFromApi} not found`)
        }
    }

    getNames() {
        return [...this.properties.map(prop => prop.name)]
    }

    getOptions(name: string) {
        const prop = this.getByName(name)
        return prop ? prop.options : undefined
    }

    getOptionKeys(name: string) {
        const prop = this.getByName(name)
        return prop ? Object.keys(prop.options) : undefined
    }

    getDefaultValue(name: string) {
        const prop = this.getByName(name)
        return prop ? prop.defaultValue : undefined
    }

    getValue(name: string) {
        const prop = this.getByName(name)
        return prop ? prop.value : undefined
    }

    getCssProperties(name: string) {
        const prop = this.getByName(name)
        return prop ? prop.cssProperties : undefined
    }
}
