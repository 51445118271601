const defaultPassPlan = {
    promptWithoutName: {
        title: 'Thanks for being a part of this community.',
        subtitle: 'Buy a pass now to keep your exclusive access.',
        description: null,
    },
    promptWithName: {
        title: 'Thanks for being a loyal member of this community, ${first_name}.',
        subtitle: 'Purchase a pass today to get exclusive access to content.',
        description: null,
    },
    confirmationWithoutName: 'Your pass has been sucessfully activated! When you see our logo in this corner, you can click on it to manage your membership.',
    confirmationWithName: 'Your pass has been successfully activated, ${first_name}! When you see our logo in this corner, you can click on it to manage your membership.',
}


export default defaultPassPlan
