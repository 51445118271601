import React from 'react'
import PropTypes from 'prop-types'

import MenuWrapper from './components/MenuWrapper'
import Button from 'piconetworks/pkg-boutton'
import cx from 'classnames'
import styles from './scss/MenuRoutes.module.scss'
import { getSvgImage } from 'piconetworks/pkg-functions'

const LoginError = ({
    sendExternalLink,
    publisherName,
    utmRootUrl,
    queryOverride,
    menuPayload: { email, phone },
    goToMenuRouteAndOpen,
    authenticationMethods,
    onClickCreateAnAccount,
    showCreateAccount = true,
    changeMenuRoute,
    getEntryRoute,
    linkColor,
    contrastColor,
}) => {
    if (!email && !phone) {
        goToMenuRouteAndOpen('/login')
    }

    const hasEmail = authenticationMethods.includes('email')
    const hasPhone = authenticationMethods.includes('sms')
    const entryRoute = getEntryRoute()

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            hasCustomFooter
            customFooter={null}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
            className={styles.loginHeight}
        >

            <div className={cx(styles.menuHeader, styles.noBottomMargin)}>
                <div
                    className={styles.backIcon}
                    onClick={() => {
                        changeMenuRoute('/login')
                    }}
                    style={{ color: linkColor }}
                >
                    {getSvgImage(linkColor, 'back-icon')}
                </div>
                <h3>Unable to Log In</h3>
            </div>
            <div className={cx(styles.loginContainer)}>
                <p className={styles.errorMessage}>
                    The password you entered may be incorrect, or you may need to log in with a different{' '}
                    {hasEmail && hasPhone
                        ? 'phone number or email address'
                        : hasEmail
                            ? 'email address'
                            : 'phone number'}
                    .
                </p>
                <Button
                    onClick={() => goToMenuRouteAndOpen('/login')}
                    variant='secondary'
                    block
                    className={styles.tryAgain}
                >
                    Try again
                </Button>
                {showCreateAccount && (
                    <Button
                        onClick={() => {
                            onClickCreateAnAccount({ entryRoute })
                        }}
                        variant='primary'
                        block
                        style={{
                            backgroundColor: linkColor,
                            color: contrastColor,
                        }}
                        className={styles.createAccountButton}
                    >
                        Create an account
                    </Button>
                )}
            </div>
        </MenuWrapper>
    )
}

LoginError.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null,
}

LoginError.propTypes = {
    authenticationMethods: PropTypes.array,
    changeMenuRoute: PropTypes.func.isRequired,
    contrastColor: PropTypes.string,
    getEntryRoute: PropTypes.func,
    goToMenuRouteAndOpen: PropTypes.func,
    linkColor: PropTypes.string,
    menuPayload: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string,

    }),
    onClickCreateAnAccount: PropTypes.func,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    sendExternalLink: PropTypes.func,
    showCreateAccount: PropTypes.bool,
    utmRootUrl: PropTypes.string,
}

export default LoginError
