import PropertyType from "../Modifiers/PropertyType"

const ThemeBlockShadowValue = new PropertyType({
    name: 'BlockShadowValue',
    propertyFromApi: 'block-shadow-value',
    defaultValue: 'rgba(0, 0, 0, 0.2)',
},
'theme'
)

const FormBlockShadowValue = new PropertyType({
    name: 'BlockShadowValue',
    propertyFromApi: 'block-shadow-value',
    defaultValue: 'rgba(0, 0, 0, 0.2)',
},
'form'
)

export {
    ThemeBlockShadowValue,
    FormBlockShadowValue,
}
