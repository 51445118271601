import { Gift } from 'piconetworks/pkg-module-gift'

//import Gift from 'pkgs/picore/src/packages/Modules/Gift/index'

import createDuck from '../baseDuck'

export default Gift({
    createDuck,
})

// import createDuck from '../baseDuck'
// import { creators, types } from './actions'
// import reducer, { initialState } from './reducer'
// import takes from './takes'
// import sagas from './sagas'

// export default createDuck({
//     store: 'gift',
//     initialState,
// }).extend({
//     types,
//     reducer,
//     creators,
//     sagas,
//     takes,
// })
