import Apps from './apps'
import Contacts from './contacts'
import Messages from './messages'
import Performance from './performance'
import Resources from './resources'
import Revenue from './revenue'
import Settings from './settings'

const NavigationIcon = {
    Apps,
    Contacts,
    Messages,
    Performance,
    Resources,
    Revenue,
    Settings
}

export default NavigationIcon
