import React from 'react'
import PropTypes from 'prop-types'

import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'
import Account from 'piconetworks/pkg-account'

import style from './scss/MenuRoutes.module.scss'

const EditProfileEditName = ({
    account,
    publisherId,
    changeMenuRoute,
    userLoader,
    guestLoader,
    router,
    WizardRedux,
    updateLoaderUser,
    initialize,
    update,
    utmRootUrl,
    queryOverride,
    userHasMultipleState
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const hasName = (userLoader?.user?.first_name || userLoader?.user?.last_name) ? true : false

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/editProfile') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>{hasName ? 'Edit' : 'Add'} Name</h3>
            </div>
            <div className={style.container}>
                <Account
                    router={router}
                    account={account}
                    publisherId={publisherId}
                    userLoader={userLoader}
                    WizardRedux={WizardRedux}
                    updateLoaderUser={updateLoaderUser}
                    initialize={initialize}
                    update={update}
                    hasName={hasName}
                    userHasMultipleState={userHasMultipleState}
                    skipWizardSubmit
                    hasInDropdown
                    changeMenuRoute={changeMenuRoute}
                    hideDefaultSubmitButtons={false}
                    type="name"
                />
            </div>
        </MenuWrapper>
    )
}

EditProfileEditName.defaultProps = {
    publisherId: null,
}

EditProfileEditName.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    queryOverride:PropTypes.object,
    userLoader: PropTypes.object,
    utmRootUrl: PropTypes.string,
}

export default EditProfileEditName
