import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Requirement.module.scss'

const Requirement = ({className, label, met = false, ...props}) => {
    return (
        <label className={cx(style.container, { [style.met]: met }, className)} {...props}>
            {label}
        </label>
    )
}

Requirement.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    met: PropTypes.bool,
    label: PropTypes.node.isRequired,
}

export default Requirement
