import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PURCHASE_GIFT } from 'piconetworks/pkg-endpoints'

function* getGiftProduct({ types, publisherId, giftId }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_GIFT()}/product/${giftId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...(publisherId && { publisherid: publisherId })
                },
                timeout: 30000,
            },
        })

        if (!data.id) {
            throw 'No product found for gift'
        }

        yield put({
            type: types.GET_GIFT_PRODUCT_SUCCESS,
            payload: {
                product: data
            },
        })
    } catch (error) {
        yield put({
            type: types.GET_GIFT_PRODUCT_ERROR,
            error: error.message,
        })
    }
}

export default getGiftProduct
