import { call, put } from 'redux-saga/effects'
import {
    SOCIAL_LINKS
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getSocialLinks({
    scope,
    types,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${SOCIAL_LINKS({ scope })}`,
            method: 'get',
            headers: {
                publisherid: publisherId,
            },
        })

        if (data) {
            yield put({
                type: types.GET_SOCIAL_LINKS_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch links')
        }
    } catch (error) {
        yield put({
            type: types.GET_SOCIAL_LINKS_ERROR,
            error: error?.message,
        })
    }
}

export default getSocialLinks
