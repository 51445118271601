import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FocusWithin from 'react-focus-within'

import styles from './TextArea.module.scss'
import FormField, { FormFieldInputPropTypes, FormFieldPropTypes } from '../FormField'

const FormTextArea = ({
    className,
    disabled = false,
    placeholder,
    type,
    input,
    meta,
    label,
    required,
    autoFocus = false,
    themeColor = '#7A34FF',
    fieldProps,
    maxLength,
    ...props
}) => {
    const [count, setCount] = useState(0)

    useEffect(() => {
        if (maxLength) {
            setCount(input.value.length)
        }
    }, [input.value])

    return (
        <FormField
            label={label}
            input={input}
            required={required}
            meta={meta}
            {...fieldProps}
        >
            <FocusWithin {...props}>
                {({ isFocused, focusProps }) => (
                    <div
                        {...focusProps}
                        className={cx(styles.container, {
                            [styles.error]: meta.touched && meta.error,
                            [styles.disabled]: disabled
                        })}
                    >
                        <textarea
                            className={cx(styles.input, className)}
                            disabled={disabled}
                            type={type}
                            id={input.name}
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                            {...input}
                            style={
                                isFocused
                                    ? { borderColor: themeColor }
                                    : {}
                            }
                            maxLength={maxLength}
                        />
                        {maxLength &&
                            <p className={styles.count}>{count}/{maxLength}</p>
                        }
                    </div>
                )}
            </FocusWithin>
        </FormField>
    )
}

FormTextArea.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    fieldProps: PropTypes.shape(FormFieldPropTypes),
    input: PropTypes.shape(FormFieldInputPropTypes),
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['password', 'email', 'text']),
    themeColor: PropTypes.string
}

export default FormTextArea
