
import { call, put } from 'redux-saga/effects'
import {
    ARTICLE
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* updateArticle({
    types,
    payload
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${ARTICLE()}/${payload.id}`,
            method: 'put',
            data: payload
        })

        if (data) {
            yield put({
                type: types.UPDATE_ARTICLE_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to update article')
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_ARTICLE_ERROR,
            error: error?.message || 'UPDATE_ARTICLE_ERROR',
        })
    }
}

export default updateArticle
