import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form, FormSpy, Field } from 'react-final-form'

import PicoForm from 'piconetworks/pkg-form'
import MenuWrapper from './components/MenuWrapper'
import ChooseNewsletter from 'piconetworks/pkg-choose-newsletter'
import Picon from 'piconetworks/pkg-picon'
import { EmailSubscribe, SMSSubscribe } from './utils/icons'

import style from './scss/MenuRoutes.module.scss'

const ManageUpdates = (props) => {
    const {
        goToMenuRouteAndOpen,
        changeMenuRoute,
        guestLoader,
        newsletterSubscribe,
        publisherId,
        queryOverride,
        sendExternalLink,
        userLoader,
        userSyncNewsletters,
        utmRootUrl,
        userEmailIsShared,
        userPhoneIsShared,
        userSMSOptIn,
        userNewsletters: _userNewsletters,
    } = props

    const [submitting, setSubmitting] = useState(false)

    const scopedLoader = userLoader || guestLoader || {}

    const {
        newsletters = [],
        userNewsletters = [],
        publisher: {
            name: publisherName,
            styling: { linkColor = '' } = {},
            phone,
        } = {}
    } = scopedLoader

    const subscribedNewsletters = _userNewsletters || userNewsletters?.reduce(
        (acc, { id, subscribed }) => {
            if (subscribed) {
                acc.push(id)
            }
            return acc
        },
        []
    )

    const isUnsubscribedFromAll =
        (userEmailIsShared && userEmailIsShared
            ? !userSMSOptIn && !userSyncNewsletters
            : userEmailIsShared
                ? !userSyncNewsletters
                : userPhoneIsShared
                    ? !userSMSOptIn
                    : false) || !newsletters.length

    const getHeaderText = () => {
        let text = 'You are currently'

        if (userEmailIsShared && userPhoneIsShared) {
            if (isUnsubscribedFromAll) {
                text += ' unsubscribed from email and text updates.'
            } else {
                if (userSyncNewsletters && userSMSOptIn) {
                    text += ' subscribed to email and text updates.'
                } else {
                    if (userSyncNewsletters) {
                        text += ' subscribed to email updates only.'
                    }
                    if (userSMSOptIn) {
                        text += ' subscribed to text updates only.'
                    }
                }
            }
        } else {
            if (userPhoneIsShared) {
                if (!userSMSOptIn) {
                    text += ' unsubscribed from text updates.'
                } else {
                    text += ' subscribed to text updates.'
                }
            }

            if (userEmailIsShared) {
                if (!userSyncNewsletters) {
                    text += ' unsubscribed from email updates.'
                } else {
                    text += ' subscribed to email updates.'
                }
            }
        }

        return text
    }

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
        >
            <div className={style.menuHeader}>
                <div
                    className={style.backIcon}
                    onClick={() => {
                        changeMenuRoute('/')
                    }}
                    style={{ color: linkColor }}
                >
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Updates</h3>
            </div>
            <div className={style.container}>
                <Form
                    onSubmit={async (props) => {
                        const {
                            newsletters = [],
                            sync = false,
                        } = props || {}

                        await newsletterSubscribe({
                            newsletters: sync ? newsletters : [],
                            publisherId,
                            sync,
                        })

                        setSubmitting(false)

                        return {}
                    }}
                    initialValues={{
                        sync: userSyncNewsletters,
                        newsletters: subscribedNewsletters
                    }}
                    render={({ form, values, handleSubmit, submitError }) => {
                        const [mounted, setMounted] = useState(false)

                        useEffect(() => {
                            setMounted(true)
                        }, [])

                        // mounted logic used to prevent this firing on initial render
                        useEffect(() => {
                            if (mounted && !submitting) {
                                setSubmitting(true)
                                form.submit(values)
                            }
                        }, [JSON.stringify(values)])

                        return (
                            <form onSubmit={handleSubmit}>
                                <div
                                    className={classNames(
                                        style.manageUpdates, {
                                            [style['manageUpdates--disabled']]: isUnsubscribedFromAll,
                                        }
                                    )}
                                >
                                    <p manageUpdates_header>
                                        {getHeaderText()}
                                    </p>
                                    {userEmailIsShared &&
                                        <div
                                            className={style.manageUpdates_item}
                                        >
                                            <Field
                                                name="sync"
                                                component={PicoForm.Checkbox}
                                                type="checkbox"
                                                text={
                                                    values?.sync
                                                        ? 'Unsubscribe from all email updates'
                                                        : 'Resubscribe to email updates'
                                                }
                                                outerClassName={style.checkboxSubscribe_check}
                                                icon={
                                                    <EmailSubscribe
                                                        type={values?.sync ? 'unsubscribe' : 'subscribe'}
                                                        fill={linkColor}
                                                    />
                                                }
                                                textColor={values?.sync ? '#FF4440' : linkColor}
                                                style={{
                                                    checkMarkOuter: {
                                                        checked: {
                                                            backgroundColor: linkColor,
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    }
                                    {userPhoneIsShared &&
                                        <div
                                            className={style.manageUpdates_item}
                                            onClick={() => {
                                                goToMenuRouteAndOpen('/notification', {
                                                    text: userSMSOptIn
                                                        ? `To unsubcribe from text updates, send "STOP" to ${phone}`
                                                        : `To resubscribe to text updates, send "START" to ${phone}`,
                                                    backRoute: '/manageUpdates',
                                                })
                                            }}
                                        >
                                            <SMSSubscribe
                                                type={userSMSOptIn ? 'unsubscribe' : 'subscribe'}
                                                fill={linkColor}
                                            />
                                            <label>
                                                {
                                                    userSMSOptIn
                                                        ? 'Unsubscribe from all text updates'
                                                        : 'Resubscribe to text updates'
                                                }
                                            </label>
                                        </div>
                                    }
                                </div>
                                {!isUnsubscribedFromAll &&
                                    <FormSpy>
                                        {(props) => {
                                            const { values: { sync = false } = {} } = props
                                            return (
                                                <div className={style.newsletterList}>
                                                    <div
                                                        className={classNames(
                                                            style.newsletterContainer
                                                        )}
                                                    >
                                                        <div className={style.newsletterContainer_title}>
                                                            Newsletters
                                                        </div>
                                                        <ChooseNewsletter
                                                            defaultValue={''}
                                                            disableGroup={!sync}
                                                            newsletterFilter="all"
                                                            newsletters={userNewsletters}
                                                            isReadyToMount={true}
                                                            isSingleColumn={true}
                                                            linkColor={linkColor}
                                                        />
                                                        <div
                                                            className={
                                                                style.buttonGroup
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </FormSpy>
                                }
                                <button className={style.hidden} type="submit">
                                    Continue
                                </button>
                                {submitError && (
                                    <div className={style.error}>{submitError}</div>
                                )}
                            </form>
                        )
                    }}
                />
            </div>
        </MenuWrapper >
    )
}

ManageUpdates.defaultProps = {
    publisherId: null,
    sendExternalLink: null
}

ManageUpdates.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    goToMenuRouteAndOpen: PropTypes.func,
    guestLoader: PropTypes.any.isRequired,
    newsletters: PropTypes.object,
    newsletterSubscribe: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    sendExternalLink: PropTypes.func,
    sync: PropTypes.object,
    userEmailIsShared: PropTypes.bool,
    userLoader: PropTypes.any.isRequired,
    userNewsletters: PropTypes.array,
    userPhoneIsShared: PropTypes.bool,
    userSMSOptIn: PropTypes.bool,
    userSyncNewsletters: PropTypes.bool,
    utmRootUrl: PropTypes.string,
    values: PropTypes.object
}

export default ManageUpdates
