import {
    put, putResolve,
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    OAUTH_USER,
} from 'piconetworks/pkg-endpoints'

function* getHasPassword({ types, email, publisherId, phone }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherId,
                },
                timeout: 30000,
                params: {
                    ...(email ? { email } : {}),
                    ...(phone ? { phone_number: phone } : {}),
                },
            },
        })

        if (data.error) {
            throw new Error('Error occured!')
        }

        yield put({
            type: types.GET_HAS_PASSWORD_SUCCESS,
            payload: {
                ...data,
            },
        })
    } catch (error) {
        yield put({
            type: types.GET_HAS_PASSWORD_ERROR,
            error: error.message,
        })
    }
}

export default getHasPassword
