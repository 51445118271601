import { Payment } from 'piconetworks/pkg-module-payment'

// import Payment from 'pkgs/picore/src/packages/Modules/Payment/index'
import createDuck from '../baseDuck'

export default Payment({
    createDuck,
})

// import createDuck from '../baseDuck'
// import { creators, types } from './actions'
// import reducer, { initialState } from './reducer'
// import takes from './takes'
// import sagas from './sagas'

// export default createDuck({
//     store: 'payment',
//     initialState,
// }).extend({
//     types,
//     reducer,
//     creators,
//     sagas,
//     takes,
// })
