import {
    put, putResolve,
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, PLANS_DISCOUNT,
} from 'piconetworks/pkg-endpoints'

function* verifyDiscountCode({
    types,
    code,
    publisherId,
}) {
    try {
        if (!code) {
            throw Error('discount code must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: `${PLANS_DISCOUNT()}/${code}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                params: {
                    publisherId,
                },
            },
        })

        if (!data.is_valid) {
            throw Error('Invalid discount code')
        }

        if (!data?.plan?.tiers) {
            throw Error('Unable to read new tiers')
        }

        yield put({
            type: types.VERIFY_DISCOUNT_CODE_SUCCESS,
            payload: {
                verifiedDiscountCodeId: data.discount_code_id,
                verifiedDiscountCode: code,
                plan: data.plan,
            },
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.VERIFY_DISCOUNT_CODE_ERROR,
            error: error.message,
        })
    }
}

export default verifyDiscountCode
