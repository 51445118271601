import { produce } from 'immer'

export const initialState = {
    data: null,
    error: null,
    auth: {
        data: null,
        error: null,
    },
    guest: {
        data: null,
        error: null,
    },
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.UPDATE_ARTICLE:
        case duck.types.GET_ARTICLE:
            draftState.data = initialState.data
            draftState.error = initialState.error
            break

        case duck.types.UPDATE_ARTICLE_SUCCESS:
        case duck.types.GET_ARTICLE_SUCCESS:
            draftState.data = payload
            break

        case duck.types.UPDATE_ARTICLE_ERROR:
        case duck.types.GET_ARTICLE_ERROR:
            draftState.error = error
            break

        case duck.types.AUTHENTICATED_ARTICLE_VISIT:
        case duck.types.AUTHENTICATED_ARTICLE_READ:
            draftState.auth = initialState.auth
            break

        case duck.types.AUTHENTICATED_ARTICLE_VISIT_SUCCESS:
        case duck.types.AUTHENTICATED_ARTICLE_READ_SUCCESS:
            draftState.auth.data = payload
            break

        case duck.types.AUTHENTICATED_ARTICLE_VISIT_ERROR:
        case duck.types.AUTHENTICATED_ARTICLE_READ_ERROR:
            draftState.auth.error = error
            break

        case duck.types.GUEST_ARTICLE_VISIT:
        case duck.types.GUEST_ARTICLE_READ:
            draftState.guest = initialState.guest
            break

        case duck.types.GUEST_ARTICLE_VISIT_SUCCESS:
        case duck.types.GUEST_ARTICLE_READ_SUCCESS:
            draftState.guest.data = payload
            break

        case duck.types.GUEST_ARTICLE_VISIT_ERROR:
        case duck.types.GUEST_ARTICLE_READ_ERROR:
            draftState.guest.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
