import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PURCHASE_GIFT } from 'piconetworks/pkg-endpoints'

function* getGift({ types, publisherId }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_GIFT()}?status=activated`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...(publisherId && { publisherid: publisherId })
                },
                timeout: 30000,
            },
        })
        if (!data.id) {
            throw 'No gift found for user'
        }
        yield put({
            type: types.GET_GIFT_SUCCESS,
            payload: {
                giftId: data.id
            },
        })
    } catch (error) {
        yield put({
            type: types.GET_GIFT_ERROR,
            error: error.message,
        })
    }
}

export default getGift
