import DashboardPerformanceFilter from './performance_filter.model'
import DashboardAppInstallChange from './app_install_change.model'
import DashboardUserLogin from './user_login.model'
import DashboardPageView from './page_view.model'
import DashboardCopyLandingPage from './copy_landing_page.model'
import DashboardBlastComposed from './blast_composed.model'
import DashboardBlastSent from './blast_sent.model'
import DashboardBlastErrored from './blast:errored.model'
import DashboardBlockUpdatedTextSignup from './block_updated:text_signup.model'
import DashboardBlockUpdatedEmailSignup from './block_updated:email_signup.model'
import DashboardGuideClicked from './guide:clicked.model'
import DashboardActionItemClicked from './action_item:clicked.model'
import DashboardPicoLinkClickFeatured from './pico_link:click_featured.model'
import DashboardDashboardOpen from './dashboard_open.model'
import DashboardUsernameUpdate from './username_update.model'
import DashboardCartFailed from './cart:failed.model'
import DashboardCartStarted from './cart:started.model'
import DashboardCartCompleted from './cart:completed.model'
import DashboardCartDismissed from './cart:dismissed.model'
import DashboardCallToActionAccepted from './call_to_action:accepted.model'
import DashboardCallToActionDeclined from './call_to_action:declined.model'
import DashboardCallToActionPresented from './call_to_action:presented.model'
import DashboardCardAdded from './card:added.model'
import DashboardCardUpdated from './card:updated.model'
import DashboardCardDeleted from './card:deleted.model'
import DashboardCardErrored from './card:errored.model'

const schemas = {
    page_view: DashboardPageView,
    user_login: DashboardUserLogin,
    app_install_change: DashboardAppInstallChange,
    performance_filter: DashboardPerformanceFilter,
    copy_landing_page: DashboardCopyLandingPage,
    blast_composed: DashboardBlastComposed,
    blast_sent: DashboardBlastSent,
    'blast:errored': DashboardBlastErrored,
    'block_updated:text_signup': DashboardBlockUpdatedTextSignup,
    'block_updated:email_signup': DashboardBlockUpdatedEmailSignup,
    'guide:clicked': DashboardGuideClicked,
    'action_item:clicked': DashboardActionItemClicked,
    'pico_link:click_featured': DashboardPicoLinkClickFeatured,
    dashboard_open: DashboardDashboardOpen,
    username_update: DashboardUsernameUpdate,
    'cart:failed': DashboardCartFailed,
    'cart:started': DashboardCartStarted,
    'cart:completed': DashboardCartCompleted,
    'cart:dismissed': DashboardCartDismissed,
    'call_to_action:accepted': DashboardCallToActionAccepted,
    'call_to_action:declined': DashboardCallToActionDeclined,
    'call_to_action:presented': DashboardCallToActionPresented,
    'card:added': DashboardCardAdded,
    'card:updated': DashboardCardUpdated,
    'card:deleted': DashboardCardDeleted,
    'card:errored': DashboardCardErrored,
}

export default schemas
