import React from 'react'
import PropTypes from 'prop-types'
import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'
import { Form as ReactFinalForm, Field } from 'react-final-form'
import Button from 'piconetworks/pkg-boutton'
import Form from 'piconetworks/pkg-form'
import Account from 'piconetworks/pkg-account'

import style from './scss/MenuRoutes.module.scss'
import { fieldValidations, composeValidators } from './utils/validations'

const EditEmail = ({
    publisherId,
    userEmail,
    userEmailIsShared,
    changeMenuRoute,
    userLoader,
    guestLoader,
    utmRootUrl,
    goToMenuRouteAndOpen,
    userHasMultipleState,
    queryOverride,
    account,
    router,
    WizardRedux,
    update,
    emailOtpEnabled,
    confirmLoginCode,
    oneTimeCodeError,
    requestOneTimeCode,
    resendOneTimeCode,
    resetOneTimeCode,
    oneTimeCodeSent,
    initialize,
    onEmailChange,
    redirectUrl,
    loginAndAccountManagementEnabled,
    me,
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    let text = ''
    let description = false
    let showTerms = false
    let confirmShow = false
    let shouldCheckEmail = false

    const {
        publisher: {
            name: publisherName,
            styling: { linkColor = '' } = {},
        } = {},
    } = scopedLoader

    if (!userEmail) {
        text = 'Add Email Address'
        showTerms = true
        description = false
        shouldCheckEmail = false
    }

    if (userEmail && userEmailIsShared && userHasMultipleState) {
        text = 'Edit Email Address'
        description = true
        shouldCheckEmail = false
    }

    if (userEmail && userEmailIsShared && !userHasMultipleState) {
        text = 'Edit Email Address'
        description = false
        shouldCheckEmail = false
    }

    if (userEmail && !userEmailIsShared && userHasMultipleState) {
        text = 'Confirm Email Address'
        confirmShow = true
        showTerms = true
        shouldCheckEmail = true
    }

    if (!loginAndAccountManagementEnabled) {
        return (
            <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
                <div className={style.menuHeader}>
                    <div className={style.backIcon} onClick={() => { changeMenuRoute('/editProfile') }} style={{ color: linkColor }}>
                        <Picon color={linkColor} icon='arrow-left' size='24' />
                    </div>
                    <h3>{oneTimeCodeSent ? 'Verify Email Address' : 'Edit Email Address'}</h3>
                </div>
                <div className={style.container}>
                    <Account
                        publisherId={publisherId}
                        userLoader={userLoader}
                        me={me}
                        account={account}
                        router={router}
                        WizardRedux={WizardRedux}
                        update={update}
                        confirmCode={confirmLoginCode}
                        emailOtpEnabled={emailOtpEnabled}
                        oneTimeCodeError={oneTimeCodeError}
                        requestOneTimeCode={requestOneTimeCode}
                        resetOneTimeCode={resetOneTimeCode}
                        resendOneTimeCode={resendOneTimeCode}
                        initialize={initialize}
                        onEmailChange={onEmailChange}
                        oneTimeCodeSent={oneTimeCodeSent}
                        skipWizardSubmit
                        changeMenuRoute={changeMenuRoute}
                        hasInDropdown
                        type="email"
                        redirectUrl={redirectUrl}
                    />
                </div>
            </MenuWrapper>
        )
    }

    return (
        <MenuWrapper
            publisherName={publisherName}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
        >
            <div className={style.editPhoneContainer}>
                <div className={style.menuHeader}>
                    <div
                        className={style.backIcon}
                        onClick={() => {
                            changeMenuRoute('/editProfile')
                        }}
                        style={{ color: linkColor }}
                    >
                        <Picon color={linkColor} icon='arrow-left' size='24' />
                    </div>
                    <h3>{text}</h3>
                </div>
                <div className={style.container}>
                    <ReactFinalForm
                        initialValues={{
                            publisherId,
                            email: userEmail,
                        }}
                        onSubmit={() => {}}
                        keepDirtyOnResubmit={true}
                        keepDirtyOnReinitialize
                        render={({ values }) => {
                            let isDisabled = false
                            if (shouldCheckEmail) {
                                isDisabled =
                                    userEmail !== values.email
                            }

                            const onEnterEmail = (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    goToMenuRouteAndOpen(
                                        '/editProfile/otp',
                                        {
                                            email: values.email,
                                            verify: 'email',
                                        }
                                    )
                                }
                            }

                            return (
                                <form>
                                    {confirmShow && (
                                        <p className={style.showTermsText}>
                                            The email address{' '}
                                            {userEmail
                                                ? userEmail.substr(
                                                    userEmail.length - 4
                                                )
                                                : ''}{' '}
                                            is already associated with your
                                            account. Enter the complete email
                                            address below to receive emails from{' '}
                                            {publisherName}.
                                        </p>
                                    )}
                                    <Field
                                        formFieldClassName={style.field}
                                        label='Email Address'
                                        name='email'
                                        type='text'
                                        placeholder='name@domain.com'
                                        validate={composeValidators(
                                            fieldValidations.email
                                        )}
                                    >
                                        {({ meta, input, ...rest }) => (
                                            <Form.Input
                                                {...rest}
                                                meta={meta}
                                                input={input}
                                                onKeyDown={ (e) => onEnterEmail(e, e.target.value) }
                                            />
                                        )}
                                    </Field>
                                    <div>
                                        {showTerms && (
                                            <p className={style.tinyText}>
                                                By clicking &ldquo;Add,&rdquo; you agree that {publisherName} may send you marketing emails.
                                            </p>
                                        )}
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <Button
                                            variant='link'
                                            onClick={() =>
                                                changeMenuRoute('/editProfile')
                                            }
                                            className={style.button}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant='primary'
                                            onClick={() => {
                                                if (!isDisabled) {
                                                    goToMenuRouteAndOpen(
                                                        '/editProfile/otp',
                                                        {
                                                            email: values.email,
                                                            verify: 'email',
                                                        }
                                                    )
                                                }
                                            }}
                                            disabled={isDisabled}
                                        >
                                            {!userEmail ? 'Add' : 'Update'}
                                        </Button>
                                    </div>
                                    <div className={style.description}>
                                        {description && (
                                            <p
                                                className={
                                                    style.disclaimer
                                                }
                                            >
                                                <b>NOTE:</b> Updating this email
                                                address will update all of the
                                                profiles associated with your
                                                Hype ID.
                                            </p>
                                        )}
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
        </MenuWrapper>
    )
}

EditEmail.defaultProps = {
    publisherId: null,
}

EditEmail.propTypes = {
    account: PropTypes.object,
    changeMenuRoute: PropTypes.func.isRequired,
    confirmLoginCode: PropTypes.func,
    contrastColor: PropTypes.string,
    emailOtpEnabled: PropTypes.bool,
    goToMenuRouteAndOpen: PropTypes.func,
    guestLoader: PropTypes.any.isRequired,
    initialize: PropTypes.any,
    loginAndAccountManagementEnabled: PropTypes.bool,
    me: PropTypes.any,
    onEmailChange: PropTypes.func,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    redirectUrl: PropTypes.string,
    requestOneTimeCode: PropTypes.func,
    resendOneTimeCode: PropTypes.func,
    resetOneTimeCode: PropTypes.func,
    router: PropTypes.any,
    update: PropTypes.func,
    userEmail: PropTypes.string,
    userEmailIsShared: PropTypes.bool,
    userHasMultipleState: PropTypes.bool,
    userLoader: PropTypes.any.isRequired,
    utmRootUrl: PropTypes.string,
    WizardRedux: PropTypes.any,
}

export default EditEmail
