import { PicoAnalytics } from 'piconetworks/pkg-pico-analytics'

const metricsEndpoint = process.env.METRICS_ENDPOINT || 'https://metrics.pico.tools/extract'
const segmentWriteKey = process.env.SEGMENT_WRITE_KEY

const analyticsConfig = {
    // eslint-disable-next-line camelcase
    metrics_endpoint: metricsEndpoint,
    context: 'landing_page',
    plugins: {
        segment: {
            writeKey: segmentWriteKey,
        },
    }
}

let analyticInstance = null

const getAnalytics = (configOptions = {}) => {
    if (analyticInstance !== null) {
        return analyticInstance
    }

    analyticInstance = PicoAnalytics({...analyticsConfig, ...configOptions})

    return analyticInstance
}

export default getAnalytics
