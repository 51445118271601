import generateAction from 'piconetworks/pkg-generate-action'

const getCurrentUser = generateAction({
    baseActionCreatorName: 'getCurrentUser',
    baseActionTypeName: 'GET_CURRENT_USER',
})

const updateCurrentUser = generateAction({
    baseActionCreatorName: 'updateCurrentUser',
    baseActionTypeName: 'UPDATE_CURRENT_USER',
})

const deleteCurrentUser = generateAction({
    baseActionCreatorName: 'deleteCurrentUser',
    baseActionTypeName: 'DELETE_CURRENT_USER',
})

const updateUserProperties = generateAction({
    baseActionCreatorName: 'updateUserProperties',
    baseActionTypeName: 'UPDATE_USER_PROPERTIES',
})

const updateUserNewsletters = generateAction({
    baseActionCreatorName: 'updateUserNewsletters',
    baseActionTypeName: 'UPDATE_USER_NEWSLETTERS',
})

const types = [
    ...getCurrentUser.types,
    ...updateCurrentUser.types,
    ...deleteCurrentUser.types,
    ...updateUserProperties.types,
    ...updateUserNewsletters.types,
    'UPDATE_USER_SYNC_SUCCESS',
]

const creators = (duck) => ({
    ...getCurrentUser.creators(duck),
    ...updateCurrentUser.creators(duck),
    ...deleteCurrentUser.creators(duck),
    ...updateUserProperties.creators(duck),
    ...updateUserNewsletters.creators(duck),
})

export {
    types,
    creators,
}
