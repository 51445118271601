import { put, putResolve } from 'redux-saga/effects'

import {
    STORE_TOKENS,
} from 'piconetworks/pkg-network-middleware'

function* tokenLogin({ types, accessToken, refreshToken }) {
    try {
        if (!accessToken) {
            throw Error('access token is required for token login')
        }

        if (!refreshToken) {
            throw Error('refresh token is required for token login')
        }

        yield putResolve({
            type: STORE_TOKENS,
            access_token: accessToken,
            refresh_token: refreshToken,
        })

        yield put({
            type: types.TOKEN_LOGIN_SUCCESS,
            payload: {
                accessToken,
                refreshToken,
            },
        })
    } catch (error) {
        yield put({
            type: types.TOKEN_LOGIN_ERROR,
            error: error.message,
        })
    }
}

export default tokenLogin
