import React from 'react'
import PropTypes from 'prop-types'
const Discount = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_discount">
                <polygon
                    id="bounds"
                    fill="#28BF6F"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M24.9623448,7.03858815 C25.5075996,7.58495981 25.7832793,8.30613483 25.7893999,9.0371744 L26,15.0485132 C25.9573041,15.4161295 25.82239,15.7369387 25.5493495,16.0105385 L16.0579946,25.4401789 C15.3043942,26.1888793 14.0885741,26.1862692 13.3381821,25.43434 L6.56450361,18.6467863 C5.81183213,17.8925731 5.81183213,16.6697518 6.5645036,15.9155386 L16.0095237,6.45117124 C16.2829657,6.22357379 16.6486259,6.0427834 17.0154907,6 L23.0615877,6.21278035 C23.7452997,6.25351897 24.4230204,6.52818842 24.9623448,7.03858815 Z M20.5250107,11.4747585 C21.2768316,12.2257008 22.559469,12.2697546 23.3114391,11.4744901 C24.0634093,10.6792255 24.0635288,9.44226004 23.3117079,8.69131773 C22.515658,7.94037967 21.2772454,7.94049898 20.5252794,8.69158617 C19.7733093,9.4868507 19.7731898,10.7238162 20.5250107,11.4747585 Z"
                    id="Shape"
                    fill="#FFFFFF"
                />
            </g>
        </g>
    </svg>
)

Discount.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Discount
