import { colors } from 'piconetworks/pkg-functions'
import { ThemeBlockBackgroundValue, FormBlockBackgroundValue } from '../Properties/BlockBackgroundValue'
import PropertyType from '../Modifiers/PropertyType'
import { ThemePageContrastColor, FormPageContrastColor } from './PageContrastColor'

const getBackgroundColorHex = (value) => {
    let sectionBackgroundColor = value || 'rgb(255, 255, 255)'
    
    if (sectionBackgroundColor?.includes('rgba')) {
        const sectionBackgroundColorAlpha = sectionBackgroundColor.split('(').pop().split(')')[0]
        const [r, g, b] = sectionBackgroundColorAlpha.split(',').map((x) => parseFloat(x))
        sectionBackgroundColor = colors.RgbToHex(r, g, b)
    }

    return sectionBackgroundColor
}

const getBlockContrastColor = ({
    formBlockBg, 
    themeBlockBg, 
    formPageContrastColor, 
    themePageContrastColor
}) => {
    let contrastColor
    let blockBackgroundColor

    const formBlockBackgroundValueHasBeenModified = formBlockBg.getHasBeenModified()

    if (formBlockBackgroundValueHasBeenModified) {
        blockBackgroundColor = formBlockBg.getValue()
    } else {
        blockBackgroundColor = themeBlockBg.getValue()
    }
    // if the block background is transparent, just set the contrast color to be the same as the page contrast color
    // if the form page color hasn't been modified, then we can just use the theme page contrast color
    if (blockBackgroundColor === 'transparent') {
        const formPageContrastColorHasBeenModified = formPageContrastColor.getHasBeenModified()
        if (formPageContrastColorHasBeenModified) {
            contrastColor = formPageContrastColor.getValue()
        } else {
            contrastColor = themePageContrastColor.getValue()
        }
    } else {
        const sectionBackgroundColor = getBackgroundColorHex(blockBackgroundColor)
        contrastColor = colors.getContrastColor(sectionBackgroundColor)
    }
    const { r: redTextColor, g: greenTextColor, b: blueTextColor } = colors.hexToRgb(contrastColor)

    const textColorRgb = `rgb(${redTextColor}, ${greenTextColor}, ${blueTextColor})`
    return textColorRgb
}

const selectors = '.block'

const ThemeBlockContrastColor = new PropertyType(
    {
        name: 'BlockContrastColor',
        propertyFromApi: 'block-contrast-color',
        convertValueToCssPropertiesFunction: (value) => {
            return `color: ${value};`
        },
        selector: selectors,
        isCompounded: true,
        cssVariable: '--block-contrast-color',
        priority: 5,
        convertToRgb: true,
    },
    'theme'
)

const FormBlockContrastColor = new PropertyType(
    {
        name: 'BlockContrastColor',
        propertyFromApi: 'block-contrast-color',
        convertValueToCssPropertiesFunction: (value) => `color: ${getBlockContrastColor({
            formBlockBg: FormBlockBackgroundValue, 
            themeBlockBg: ThemeBlockBackgroundValue, 
            formPageContrastColor: FormPageContrastColor, 
            themePageContrastColor: ThemePageContrastColor
        })};`,
        selector: selectors,
        isCompounded: true,
        cssVariable: '--block-contrast-color',
        cssVariableFunction: (value) => getBlockContrastColor({
            formBlockBg: FormBlockBackgroundValue, 
            themeBlockBg: ThemeBlockBackgroundValue, 
            formPageContrastColor: FormPageContrastColor, 
            themePageContrastColor: ThemePageContrastColor
        }),
        priority: 6,
        convertToRgb: true,
    },
    'form'
)

export {
    ThemeBlockContrastColor,
    FormBlockContrastColor,
}
