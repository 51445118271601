import Joi from 'joi'

const actionItemSlugs = [
    'add-a-link',
    'add-socials',
    'edit-sign-up',
    'share-pico-link',
    'all-tasks-dismissed'
]

const DashboardActionItemClicked = Joi.object()
    .keys({
        action: Joi.string().valid(...actionItemSlugs).description('The action specific to the item clicked'),
    })
    .description('dashboard:action_item:clicked event attributes ')
    .meta({ name: 'DashboardActionItemClicked', filename: 'dashboard:action_item:clicked.model' })

export default DashboardActionItemClicked
