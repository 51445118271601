import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'

// import './Tooltip.scss'

const Trigger = React.forwardRef(
    ({ children, className }, ref) => {
        return (
            <span ref={ref} className={className}>
                { children }
            </span>
        )
    }
)

const Tooltip = (props) => {
    const {
        placement,
        className,
        children,
        label,
        content,
        triggerClassName,
        ...rest
    } = props

    return (
        <Tippy
            // to debug styles, add prop to Tippy trigger="click"
            arrow={true}
            content={content || label}
            className={className}
            placement={placement}
            theme='pico'
            {...rest}
        >
            <Trigger className={triggerClassName}>
                {children}
            </Trigger>
        </Tippy>
    )
}

export const TooltipPropTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    placement: PropTypes.oneOf([
        'top',
        'right',
        'bottom',
        'left',
        'top-start',
        'top-end',
        'right-start',
        'right-end',
        'bottom-start',
        'bottom-end',
        'left-start',
        'left-end',
        'auto',
        'auto-start',
        'auto-end'
    ]),
    triggerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Tooltip.propTypes = { ...TooltipPropTypes }

Tooltip.defaultProps = {
    className: '',
    placement: 'auto',
    triggerClassName: ''
}

export default Tooltip
