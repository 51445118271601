import AnnualPercentDiscount from './AnnualPercentDiscount'
import Capitalize from './Capitalize'
import getSvgImage from './GetSvgImage'
import InFrame from './InFrame'
import rgbToHex from './RGBToHex'
import utmLink from './UtmLink'
import * as colors from './Colors'
import priceText from './priceText'
import maskPhoneNumber from './maskPhoneNumber'

export {
    AnnualPercentDiscount,
    Capitalize,
    getSvgImage,
    InFrame,
    rgbToHex,
    colors,
    utmLink,
    priceText,
    maskPhoneNumber,
}
