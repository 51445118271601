import {
    creators as creatorsDefault,
    types as typesDefault,
} from './actions'

import reducerDefault, {
    initialState as initialStateDefault,
} from './reducer'

import sagasDefault from './sagas'
import takesDefault from './takes'
import selectorsDefault from './selectors'

const storeDefault = 'payment'

const Payment = ({ 
    store = storeDefault,
    createDuck,
    initialState = initialStateDefault,
    creators = creatorsDefault,
    types = typesDefault,
    reducer = reducerDefault,
    sagas = sagasDefault,
    takes = takesDefault,
    selectors = selectorsDefault,
}) => {
    return createDuck({
        store,
        initialState,
    }).extend({
        creators,
        types,
        reducer,
        sagas,
        takes,
        selectors,
    }) 
}

export {
    storeDefault as store,
    creatorsDefault as creators,
    typesDefault as types,
    reducerDefault as reducer,
    initialStateDefault as initialState,
    sagasDefault as sagas,
    takesDefault as takes,
    Payment,
}
