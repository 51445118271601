import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-8 -8)">
            <rect height="56" width="56" fill="#F9F4FF" rx="28" x="8" y="8" />
            <path
                d="M49.6530682,22.2078504 L21.1847734,33.6929995 C19.5858384,34.3382076 19.6082385,35.3288115 21.2341956,35.8950826 L28.3958027,38.3877131 L31.1776721,47.43508 C31.5104725,48.5765165 32.4438067,48.8203689 33.2519853,47.9765211 L37.0663891,43.993723 C37.0827446,43.9766756 37.097678,43.9599987 37.1133225,43.9433219 L44.0989294,49.4485296 C45.4671085,50.5269647 46.8687099,49.9744057 47.2135991,48.2211147 L51.9332482,24.2227823 C52.2781375,22.4694913 51.2520031,21.5630128 49.6530682,22.2078504 Z"
                fill="#C9ADFF"
            />
            <path
                d="M45.0283525,28.6284308 L33.1822963,40.2088232 L33.1819408,40.2091938 L33.1808741,40.2103056 C32.9728739,40.4145042 32.801496,40.8069656 32.7769626,41.1038132 L32.7769626,41.1038132 L32.4615845,44.9065016 C32.3022955,46.7983956 32.0167841,46.139846 31.6679837,45.0035978 L29.4013148,37.7273106 L44.2493295,27.814972 C46.5458651,26.323322 46.5458651,27.1819936 45.0283525,28.6284308 Z"
                fill="#7A34FF"
            />
        </g>
    </svg>
)
