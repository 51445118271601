import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const prepareFields = (fields, customPropertyValues = {}) => {
    const typeMap = {
        // Custom property types
        dropdown: 'Dropdown',
        short_answer: 'Text',
        number: 'Number',
        url: 'Text',
        paragraph: 'Text',
        radio: 'Radio',
        boolean: 'Boolean',
        checkbox: 'Checkbox',

        // Standard property types
        first_name: 'Text',
        last_name: 'Text',
        address: 'Address',
        phone_number: 'Text',
        gender: 'Text',
        birthday: 'Text',
        date: 'Date',
    }

    return fields.map(field => {
        const formattedFields = {
            id: field?.connected_property?.id,
            title: field?.label,
            description: field?.description,
            type: typeMap?.[field?.connected_property?.type],
            required: field?.required,
            order: field?.order,
            options: field?.connected_property?.options,
        }
        if (customPropertyValues[field?.connected_property?.id]) {
            formattedFields.defaultValue = customPropertyValues[field?.connected_property?.id]
        }
        return formattedFields
    })
}

const popups = (duck) => ({
    popups: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        ({ popups = [] }) => (
            [...popups].sort(({ order: a }, { order: b }) => a - b)
        ),
    )),
    scopedPopups: new Duck.Selector(() => createSelector(
        (state, scope = 'guest') => state?.[duck.store]?.[scope]?.popups,
        (popups = []) => (
            [...popups].sort(({ order: a }, { order: b }) => a - b)
        ),
    )),
    getAdditionalPopups: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        ({ additionalPopups = [] }) => additionalPopups,
    )),
    // because of popup optimization, if we go from
    // guest -> authed and vice versa
    // the later store has the cached popups
    allPopups: new Duck.Selector(() => createSelector(
        (state) => duck.selectors.scopedPopups(state, 'user'),
        (state) => duck.selectors.scopedPopups(state),
        duck.selectors.getAdditionalPopups,
        duck.selectors.getDefaultRegisteredPopup,
        duck.selectors.getDefaultAnonPopup,
        (userPopups, guestPopups, additionalPopups, registeredPopup, anonPopup) => {
            const registeredPopupId = registeredPopup?.id
            const anonPopupId = anonPopup?.id
            let allPopups = []
            if (registeredPopupId && anonPopupId) {
                allPopups = [
                    ...userPopups.filter(popup => (registeredPopupId && anonPopupId) !== popup?.id),
                    ...guestPopups.filter(popup => (registeredPopupId && anonPopupId) !== popup?.id),
                    registeredPopup,
                    anonPopup,
                    ...additionalPopups,
                ]
            } else if (anonPopupId) {
                allPopups = [
                    ...userPopups.filter(popup => anonPopupId !== popup?.id),
                    ...guestPopups.filter(popup => anonPopupId !== popup?.id),
                    anonPopup,
                    ...additionalPopups
                ]
            } else if (registeredPopupId) {
                allPopups = [
                    ...userPopups.filter(popup => registeredPopupId !== popup?.id),
                    ...guestPopups.filter(popup => registeredPopupId !== popup?.id),
                    registeredPopup,
                    ...additionalPopups,
                ]
            } else {
                allPopups = [...userPopups, ...guestPopups, ...additionalPopups]
            }

            return allPopups.sort(({ order: a }, { order: b }) => a - b)
        },
    )),
    customProperties: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        ({ custom_properties }) => custom_properties,
    )),
    popupNewsletters: ((state, popupId) => {
        const popups = duck.selectors.allPopups(state)

        return popups.find(({ id }) => popupId === id)?.newsletters || []
    }),
    popupAuthenticationMethods: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        (popup) => (popup?.authentication_methods || []).length ? popup.authentication_methods : ['email'],
    )),
    popupPlanOptions: new Duck.Selector(() => createSelector(
        duck.selectors.popups,
        (popups) => (
            popups
                .filter(({ type }) => type === 'offer')
                .reduce((options, popup) => {
                    popup?.products?.forEach((product) => {
                        options = [
                            ...options,
                            ...product.options,
                        ]
                    })
                    return options
                }, [])
                .map(({ id, ...rest }) => ({
                    plan_option_id: id,
                    id,
                    ...rest,
                }))
        ),
    )),
    popupUserOffer: (state) => {
        const currentProductOptionId = duck.selectors.currentProductOptionId(state)

        if (!currentProductOptionId) {
            return null
        }

        // const currentProductOptionId = '5c00c747-4089-48af-a1d5-f04795f38100'
        const popups = duck.selectors.popups(state)

        const userOffer = popups.reduce((acc, { products } = {}) => {
            const offer = products?.find(({ id }) => (id === currentProductOptionId))

            if (offer) {
                return offer
            }

            return acc
        }, null)

        return userOffer
    },
    popupGetProductByProductOptionId: new Duck.Selector(() => createSelector(
        duck.selectors.allPopups,
        (_, productOptionId) => productOptionId,
        (allPopups, productOptionId) => {
            const products = allPopups.reduce((acc, { products } = {}) => {
                return [
                    ...acc,
                    ...(products ? products : [])
                ]
            }, [])

            const product = products.find(({ options = [] }) => (
                options.some(option => option?.id === productOptionId || option?.short_code === productOptionId)
            ))

            return product
        }
    )),
    popupGetOptionById: new Duck.Selector(() => createSelector(
        (state, optionId) => duck.selectors.popupGetProductByProductOptionId(state, optionId),
        (_, optionId) => optionId,
        (product, optionId) => {
            const option = (product?.options || []).find(({
                id,
                short_code,
            }) =>
                optionId === id || optionId === short_code
            )
            return option
        },
    )),
    popupGetPopupByProductId: new Duck.Selector(() => createSelector(
        duck.selectors.allPopups,
        (_, productId) => productId,
        (allPopups, productId) => {
            const popup = allPopups.find(({ products } = {}) =>
                products ? products.some(product => product?.id === productId) : false
            )
            return popup || {}
        },
    )),
    popupGetPopupByProductOptionId: new Duck.Selector(() => createSelector(
        duck.selectors.allPopups,
        (_, optionId) => optionId,
        (allPopups, optionId) => {
            const popup = allPopups.find(({ products } = {}) =>
                products ? products.some(product =>
                    product?.options.find(option =>
                        option?.id === optionId || option?.short_code === optionId
                    )) : false
            )
            return popup || {}
        }
    )),
    popupGetPopupById: new Duck.Selector(() => createSelector(
        duck.selectors.allPopups,
        (_, popupId = '') => popupId ? popupId.toLowerCase() : '',
        (allPopups, popupId) => {
            let popup = allPopups.find(popup =>
                (popup?.id === popupId || popup?.short_code === popupId) && popup.fetched
            )

            // else it was never fetched
            if (!popup) {
                popup = allPopups.find(popup =>
                    popup?.id === popupId || popup?.short_code === popupId
                )
            }

            return popup
        }
    )),
    popupGetProductFields: (state, productId, _fields) => {
        const product = duck.selectors.popupGetProductById(state, productId)
        const customPropertyValues = duck.selectors.customProperties(state) || {}
        const fields = _fields || product?.fields || []

        return prepareFields(fields, customPropertyValues)
    },
    popupGetFields: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        duck.selectors.customProperties,
        (popup, customPropertyValues = {}) => {
            return prepareFields(popup?.fields || [], customPropertyValues)
        }
    )),
    popupGetProductById: new Duck.Selector(() => createSelector(
        duck.selectors.allPopups,
        (_, productId) => productId,
        (allPopups, productId) => {
            const products = allPopups.reduce((acc, { products } = {}) => {
                return [
                    ...acc,
                    ...(products ? products : [])
                ]
            }, [])

            const product = products.find(({ id }) => {
                return id === productId
            })

            return product
        }
    )),
    popupIsPledge: (state, { productId, optionId } = {}) => {
        if (productId) {
            const product = duck.selectors.popupGetProductById(state, productId)

            return product?.pledge_mode
        }

        if (optionId) {
            const product = duck.selectors.popupGetProductByProductOptionId(state, optionId)

            return product?.pledge_mode
        }

        return null
    },
    getFirstOptionTypeOfPopup: (popup) => {
        const type = popup?.products?.[0]?.options?.[0]?.type

        if (
            popup?.type !== 'offer'
            || !type
        ) {
            return null
        }

        return type
    },
    passNewRegular: (popup) => {
        const type = duck.selectors.getFirstOptionTypeOfPopup(popup)

        return type === 'pass'
    },
    subscriptionExistingSwitch: (state, option) => {
        // console.log('subscriptionExistingSwitch', state, option)
        return false
    },
    subscriptionNewPledge: (state, option) => {
        // console.log('subscriptionNewPledge', state, option)
        return false
    },
    subscriptionNewRegular: (state, option) => {
        // console.log('subscriptionNewRegular', state, option)
        return false
    },
    getDefaultPopup: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        duck.selectors.userStatus,
        ({ default_registered_popup = {}, default_anonymous_popup = {} }, userStatus) =>
            userStatus === 'anonymous' ? default_anonymous_popup : default_registered_popup,
    )),
    getDefaultRegisteredPopup: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        ({ default_registered_popup } = {}) => default_registered_popup,
    )),
    getDefaultAnonPopup: new Duck.Selector(() => createSelector(
        duck.selectors.topScopeLoader,
        ({ default_anonymous_popup } = {}) => default_anonymous_popup,
    )),
    getPaymentPopupCustomTextFileName: new Duck.Selector(() => createSelector(
        duck.selectors.getDefaultPopup,
        ({ custom_text } = {}) => custom_text?.payment?.file_name,
    )),
    getPaymentPopupCustomTextFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text } = {}) => custom_text?.payment?.file_name,
    )),
    getAckPopupCustomTextFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text, type } = {}) => !type?.startsWith('signup_') ? custom_text?.ack_default_payment?.file_name : custom_text?.ack_default_signup?.file_name,
    )),
    getSignupPopupCustomTextFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text } = {}) => custom_text?.signup?.file_name,
    )),
    getTrialCustomAckFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text } = {}) => custom_text?.ack_trial?.file_name,
    )),
    getSingleDonoCustomAckFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text } = {}) => custom_text?.ack_donation_single?.file_name,
    )),
    getRecurringDonoCustomAckFileNameById: new Duck.Selector(() => createSelector(
        duck.selectors.popupGetPopupById,
        ({ custom_text } = {}) => custom_text?.ack_donation_recurring?.file_name,
    )),
})

export default popups
