const generateAction = ({ baseActionCreatorName, baseActionTypeName }) => {
    const actions = {
        baseAction: {
            type: baseActionTypeName,
            actionCreatorName: baseActionCreatorName,
            creator: (duck) => ({ payload }) => ({
                type: duck.types[baseActionTypeName],
                ...payload,
            }),
        },
        successAction: {
            type: `${baseActionTypeName}_SUCCESS`,
            actionCreatorName: `${baseActionCreatorName}Success`,
            creator: (duck) => ({ payload }) => ({
                type: duck.types[`${baseActionTypeName}_SUCCESS`],
                payload,
            }),
        },
        errorAction: {
            type: `${baseActionTypeName}_ERROR`,
            actionCreatorName: `${baseActionCreatorName}Error`,
            creator: (duck) => ({ payload, error }) => ({
                type: duck.types[`${baseActionTypeName}_ERROR`],
                payload,
                error,
            }),
        },
    }

    const actionsArray = Object.values(actions)

    const types = actionsArray.map(({ type }) => type)

    const creators = (duck) => actionsArray.reduce((acc, { creator, actionCreatorName }) => {
        /* eslint-disable-next-line no-param-reassign */
        acc[actionCreatorName] = creator(duck)
        return acc
    }, {})

    return {
        types,
        creators,
    }
}

export default generateAction
