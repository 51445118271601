import createAccount from './createAccount'
import getHasPassword from './getHasPassword'
import newsletterSubscribe from './newsletterSubscribe'
import requestLoginLink from './requestLoginLink'
import requestOneTimeCode from './requestOneTimeCode'
import confirmOneTimeCode from './confirmOneTimeCode'
import resetOneTimeCode from './resetOneTimeCode'
import setLoginEmail from './setLoginEmail'
import setLoginPhone from './setLoginPhone'
import requestPasswordReset from './requestPasswordReset'
import update from './update'
import userJoinGroup from './userJoinGroup'
import deleteAccount from './deleteAccount'
import crossVerification from './crossVerification'

const sagas = (duck) => ({
    * createAccount(payload) {
        yield createAccount({ ...payload,
            ...duck })
    },
    * getHasPassword(payload) {
        yield getHasPassword({ ...payload,
            ...duck })
    },
    * newsletterSubscribe(payload) {
        yield newsletterSubscribe({ ...payload,
            ...duck })
    },
    * requestLoginLink(payload) {
        yield requestLoginLink({ ...payload,
            ...duck })
    },
    * requestOneTimeCode(payload) {
        yield requestOneTimeCode({ ...payload,
            ...duck })
    },
    * confirmOneTimeCode(payload) {
        yield confirmOneTimeCode({ ...payload,
            ...duck })
    },
    * setLoginEmail(payload) {
        yield setLoginEmail({ ...payload,
            ...duck })
    },
    * setLoginPhone(payload) {
        yield setLoginPhone({ ...payload,
            ...duck })
    },
    * requestPasswordReset(payload) {
        yield requestPasswordReset({ ...payload,
            ...duck })
    },
    * resetOneTimeCode(payload) {
        yield resetOneTimeCode({ ...payload,
            ...duck })
    },
    * update(payload) {
        yield update({ ...payload,
            ...duck })
    },
    * userJoinGroup(payload) {
        yield userJoinGroup({ ...payload,
            ...duck })
    },
    * deleteAccount(payload) {
        yield deleteAccount({ ...payload,
            ...duck })
    },
    * crossVerification(payload) {
        yield crossVerification({ ...payload, ...duck })
    },
})

export default sagas
