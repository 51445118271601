import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import isNil  from 'lodash/isNil'

import { format } from "piconetworks/pkg-currency"

import { selectors as loaderSelectors } from 'piconetworks/pkg-module-loader'

const selectors = (duck) => {
    const loader = loaderSelectors(duck)

    return {
        landingPage: new Duck.Selector(() => (state) => state?.landingPage),
        fetchedLandingPage: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ fetchedLandingPage } = {}) => fetchedLandingPage,
        )),
        fetchLandingPageError: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ fetchLandingPageError } = {}) => fetchLandingPageError,
        )),
        showOfferCode: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ showOfferCode } = {}) => showOfferCode,
        )),
        getAllLandingPages: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ data } = {}) => Object.values(data),
        )),
        getDefaultLandingPage: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ data } = {}) => data?.default_landing_page || data?.default_payment_landing_page,
        )),
        getDefaultPaymentLandingPage: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ data } = {}) => data?.default_payment_landing_page,
        )),
        getLandingPageError: new Duck.Selector((selectors) => createSelector(
            selectors.landingPage,
            ({ error } = {}) => error,
        )),
        getMappedFields: new Duck.Selector(() => createSelector(
            (fields) => fields || [],
            (fields) => {
                const typeMap = {
                    // Custom property types
                    dropdown: 'Dropdown',
                    short_answer: 'Text',
                    number: 'Number',
                    url: 'Text',
                    paragraph: 'Text',
                    radio: 'Radio',
                    boolean: 'Boolean',
                    checkbox: 'Checkbox',
                    // Standard property types
                    first_name: 'Text',
                    last_name: 'Text',
                    address: 'Address',
                    phone_number: 'Text',
                    gender: 'Text',
                    birthday: 'Text',
                    date: 'Date',
                }

                const mappedFields = fields.map(field => {
                    const formattedFields = {
                        id: field?.connected_property?.id,
                        title: field?.label,
                        description: field?.description,
                        type: typeMap?.[field?.connected_property?.type],
                        required: field?.required,
                        order: field?.order,
                        options: field?.connected_property?.options,
                    }
                    return formattedFields
                })
                return mappedFields
            },
        )),
        getFields: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            ({ fields } = {}) => {
                const mappedFields = selectors.getMappedFields(fields)
                return mappedFields
            },
        )),
        getProductFields: new Duck.Selector((selectors) => createSelector(
            (state, optionId) => selectors.landingPageGetProductByPlanOptionId(state, optionId),
            (_, optionId, product) => product,
            (product, passedProduct) => {
                const fields = passedProduct?.fields || product?.fields || []
                const mappedFields = selectors.getMappedFields(fields);
                return mappedFields
            },
        )),
        authenticationMethods: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            ({ authentication_methods = [] } = {}) => authentication_methods.length ? authentication_methods : ['email'],
        )),
        publisherAuthenticationMethods: (state) => {
            return state?.client?.authentication_methods || []
        },
        defaultPublisherAuthenticationMethod: (state) => {
            return state?.client?.default_authentication_method || 'email'
        },
        getLandingPageByShortCode: new Duck.Selector((selectors) => createSelector(
            (state) => selectors.landingPage(state) || {},
            selectors.getDefaultLandingPage,
            selectors.getDefaultPaymentLandingPage,
            (_, shortCode, type = 'signup', shouldNull) => ({ shortCode, type, shouldNull }),
            ({ data }, defaultLandingPage, defaultPaymentLandingPage, { shortCode, type, shouldNull }) =>
                data?.[shortCode]
                    ? data[shortCode]
                    : shouldNull
                        ? null
                        : (type === 'payment' ? defaultPaymentLandingPage : defaultLandingPage)

        )),
        getLandingPage: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPageByShortCode,
            selectors.getDefaultLandingPage,
            (shortCodelandingPage, defaultLandingPage) => shortCodelandingPage || defaultLandingPage
        )),
        isSignupLandingPage: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => {
                if (isNil(landingPage)) return false
                return landingPage.type === 'signup_landing_page' || landingPage?.type === 'standard_page'
            }
        )),
        getPaymentCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.payment?.file_name
        )),
        getSignupCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.signup?.file_name
        )),
        getPaymentAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_default_payment?.file_name
        )),
        getSignupAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_default_signup?.file_name
        )),
        getTrialAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_trial?.file_name
        )),
        getSingleDonoAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_donation_single?.file_name
        )),
        getRecurringDonoAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_donation_recurring?.file_name
        )),
        getGroupPaidAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_billing_admin?.file_name
        )),
        getGroupJoinedAckCustomTextFileName: new Duck.Selector((selectors) => createSelector(
            selectors.getLandingPage,
            (landingPage) => landingPage?.custom_text?.ack_group_member?.file_name
        )),
        landingPageGetProductByPlanOptionId: new Duck.Selector((selectors) => createSelector(
            selectors.getAllLandingPages,
            (_, optionId) => optionId,
            (allLandingPages, optionId) => {
                const getProductbyPlanOptionId = (landingPage) => {
                    let productFound

                    landingPage?.products?.forEach((product = {}) => {
                        const { options } = product
                        let optionFound = false

                        options?.forEach(({ id } = {}) => {
                            if (id === optionId) {
                                optionFound = true
                            }
                        })

                        if (optionFound) {
                            productFound = product
                        }
                    })

                    return productFound
                }

                let product

                allLandingPages.forEach(lp => {
                    const maybeProduct = getProductbyPlanOptionId(lp)

                    if (maybeProduct) {
                        product = maybeProduct

                    }
                })

                return product
            }
        )),
        landingPageGetOptionById: new Duck.Selector((selectors) => createSelector(
            selectors.getAllLandingPages,
            (_, optionId) => optionId,
            (allLandingPages, optionId) => {
                const getOptionById = (landingPage) => {
                    let optionFound = false

                    landingPage?.products?.forEach(({ options } = {}) => {
                        options?.forEach((option = {}) => {
                            const { id } = option
                            if (id === optionId) {
                                optionFound = option
                            }
                        })
                    })

                    return optionFound
                }

                let option

                allLandingPages.forEach(lp => {
                    const maybeOption = getOptionById(lp)

                    if (maybeOption) {
                        option = maybeOption
                    }
                })

                return option
            }
        )),
        getLandingPageByPlanOptionId: new Duck.Selector((selectors) => createSelector(
            selectors.getAllLandingPages,
            (_, optionId) => optionId,
            (allLandingPages, optionId) => {
                const getPopupByPlanOptionId = (landingPage) => {
                    let optionFound = false

                    landingPage?.products?.forEach(({ options } = {}) => {
                        options?.forEach((option = {}) => {
                            const { id } = option
                            if (id === optionId) {
                                optionFound = option
                            }
                        })
                    })

                    return optionFound && landingPage
                }

                let landingPage

                allLandingPages.forEach(lp => {
                    const maybeLP = getPopupByPlanOptionId(lp)

                    if (maybeLP) {
                        landingPage = maybeLP
                    }
                })

                return landingPage
            }
        )),
        landingPageGetProductById: new Duck.Selector((selectors) => createSelector(
            selectors.getAllLandingPages,
            (_, productId) => productId,
            (allLandingPages, productId) => {
                const getProductById = (landingPage) => {
                    let productFound

                    landingPage?.products?.forEach((product = {}) => {
                        const { id } = product

                        if (id === productId) {
                            productFound = product
                        }
                    })

                    return productFound
                }

                let product

                allLandingPages.forEach(lp => {
                    const maybeProduct = getProductById(lp)

                    if (maybeProduct) {
                        product = maybeProduct
                    }
                })

                return product
            }
        )),
        landingPageIsPledge: new Duck.Selector((selectors) => (state, { productId, optionId }) => {
            if (productId) {
                const product = selectors.landingPageGetProductById(state, productId)

                return product?.pledge_mode
            }

            if (optionId) {
                const product = selectors.landingPageGetProductByPlanOptionId(state, optionId)

                return product?.pledge_mode
            }

            return null
        }),
        capitalizeFirstLetter: (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        intervalAdverb: (interval) => {
            switch (interval) {
                case 'Month':
                    return 'Monthly'
                case 'Year':
                    return 'Yearly'
                case 'month':
                    return 'monthly'
                case 'year':
                    return 'yearly'
            }

            return ''
        },
        memberNounFromString: (string) => {
            const isPledge = string === 'membership'
            if (isPledge) {
                return 'membership'
            }

            const isSubscription = string === 'subscription'
            if (isSubscription) {
                return 'subscription'
            }

            const isPass = string === 'pass'
            if (isPass) {
                return 'pass'
            }

            const isDonation = (
                string === 'recurring-donation'
                || string === 'single-donation'
            )
            if (isDonation) {
                return 'contribution'
            }
            const isGroup = string === 'group'
            if (isGroup) {
                return 'group'
            }

            return null
        },
        currentSubscriptionPrice: (state) => {
            return state?.loader?.user?.purchases?.current?.product_option_price
        },
        currentSubscriptionCurrency: (state) => {
            return state?.loader?.user?.purchases?.current?.product_option_currency
        },
        priceText: loader.priceText,
        orderSummary: new Duck.Selector((selectors) => createSelector(
            (state, { productId }) => selectors.landingPageGetProductById(state, productId),
            (state, { optionId }) => selectors.landingPageGetOptionById(state, optionId),
            (state, { productId }) => selectors.landingPageIsPledge(state, { productId }),
            selectors.currentSubscriptionPrice,
            selectors.currentSubscriptionCurrency,
            (_, props) => props,
            (
                lpProduct,
                lpOption,
                isPledge,
                currentSubscriptionPrice,
                currentSubscriptionCurrency,
                {
                    customPrice,
                    option: _option,
                    product: _product,
                }
            ) => {
                // option or product can come from above lp selectors
                // or as whole populated objects coming from props
                // (when fetching a product from product checkout link)
                const product = lpProduct || _product
                const option = lpOption || _option

                if (!option || !product) {
                    return null
                }

                const priceText = selectors.priceText;
                //const showCurrencyCode = option.currency !== 'USD'
                const showCurrencyCode = true

                const intervalAdverb = selectors.capitalizeFirstLetter(selectors.intervalAdverb(option.interval))

                const formattedOptionType = isPledge ? 'membership' : option.type

                const productTitle = product.title || 'No Title'
                const optionTypeAndInterval = `${intervalAdverb} ${selectors.memberNounFromString(formattedOptionType)}`
                const renewalDate = dayjs().add(option.interval_quantity, option.interval).format('LL')
                const trialFinishDate = dayjs().add(option.trial_quantity, option.trial_interval).format('LL')
                const optionPrice = format({
                    currencyCode: option?.currency,
                    amount: option?.price,
                    symbol: !showCurrencyCode,
                })
                const optionInterval = option.interval

                const oldOptionPrice = (
                    currentSubscriptionPrice
                    && currentSubscriptionCurrency
                )
                    ? format({
                        currencyCode: currentSubscriptionCurrency,
                        amount: currentSubscriptionPrice,
                        symbol: !showCurrencyCode,
                    })
                    : null

                const parsedCustomPrice = parseInt(customPrice)
                const optionCustomPrice = !Number.isNaN(parsedCustomPrice) && format({
                    currencyCode: option?.currency,
                    amount: parsedCustomPrice,
                    symbol: !showCurrencyCode,
                })

                const trialPrice = format({
                    currencyCode: option?.currency,
                    amount: option?.trial_price,
                    symbol: !showCurrencyCode,
                })
                const isTrial = !!option?.trial_price || !!option?.trial_interval
                const isFree = isTrial && !((option?.trial_price || 0) > 0)

                const trialDuration = isTrial
                    && `${option.trial_quantity} ${option.trial_interval}${(option.trial_quantity > 1) ? 's' : ''}`

                const passDuration = (option.type === 'pass')
                    && `${option.interval_quantity} ${option.interval}${(option.interval_quantity > 1) ? 's' : ''}`

                return {
                    productTitle,
                    optionTypeAndInterval,
                    renewalDate,
                    optionPrice: priceText(optionPrice, option?.currency, showCurrencyCode),
                    optionInterval,
                    optionRecurring: option?.recurring,
                    optionPerSeat: option?.per_seat,
                    ...(oldOptionPrice && {
                        oldOptionPrice: priceText(oldOptionPrice, currentSubscriptionCurrency, showCurrencyCode),
                    }),
                    ...(optionCustomPrice && {
                        optionCustomPrice: priceText(optionCustomPrice, option?.currency, showCurrencyCode),
                    }),
                    ...(passDuration && { passDuration }),
                    trialPrice: isTrial && priceText(trialPrice, option?.currency, showCurrencyCode),
                    trialDuration,
                    trialFinishDate,
                    isTrial,
                    isFree
                }
            },
        )),
        getOrderType: (product, _option) => {
            let productType = null
            const option = _option
                || (product?.options?.length ? product.options[0] : {})

            const { type } = option
            if (type === 'subscription') {
                const isPledgeMode = product.pledge_mode
                const isTrial = (
                    (!!option.trial_price || option.trial_price === null)
                    && !!option.trial_interval
                    && !!option.trial_quantity
                )

                if (isPledgeMode) {
                    productType = 'pledge'
                } else if (isTrial) {
                    productType = 'trial'
                } else {
                    productType = type
                }
            } else {
                productType = type
            }

            return productType
        },
        getUTM: () => {
            const utm = typeof localStorage !== 'undefined' && localStorage.getItem('pico_utm')
            if (utm) {
                return JSON.parse(utm)
            }
            return null
        },
        isPayingText: new Duck.Selector((selectors) =>
            createSelector(
                selectors.getLandingPageByShortCode,
                (landingPage) => {
                    const { products = {}, type = '' } = landingPage || {}
                    const isDonation = products[0]?.type === 'donation'
                    return type === 'payment_landing_page' && !isDonation
                }
            )
        )
    }
}

export default selectors
