import { put, putResolve } from 'redux-saga/effects'

import {
    REFRESH_TOKEN,
} from 'piconetworks/pkg-network-middleware'

function* useRefreshToken({ types }) {
    try {
        yield putResolve({
            type: REFRESH_TOKEN,
        })

        yield put({
            type: types.USE_REFRESH_TOKEN_SUCCESS,
            payload: {
                response: {},
            },
        })
    } catch (error) {
        yield put({
            type: types.USE_REFRESH_TOKEN_ERROR,
            error: error.message,
        })
    }
}

export default useRefreshToken
