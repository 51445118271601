import axios from 'axios'
import Marked from 'marked'
import DOMPurify from 'dompurify'

import { call, put } from 'redux-saga/effects'

import { creators } from '../actions'

let markdownFetchCache = {}

const marked = (
    markdownString,
    options,
) => new Promise((resolve, reject) => (
    Marked(markdownString, options, (err, html) => {
        if (err) {
            return reject(err)
        }
        return resolve(html)
    })
))

function* fetchMarkdown({
    payload,
}) {
    const url = payload?.url
    const options = payload?.options || {}

    if (markdownFetchCache[url]) {
        return
    }

    markdownFetchCache[url] = url

    try {
        if (!url) {
            throw new Error('markdown url must be provided')
        }

        const response = yield call(axios, {
            method: 'get',
            timeout: 5000,
            url,
        })

        if (!response || !response.data) {
            throw new Error('response object not returned from axios')
        }

        const html = yield call(marked, response.data, options)

        const cleanHtml = DOMPurify.sanitize(html)

        yield put(
            yield call(
                creators.fetchMarkdownSuccess,
                {
                    payload: {
                        url,
                        html: cleanHtml,
                    },
                },
            ),
        )
    } catch (error) {
        delete markdownFetchCache[url]

        yield put(
            yield call(
                creators.fetchMarkdownError,
                {
                    payload: {
                        url: typeof url === 'undefined' ? null : url,
                    },
                    error: error.message,
                },
            ),
        )
    }
}

export default fetchMarkdown
