import getNewsletters from './getNewsletters'

const sagas = (duck) => ({
    * retrieveNewsletters(payload) {
        yield getNewsletters({
            ...payload,
            ...duck,
        })
    }
})

export default sagas
