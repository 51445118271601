import LandingPageAccountCreate from './account_create.model'
import LandingPageAccountSubmit from './account_submit.model'
import LandingPageLinkClick from './link_click.model'
import LandingPageOtpGenerated from './otp_generated.model'
import LandingPageOtpRedeemed from './otp_redeemed.model'
import LandingPageOtpRequested from './otp_requested.model'
import LandingPagePageView from './page_view.model'
import LandingPagePasswordCreated from './password_created.model'
import LandingPagePasswordUpdated from './password_updated.model'
import LandingPagePicoLinkView from './pico_link_view.model'
import LandingPagePromptConverted from './prompt_converted.model'
import LandingSignupToCreator from './signup_to_creator.model'
import LandingPageUserLogin from './user_login.model'
import LandingPageUserPayment from './user_payment.model'

const schemas = {
    user_login: LandingPageUserLogin,
    page_view: LandingPagePageView,
    user_payment: LandingPageUserPayment,
    prompt_converted: LandingPagePromptConverted,
    link_click: LandingPageLinkClick,
    pico_link_view: LandingPagePicoLinkView,
    account_create: LandingPageAccountCreate,
    signup_to_creator: LandingSignupToCreator,
    account_submit: LandingPageAccountSubmit,
    otp_requested: LandingPageOtpRequested,
    otp_generated: LandingPageOtpGenerated,
    otp_redeemed: LandingPageOtpRedeemed,
    password_created: LandingPagePasswordCreated,
    password_updated: LandingPagePasswordUpdated
}

export default schemas
