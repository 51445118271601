/* eslint-disable camelcase */

import Account from 'modules/account'

import storeSubscriber from '../storeSubscriber'
import getAnalytics from '../../analytics'

const confirmOneTimeCode = async ({
    dispatch,
    payload: {
        code,
        customProperties,
        email,
        newsletters,
        origin,
        phone,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        utm,
        is_login = false,
    },
}) => {
    const { track } = getAnalytics()

    dispatch(Account.creators.confirmOneTimeCode({
        code,
        customProperties,
        email,
        newsletters,
        origin,
        phone,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        utm,
        is_login,
    }))

    let claim = null
    let errorMessage
    let isNewRegistration
    let oneTimeCodeConfirmed
    let response
    let verifyFlowId

    try {
        response = await storeSubscriber({
            checks: [
                (state) => {
                    claim = Account.selectors.getClaim(state)
                    errorMessage = Account.selectors.oneTimeCodeError(state)
                    isNewRegistration = Account.selectors.isNewRegistration(state)
                    oneTimeCodeConfirmed = Account.selectors.oneTimeCodeConfirmed(state)
                    verifyFlowId = Account.selectors.getVerifyFlowId(state)

                    if (errorMessage) {
                        throw new Error(errorMessage)
                    }

                    return (
                        oneTimeCodeConfirmed === true
                    )
                },
            ],
        })
    } catch (error) {
        console.log('error', error)
        response = { error }
    }

    //Send event otp_redeemed
    await track('otp_redeemed', {
        success: !!oneTimeCodeConfirmed,
        verify_flow_id: verifyFlowId
    }, {
        plugins: {
            segment: false,
        },
    })


    if (response?.error) {
        throw new Error(response.error)
    }

    return {
        claim,
        errorMessage,
        isNewRegistration,
        oneTimeCodeConfirmed,
    }
}

export default confirmOneTimeCode
