const types = [
    'CREATE_GIFT',
    'CREATE_GIFT_SUCCESS',
    'CREATE_GIFT_ERROR',
    'REDEEM_GIFT',
    'REDEEM_GIFT_SUCCESS',
    'REDEEM_GIFT_ERROR',
    'CONVERT_GIFT',
    'CONVERT_GIFT_SUCCESS',
    'CONVERT_GIFT_ERROR',
    'GET_GIFT',
    'GET_GIFT_SUCCESS',
    'GET_GIFT_ERROR',
    'GET_GIFT_PRODUCT',
    'GET_GIFT_PRODUCT_SUCCESS',
    'GET_GIFT_PRODUCT_ERROR',
]

const creators = (duck) => ({
    createGift: (payload) => ({
        type: duck.types.CREATE_GIFT,
        ...payload,
    }),
    redeemGift: (payload) => ({
        type: duck.types.REDEEM_GIFT,
        ...payload,
    }),
    convertGift: (payload) => ({
        type: duck.types.CONVERT_GIFT,
        ...payload,
    }),
    getGift: (payload) => ({
        type: duck.types.GET_GIFT,
        ...payload,
    }),
    getGiftProduct: (payload) => ({
        type: duck.types.GET_GIFT_PRODUCT,
        ...payload,
    })
})

export {
    types,
    creators,
}
