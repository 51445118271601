import React from 'react'

export default ({ color = '#ffc107', height = '17', width = '20' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 20 17"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-2 -4)">
            <path d="m0 0h24v24h-24z" />
            <path
                d="m4.46889451 20.9999987c-.35188519 0-.69754185-.0928401-1.00207254-.2691474-.95592348-.5534294-1.28220942-1.7770016-.72878003-2.7329251l7.53110476-13.00827188c.175087-.30242304.426357-.55369301.72878-.72878003.9559235-.55342938 2.1794958-.22714345 2.7329252.72878003l7.5311047 13.00827188c.1763073.3045307.2691475.6501874.2691475 1.0020725 0 1.1045695-.8954305 2-2 2zm6.74710549-6.719999h1.568l.336-3.0879998.084-2.07200001h-2.408l.084 2.07200001zm.784 4.3880002c.4106687 0 .7466654-.1399986 1.008-.42s.392-.6253313.392-1.036-.1306654-.7559986-.392-1.036-.5973313-.42-1.008-.42-.7466654.1399986-1.008.42-.392.6253313-.392 1.036.1306654.7559986.392 1.036.5973313.42 1.008.42z"
                fill={color}
            />
        </g>
    </svg>
)
