import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_ARTICLE, sagas.getArticle),
    takeEvery(types.UPDATE_ARTICLE, sagas.updateArticle),
    takeEvery(types.AUTHENTICATED_ARTICLE_READ, sagas.authenticatedArticleRead),
    takeEvery(types.AUTHENTICATED_ARTICLE_VISIT, sagas.authenticatedArticleVisit),
    takeEvery(types.GUEST_ARTICLE_READ, sagas.guestArticleRead),
    takeEvery(types.GUEST_ARTICLE_VISIT, sagas.guestArticleVisit)
])

export default takes
