import React from 'react'
import PropTypes from 'prop-types'

import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const ManagePlanCancelPlan = ({ changeMenuRoute, userLoader, guestLoader, utmRootUrl, queryOverride }) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/managePlan') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Cancel Plan</h3>
            </div>
            {/* Cancel Plan Container */}
        </MenuWrapper>
    )
}

ManagePlanCancelPlan.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    utmRootUrl: PropTypes.string,
    userLoader: PropTypes.any.isRequired,
    guestLoader: PropTypes.any.isRequired,
    queryOverride:PropTypes.object,
}

export default ManagePlanCancelPlan
