import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-8 -8)">
            <rect height="56" width="56" fill="#F9F4FF" rx="28" x="8" y="8" />
            <path
                d="M51.3511,28.9454 C41.0098,31.6267 30.6686,25.336 20.3273,28.3267 C19.8102,28.4298 19.5,28.8423 19.5,29.2548 L19.5,45.6517 C19.5,46.3735 20.1205,46.786 20.741,46.6829 C31.0822,44.0017 41.4235,50.2923 51.7648,47.3017 C52.1784,47.1985 52.4886,46.786 52.4886,46.2704 L52.4886,29.8735 C52.5921,29.2548 51.9716,28.7392 51.3511,28.9454 Z M36.046,42.9704 C33.9778,42.9704 32.3232,40.7017 32.3232,37.8142 C32.3232,34.9267 33.9778,32.6579 36.046,32.6579 C38.1143,32.6579 39.7689,34.9267 39.7689,37.8142 C39.7689,40.7017 38.1143,42.9704 36.046,42.9704 Z"
                fill="#7A34FF"
            />
        </g>
    </svg>
)
