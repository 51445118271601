import axios from 'axios'

const getQuery = async ({ publisherId, baseURL }) => {
    const response = await axios({
        method: 'post',
        baseURL,
        url: `/query`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            publisherid: publisherId,
        },
        timeout: 30000,
        data: {
            query: `{
                products(
                    publisher_id:"${publisherId}"
                )
                {   
                    products{
                        id,
                        publisher_id,
                        type, 
                        title, 
                        pledge_mode, 
                        match_type, 
                        product_label, 
                        custom_text{
                            filename
                        },
                        options{
                            id,
                            type,
                            price,
                            currency,
                            recurring,
                            per_seat,
                            interval,
                            user_created,
                            interval_quantity,
                            short_code,
                            trial_interval,
                            trial_quantity,
                            trial_price
                        }
                    }
                },
                client(id:"${publisherId}")
                {
                    has_multiple_page_feature,
                    has_multiple_pages
                }
            }`
        },
    })

    return response?.data?.data || {}
}

export default getQuery