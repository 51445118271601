import SignupUserLogin from './user_login.model'
import SignupUserUpdate from './user_update.model'
import SignupPageView from './page_view.model'
import SignupPageSubmit from './page_submit.model'
import SignupPageAbandon from './page_abandon.model'


const schemas = {
    user_login: SignupUserLogin,
    user_update: SignupUserUpdate,
    page_view: SignupPageView,
    page_submit: SignupPageSubmit,
    page_abandon: SignupPageAbandon
}

export default schemas
