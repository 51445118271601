/* eslint-disable max-len */

import actionCreatorFactory from '../../pkgs/actionCreatorFactory'

/* eslint-disable no-undef */
const types = {
    SYNC_USER_DATA_AFTER_PASSWORD_REGISTER: 'SYNC_USER_DATA_AFTER_PASSWORD_REGISTER',
    LOGOUT_AND_EXCHANGE_CLAIM: 'LOGOUT_AND_EXCHANGE_CLAIM',
}

const creators = () => ({
    syncUserDataAfterPasswordRegister: actionCreatorFactory(types.SYNC_USER_DATA_AFTER_PASSWORD_REGISTER),
    logoutAndExchangeClaim: actionCreatorFactory(types.LOGOUT_AND_EXCHANGE_CLAIM),
})

export {
    types,
    creators,
}
