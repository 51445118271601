/* eslint-disable camelcase */

const PICO_QUERY_PARAMS = {
    pico_action: null,
    pico_ui: null,
    pico_access_token: null,
    pico_refresh_token: null,
    pico_has_password: null,
    pico_email: null,
    pico_discount_code: null,
    pico_custom_price: null,
    pico_claim: null,
    pico_new_user: null,
    pn: null,
    gift_id: null,
    pico_token: null,
}

const QueryStringParser = (query = PICO_QUERY_PARAMS, removeParams = true) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    const queryParams = Object.keys(query).reduce((acc, key) => {
        // eslint-disable-next-line no-param-reassign
        acc = {
            ...acc,
            [key]: searchParams.get(key),
        }

        searchParams.delete(key)

        return acc
    }, {})

    url.search = searchParams.toString()

    if (window.history.replaceState && removeParams) {
        window.history.replaceState(null, null, url.toString())
    }

    return {
        params: queryParams,
        url: url.toString(),
    }
}

export default QueryStringParser
