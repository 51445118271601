import Joi from 'joi'

const DashboardPicoLinkClickFeatured = Joi.object()
    .keys({
        featured_creator: Joi.string().required().description('The username of the featured creator'),
    })
    .description('dashboard:pico_link:click_featured event attributes ')
    .meta({ name: 'DashboardPicoLinkClickFeatured', filename: 'dashboard:pico_link:click_featured.model' })

export default DashboardPicoLinkClickFeatured
