import { ThemeBlockBackgroundValue, FormBlockBackgroundValue } from '../Properties/BlockBackgroundValue'
import PropertyType from '../Modifiers/PropertyType'

enum BlockBackgroundTypeOptions {
    color = 'color',
}

const themeBackgroundTypeFunction = (backgroundType: string) => {
    const backgroundValue = ThemeBlockBackgroundValue.getValue()

    switch (backgroundType) {
        case BlockBackgroundTypeOptions.color:
        default:
            return `background-color: ${backgroundValue}; backdrop-filter: blur(8px);`
    }
}

const formBackgroundTypeFunction = (backgroundType: string) => {
    const backgroundValue = FormBlockBackgroundValue.getValue()

    switch (backgroundType) {
        case BlockBackgroundTypeOptions.color:
        default:
            return `background-color: ${backgroundValue}; backdrop-filter: blur(8px);`
    }
}

const ThemeBlockBackgroundType = new PropertyType(
    {
        name: 'BlockBackgroundType',
        propertyFromApi: 'block-background-type',
        convertValueToCssPropertiesFunction: themeBackgroundTypeFunction,
        defaultValue: 'color',
        options: BlockBackgroundTypeOptions,
        selector: '.block',
        isCompounded: true,
    },
    'theme'
)

const FormBlockBackgroundType = new PropertyType(
    {
        name: 'BlockBackgroundType',
        propertyFromApi: 'block-background-type',
        convertValueToCssPropertiesFunction: formBackgroundTypeFunction,
        defaultValue: 'color',
        options: BlockBackgroundTypeOptions,
        selector: '.block',
        isCompounded: true,
    },
    'form'
)

/*
 * Block BlockBackgroundType has only one value for now: color
 * In the future we might want to add additional things like gradients
 */
export {
    ThemeBlockBackgroundType,
    FormBlockBackgroundType,
}
