import React from 'react'

export default ({ color = 'currentcolor', width = '24', height = '24' }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm0 2a1 1 0 011 1v4.423l4.696 2.711a1 1 0 11-1 1.732l-5.196-3-.015-.009-.028-.017-.015-.01.017.01-.023-.015-.05-.035a1.108 1.108 0 01-.043-.036l-.02-.017-.017-.017a1.23 1.23 0 01-.078-.085l-.04-.052-.022-.032a1.043 1.043 0 01-.035-.056l-.012-.022-.012-.022a.856.856 0 01-.067-.17l-.008-.029a.82.82 0 01-.015-.069l-.004-.024-.004-.023A.79.79 0 0111 12V7a1 1 0 011-1z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
