import qs from 'qs'

const utmLink = ({ 
    utmCampaign = null,
    utmSource = null,
    utmMedium = null,
    utmContent = null,
    rootUrl = '',
} = {}) => {
    const queryString = qs.stringify({
        utm_campaign: utmCampaign,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
    }, {
        skipNulls: true,
    })

    const trypico_link = `${rootUrl}/?${queryString}`
    const terms_link = `${rootUrl}/terms/user-terms/?${queryString}`

    return {
        trypico_link,
        terms_link,
    }
}

export default utmLink
