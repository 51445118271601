import {
    put, putResolve, call
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    GROUPS_USER,
} from 'piconetworks/pkg-endpoints'

function* userJoinGroup({
    types,
    code,
    groupId,
    newsletters,
    customFields,
    publisherId,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        const requestData = {
            ...(code && { code }),
            ...(newsletters && { newsletter_ids: newsletters }),
            ...(customFields && { custom_properties: customFields }),
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: `${GROUPS_USER()}/${groupId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: requestData,
            },
        })

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.USER_JOIN_GROUP_SUCCESS,
            payload: {
                ...data,
            },
        })
    } catch (error) {
        yield put({
            type: types.USER_JOIN_GROUP_ERROR,
            error: error.message,
        })
    }
}

export default userJoinGroup
