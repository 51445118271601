import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-8 -8)">
            <rect height="56" width="56" fill="#F9F4FF" rx="28" x="8" y="8" />
            <path
                d="M36,36.5 C38.2091,36.5 40,34.7091 40,32.5 C40,30.2909 38.2091,28.5 36,28.5 C33.7909,28.5 32,30.2909 32,32.5 C32,34.7091 33.7909,36.5 36,36.5 Z M45,42 C45,39.0043 38.9957,37.5 36,37.5 C33.0043,37.5 27,39.0043 27,42 L27,45.2111 C27,45.923 27.5785,46.5 28.2945,46.5 L43.7055,46.5 C44.4204,46.5 45,45.9254 45,45.2111 L45,42 Z"
                fill="#7A34FF"
            />
            <path
                d="M47.1111 28.6111C47.1111 30.3293 45.7182 31.7222 44 31.7222 42.2818 31.7222 40.8889 30.3293 40.8889 28.6111 40.8889 26.8929 42.2818 25.5 44 25.5 45.7182 25.5 47.1111 26.8929 47.1111 28.6111zM42.2176 35.719C44.4647 36.5528 46.5287 37.8136 47.4675 39.5L49.9932 39.5C50.5492 39.5 51 39.0531 51 38.4976L51 36C51 33.67 46.33 32.5 44 32.5 43.7003 32.5 43.3619 32.5194 42.9998 32.558 42.9905 33.6972 42.7092 34.7714 42.2176 35.719zM31.1111 28.6111C31.1111 30.3293 29.7182 31.7222 28 31.7222 26.2818 31.7222 24.8889 30.3293 24.8889 28.6111 24.8889 26.8929 26.2818 25.5 28 25.5 29.7182 25.5 31.1111 26.8929 31.1111 28.6111zM29.7824 35.719C29.2908 34.7714 29.0095 33.6972 29.0002 32.558 28.6381 32.5194 28.2997 32.5 28 32.5 25.67 32.5 21 33.67 21 36L21 38.4976C21 39.0512 21.4499 39.5 22.0068 39.5L24.5325 39.5C25.4713 37.8136 27.5353 36.5528 29.7824 35.719z"
                fill="#C9ADFF"
            />
        </g>
    </svg>
)
