import { useSelector } from 'react-redux'
import React from 'react'
import Client from 'modules/client'

const useClaimThisPage = () => {
    const client = useSelector(Client.selectors.client)
    const username = client?.username
    const showClaimThisPage = client?.claimed === false

    return {
        showClaimThisPage,
        username,
    }
}

export default useClaimThisPage
