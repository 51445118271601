import Joi from 'joi'
import authMethods from '../utils/authMethods'

const LandingPageAccountCreate = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        auth_method: Joi.string().valid(...authMethods).required().description('The auth method user used to sign up'),
    })
    .description('landing_page:account_create event attributes ')
    .meta({ name: 'LandingPageAccountCreate', filename: 'landing_page:account_create.model' })

export default LandingPageAccountCreate
