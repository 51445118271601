import React from 'react'
import PropTypes from 'prop-types'
const MasterCard = ({ width = '42px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 42 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_payment/mastercard">
                <circle id="Oval" fill="#EA001B" cx="13.59" cy="12" r="12" />
                <circle id="Oval" fill="#F79F1A" cx="28.4099998" cy="12" r="12" />
                <path
                    d="M21.0010004,2.56128517 C23.7953384,4.75836765 25.59,8.16960099 25.59,12 C25.59,15.830399 23.7953384,19.2416323 21.0010004,21.4387148 C18.2050919,19.2424504 16.4099998,15.8308583 16.4099998,12 C16.4099998,8.22747032 18.1508439,4.86153656 20.8730369,2.66169381 Z"
                    id="Combined-Shape"
                    fill="#FF5F01"
                />
            </g>
        </g>
    </svg>
)

MasterCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default MasterCard
