import getDefaultLandingPage from './getDefaultLandingPage'
import getLandingPage from './getLandingPage'
import getDefaultPopup from './getDefaultPopup'
import getPopup from './getPopup'

const sagas = (duck) => ({
    * getDefaultLandingPage(payload) {
        yield getDefaultLandingPage({ ...payload, ...duck })
    },
    * getLandingPage(payload) {
        yield getLandingPage({ ...payload, ...duck })
    },
    * getDefaultPopup(payload) {
        yield getDefaultPopup({ ...payload, ...duck })
    },
    * getPopup(payload) {
        yield getPopup({ ...payload, ...duck })
    },
})

export default sagas
