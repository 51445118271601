import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import { format } from 'piconetworks/pkg-currency'
import Tooltip from 'piconetworks/pkg-tooltip'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanSubscription = ({
    Link,
    changeMenuRoute,
    currentBillingIntervalTotalPaid,
    currentProductOptionId,
    currentPurchaseCanceled,
    currentPurchaseFailed,
    currentSubscriptionStart,
    nextSubscriptionStart,
    nextSubscriptionEnd,
    currentSubscriptionIsTrial,
    nextStatusNext,
    currentSubscriptionId,
    nextProductOptionPrice,
    onClickChangePlan,
    discordLink,
    originalEnrollmentDate,
    userLoader,
    guestLoader,
    publisherId,
    router,
    closeMenu,
    keepPlan,
    currentProductOptionCurrency,
    next,
    utmRootUrl,
    queryOverride,
    isGroupMember,
    pubPriceChangeDate,
    groupName,
    priceText,
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const {
        query: {
            company_slug,
        } = {},
    } = router

    const isWhitelistedReader = currentSubscriptionId === 'WHITELIST'
    const nextPaymentDate = nextStatusNext ? nextSubscriptionStart : nextSubscriptionEnd
    const planTierTitle = next?.plan_tier_title
    const isPayingIndividual = !isWhitelistedReader && !isGroupMember
    const isNotCancelled = !currentPurchaseCanceled || (currentPurchaseCanceled && nextStatusNext)
    const isSwitching = currentProductOptionId !== next?.productOptionId

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Subscription</h3>
            </div>
            <div className={style.container}>
                <nav className={style.menuContent} style={{ color: linkColor, borderBottom: '1px solid #ddd' }}>
                    {isWhitelistedReader && (<li className={style.list}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                You have access to {planTierTitle || publisherName} until
                                {' '}
                                <strong>{dayjs(nextSubscriptionEnd).format('LL')}</strong>
                            </div>
                        </div>
                    </li>)}
                    {isGroupMember && (<li className={style.list}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                You have access to {publisherName} provided by
                                {' '}
                                <strong>{groupName}</strong>.
                                Please contact your group administrator to learn more.
                            </div>
                        </div>
                    </li>)}
                    {isPayingIndividual && <li className={style.list}>
                        <p className={style.listTitle}>Subscriber Since</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {dayjs(originalEnrollmentDate).format('LL')}
                            </div>
                        </div>
                    </li>}
                    {isPayingIndividual && <li className={style.list}>
                        <p className={style.listTitle}>{`${isNotCancelled ? 'Last' : 'Final'} Payment`}</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {`${priceText(format({
                                    amount: currentBillingIntervalTotalPaid,
                                    currencyCode: currentProductOptionCurrency,
                                    symbol: false
                                }), currentProductOptionCurrency)} on ${dayjs(currentSubscriptionStart).format('LL')}`}
                            </div>
                        </div>
                    </li>}
                    {isPayingIndividual && isNotCancelled && (
                        <li className={style.list}>
                            <p className={style.listTitle}>Next Payment</p>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor, style.warningTooltipContainer)}>
                                    <span>
                                        {`${priceText(format({
                                            amount: nextProductOptionPrice,
                                            currencyCode: next?.product_option_currency,
                                            symbol: false,
                                        }), next?.product_option_currency)} on ${dayjs(nextPaymentDate).format('LL')}`}
                                    </span>
                                    {isPayingIndividual
                                        && isNotCancelled
                                        && nextProductOptionPrice !== currentBillingIntervalTotalPaid
                                        && !currentSubscriptionIsTrial
                                        && !isSwitching ? (
                                            <span className={style.warningTooltip}>
                                                <Tooltip label={`The price of a subscription changed on ${dayjs(pubPriceChangeDate, 'X').format('LL')}`} placement="top">
                                                    <span style={{ color: linkColor }} className={classNames('icon-info', style.warningTooltipIcon)}></span>
                                                </Tooltip>
                                            </span>
                                        ) : null}
                                </div>
                            </div>
                        </li>
                    )}
                    {isPayingIndividual && !isNotCancelled && (
                        <li className={style.list}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    Your subscription is set to end on
                                    {' '}
                                    <strong>{dayjs(nextSubscriptionEnd).format('LL')}</strong>
                                </div>
                            </div>
                        </li>
                    )}

                    {discordLink && (
                        <li className={style.list}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    Your discord access link is
                                    {' '}
                                    <strong>{discordLink}</strong>
                                </div>
                            </div>
                        </li>
                    )}
                </nav>
                <nav className={classNames(style.menuContent, style.planOption, style.btnLinkGroup)} style={{ color: linkColor }}>
                    {(isPayingIndividual &&
                        isNotCancelled
                    ) && (
                        <li className={style.mainMenuItem}>
                            <Boutton
                                icon={<Picon icon='icon_switch' size='24' color={linkColor} />}
                                variant="link"
                                onClick={onClickChangePlan ? () => onClickChangePlan(currentProductOptionId) : () => router.push('/checkout')}
                            >
                                Change plan
                            </Boutton>
                        </li>
                    )}
                    {isPayingIndividual && (isNotCancelled || currentPurchaseFailed) && (
                        <Link
                            href="/cards"
                            as={'/cards'}
                        >
                            <a
                                onClick={() => {
                                    closeMenu()
                                }}
                            >
                                <li className={style.mainMenuItem}>
                                    <Boutton
                                        icon={<Picon icon='icon_card' size='24' color={linkColor} />}
                                        variant="link"
                                    >
                                        Change payment method
                                    </Boutton>
                                </li>
                            </a>
                        </Link>
                    )}
                    {isPayingIndividual && isNotCancelled && (
                        <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/subscription/stopSubscription') }}>
                            <Boutton
                                icon={<Picon icon='icon_negative' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Cancel subscription
                            </Boutton>
                        </li>
                    )}
                    {isPayingIndividual && !isNotCancelled && !currentPurchaseFailed && (
                        <li
                            className={style.mainMenuItem}
                            onClick={() => {
                                keepPlan({
                                    productOptionId: currentProductOptionId,
                                    publisherId,
                                    subscriptionId: currentSubscriptionId,
                                })
                            }}
                        >
                            <Boutton
                                icon={<Picon icon='icon_undo' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Keep subscription
                            </Boutton>
                        </li>
                    )}
                </nav>
            </div>
        </MenuWrapper>
    )
}

ManagePlanSubscription.defaultProps = {
    publisherId: null,
    pubPriceChangeDate: null,
}

ManagePlanSubscription.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    next: PropTypes.shape({
        product_option_currency: PropTypes.string.isRequired,
    }).isRequired,
    currentProductOptionCurrency: PropTypes.string.isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride: PropTypes.object,
    pubPriceChangeDate: PropTypes.string,
    priceText: PropTypes.func.isRequired,
    discordLink: PropTypes.string,
    guestLoader: PropTypes.object,
    userLoader: PropTypes.object,
}

export default ManagePlanSubscription
