import { produce } from 'immer'

export const initialState = {
    purchases: {
        data: null,
        error: null
    },
    product: {
        data: null,
        error: null,
    },
    switchProducts: {
        data: [],
        loading: false,
        error: null,
        switchProductId: null,
    },
    switchProration: {
        data: null,
        error: null
    },
    gift: {
        data: null,
        error: null,
    }
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_PURCHASE_HISTORY:
            draftState.purchases = initialState.purchases
            break

        case duck.types.GET_PURCHASE_HISTORY_SUCCESS:
            draftState.purchases.data = payload
            break

        case duck.types.GET_PURCHASE_HISTORY_ERROR:
            draftState.purchases.error = error
            break

        case duck.types.GET_SWITCH_PRODUCTS:
            draftState.switchProducts.error = null
            draftState.switchProducts.loading = true
            break

        case duck.types.GET_SWITCH_PRODUCTS_SUCCESS:
            draftState.switchProducts.data = payload?.data
            draftState.switchProducts.loading = false
            draftState.switchProducts.switchProductId = payload?.productId
            break

        case duck.types.CREATE_SWITCH_PRODUCT_ERROR:
        case duck.types.GET_SWITCH_PRODUCTS_ERROR:
            draftState.switchProducts.error = error
            draftState.switchProducts.switchProductId = null
            draftState.switchProducts.loading = false
            break

        case duck.types.CREATE_SWITCH_PRODUCT:
            draftState.switchProducts.error = initialState.switchProducts.error
            break

        case duck.types.CREATE_SWITCH_PRODUCT_SUCCESS:
            draftState.switchProducts.data = [...draftState.switchProducts.data, payload]
            break

        case duck.types.GET_SWITCH_PRORATION:
            draftState.switch_proration = initialState.switch_proration
            break

        case duck.types.GET_SWITCH_PRORATION_SUCCESS:
            draftState.switch_proration.data = payload
            break

        case duck.types.GET_SWITCH_PRORATION_ERROR:
            draftState.switch_proration.error = error
            break

        case duck.types.GET_GIFT:
        case duck.types.UPDATE_GIFT:
        case duck.types.CREATE_GIFT:
        case duck.types.DELETE_GIFT_SUCCESS:
            draftState.gift = initialState.gift
            break

        case duck.types.GET_GIFT_SUCCESS:
        case duck.types.UPDATE_GIFT_SUCCESS:
        case duck.types.CREATE_GIFT_SUCCESS:
            draftState.gift.data = payload
            break

        case duck.types.DELETE_GIFT_ERROR:
        case duck.types.UPDATE_GIFT_ERROR:
        case duck.types.CREATE_GIFT_ERROR:
        case duck.types.GET_GIFT_ERROR:
            draftState.gift.error = error
            break

        case duck.types.CANCEL_PRODUCT:
        case duck.types.REACTIVATE_PRODUCT:
        case duck.types.PURCHASE_PRODUCT:
            draftState.product = initialState.product
            break

        case duck.types.PURCHASE_PRODUCT_SUCCESS:
        case duck.types.REACTIVATE_PRODUCT_SUCCESS:
        case duck.types.CANCEL_PRODUCT_SUCCESS:
            draftState.product.data = payload
            break

        case duck.types.REACTIVATE_PRODUCT_ERROR:
        case duck.types.CANCEL_PRODUCT_ERROR:
        case duck.types.PURCHASE_PRODUCT_ERROR:
            draftState.product.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
