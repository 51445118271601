import React from 'react'
import cn from 'classnames'
import style from './scss/Account.module.scss'

const PasswordWarning = ({
    warning,
}) => {
    if (!warning) {
        return null
    }

    return (
        <div className={cn(style.passwordWarning)}>{warning}</div>
    )
}

export default PasswordWarning
