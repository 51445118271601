import signupSchemas from './signup'
import dashboardSchemas from './dashboard'
import gadgetSchemas from './gadget'
import landingPageSchemas from './landing_page'

const signup = {
    ...signupSchemas
}

const dashboard = {
    ...dashboardSchemas
}

const landing_page = {
    ...landingPageSchemas
}

const gadget = {
    ...gadgetSchemas
}

const eventSchemas = {
    signup,
    dashboard,
    landing_page,
    gadget
}

export default eventSchemas
