import { FORM_ERROR } from 'final-form'
import Loading from 'piconetworks/pkg-loading'

const WIZARD_LOADING = 'WizardLoading'

class WizardLoading extends Error {
    constructor(message = 'loading', options = {}) {
        super(message)
        this.name = WIZARD_LOADING
        this.options = options
    }

    static get FORM_ERROR() {
        return FORM_ERROR
    }

    static get Loading() {
        return Loading
    }

    static isError(error) {
        if (error && error.name === WIZARD_LOADING) {
            return true
        }
        return false
    }
}

export default WizardLoading
