import {
    put, putResolve
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    NEWSLETTERS_SUBSCRIBE,
} from 'piconetworks/pkg-endpoints'

function* newsletterSubscribe({
    types,
    newsletterIds,
    publisherId,
    sync,
    onlyAddNewsletters = false,
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: NEWSLETTERS_SUBSCRIBE(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    newsletters: newsletterIds,
                    sync,
                    publisherId,
                    only_add_newsletters: onlyAddNewsletters,
                },
            },
        })

        yield put({
            type: types.NEWSLETTER_SUBSCRIBE_SUCCESS,
            payload: {
                ...data,
            },
        })
    } catch (error) {
        yield put({
            type: types.NEWSLETTER_SUBSCRIBE_ERROR,
            error: error.message,
        })
    }
}

export default newsletterSubscribe
