import { put, putResolve } from 'redux-saga/effects'

function* socialLogin({
    types, creators, error, accessToken, refreshToken, newRegisteredUser = null
}) {
    try {
        if (error) {
            throw new Error(error)
        }

        yield putResolve(creators.tokenLogin({
            accessToken,
            refreshToken,
        }))

        yield put({
            type: types.SOCIAL_LOGIN_SUCCESS,
            payload: {
                accessToken,
                refreshToken,
                newRegisteredUser
            },
        })
    } catch (error) {
        yield put({
            type: types.SOCIAL_LOGIN_ERROR,
            error: error.message,
        })
    }
}

export default socialLogin

