export const PICO_API_URL = () => process.env.PICO_API_URL
export const LANG_URL = () => 'https://lang.pico.tools'

export const GIFTS = () => '/gifts'

export const GROUPS_USER = () => '/purchase/group'

export const NEWSLETTERS_SUBSCRIBE = () => '/newsletters/subscribe'
export const NEWSLETTERS = () => '/me/newsletters'

export const AUTH_USER = () => '/auth/user'
export const AUTH_USER_CLAIM = () => '/auth/user/claim'
export const AUTH_USER_CODE_INIT = () => '/auth/user/code/init'
export const AUTH_USER_CODE_CONFIRM = () => '/auth/user/code/confirm'
export const AUTH_USER_CROSS_VERIFY_CONFIRM = () => '/auth/user/code/crossverify'

export const AUTH_PUBLISHER = () => '/auth/publisher'
export const AUTH_PUBLISHER_CLAIM = () => '/auth/publisher/claim'
export const AUTH_PUBLISHER_CODE_INIT = () => '/auth/publisher/code/init'
export const AUTH_PUBLISHER_CODE_CONFIRM = () => '/auth/publisher/code/confirm'

export const OAUTH_USER = () => '/oauth/user'
export const OAUTH_USER_LOGIN = () => '/oauth/user/login'
export const OAUTH_USER_TOKEN = () => '/oauth/user/token'
export const OAUTH_USER_LINK = () => '/oauth/user/link'
export const OAUTH_USER_RESET = () => '/oauth/user/reset'

export const OAUTH_PUBLISHER = () => '/oauth/publisher'
export const OAUTH_PUBLISHER_LOGIN = () => '/oauth/publisher/login'
export const OAUTH_PUBLISHER_TOKEN = () => '/oauth/publisher/token'
export const OAUTH_PUBLISHER_LINK = () => '/oauth/publisher/link'

export const PURCHASE_PLAN = () => '/purchase/plan'
export const PURCHASE_SWITCH = () => '/purchase/switch'
export const PURCHASE_GIFT = () => '/purchase/gift'
export const PURCHASE_PRODUCT = () => '/purchase/product'
export const PURCHASE_HISTORY = () => '/purchase/history'

export const PURCHASE_PLAN_GROUP = () => '/purchase/plan/group'
export const PURCHASE_PLAN_REACTIVATE = () => '/purchase/plan/reactivate'

export const PURCHASE_ARTICLE = () => '/purchase/article'
export const PURCHASE_ARTICLE_GUEST = () => '/purchase/article/guest'

export const LOADER = () => '/loader'
export const LOADER_GUEST = () => '/loader/guest'

export const LANDING_PAGE_DYNAMIC = ({ publisherName }) => `/landingPage/${publisherName}`
export const LANDING_PAGE_SIGNUP_MODE = ({ publisherName }) => `/landingPage/${publisherName}/confirm`
export const LANDING_PAGE_GROUP = ({ publisherName, shortCode }) => `/landingPage/${publisherName}/group/${shortCode}`
export const LANDING_PAGE_STATIC = ({ publisherName, shortCode }) => `/landingPage/${publisherName}/${shortCode}`

export const PLANS_DISCOUNT = () => '/plans/discount'

export const USERS = () => '/users'
export const USERS_EMAIL = () => '/users/email'
export const USERS_PAYMENT = () => '/users/payment'
export const USERS_PAYMENT_DEFAULT = () => '/users/payment/default'
export const USERS_PASSWORD = () => '/users/password'
export const USERS_REGISTER_CONFIRM = () => 'users/register/confirm'
export const USERS_PLAN = () => '/users/plans'

export const HUBSPOT = () => '/publisher/hubspot/setup'

export const SIGNUP = () => '/setup/signup'

export const SETUP = () => '/publisher'
export const SETUP_EMAIL_CHECK = () => '/setup/email'
export const SETUP_PASSWORD_CHECK = () => '/setup/password'
export const SETUP_TWITTER = () => '/setup/twitter'
// export const SETUP_INSTAGRAM = () => '/setup/instagram'
export const SETUP_EMAIL_EXIST_CHECK = () => '/setup/existing-email'

export const TWITTER_API_URL = () => process.env.TWITTER_API_URL || 'https://api.twitter.com'
export const TWITTER_OAUTH = () => '/oauth2/token'
export const TWITTER_USER_INFO = (username) => `/labs/2/users/by/username/${username}`
export const INSTAGRAM_API_URL = () => process.env.INSTAGRAM_API_URL
export const IFRAMELY_API_URL = (url) => `https://cdn.iframe.ly/api/oembed?url=${encodeURIComponent(url)}&key=${process.env.IFRAMELY_KEY_HASH}&omit_script=1`

export const PUBLISHER_ID = ({ publisherUsername }) => `/publisher/${publisherUsername}`
export const PUBLISHER = () => '/publisher/'
export const ZIP_CODE = () => '/setup/zipcode'

export const PROMPT_METRICS = () => 'metrics/prompt'

export const ME = () => '/me'
export const ME_PAYMENT = () => '/me/payment'

export const ARTICLE = () => '/article'

export const FORMS_LANDING_PAGE = () => '/landing_page'
export const FORMS_POPUP = () => '/popup'

export const DOWNLOADS = () => '/downloads'
export const SOCIAL_LINKS = ({ scope }) => scope === 'creator' ? '/social_links' : '/social_links/display'

export const BLOCKS = ({ scope }) => scope === 'creator' ? '/blocks' : '/blocks/display'

export const METRICS_ENDPOINT = () => process.env.METRICS_ENDPOINT || 'https://metrics.pico.tools/extract'
export const FEATURE_FLAGS = () => '/feature_flags'


export const CLIENT = () => '/client/'
export const PROFILE_UPDATE = () => '/publisher/profile'
export const LANDING_PAGE_UPDATE = () => '/form/image'
export const PUBLISHER_CONTENT_CATEGORIES = () => '/publisher/categories'
