import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    utmParameters: new Duck.Selector(() => (state) => state?.utmParameters),
    getUtmParameters: new Duck.Selector((selector) => createSelector(
        selector.utmParameters,
        ({ data } = {}) => data || {},
    )),
})

export default selectors
