import Account from 'modules/account'

import storeSubscriber from '../storeSubscriber'

const updateUser = async ({
    dispatch,
    payload: {
        email,
        publisherId,
        redirectUrl,
    },
}) => {
    dispatch(Account.creators.update({
        email,
        publisherId,
        redirectUrl,
    }))

    let emailDataUpdated
    let updateAccountError

    const response = await storeSubscriber({
        checks: [
            (state) => {
                emailDataUpdated = Account.selectors.emailDataUpdated(state)
                updateAccountError = Account.selectors.updateAccountError(state)
                return (
                    emailDataUpdated === true || updateAccountError
                )
            },
        ],
    })

    if (response?.error) {
        throw response.error
    }

    return {
        emailDataUpdated,
        updateAccountError,
    }
}

export default updateUser
