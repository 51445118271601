import Joi from 'joi'

const experimentsSchema = Joi.object().keys({
    'ex-dashboard-homepage': Joi.boolean().description('Flag for determining whether the creator sees expermintal homepage'),
    'ex-experimentation-test-flag': Joi.boolean().description('Flag for determining whether the exprimentation flag is on'),
    'ex-onboarding-v1': Joi.boolean().description('Flag for determining whether the creator sees v1 onboarding flow'),
    'ex-onboarding-v2': Joi.boolean().description('Flag for determining whether the creator sees v2 onboarding flow'),
    'ex-onboarding-v3': Joi.boolean().description('Flag for determining whether the creator sees v3 onboarding flow'),
}).required().description('LaunchyDarkly onboarding experiment flags')

export default experimentsSchema
