import {
    put, putResolve,
} from 'redux-saga/effects'

import {
    STORE_TOKENS, REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    OAUTH_USER,
    OAUTH_USER_LINK,
} from 'piconetworks/pkg-endpoints'


function* createAccount({
    types, email, publisher_id, company_slug,
}) {
    try {
        const { data: oauthUserData } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherId: publisher_id,
                },
                timeout: 30000,
                params: {
                    email,
                },
            },
        })

        if (oauthUserData.has_password) {
            throw {
                email,
                hasPassword: true,
            }
        }

        const { data: oauthUserLinkData } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER_LINK(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisher_id,
                },
                timeout: 30000,
                data: {
                    return_token: true,
                    articleId: null,
                    email,
                    newsletter_ids: [],
                    redirectUrl: `${window.location.origin}/${company_slug}/login/token`,
                },
            },
        })

        if (!oauthUserLinkData.isNewRegistration) {
            throw {
                email,
                hasPassword: false,
                isNewRegistration: false,
            }
        }

        yield putResolve({
            type: STORE_TOKENS,
            data: oauthUserLinkData,
        })

        yield put({
            type: types.CREATE_ACCOUNT_SUCCESS,
            payload: {
                ...oauthUserLinkData,
            },
        })
    } catch (error) {
        yield put({
            type: types.CREATE_ACCOUNT_ERROR,
            error: error.message,
        })
    }
}

export default createAccount
