import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import OneTimePasscode from 'piconetworks/pkg-otp'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import styles from './scss/Account.module.scss'

const Otp = ({
    changeMenuRoute,
    checkPhone,
    confirmCode,
    email,
    fieldContainerProps,
    linkColor,
    oneTimeCodeError,
    phoneNumber,
    publisherId,
    resendOneTimeCode,
    resetOneTimeCode
}) => {
    const [currentPasscode, setCurrentPasscode] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        if (showNotification) {
            setTimeout(() => setShowNotification(false), 3300)
        }
    }, [showNotification])

    let friendlyOneTimeCodeError
    if (!!oneTimeCodeError && oneTimeCodeError?.includes('The requested resource')) {
        friendlyOneTimeCodeError = 'Unable to verify email. This code is no longer valid.'
    }

    const processCode = async () => {
        try {
            await confirmCode({
                code: currentPasscode,
                email,
                publisherId,
                phone: phoneNumber
            })
        } catch (error) {
            console.error(error)
        }
    }

    const onEnterCode = (e, otpInputsWithValues) => {
        if (e.key === 'Enter' || otpInputsWithValues === 6) {
            e.preventDefault()
            processCode()
        }
    }

    const otpInputsEl = document.getElementById('otp-inputs')
    const otpInputs = otpInputsEl && otpInputsEl.getElementsByTagName('input')

    const resendOTP = async () => {
        try {
            if (email) {
                await resendOneTimeCode({ email, publisherId })

                Array.from(otpInputs).forEach((input) => {
                    input.value = ''
                })
            }

            if (phoneNumber) {
                await checkPhone({
                    phone: phoneNumber,
                })
            }

            setShowNotification(true)
        } catch (error) {
            console.log(error)
            setErrorMessage(error.message)
        }
    }

    let otpInputsWithValues = 0

    useEffect(() => {
        if (otpInputs) {
            Array.from(otpInputs).forEach((input) => {
                if (input.value) {
                    otpInputsWithValues++
                }

                input.onkeyup = (e) => {
                    onEnterCode(e, otpInputsWithValues)
                }
            })
        }
    })

    const renderOTPButton = () => (
        <div className={styles.approveButtonGroup}>
            <Boutton
                className={styles.cancel}
                onClick={() => {
                    resetOneTimeCode()
                    changeMenuRoute('/')
                }}
                variant="link"
            >
                Cancel
            </Boutton>
            <Boutton
                variant="primary"
                onClick={(e) => {
                    e.preventDefault()
                    processCode()
                }}
                disabled={!currentPasscode}
                className={styles.otpButton}
            >
                Verify
            </Boutton>
        </div>
    )

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <label>
                    {`Enter the verification code sent to ${email || phoneNumber}.
                    ${email ? 'If you don\'t see a verification code in your inbox, please check your filters for spam and updates.' : ''}`}
                </label>
                <Field
                    {...fieldContainerProps}
                    name="code"
                    type="tel"
                    autoComplete="one-time-code"
                >
                    {({ meta, input, ...rest }) => (
                        <div
                            className={styles.otpInput}
                            id="otp-inputs"
                        >
                            <OneTimePasscode
                                className="otc"
                                isErrored={!!oneTimeCodeError}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                numInputs={6}
                                currentPasscode={currentPasscode}
                                setCurrentPasscode={setCurrentPasscode}
                                input={input}
                                meta={meta}
                                errorText={friendlyOneTimeCodeError || oneTimeCodeError}
                            />
                        </div>
                    )}
                </Field>
            </div>
            <div className={styles.resendCode}>
                {showNotification ? (
                    <div className={styles.notification}>
                        <div>
                            <Picon icon="icon_checkmark" size='24' color="#28bf6f" />
                            New code sent
                        </div>
                    </div>) : (
                    <Boutton
                            onClick={() => {
                            resendOTP(email || phoneNumber)
                        }}
                            variant="link"
                            icon={<Picon icon='icon_reload' size='24' color={linkColor} />}
                    >
                            Resend code
                    </Boutton>
                )}
            </div>
            <div>
                {renderOTPButton()}
            </div>
            {errorMessage && (<div className={styles.error}>{errorMessage}</div>)}
        </div>
    )
}

Otp.propTypes = {
    allowSkipOtp: PropTypes.bool,
    changeMenuRoute: PropTypes.func,
    checkPhone: PropTypes.func,
    confirmCode: PropTypes.func,
    contrastColor: PropTypes.string,
    email: PropTypes.string,
    fieldContainerProps: PropTypes.any,
    linkColor: PropTypes.string,
    onSkipOtp: PropTypes.func,
    oneTimeCodeError:PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.bool,
    phoneNumber: PropTypes.string,
    publisherId: PropTypes.string,
    requestNewCode: PropTypes.func,
    resetOneTimeCode: PropTypes.func,
}

const MemoizedOtp = React.memo(Otp)

export default MemoizedOtp
