import Payment from 'modules/payment'

import storeSubscriber from '../storeSubscriber'

const addPaymentMethod = async ({
    dispatch,
    payload: {
        token,
        publisherId,
    },
}) => {
    dispatch(Payment.creators.addPaymentMethod({
        token,
        publisherId,
    }))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                if (Payment.selectors.doesPaymentMethodErrorExist(state, token.id)) {
                    throw new Error(
                        Payment.selectors.getPaymentMethodError(state, token.id),
                    )
                }

                return (
                    Payment.selectors.doesPaymentMethodExist(state, token.id)
                )
            },
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default addPaymentMethod
