import { call, put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, GET_LANDING_PAGE, } from '../../../lib/endpoints'

function* requestLandingPage({
    publisherId,
    publisherUsername,
    shortCode,
}) {
    let response

    try {
        if (publisherUsername) {
            response = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'get',
                    baseURL: PICO_API_URL(),
                    url: GET_LANDING_PAGE({
                        publisherUsername,
                        shortCode,
                    }),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    timeout: 30000,
                },
            })
        } else {
            throw new Error('Publisher username undefined')
        }
    } catch (error) {
        response = error
    }
    return response
}

function* fetchLandingPage({
    types,
    publisherId,
    publisherUsername,
    shortCode,
}) {
    try {
        let response = yield call(requestLandingPage, {
            publisherId,
            publisherUsername,
            shortCode,
        })

        if (response?.response?.status == '404') {
            response = yield call(requestLandingPage, {
                publisherId,
                publisherUsername,
            })
        }

        const payload = (shortCode && response?.data) ? {
            [shortCode]: response?.data.landing_page,
        } : response?.data

        if (!payload?.[shortCode]) {
            throw new Error(`unable to fetch landing page for: ${publisherUsername}`)
        }

        yield put({
            type: types.FETCH_LANDING_PAGE_SUCCESS,
            payload,
        })
    } catch (error) {
        yield put({
            type: types.FETCH_LANDING_PAGE_ERROR,
            error: error?.message || 'GET_LANDING_PAGE_ERROR',
        })
    }
}

export default fetchLandingPage
