import Account from 'modules/account'

import storeSubscriber from '../storeSubscriber'

const requestLoginLink = async ({
    dispatch,
    payload: {
        customProperties,
        email,
        newsletters,
        origin,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        emailOtpEnabled,
        utm,
    },
}) => {
    dispatch(Account.creators.requestLoginLink({
        customProperties,
        email,
        newsletters,
        origin,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        emailOtpEnabled,
        utm,
    }))

    let isNewRegistration
    let hasUserLoader

    const response = await storeSubscriber({
        checks: [
            (state) => {
                if (state.loader.user) {
                    hasUserLoader = true
                    return true
                }

                isNewRegistration = Account.selectors.isNewRegistration(state)

                return (
                    typeof isNewRegistration !== 'undefined'
                )
            },
        ],
    })

    if (response?.error) {
        throw response.error
    }

    return {
        isNewRegistration,
        hasUserLoader,
    }
}

export default requestLoginLink
