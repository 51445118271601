import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Header.module.scss'

const Header = (props) => {
    const { className, children } = props
    return <div {...props} className={cx(styles.header, className)}>{children}</div>
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Header
