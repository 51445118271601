import React from 'react'

export default ({ color = 'currentcolor', width = '56px', height = '56px' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <title>icon/settings</title>
        <g id="icon/settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Bounds" points="0 0 23.9999985 0 23.9999985 23.9999985 0 23.9999985" />
            <path
                d="M16,15 C17.3062521,15 18.4175144,15.8348501 18.8293257,17.0000889 L21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L18.8289758,19.0009007 C18.4168852,20.1656226 17.3058822,21 16,21 C14.6941178,21 13.5831148,20.1656226 13.1710242,19.0009007 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L13.1706743,17.0000889 C13.5824856,15.8348501 14.6937479,15 16,15 Z M16,16.5 C15.1715729,16.5 14.5,17.1715729 14.5,18 C14.5,18.8284271 15.1715729,19.5 16,19.5 C16.8284271,19.5 17.5,18.8284271 17.5,18 C17.5,17.1715729 16.8284271,16.5 16,16.5 Z M8,9 C9.30625206,9 10.4175144,9.8348501 10.8293257,11.0000889 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L10.8289758,13.0009007 C10.4168852,14.1656226 9.30588222,15 8,15 C6.69411778,15 5.58311485,14.1656226 5.17102423,13.0009007 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5.17067428,11.0000889 C5.58248558,9.8348501 6.69374794,9 8,9 Z M8,10.5 C7.17157288,10.5 6.5,11.1715729 6.5,12 C6.5,12.8284271 7.17157288,13.5 8,13.5 C8.82842712,13.5 9.5,12.8284271 9.5,12 C9.5,11.1715729 8.82842712,10.5 8,10.5 Z M16,3 C17.3062521,3 18.4175144,3.8348501 18.8293257,5.00008893 L21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L18.8289758,7.00090072 C18.4168852,8.16562257 17.3058822,9 16,9 C14.6941178,9 13.5831148,8.16562257 13.1710242,7.00090072 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L13.1706743,5.00008893 C13.5824856,3.8348501 14.6937479,3 16,3 Z M16,4.5 C15.1715729,4.5 14.5,5.17157288 14.5,6 C14.5,6.82842712 15.1715729,7.5 16,7.5 C16.8284271,7.5 17.5,6.82842712 17.5,6 C17.5,5.17157288 16.8284271,4.5 16,4.5 Z"
                id="Combined-Shape"
                fill={color}
            />
        </g>
    </svg>
)
