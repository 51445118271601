import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import capitalize from 'lodash/capitalize'

import Link from 'piconetworks/pkg-link'
import Icon from 'piconetworks/pkg-icon'

const { Default: DefaultIcon } = Icon

import styles from './SocialLinks.module.scss'

const sortOrder = [
    'instagram',
    'threads',
    'tiktok',
    'youtube',
    'twitter',
    'email',
    'website',
    'venmo',
    'cash_app',
    'spotify',
    'apple_music',
    'apple_podcasts',
    'etsy',
    'pinterest',
    'twitch',
    'discord',
    'snapchat',
    'facebook',
    'linkedin',
    'github',
]

const SocialLinks = React.forwardRef(({ links, onClick = () => { } }, ref) =>
    links.filter((link) => {
        return sortOrder.indexOf(link.type) !== -1
    }).sort((a, b) => {
        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
    }).map(({ id, type, url }, i) => {
        const iconType = capitalize(type)
        let redirectUrl;

        if (type === 'email' && !url.includes('mailto:')) {
            redirectUrl = 'mailto:' + url
        } else {
            redirectUrl = url
        }


        return (
            type && (
                <Link
                    className={cx(styles.socialLink, styles[type])}
                    hideText
                    href={redirectUrl}
                    icon={
                        DefaultIcon[`Social${iconType}`] ? (
                            React.createElement(
                                DefaultIcon[`Social${iconType}`],
                                {
                                    className: `${type}-icon`
                                }
                            )
                        ) : (
                        // This prevents app from crashing if logo doesn't exist.
                            <span className={styles.noSvg} />
                        )
                    }
                    key={i}
                    openNewWindow
                    ref={ref}
                    type={type}
                    variant={null}
                    hrefClick={() => onClick({
                        link_id: id,
                        link_url: redirectUrl,
                        link_social_type: type,
                        link_type: 'social',
                    })}
                >
                    {type}
                </Link>
            )
        )
    })
)

SocialLinks.displayName = 'SocialLinks'

SocialLinks.propTypes = {
    links: PropTypes.array
}

export default SocialLinks
