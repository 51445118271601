import React from 'react'

export default ({ title = 'Stripe Test Bank', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M12.77 3.209l7.98 4.877c.279.17.333.49.12.714a.7.7 0 0 1-.505.2H3.635C3.285 9 3 8.772 3 8.49c0-.158.092-.308.25-.404l7.98-4.877a1.528 1.528 0 0 1 1.54 0zM6 10a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zM5 19h14a2 2 0 0 1 2 2H3a2 2 0 0 1 2-2z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Bank"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M12.77 3.209l7.98 4.877c.279.17.333.49.12.714a.7.7 0 0 1-.505.2H3.635C3.285 9 3 8.772 3 8.49c0-.158.092-.308.25-.404l7.98-4.877a1.528 1.528 0 0 1 1.54 0zM6 10a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zM5 19h14a2 2 0 0 1 2 2H3a2 2 0 0 1 2-2z"
            fill={color}
        />
    </svg>
)
