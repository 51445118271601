import { call, put } from 'redux-saga/effects'
import {
    ME
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getCurrentUser({
    types,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: ME(),
            headers: {
                publisherid: publisherId,
            },
        })

        const { data: manageUpdatesData } = yield call(picoAPIHelper, {
            url: `${ME()}/manage_updates`,
            headers: {
                publisherid: publisherId,
            },
        })

        if (data) {
            yield put({
                type: types.GET_CURRENT_USER_SUCCESS,
                payload: {
                    ...data,
                    ...manageUpdatesData,
                },
            })
        } else {
            throw new Error('unable to get current user')
        }
    } catch (error) {
        yield put({
            type: types.GET_CURRENT_USER_ERROR,
            error: error?.message || 'GET_CURRENT_USER_ERROR',
        })
    }
}

export default getCurrentUser
