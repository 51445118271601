import Joi from 'joi'

const DashboardPageView = Joi.object()
    .keys({
        page_name: Joi.string().required().description('The name of the page, or tab, the creator is on'),
    })
    .description('Tracks when what page a user is on, based on if the url changes.')
    .meta({ name: 'DashboardPageView', filename: 'dashboard:page_view.model' })

export default DashboardPageView
