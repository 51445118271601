import React from 'react'
import PropTypes from 'prop-types'

import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const ManagePlan = ({
    changeMenuRoute,
    userLoader,
    guestLoader,
    sendExternalLink,
    utmRootUrl,
    queryOverride,
    contributionLanguage = 'tip',
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    return (
        <MenuWrapper publisherName={publisherName} sendExternalLink={sendExternalLink} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Plan</h3>
            </div>
            {/* Plan Details Container */}
            <ul style={{ color: linkColor }}>
                <li onClick={() => { changeMenuRoute('/managePlan/subscription') }}>
                    Subscription
                </li>
                <li onClick={() => { changeMenuRoute('/managePlan/subscription/finished') }}>
                    Stopped Subscription
                </li>
                <li onClick={() => { changeMenuRoute('/managePlan/donation') }}>
                    Reccuring {contributionLanguage === 'tip' ? 'Tip' : 'Donation'}
                </li>
                <li onClick={() => { changeMenuRoute('/managePlan/pass') }}>
                    Pass
                </li>
                <li onClick={() => { changeMenuRoute('/managePlan/gift') }}>
                    Gift
                </li>
            </ul>
        </MenuWrapper>
    )
}

ManagePlan.propTypes = {
    sendExternalLink: null,
    utmRootUrl: null
}

ManagePlan.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string,
    guestLoader: PropTypes.object,
    userLoader: PropTypes.object,
    queryOverride:PropTypes.object,
}

export default ManagePlan
