import { call, select, put } from 'redux-saga/effects'

import { PICO_API_URL } from 'lib/endpoints'
import  getQuery from 'lib/getQuery'

function* checkIfShowOfferCode({
    types,
    publisherId,
}) {
    try {
        const baseURL = PICO_API_URL()

        let response = yield call(getQuery, {
            publisherId,
            baseURL,
        })

        const allProducts = response?.products?.products

        const blocks = yield select((state) => state?.blocks?.data) || {}

        const diplayedProducts = []

        Object.entries(blocks).forEach(([key, value = []]) => {
            value?.forEach((item) => {
                if (item?.type === 'product') {
                    const productId = item?.product_block?.product?.id
                    diplayedProducts.push(productId)
                }
            })  
        })

        const hasMultiplePages = response?.client.has_multiple_pages
        const hasMultipePagesFeature = response?.client?.has_multiple_page_feature

        let result = false
        if (
            hasMultiplePages && hasMultipePagesFeature &&
            diplayedProducts.length < allProducts.length
            && diplayedProducts.length > 0
        ) {
            result = true
        }

        yield put({
            type: types.CHECK_IF_SHOW_OFFER_CODE_SUCCESS,
            payload: result,
        })
    } catch (error) {
        yield put({
            type: types.CHECK_IF_SHOW_OFFER_CODE_ERROR,
            error: error?.message || 'GET_LANDING_PAGE_ERROR',
        })
    }
}

export default checkIfShowOfferCode
