import React from 'react'

export default ({
    title = 'icon/overflow',
    color = 'currentcolor',
    width = '24px',
    height = '24px'
}) => (
    <svg
        height={height}
        width={width}
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="icon/overflow"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <polygon
                id="Bounds"
                points="0 0 23.9999985 0 23.9999985 23.9999985 0 23.9999985"
            />
            <path
                id="shape"
                d="M5.99999976,9.9999994 C4.8999998,9.9999994 3.99999976,10.8999989 3.99999976,11.9999994 C3.99999976,13.0999995 4.8999998,13.9999994 5.99999976,13.9999994 C7.09999947,13.9999994 7.99999976,13.0999995 7.99999976,11.9999994 C7.99999976,10.8999989 7.09999947,9.9999994 5.99999976,9.9999994 Z M17.999999,9.9999994 C16.8999986,9.9999994 15.999999,10.8999989 15.999999,11.9999994 C15.999999,13.0999995 16.8999986,13.9999994 17.999999,13.9999994 C19.0999992,13.9999994 19.999999,13.0999995 19.999999,11.9999994 C19.999999,10.8999989 19.0999992,9.9999994 17.999999,9.9999994 Z M11.9999994,9.9999994 C10.8999989,9.9999994 9.9999994,10.8999989 9.9999994,11.9999994 C9.9999994,13.0999995 10.8999989,13.9999994 11.9999994,13.9999994 C13.0999995,13.9999994 13.9999994,13.0999995 13.9999994,11.9999994 C13.9999994,10.8999989 13.0999995,9.9999994 11.9999994,9.9999994 Z"
                fill={color}
            />
        </g>
    </svg>
)
