import setDefaultPaymentMethod from './setDefaultPaymentMethod'
import addPaymentMethod from './addPaymentMethod'
import confirmPayment from './confirmPayment'
import confirmOneTimeDonation from './confirmOneTimeDonation'
import convertGift from './convertGift'
import createGift from './createGift'
import getHasPassword from './getHasPassword'
import requestLoginLink from './requestLoginLink'
import requestOneTimeCode from './requestOneTimeCode'
import confirmOneTimeCode from './confirmOneTimeCode'
import exchangeClaim from './exchangeClaim'
import login from './login'
import awaitLoaderFetching from './awaitLoaderFetching'
import awaitGiftResults from './awaitGiftResults'
import updateUser from './updateUser'
import updateUserProperties from './updateUserProperties'
import updateUserNewsletters from './updateUserNewsletters'
import crossVerification from './crossVerification'
export default {
    crossVerification,
    setDefaultPaymentMethod,
    addPaymentMethod,
    confirmPayment,
    confirmOneTimeDonation,
    convertGift,
    createGift,
    getHasPassword,
    requestLoginLink,
    requestOneTimeCode,
    confirmOneTimeCode,
    exchangeClaim,
    login,
    awaitLoaderFetching,
    awaitGiftResults,
    updateUser,
    updateUserProperties,
    updateUserNewsletters,
}
