import { CONSTANTS } from 'analytics'

import beacon from './beacon'
import page from './page'
import track from './track'
import identify from './identify'
import eventSchemas from './EventRegistry/eventSchemas'

const analyticsPluginPico = ({ metrics_endpoint = null, publisherId = null, context = 'gadget', ...options } = {}) => {
    return {
        name: 'analytics-plugin-pico',
        config: {
            context,
            publisherId,
            eventSchemas,
            send: beacon(metrics_endpoint),
            ...options
        },
        track: track,
        identify: identify,
        page: page,
        loaded: () => true,
        methods: {
            guest({ id, ...traits } = {}) {
                this.instance.storage.setItem(CONSTANTS.ANON_ID, id)
                this.instance.storage.setItem(CONSTANTS.USER_TRAITS, traits)
            },
            user({ id, ...traits } = {}) {
                this.instance.storage.setItem(CONSTANTS.USER_ID, id)
                this.instance.storage.setItem(CONSTANTS.USER_TRAITS, traits)
            },
            initPublisher(publisher_id) {
                // check the code in standardPayload; this handles when a user
                // changes their publisher, so the publisherId stored in the
                // config above is not longer the correct one.
                this.instance.storage.setItem('publisherId', publisher_id)
            }
        }
    }
}

export default analyticsPluginPico
