import { produce } from 'immer'

export const initialState = {
    data: null,
    error: null,
    newsletters: {
        data: null,
        loading: false,
        error: null,
    },
    properties: {
        data: null,
        loading: false,
        error: null,
    }
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.DELETE_CURRENT_USER_SUCCESS:
            draftState.data = initialState.data
            draftState.error = initialState.error
            break

        case duck.types.GET_CURRENT_USER:
            draftState.error = initialState.error
            break

        case duck.types.DELETE_CURRENT_USER:
        case duck.types.UPDATE_CURRENT_USER:
            draftState.error = initialState.error
            break

        case duck.types.UPDATE_CURRENT_USER_SUCCESS:
        case duck.types.GET_CURRENT_USER_SUCCESS:
            draftState.data = payload
            break

        case duck.types.GET_CURRENT_USER_ERROR:
        case duck.types.UPDATE_CURRENT_USER_ERROR:
        case duck.types.DELETE_CURRENT_USER_ERROR:
            draftState.error = error
            break

        case duck.types.UPDATE_USER_NEWSLETTERS:
            draftState.newsletters.loading = true
            draftState.newsletters.data = initialState.newsletters.data
            draftState.newsletters.error = initialState.newsletters.error
            break

        case duck.types.UPDATE_USER_PROPERTIES:
            draftState.properties.loading = true
            draftState.properties.data = initialState.properties.data
            draftState.properties.error = initialState.properties.error
            break

        case duck.types.UPDATE_USER_NEWSLETTERS_SUCCESS:
            draftState.newsletters.data = payload
            draftState.newsletters.loading = false
            break

        case duck.types.UPDATE_USER_SYNC_SUCCESS:
            draftState.newsletters.data = payload.newsletters
            if (draftState.data) {
                draftState.data.sync = payload.sync
            }
            break

        case duck.types.UPDATE_USER_PROPERTIES_SUCCESS:
            draftState.properties.data = payload
            draftState.properties.loading = false
            break

        case duck.types.UPDATE_USER_NEWSLETTERS_ERROR:
            draftState.newsletters.loading = false
            draftState.newsletters.error = error
            break

        case duck.types.UPDATE_USER_PROPERTIES_ERROR:
            draftState.properties.loading = false
            draftState.properties.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
