import Joi from 'joi'

const DashboardCartCompleted = Joi.object()
    .keys({
        cart_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cart session'),
        cta_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cta session'),
        cta_id: Joi.string().allow(null).description('The id of the cta'),
        app_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the apps'),
    })
    .description('dashboard:cart:completed event attributes ')
    .meta({ name: 'DashboardCartCompleted', filename: 'dashboard:cart:completed.model' })

export default DashboardCartCompleted
