import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    newsletters: new Duck.Selector(() => (state) => state?.newsletter),
    newslettersHaveBeenFetched: new Duck.Selector((selectors) => createSelector(
        selectors.newsletters,
        ({ fetched }) => fetched,
    )),
    allNewsletters: new Duck.Selector((selectors) => createSelector(
        selectors.newsletters,
        ({ newsletters = [] }) => newsletters,
    )),
    freeNewsletters: new Duck.Selector((selectors) => createSelector(
        selectors.newsletters,
        ({ newsletters = [] }) =>  newsletters.filter(nl => nl.type === 'free'),
    )),
    subscribedNewsletters: new Duck.Selector((selectors) => createSelector(
        selectors.allNewsletters,
        ({ newsletters = [] }) => newsletters.filter(({ subscribed }) => subscribed),
    )),
})

export default selectors
