import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './CoverPhoto.module.scss'

const CoverPhoto = ({className, children, ...props}) => {
    return (
        <div className={cx(style.coverPhoto, className)} {...props}>
            {children}
        </div>
    )
}

CoverPhoto.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CoverPhoto
