import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'

import MenuWrapper from './components/MenuWrapper'
import Avatar from 'piconetworks/pkg-avatar'
import Boutton from 'piconetworks/pkg-boutton'
import { colors } from 'piconetworks/pkg-functions'
import Tooltip from 'piconetworks/pkg-tooltip'
import Picon from 'piconetworks/pkg-picon'

// use a dynamic import instead (this is ~275kb in build)
import Icon, { Icons } from 'piconetworks/pkg-icon'

import style from './scss/MenuRoutes.module.scss'

const WarnPaymentMethod = ({ label = 'Please update your payment method.' }) => (
    <span >
        <Tooltip
            label={label}
            placement="top"
            className=""
        >
            <Icon
                icon={Icons.Warning}
                height={18}
                width={18}
            />
        </Tooltip>
    </span>
)


const Root = ({
    Link,
    changeMenuRoute,
    currentPurchaseFailed,
    currentSubscriptionId,
    currentSubscriptionTypeIsPass,
    currentSubscriptionTypeIsRecurringDonation,
    currentSubscriptionTypeIsSubscription,
    isGroupAdmin,
    isGroupMember,
    linkColor,
    monetizationIsPledge,
    monetizationTypeIsDonation,
    monetizationTypeIsGroup,
    monetizationTypeIsPass,
    publisherId,
    pricingHasMonetization,
    publisherName,
    supportEmail,
    userAvatarUrl,
    userEmail,
    userEmailIsShared,
    userHasCurrentPurchases,
    userHasGroupData,
    userInitials,
    userPhone,
    userVerified,
    closeMenu,
    sendExternalLink,
    tier,
    oneTimeCodeError,
    goToMenuRouteAndOpen,
    requestOneTimeCode,
    utmRootUrl,
    queryOverride,
    downloads = [],
    loginAndAccountManagementEnabled,
    contributionLanguage = 'tip',
    accountPortalLogin = () => { },
}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const contrastColor = colors.getContrastColor(linkColor)
    const isTier1 = tier === 'tier_1'
    const isGmail = userEmail && userEmail.length > 10 && userEmail.slice(-10) === '@gmail.com'
    const helpMailto = (!isIOS && isGmail) ?
        `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${supportEmail}` :
        `mailto:${supportEmail}`
    const currentSubscriptionIdIsGift = currentSubscriptionId === 'GIFT'

    useEffect(() => {
        oneTimeCodeError && setErrorMessage(oneTimeCodeError)
    }, [oneTimeCodeError])

    return (
        <MenuWrapper publisherName={publisherName} sendExternalLink={sendExternalLink} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={classNames(style.mainMenuContainer)}>
                <div className={style.accountWrapper}>
                    <div>
                        <div className={style.accountHeader}>My Account</div>
                        <div className={style.email}>{userEmailIsShared && userEmail ? userEmail : userPhone}</div>

                    </div>
                    <div className={style.menuAvatar}>
                        <Avatar
                            contrastColor={contrastColor}
                            email={userEmail}
                            initials={userInitials}
                            linkColor={linkColor}
                            size="96px"
                            src={userAvatarUrl}
                        />
                    </div>
                </div>
                <nav className={style.mainNavigation} style={{ color: linkColor }}>
                    <li className={style.mainMenuItem} onClick={accountPortalLogin}>
                        <Boutton
                            icon={<Picon icon='icon_profile' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Manage profile
                        </Boutton>
                    </li>
                    <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/logOut') }}>
                        <Boutton
                            icon={<Picon icon='icon_exit' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Log out
                        </Boutton>
                    </li>
                </nav>
            </div>
        </MenuWrapper >
    )
}

Root.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null
}

Root.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    closeMenu: PropTypes.func,
    contributionLanguage: PropTypes.string,
    currentPurchaseFailed: PropTypes.any,
    currentSubscriptionId: PropTypes.any,
    currentSubscriptionIdIsGift: PropTypes.bool,
    currentSubscriptionTypeIsPass: PropTypes.bool,
    currentSubscriptionTypeIsRecurringDonation: PropTypes.bool,
    currentSubscriptionTypeIsSubscription: PropTypes.bool,
    goToMenuRouteAndOpen: PropTypes.func,
    isGroupAdmin: PropTypes.bool,
    isGroupMember: PropTypes.bool,
    Link: PropTypes.any,
    linkColor: PropTypes.string,
    monetizationIsPledge: PropTypes.bool,
    monetizationTypeIsDonation: PropTypes.bool,
    monetizationTypeIsGroup: PropTypes.bool,
    monetizationTypeIsPass: PropTypes.bool,
    pricingHasMonetization: PropTypes.bool,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    sendExternalLink: PropTypes.func,
    supportEmail: PropTypes.string,
    userAvatarUrl: PropTypes.string,
    userEmail: PropTypes.string,
    userHasCurrentPurchases: PropTypes.bool,
    userHasGroupData: PropTypes.bool,
    userInitials: PropTypes.string,
    userVerified: PropTypes.bool,
    utmRootUrl: PropTypes.string,
}

export default Root
