import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Flexbox from '../Flexbox'
import styles from './FlexboxPane.module.scss'

const FlexboxPaneContainer = ({
    children,
    className,
    flexboxContainer,
    ...props
}) => {
    return (
        <Flexbox.Container className={cx(styles.flexboxPaneContainer, className)} {...flexboxContainer} {...props}>
            {children}
        </Flexbox.Container>
    )
}

FlexboxPaneContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    flexboxContainer: PropTypes.object
}

FlexboxPaneContainer.defaultProps = {
    flexboxContainer: {
        alignItems: 'start',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    }
}

export default FlexboxPaneContainer
