/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import classNames from 'classnames'

import { useDispatch, useSelector } from 'react-redux'

import selectors from './text/selectors'
import { creators } from './text/actions'

const RemoteJsonText = ({
    url,
    linkColor,
    style,
}) => {
    const dispatch = useDispatch()

    const json = useSelector((state) => selectors.getJsonFile(state, url), isEqual)
    const isFetching = useSelector((state) => selectors.isFetchingJson(state, url), isEqual)

    useEffect(() => {
        if (!isFetching && url && (!json && !json?.error)) {
            dispatch(creators.fetchJson({
                payload: {
                    url,
                },
            }))
        }
    }, [isFetching, JSON.stringify(json)])

    if (!json || json?.error) {
        return null
    }

    const { description = '', feature_list: featureList = [] } = json || {}

    return (
        <>
            {description && (
                <p>{description}</p>
            )}
            <ul className={style.list}>
                {featureList.map((item, i) => (
                    <li key={i}>
                        <i
                            className={classNames(style.icon, 'icon-icon_checkmark')}
                            style={{
                                color: linkColor
                            }}
                        />
                        {item.label}
                        {item.detail && (<span>{item.detail}</span>)}
                    </li>
                ))}
            </ul>
        </>
    )
}

RemoteJsonText.defaultProps = {
    // options: {},
}

RemoteJsonText.propTypes = {
    url: PropTypes.string.isRequired,
    linkColor: PropTypes.any.isRequired,
    style: PropTypes.any.isRequired,
}

const MemoizedRemoteJsonText = React.memo(RemoteJsonText)

export default MemoizedRemoteJsonText
