import { useSelector } from 'react-redux'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { tagGenerator } from 'piconetworks/tag-generator'
import { colors } from 'piconetworks/pkg-functions'
import Client from 'modules/client'
import style from './style.module.scss'

const useStyles = ({ landingPage }) => {
    const client = useSelector(Client.selectors.client)
    const {
        styling: {
            linkColor: _linkColor,
        } = {},
    } = client || {}
    let linkColor = _linkColor || ''
    let customStyles = ''
    let videoTagUrl = ''

    // console.log('landingPage?.theme_id', landingPage?.theme_id)
    // console.groupCollapsed('theme customizations')
    // console.log(landingPage?.theme_customizations)
    // console.groupEnd()
    
    // console.groupCollapsed('form customizations')
    // console.log(landingPage?.form_customizations)
    // console.groupEnd()

    let serializers = {}
    // theme styles get set in here
    if (landingPage?.theme_id) {
        // console.log('landingPage?.theme_id', landingPage?.theme_id, landingPage?.theme_customizations, linkColor)
        const themeStyleObject = tagGenerator({
            id: landingPage?.theme_id, 
            type: 'theme', 
            customizations: landingPage?.theme_customizations, 
            isPreview: false, 
            isThemePreview: false
        })
        customStyles += themeStyleObject?.styles
        if (themeStyleObject?.videoTagUrl) {
            videoTagUrl = themeStyleObject?.videoTagUrl
        }
        serializers.theme = themeStyleObject?.serializer
    }

    // form styles get set in here
    if (!isEmpty(landingPage?.form_customizations)) {
        const formStyleObject = tagGenerator({
            id: landingPage?.id, 
            type: 'form', 
            customizations: landingPage?.form_customizations, 
            isPreview: false, 
            isThemePreview: false
        })
        customStyles += formStyleObject?.styles
        if (formStyleObject?.videoTagUrl && formStyleObject?.videoTagUrl) {
            videoTagUrl = formStyleObject?.videoTagUrl
        }
        serializers.form = formStyleObject?.serializer
    }
    // console.groupCollapsed('theme serializer')
    // console.log(serializers.theme)
    // console.groupEnd()
    
    // console.groupCollapsed('form serializer')
    // console.log(serializers.form)
    // console.groupEnd()
    const hasCustomizations = landingPage?.theme_id || !isEmpty(landingPage?.form_customizations)

    // need to swap this with the default theme and a form customization for the page-background-value
    const defaultBackgroundStyles = { backgroundColor: linkColor }

    const StyleContainer = hasCustomizations ? () => (
        <style jsx global>{customStyles}</style>
    ) : () => (
        <div className={style.backgroundImage} style={defaultBackgroundStyles} />
    )

    const TagsToRender = videoTagUrl.length > 0 ? (
        <video autoPlay muted loop playsInline className='page'>
            <source src={videoTagUrl} type="video/mp4"/>
        </video>
    ) : null
    
    let contrastColor = colors.getContrastColor(linkColor)
    const { r, g, b } = colors.hexToRgb(linkColor)
    const rgbColorValue = `${r}, ${g}, ${b}`

    const formPageBackground = serializers?.form?.getByApiPropertyName('page-background-value')

    if (landingPage?.theme_id || formPageBackground?.getHasBeenModified()) {
        linkColor = '#000000' 
        contrastColor = '#FFFFFF'
    }

    const RootCssVariables = (<style>
        {`
        :root{
            --block-shadow: none;
            --block-border-style: none;
            --block-border-color: transparent;
            --block-border-width: 0;
            --block-border-radius: 0.5rem;
            --page-font-body: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            --page-font-heading: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            --page-background-color: #834EFF;
            --block-background-color: #FFFFFF;
            --block-contrast-color: #000000;
            --block-button-contrast-color: #FFFFFF;
            --blockTextColorRgb: var(--block-contrast-color);
            --linkColor: ${linkColor};
            --contrastColor: ${contrastColor};
            --rgbColorValue: ${rgbColorValue};
        }
            `}
    </style>)
    // --blockTextColorRgb: ${blockTextColorRgb};

    return {
        contrastColor,
        linkColor,
        contrastColor,
        RootCssVariables,
        StyleContainer,
        TagsToRender
    }
}

export default useStyles
