import {
    types,
    creators,
} from './actions'

import reducer, {
    initialState,
} from './reducer'

import sagas from './sagas'
import takes from './takes'
import selectors from './selectors'

const store = 'links'

const Links = ({
    createDuck
}) =>
    createDuck({
        store,
        initialState
    }).extend({
        creators,
        types,
        reducer,
        sagas,
        takes,
        selectors,
    })

export {
    store,
    creators,
    types,
    reducer,
    initialState,
    sagas,
    takes,
    Links,
}
