import React from 'react'

export default ({ color = '#DDDCE0', width = '24px', height = '24px', viewBox='0 0 24 24' }) => (
    <svg width={width} height={height} viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Standard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_drag" fill={color}>
                <path d="M9,16 C10.1045695,16 11,16.8954305 11,18 C11,19.1045695 10.1045695,20 9,20 C7.8954305,20 7,19.1045695 7,18 C7,16.8954305 7.8954305,16 9,16 Z M15,16 C16.1045695,16 17,16.8954305 17,18 C17,19.1045695 16.1045695,20 15,20 C13.8954305,20 13,19.1045695 13,18 C13,16.8954305 13.8954305,16 15,16 Z M9,10 C10.1045695,10 11,10.8954305 11,12 C11,13.1045695 10.1045695,14 9,14 C7.8954305,14 7,13.1045695 7,12 C7,10.8954305 7.8954305,10 9,10 Z M15,10 C16.1045695,10 17,10.8954305 17,12 C17,13.1045695 16.1045695,14 15,14 C13.8954305,14 13,13.1045695 13,12 C13,10.8954305 13.8954305,10 15,10 Z M9,4 C10.1045695,4 11,4.8954305 11,6 C11,7.1045695 10.1045695,8 9,8 C7.8954305,8 7,7.1045695 7,6 C7,4.8954305 7.8954305,4 9,4 Z M15,4 C16.1045695,4 17,4.8954305 17,6 C17,7.1045695 16.1045695,8 15,8 C13.8954305,8 13,7.1045695 13,6 C13,4.8954305 13.8954305,4 15,4 Z" id="shape"></path>
            </g>
        </g>
    </svg>
)
