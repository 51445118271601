import React from 'react'
import PropTypes from 'prop-types'

import { Flexbox } from 'piconetworks/pkg-box'

import Trigger from './Trigger'
import style from './DynamicFieldItem.module.scss'

const Item = ({ children, disabled, flexboxContainer, onClick, triggerComponent, ...props }) => {
    return (
        <Flexbox.Container {...flexboxContainer} {...props}>
            {React.isValidElement(triggerComponent) && (
                <Trigger onClick={onClick} disabled={disabled} className={style.itemButton}>
                    {triggerComponent}
                </Trigger>
            )}
            {children}
        </Flexbox.Container>
    )
}

Item.propTypes = {
    /**
     * Adding flexbox container props to here,
     */
    flexboxContainer: PropTypes.object,

    /**
     * Disables the onClick Actioon, preventing mouse events,
     */
    disabled: PropTypes.bool,

    children: PropTypes.node.isRequired,

    triggerComponent: PropTypes.any.isRequired,

    /**
     * On click action for bottom of the list button
     */
    onClick: PropTypes.func
}

Item.defaultProps = {
    disabled: false
}
export default Item
