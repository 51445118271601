import claimMagicLink from './claimMagicLink'
import login from './login'
import logout from './logout'
import socialLogin from './socialLogin'
import tokenLogin from './tokenLogin'
import useRefreshToken from './useRefreshToken'
import registerConfirm from './registerConfirm'

const sagas = (duck) => ({
    * claimMagicLink(payload) {
        yield claimMagicLink({ ...payload,
            ...duck })
    },
    * login(payload) {
        yield login({ ...payload,
            ...duck })
    },
    * logout(payload) {
        yield logout({ ...payload,
            ...duck })
    },
    * socialLogin(payload) {
        yield socialLogin({ ...payload,
            ...duck })
    },
    * tokenLogin(payload) {
        yield tokenLogin({ ...payload,
            ...duck })
    },
    * useRefreshToken(payload) {
        yield useRefreshToken({ ...payload,
            ...duck })
    },
    * registerConfirm(payload) {
        yield registerConfirm({ ...payload,
            ...duck })
    },
})

export default sagas
