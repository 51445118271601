import React from 'react'

export default ({ title = 'Back', color = 'currentColor' }) => (
    <svg
        className="Menu-Tray-Header-Nav clickable"
        height="24px"
        icon="M12 4l1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Back"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M12 4l1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z"
            fill={color}
        />
    </svg>
)
