import React from 'react'
import PropTypes from 'prop-types'
const Discover = ({ width = '42px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 42 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <radialGradient
                cx="50%"
                cy="50%"
                fx="50%"
                fy="50%"
                r="50%"
                gradientTransform="translate(0.500000,0.500000),scale(0.970547,1.000000),rotate(90.000000),scale(1.000000,1.029991),translate(-0.500000,-0.500000)"
                id="radialGradient-1"
            >
                <stop stopColor="#FFEEDD" offset="0%" />
                <stop stopColor="#E77A2C" offset="100%" />
            </radialGradient>
        </defs>
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_payment/discover">
                <path
                    d="M3.44594444,13.6756727 C3.0332037,14.0413273 2.50185185,14.1992727 1.65744444,14.1992727 L1.30666667,14.1992727 L1.30666667,9.85189091 L1.65744444,9.85189091 C2.50185185,9.85189091 3.01285185,9.99990909 3.44594444,10.3838909 C3.89757407,10.7781818 4.16694444,11.3887091 4.16694444,12.0192182 C4.16694444,12.651 3.89757407,13.2829091 3.44594444,13.6756727 L3.44594444,13.6756727 Z M1.91942593,8.73889091 L0,8.73889091 L0,15.3120182 L1.90944444,15.3120182 C2.9225,15.3120182 3.65516667,15.0763091 4.29787037,14.5536 C5.06048148,13.9326364 5.51275926,12.9962909 5.51275926,12.0286364 C5.51275926,10.0879818 4.03614815,8.73889091 1.91942593,8.73889091 L1.91942593,8.73889091 Z"
                    id="D"
                    fill="#0B1015"
                />
                <polygon
                    id="I"
                    fill="#0B1015"
                    points="6.11553704 15.3120182 7.42 15.3120182 7.42 8.73889091 6.11553704 8.73889091"
                />
                <path
                    d="M10.6136852,11.2602909 C9.82968519,10.9750727 9.59894444,10.7869636 9.59894444,10.4334 C9.59894444,10.0191273 10.0097407,9.704 10.5728519,9.704 C10.9643333,9.704 11.2855556,9.86156364 11.6279074,10.2349818 L12.3096296,9.35921818 C11.7475556,8.8752 11.075037,8.62918182 10.341463,8.62918182 C9.15807407,8.62918182 8.25390741,9.43774545 8.25390741,10.5110364 C8.25390741,11.4186182 8.6752037,11.8813818 9.90033333,12.3161455 C10.4126296,12.4926727 10.6730556,12.6104 10.8038519,12.6908364 C11.0647963,12.8584545 11.1958519,13.0941636 11.1958519,13.3699636 C11.1958519,13.9029818 10.7637963,14.2966364 10.1807222,14.2966364 C9.55824074,14.2966364 9.05683333,13.9914364 8.75544444,13.4198545 L7.91324074,14.2191273 C8.51433333,15.0852182 9.23727778,15.4708545 10.2321852,15.4708545 C11.5873333,15.4708545 12.5405,14.5826182 12.5405,13.3115455 C12.5405,12.2666364 12.0998889,11.7931818 10.6136852,11.2602909"
                    id="S"
                    fill="#0B1015"
                />
                <path
                    d="M12.9519444,12.0286364 C12.9519444,13.9625455 14.4984259,15.4602909 16.4866852,15.4602909 C17.0487593,15.4602909 17.5305926,15.3512182 18.1235185,15.0763091 L18.1235185,13.5674909 C17.6007222,14.0805273 17.1388519,14.2869636 16.5463148,14.2869636 C15.2314815,14.2869636 14.2972407,13.3503636 14.2972407,12.0192182 C14.2972407,10.7582 15.2606481,9.76254545 16.4866852,9.76254545 C17.1081296,9.76254545 17.5807593,9.97929091 18.1235185,10.5016182 L18.1235185,8.99292727 C17.5513333,8.70796364 17.0787037,8.59061818 16.5165,8.59061818 C14.5383519,8.59061818 12.9519444,10.1186545 12.9519444,12.0286364"
                    id="C"
                    fill="#0B1015"
                />
                <ellipse
                    id="O"
                    fill="url(#radialGradient-1)"
                    cx="22.1850009"
                    cy="12.04"
                    rx="3.56500006"
                    ry="3.46000004"
                />
                <polyline
                    id="V"
                    fill="#0B1015"
                    points="28.7041481 13.1542364 26.9170741 8.73889091 25.4914074 8.73889091 28.333537 15.4798909 29.0357407 15.4798909 31.9275185 8.73889091 30.5123519 8.73889091 28.7041481 13.1542364"
                />
                <polyline
                    id="E"
                    fill="#0B1015"
                    points="32.5210926 15.3120182 36.2251296 15.3120182 36.2251296 14.1992727 33.8260741 14.1992727 33.8260741 12.4238182 36.1333519 12.4238182 36.1333519 11.3108182 33.8260741 11.3108182 33.8260741 9.85189091 36.2251296 9.85189091 36.2251296 8.73889091 32.5210926 8.73889091 32.5210926 15.3120182"
                />
                <path
                    d="M38.7736481,11.7642909 L38.3937037,11.7642909 L38.3937037,9.77247273 L38.7945185,9.77247273 C39.6095,9.77247273 40.0510185,10.1086 40.0510185,10.7482727 C40.0510185,11.4081818 39.6095,11.7642909 38.7736481,11.7642909 L38.7736481,11.7642909 Z M41.3941111,10.6787818 C41.3941111,9.44741818 40.5319444,8.73889091 39.0251296,8.73889091 L37.0872963,8.73889091 L37.0872963,15.3120182 L38.3937037,15.3120182 L38.3937037,12.6702182 L38.5646852,12.6702182 L40.3708148,15.3120182 L41.9771852,15.3120182 L39.8675926,12.5432 C40.8529074,12.3454182 41.3941111,11.6847455 41.3941111,10.6787818 L41.3941111,10.6787818 Z"
                    id="R"
                    fill="#0B1015"
                />
            </g>
        </g>
    </svg>
)

Discover.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Discover
