import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const SignupPageView = Joi.object()
    .keys({
        page_number: Joi.number().min(1).required().description('Step in Onboarding Wizard'),
        experiments: experimentsSchema,
        name: Joi.string().optional().description('Creator/Company Name'),
        publisher_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).optional().description('Publisher user logged into their account from'),
        username: Joi.string().optional().description('Creator Username'),
    })
    .description('Fires on every new step of the onboarding flow.')
    .meta({ name: 'SignupPageView', filename: 'signup:page_view.model' })

export default SignupPageView
