const testEmail = (email) => {
    if (!email) {
        return false
    }

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)
}

const fieldValidations = {
    email: (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value.trim()) ? 'Invalid email address' : undefined,
}

const composeValidators =
    (...validators) =>
        (value) =>
            validators.reduce((error, validator) => error || validator(value), undefined)

export {
    fieldValidations,
    testEmail,
    composeValidators,
}
