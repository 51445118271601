import Abandonment from './abandonment'
import Access from './access'
import Add from './add'
import Cancel from './cancel'
import Discount from './discount'
import Donation from './donation'
import Email from './email'
import Gift from './gift'
import Group from './group'
import Import from './import'
import Join from './join'
import Pass from './pass'
import RecurringDonation from './recurringDonation'
import Refund from './refund'
import Remove from './remove'
import Renewal from './renewal'
import Reverse from './reverse'
import Signup from './signup'
import Stop from './stop'
import Subscription from './subscription'
import Switch from './switch'
import Trial from './trial'

const Activity = {
    Abandonment,
    Access,
    Add,
    Cancel,
    Discount,
    Donation,
    Email,
    Gift,
    Group,
    Import,
    Join,
    Pass,
    RecurringDonation,
    Refund,
    Remove,
    Renewal,
    Reverse,
    Signup,
    Stop,
    Subscription,
    Switch,
    Trial
}
export default Activity
