import { Loader } from 'piconetworks/pkg-module-loader'
// import Loader from '../../pkgs/picore/src/packages/Modules/Loader/index'

/* eslint-disable-next-line import/no-cycle */
import createDuck from '../baseDuck'

export default Loader({
    createDuck,
})

// import createDuck from '../baseDuck';
// import { creators, types } from './actions';
// import reducer, { initialState } from './reducer';
// import takes from './takes';
// import sagas from './sagas';
// import selectors from './selectors';

// export default createDuck({
//   store: 'loader',
//   initialState,
// }).extend({
//   types,
//   reducer,
//   selectors,
//   creators,
//   sagas,
//   takes,
// });
