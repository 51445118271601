import { ThemeBlockShadowValue, FormBlockShadowValue } from "../Properties/BlockShadowValue"
import PropertyType from "../Modifiers/PropertyType"

enum BlockShadowStyleOptions {
    none = 'none',
    light = 'light',
    retro = 'retro'
}

const ThemeBlockShadowStyleValues = (shadowStyle: string) => {
    const shadowValue = ThemeBlockShadowValue.getValue()
    switch (shadowStyle) {
        case BlockShadowStyleOptions.retro:
            return `2.5px 2.5px 0 ${shadowValue}`
        case BlockShadowStyleOptions.light:
            return `0 0 2px ${shadowValue}`
        case BlockShadowStyleOptions.none:
            return 'none'
    }
}

const FormBlockShadowStyleValues = (shadowStyle: string) => {
    const shadowValue = FormBlockShadowValue.getValue()
    switch (shadowStyle) {
        case BlockShadowStyleOptions.retro:
            return `2.5px 2.5px 0 ${shadowValue}`
        case BlockShadowStyleOptions.light:
            return `0 0 2px ${shadowValue}`
        case BlockShadowStyleOptions.none:
            return 'none'
    }
}

// we'll need to limit how the color gets input into the shadow function
// because we're expecting rgb values, but want to set the opacity to be fixed
const themeShadowFunction = (shadowStyle: string) => {
    const shadowValue = ThemeBlockShadowValue.getValue()
    switch (shadowStyle) {
        case BlockShadowStyleOptions.retro:
            return `box-shadow: 2.5px 2.5px 0 ${shadowValue};`
        case BlockShadowStyleOptions.light:
            return `box-shadow: 0 0 2px ${shadowValue};`
        case BlockShadowStyleOptions.none:
            return 'box-shadow: none;'
        default:
            return
    }
}

const formShadowFunction = (shadowStyle: string) => {
    const shadowValue = FormBlockShadowValue.getValue()
    switch (shadowStyle) {
        case BlockShadowStyleOptions.retro:
            return `box-shadow: 2.5px 2.5px 0 ${shadowValue};`
        case BlockShadowStyleOptions.light:
            return `box-shadow: 0 0 2px ${shadowValue};`
        case BlockShadowStyleOptions.none:
            return 'box-shadow: none;'
        default:
            return
    }
}

const ThemeBlockShadowStyle = new PropertyType({
    name: 'BlockShadowStyle',
    propertyFromApi: 'block-shadow-style',
    convertValueToCssPropertiesFunction: themeShadowFunction,
    defaultValue: 'none',
    options: BlockShadowStyleOptions,
    selector: '.block',
    isCompounded: true,
    cssVariable: '--block-shadow',
    cssVariableFunction: (value: string) => ThemeBlockShadowStyleValues(value),
},
'theme'
)
const FormBlockShadowStyle = new PropertyType({
    name: 'BlockShadowStyle',
    propertyFromApi: 'block-shadow-style',
    convertValueToCssPropertiesFunction: formShadowFunction,
    defaultValue: 'none',
    options: BlockShadowStyleOptions,
    selector: '.block',
    isCompounded: true,
    cssVariable: '--block-shadow',
    cssVariableFunction: (value: string) => FormBlockShadowStyleValues(value),
},
'form'
)

export { ThemeBlockShadowStyle, FormBlockShadowStyle }
