const getCheckoutPrice = ({ state = {}, router = {} } = {}) => {
    const { loader = {}, wizard = {} } = state
    const scopedLoader = loader.user || loader.guest

    if (!scopedLoader) {
        return null
    }

    const { query = {} } = router
    const { company_slug, creator_username } = query

    if (!company_slug) {
        return null
    }

    const { wizards } = wizard
    const isRegistration = (
        !scopedLoader.pricing.monetization
    || (
        scopedLoader.pricing.monetization
      && !scopedLoader.pricing.monetization.type
    )
    )
    const isDonation = scopedLoader.pricing.monetization.type === 'donation'
    const isGroup = scopedLoader.pricing.monetization.type === 'group'
    let isCheckout = false

    let wizardName

    if (isRegistration) {
        return null
    } if (isDonation) {
        wizardName = `DonateWizard_${creator_username}`
    } else if (isGroup) {
        wizardName = `GroupWizard_${creator_username}`
    } else {
        isCheckout = true
        wizardName = `CheckoutWizard_${creator_username}`

        if (!wizards[wizardName]) {
            wizardName = `GiftWizard_${creator_username}`
        }
    }

    if (!wizards[wizardName]) {
        return null
    }

    const thisWizard = wizards[wizardName]

    if (isGroup) {
        const tierId = scopedLoader.group.tier_id
        const thisTier = scopedLoader.pricing.monetization.tiers.find((t) => t.tier_id === tierId)
        const thisOption = {
            ...thisTier.options[0],
        }

        return (thisTier.name === 'Default Group Tier')
            ? thisOption.price * (scopedLoader.group.size || 1)
            : thisOption.price
    }

    if (isDonation) {
        if (thisWizard.submit.payload.custom_price) {
            return thisWizard.submit.payload.custom_price
        }

        const planOptionId = thisWizard.submit.payload.plan_option_id
        const tierId = thisWizard.submit.payload.tier_id
        const thisTier = scopedLoader.pricing.monetization.tiers.find((t) => t.tier_id === tierId)
        const thisOption = thisTier.options.find((option) => option.plan_option_id === planOptionId)

        return thisOption.price
    }

    if (isCheckout) {
        if (thisWizard.submit.payload.custom_price) {
            return thisWizard.submit.payload.custom_price
        }

        const isDiscounted = thisWizard.submit.payload.discountCode
        const { isGift } = thisWizard.submit.payload

        if (isDiscounted || isGift) {
            const thisOption = thisWizard.submit.payload.option

            return thisOption.price
        }
        const planOptionId = thisWizard.submit.payload.plan_option_id
        const tierId = thisWizard.submit.payload.tier_id
        const thisTier = scopedLoader.pricing.monetization.tiers.find((t) => t.tier_id === tierId)

        if (!thisTier || !thisTier.options) {
            return null
        }

        const thisOption = thisTier.options.find((option) => option.plan_option_id === planOptionId)

        return thisOption.price
    }

    return null
}

export default getCheckoutPrice
