import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
// import 'react-bootstrap-typeahead/css/Typeahead.css'

import FormSearch from '../FormSearch'
import FormInput from '../FormInput'

class Async extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState() {
        const { handleRenderMenuItemChildren, handleRenderMenu } = this.props
        return {
            isLoading: false,
            options: [],
            query: '',
            selected: [],
            ...handleRenderMenuItemChildren && { renderMenuItemChildren: handleRenderMenuItemChildren },
            ...handleRenderMenu && { renderMenu: handleRenderMenu }
        }
    }

    cache = {}

    typeaheadRef = React.createRef()

    inputRef = React.createRef()

    handleSearch = query => {

        if (this.props.debugMode) {
            console.log('this.cache', this.cache)
            console.log('query', query)
        }

        if (this.cache[query]) {
            this.setState({ options: this.cache[query].options })
            return
        }

        this.setState({ isLoading: true })
        this.props.makeAndHandleRequest(query).then(response => {
            this.cache[query] = {
                ...response,
                page: 1
            }
            this.setState({
                isLoading: false,
                options: response.options
            })

            if (this.props.debugMode) {
                console.log('Response:', response)
            }
        })
    }

    handleSelectionChange = (selected) => {
        // console.log('selection changed', selected)
        const { labelKey } = this.props
        // not handling allowMultiple just yet
        return this.setState({ selected }, () => {
            if (selected.length > 0) {
                if (typeof labelKey == 'string') {
                    this.inputRef.value = selected[0][labelKey]
                } else {
                    // labelKey is a function
                    this.inputRef.value = labelKey(selected[0])
                }
            } else {
                this.inputRef.value = ''
            }
        })
    }

    render() {
        const { allowMultiple, autoFocus, className, debugMode, id, labelKey, minLength, maxResults, placeholder, notifyOnChange, ...props } = this.props
        if (debugMode) {
            console.log('Typeahead state:', this.state)
        }
        return (
            <AsyncTypeahead
                {...this.state}
                autoFocus={autoFocus}
                id={id}
                className={className}
                labelKey={labelKey}
                maxResults={maxResults}
                multiple={allowMultiple}
                minLength={minLength}
                onChange={this.handleSelectionChange}
                onInputChange={query => this.setState({ query }, () => notifyOnChange(query))}
                onSearch={this.handleSearch}
                placeholder={placeholder}
                ref={this.typeaheadRef}
                renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                }) => {

                    const input = {
                        autoComplete: 'off',
                        onBlur: inputProps.onBlur,
                        onChange: inputProps.onChange,
                        onFocus: inputProps.onFocus,
                        onKeyDown: inputProps.onKeyDown
                    }
                    return (
                        <FormSearch
                            isDirty={!isEmpty(this.state.query)}
                            isSearching={this.state.isLoading}
                            onClear={() => {
                                this.typeaheadRef.current.clear()
                                this.inputRef.value = ''
                                this.setState(this.getInitialState, () => notifyOnChange(''))
                            }}
                        >
                            <FormInput
                                input={input}
                                ref={(node) => {
                                    inputRef(node)
                                    referenceElementRef(node)
                                    this.inputRef = node
                                }}
                            />
                        </FormSearch>
                    )
                }}
                useCache={false}
                {...props}
            />
        )
    }
}

Async.defaultProps = {
    autoFocus: false,
    notifyOnChange: (value) => value
}


Async.propTypes = {
    /* Whether to allow more than one item to be selected */
    allowMultiple: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /* To see helpful console logs */
    debugMode: PropTypes.bool,
    /* Unique identifier for Async */
    id: PropTypes.string.isRequired,
    handleRenderMenu: PropTypes.func,
    handleRenderMenuItemChildren: PropTypes.func,
    /* Value to key options selected by */
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    maxResults: PropTypes.number,
    minLength: PropTypes.number,
    /* passes query options */
    makeAndHandleRequest: PropTypes.func.isRequired,
    notifyOnChange: PropTypes.func,
    placeholder: PropTypes.string,
    /* will position the menu using fixed instead of absolute positioning */
    positionFixed: PropTypes.bool
}

export default Async
