import React from 'react'
import PropTypes from 'prop-types'

import DefaultIcons from './Default'
import NavigationIcons from './Navigation'
import BrandIcons from './Brands'
import LoadingIcon from './LoadingIcon'
import GoogleIcon from './GoogleIcon'
import VariantIcon from './VariantIcon'
import CustomizableIcons from './CustomizableIcons'
import Social from './Social'
import Activity from './Activity'
import Payment from './Payment'

export const Icons = {
    ...Activity,
    ...DefaultIcons,
    ...BrandIcons,
    ...NavigationIcons,
    ...Social,
    Loading: LoadingIcon,
    Google: GoogleIcon,
    ...CustomizableIcons,
    ...Payment
}

const Icon = ({
    icon,
    type,
    width = '56',
    height = '56',
    color = '#120A20',
    className,
    ...props
}) => {
    if (icon) {
        return (
            <VariantIcon
                {...props}
                width={width}
                height={height}
                className={className}
                icon={icon}
            />
        )
    }
    /**
     * example usage 1: <Icon.Loading />
     * example usage 2: <Icon type="loading" />
     *
    */

    const Component = Icons[type]
    if (typeof Component === 'undefined') return null

    return (
        <span className={className} style={{ color }}>
            <Component {...props} width={width} height={height} color={color} />
        </span>
    )
}

Icon.Customizable = CustomizableIcons
Icon.Navigation = NavigationIcons
Icon.Brand = BrandIcons
Icon.Loading = LoadingIcon
Icon.Google = GoogleIcon
Icon.Default = DefaultIcons

Icon.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /* Not all icons accept color (e.g tada)*/
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /* if this prop is passed component uses old version of Icon aka VariantIcon */
    icon: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Icon
