import PropertyType from "../Modifiers/PropertyType"

// `very rounded` can be removed whenever HYPE-632 is done https://hypeco.atlassian.net/browse/HYPE-632

enum BlockBorderRadiusOptions {
    square = 'border-radius: 0;',
    rounded = 'border-radius: 0.5rem;',
    'very rounded' = 'border-radius: 3.5rem;',
    'very-rounded' = 'border-radius: 3.5rem;'
}

// we need these because we can't use `very rounded` as a css variable name
enum BlockBorderRadiusValues {
    square = '0',
    rounded = '0.5rem',
    'very rounded' = '3.5rem',
    'very-rounded' = '3.5rem'
}

const ThemeBlockBorderRadius = new PropertyType(
    {
        name: 'BlockBorderRadius',
        propertyFromApi: 'block-border-radius',
        convertValueToCssPropertiesFunction: (value: string) => BlockBorderRadiusOptions[value],
        defaultValue: 'rounded',
        options: BlockBorderRadiusOptions,
        selector: '.block',
        cssVariable: '--block-border-radius',
        cssVariableFunction: (value: string) => BlockBorderRadiusValues[value],
    },
    'theme'
)
const FormBlockBorderRadius = new PropertyType(
    {
        name: 'BlockBorderRadius',
        propertyFromApi: 'block-border-radius',
        convertValueToCssPropertiesFunction: (value: string) => BlockBorderRadiusOptions[value],
        defaultValue: 'rounded',
        options: BlockBorderRadiusOptions,
        selector: '.block',
        cssVariable: '--block-border-radius',
        cssVariableFunction: (value: string) => BlockBorderRadiusValues[value],
    },
    'form'
)

export {
    ThemeBlockBorderRadius,
    FormBlockBorderRadius,
}
