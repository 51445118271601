import { call, put } from 'redux-saga/effects'
import {
    ARTICLE
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper as picoApiHelper } from 'piconetworks/pkg-network-middleware'

function* authenticatedArticleRead({
    types,
    payload
}) {
    try {
        const { data } = yield call(picoApiHelper, {
            url: `${ARTICLE()}/${payload.id}/read`,
            method: 'post',
            data: payload
        })

        if (data) {
            yield put({
                type: types.AUTHENTICATED_ARTICLE_READ_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to authenticate article read')
        }
    } catch (error) {
        yield put({
            type: types.AUTHENTICATED_ARTICLE_READ_ERROR,
            error: error?.message || 'AUTHENTICATED_ARTICLE_READ_ERROR',
        })
    }
}

export default authenticatedArticleRead
