import Joi from 'joi'

const DashboardBlastSent = Joi.object()
    .keys({
        blast_id: Joi.string().guid({ version: ['uuidv4'] }).required().description('The id of the blast sent'),
        sent_at: Joi.date().required().description('Datetime event was sent'),
    })
    .description('dashboard:blast_sent event attributes ')
    .meta({ name: 'DashboardBlastSent', filename: 'dashboard:blast_sent.model' })

export default DashboardBlastSent
