import {
    call, put, putResolve,
} from 'redux-saga/effects'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { goToMenuRouteAndOpen } from 'piconetworks/pkg-menu-middleware'

import {
    PICO_API_URL, PURCHASE_PRODUCT,
} from 'piconetworks/pkg-endpoints'

dayjs.extend(localizedFormat)

function* keepPlan({
    types,
    productOptionId = null,
    publisherId = null,
    subscriptionId = null,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!productOptionId) {
            throw Error('no from id provided')
        }

        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        if (!subscriptionId) {
            throw Error('subscriptionId not provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_PRODUCT()}/${productOptionId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
            },
        })

        if (!data) {
            throw Error('no data returned')
        }

        if (!data.success) {
            throw Error('create plan did not succeed')
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        const nextDate = dayjs(data.purchases.current.subscription_end).format('LL')

        const goToMenuRouteAndOpenAction = yield call(goToMenuRouteAndOpen, {
            menuRoute: '/notification/keepPlan',
            backRoute: '/',
            data: {
                nextDate,
            },
        })

        yield put(goToMenuRouteAndOpenAction)

        yield put({
            type: types.KEEP_PLAN_SUCCESS,
            payload: {
                success: productOptionId,
            },
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.KEEP_PLAN_ERROR,
            error: error.message,
            payload: {
                error: productOptionId,
            },
        })
    }
}

export default keepPlan
