import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_HISTORY
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getPurchaseHistory({
    types,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: PURCHASE_HISTORY(),
            method: 'get',
        })

        if (data) {
            yield put({
                type: types.GET_PURCHASE_HISTORY_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch purchase history')
        }
    } catch (error) {
        yield put({
            type: types.GET_PURCHASE_HISTORY_ERROR,
            error: error?.message || 'GET_PURCHASE_HISTORY_ERROR',
        })
    }
}

export default getPurchaseHistory
