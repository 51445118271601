import React from 'react'
import PropTypes from 'prop-types'
const Import = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_import">
                <polygon
                    id="bounds"
                    fill="#F9F4FF"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M20.0003882,8.00038816 C22.2095272,8.00038816 24.0003882,9.79124916 24.0003882,12.0003882 L24.0003882,20.0003882 C24.0003882,22.2095272 22.2095272,24.0003882 20.0003882,24.0003882 L12.0003882,24.0003882 C9.79124916,24.0003882 8.00038816,22.2095272 8.00038816,20.0003882 L8,12.242 L10,14.242 L10.0003882,20.0003882 C10.0003882,21.05475 10.8162659,21.9185533 11.8511258,21.9949024 L12.0003882,22.0003882 L20.0003882,22.0003882 C21.05475,22.0003882 21.9185533,21.1845104 21.9949024,20.1496505 L22.0003882,20.0003882 L22.0003882,12.0003882 C22.0003882,10.8958187 21.1049577,10.0003882 20.0003882,10.0003882 L14.241,10 L12.241,8 L20.0003882,8.00038816 Z M8.29328137,8.29328137 C8.68380567,7.90275708 9.31697064,7.90275708 9.70749494,8.29328137 L9.70749494,8.29328137 L17.1310036,15.71679 L17.1361465,13.0580666 C17.1372122,12.5071168 17.5841447,12.061048 18.1350956,12.0610481 C18.6857349,12.0610482 19.1321161,12.5074296 19.1321161,13.0580689 L19.1321161,13.0580689 L19.1321161,18.1321161 C19.1321161,18.6844009 18.6844009,19.1321161 18.1321161,19.1321161 L18.1321161,19.1321161 L13.0580691,19.1321161 L12.9417954,19.1254084 C12.4459384,19.0678154 12.0610483,18.6464033 12.0610484,18.1350954 C12.0610484,17.5841444 12.5071173,17.1372118 13.0580673,17.1361462 L13.0580673,17.1361462 L15.71679,17.1310036 L8.29328137,9.70749494 C7.90275708,9.31697064 7.90275708,8.68380567 8.29328137,8.29328137 Z"
                    id="Combined-Shape"
                    fill="#7A34FF"
                />
            </g>
        </g>
    </svg>
)

Import.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Import
