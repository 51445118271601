import createPixelPlugin from '../utils/createPixelPlugin'

const TwitterPixelPlugin = ({
    publisherId = null,
    context = null,
    twitter_pixel_id = null,
    twitter_pixel_event_map = {},
    enabled = false
}) => {
    const provider = 'twitter'
    const scriptSrcTwitter = 'https://static.ads-twitter.com/uwt.js'

    /**
     * Since we do not have a backup sdk for every pixel we plan on using,
     * we instead will check when the window is defined to load the pixel
     */

    const isSSr = typeof window === 'undefined'

    if (isSSr) return {
        name: `analytics-plugin-${provider}-pixel`
    }

    const sendTwitterConversionEvent = (event_id, event_params = {}) => {
        const { twq } = window || {}
        if (event_id && twq) {
            return window.twq('event', `tw-${twitter_pixel_id}-${event_id}`, event_params)
        }
    }

    const customTwitterInit = () => {
        // /**
        //  * Set up twitter tag and event queue
        //  */
        let twitterTrackingLayer
        if (window && !window.twq) {
            window.twq = function() {
                if (twitterTrackingLayer.exe) {
                    twitterTrackingLayer.exe.apply(twitterTrackingLayer, arguments)
                } else {
                    twitterTrackingLayer.queue.push(arguments)
                }
            }

            twitterTrackingLayer = window.twq
        }

        twitterTrackingLayer.version = '1.1'
        twitterTrackingLayer.queue = []

        window.twq('config', twitter_pixel_id)
    }

    return createPixelPlugin({
        provider,
        publisherId,
        context,
        enabled,
        pixelConfig: {
            scriptSrc: scriptSrcTwitter
        },
        coreEvents: {
            initialize: customTwitterInit,
            page: ({ config, payload }) => sendTwitterConversionEvent(twitter_pixel_event_map.page_view),
            identify: ({ config, payload }) => {
                const { type } = payload?.options || {}
                const { email  } = payload?.traits || {}
                if (type === 'user_login') {
                    return sendTwitterConversionEvent(twitter_pixel_event_map.creator_signup, { email_address: email })
                }

                return false
            }
        }
    })
}

export default TwitterPixelPlugin

