import Account from 'modules/account'
import Loader from 'modules/loader'
import Me from 'modules/me'

import storeSubscriber from '../storeSubscriber'

const updateUserNewsletters = async ({
    dispatch,
    payload: {
        newsletters,
        sync = true,
        publisherId,
        onlyAddNewsletters = true,
    },
}) => {
    dispatch(Account.creators.newsletterSubscribe({
        newsletterIds: newsletters,
        publisherId,
        sync,
        onlyAddNewsletters,
    }))

    dispatch({
        type: Me.types.UPDATE_USER_SYNC_SUCCESS,
        payload: {
            newsletters,
            sync,
        },
    })

    const response = await storeSubscriber({
        checks: [
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default updateUserNewsletters
