import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const SignupUserUpdate = Joi.object()
    .keys({
        content_categories: Joi.array().items(Joi.string()).required().min(0).description('Creators content categories selections'),
        twitter_handle: Joi.string().allow(null).default(null).description('Creators Twitter Handle'),
        experiments: experimentsSchema,
        name: Joi.string().optional().description('Creator Name'),
        username: Joi.string().optional().description('Creator Username'),
        page_number: Joi.number().min(1).required().description('Step in Onboarding Wizard'),
    })
    .description('This fires when when a user updates attributes such as content_categories and twitter_handle.')
    .meta({ name: 'SignupUserUpdate', filename: 'signup:user_update.model' })

export default SignupUserUpdate
