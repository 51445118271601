import { colors } from 'piconetworks/pkg-functions'
import { ThemePageBackgroundValue, FormPageBackgroundValue } from '../Properties/PageBackgroundValue'
import { ThemePageBackgroundType, FormPageBackgroundType } from './PageBackgroundType'
import PropertyType from '../Modifiers/PropertyType'

const getBackgroundColorHex = (backgroundColor) => {
    let sectionBackgroundColor = backgroundColor || '#FFFFFF'

    if (backgroundColor === 'transparent') {
        sectionBackgroundColor = '#FFFFFF'
    }

    if (backgroundColor?.includes('rgba')) {
        const sectionBackgroundColorAlpha = backgroundColor.split('(').pop().split(')')[0]
        const [r, g, b] = sectionBackgroundColorAlpha.split(',').map((x) => parseFloat(x))
        sectionBackgroundColor = colors.RgbToHex(r, g, b)
    }
    return sectionBackgroundColor
}

const getPageContrastColor = ({
    formPageBg, 
    themePageBg,
    formPageBgType,
    themePageBgType,
}) => {
    let contrastColor
    // let why
    let backgroundColor
    let backgroundType
    let sectionBackgroundColor

    const formPageBackgroundValueHasBeenModified = formPageBg.getHasBeenModified()
    if (formPageBackgroundValueHasBeenModified) {
        backgroundColor = formPageBg.getValue()
        backgroundType = formPageBgType.getValue()
        // console.log('because form page background value has been modified', backgroundColor)
    } else {
        backgroundColor = themePageBg.getValue()
        backgroundType = themePageBgType.getValue()
        // console.log('because form page background value has NOT been modified', backgroundColor)
    }

    if (backgroundType === 'color' || backgroundType === 'gradient') {
        sectionBackgroundColor = getBackgroundColorHex(backgroundColor)
        contrastColor = colors.getContrastColor(sectionBackgroundColor)
        // why = `contrast color is ${contrastColor} because backgroundtype is color or gradient`
    } else {
        // for videos and images, we will default to white
        contrastColor = '#FFFFFF'
        // why = `contrast color is ${contrastColor} because the background type is ${backgroundType}`
    }

    const { r: redTextColor, g: greenTextColor, b: blueTextColor } = colors.hexToRgb(contrastColor)
    // console.groupCollapsed(`why page contrast color`)
    // console.log(why)
    // console.groupEnd()
    const textColorRgb = `rgb(${redTextColor}, ${greenTextColor}, ${blueTextColor})`
    // console.log('pageContrastColor', textColorRgb)
    return textColorRgb
}


const selectors = '.page'

const ThemePageContrastColor = new PropertyType(
    {
        name: 'PageContrastColor',
        propertyFromApi: 'page-contrast-color',
        convertValueToCssPropertiesFunction: (value) => `color: ${value};`,
        selector: selectors,
        isCompounded: true,
        cssVariable: '--page-contrast-color',
        priority: 3,
        convertToRgb: true,
    },
    'theme'
)

const FormPageContrastColor = new PropertyType(
    {
        name: 'PageContrastColor',
        propertyFromApi: 'page-contrast-color',
        convertValueToCssPropertiesFunction: (value) => `color: ${getPageContrastColor({
            formPageBg: FormPageBackgroundValue,
            themePageBg: ThemePageBackgroundValue,
            formPageBgType: FormPageBackgroundType,
            themePageBgType: ThemePageBackgroundType,
        })}`,
        selector: selectors,
        isCompounded: true,
        cssVariable: '--page-contrast-color',
        cssVariableFunction: (value) => getPageContrastColor({
            formPageBg: FormPageBackgroundValue,
            themePageBg: ThemePageBackgroundValue,
            formPageBgType: FormPageBackgroundType,
            themePageBgType: ThemePageBackgroundType,
        }),
        priority: 4,
        convertToRgb: true,
    },
    'form'
)

export {
    ThemePageContrastColor,
    FormPageContrastColor,
}
