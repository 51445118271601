import { putResolve } from 'redux-saga/effects'
import {
    PICO_API_URL,
} from 'piconetworks/pkg-endpoints'
import {
    REQUEST,
} from './actionTypes'

export default function* picoAPIHelper({ method = 'get', url, headers = {}, data }) {
    const { data: response, status } = yield putResolve({
        type: REQUEST,
        config: {
            url,
            method,
            baseURL: PICO_API_URL(),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...headers
            },
            timeout: 30000,
            data
        },
    })

    return {
        data: response,
        status
    }
}
