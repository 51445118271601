import { takeEvery } from 'redux-saga/effects'
import { types } from '../actions'

import fetchMarkdown from './fetchMarkdown'
import fetchJson from './fetchJson'

export const takes = ([
    takeEvery(types.FETCH_MARKDOWN, fetchMarkdown),
    takeEvery(types.FETCH_JSON, fetchJson),
])

const sagas = {
    fetchMarkdown,
    fetchJson,
}

export default sagas
