import {
    put, putResolve,
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {PICO_API_URL, AUTH_USER_CROSS_VERIFY_CONFIRM } from 'piconetworks/pkg-endpoints'

function* crossVerification({
    code,
    email,
    origin,
    phone,
    publisherId,
    referer,
    types,
    utm,
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                // todo update it with correct url
                url:  AUTH_USER_CROSS_VERIFY_CONFIRM(),
                params: {
                    ...(!!utm && { utm }),
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                    ...(!!referer && { 'prev-page': referer }),
                    ...(!!origin && { 'current-url': origin }),
                },
                timeout: 30000,
                data: {
                    code,
                    email,
                    phone,
                },
            },
        })

        yield put({
            type: types.CROSS_VERIFICATION_SUCCESS,
            payload: {
                ...data,
            },
        })

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.CROSS_VERIFICATION_ERROR,
                error: {
                    message: error.response.data.message,
                },
            })
        } else {
            yield put({
                type: types.CROSS_VERIFICATION_ERROR,
                error: {
                    message: error.message,
                },
            })
        }
    }
}

export default crossVerification
