import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    gift: new Duck.Selector(() => (state) => state?.gift),
    giftId: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ giftId }) => giftId,
    )),
    giftProduct: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ product = {} }) => product,
    )),
    giftConversionSuccess: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ giftConversionSuccess }) => giftConversionSuccess,
    )),
    didCreateGift: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ didCreateGift }) => didCreateGift,
    )),
    creatingGift: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ creatingGift }) => creatingGift,
    )),
    createGiftError: new Duck.Selector((selectors) => createSelector(
        selectors.gift,
        ({ createGiftError }) => createGiftError,
    )),
})

export default selectors

