import getUrlMetadata from './getUrlMetadata'
import getUrlsMetadata from './getUrlsMetadata'

const sagas = (duck) => ({
    * getUrlMetadata(payload) {
        yield getUrlMetadata({ ...payload, duck })
    },
    * getUrlsMetadata(payload) {
        yield getUrlsMetadata({ ...payload, duck })
    },
})

export default sagas
