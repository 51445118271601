import { produce } from 'immer'

export const initialState = {
    url_data: {},
    current_url: null,
    loading: false,
    error: false,
    status: 'INITIATED',
}

const reducer = (state, { type, payload, error }, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (type) {
            case types.GET_URL_METADATA:
            case types.GET_URLS_METADATA:

                draftState.loading = true
                draftState.error = initialState.error
                draftState.status = statuses?.LOADING
                break

            case types.GET_URL_METADATA_SUCCESS:
                draftState.loading = initialState.loading
                draftState.current_url = payload.data
                draftState.status = statuses?.READY

                const { linkUrl, data } = payload
                if (!draftState.url_data?.[linkUrl]) {
                    draftState.url_data = { ...draftState.url_data, [linkUrl]: data }
                }
                break

            case types.GET_URLS_METADATA_SUCCESS:
                draftState.loading = initialState.loading
                draftState.current_url = initialState.current_url
                draftState.status = statuses?.READY

                payload.forEach(urlData => {
                    const { linkUrl, data } = urlData
                    if (!draftState.url_data?.[linkUrl]) {
                        draftState.url_data = { ...draftState.url_data, [linkUrl]: data }
                    }
                })
                break

            case types.GET_URL_METADATA_ERROR:
            case types.GET_URLS_METADATA_ERROR:
                draftState.current_url = initialState.current_url
                draftState.loading = initialState.loading
                draftState.error = error
                draftState.status = statuses?.ERRORED
                break

            case types.CLEAR_CURRENT_URL:
                draftState.current_url = initialState.current_url
                break

            default:
                return state
        }
    })

export default reducer
