import {
    put, putResolve, call, select
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, PURCHASE_PRODUCT,
} from 'piconetworks/pkg-endpoints'

function* purchasePlan({
    types,
    utm,
    formId = null,
    articleId = null,
    productOptionId = null,
    publisherId = null,
    customPrice = null,
    newsletters = null,
    customFields = null,
    oneTimeDonation = false,
    origin,
    source = null,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    const state = yield select()

    try {
        if (!productOptionId) {
            throw Error('no product option id provided')
        }

        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        const { utm_campaign: campaign, utm_medium: medium, utm_source: source, utm_term: term, utm_content: content } = state?.utm?.data ||  {}

        // Here is because of magic links. When there is magic link we are losing utm parameters.
        // If we lose it instead of waiting them, just taking them from state.
        const created_utm = utm ? utm : {
            ...(campaign && { campaign }),
            ...(medium && { medium }),
            ...(source && { source }),
            ...(term && { term }),
            ...(content && { content })
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_PRODUCT()}/${productOptionId}`,
                params: {
                    ...(!!created_utm && { utm: created_utm }),
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                    ...(!!origin && { 'current-url': origin }),
                },
                timeout: 30000,
                data: {
                    article_id: articleId,
                    form_id: formId,
                    ...(customPrice && { custom_price: parseFloat(customPrice) }),
                    ...(newsletters && { newsletter_ids: newsletters }),
                    ...(customFields && { custom_properties: customFields })
                },
            },
        })

        if (!data) {
            throw Error('no data returned')
        }

        if (!data.success) {
            throw Error('create plan did not succeed')
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.PURCHASE_PLAN_SUCCESS,
            payload: {
                success: productOptionId,
                oneTimeDonationSuccess: oneTimeDonation,
                source,
            },
        })
    } catch (error) {
        const errorMessage = error.errors?.[0].custom_price
            ? 'Maximum purchase limit exceeded. Your product price must be less than or equal to $999,999.99.'
            : 'There was an issue trying to complete your purchase. Please try again or contact support if this persists.'

        yield put({
            type: types.PURCHASE_PLAN_ERROR,
            error: errorMessage,
            payload: {
                error: productOptionId,
            },
        })
    }
}

export default purchasePlan
