import { produce } from 'immer'

export const initialState = {
    claim: null,
    data: null,
    error: null,
    outboundClaim: null
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GENERATE_CLAIM:
            draftState.outboundClaim = initialState.outboundClaim
            draftState.error = initialState.error
            break

        case duck.types.GENERATE_CLAIM_SUCCESS:
            draftState.outboundClaim = payload.claim
            break

        case duck.types.GENERATE_CLAIM_ERROR:
            draftState.error = error
            break
        case duck.types.EXCHANGE_CLAIM:
            draftState.claim = initialState.claim
            draftState.data = initialState.data
            draftState.error = initialState.error
            break

        case duck.types.EXCHANGE_CLAIM_SUCCESS:
            draftState.data = payload
            break

        case duck.types.EXCHANGE_CLAIM_ERROR:
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
