import Auth from 'modules/auth'

import storeSubscriber from '../storeSubscriber'

const login = async ({
    dispatch,
    payload: {
        email,
        password,
        phone,
        publisherId,
    },
}) => {
    dispatch(Auth.creators.login({
        email,
        phone,
        password,
        publisher_id: publisherId,
        publisherId: publisherId
    }))

    let isAuthed
    let error

    const response = await storeSubscriber({
        checks: [
            (state) => {
                isAuthed = state.auth.isAuthed
                error = state.auth.error

                if (error) {
                    throw new Error(error)
                }

                if (isAuthed) {
                    return true
                }

                return false
            },
        ],
    })

    if (response?.error) {
        throw response.error
    }

    return {
        isAuthed,
    }
}

export default login
