import PropTypes from 'prop-types'
import cx from 'classnames'
import React from 'react'
import styles from './LoadingIcon.module.scss'
import LoadingIcon from './loading'

const Loading = ({
    variant,
    size,
    children,
    className,
    ...props
}) => {

    return (
        <LoadingIcon
            className={cx(
                className,
                size && size,
                styles.loading
            )}
        />
    )
}

Loading.propTypes = {
    /**
     * Color of base element (to determine whether to use light or dark loader)
     */
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'dark',
        'light',
        'link',
        'facebook',
        'github',
        'google',
        'linkedin',
        'twitter'
    ]),

    /**
     * Component size presets
     */
    size: PropTypes.oneOf(['sm']),

    /**
     * This component may be used to wrap child elements or components
     */
    children: PropTypes.element,

    /**
     * An ARIA accessible role - typically status
     */
    role: PropTypes.string
}

Loading.defaultProps = {
    role: 'status',
    variant: 'primary'
}

export default Loading
