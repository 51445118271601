import { call, put } from 'redux-saga/effects'
import {
    ME_PAYMENT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* addPayment({
    types,
    token,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: ME_PAYMENT(),
            method: 'post',
            headers: {
                publisherid: publisherId,
            },
            data: {
                ...(token.account_id && { account_id: token.account_id }),
                token: token.id,
                source_type: token.type,
            },
        })

        if (data) {
            yield put({
                type: types.ADD_PAYMENT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to add payment')
        }
    } catch (error) {
        yield put({
            type: types.ADD_PAYMENT_ERROR,
            error: error?.message || 'ADD_PAYMENT_ERROR',
        })
    }
}

export default addPayment
