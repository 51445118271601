import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import classes from './Avatar.module.scss'
import Image from 'piconetworks/pkg-image'
import Picon from 'piconetworks/pkg-picon'

const { Img } = Image 

const Avatar = ({
    linkColor,
    contrastColor,
    initials,
    src,
    size,
    style,
    className,
    ...props
}) => {
    const [showImage, setShowImage] = useState(true)

    return (
        <div
            className={cx(classes.avatar, className)}
            style={{
                backgroundColor: linkColor,
                color: contrastColor,
                width: size,
                height: size,
                ...style
            }}
            {...props}
        >
            {src && showImage ?  (
                <Img src={src} height={size} width={size} onError={() => setShowImage(false)} />
            ) : initials ? (
                (initials || ' ')[0].toUpperCase()
            ) : (
                <Picon icon="icon_profile" size="48" color={contrastColor} />
            )}
        </div>
    )
}

Avatar.defaultProps = {
    linkColor: '#eee',
    contrastColor: '',
    initials: '',
    size: '56px',
    style: {}
}

Avatar.propTypes = {
    linkColor: PropTypes.string,
    contrastColor: PropTypes.string,
    initials: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default Avatar
