import { put } from 'redux-saga/effects'

function* removeLoader({ types, scope = 'guest' }) {
    try {
        yield put({
            type: types.REMOVE_LOADER_SUCCESS,
            payload: {
                scope,
            },
        })
    } catch (error) {
        yield put({
            type: types.REMOVE_LOADER_ERROR,
            error: error.message,
        })
    }
}

export default removeLoader
