import produce from 'immer'

export const initialState = {
    data: null,
    error: null,

    fetchedLandingPage: null,
    fetchLandingPageInProgress: null,
    fetchLandingPageError: null,

    showOfferCode: null,
    checkIfShowOfferCodeInProgress: null,
    checkIfShowOfferCodeError: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_LANDING_PAGE:
            draftState.data = draftState.data || initialState.data
            draftState.error = initialState.error
            break

        case duck.types.GET_LANDING_PAGE_SUCCESS:
            draftState.data = {
                ...draftState.data,
                ...payload
            }
            break

        case duck.types.GET_LANDING_PAGE_ERROR:
            draftState.error = error
            break

        case duck.types.FETCH_LANDING_PAGE:
            draftState.fetchLandingPageInProgress = true
            draftState.fetchLandingPageError = initialState.fetchLandingPageError
            break

        case duck.types.FETCH_LANDING_PAGE_SUCCESS:
            draftState.fetchedLandingPage = payload
            draftState.fetchLandingPageInProgress = false
            break

        case duck.types.FETCH_LANDING_PAGE_ERROR:
            draftState.fetchLandingPageError = error
            draftState.fetchLandingPageInProgress = false
            break

        case duck.types.CHECK_IF_SHOW_OFFER_CODE:
            draftState.checkIfShowOfferCodeInProgress = true
            draftState.checkIfShowOfferCodeError = initialState.checkIfShowOfferCodeError
            break

        case duck.types.CHECK_IF_SHOW_OFFER_CODE_SUCCESS:
            draftState.showOfferCode = payload
            draftState.checkIfShowOfferCodeInProgress = false
            break

        case duck.types.CHECK_IF_SHOW_OFFER_CODE_ERROR:
            draftState.checkIfShowOfferCodeError = error
            draftState.checkIfShowOfferCodeInProgress = false
            break

        /// Preview

        case duck.types.THEME_CUSTOMIZATION_UPDATE:
            if (payload.shortCode) {
                const pageDraft = draftState.data[payload.shortCode] || draftState.data.default_landing_page
                pageDraft.theme_id = payload?.theme_id
                pageDraft.theme_customizations = payload?.theme_customizations
                pageDraft.form_customizations = payload?.form_customizations

            }
            break

        case duck.types.UPDATE_FORM_CUSTOMIZATIONS:

            if (payload.shortCode) {
                const pageDraft = draftState.data[payload.shortCode] || draftState.data.default_landing_page
                pageDraft.form_customizations = payload?.form_customizations
                console.log(payload)

            }
            break

        case duck.types.PAGE_HEADER_UPDATE:
            if (payload.shortCode) {
                const pageDraft = draftState.data[payload.shortCode] || draftState.data.default_landing_page
                for (const key in payload) {
                    pageDraft[key] = payload[key]
                }
            }
            break

        case duck.types.UPSERT_FIELDS:
            if (payload.shortCode) {
                const pageDraft = draftState.data[payload.shortCode] || draftState.data.default_landing_page
                pageDraft.fields = payload.fields
            }
            break

        default:
        // do nothing
    }
})

export default reducer
