import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MenuWrapper from './components/MenuWrapper'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const ManagePlanSubscriptionFinished = ({ changeMenuRoute, userLoader, guestLoader, utmRootUrl, queryOverride }) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Subscription</h3>
            </div>
            <div className={style.container}>
                <ul className={style.menuContent} style={{ color: linkColor,
                    borderBottom: '1px solid #ddd' }}>
                    <li className={style.list}>
                        <p className={style.listTitle}>SUBSCRIBER SINCE</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>April 24, 2020</div>
                        </div>
                    </li>
                    <li className={style.list}>
                        <p className={style.listTitle}>FINAL PAYMENT</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>$10 on April 24, 2020</div>
                        </div>
                    </li>
                    <li className={style.list}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                Your subscription is set to end on
                                <strong>April 25, 2021</strong>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className={classNames(style.menuContainer, style.planOption)} style={{ color: linkColor }}>
                    <li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/') }}>
                        <Boutton
                            icon={<Picon icon='icon_undo' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Keep subscription
                        </Boutton>
                    </li>
                </ul>
            </div>
        </MenuWrapper>
    )
}

ManagePlanSubscriptionFinished.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    utmRootUrl: PropTypes.string,
    userLoader: PropTypes.any.isRequired,
    guestLoader: PropTypes.any.isRequired,
    queryOverride:PropTypes.object,
}

export default ManagePlanSubscriptionFinished
