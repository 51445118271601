import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    featureFlags: new Duck.Selector(() => (state) => state?.featureFlags),
    getLoading: new Duck.Selector(() => (state) => state?.featureFlags.loading),
    // expecting a payload like: { flag_name: 'value', other_flag: 'value2' }
    allFeatureFlags: new Duck.Selector(() => (state) => state?.featureFlags?.all_flags || {}),
    getFeatureFlags: new Duck.Selector((selectors) => createSelector(
        selectors.featureFlags,
        ({ query_flags = [], feature_flags = [] } = {}) => [...query_flags, ...feature_flags],
    )),
    getExperiments: new Duck.Selector((selectors) => createSelector(
        selectors.allFeatureFlags,
        (flags = {}) => {
            // converts { foo: 'bar' } to [['foo', 'bar']] for easier filtering
            const allFlags = Object.entries(flags)
            // this is ['key', 'value'] and we only care about the key
            const experimentFlags = allFlags.filter(([key, value]) => key.startsWith('ex-'))
            return Object.fromEntries(experimentFlags)
        }
    )),
    getFeatureFlagIsEnabled: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (_, flag = '') => flag,
        (flags, flag) => flags.includes(flag.toLowerCase())
    )),
    newPerformanceSection: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('new-performance-section'),
    )),
    legacyMetrics: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('legacy-metrics'),
    )),
    discordIntegration: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('discord-integration'),
    )),
    twitterIntegration: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('twitter-integration'),
    )),
    youtubeIntegration: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('youtube-integration'),
    )),
    instagramIntegration: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('instagram-integration'),
    )),
    audienceChart: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('audience-chart'),
    )),
    iframelyRichMedia: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('iframely-rich-media'),
    )),
    dashboardHomepage: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('dashboard-homepage'),
    )),
    smsSignup: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('messaging'),
    )),
    conversations: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('conversations'),
    )),
    blasts: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('blasts'),
    )),
    emailOtp: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('one-time-password'),
    )),
    creatorBilling: new Duck.Selector((selectors) => createSelector(
        selectors.getFeatureFlags,
        (flags) => flags.includes('creator-billing'),
    )),
})

export default selectors
