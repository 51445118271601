import { colors } from 'piconetworks/pkg-functions'

// need to change css variables blockButtonBackgroundColorRgb and blockButtonColor to use this function

import { ThemeBlockContrastColor, FormBlockContrastColor } from './BlockContrastColor'
import PropertyType from '../Modifiers/PropertyType'

const makeBlockButtonContrastColor = (property) => {
    const value = property.getCssVariableValue()
    
    if (value !== undefined) {
        const blockButtonContrastColor = colors.getContrastColor(value)
        return blockButtonContrastColor
    }
}

const makeCssBlockButtonContrastColor = (property) => {
    const color = makeBlockButtonContrastColor(property)
    if (color !== undefined) {
        return `background-color: ${color};`
    }
}

const ThemeBlockButtonContrastColor = new PropertyType(
    {
        name: 'BlockButtonContrastColor',
        propertyFromApi: 'block-button-contrast-color',
        convertValueToCssPropertiesFunction: (value) => makeCssBlockButtonContrastColor(ThemeBlockContrastColor),
        defaultValue: '#000000',
        isCompounded: true,
        selector: '.block > .btn',
        priority: 9,
        cssVariable: '--block-button-contrast-color',
        cssVariableFunction: (value: string) => makeBlockButtonContrastColor(ThemeBlockContrastColor),
        convertToRgb: true,
    },
    'theme'
)

const FormBlockButtonContrastColor = new PropertyType(
    {
        name: 'BlockButtonContrastColor',
        propertyFromApi: 'block-button-contrast-color',
        convertValueToCssPropertiesFunction: (value) => makeCssBlockButtonContrastColor(FormBlockContrastColor),
        defaultValue: '#000000',
        isCompounded: true,
        selector: '.block > .btn',
        priority: 10,
        cssVariable: '--block-button-contrast-color',
        cssVariableFunction: (value: string) => makeBlockButtonContrastColor(FormBlockContrastColor),
        convertToRgb: true,
    },
    'form'
)

export {
    ThemeBlockButtonContrastColor,
    FormBlockButtonContrastColor,
}