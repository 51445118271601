import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    iframely: new Duck.Selector(() => (state) => state?.iframely),
    getUrls: new Duck.Selector((selectors) => createSelector(
        selectors.iframely,
        ({ url_data } = {}) => url_data
    )),
    getCurrentUrl: new Duck.Selector((selectors) => createSelector(
        selectors.iframely,
        ({ current_url } = {}) => current_url
    )),
    getLoadingStatus: new Duck.Selector((selectors) => createSelector(
        selectors.iframely,
        ({ loading } = {}) => loading
    )),
    getLinkDataByUrl: new Duck.Selector((selectors) => createSelector(
        selectors.getUrls,
        (_, linkUrl) => linkUrl,
        (url_data, linkUrl) => url_data?.[linkUrl] || null
    )),
    getRichMediaByUrl: new Duck.Selector((selectors) => createSelector(
        (state, linkUrl) => selectors.getLinkDataByUrl(state,linkUrl),
        (linkData) => ['photo','video','rich'].includes(linkData?.type) ? linkData : null
    )),
    getAllRichMedia: new Duck.Selector((selectors) => createSelector(
        selectors.getUrls,
        (url_data) => Object.entries(url_data).reduce((acc, [url, data]) => {
            if (['photo','video','rich'].includes(data?.type)) {
                return {...acc, [url]: data}
            }

            return acc
        }, {})
    ))
})

export default selectors
