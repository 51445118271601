import Joi from 'joi'

const LandingPageOtpRedeemed = Joi.object()
    .keys({
        success: Joi.boolean().required().description('Describes whether the otp redemption attempt failed or succeeded'),
        verify_flow_id: Joi.string().guid({ version: ['uuidv4'] }).required().description(
            `The id that links otp events to an individual & unique verification flow (OTP Request).
            The verify flow id is a uuid created when the initial otp request associated to an event was made.`
        )
    })
    .description('This event is fired when an end user submits a one time code, and can either be successful or fail.')
    .meta({ name: 'LandingPageOtpRedeemed', filename: 'landing_page:otp_redeemed.model' })

export default LandingPageOtpRedeemed
