import getProductByOption from './getProductByOption'

const sagas = (duck) => ({
    * getProductByOption(payload) {
        yield getProductByOption({
            ...payload,
            ...duck,
        })
    },
})

export default sagas
