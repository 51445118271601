import React from 'react'
import PropTypes from 'prop-types'
const Switch = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_switch">
                <polygon
                    id="bounds"
                    fill="#FFFAF1"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M24.3955431,13.0499852 C23.899536,13.3363551 23.2745097,13.1968314 22.9439174,12.7448782 L22.8777371,12.6432903 L21.9335681,11.0073055 L12.489561,16.4598055 C12.0112684,16.7359479 11.399678,16.5720728 11.1235356,16.0937801 C10.8473933,15.6154875 11.0112684,15.0038971 11.489561,14.7277547 L20.9335681,9.27525473 L20.0046413,7.66694244 C19.8783478,7.44819556 19.8309709,7.1941253 19.8680423,6.94674709 L19.8936409,6.82381017 C20.0411205,6.27340863 20.5769889,5.93079242 21.12737,6.01181899 L21.2544685,6.03813596 L25.1791903,7.08976201 C25.7295919,7.23724165 26.0722081,7.77311007 25.9911815,8.32349117 L25.9648645,8.45058964 L24.9132385,12.3753115 C24.8369684,12.6599553 24.6507478,12.9026427 24.3955431,13.0499852 Z M10.8756305,25.9882928 L10.748532,25.9619759 L6.82381017,24.9103498 C6.27340863,24.7628702 5.93079242,24.2270018 6.01181899,23.6766207 L6.03813596,23.5495222 L7.08976201,19.6248004 C7.16603209,19.3401565 7.35225268,19.0974691 7.60745737,18.9501266 C8.10346453,18.6637568 8.72849079,18.8032804 9.05908312,19.2552337 L9.12526337,19.3568215 L10.0694324,20.9928063 L19.5134395,15.5403063 C19.9917321,15.2641639 20.6033225,15.4280391 20.8794649,15.9063317 C21.1556072,16.3846243 20.9917321,16.9962147 20.5134395,17.2723571 L11.0694324,22.7248571 L11.9983591,24.3331694 C12.1246527,24.5519163 12.1720296,24.8059865 12.1349582,25.0533647 L12.1093596,25.1763017 C11.96188,25.7267032 11.4260116,26.0693194 10.8756305,25.9882928 Z"
                    id="Shape"
                    fill="#FCAF2F"
                />
            </g>
        </g>
    </svg>
)

Switch.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Switch
