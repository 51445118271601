import createDuck from '../baseDuck'
import { creators, types } from './actions'
import reducer, { initialState } from './reducer'
import takes from './takes'
import sagas from './sagas'
import selectors from './selectors'

export default createDuck({
    store: 'metrics',
    initialState,
}).extend({
    types,
    reducer,
    selectors,
    creators,
    sagas,
    takes,
})
