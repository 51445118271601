import React from 'react'
import PropTypes from 'prop-types'
const Visa = ({ width = '42px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 42 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_payment/visa" fill="#1A1F71">
                <path
                    d="M20.6407353,18.6597794 L17.8556618,5.63934191 L14.4879412,5.63934191 L17.2730147,18.6597794 L20.6407353,18.6597794 L20.6407353,18.6597794 Z M34.811,10.2515956 L36.5863235,15.1413015 L37.6054926,10.2515956 L34.811,10.2515956 L34.811,10.2515956 Z M38.5709779,5.63934191 L41.6871618,5.63934191 L38.9668382,18.6597794 L36.0897353,18.6597794 C35.4463529,18.6597794 34.8996838,18.2819853 34.6581838,17.7029412 L29.6038235,5.63934191 L33.1404191,5.63934191 L33.8439191,7.58336029 L38.1644118,7.58336029 L38.5709779,5.63934191 L38.5709779,5.63934191 Z M29.7783603,9.88968529 C29.7926176,13.3259338 25.0255662,13.5147279 25.0599485,15.0506103 C25.0697794,15.5174485 25.5139191,16.0136765 26.4872279,16.1423529 C26.9716176,16.2046838 28.3005882,16.2531176 29.8087279,15.5597059 L30.4016176,18.3202279 C29.5915735,18.6135588 28.5460515,18.8970588 27.2510515,18.8970588 C23.9219853,18.8970588 21.5774485,17.1259044 21.5573235,14.592625 C21.5374044,12.7165221 23.2301176,11.6732647 24.5066397,11.0500074 C25.8197574,10.4106397 26.2602426,10.0024779 26.2540147,9.43107206 C26.2459853,8.55868676 25.20875,8.17265735 24.2396103,8.15677353 C22.5467426,8.13043603 21.5615441,8.61478456 20.7772353,8.97869706 L20.1682353,6.12569779 C20.9541397,5.76379265 22.4078235,5.45034706 23.9136985,5.43446324 C27.4523015,5.43446324 29.7684779,7.18345956 29.7783603,9.88968529 L29.7783603,9.88968529 Z M15.8272059,18.6597794 L10.3702941,5.63934191 L6.80801471,5.63934191 L4.12388015,16.0301471 C3.96062059,16.6695147 3.81807279,16.9047868 3.32347132,17.1717647 C2.51321103,17.6125074 1.17819265,18.0246324 0,18.2819853 L0.0802215441,18.6597794 L5.81056618,18.6597794 C6.542375,18.6597794 7.19965441,18.1734338 7.36492647,17.3330221 L8.78397059,9.79719779 L12.2886029,18.6597794 L15.8272059,18.6597794 L15.8272059,18.6597794 Z"
                    id="Visa"
                    transform="translate(20.843581, 12.165761) scale(1, -1) translate(-20.843581, -12.165761) "
                />
            </g>
        </g>
    </svg>
)

Visa.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Visa
