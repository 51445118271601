import React from 'react'
import PropTypes from 'prop-types'
const Group = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_group">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M16,15.8571431 C17.9971427,15.8571431 22,16.8600001 22,18.8571431 L22,20.1407651 C22,20.6169002 21.613617,21 21.1369895,21 L10.8630104,21 C10.3856704,21 10,20.6153075 10,20.1407651 L10,18.8571431 C10,16.8600001 14.0028572,15.8571431 16,15.8571431 Z M10,14.5714287 C10.4844523,14.5714287 11.1459335,14.6599431 11.7914783,14.8366968 C10.0359361,15.4988728 8.46142825,16.5538855 8.08507128,17.9999403 L6.57534026,18 C6.25711357,18 6,17.7435383 6,17.4271767 L6,16.5714287 C6,15.2400001 8.66857147,14.5714287 10,14.5714287 Z M22,14.5714287 C23.3314285,14.5714287 26,15.2400001 26,16.5714287 L26,17.4271767 C26,17.7446001 25.7424114,18 25.4246597,18 L23.9149287,17.9999403 C23.5385717,16.5538855 21.9640639,15.4988728 20.208902,14.8365956 C20.8540664,14.6599431 21.5155477,14.5714287 22,14.5714287 Z M16.0000002,10 C17.4228572,10 18.562857,11.1485715 18.562857,12.5714285 C18.562857,13.9942855 17.4228572,15.1428569 16.0000002,15.1428569 C14.5771432,15.1428569 13.4285716,13.9942855 13.4285716,12.5714285 C13.4285716,11.1485715 14.5771432,10 16.0000002,10 Z M10.003339,10 C11.1118532,10 12,10.8933334 12,12 C12,13.1066666 11.1118532,14 10.003339,14 C8.89482488,14 8,13.1066666 8,12 C8,10.8933334 8.89482488,10 10.003339,10 Z M22.003339,10 C23.1118532,10 24,10.8933334 24,12 C24,13.1066666 23.1118532,14 22.003339,14 C20.8948249,14 20,13.1066666 20,12 C20,10.8933334 20.8948249,10 22.003339,10 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                />
            </g>
        </g>
    </svg>
)

Group.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Group
