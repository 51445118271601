import React from 'react'
import PropTypes, { bool } from 'prop-types'
import { Field } from 'react-final-form'
import classNames from 'classnames'
import ScriptTag from 'react-script-tag'

import WizardSlider from 'piconetworks/pkg-wizard-slider'
import LandingWizardArrow from 'piconetworks/pkg-landing-wizard-arrow'

import { colors, utmLink } from 'piconetworks/pkg-functions'
const { getContrastColor } = colors

import Boutton from 'piconetworks/pkg-boutton'

import Form from 'piconetworks/pkg-form'

import ContentFooter from 'piconetworks/pkg-content-footer'
import Loading from 'piconetworks/pkg-loading'

import ShowPassword from './ShowPassword'
import PasswordMeter from './PasswordMeter'
import PasswordStrength from './PasswordStrength'
import PasswordWarning from './PasswordWarning'
import Otp from './Otp'

import style from './scss/Account.module.scss'

class AccountContainer extends React.Component {
    constructor() {
        super()

        this.state = {
            doingSubmit: false,
            errorMessage: {},
            zxcvbn: null,
            password: {
                score: null,
                strength: null,
                warning: null,
            },
            showPassword: false,
        }

        this.onLoadZxcvbn = this.onLoadZxcvbn.bind(this)
        this.onPasswordChange = this.onPasswordChange.bind(this)
        this.strengthConverter = this.strengthConverter.bind(this)
    }

    onLoadZxcvbn() {
        this.setState({
            zxcvbn: window.zxcvbn,
        })
    }

    strengthConverter(score) {
        switch (score) {
            case 0:
                return 'Very Weak'
            case 1:
                return 'Weak'
            case 2:
                return 'Average'
            case 3:
                return 'Strong'
            case 4:
                return 'Very Strong'
            default:
                return ''
        }
    }

    onPasswordChange({ target }) {
        const { zxcvbn } = this.state

        if (zxcvbn) {
            const { score, feedback = {} } = zxcvbn(target.value) || {}
            const { warning } = feedback

            this.setState({
                password: {
                    score,
                    strength: this.strengthConverter(score),
                    warning,
                },
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { errorMessage } = this.state

        const {
            account,
            changeMenuRoute,
            initialize,
            me,
            onEmailChange,
            oneTimeCodeSent,
            onUpdateSuccess,
            publisherId,
            type,
        } = this.props
        const email = me?.email?.value || me?._email?.value || me?.email
        const { userDataUpdated, emailDataUpdated, error: updateAccountError } = account.updateAccount
        const { oneTimeCodeConfirmed } = account
        const emailInput = document.getElementById('email')
        const isSharedEmail = email === emailInput?.value
        const error = typeof updateAccountError === 'object'
            ? (updateAccountError?.message || 'Something went wrong.')
            : updateAccountError

        if (
            (userDataUpdated === false && prevProps.account.updateAccount?.userDataUpdated !== false)
            || (emailDataUpdated === false && prevProps.account.updateAccount?.emailDataUpdated !== false)
            || (oneTimeCodeConfirmed === true && prevProps.account.oneTimeCodeConfirmed !== true)
        ) {
            this.setState({
                doingSubmit: false,
                errorMessage: {
                    ...errorMessage,
                    ...(!userDataUpdated && { user: error }),
                    ...(!emailDataUpdated && { email: error }),
                },
            }, () => {
                if (type === 'email') {
                    onEmailChange({
                        publisherId,
                        text: isSharedEmail || oneTimeCodeConfirmed
                            ? `Your email was successfully ${emailDataUpdated ? ' updated' : ' verified'}.`
                            : 'Please check your inbox to verify your new email address.',
                    })
                }
                initialize()
            })
        }

        if (oneTimeCodeSent & prevProps.oneTimeCodeSent === false) {
            this.setState({
                doingSubmit: false,
            })
        }

        if (
            (userDataUpdated === true && prevProps.account.updateAccount.userDataUpdated !== true)
            || (emailDataUpdated === true && prevProps.account.updateAccount.emailDataUpdated !== true)
        ) {
            this.setState({
                doingSubmit: false,
                ...((userDataUpdated === true) && { user: null }),
                ...((emailDataUpdated === true) && { email: null }),
            }, () => {
                if (type === 'email') {
                    onEmailChange({
                        publisherId,
                        text: isSharedEmail || oneTimeCodeConfirmed
                            ? `Your email was successfully ${emailDataUpdated ? ' updated' : ' verified'}.`
                            : 'Please check your inbox to verify your new email address.',
                    })
                }
                if (type === 'name') {
                    changeMenuRoute('/editProfile')
                }

                initialize()
                onUpdateSuccess()
            })
        }
    }

    renderWithWizard({ type }, children) {
        if (type === 'password') {
            return (
                <WizardSlider
                    title='Create a new password.'
                    backButton={false}

                    wizardHeaderClass={style.wizardHeaderClass}
                >
                    {children}
                </WizardSlider>
            )
        }
        return children
    }

    getFirstAndLastName() {
        return (
            <div>
                <Field
                    className={style.formInput}
                    label='First Name'
                    name='first_name'
                    component={Form.Input}
                    type='text'
                />
                <Field
                    className={style.formInput}
                    label='Last Name'
                    name='last_name'
                    component={Form.Input}
                    type='text'
                />
            </div>
        )
    }

    getEmail() {
        const { errorMessage } = this.state
        const { oneTimeCodeError } = this.props

        return (
            <div>
                <Field
                    className={style.formInput}
                    label='Email Address'
                    name='email'
                    component={Form.Input}
                    type='text'
                />
                {/* {error.status_code === 409 && <div className={style.error}>{error.message}</div>} */}
                {errorMessage.email && <div className={style.error}>{errorMessage.email}</div>}
            </div>
        )
    }

    getOtp(email) {
        const {
            changeMenuRoute,
            confirmCode,
            fieldContainerProps,
            oneTimeCodeError,
            oneTimeCodeSent,
            publisherId,
            requestOneTimeCode,
            resendOneTimeCode = () => {},
            resetOneTimeCode,
            userLoader,
        } = this.props

        const {
            publisher: { styling: { linkColor } },
        } = userLoader

        const contrastColor = getContrastColor(linkColor)

        const requestNewCode = () => {
            resetOneTimeCode()
        }

        return (
            <div>
                <Otp
                    changeMenuRoute={changeMenuRoute}
                    confirmCode={confirmCode}
                    contrastColor={contrastColor}
                    email={email}
                    fieldContainerProps={fieldContainerProps}
                    isNewRegistration={false}
                    linkColor={linkColor}
                    oneTimeCodeError={oneTimeCodeError}
                    oneTimeCodeSent={oneTimeCodeSent}
                    publisherId={publisherId}
                    requestNewCode={requestNewCode}
                    requestOneTimeCode={requestOneTimeCode}
                    resendOneTimeCode={resendOneTimeCode}
                    resetOneTimeCode={resetOneTimeCode}
                />
            </div>
        )
    }

    getCreateNewPassword({ pristine, values }) {
        const {
            errorMessage,
            showPassword,
            password = {},
        } = this.state

        const {
            account,
            userLoader,
            type
        } = this.props

        const {
            publisher: { styling: { linkColor } },
            user: { has_password },
        } = userLoader

        const {
            hasPasswordResetAuth,
        } = account

        const contrastColor = getContrastColor(linkColor)

        return (
            <div className={style.passwordContainer}>
                <p className={style.passwordInfo}>
                    Create a new password for the account
                    <br />
                    <strong>{userLoader.user.email}</strong>
                    .
                </p>
                <ShowPassword
                    linkColor={linkColor}
                    isShown={showPassword}
                    onClick={() => {
                        this.setState({
                            showPassword: !showPassword,
                        })
                    }}
                />
                <ScriptTag
                    type='text/javascript'
                    src='https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js'
                    onLoad={this.onLoadZxcvbn}
                    async
                />
                {has_password && !hasPasswordResetAuth && (
                    <Field
                        className={style.formInput}
                        label='Old Password'
                        name='oldPassword'
                        component={Form.Input}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={has_password && '*******'}
                        required
                    />
                )}
                <Field
                    name='password'
                >
                    {({ input, meta }) => (
                        <div className={style.passwordInput}>
                            <Form.Input
                                meta={meta}
                                label={has_password ? 'New Password' : 'Password'}
                                className={style.formInput}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={has_password && '*******'}
                                themeColor={linkColor}
                                input={{
                                    type: (showPassword) ? 'text' : 'password',
                                    onChange: (e) => {
                                        this.onPasswordChange(e)
                                        input.onChange(e)
                                    },
                                }}
                            />
                            {type === 'password' && (
                                <LandingWizardArrow
                                    buttonType='submit'
                                    className={classNames(style.iconContainer, style.right, style.color)}
                                    style={{
                                        backgroundColor: linkColor,
                                        color: contrastColor
                                    }}
                                    disabled={
                                        pristine ||
                                        (has_password && !hasPasswordResetAuth
                                            ? !(values.oldPassword && password.score > 1)
                                            : password.score < 2
                                        )
                                    }
                                />
                            )}
                        </div>
                    )}
                </Field>
                <PasswordMeter score={values.password && password.score} minScore={0} maxScore={4} />
                <PasswordStrength strength={password.strength} score={values.password && password.score} />
                <PasswordWarning warning={password.warning} />
                {errorMessage.user && <div className={style.error}>{errorMessage.user}</div>}
            </div>
        )
    }

    getShowDefaultSubmitButtons({ pristine }) {
        const {
            changeMenuRoute,
            oneTimeCodeSent,
            hasName,
            type,
        } = this.props

        return !oneTimeCodeSent ? (
            <div className={style.approveButtonGroup}>
                <Boutton
                    className={style.cancel}
                    onClick={() => changeMenuRoute('/editProfile')}
                    variant="link"
                >
                    Cancel
                </Boutton>
                <Boutton
                    className={style.button}
                    type='submit'
                    disabled={pristine}
                    variant='primary'
                >
                    {(type === 'name' && !hasName) ? 'Add' : 'Update'}
                </Boutton>
            </div>
        ) : null
    }

    getDisclaimer() {
        const {
            type,
            userHasMultipleState,
        } = this.props

        let disclaimer = null

        switch (type) {
            case 'name':
                if (userHasMultipleState) {
                    disclaimer = 'Updating your name will update all of the profiles associated with your Hype ID.'
                } else {
                    disclaimer = false
                }
                break

            case 'email':
                disclaimer = 'Updating this email address will update all of the profiles associated with your Hype ID.'
                break

            case 'password':
                disclaimer = 'Updating your password will impact how you access all of the profiles associated with your Hype ID.'
                break

            case 'phone':
                disclaimer = 'Updating this phone number will update all of the profiles associated with your Hype ID.'
                break
        }

        return (
            disclaimer && (
                <div className={style.disclaimer}>
                    <span>NOTE: </span>{disclaimer}</div>
            )
        )
    }

    render() {
        const {
            doingSubmit
        } = this.state

        const {
            account,
            publisherId,
            userLoader,
            me,
            update,
            emailOtpEnabled,
            oneTimeCodeSent,
            requestOneTimeCode,
            skipWizardSubmit = false,
            type,
            router,
            hideDefaultSubmitButtons = false,
            updateLoaderUser,
            WizardRedux,
            utmRootUrl,
            queryOverride,
            redirectUrl,
        } = this.props

        if (!userLoader) {
            return null
        }

        const {
            error,
        } = account.updateAccount

        const {
            hasPasswordResetAuth,
        } = account

        const {
            publisher: { styling: { linkColor } },
        } = userLoader

        const { query } = router
        const { trypico_link } = utmLink({
            ...(queryOverride || query),
            utmRootUrl
        })

        const isSharedEmail = me?.email?.shared || me?._email?.shared
        const currentEmail = me?.email?.value || me?._email?.value || me?.email

        return (
            <div className={style.accountContainer}>
                {doingSubmit === true && <Loading />}
                <WizardRedux.Step
                    initialValues={{
                        first_name: userLoader.user.first_name,
                        last_name: userLoader.user.last_name,
                        email: isSharedEmail ? currentEmail : '',
                    }}
                    validate={() => {
                        const errors = {}

                        return errors
                    }}
                    onSubmit={({ values }) => {
                        if (!doingSubmit) {
                            this.setState({
                                doingSubmit: true,
                            })
                        }

                        const {
                            first_name, last_name, email, password, oldPassword,
                        } = values
                        if (!!email && !!currentEmail && !isSharedEmail && (email !== currentEmail)) {
                            throw new Error('Please use the email that is linked to your Hype account.')
                        }

                        if (email && emailOtpEnabled) {
                            requestOneTimeCode({
                                email,
                                publisherId
                            })
                        }

                        if (email && !emailOtpEnabled) {
                            update({
                                updateLoaderUser,
                                publisherId,
                                ...(email && { email }),
                            })
                        }

                        if (!email) {
                            update({
                                updateLoaderUser,
                                publisherId,
                                ...(redirectUrl && { redirectUrl }),
                                ...(first_name && first_name !== userLoader.user.first_name && { first_name }),
                                ...(last_name && last_name !== userLoader.user.last_name && { last_name }),
                                ...(oldPassword && { oldPassword }),
                                ...(password && { password }),
                                ...(hasPasswordResetAuth && { hasPasswordResetAuth }),
                            })
                        }

                        if (skipWizardSubmit && !error) {
                            throw {
                                message: 'success',
                            }
                        }

                        return {}
                    }}
                    render={({
                        handleSubmit, submitError, pristine, values,
                    }) => {

                        return (this.renderWithWizard({
                            type,
                            linkColor
                        }, (
                            <>
                                <div className={style.formArea}>
                                    {type !== 'name' && type !== 'email' && type !== 'password' && (
                                        <p className={style.secureText}>
                                            <span className='icon-landing-page-case' />
                                            Your data is managed and secured by
                                            <strong><a href={trypico_link} target='_blank' rel='noopener noreferrer'>Hype.</a></strong>
                                        </p>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        {(type === 'name' || !type) && this.getFirstAndLastName()}
                                        {(type === 'email' || !type) && !oneTimeCodeSent && this.getEmail()}
                                        {(type === 'email' || !type) && oneTimeCodeSent && this.getOtp(values.email)}
                                        {submitError && submitError !== 'success' && <div className={style.error}>{submitError}</div>}

                                        {!hideDefaultSubmitButtons && this.getShowDefaultSubmitButtons({ pristine })}
                                        {this.getDisclaimer()}
                                    </form>
                                </div>
                            </>
                        )))
                    }}
                />
                {(type === 'password' || !type) && <ContentFooter trypicoLink={trypico_link} />}
            </div>
        )
    }
}

AccountContainer.defaultProps = {
    publisherId: null,
    onUpdateSuccess: () => { },
    onEmailChange: () => { },
    utmRootUrl: null,
    queryOverride: null,
    redirectUrl: null,
    initialize: () => { },
    update: () => { },
}

AccountContainer.propTypes = {
    account: PropTypes.any,
    changeMenuRoute: PropTypes.func,
    hasName: PropTypes.bool,
    hideDefaultSubmitButtons: PropTypes.any,
    initialize: PropTypes.any,
    onEmailChange: PropTypes.func,
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onUpdateSuccess: PropTypes.func,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    redirectUrl: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
        skipWizardSubmit: PropTypes.any,
        type: PropTypes.any,
        update: PropTypes.any,
        userHasMultipleState: PropTypes.bool,
        updateLoaderUser: PropTypes.any,
        userLoader: PropTypes.any,
        utmRootUrl: PropTypes.string,
        WizardRedux: PropTypes.any,
    }).isRequired,
}

export { default as EditPassword } from './EditPassword'
export { default as PasswordMeter } from './PasswordMeter'
export { default as PasswordStrength } from './PasswordStrength'
export { default as PasswordWarning } from './PasswordWarning'
export { default as ShowPassword } from './ShowPassword'
export default AccountContainer
