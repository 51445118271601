import React from 'react'

export default ({ color = 'currentcolor', width = '24', height = '24' }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 4v.998A2.998 2.998 0 0017 8l.176-.005A3.003 3.003 0 0020 4.998v-.463c1.196.692 2 1.983 2 3.46v10.01A3.998 3.998 0 0118 22H6a4 4 0 01-4-3.995V7.995c0-1.479.805-2.77 2-3.46v.463A2.998 2.998 0 007 8l.176-.005A3.003 3.003 0 0010 4.998V4h4zm4.006 6H5.994C4.894 10 4 10.895 4 12v6c0 1.113.893 2 1.994 2h12.012c1.1 0 1.994-.895 1.994-2v-6c0-1.113-.893-2-1.994-2zM6 17a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zM6 14a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zM6 11a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zM7 2a1 1 0 011 1.01v1.98c0 .518-.383.945-.883 1.003L7 6a1 1 0 01-1-1.01V3.01c0-.518.383-.945.883-1.003L7 2zm10 0a1 1 0 011 1.01v1.98c0 .518-.383.945-.883 1.003L17 6a1 1 0 01-1-1.01V3.01c0-.518.383-.945.883-1.003L17 2z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
