/* eslint-disable camelcase */
import { useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import isNil from 'lodash/isNil'
import LandingPage from "modules/landing-page"
import Blocks from 'modules/blocks'
import Iframely from "modules/iframely"
import Client from 'modules/client'
import Links from 'modules/links'

const THEME_CUSTOMIZATION_UPDATE = "update_theme"
const PAGE_HEADER_UPDATE = "update_page_header"
const UPSERT_BLOCK = "upsert_block"
const RESET_BLOCKS = "reset_blocks"
const REMOVE_BLOCK = "remove_block"
const UPSERT_FIELDS = "upsert_fields"
const UPSERT_AUTH_METHODS = "upsert_auth_methods"
const SET_DEFAULT_AUTH_METHOD = "set_default_auth_method"
const UPSERT_SOCIAL_LINKS = "upsert_social_links"
const UPDATE_PUBLISHER_SETTINGS = "update_publisher_settings"
const UPDATE_FORM_CUSTOMIZATIONS = "update_form_customizations"
const linkTypes = {
    link: 'link',
    product: 'product',
    text: 'text',
    signup: 'signup'
}

const usePagePreview = ({ config = "ios_preview_bridge", landingPage, blocks, readyToPage }) => {
    const isPreview = config.isPreview
    const bridge = config.bridge

    const dispatch = useDispatch()

    const iframelyUrls = useSelector(Iframely.selectors.getUrls)

    const sendMessageToParent = useCallback((
        payload
    ) => {
        if (isPreview) {
            window?.webkit?.messageHandlers?.[bridge]?.postMessage?.(JSON.stringify(payload))
        }
    }, [isPreview, bridge, readyToPage])


    const handleBridgeMessage = useCallback((event) => {
        try {
            const shortCode = landingPage?.short_code
            const pageId = landingPage?.id

            let payload = {
                pageId,
                shortCode
            }
            let values = JSON.parse(event.detail)

            
            switch (values.type) {
                case UPSERT_BLOCK:
                    const linkType = values?.block?.type
                    switch (linkType) {
                        case linkTypes.link:

                            dispatch({
                                type: Iframely.types.GET_URL_METADATA,
                                linkUrl: values?.block?.link_block?.links[0]?.url
                            })

                            // todo: create update block action and use it.
                            dispatch({
                                type: Blocks.types.UPSERT_BLOCK,
                                payload: {
                                    data: {
                                        ...(linkType && values.block),
                                        id: values?.id,
                                        temp_id: values?.block?.temp_id
                                    },
                                    formId: payload?.pageId,
                                },
                            })

                            break
                        case linkTypes.product:
                            dispatch({
                                type: Blocks.types.UPSERT_BLOCK,
                                payload: {
                                    data: {
                                        ...(linkType && values?.block),
                                        id: values?.id,
                                        temp_id: values?.block?.temp_id
                                    },
                                    formId: payload?.pageId,
                                },
                            })
                            break

                        case linkTypes.text:
                            dispatch({
                                type: Blocks.types.UPSERT_BLOCK,
                                payload: {
                                    data: {
                                        ...(linkType && values?.block),
                                        id: values?.id,
                                        temp_id: values?.temp_id
                                    },
                                    formId: payload?.pageId,
                                },
                            })
                            break
                        case linkTypes.signup:
                            break

                        default:
                            break
                    }
                    break

                case REMOVE_BLOCK:
                case RESET_BLOCKS:
                    payload.data = values?.blocks
                    const linkBlocks = values?.blocks?.filter((block) => block.type === 'link')

                    if (linkBlocks.length) {
                        const linkUrls = linkBlocks?.reduce((urls, { link_block }) => (
                            [...urls, ...(link_block?.links || [])?.length && link_block?.links?.[0]?.is_rich
                                ? [link_block?.links?.[0]?.url]
                                : []]
                        ), [])


                        const filteredLinkUrls = linkUrls?.filter((url) => !iframelyUrls?.[url])
                        dispatch({
                            type: Iframely.types.GET_URLS_METADATA,
                            urls: filteredLinkUrls
                        })
                    }

                    dispatch({
                        type: Blocks.types.GET_BLOCKS_SUCCESS,
                        payload: {
                            data: payload?.data,
                            formId: payload?.pageId,
                        },
                    })

                    break

                case PAGE_HEADER_UPDATE:
                    if (values.cover_image) {
                        const element = document.getElementById("headerCoverImage")
                        element.style.backgroundImage = "url('" + values.cover_image + "')"
                    }
                 
                    dispatch({
                        type: LandingPage.types.PAGE_HEADER_UPDATE,
                        payload: {
                            ...payload,
                            ...( !isNil(values.display_banner_image) && { display_banner_image: values.display_banner_image }),
                            ...( !isNil(values.cover_image) && { cover_image: values.cover_image }),
                            ...( !isNil(values.display_logo) && { display_logo: values.display_logo }),
                            ...( !isNil(values.display_brand_name) && { display_brand_name: values.display_brand_name }),
                            ...( !isNil(values.display_social_links) && { display_social_links: values.display_social_links }),
                        },
                    })
                    break
                
                case UPDATE_PUBLISHER_SETTINGS:
                    dispatch({
                        type: Client.types.SET_CLIENT_SUCCESS,
                        payload: {
                            ...( !isNil(values?.icon) && { icon: values?.icon }),
                            ...( !isNil(values?.name) && { name: values?.name }),
                        },
                    })
                    break

                case THEME_CUSTOMIZATION_UPDATE:

                    payload.theme_id = values?.theme?.id || landingPage?.theme_id
                    payload.theme_customizations = values?.theme?.customizations || landingPage?.theme_customizations
                    payload.form_customizations = []

                    dispatch({
                        type: LandingPage.types.THEME_CUSTOMIZATION_UPDATE,
                        payload,
                    })
                    break

                case UPDATE_FORM_CUSTOMIZATIONS:
                    payload.form_customizations = values?.formCustomizations
                    dispatch({
                        type: LandingPage.types.UPDATE_FORM_CUSTOMIZATIONS,
                        payload,
                    })
                    break

                case UPSERT_FIELDS:
                    payload.fields = values?.fields

                    dispatch({
                        type: LandingPage.types.UPSERT_FIELDS,
                        payload,
                    })
                    break

                case UPSERT_AUTH_METHODS:
                    dispatch({
                        type: Client.types.SET_CLIENT_SUCCESS,
                        payload: {
                            authentication_methods: values?.authentication_methods
                        },
                    })
                    break

                case SET_DEFAULT_AUTH_METHOD:
                    dispatch({
                        type: Client.types.SET_CLIENT_SUCCESS,
                        payload: {
                            default_authentication_method: values?.default_authentication_method
                        },
                    })
                    break

                case UPSERT_SOCIAL_LINKS:
                    dispatch({
                        type: Links.types.GET_SOCIAL_LINKS_SUCCESS,
                        payload: values.social_links,
                    })
                    break

                default:
                    sendMessageToParent({
                        type: "error",
                        body: {
                            error: "Undefined event type"
                        }
                    })
                    break
            }

            if (values.callback) {
                sendMessageToParent({ type: "event_recieved", body: event })
            }

        } catch (error) {
            sendMessageToParent({
                type: "error",
                body: {
                    error: "Something went wrong when decoding event.",
                }
            })
        }
    }, [
        dispatch,
        landingPage,
        iframelyUrls,
        blocks,
        isPreview,
        bridge
    ])




    useEffect(() => {
        if (isPreview && readyToPage) {
            sendMessageToParent({ type: 'ready', body: { ready: true } })
        }
    }, [
        sendMessageToParent,
        isPreview,
        readyToPage
    ])


    useEffect(() => {
        if (isPreview) {
            window.addEventListener(bridge, handleBridgeMessage)
            return () => {
                window.removeEventListener(bridge, handleBridgeMessage)
            }
        }
    }, [
        dispatch,
        landingPage,
        isPreview,
        bridge
    ])

    return {
        sendMessageToParent
    }
}


export default usePagePreview