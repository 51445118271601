import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MenuWrapper from './components/MenuWrapper'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const EditProfile = ({
    changeMenuRoute,
    userLoader,
    guestLoader,
    userEmail,
    userEmailIsShared,
    userPhoneIsShared,
    userFullName,
    userPhone,
    userHasPassword,
    sendExternalLink,
    loginAndAccountManagementEnabled,
    utmRootUrl,
    queryOverride,
    requestOneTimeCode,
    userVerified,
    publisherId,
    goToMenuRouteAndOpen,
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const requestOtp = async () => {
        try {
            await requestOneTimeCode({
                email: userEmail,
                publisherId,
            })
            goToMenuRouteAndOpen('/editProfile/otp', {
                email: userEmail,
                verify: 'email',
            })
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    return (
        <MenuWrapper publisherName={publisherName} sendExternalLink={sendExternalLink} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Edit Profile</h3>
            </div>
            <div className={style.container}>
                <nav className={style.menuContent} style={{ color: linkColor }}>
                    <li onClick={() => { changeMenuRoute('/editProfile/editName') }}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div>
                                <p className={style.listTitle}>Name</p>
                                <div className={classNames({
                                    [style.emptyListItem]: !userFullName,
                                    [style.regularColor]: userFullName
                                })}>{userFullName || 'None'}</div>
                            </div>
                            <Picon color={linkColor} icon={`${userFullName ? 'icon_edit' : 'icon_add'}`} size='24' />
                        </div>
                    </li>
                    {loginAndAccountManagementEnabled && (
                        <li onClick={() => { changeMenuRoute('/editProfile/editPhone') }}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div>
                                    <p className={style.listTitle}>Phone Number</p>
                                    <div className={classNames({
                                        [style.emptyListItem]: !userPhoneIsShared,
                                        [style.regularColor]: userPhoneIsShared
                                    })}>{userPhoneIsShared ? userPhone : 'None'}</div>
                                </div>
                                <Picon color={linkColor} icon={`${userPhoneIsShared ? 'icon_edit' : 'icon_add'}`} size='24' />
                            </div>
                        </li>
                    )}
                    <li onClick={() => { changeMenuRoute('/editProfile/editEmail') }}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div>
                                <p className={style.listTitle}>Email Address</p>
                                <div className={classNames({
                                    [style.emptyListItem]: !userEmailIsShared,
                                    [style.regularColor]: userEmailIsShared
                                })}>{userEmailIsShared ? userEmail : 'None'}</div>
                            </div>
                            <Picon color={linkColor} icon={`${userEmailIsShared ? 'icon_edit' : 'icon_add'}`} size='24' />
                        </div>
                    </li>
                    <li onClick={() => { changeMenuRoute('/editProfile/editPassword') }}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div>
                                <p className={style.listTitle}>Password</p>
                                <div className={style.emptyListItem}>{(userLoader && userHasPassword) ? '*******' : 'None'}</div>
                            </div>
                            <Picon color={linkColor} icon={`${userHasPassword ? 'icon_edit' : 'icon_add'}`} size='24' />
                        </div>
                        {!userVerified && (
                            <div
                                className={style['verificationWarning--pw']}
                                onClick={() => requestOtp()}
                            >
                                <Picon icon='icon_warning' size='24' color='#FCAF2F' />
                                <p>
                                    Email address must be verified first before creating or updating your password.
                                </p>
                            </div>
                        )}
                    </li>
                    <li className={classNames(style.mainMenuItem, style.deleteLinkItem, style.btnLinkGroup)}>
                        <Boutton
                            style={{ color: 'rgb(255, 94, 91)' }}
                            icon={<Picon color='rgb(255, 94, 91)' icon='icon_delete' size='24' />}
                            onClick={() => { changeMenuRoute('/editProfile/deleteAccount') }}
                            variant="link"
                        >
                            Delete account
                        </Boutton>
                    </li>
                </nav>
            </div>
        </MenuWrapper>
    )
}

EditProfile.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null
}

EditProfile.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    goToMenuRouteAndOpen: PropTypes.func,
    loginAndAccountManagementEnabled: PropTypes.bool,
    oneTimeCodeSent: PropTypes.bool,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    requestOneTimeCode: PropTypes.func,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    sendExternalLink: PropTypes.func,
    userVerified: PropTypes.bool,
    utmRootUrl: PropTypes.string,
}

export default EditProfile
