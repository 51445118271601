import {
    put, putResolve,
} from 'redux-saga/effects'
// import { captureException } from '@sentry/browser'

import {
    PICO_API_URL,
    ME,
} from 'piconetworks/pkg-endpoints'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'

function* deleteAccount({
    userId, publisherId, types,
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                baseURL: PICO_API_URL(),
                url: ME({ id: userId }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                params: {
                    adBlocking: false,
                    origin: window.location.href,
                    prev_page: '',
                    publisherId,
                    taxonomies: {},
                },
            },
        })

        if (!data.success) {
            throw new Error('delete account error')
        }

        yield put({
            type: types.DELETE_ACCOUNT_SUCCESS,
            payload: {
            },
        })
    } catch (error) {
        // captureException(error)
        console.log(error)
        yield put({
            type: types.DELETE_ACCOUNT_ERROR,
            error,
        })
    }
}

export default deleteAccount
