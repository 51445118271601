import React from 'react'
import PropTypes from 'prop-types'

// import Router, { withRouter } from 'lib/RouterAdapterDecorator'
import Router, { withRouter } from 'lib/RouterAdapterDecorator'
import { connect } from 'react-redux'
import Wizard from './modules'

class Wrapper extends React.Component {
    componentDidMount() {
        const {
            id,
            children,
            dispatch,
            submitPayload = {},
            submitActionType,
            preSubmitHooks = [],
        } = this.props

        const steps = Array.isArray(children)
            ? children.filter((child) => child !== false).map(({ props }, index) => ({
                ...props,
                step: index,
            }))
            : [
                {
                    ...children.props,
                    step: 0,
                },
            ]

        dispatch(
            Wizard.creators.createWizard({
                payload: {
                    id,
                    currentStep: null,
                    steps,
                    submitPayload,
                    submitActionType,
                    preSubmitHooks,
                },
            }),
        )
    }

    componentDidUpdate() {
        const { id, wizard: wizardStore, router } = this.props
        const { query: { short_code } = {} } = router

        if (wizardStore.wizards[id]) {
            const { steps } = wizardStore.wizards[id]
            const firstStep = steps[0]

            Router.replace(
                {
                    pathname: firstStep.url,
                    query: {
                        id,
                        ...(short_code ? { short_code } : {}),
                    },
                },
                {
                    pathname: firstStep.as,
                    query: {
                        id,
                        ...(short_code ? { short_code } : {}),
                    },
                },
            )
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = (state) => ({
    wizard: state.wizard,
})

Wrapper.defaultProps = {
    submitPayload: {},
}

Wrapper.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
    submitPayload: PropTypes.objectOf(PropTypes.object),
    submitActionType: PropTypes.string.isRequired,
    wizard: PropTypes.objectOf(PropTypes.object).isRequired,
    router: PropTypes.shape({
        query: PropTypes.shape({
            short_code: PropTypes.string,
        }),
    }).isRequired,
}

export default connect(mapStateToProps)(withRouter(Wrapper))
