import PropTypes from 'prop-types' 
import React from 'react'
import classNames from 'classnames'
import Head from 'next/head'
import ContentContainer from 'piconetworks/pkg-content-container'
import Header from 'piconetworks/pkg-header'

import Image from 'piconetworks/pkg-image'

import style from './style.module.scss'

export default class Invalid extends React.Component {
    render() {
        return (
            <div className={style.layoutContainer}>
                <Head>
                    <title>Hmm...</title>
                    {/*
                <meta property="og:title" content={headTitle} />
                <meta property="og:image" content={avatarImg} />
                <meta name="twitter:title" content={headTitle} />
                <meta name="twitter:image" content={avatarImg} /> */}

                    <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                    />
                    {process.env.STAGE === 'production' && <script defer data-domain="hype.co" src="https://plausible.io/js/script.js"></script>}
                </Head>
                <div className={classNames(style.mainWrapper)}>
                    <Header
                        className={style.headerClass}
                    >
                        <div className={style.headerRight} />
                    </Header>
                    <ContentContainer>
                        <div
                            className={classNames(style.modalTopBorder)}
                        />
                        <Image.CoverPhoto
                            className={classNames(style.banner)}
                            style={{
                                backgroundImage: 'url(https://development-cms-assets.s3.amazonaws.com/landing_pages/img/bannerTrypico.jpeg)',
                            }}
                        >
                            <Image.Avatar
                                src="https://development-cms-assets.s3.amazonaws.com/landing_pages/img/trypicoLogo.jpg"
                                className={classNames(style.bannerAvatar)}
                                style={{ backgroundColor: '#fff' }}
                                width="90px"
                                height="90px"
                            />
                        </Image.CoverPhoto>
                        <div className={classNames(style.mainContentArea)}>
                            <div className={classNames(style.mainContent)}>
                                <h1>Hmmm</h1>
                                <p>The page you are looking for can&#39;t be found. Please check your URL or visit any of the links on this page.</p>
                                <a href="https://hype.co">Learn more about Hype.</a>
                            </div>
                        </div>
                    </ContentContainer>
                </div>
            </div>
        )
    }
}
