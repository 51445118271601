import React from 'react'

export default ({ title = 'Keep Subscription', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M6.36 6.35A7.958 7.958 0 0 1 12.01 4C16.43 4 20 7.58 20 12s-3.57 8-7.99 8c-3.73 0-6.84-2.55-7.73-6h2.08c.82 2.33 3.04 4 5.65 4 3.31 0 6-2.69 6-6s-2.69-6-6-6c-1.66 0-3.14.69-4.22 1.78l2.366 2.366a.5.5 0 0 1-.353.854H4.01V5.207a.5.5 0 0 1 .854-.353L6.36 6.35z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Keep Subscription"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M6.36 6.35A7.958 7.958 0 0 1 12.01 4C16.43 4 20 7.58 20 12s-3.57 8-7.99 8c-3.73 0-6.84-2.55-7.73-6h2.08c.82 2.33 3.04 4 5.65 4 3.31 0 6-2.69 6-6s-2.69-6-6-6c-1.66 0-3.14.69-4.22 1.78l2.366 2.366a.5.5 0 0 1-.353.854H4.01V5.207a.5.5 0 0 1 .854-.353L6.36 6.35z"
            fill={color}
        />
    </svg>
)
