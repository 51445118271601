import getLandingPage from './getLandingPage'
import fetchLandingPage from './fetchLandingPage'
import checkIfShowOfferCode from './checkIfShowOfferCode'

const sagas = (duck) => ({
    * getLandingPage(payload) {
        yield getLandingPage({ ...payload, ...duck })
    },
    * fetchLandingPage(payload) {
        yield fetchLandingPage({ ...payload, ...duck })
    },
    * checkIfShowOfferCode(payload) {
        yield checkIfShowOfferCode({ ...payload, ...duck })
    },
})

export default sagas
