import { showConsoleLogs } from './debug'
import HEX from './HEX'
import RGB from './RGB'

const WHITE = '#FFFFFF'
const DARK_GRAY = '#000000'

export const hexToRgba = (hex = '', a) => HEX.toRGB(hex, a)

export const hexToRgb = (hex = '') => HEX.toRGB(hex)

export const isHex = (value = '') => HEX.isHex(value)

export const isRGB = (value = '') => RGB.isRGB(value)

export const isRGBA = (value = '') => RGB.isRGBA(value)

export const RgbToHex = (r, g, b ) => {
    let red = r
    let green = g
    let blue = b
    if (typeof r === 'object') {
        red = r.r
        green = r.g
        blue = r.b
        if (showConsoleLogs()) {
            console.groupCollapsed('RgbToHex deconstruct', r)
            console.log('red', red)
            console.log('green', green)
            console.log('blue', blue)
            console.groupEnd()
        }
    }

    if (showConsoleLogs()) {
        console.groupCollapsed('RgbToHex')
        console.log('r, g, b', r, g, b)
        console.log('red:', red, 'green:', green, 'blue:', blue)
        console.groupEnd()
    }

    r = red.toString(16)
    g = green.toString(16)
    b = blue.toString(16)

    if (r.length == 1)
        r = '0' + r
    if (g.length == 1)
        g = '0' + g
    if (b.length == 1)
        b = '0' + b

    return '#' + r + g + b
}

export const convertRgbaToObject = (rgba) => {
    const rgbKeys = ['r', 'g', 'b', 'a']
    let rgbaObject = {}
    let color = rgba.replace(/^rgba?\(|\s+|\)$/g,'').split(',')
    for (let i in rgbKeys)
        rgbaObject[rgbKeys[i]] = color[i] || 1

    return rgbaObject
}

export const rgbaToRgb = (rgba) => {
    const { r, g, b, a } = convertRgbaToObject(rgba)
    const returnValue = {
        r: Math.round((1 - a) * 255 + a * r),
        g: Math.round((1 - a) * 255 + a * g),
        b: Math.round((1 - a) * 255 + a * b)
    }
    if (showConsoleLogs()) {
        console.groupCollapsed('rgbaToRgb')
        console.log('rgba', rgba)
        console.log('returning', returnValue)
        console.groupEnd()
    }
    return returnValue
}

export const rgbToObject = (rgb = '') => {
    const [r, g, b] = rgb.replace(/[^\d,]/g, '').split(',')
    return { r, g, b }
}

const getBrightness = (hex = '') => {
    let r, g, b, rgbObject = {}
    if (isHex(hex)) {
        if (showConsoleLogs()) {
            console.groupCollapsed('getBrightness')
            console.log('color is a hex', hex)
            console.log('now converted to rgb', HEX.toRGB(hex))
            console.groupEnd()
        }
        rgbObject = HEX.toRGB(hex)
    } else if (isRGBA(hex)) {
        if (showConsoleLogs()) {
            console.groupCollapsed('getBrightness')
            console.log('color is a rgba', hex)
            console.log('now converted to rgb', rgbaToRgb(hex))
            console.groupEnd()
        }
        rgbObject = rgbaToRgb(hex)
    } else if (isRGB(hex)) {
        if (showConsoleLogs()) {
            console.groupCollapsed('getBrightness')
            console.log('color is a rgb', hex)
            console.log('now converted to object', rgbToObject(hex))
            console.groupEnd()
        }
        rgbObject = rgbToObject(hex)
    }
    r = rgbObject.r
    g = rgbObject.g
    b = rgbObject.b

    return (r * 299 + g * 587 + b * 114) / 1000
}

export const filterColor = (hex = '', filter) => {
    if (isDark(hex, 176)) {
        return HEX.lighten(hex, filter)
    } else {
        return HEX.darken(hex, filter)
    }
}
export const isDark = (hex = '', threshold) => {
    if (showConsoleLogs()) {
        console.groupCollapsed('isDark')
        console.log('hex', hex)
        console.log('threshold', threshold)
        console.log('getBrightness', getBrightness(hex))
        console.log('getBrightness < threshold', getBrightness(hex) < threshold)
        console.groupEnd()
    }
    return getBrightness(hex) < threshold
}
export const getContrastColor = (hex = '') => {
    try {
        if (!hex || hex === '') {
            console.warn('Hex cannot be undefined or empty', hex, 'returning #2d2d2d')
            // as a fallback
            return '#2d2d2d'
        }
        return isDark(hex, 176) ? WHITE : DARK_GRAY        
    } catch (error) {
        console.error(error)
    }
}
export const getHoverColor = (hex = '') => HEX.saturate(filterColor(hex, 5), 2)

export const getActiveColor = (hex = '') => filterColor(hex, 10)

export const isColor = (color) => {
    const s = new Option().style
    s.color = color
    return s.color !== ''
}

export const getBgColor = (hex = '', alpha, returnObject) => {
    const { r, g, b } = HEX.toRGB(hex)

    const lightR = Math.round((1 - alpha) * 255 + alpha * r)
    const lightG = Math.round((1 - alpha) * 255 + alpha * g)
    const lightB = Math.round((1 - alpha) * 255 + alpha * b)

    return (returnObject) ?
        { r: lightR, g: lightG, b: lightB } :
        `rgb(${lightR}, ${lightG}, ${lightB})`
}