import { CONSTANTS } from 'analytics'

/**
 * Check if pixel script has loaded in DOM
 *
 * @param {string} scriptSrc - the src of the pixel script to check for
 * @returns {boolean}
 */
const scriptLoaded = (scriptSrc) => {
    const scripts = Object.values(document?.scripts || {})
    const srcRegex = new RegExp(`^${scriptSrc}`)

    return !!scripts.find((script) => srcRegex.test(script.src))
}


/**
 * Load pixel script on initialize into DOM
 *
 * @param {string} scriptSrc - the src of the pixel script to load
 * @returns {boolean}
 */
const loadScript = (scriptSrc) => {
    const pixelScript = document.createElement('script')
    pixelScript.async = true
    pixelScript.src = scriptSrc
    pixelScript.type = 'text/javascript'

    // Fast way to insert assuming at least one other exists (instead of head)
    const script = document.getElementsByTagName('script')[0]
    script.parentNode.insertBefore(pixelScript, script)
}



/**
 *
 * @param {object} pluginConfig - Configuration object with information specific to the pixel being created
 * @param {string|null} [pluginConfig.publisherId] - id of publisher for user being tracked
 * @param {string} [pluginConfig.context] - The app which is instantiating the particular plugin
 * @param {string} pluginConfig.provider - Name of the pixel provider i.e. Twitter, TikTok, Facebook, etc.
 * @param {object} [pluginConfig.pixelConfig] - Custom Pixel config specific to the third-party being used
 * @param {string} pluginConfig.pixelConfig.scriptSrc - Pixel Script to load pixel, provided by the app being integrated
 * @param {object} [pluginConfig.customMethods] - Any custom methods needed within the Pixel Plugin, possibly for accessing/setting data in storage
 * @param {object} [pluginConfig.coreEvents] - @see https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/events.js for a reference of all events
 * @returns {object}
 */

const createPixelPlugin = ({
    provider = 'pico',
    publisherId = null,
    context = 'gadget',
    pixelConfig = {},
    customMethods = {},
    coreEvents = {},
    enabled = true
} = {}) => {
    const pixelPluginConfig = {
        context,
        publisherId,
        ...pixelConfig,
        scriptLoaded
    }

    const {
        initialize: customInitialize  = () => {},
        loaded: customLoaded = () => true,
        ...customCoreEvents
    } = coreEvents

    const name = `analytics-plugin-${provider}-pixel`
    return {
        name,
        enabled,
        config: pixelPluginConfig,

        /**
         * Core Events
         * @see https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/events.js for a list of core events
         * The Pixel Factory comes with load script run at initialization
         *
         * Args supplied to events: [config, instance, abort, payload, plugins]
         */
        initialize: ({
            config
        }) => {
            const { scriptSrc } = config

            /**
             * Load the pixel if not loaded already by injecting script into document
             */
            if (!scriptLoaded(scriptSrc)) {
                loadScript(scriptSrc)
                customInitialize()
                console.log(`${name} has loaded!`)
            }
        },
        //
        loaded: () => {
            const { scriptSrc } = pixelPluginConfig

            return pixelPluginConfig.scriptLoaded(scriptSrc) && customLoaded(pixelPluginConfig)
        },
        ...customCoreEvents,

        /**
         * Custom Methods
         */
        methods: {
            guest({ id, ...traits } = {}) {
                this.instance.storage.setItem(CONSTANTS.ANON_ID, id)
                this.instance.storage.setItem(CONSTANTS.USER_TRAITS, traits)
            },
            user({ id, ...traits } = {}) {
                this.instance.storage.setItem(CONSTANTS.USER_ID, id)
                this.instance.storage.setItem(CONSTANTS.USER_TRAITS, traits)
            },
            ...customMethods
        }
    }
}

export default createPixelPlugin
