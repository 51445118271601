import Router from 'lib/RouterAdapterDecorator'
import { call } from 'redux-saga/effects'

function* push({ payload = {} }) {
    const { url, as = url, options = { shallow: false } } = payload

    yield call(Router.push, url, as, options)
}

export default push
