import { processProperties, processCompoundedProperties } from './process'
import { themeSerializer, formSerializer } from "./instantiate"
import { showConsoleLogs } from "./utils"
import { tagGeneratorType } from "./types"

// /** The id of the theme or form */
// id: string = '',
// /** The type, either a theme or form */
// type: string,
// /** The customizations to generate CSS from */
// customizations: customization[],
// /** Whether or not we're in preview mode */
// isPreview: boolean,
// /** Whether or not we're previewing a theme */
// isThemePreview: boolean,
const tagGenerator = (tagGenerator: tagGeneratorType) => {
    try {
        const { id, type, customizations, isPreview, isThemePreview } = tagGenerator
        let styles = ''
        let videoTagUrl = ''
        let fontFaces = ''
        const isForm = type === 'form'
        let customizationsPlus = [...customizations]

        // this is in case a user changes the page or block background value on their form
        // because we don't give them the ability to edit the page or block contrast color yet on a page by page basis, we need to add it here
        // values need to be set because of an issue in the property serializer but we're not actually using them for these
        const pageBackgroundCustomization = customizationsPlus?.filter(c => c?.property === 'page-background-value')
        const blockBackgroundCustomization = customizationsPlus?.filter(c => c?.property === 'block-background-value')

        if (pageBackgroundCustomization.length > 0) {
            const pageContrastColor = customizationsPlus?.filter(c => c?.property === 'page-contrast-color')
            const pageButtonContrastColor = customizationsPlus?.filter(c => c?.property === 'page-button-contrast-color')
            if (pageContrastColor.length === 0) {
                customizationsPlus.push({
                    id: 'fake-id-1',
                    property: 'page-contrast-color',
                    type: 'style',
                    value: '#FFFFFF'
                })
            }
            if (pageButtonContrastColor.length === 0) {
                customizationsPlus.push({
                    id: 'fake-id-2',
                    property: 'page-button-contrast-color',
                    type: 'style',
                    value: '#000000'
                })
            }
        }
        // console.log('blockBackgroundCustomization', blockBackgroundCustomization)
        // we haven't added the block-contrast-color or block-button-contrast-color to the api yet for users to be able to edit, so we need to add it here
        
        if (blockBackgroundCustomization.length > 0) {
            const blockContrastColor = customizationsPlus.filter(c => c?.property === 'block-contrast-color')
            const blockButtonContrastColor = customizationsPlus.filter(c => c?.property === 'block-button-contrast-color')
            if (blockContrastColor.length === 0) {
                customizationsPlus.push({
                    id: 'fake-id-3',
                    property: 'block-contrast-color',
                    type: 'style',
                    value: '#000000'
                })
            }
            if (blockButtonContrastColor.length === 0) {
                customizationsPlus.push({
                    id: 'fake-id-4',
                    property: 'block-button-contrast-color',
                    type: 'style',
                    value: '#000000'
                })
            }
        }

        const memo = id ? id : 'new'
        const serializer = isForm ? formSerializer(memo) : themeSerializer(memo)
        
        const { css, compoundedProperties, cssVariables } = processProperties(customizationsPlus, isForm, serializer, isPreview, isThemePreview)
        const { compoundedCss, compoundedCssVariables } = processCompoundedProperties(compoundedProperties, isForm, isPreview, isThemePreview)

        const pageFontBody = serializer.getByApiPropertyName('page-font-body')
        const pageFontHeading = serializer.getByApiPropertyName('page-font-heading')
        const body = getFontSource(pageFontBody)
        const heading = getFontSource(pageFontHeading)

        if (heading !== null && heading !== undefined) {
            fontFaces += heading
        }

        if (body !== null && body !== undefined) {
            fontFaces += body
        }

        styles = `
/* ${type}_id: ${memo} */

`
        if (fontFaces) {
            styles += `/* fontFaces */\n`
            styles += `${fontFaces}`
            styles += `\n/* end fontFaces */\n`
        }
        if (cssVariables) {
            styles += `/* cssVariables */\n`
            styles += `${cssVariables}`
            styles += `\n/* end cssVariables */\n`
        }
        if (compoundedCssVariables) {
            styles += `/* compoundedCssVariables */\n`
            styles += `${compoundedCssVariables}\n`
            styles += `\n/* end compoundedCssVariables */\n`
        }
        if (compoundedCss) {
            styles += `/* compounded properties css */\n`
            // console.log('css', compoundedCss)
            styles += `${compoundedCss}`
            styles += `/* end compounded properties css */\n`
        }
        if (css) {
            styles += `/* properties css */\n`
            styles += `${css}`
            styles += `/* end properties css */\n`
        }

        styles+= `\n/* end ${type}_id: ${memo} */\n`

        styles += `
/* text sizes */
:root {
    --p-font-size: 0.875rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.125rem;
    --h3-font-size: 1rem;
    --h4-font-size: 0.875rem;
}

#preview p, #theme-preview p, #form p,
#preview span, #theme-preview span, #form span,
#preview label, #theme-preview label, #form label,
#preview div, #theme-preview div, #form div  {
    font-size: var(--p-font-size);
}

#preview h1, #theme-preview h1, #form h1 {
    font-size: var(--h1-font-size);
}

#preview h2, #theme-preview h2, #form h2 {
    font-size: var(--h2-font-size);
}

#preview h3, #theme-preview h3, #form h3 {
    font-size: var(--h3-font-size);
}

#preview h4, #theme-preview h4, #form h4 {
    font-size: var(--h4-font-size);
    font-weight: bold;
}
/* end text sizes */\n
        `
        styles += `
/* account menu variables */
#menu {
    --block-background-value: #FFFFFF;
    --block-contrast-color: var(--linkColor);
    --block-button-contrast-color: #000000;
    --page-background-value: var(--linkColor);
    --page-contrast-color: var(--contrastColor);
    --page-button-contrast-color: var(--contrastColor);
    --block-border-radius: 0.5rem;
    --block-border-width: 0;
    --block-shadow-style: none;
    --page-font-heading: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --page-font-body: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#menu p, #menu span, #menu label, #menu div, #menu input, #menu li, #menu ol {
    font-family: var(--page-font-body);
}
#menu h1, #menu h2, #menu h3, #menu h4, #menu h5, #menu h6, #menu blockquote, #menu blockquote > div > span > span {
    font-family: var(--page-font-heading);
}
/* end menu variables */\n
        `

        const pageBackgroundType = serializer.getByApiPropertyName('page-background-type')
        if (pageBackgroundType?.value === 'video') {
            const pageBackground = serializer.getByApiPropertyName('page-background-value')
            videoTagUrl = pageBackground.value
        }

        if (showConsoleLogs()) {
            console.groupCollapsed(`1. [${type}] tagGenerator`)
            console.count('tagGenerator')
            console.log('Called with id', id)
            console.log('type', type)
            console.log('customizationsPlus', customizationsPlus)
            console.log('returns styles', styles)
            console.log('videoTagUrl', videoTagUrl)
            console.groupEnd()
        }
        // console.log('sttyles', styles, linkColor, videoTagUrl, type, serializer)
        return {
            styles,
            ...videoTagUrl && { videoTagUrl },
            type,
            serializer
        }

    } catch (error) {
        console.error(error)
    }
}

const getFontSource = (fontProperty) => {
    if (fontProperty._hasBeenModified) {
        const fontNameProperty = fontProperty.value
        const name = fontNameProperty.split(',')[0].replace(/['"]/g, '')
        const filename = name.toLowerCase().replace(/\s+/g, '-')
        return `
@font-face {
    font-display: swap;
    font-family: ${name};
    src: url('/assets/fonts/${filename}.woff2');
    src: url('/assets/fonts/${filename}.woff');
}
`
    }
}

export default tagGenerator