import Account from 'modules/account'

import storeSubscriber from '../storeSubscriber'

const getHasPassword = async ({
    dispatch,
    payload: {
        email,
        phone,
        publisherId,
    },
}) => {
    dispatch(Account.creators.getHasPassword({
        email,
        phone,
        publisherId,
    }))

    let hasPassword
    let isNewRegistration

    const response = await storeSubscriber({
        checks: [
            (state) => {
                hasPassword = Account.selectors.hasPassword(state)
                isNewRegistration = Account.selectors.isNewRegistration(state)

                return (
                    typeof Account.selectors.hasPassword(state) !== 'undefined'
                    && Account.selectors.isNotFetching(state)
                )
            },
            Account.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }

    return {
        hasPassword,
        isNewRegistration,
    }
}

export default getHasPassword
