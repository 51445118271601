const types = [
    'PURCHASE_PLAN',
    'PURCHASE_PLAN_SUCCESS',
    'PURCHASE_PLAN_ERROR',
    'PURCHASE_PLAN_GROUP',
    'PURCHASE_PLAN_GROUP_SUCCESS',
    'PURCHASE_PLAN_GROUP_ERROR',
    'PURCHASE_SWITCH',
    'PURCHASE_SWITCH_SUCCESS',
    'PURCHASE_SWITCH_ERROR',
    'CANCEL_PLAN',
    'CANCEL_PLAN_SUCCESS',
    'CANCEL_PLAN_ERROR',
    'KEEP_PLAN',
    'KEEP_PLAN_SUCCESS',
    'KEEP_PLAN_ERROR',
    'VERIFY_DISCOUNT_CODE',
    'VERIFY_DISCOUNT_CODE_SUCCESS',
    'VERIFY_DISCOUNT_CODE_ERROR',
    'RESET',
    'CHECK_SWITCH_PLAN_NOW',
    'CHECK_SWITCH_PLAN_NOW_SUCCESS',
    'CHECK_SWITCH_PLAN_NOW_ERROR',
    'HANDLE_DIRECT_PLAN',
    'GET_PLANS',
    'GET_PLANS_SUCCESS',
    'GET_PLANS_ERROR',
]

const creators = (duck) => ({
    purchasePlan: (payload) => ({
        type: duck.types.PURCHASE_PLAN,
        ...payload,
    }),
    purchaseSwitch: (payload) => ({
        type: duck.types.PURCHASE_SWITCH,
        ...payload,
    }),
    purchasePlanGroup: (payload) => ({
        type: duck.types.PURCHASE_PLAN_GROUP,
        ...payload,
    }),
    cancelPlan: (payload) => ({
        type: duck.types.CANCEL_PLAN,
        ...payload,
    }),
    keepPlan: (payload) => ({
        type: duck.types.KEEP_PLAN,
        ...payload,
    }),
    verifyDiscountCode: (payload) => ({
        type: duck.types.VERIFY_DISCOUNT_CODE,
        ...payload,
    }),
    reset: (payload) => ({
        type: duck.types.RESET,
        ...payload,
    }),
    checkCanSwitchPlanNow: (payload) => ({
        type: duck.types.CHECK_SWITCH_PLAN_NOW,
        ...payload,
    }),
    directPlanLink: (payload) => ({
        type: duck.types.HANDLE_DIRECT_PLAN,
        ...payload
    }),
    getPlans: (payload) => ({
        type: duck.types.GET_PLANS,
        ...payload,
    }),
})

export {
    types,
    creators,
}
