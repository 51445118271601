import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Tiny.module.scss'

const Tiny = ({children, className, ...props}) => {
    return (
        <small className={cx(styles.tiny, className)} {...props}>
            {children}
        </small>
    )
}

Tiny.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
}

export default Tiny
