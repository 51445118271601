import Joi from 'joi'
import productTypes from '../utils/productTypes'

const GadgetUserPayment = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).required().description('The id of the popup/landing page in Hype Forms table'),
        product_type: Joi.string().valid(...productTypes).optional().description('The type of product the user paid for'),
        custom_price: Joi.string().optional().description('The custom price of the user\'s purchase'),
        currency: Joi.string().default('USD').optional().description('Currency the user used to pay for product'),
        product_option_id: Joi.string().guid({ version: ['uuidv4'] }).optional().description('The Hype-assigned id for the particular product option')
    })
    .description('gadget:user_payment event attributes ')
    .meta({ name: 'GadgetUserPayment', filename: 'gadget:user_payment.model' })

export default GadgetUserPayment
