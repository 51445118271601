import Joi from 'joi'

const LandingPagePageView = Joi.object()
    .keys({
        theme_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the theme applied'),
    })
    .description('landing_page:page_view event attributes ')
    .meta({ name: 'LandingPagePageView', filename: 'landing_page:page_view.model' })

export default LandingPagePageView
