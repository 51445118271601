import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'
import { format } from 'piconetworks/pkg-currency'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanPass = ({
    changeMenuRoute,
    currentBillingIntervalTotalPaid,
    currentSubscriptionEnd,
    currentSubscriptionStart,
    userLoader,
    guestLoader,
    currentProductOptionCurrency,
    discordLink,
    utmRootUrl,
    queryOverride,
    priceText,
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>My Pass</h3>
            </div>
            <div className={style.container}>
                <ul className={style.menuContent} style={{ color: linkColor,
                    borderBottom: '1px solid #ddd' }}>
                    <li className={style.list}>
                        <p className={style.listTitle}>PASS START DATE</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {dayjs(currentSubscriptionStart).format('LL')}
                            </div>
                        </div>
                    </li>
                    <li className={style.list}>
                        <p className={style.listTitle}>FINAL PAYMENT</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {`${priceText(format({
                                    amount: currentBillingIntervalTotalPaid,
                                    currencyCode: currentProductOptionCurrency,
                                    symbol: false
                                }), currentProductOptionCurrency)} on ${dayjs(currentSubscriptionStart).format('LL')}`}
                            </div>
                        </div>
                    </li>
                    <li className={style.list}>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                Your pass is set to end on
                                {' '}
                                <strong>{dayjs(currentSubscriptionEnd).format('LL')}</strong>
                            </div>
                        </div>
                    </li>

                    { discordLink && (
                        <li className={style.list}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    Your discord access link is
                                    {' '}
                                    <strong>{discordLink}</strong>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </MenuWrapper>
    )
}

ManagePlanPass.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    utmRootUrl: PropTypes.string,
    currentProductOptionCurrency: PropTypes.any,
    discordLink: PropTypes.string,
    queryOverride:PropTypes.object,
    priceText: PropTypes.func.isRequired,
    userLoader: PropTypes.object,
    guestLoader: PropTypes.object
}

export default ManagePlanPass
