import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Flexbox } from 'piconetworks/pkg-box'

import styles from './Feedback.module.scss'

const FormFeedback = ({
    children,
    className,
    variant,
    icon,
    ...props
}) => {
    return (
        <Flexbox.Container alignItems='center' className={cx(styles.feedback, styles[variant], className)} {...props}>
            {icon}
            {children && <span className={cx(styles.text)}>{children}</span>}
        </Flexbox.Container>
    )
}

FormFeedback.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.node,
    variant: PropTypes.string,
}

export default FormFeedback
