import { produce } from 'immer'

export const initialState = {
    claim: null,
    isAuthed: false,
    error: null,
    redirectUrl: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.LOGIN:
            draftState.error = duck.initialState.error
            break

        case duck.types.LOGIN_SUCCESS:
            draftState.isAuthed = true
            break
    
        case duck.types.LOGIN_ERROR:
            draftState.error = error
            break

        case duck.types.SOCIAL_LOGIN:
            draftState.error = duck.initialState.error
            break

        case duck.types.SOCIAL_LOGIN_ERROR:
            draftState.error = error
            break

        case duck.types.LOGOUT:
            draftState.error = duck.initialState.error
            break

        case duck.types.LOGOUT_SUCCESS:
            draftState.isAuthed = false
            draftState.error = duck.initialState.error
            break

        case duck.types.TOKEN_LOGIN:
            draftState.isAuthed = duck.initialState.isAuthed
            draftState.error = duck.initialState.error
            break

        case duck.types.TOKEN_LOGIN_SUCCESS:
            draftState.isAuthed = true
            draftState.error = duck.initialState.error
            break

        case duck.types.TOKEN_LOGIN_ERROR:
            draftState.isAuthed = false
            draftState.error = error
            break

        case duck.types.CLAIM_MAGIC_LINK:
            draftState.claim = payload.claim
            break

        case duck.types.CLAIM_MAGIC_LINK_SUCCESS:
            draftState.claim = duck.initialState.claim
            break

        case duck.types.CLAIM_MAGIC_LINK_ERROR:
            draftState.claim = duck.initialState.claim
            draftState.error = error
            break

        case duck.types.REGISTER_CONFIRM_SUCCESS:
            draftState.redirectUrl = payload.redirectUrl
            break

        case duck.types.REGISTER_CONFIRM_ERROR:
            draftState.redirectUrl = false
            break

        default:
      // do nothing
    }
})

export default reducer
