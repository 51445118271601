import Joi from 'joi'

const DashboardUsernameUpdate = Joi.object()
    .keys({
        new_username: Joi.string().required().description('Creator Username'),
    })
    .description('dashboard:username_update event attributes ')
    .meta({ name: 'DashboardUsernameUpdate', filename: 'dashboard:username_update.model' })

export default DashboardUsernameUpdate
