import {
    addPaymentIcon,
    bankIcon,
    upIcon,
    downIcon,
    eyeIconCrossed,
    eyeIcon,
    giftIcon,
    userIcon,
    envelopeIcon,
    refreshIcon,
    questionMarkIcon,
    logoutIcon,
    editIcon,
    trashIcon,
    switchPlan,
    paymentMethod,
    closeIcon,
    picoIcon,
    backIcon,
    keepIcon,
    checkMarkIcon,
    downloadIcon,
    appleIcon,
    apple_musicIcon,
    apple_podcastsIcon,
    cash_appIcon,
    discordIcon,
    emailIcon,
    etsyIcon,
    facebookIcon,
    githubIcon,
    googleIcon,
    instagramIcon,
    linkedinIcon,
    pinterestIcon,
    snapchatIcon,
    spotifyIcon,
    threadsIcon,
    tiktokIcon,
    twitchIcon,
    twitterIcon,
    venmoIcon,
    websiteIcon,
    whatsappIcon,
    youtubeIcon
} from './customizableIcons'

const getSvgImage = (color = '444', iconType = 'question-mark-icon') => {
    switch (iconType) {
        case 'user-icon':
            return userIcon(color)
        case 'envelope-icon':
            return envelopeIcon(color)
        case 'refresh-icon':
            return refreshIcon(color)
        case 'question-mark-icon':
            return questionMarkIcon(color)
        case 'logout-icon':
            return logoutIcon(color)
        case 'edit-icon':
            return editIcon(color)
        case 'trash-icon':
            return trashIcon(color)
        case 'switch-plan':
            return switchPlan(color)
        case 'update-payment':
            return paymentMethod(color)
        case 'close-icon':
            return closeIcon(color)
        case 'pico-icon':
            return picoIcon(color)
        case 'back-icon':
            return backIcon(color)
        case 'keep-icon':
            return keepIcon(color)
        case 'gift-icon':
            return giftIcon(color)
        case 'check-icon':
            return checkMarkIcon(color)
        case 'eye-icon':
            return eyeIcon(color)
        case 'eye-icon-crossed':
            return eyeIconCrossed(color)
        case 'up-icon':
            return upIcon(color)
        case 'down-icon':
            return downIcon(color)
        case 'bank-icon':
            return bankIcon(color)
        case 'add-payment-icon':
            return addPaymentIcon(color)
        case 'download-icon':
            return downloadIcon(color)
        case 'apple-icon':
            return appleIcon()
        case 'apple_music-icon':
            return apple_musicIcon()
        case 'apple_podcasts-icon':
            return apple_podcastsIcon()
        case 'cash_app-icon':
            return cash_appIcon()
        case 'discord-icon':
            return discordIcon()
        case 'email-icon':
            return emailIcon()
        case 'etsy-icon':
            return etsyIcon()
        case 'facebook-icon':
            return facebookIcon()
        case 'github-icon':
            return githubIcon()
        case 'google-icon':
            return googleIcon()
        case 'instagram-icon':
            return instagramIcon()
        case 'linkedin-icon':
            return linkedinIcon()
        case 'pinterest-icon':
            return pinterestIcon()
        case 'snapchat-icon':
            return snapchatIcon()
        case 'spotify-icon':
            return spotifyIcon()
        case 'threads-icon':
            return threadsIcon()
        case 'tiktok-icon':
            return tiktokIcon()
        case 'twitch-icon':
            return twitchIcon()
        case 'twitter-icon':
            return twitterIcon()
        case 'venmo-icon':
            return venmoIcon()
        case 'website-icon':
            return websiteIcon()
        case 'whatsapp-icon':
            return whatsappIcon()
        case 'youtube-icon':
            return youtubeIcon()
        default:
            return null
    }
}

export default getSvgImage
