import generateAction from 'piconetworks/pkg-generate-action'

const getHistory = generateAction({
    baseActionCreatorName: 'getPurchaseHistory',
    baseActionTypeName: 'GET_PURCHASE_HISTORY',
})

const getSwitchProducts = generateAction({
    baseActionCreatorName: 'getSwitchProducts',
    baseActionTypeName: 'GET_SWITCH_PRODUCTS',
})

const createSwitchProduct = generateAction({
    baseActionCreatorName: 'createSwitchProduct',
    baseActionTypeName: 'CREATE_SWITCH_PRODUCT',
})

const getSwitchProration = generateAction({
    baseActionCreatorName: 'getSwitchProration',
    baseActionTypeName: 'GET_SWITCH_PRORATION',
})

const getGift = generateAction({
    baseActionCreatorName: 'getGift',
    baseActionTypeName: 'GET_GIFT',
})

const createGift = generateAction({
    baseActionCreatorName: 'createGift',
    baseActionTypeName: 'CREATE_GIFT',
})

const updateGift = generateAction({
    baseActionCreatorName: 'updateGift',
    baseActionTypeName: 'UPDATE_GIFT',
})

const deleteGift = generateAction({
    baseActionCreatorName: 'deleteGift',
    baseActionTypeName: 'DELETE_GIFT',
})

const purchaseProduct = generateAction({
    baseActionCreatorName: 'purchaseProduct',
    baseActionTypeName: 'PURCHASE_PRODUCT',
})

const reactivateProduct = generateAction({
    baseActionCreatorName: 'reactivateProduct',
    baseActionTypeName: 'REACTIVATE_PRODUCT',
})

const cancelProduct = generateAction({
    baseActionCreatorName: 'cancelProduct',
    baseActionTypeName: 'CANCEL_PRODUCT',
})

const types = [
    ...getHistory.types,
    ...getSwitchProducts.types,
    ...createSwitchProduct.types,
    ...getSwitchProration.types,
    ...getGift.types,
    ...createGift.types,
    ...updateGift.types,
    ...deleteGift.types,
    ...purchaseProduct.types,
    ...reactivateProduct.types,
    ...cancelProduct.types,
]

const creators = (duck) => ({
    ...getHistory.creators(duck),
    ...getSwitchProducts.creators(duck),
    ...createSwitchProduct.creators(duck),
    ...getSwitchProration.creators(duck),
    ...getGift.creators(duck),
    ...createGift.creators(duck),
    ...updateGift.creators(duck),
    ...deleteGift.creators(duck),
    ...purchaseProduct.creators(duck),
    ...reactivateProduct.creators(duck),
    ...cancelProduct.creators(duck),
})

export {
    types,
    creators,
}
