import generateAction from 'piconetworks/pkg-generate-action'

const getArticle = generateAction({
    baseActionCreatorName: 'getArticle',
    baseActionTypeName: 'GET_ARTICLE',
})

const updateArticle = generateAction({
    baseActionCreatorName: 'updateArticle',
    baseActionTypeName: 'UPDATE_ARTICLE',
})

const authenticatedArticleRead = generateAction({
    baseActionCreatorName: 'authenticatedArticleRead',
    baseActionTypeName: 'AUTHENTICATED_ARTICLE_READ',
})

const authenticatedArticleVisit = generateAction({
    baseActionCreatorName: 'authenticatedArticleVisit',
    baseActionTypeName: 'AUTHENTICATED_ARTICLE_VISIT',
})

const guestArticleRead = generateAction({
    baseActionCreatorName: 'guestArticleRead',
    baseActionTypeName: 'GUEST_ARTICLE_READ',
})

const guestArticleVisit = generateAction({
    baseActionCreatorName: 'guestArticleVisit',
    baseActionTypeName: 'GUEST_ARTICLE_VISIT',
})

const types = [
    ...getArticle.types,
    ...updateArticle.types,
    ...authenticatedArticleRead.types,
    ...authenticatedArticleVisit.types,
    ...guestArticleRead.types,
    ...guestArticleVisit.types
]

const creators = (duck) => ({
    ...getArticle.creators(duck),
    ...updateArticle.creators(duck),
    ...authenticatedArticleRead.creators(duck),
    ...authenticatedArticleVisit.creators(duck),
    ...guestArticleRead.creators(duck),
    ...guestArticleVisit.creators(duck)
})

export {
    types,
    creators,
}
