import Loader from 'modules/loader'
import Gift from 'modules/gift'

import storeSubscriber from '../storeSubscriber'

const confirmPayment = async ({
    dispatch,
    payload,
    action,
    trackPaymentEvent = () => {},
}) => {
    dispatch(action(payload))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                const giftConversionSuccess = Gift.selectors.giftConversionSuccess(state)

                if (giftConversionSuccess) {
                    return true
                }

                return false
            },
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }

    trackPaymentEvent()
}

export default confirmPayment
