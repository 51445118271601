import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import axios from 'axios'
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'

import styles from './FormUploader.module.scss'

class Uploader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            preview: null,
        }
        this.getSignedUrl = this.getSignedUrl.bind(this)
        this.handleFinishedUpload = this.handleFinishedUpload.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    getSignedUrl(file, callback) {
        const { bucket, filePath, serverUrl } = this.props
        const params = {
            objectName: file.name,
            bucket,
            filePath
        }

        const headers = {
            'Content-Type': 'application/json'
        }
        axios
            .get(serverUrl, { params }, headers)
            .then((response) => {
                callback(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    handleFinishedUpload(info) {
        const { input, progress } = this.props
        input && input.onChange(info.signedUrl.split('?')[0])
        progress(false)
    }

    onDrop(file) {
        const { customOnDrop, progress } = this.props
        this.setState({ preview: file[0] && file[0].preview })
        progress(true)
        customOnDrop(file)
    }
    
    render() {
        const options = {
            getSignedUrl: this.getSignedUrl
        }
        const { preview } = this.state
        const {
            s3Url,
            child,
            className,
            filename,
            input,
            childStyle,
            savedImage,
            accept,
            multiple
        } = this.props
        const Component = child

        return (
            <DropzoneS3Uploader
                className={cx(styles.dropzone, className)}
                s3Url={s3Url}
                filename={filename}
                notDropzoneProps={[
                    'onFinish',
                    's3Url',
                    'filename',
                    'host',
                    'upload',
                    'isImage',
                    'notDropzoneProps',
                    'onDrop'
                ]}
                upload={options}
                onDrop={this.onDrop}
                onFinish={this.handleFinishedUpload}
                multiple={multiple}
                name={input.name}
                accept={accept}
            >
                <Component
                    childStyle={childStyle}
                    preview={preview}
                    savedImage={savedImage}
                    image={input && input.value}
                />
            </DropzoneS3Uploader>
        )
    }
}

Uploader.defaultProps = {
    customOnDrop: () => null,
    progress: () => null,
}

Uploader.propTypes = {
    /* Give Array List For Accepted File Types Example: ['.csv','.pdf'] */
    accept: PropTypes.array,
    bucket: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customOnDrop: PropTypes.func,
    filename: PropTypes.string,
    filePath: PropTypes.string,
    input: PropTypes.object,
    /* For Multiple Uploads */
    multiple: PropTypes.bool,
    progress: PropTypes.func,
    s3Url: PropTypes.string,
}

export default Uploader
