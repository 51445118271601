import { call, put } from 'redux-saga/effects'
import {
    DOWNLOADS
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'
import download from 'downloadjs'

function* getDownloadableFile({
    types,
    fileId,
    fileName,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${DOWNLOADS()}/${fileId}`,
            method: 'get',
            headers: {
                publisherid: publisherId,
            },
        })

        if (data) {
            const url = data.downloadable_url
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function (e) {
                download(e.target.response, fileName)
            }
            xhr.send()

            yield put({
                type: types.GET_DOWNLOADABLE_FILE_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch download')
        }
    } catch (error) {
        yield put({
            type: types.GET_DOWNLOADABLE_FILE_ERROR,
            error: error?.message,
        })
    }
}

export default getDownloadableFile
