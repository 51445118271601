import { types, creators } from './actions'
import reducer, { initialState } from './reducer'
import sagas, { takes } from './sagas'
import selectors from './selectors'

const text = {
    store: 'text',
    initialState,
    reducer,
    types,
    creators,
    sagas,
    takes,
    selectors,
}

export default text
