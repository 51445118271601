import generateAction from 'piconetworks/pkg-generate-action'

const getPayments = generateAction({
    baseActionCreatorName: 'getPayments',
    baseActionTypeName: 'GET_PAYMENTS',
})

const addPayment = generateAction({
    baseActionCreatorName: 'addPayment',
    baseActionTypeName: 'ADD_PAYMENT',
})

const updateDefaultPayment = generateAction({
    baseActionCreatorName: 'updateDefaultPayment',
    baseActionTypeName: 'UPDATE_DEFAULT_PAYMENT',
})

const deletePayment = generateAction({
    baseActionCreatorName: 'deletePayment',
    baseActionTypeName: 'DELETE_PAYMENT',
})

const types = [
    ...getPayments.types,
    ...addPayment.types,
    ...updateDefaultPayment.types,
    ...deletePayment.types
]

const creators = (duck) => ({
    ...getPayments.creators(duck),
    ...addPayment.creators(duck),
    ...updateDefaultPayment.creators(duck),
    ...deletePayment.creators(duck)
})

export {
    types,
    creators,
}
