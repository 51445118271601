import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Img.module.scss'

const Img = ({ src = '', alt = '', className, ...props }) => {
    if (!src) return null
    return <img className={classNames(styles.img, className)} src={src} alt={alt} {...props} />
}

Img.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    src: PropTypes.string.isRequired,
}

export default Img
