import storage from 'redux-persist/lib/storage'

import QueryStringParser from './QueryStringParser'

const UTMParams = {
    // eslint-disable-next-line camelcase
    utm_source: null,
    // eslint-disable-next-line camelcase
    utm_medium: null,
    // eslint-disable-next-line camelcase
    utm_campaign: null,
    // eslint-disable-next-line camelcase
    utm_term: null,
    // eslint-disable-next-line camelcase
    utm_content: null,
}

const UTMKey = 'pico_utm'

const UTMParser = () => {
    const { params: utmObject } = QueryStringParser(UTMParams, false)
    const utmParsed = Object.keys(utmObject).reduce((acc, current) => {
        const newKey = current.replace('utm_', '')
        // eslint-disable-next-line no-param-reassign
        acc[newKey] = utmObject[current]
        return acc
    }, {})

    const utmCache = storage.getItem(UTMKey)

    // if the utm object is full of nulls, the cache is better then the url
    if (Object.values(utmObject).every((value) => value === null)) {
        return utmCache
    }

    storage.setItem(UTMKey, JSON.stringify(utmParsed))

    return utmParsed
}

export default UTMParser
