import { put, putResolve, select } from 'redux-saga/effects'

import {
    REMOVE_TOKENS, REQUEST,
} from 'piconetworks/pkg-network-middleware'
import { RESET as MENU_RESET } from 'piconetworks/pkg-menu-middleware'
import {
    PICO_API_URL, OAUTH_USER,
} from 'piconetworks/pkg-endpoints'

function* logout({ types, publisherId, tokenKey, withNetworkRequest = true }) {

    try {
        const state = yield select()

        yield put({
            type: MENU_RESET,
        })

        if (withNetworkRequest) {
            yield putResolve({
                type: REQUEST,
                config: {
                    method: 'delete',
                    baseURL: PICO_API_URL(),
                    url: OAUTH_USER(),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        refreshtoken: state._network.refresh_token[tokenKey],
                        publisherid: publisherId,
                    },
                    timeout: 30000,
                },
            })
        }

        yield putResolve({
            type: REMOVE_TOKENS,
        })

        yield put({
            type: types.LOGOUT_SUCCESS,
            payload: {
                response: {},
            },
        })
    } catch (error) {
        yield putResolve({
            type: REMOVE_TOKENS,
        })

        yield put({
            type: types.LOGOUT_ERROR,
            error: error.message,
        })
    }
}

export default logout
