import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
} from 'piconetworks/pkg-endpoints'

function* getNewsletters({
    types,
    publisherId = null
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: '/client/newsletters',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...(!!publisherId && { publisherid : publisherId }),
                },
                timeout: 30000,
            },
        })
        if (!data){
            throw 'Could not fetch newsletters'
        }
        yield put({
            type: types.GET_NEWSLETTERS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        yield put({
            type: types.GET_NEWSLETTERS_ERROR,
            error: error?.message || 'GET_NEWSLETTER_ERROR',
        })
    }
}

export default getNewsletters
