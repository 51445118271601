import {
    select, call, put, putResolve, takeEvery, take,
} from 'redux-saga/effects'
import Router from 'next/router'

import { STORE_TOKENS, STORE_TOKENS_SUCCESS } from 'piconetworks/pkg-network-middleware'

import Loader from 'modules/loader'
import Client from 'modules/client'
import Claim from 'modules/claim'
import Me from 'modules/me'
import PaymentV2 from 'modules/paymentv2'
import Downloads from 'modules/downloads'
import qs from 'qs'
import Utils from 'modules/utils'

import logger from 'lib/logger'

const takes = ([
    takeEvery('trypico/claim/EXCHANGE_CLAIM_SUCCESS', function* takeEveryClaimSuccess({ payload } = {}) {
        if (!payload.token || !payload.refresh_token) {
            return
        }

        const publisherId = yield select(Client.selectors.id)

        let url = new URL(window?.location?.href)
        let params = new URLSearchParams(url.search)

        // // Delete the claim parameter.
        if (params.has("claim")) {
            params.delete("claim")
        }

        const queryParams = qs.parse(params.toString())

        yield putResolve({
            type: STORE_TOKENS,
            access_token: payload.token,
            refresh_token: payload.refresh_token,
        })

        yield put(
            yield call(Loader.creators.getLoader, {
                scope: 'user',
                publisherId,
            }),
        )

        yield call(Router.replace, `/${url.pathname}?${qs.stringify(queryParams)}`)

        // window.location.href = `${url?.origin}/${url.pathname}?${qs.stringify(queryParams)}`
    }),
    takeEvery('trypico/claim/EXCHANGE_CLAIM_SUCCESS', function* takeEveryClaimSuccess({ payload } = {}) {
        let xVerifyEmail = null
        let xVerifyPhone = null

        yield take(STORE_TOKENS_SUCCESS)

        yield take("trypico/me/GET_CURRENT_USER_SUCCESS")

        let url = new URL(window?.location?.href)
        let params = new URLSearchParams(url.search)

        // // Delete the claim parameter.
        if (params.has("x_verify_email")) {
            xVerifyEmail = params.get("x_verify_email")
            params.delete("x_verify_email")
        }

        if (params.has('x_verify_phone')) {
            xVerifyPhone = params.get('x_verify_phone')
            params.delete("x_verify_phone")
        }

        if (params.has("claim")) {
            params.delete('claim')
        }

        if (xVerifyEmail || xVerifyPhone) {
            const queryParams = qs.parse(params.toString())

            yield put({
                type: Utils.types.SET_VERIFICATION_INFO,
                payload: { xVerifyEmail, xVerifyPhone },
            })

            yield call(Router.replace, `/${url.pathname}?${qs.stringify(queryParams)}`)
        }
        // window.location.href = `${url?.origin}/${url.pathname}?${qs.stringify(queryParams)}`
    }),
    takeEvery('LOADER_INVALIDATION_EVENT', function* takeEveryLoaderInvalidation() {
        const publisherId = yield select(Client.selectors.id)
        const isFetching = yield select(Loader.selectors.isFetching)
        if (!isFetching) {
            yield put(
                yield call(Loader.creators.getLoader, {
                    scope: 'user',
                    publisherId,
                }),
            )
        }
    }),
    takeEvery('trypico/loader/GET_LOADER_SUCCESS', function* takeEveryLoaderSuccess({ payload } = {}) {
        if (payload?.scope !== 'user') {
            return
        }

        const publisherId = yield select(Client.selectors.id)
        const currentPurchaseActive = yield select(Loader.selectors.currentPurchaseActive)

        yield put(
            yield call(Me.creators.getCurrentUser, {
                payload: {
                    publisherId,
                },
            }),
        )

        if (currentPurchaseActive) {
            yield put(
                yield call(Downloads.creators.getDownloadableFiles, {
                    payload: {
                        publisherId,
                    },
                }),
            )
        }

        yield put(
            yield call(PaymentV2.creators.getPayments, {
                payload: {
                    publisherId,
                },
            }),
        )
    }),
    takeEvery(STORE_TOKENS_SUCCESS, function* takeEveryStoreTokensSuccess() {
        try {
            const publisherId = yield select(Client.selectors.id)
            yield put(yield call(Claim.creators.generateClaim, {
                publisherId,
            }))

            const generateClaim = yield take([
                Claim.types.GENERATE_CLAIM_SUCCESS,
                Claim.types.GENERATE_CLAIM_ERROR,
            ])

            if (generateClaim.type === Claim.types.GENERATE_CLAIM_ERROR) {
                throw new Error('update default payment method failed')
            }

            const _getOutboundClaim = yield select((state) => state?.claim?.outboundClaim)

            if (!_getOutboundClaim) {
                throw new Error('no outbound claim in store')
            }
        } catch (error) {
            logger.error('takeEveryStoreTokensSuccess', {}, error)
        }

    }),
])

export default takes
