import React from 'react'

export default ({ title = 'Add Payment', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M14.748 6H19a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-1.582c.63.256 1.301.433 2 .52V17a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-4c0-.69-.088-1.36-.252-2zm0 4H20v3h-6.755a7.973 7.973 0 0 0 1.503-3zM8 7V5a1 1 0 1 0-2 0v2H4a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 0 0 0-2H8zm8 8h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm-9-1A6 6 0 1 1 7 2a6 6 0 0 1 0 12z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Add Payment"
        width="24px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M14.748 6H19a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-1.582c.63.256 1.301.433 2 .52V17a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-4c0-.69-.088-1.36-.252-2zm0 4H20v3h-6.755a7.973 7.973 0 0 0 1.503-3zM8 7V5a1 1 0 1 0-2 0v2H4a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 0 0 0-2H8zm8 8h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm-9-1A6 6 0 1 1 7 2a6 6 0 0 1 0 12z"
            fill={color}
        />
    </svg>
)
