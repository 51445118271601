import React from 'react'

export default ({ title = 'Show details', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="12px"
        icon="M.787 3.312a.932.932 0 0 1 1.39 0l3.824 4.122 3.824-4.122a.931.931 0 0 1 1.39 0c.384.414.377 1.093-.006 1.507L6.702 9.688a.943.943 0 0 1-1.402 0L.793 4.819a1.125 1.125 0 0 1-.006-1.507z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Show details"
        width="12px"
        viewBox="0 0 12 12"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M.787 3.312a.932.932 0 0 1 1.39 0l3.824 4.122 3.824-4.122a.931.931 0 0 1 1.39 0c.384.414.377 1.093-.006 1.507L6.702 9.688a.943.943 0 0 1-1.402 0L.793 4.819a1.125 1.125 0 0 1-.006-1.507z"
            fill={color}
        />
    </svg>
)
