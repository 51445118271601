import {
    call, put, putResolve,
} from 'redux-saga/effects'

import {
    STORE_TOKENS, REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    OAUTH_USER_LINK,
} from 'piconetworks/pkg-endpoints'

function* requestLoginLink({
    articleId,
    customProperties,
    email,
    emailOtpEnabled,
    newsletters,
    origin,
    popupId,
    publisherId,
    redirectUrl,
    referer,
    types,
    utm,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER_LINK(),
                params: {
                    ...(!!utm && { utm }),
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                    ...(!!referer && { 'prev-page': referer }),
                    ...(!!origin && { 'current-url': origin }),
                },
                timeout: 30000,
                data: {
                    return_token: true,
                    article_id: articleId,
                    form_id: popupId,
                    email,
                    newsletter_ids: newsletters,
                    custom_properties: customProperties,
                    redirect_url: redirectUrl,
                    is_otp_enabled: emailOtpEnabled,
                },
            },
        })

        if (data.isNewRegistration) {
            yield putResolve({
                type: STORE_TOKENS,
                data,
            })
            yield put({ type: types.NEW_USER_REGISTRATION })

            yield put(
                yield call(loaderInvalidationEvent, {
                    payload: {
                        scope: [
                            'user',
                        ],
                    },
                })
            )
        }

        yield put({
            type: types.REQUEST_LOGIN_LINK_SUCCESS,
            payload: {
                ...data,
            },
        })

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.REQUEST_LOGIN_LINK_ERROR,
                error: error.response.data,
            })
        } else {
            yield put({
                type: types.REQUEST_LOGIN_LINK_ERROR,
                error: error.message,
            })
        }
    }
}

export default requestLoginLink
