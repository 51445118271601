import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import { Flexbox } from 'piconetworks/pkg-box'
import styles from './Link.module.scss'

const Link = ({
    children,
    className,
    disabled,
    href,
    onClick,
    openInNewWindow = false,
    flexboxContainer,
    style,
    ...props
}) => {

    if (onClick) {
        props.onClick = onClick
    } else if (href) {
        props.href = href
        if (openInNewWindow) {
            props.rel='noopener noreferrer'
            props.target='_blank'
        }
    }

    return (
        <a 
            className={cx(
                styles.link, 
                className,
                {
                    [styles.disabled]: disabled,
                },
                'block'
            )} 
            {...props} 
            style={{
                display: 'block',
                padding: '1.25rem',
                ...style
            }}
        >
            <Flexbox.Container {...flexboxContainer}>
                {children}
            </Flexbox.Container>
        </a>
    )
}

Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    flexboxContainer: PropTypes.object,
    href: PropTypes.string,
    onClick: PropTypes.func,
    openInNewWindow: PropTypes.bool,
    style: PropTypes.object,
}

Link.defaultProps = {
    disabled: false,
    flexboxContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    }
}

Link.displayName = 'Link'

export default Link
