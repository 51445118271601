import Joi from 'joi'

const DashboardCardErrored = Joi.object()
    .keys({
        customer_id: Joi.string().allow(null).description('The id of the customer'),
        card_error: Joi.string().allow(null).description('The error of the card'),
    })
    .description('dashboard:card:errored event attributes')
    .meta({ name: 'DashboardCardErrored', filename: 'dashboard:card:errored.model' })

export default DashboardCardErrored
