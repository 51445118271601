import PropertyType from "../Modifiers/PropertyType"

// this isn't being used anywhere for now because we don't have any other shadow types other than color
const ThemeBlockShadowType = new PropertyType({
    name: 'BlockShadowType',
    propertyFromApi: 'block-shadow-type',
    defaultValue: 'color'
},
'theme'
)

const FormBlockShadowType = new PropertyType({
    name: 'BlockShadowType',
    propertyFromApi: 'block-shadow-type',
    defaultValue: 'color'
},
'form'
)

export {
    ThemeBlockShadowType,
    FormBlockShadowType,
}
