import Joi from 'joi'

const linkTypes = [
    'apple_music',
    'apple_podcasts',
    'cash_app',
    'discord',
    'email',
    'etsy',
    'facebook',
    'github',
    'google',
    'instagram',
    'linkedin',
    'pinterest',
    'snapchat',
    'spotify',
    'threads',
    'tiktok',
    'twitch',
    'twitter',
    'venmo',
    'website',
    'youtube',
    'standard'
]

const LandingPageLinkClick = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).required().allow(null).description('The id of the landing page in Hype Forms table'),
        theme_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the theme applied'),
        link_id: Joi.string().guid({ version: ['uuidv4'] }).required().allow(null).description('The id of the link in Hype SocialLinks or Links table'),
        link_url: Joi.string().uri({ allowRelative: false }).description('URL of link'),
        link_social_type: Joi.alternatives().conditional('link_type', { is: 'social', then: Joi.string().valid(...linkTypes).description('Specific social platform for link, or standard') }),
        link_type: Joi.string().valid('social', 'link_block').required().description('Type of link, either social or for a link block'),
        link_title: Joi.string().optional().description('Title for link if link_block'),
        link_description: Joi.string().allow(null).optional().description('Description for link if link_block'),
    })
    .description('landing_page:link_click event attributes ')
    .meta({ name: 'LandingPageLinkClick', filename: 'landing_page:link_click.model' })

export default LandingPageLinkClick
