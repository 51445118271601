import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import { creators } from '../actions'

let markdownFetchCache = {}

function* fetchJson({
    payload,
}) {
    const url = payload?.url

    try {
        if (!url) {
            throw new Error('markdown url must be provided')
        }

        if (markdownFetchCache[url]) {
            return
        }
    
        markdownFetchCache[url] = url
    
        const response = yield call(axios, {
            method: 'get',
            timeout: 5000,
            url,
        })

        if (!response || !response.data) {
            throw new Error('response object not returned from axios')
        }

        const json = response.data

        yield put(
            yield call(
                creators.fetchJsonSuccess,
                {
                    payload: {
                        url,
                        json
                    },
                },
            ),
        )


    } catch (error) {
        delete markdownFetchCache[url]

        yield put(
            yield call(
                creators.fetchJsonError,
                {
                    payload: {
                        url: typeof url === 'undefined' ? null : url,
                    },
                    error: error.message,
                },
            ),
        )
    }
}

export default fetchJson
