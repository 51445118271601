import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'

class FormSwitch extends React.Component {
    render() {
        const { input } = this.props
        return (
            <Switch
                {...this.props}
                onChange={input.onChange}
                checked={input.value || false}
            />
        )
    }
}

FormSwitch.propTypes = {
    input: PropTypes.object.isRequired,

    /**
     * When disabled, the switch will no longer be interactive and its colors will be greyed out.
     */
    disabled: PropTypes.bool,
    /**
     * The switch will take on this color when it is not checked. Only accepts hex-colors.
     */
    offColor: PropTypes.string,
    /**
     * The switch will take on this color when it is checked. Only accepts hex-colors.
     */
    onColor: PropTypes.string,
    /**
     * The handle of the switch will take on this color when it is not checked. Only accepts hex-colors.
     */
    offHandleColor: PropTypes.string,
    /**
     * The handle of the switch will take on this color when it is checked. Only accepts hex-colors.
     */
    onHandleColor: PropTypes.string,
    /**
     * The diameter of the handle, measured in pixels. By default it will be 2 pixels smaller than the height of the switch.
     */
    handleDiameter: PropTypes.number,
    /**
     * An icon that will be shown on the switch when it is not checked. Pass in false if you don't want any icon.
     */
    uncheckedIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    /**
     * An icon that will be shown on the switch when it is checked. Pass in false if you don't want any icon.
     */
    checkedIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    /**
     * The default box-shadow of the handle. You can read up on the box-shadow syntax on MDN.
     */
    boxShadow: PropTypes.string,
    /**
     * The box-shadow of the handle when it is active or focused. Do not set this to null, since it is important for accessibility.
     */
    activeBoxShadow: PropTypes.string,
    /**
     * The height of the background of the switch, measured in pixels.
     */
    height: PropTypes.number,
    /**
     * The width of the background of the switch, measured in pixels.
     */
    width: PropTypes.number,
    /**
     * Set as an attribute to the embedded checkbox. This is useful for the associated label, which can point to the id in its htmlFor attribute.
     */
    id: PropTypes.string,
    /**
     * Set as the className of the outer shell of the switch. Useful for positioning the switch.
     */
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

FormSwitch.defaultProps = {
    checked: false,
    disabled: false,
    offColor: '#888',
    onColor: '#080',
    offHandleColor: '#fff',
    onHandleColor: '#fff',
    boxShadow: null,
    activeBoxShadow: '0 0 2px 3px #3bf',
    height: 28,
    width: 56
}

export default FormSwitch
