import { put, putResolve, call } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PURCHASE_GIFT } from 'piconetworks/pkg-endpoints'

function* convertGift({
    types,
    giftId,
    publisherId,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_GIFT()}/${giftId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...(publisherId && { publisherid: publisherId })
                },
                timeout: 30000,
                data: {
                    status: 'converted',
                },
            },
        })

        if (data && !data.success){
            throw 'Error with converting gift'
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.CONVERT_GIFT_SUCCESS,
            payload: {},
        })
    } catch (error) {
        yield put({
            type: types.CONVERT_GIFT_ERROR,
            error: error.message,
        })
    }
}

export default convertGift
