import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.VERIFY_DISCOUNT_CODE, sagas.verifyDiscountCode),
    takeEvery(types.PURCHASE_PLAN, sagas.purchasePlan),
    takeEvery(types.PURCHASE_PLAN_GROUP, sagas.purchasePlanGroup),
    takeEvery(types.PURCHASE_SWITCH, sagas.purchaseSwitch),
    takeEvery(types.CANCEL_PLAN, sagas.cancelPlan),
    takeEvery(types.KEEP_PLAN, sagas.keepPlan),
    takeEvery(types.CHECK_SWITCH_PLAN_NOW, sagas.checkCanSwitchPlanNow),
    takeEvery(types.GET_PLANS, sagas.getPlans),
])

export default takes
