export const customStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: state.isDisabled ? '#AAA' : '#000',
            textAlign: 'left',
            fontSize: '17px',
            fontWeight: 400,
            padding: '11px 0 11px 24px',
            cursor: 'pointer',
            backgroundColor: '#fff',
            ...(state.isSelected && {
                backgroundColor: '#F8F8F8',
                fontSize: '17px',
                fontWeight: 500,
            }),
            '&:hover': {
                ...(!state.isDisabled && {
                    backgroundColor: state.selectProps.themeColor,
                    color: state.selectProps.contrastColor,
                }),
            },
            ':active': {
                backgroundColor: '#fff',
            },
        }
    },
    control: (provided, state) => {
        return {
            ...provided,
            borderRadius: '8px',
            minHeight: '44px',
            backgroundColor: state.isDisabled ? '#f8f8f8' : '#fff',
            border: state.selectProps.error && state.selectProps.touched
                ? '1px solid #FF5E5B'
                : state.isFocused
                    ? `1px solid ${state.selectProps.themeColor}`
                    : '1px solid #ddd',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
            boxSizing: 'border-box',
            '&:hover': {
                ...(!state.selectProps.error && state.selectProps.touched && {
                    border: state.selectProps.error && state.selectProps.touched && '1px solid #FF5E5B',
                }),
            },
            padding: '0 8px 0 0',
            lineHeight: 1
        }
    },
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 24px',
        lineHeight: '2rem',
        minHeight: '2rem',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => {
        return {
            transform: !state.isFocused ? 'rotate(0)' : 'rotate(180deg)',
            color: state.selectProps.error && state.selectProps.touched && ' #FF5E5B',
            '.icon-dropdown-indicator': {
                '&:before': {
                    color: state.selectProps.error && state.selectProps.touched ? ' #FF5E5B' : '#777777',
                    fontSize: '17px',
                    padding: '0 16px',
                },
            },
        }
    },
    singleValue: (provided) => ({
        ...provided,
        fontSize: '16px',
        color: '#000',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 24px',
        left: 0,
        width: '100%'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '16px',
        fontWeight: 500,
        color: state.selectProps.error && state.selectProps.touched && '#FF5E5B',
        marginLeft: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 24px',
        left: 0,
        width: '100%'
    }),
    menu: (provided, state) => ({
        ...provided,
        minWidth: 200,
        zIndex: 7
    })
}
