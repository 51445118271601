import React from 'react'

export default ({ title = 'Hide', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M10.545 15.851c.473.1.958.149 1.455.149 2.577 0 4.827-1.333 6.75-4-.657-.912-1.353-1.667-2.086-2.267l-.686.685a4.001 4.001 0 0 1-3.56 3.56l-1.873 1.873zm7.412-7.411c1.055.924 2.07 2.11 3.043 3.56-2.686 4-5.686 6-9 6a8.03 8.03 0 0 1-3.017-.587l-2.149 2.15a1 1 0 0 1-1.414-1.415L18.148 5.42a1 1 0 1 1 1.414 1.414L17.957 8.44zM6.869 13.859l-1.282 1.282C4.694 14.286 3.832 13.24 3 12c2.686-4 5.686-6 9-6 .809 0 1.599.12 2.37.357L11.558 9.17a1 1 0 0 0-1.388 1.388L8.608 12.12A3.981 3.981 0 0 1 8 10c0-.33.04-.606.115-.836-1.024.662-1.98 1.608-2.865 2.836a12.54 12.54 0 0 0 1.619 1.859z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Hide Password"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M10.545 15.851c.473.1.958.149 1.455.149 2.577 0 4.827-1.333 6.75-4-.657-.912-1.353-1.667-2.086-2.267l-.686.685a4.001 4.001 0 0 1-3.56 3.56l-1.873 1.873zm7.412-7.411c1.055.924 2.07 2.11 3.043 3.56-2.686 4-5.686 6-9 6a8.03 8.03 0 0 1-3.017-.587l-2.149 2.15a1 1 0 0 1-1.414-1.415L18.148 5.42a1 1 0 1 1 1.414 1.414L17.957 8.44zM6.869 13.859l-1.282 1.282C4.694 14.286 3.832 13.24 3 12c2.686-4 5.686-6 9-6 .809 0 1.599.12 2.37.357L11.558 9.17a1 1 0 0 0-1.388 1.388L8.608 12.12A3.981 3.981 0 0 1 8 10c0-.33.04-.606.115-.836-1.024.662-1.98 1.608-2.865 2.836a12.54 12.54 0 0 0 1.619 1.859z"
            fill={color}
        />
    </svg>
)
