import PropertyType from "../Modifiers/PropertyType"

enum BlockBorderWidthOptions {
    none = 'border-width: 0;',
    thin = 'border-width: 0.05rem;',
    regular = 'border-width: 0.15rem;',
    medium = 'border-width: 0.25rem;',
    heavy = 'border-width: 0.5rem;'
}

// we need these because we can't use `heavy` as a css variable value
enum BlockBorderWidthValues {
    none = '0',
    thin = '0.05rem',
    regular = '0.15rem',
    medium = '0.25rem',
    heavy = '0.5rem'
}

const ThemeBlockBorderWidth = new PropertyType({
    name: 'BlockBorderWidth',
    propertyFromApi: 'block-border-width',
    convertValueToCssPropertiesFunction: (value: string) => BlockBorderWidthOptions[value],
    options: BlockBorderWidthOptions,
    defaultValue: 'none',
    selector: '.block',
    cssVariable: '--block-border-width',
    cssVariableFunction: (value: string) => BlockBorderWidthValues[value],
},
'theme'
)

const FormBlockBorderWidth = new PropertyType({
    name: 'BlockBorderWidth',
    propertyFromApi: 'block-border-width',
    convertValueToCssPropertiesFunction: (value: string) => BlockBorderWidthOptions[value],
    options: BlockBorderWidthOptions,
    defaultValue: 'none',
    selector: '.block',
    cssVariable: '--block-border-width',
    cssVariableFunction: (value: string) => BlockBorderWidthValues[value],
},
'form'
)

export {
    ThemeBlockBorderWidth,
    FormBlockBorderWidth,
}
