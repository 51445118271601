import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import '../Heading.module.scss'

const H3 = ({className, children, ...props}) => {
    return (
        <h3 className={cx(className)} {...props}>{children}</h3>
    )
}

H3.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default H3
