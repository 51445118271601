import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    OPEN_MENU,
    CLOSE_MENU,
    TOGGLE_MENU,
    GO_TO_MENU_ROUTE,
} from '../../actionTypes'
import DefaltMenuToggle from '../DefaltMenuToggle'
import style from './Menu.module.scss'

class Menu extends React.Component {
    constructor() {
        super()
        this.changeMenuRoute = this.changeMenuRoute.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.openMenu = this.openMenu.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.shouldOpen = this.shouldOpen.bind(this)
        this.state = {
            menuId: null,
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
        const { _menu: { activeMenuId } } = this.props
        const menuId = activeMenuId || uuidv4()
        this.setState({
            menuId,
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    changeMenuRoute(route = '') {
        const { dispatch, routes } = this.props
        if (routes[route]) {
            dispatch({
                type: GO_TO_MENU_ROUTE,
                payload: {
                    menuRoute: route,
                },
            })
        } else {
            console.error(`menu route for: "${route}" not declared`)
        }
    }

    closeMenu() {
        const { dispatch } = this.props
        dispatch({
            type: CLOSE_MENU,
        })
    }

    handleClickOutside(event) {
        const shouldOpen = this.shouldOpen()

        if (shouldOpen && !this.wrapperRef?.contains(event.target)) {
            this.closeMenu()
        }
    }

    openMenu() {
        const { menuId } = this.state
        const { dispatch } = this.props
        dispatch({
            type: OPEN_MENU,
            payload: {
                menuId,
            },
        })
    }

    renderRoute() {
        const {
            _menu,
            routeProps,
            routes,
        } = this.props
        const {
            backRoute = null,
            menuRoute = '/',
            text = '',
            link = '',
            data = {},
        } = _menu
        return routes[menuRoute]({
            ...routeProps,
            changeMenuRoute: this.changeMenuRoute,
            text,
            backRoute,
            data,
            link
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    toggleMenu() {
        const { menuId } = this.state
        const { dispatch } = this.props
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                menuId,
            },
        })
    }

    shouldOpen() {
        const { menuId } = this.state
        const { _menu } = this.props
        const { isOpen, activeMenuId } = _menu
        const shouldOpen = (
            (isOpen && activeMenuId === menuId)
      || isOpen && activeMenuId === null
        )

        return shouldOpen
    }

    render() {
        const { MenuToggle = DefaltMenuToggle, showMobileOnly, menuContainerClass } = this.props
        const shouldOpen = this.shouldOpen()

        return (
            <div
                className={cx(style.menu, { [style.mobile]: showMobileOnly })}
                ref={this.setWrapperRef}
            >
                <div onClick={this.toggleMenu}>
                    <MenuToggle />
                </div>
                <div id='menu' className={cx(style.menuContainer, { [style.isOpen]: shouldOpen }, menuContainerClass)}>
                    {this.renderRoute()}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    _menu: state._menu,
})

Menu.propTypes = {
    _menu: PropTypes.any,
    menuContainerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default connect(mapStateToProps)(Menu)
