import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const FlexboxChild = ({
    alignSelf,
    children,
    className,
    flex,
    flexFill,
    flexGrow,
    flexShrink,
    ...props
}) => (
    <div
        className={cx(
            flexFill && 'flex-fill',
            flexGrow && `flex-grow-${flexGrow}`,
            flexShrink && `flex-shrink-${flexShrink}`,
            alignSelf && `align-self-${alignSelf}`,
            className,
        )}
        style={{
            flex: flex
        }}
        {...props}
    >
        {children}
    </div>
)

FlexboxChild.propTypes = {
    alignSelf:  PropTypes.oneOf([
        'start',
        'end',
        'center',
        'between',
        'around',
        'initial'
    ]),
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * This is the shorthand for flex-grow, flex-shrink and flex-basis combined.
     */
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Set this on a series of sibling elements to force them into widths equal
     * to their content (or equal widths if their content does not surpass their border-boxes)
     * while taking up all available horizontal space.
     * */
    flexFill: PropTypes.bool,
    /**
     * This defines the ability for a flex item to grow if necessary.
     * It accepts a unitless value that serves as a proportion.
     * It dictates what amount of the available space inside the flex container the item should take up.
     */
    flexGrow: PropTypes.number,
    /**
     * This defines the ability for a flex item to shrink if necessary.
     */
    flexShrink: PropTypes.number
}

// maybe i can remove this?
// FlexboxChild.defaultProps = {
//     flex: '0 1 auto'
// }

export default FlexboxChild
