import { put, putResolve } from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, OAUTH_USER_LOGIN, OAUTH_PUBLISHER_LINK,
} from 'piconetworks/pkg-endpoints'

function* claimMagicLink({ types, creators, payload }) {
    let url
    if (payload.scope === 'publisher') {
        url = OAUTH_PUBLISHER_LINK()
    } else {
        url = OAUTH_USER_LOGIN()
    }

    try {
        if (!payload.claim) {
            throw Error('token claim is required')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                params: {
                    token_claim: payload.claim,
                },
                timeout: 30000,
            },
        })

        yield putResolve(creators.tokenLogin({
            accessToken: data.token,
            refreshToken: data.refresh_token,
        }))

        yield put({
            type: types.CLAIM_MAGIC_LINK_SUCCESS,
            payload: {
                accessToken: data.token,
                refreshToken: data.refresh_token,
            },
        })
    } catch (error) {
        yield put({
            type: types.CLAIM_MAGIC_LINK_ERROR,
            error: error.message,
        })
    }
}

export default claimMagicLink
