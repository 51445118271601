import { produce } from 'immer'

export const initialState = {
    data: {},
    error: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_PRODUCT_BY_OPTION:
            draftState.error = initialState.error
            break

        case duck.types.GET_PRODUCT_BY_OPTION_SUCCESS:
            draftState.data = {
                ...draftState.data,
                [payload.id]: payload
            }
            break

        case duck.types.GET_PRODUCT_BY_OPTION_ERROR:
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
