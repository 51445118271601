import {
    put, putResolve, call
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, ME_PAYMENT } from 'piconetworks/pkg-endpoints'

function* setDefaultPaymentMethod({
    types,
    sourceId,
    publisherId,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!sourceId) {
            throw Error('sourceId must be provided')
        }

        if (!publisherId) {
            throw Error('publisherId must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: ME_PAYMENT(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    source_id: sourceId,
                    retry_charge: false,
                },
            },
        })

        if (!data) {
            throw Error('no data returned from server')
        }

        if (data.error) {
            throw Error(data.error)
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
            payload: {
            },
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.SET_DEFAULT_PAYMENT_METHOD_ERROR,
            error: error.message,
        })
    }
}

export default setDefaultPaymentMethod
