import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_PURCHASE_HISTORY, sagas.getPurchaseHistory),
    takeEvery(types.GET_SWITCH_PRODUCTS, sagas.getSwitchProducts),
    takeEvery(types.CREATE_SWITCH_PRODUCT, sagas.createSwitchProduct),
    takeEvery(types.GET_SWITCH_PRORATION, sagas.getSwitchProration),
    takeEvery(types.GET_GIFT, sagas.getGift),
    takeEvery(types.CREATE_GIFT, sagas.createGift),
    takeEvery(types.UPDATE_GIFT, sagas.updateGift),
    takeEvery(types.DELETE_GIFT, sagas.deleteGift),
    takeEvery(types.PURCHASE_PRODUCT, sagas.purchaseProduct),
    takeEvery(types.REACTIVATE_PRODUCT, sagas.reactivateProduct),
    takeEvery(types.CANCEL_PRODUCT, sagas.cancelProduct),
])

export default takes
