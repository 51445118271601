/* eslint-disable import/no-cycle */
/* eslint-disable global-require */
/* eslint-disable import/no-mutable-exports */

// import Router from 'lib/RouterAdapterDecorator'
import Router from 'next/router'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import qs from 'qs'

import { createNetworkMiddleware } from 'piconetworks/pkg-network-middleware'
import Client from 'modules/client'
import { initialAppState } from './reducers'
import rootSaga from './rootSaga'
import persistedReducer from './persistedReducer'
import { OAUTH_USER_TOKEN, PICO_API_URL } from '../lib/endpoints'

const bindMiddleware = (middleware) => {
    const { composeWithDevTools } = require('redux-devtools-extension')

    return composeWithDevTools(applyMiddleware(...middleware))
}

export let exportedStore

export const storeFactory = ({ publisherId, client } = {}) => (initialState = initialAppState) => {
    const sagaMiddleware = createSagaMiddleware()

    const networkMiddleware = createNetworkMiddleware({
        preRequestHook: async (config) => ({
            ...config,
            paramsSerializer: (params) => qs.stringify(params),
        }),
        tokenKey: Router.router && Router.router.query.company_slug.replace(/^@/, ''),
        accessTokenAdapters: {
            response: ({ data }) => ({
                access_token: data.token,
                refresh_token: data.refresh_token,
            }),
        },
        refreshTokenAdapters: {
            request: ({ refresh_token: refreshToken, scope }) => {
                console.log('scope', scope)
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    refreshtoken: refreshToken,
                }

                if (scope === 'user' && !!publisherId) {
                    headers.publisherid = publisherId
                }

                if (scope === 'user' && !headers.publisherid) {
                    const currentState = exportedStore.getState()
                    const clientId = Client.selectors.id(currentState)

                    headers.publisherid = clientId
                }

                return {
                    method: 'get',
                    baseURL: PICO_API_URL(),
                    url: `${OAUTH_USER_TOKEN()}/${refreshToken}`,
                    headers,
                    timeout: 30000,
                }
            },
            response: ({ data }) => ({
                access_token: data.token,
                refresh_token: data.refresh_token,
            }),
        },
    })

    const store = createStore(
        persistedReducer,
        initialState,
        bindMiddleware([
            sagaMiddleware,
            networkMiddleware,
        ]),
    )

    exportedStore = store

    store.sagaTask = sagaMiddleware.run(function* sagaTask() {
        yield rootSaga()
    })

    if (client) {
        const setClient = Client.creators.setClient({ payload: { client } })
        store.dispatch(setClient)
    }

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(require('./reducers').default)
        })

        module.hot.accept('./rootSaga', () => {
            store.replaceReducer(require('./rootSaga').default)
        })

        return store
    }

    return store
}
