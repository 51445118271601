import {  PropertySerializer, IPropertySerializerOptions } from "./PropertySerializer"

class PropertyType extends PropertySerializer {
    /**
     * Type is either form or theme. This is because we want to have independence between a
     * PropertySerializer that belongs to a Theme and a PropertySerializer that belongs to a Form
     * @type {string}
     * @readonly
     */
    readonly type: string

    constructor(opts: IPropertySerializerOptions, type: string) {
        super(opts)
        this.type = type
    }
}

export default PropertyType