import {
    formSerializer,
    themeSerializer
} from './instantiate'

import {
    processProperties,
    processCompoundedProperties,
    makeCss,
    getPropertiesByType,
} from './process'

import tagGenerator from './tagGenerator'

import { showConsoleLogs } from './utils'

// import defaultCssVariables from './default.css'
// import utilityCssClasses from './utilityClasses.css'

export {
    themeSerializer,
    formSerializer,
    processProperties,
    makeCss,
    processCompoundedProperties,
    getPropertiesByType,
    showConsoleLogs,
    tagGenerator,
    // defaultCssVariables,
    // utilityCssClasses,
}
