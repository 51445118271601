import {
    call, put, putResolve,
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, PURCHASE_PLAN_GROUP,
} from 'piconetworks/pkg-endpoints'

function* purchasePlanGroup({
    types,
    groupId = null,
    publisherId = null,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!groupId) {
            throw Error('groupId not provided')
        }

        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: PURCHASE_PLAN_GROUP(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    group_id: groupId,
                    publisherId,
                },
            },
        })

        if (!data) {
            throw Error('no data returned')
        }

        if (!data.group || data.group.billing_status !== 'PAID') {
            throw Error('create group plan did not succeed')
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.PURCHASE_PLAN_GROUP_SUCCESS,
            payload: {
                success: groupId,
            },
        })
    } catch (error) {
        console.log(error)

        yield put({
            type: types.PURCHASE_PLAN_GROUP_ERROR,
            error: error.message,
            payload: {
                error: groupId,
            },
        })
    }
}

export default purchasePlanGroup
