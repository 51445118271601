import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './Radio.module.scss'
import FormField, { FormFieldInputPropTypes, FormFieldPropTypes } from '../FormField'
import { onlyWarnInDevelopmentMode, warnOnFieldProps } from '../utils/propWarnings'

const Radio = ({
    text,
    label,
    className,
    disabled = false,
    input,
    meta,
    required,
    changed = null,
    color,
    fieldProps,
    ...props
}) => {
    onlyWarnInDevelopmentMode(() => warnOnFieldProps(fieldProps))

    return (
        <FormField label={label} input={input} required={required} meta={meta} {...props} {...fieldProps}>
            <label className={cx(style.container)}>
                <p className={style.label}> {text}</p>
                <input
                    type="radio"
                    className={className}
                    {...input}
                    disabled={disabled}
                    onChange={value => {
                        if (changed) {
                            changed(value)
                        }
                        input.onChange(value)
                    }}
                />

                <span className={style.checkmark} style={{ borderColor: input.checked && color }}>
                    <span className={style.inner} style={{ backgroundColor: color }} />
                </span>
            </label>
        </FormField>
    )
}

Radio.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    fieldProps: PropTypes.shape(FormFieldPropTypes),
    input: PropTypes.shape(FormFieldInputPropTypes),
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    required: PropTypes.bool,
    text: PropTypes.string,
}

export default Radio
