import {
    types,
    creators,
} from './actions'

import reducer, {
    initialState,
} from './reducer'

import sagas from './sagas'
import takes from './takes'
import selectors from './selectors'

const store = 'iframely'

const Iframely = ({
    createDuck
}) =>
    createDuck({
        store,
        initialState,
        path: '/iframely'
    }).extend({
        creators,
        types,
        reducer,
        sagas,
        takes,
        selectors,
    })
    
export {
    store,
    creators,
    types,
    reducer,
    initialState,
    sagas,
    takes,
    Iframely,
}
