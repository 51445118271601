import Joi from 'joi'

const DashboardCardAdded = Joi.object()
    .keys({
        card_id: Joi.string().allow(null).description('The id of the card'),
        customer_id: Joi.string().allow(null).description('The id of the customer'),
    })
    .description('dashboard:card:added event attributes ')
    .meta({ name: 'DashboardCardAdded', filename: 'dashboard:card:added.model' })

export default DashboardCardAdded
