import produce from 'immer'

export const initialState = {
    data: null,
}

const reducer = (
    state,
    action,
    duck,
) => produce(state, (draftState) => {
    switch (action.type) {
        case duck.types.SET_UTM_PARAMETERS:
            draftState.data = action.data
            break
        default:
        // do nothing
    }
})

export default reducer
