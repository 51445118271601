import { produce } from 'immer'

export const initialState = {
    isFetching: false,
    guest: undefined,
    user: undefined,
}

const loaderCleaner = ({ scope, key, payload }) => {
    if (!payload) {
        return payload
    }

    // fix the loader's sometimes null group

    // fix the loader's sometimes null paymentMethods
    if (scope === 'user') {
        if (payload.paymentMethods) {
            const nextState = produce(payload, (draftState) => {
                const filteredPaymentMethods = payload.paymentMethods.filter((paymentMethod) => paymentMethod !== null)

                draftState.paymentMethods = filteredPaymentMethods
            })

            return nextState
        }

        if (key === 'paymentMethods') {
            const nextState = produce(payload, (draftState) => draftState.filter((paymentMethod) => paymentMethod !== null))

            return nextState
        }
    }

    return payload
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.LOADER_IS_FETCHING:
            draftState.isFetching = true
            break

        case duck.types.LOADER_IS_NOT_FETCHING:
            draftState.isFetching = false
            break

        case duck.types.GET_LOADER:
            draftState.isFetching = true
            break

        case duck.types.GET_LOADER_SUCCESS:
            const payloadToClean = payload.data
            const currentPopups = draftState?.[payload.scope]?.popups || []
            const newPopups = payloadToClean?.popups
            const additionalPopups = draftState?.[payload.scope]?.additionalPopups || []
            let popupsToClean

            if (newPopups) {
                // make sure that new loader requests dont overwrite popups in cache
                popupsToClean = newPopups.reduce((acc, popup) => {
                    const maybeNewPopup = currentPopups.find(({ id }) => id === popup.id)
                    if (!maybeNewPopup) {
                        acc.push(popup)
                    }
                    return acc
                }, [])
            }

            draftState[payload.scope] = loaderCleaner({
                type: duck.types.GET_LOADER_SUCCESS,
                scope: payload.scope,
                payload: payloadToClean ? {
                    ...payloadToClean,
                    ...(popupsToClean && { popups: popupsToClean }),
                    additionalPopups,
                } : undefined
            })
            draftState.isFetching = false
            draftState.isError = false
            break

        case duck.types.GET_LOADER_ERROR:
            draftState.isFetching = false
            draftState.isError = true
            draftState.error = error
            break

        case duck.types.REMOVE_LOADER_SUCCESS:
            draftState[payload.scope] = undefined
            break

        case duck.types.UPDATE_LOADER:
            if (draftState[payload.scope]) {
                draftState[payload.scope] = Object.keys(payload.loader).reduce((loader, key) => ({
                    ...loader,
                    [key]: loaderCleaner({
                        type: duck.types.UPDATE_LOADER,
                        scope: payload.scope,
                        key,
                        payload: payload.loader[key],
                    }),
                }), draftState[payload.scope])
            }
            break

        case duck.types.UPDATE_LOADER_PRICING_MONETIZATION_TIERS:
            if (payload.tiers) {
                if (draftState.guest) {
                    draftState.guest.pricing.monetization.cover_photo = payload.cover_photo
                    draftState.guest.pricing.monetization.is_pledge = payload.is_pledge
                    draftState.guest.pricing.monetization.plan_id = payload.plan_id
                    draftState.guest.pricing.monetization.type = payload.type
                    draftState.guest.pricing.monetization.tiers = payload.tiers
                }

                if (draftState.user) {
                    draftState.user.pricing.monetization.cover_photo = payload.cover_photo
                    draftState.user.pricing.monetization.is_pledge = payload.is_pledge
                    draftState.user.pricing.monetization.plan_id = payload.plan_id
                    draftState.user.pricing.monetization.type = payload.type
                    draftState.user.pricing.monetization.tiers = payload.tiers
                }
            }
            break

        case duck.types.UPDATE_LOADER_GROUP_BILLING_STATUS:
            if (payload.groupId && payload.billingStatus) {
                if (draftState.guest && draftState.guest.group.id === payload.groupId) {
                    draftState.guest.group.billing_status = payload.billingStatus
                }

                if (draftState.user && draftState.user.group.id === payload.groupId) {
                    draftState.user.group.billing_status = payload.billingStatus
                }
            }
            break

        case duck.types.UPDATE_LOADER_USER:
            if (payload.user) {
                draftState.user.user = Object.keys(payload.user).reduce((loaderUserUser, key) => ({
                    ...loaderUserUser,
                    [key]: loaderCleaner({
                        type: duck.types.UPDATE_LOADER_USER,
                        scope: 'user',
                        key,
                        payload: payload.user[key],
                    }),
                }), draftState.user.user)
            }
            break

        case duck.types.UPDATE_LOADER_USER_NEWSLETTERS:
            if (payload.newsletters) {
                if (draftState.user) {
                    draftState.user.newsletters = payload.newsletters
                }
            }

            if (draftState.user) {
                draftState.user.user.sync = !!payload.sync
            }
            break

        case duck.types.FETCH_LOADER_POPUP_SUCCESS:
            if (payload?.popup) {
                const scopeToUpdate = draftState.user ? 'user' : 'guest'

                const updatePopups = (key) => {
                    if (draftState[scopeToUpdate]?.[key]) {
                        const idxToUpdate = draftState[scopeToUpdate][key].findIndex(p =>
                            p.id === payload.popup?.id
                        )
                        if (idxToUpdate !== -1) {
                            draftState[scopeToUpdate][key][idxToUpdate] = payload.popup
                        } else {
                            draftState[scopeToUpdate][key].push(payload.popup)
                        }
                    }
                }

                updatePopups('popups')
                updatePopups('additionalPopups')
            }
            break

        default:
        // do nothing
    }
})

export default reducer
