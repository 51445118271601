import { produce } from 'immer'

export const initialState = {
    creatingGift: false,
    didCreateGift: null,
    redeemingGift: false,
    redeemGiftSuccess: null,
    redeemGiftError: null,
    redeemGiftErrorMessage: null,
    createGiftError: null,
    product: {
        loading: false,
        data: {},
        error: null,
    },
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.CREATE_GIFT:
            draftState.creatingGift = true
            draftState.didCreateGift = duck.initialState.didCreateGift
            draftState.createGiftError = duck.initialState.createGiftError
            break

        case duck.types.CREATE_GIFT_SUCCESS:
            draftState.creatingGift = false
            draftState.didCreateGift = true
            break

        case duck.types.CREATE_GIFT_ERROR:
            draftState.creatingGift = false
            draftState.didCreateGift = false
            draftState.createGiftError = error
            break

        case duck.types.REDEEM_GIFT:
            draftState.redeemingGift = true
            draftState.redeemGiftSuccess = duck.initialState.redeemGiftSuccess
            draftState.redeemGiftError = duck.initialState.redeemGiftError
            break

        case duck.types.REDEEM_GIFT_SUCCESS:
            draftState.redeemingGift = false
            draftState.redeemGiftSuccess = true
            draftState.redeemGiftError = false
            break

        case duck.types.REDEEM_GIFT_ERROR:
            draftState.redeemingGift = false
            draftState.redeemGiftSuccess = false
            draftState.redeemGiftError = true
            draftState.redeemGiftErrorMessage = payload.error
            break

        case duck.types.GET_GIFT_SUCCESS:
            draftState.giftId = payload.giftId
            break

        case duck.types.CONVERT_GIFT_SUCCESS:
            draftState.giftConversionSuccess = true
            break

        case duck.types.GET_GIFT_PRODUCT:
            draftState.product.data = duck.initialState.product.data
            draftState.product.error = duck.initialState.product.error
            draftState.product.loading = true
            break

        case duck.types.GET_GIFT_PRODUCT_SUCCESS:
            draftState.product.loading = false
            draftState.product.data = payload?.product
            break

        case duck.types.GET_GIFT_PRODUCT_ERROR:
            draftState.product.loading = false
            draftState.product.error = payload?.error
            break

        default:
        // do nothing
    }
})

export default reducer
