import PropertyType from '../Modifiers/PropertyType'

const ThemePageFontBody = new PropertyType({
    name: 'PageFontBody',
    propertyFromApi: 'page-font-body',
    convertValueToCssPropertiesFunction: (value: string) => `font-family: ${value};`,
    selector: 'p, span, label, div, input, li, ol',
    cssVariable: '--page-font-body'
},
'theme'
)

const FormPageFontBody = new PropertyType({
    name: 'PageFontBody',
    propertyFromApi: 'page-font-body',
    convertValueToCssPropertiesFunction: (value: string) => `font-family: ${value};`,
    selector: 'p, span, label, div, input, li, ol',
    cssVariable: '--page-font-body'
},
'form'
)

export {
    ThemePageFontBody,
    FormPageFontBody,
}
