import PropertyType from "../Modifiers/PropertyType"

enum BlockBorderStyleOptions {
    none = 'border-style: none;',
    solid = 'border-style: solid;',
    dotted = 'border-style: dotted;',
    dashed = 'border-style: dashed;',
    double = 'border-style: double;'
}

const ThemeBlockBorderStyle = new PropertyType({
    name: 'BlockBorderStyle',
    propertyFromApi: 'block-border-style',
    convertValueToCssPropertiesFunction: (value: string) => BlockBorderStyleOptions[value],
    defaultValue: 'none',
    options: BlockBorderStyleOptions,
    selector: '.block',
    cssVariable: '--block-border-style',
},
'theme'
)

const FormBlockBorderStyle = new PropertyType({
    name: 'BlockBorderStyle',
    propertyFromApi: 'block-border-style',
    convertValueToCssPropertiesFunction: (value: string) => BlockBorderStyleOptions[value],
    defaultValue: 'none',
    options: BlockBorderStyleOptions,
    selector: '.block',
    cssVariable: '--block-border-style',
},
'form'
)

export {
    ThemeBlockBorderStyle,
    FormBlockBorderStyle,
}
