/* eslint-disable no-template-curly-in-string */
const optionalRule = {
    menu: {
        title: 'Join the ${publication_name} community.',
        subtitle:
            'It’s free, and you’ll get special updates, exclusive content, and more.',
        button: 'Join or Log In',
    },
    prompt: {
        title: 'Join the ${publication_name} community.',
        subtitle:
            'It’s free, and you’ll get special updates, exclusive content, and more.',
        description: null,
    },
    confirmationWithoutName: 'You are now logged in to ${publication_name}.',
    confirmationWithName:
        'Hi ${first_name}, you are now logged in to ${publication_name}.',
}
/* eslint-enable no-template-curly-in-string */

export default optionalRule
