import { call, put } from 'redux-saga/effects'
import {
    ME_PAYMENT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* deletePayment({
    types,
    publisherId,
    payload,
} = {}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            headers: {
                publisherid: publisherId,
            },
            url: ME_PAYMENT(),
            method: 'delete',
            data: payload
        })

        if (data?.source_id) {
            yield put({
                type: types.DELETE_PAYMENT_SUCCESS,
                payload,
            })
        } else {
            throw new Error(`unable to delete payment, ${payload.source_id}`)
        }
    } catch (error) {
        yield put({
            type: types.DELETE_PAYMENT_ERROR,
            error: error?.message || 'DELETE_PAYMENT_ERROR',
        })
    }
}

export default deletePayment
