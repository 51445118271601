const types = [
    'ADD_PAYMENT_METHOD',
    'ADD_PAYMENT_METHOD_SUCCESS',
    'ADD_PAYMENT_METHOD_ERROR',
    'DELETE_PAYMENT_METHOD',
    'DELETE_PAYMENT_METHOD_SUCCESS',
    'DELETE_PAYMENT_METHOD_ERROR',
    'SELECT_PAYMENT_METHOD',
    'SET_DEFAULT_PAYMENT_METHOD',
    'SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    'SET_DEFAULT_PAYMENT_METHOD_ERROR',
]
  
const creators = (duck) => ({
    addPaymentMethod: (payload) => ({
        type: duck.types.ADD_PAYMENT_METHOD,
        ...payload,
    }),
    deletePaymentMethod: (payload) => ({
        type: duck.types.DELETE_PAYMENT_METHOD,
        ...payload,
    }),
    selectPaymentMethod: (payload) => ({
        type: duck.types.SELECT_PAYMENT_METHOD,
        ...payload,
    }),
    setDefaultPaymentMethod: (payload) => ({
        type: duck.types.SET_DEFAULT_PAYMENT_METHOD,
        ...payload,
    }),
})
  
export {
    types,
    creators,
}
  