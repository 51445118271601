import React from 'react'

const isDev = process.env.NODE_ENV === 'development'
const enableWdyr = typeof process.env.WDYR !== 'undefined'

if (isDev && enableWdyr) {
    /* eslint-disable-next-line global-require */
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    })
}
