import {
    put, putResolve,
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'

import {
    PICO_API_URL, PURCHASE_SWITCH
} from 'piconetworks/pkg-endpoints'

function* checkCanSwitchPlanNow({
    types,
    productOptionId = null,
    publisherId = null,
}) {
    try {
        if (!productOptionId) {
            throw Error('productOptionId not provided')
        }

        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_SWITCH()}/${productOptionId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                params: {
                    product_option_id: productOptionId,
                },
            },
        })

        if (!data) {
            throw Error('Check switch plan now did not succeed. No data returned')
        }

        yield put({
            type: types.CHECK_SWITCH_PLAN_NOW_SUCCESS,
            payload: {
                canSwitchPlanNow: data.pay_now,
                prorate_amount: data.prorate_amount,
                next_price: data.next_price,
                end_date: data.end_date
            },
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.CHECK_SWITCH_PLAN_NOW_ERROR,
            error: error.message,
            payload: {
                error: productOptionId,
            },
        })
    }
}

export default checkCanSwitchPlanNow
