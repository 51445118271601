import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import intlTelInput from 'intl-tel-input'
import Form from 'piconetworks/pkg-form'
import styles from '../scss/MenuRoutes.module.scss'

const PhoneField = ({
    checkPhone,
    fieldContainerProps,
    phoneInputRef,
    label,
}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [phoneInputError, setPhoneInputError] = useState(null)
    let number
    useEffect(() => {
        if (phoneInputRef?.current) {
            const iti = intlTelInput(phoneInputRef.current, {
                onlyCountries: ['us', 'ca'],
                preferredCountries: ['us'],
                utilsScript:
                    'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
            })

            window.loginIti = iti
        }
    }, [phoneInputRef.current])

    const error = phoneInputError || errorMessage

    if (typeof window !== 'undefined') {
        number = window?.iti?.getNumber() || null
    }

    const shouldShowError = (phoneInput) => {
        // intl-input number validates numbers with letters if it fits the country's
        // number length, so we must check the raw input as well as the formatted input
        // they provide. shouldShowError is true if intl-input number validation does not pass
        // or input value contains anything other than +-() and numbers
        if (typeof window !== 'undefined') {
            return (
                (number && !window?.iti?.isValidNumber()) ||
                !/^(?=.*[0-9])[- +()0-9]+$/.test(phoneInput)
            )
        }
    }

    const resolvePhoneInputError = () => {
        switch (window.iti.getValidationError()) {
            case 1:
                return 'Invalid Country Code'
            case 2:
                return 'Number is too short'
            case 3:
                return 'Number is too long'
            case 5:
                return 'Number length is invalid'
            default:
                return 'Invalid number'
        }
    }

    const handlePhoneErrorReset = () => {
        if (error) {
            setErrorMessage(null)
            setPhoneInputError(null)
        }
    }

    const processNumber = async (phone) => {
        phone = phone && phone.trim()

        if (shouldShowError(phone)) {
            const error = resolvePhoneInputError()
            setPhoneInputError(error)
        } else {
            try {
                await checkPhone({
                    phone: number || phone,
                })
            } catch (error) {
                setErrorMessage(error.message)
            }
        }
    }

    const onEnterPhone = (e, phone) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            processNumber(phone)
        }
    }

    return (
        <div className={ styles.field }>
            <Field
                { ...fieldContainerProps }
                name='phone'
                id='phone'
                { ...(label && { label }) }
                type='tel'
            >
                { ({ meta, input, ...rest }) => (
                    <div
                        className={
                            styles[`phoneInput${error ? '--error' : ''}`]
                        }
                    >
                        <Form.Input
                            { ...rest }
                            meta={ meta }
                            ref={ phoneInputRef }
                            input={ input }
                            onClick={ () => {
                                handlePhoneErrorReset()
                            } }
                            onChange={ () => {
                                handlePhoneErrorReset()
                            } }
                            onKeyDown={ (e) => onEnterPhone(e, e.target.value) }
                        />
                        { error && (
                            <span className={ styles.phoneError }>{ error }</span>
                        ) }
                    </div>
                ) }
            </Field>
        </div>
    )
}

PhoneField.propTypes = {
    checkPhone: PropTypes.func,
    contrastColor: PropTypes.string,
    enterOTP: PropTypes.func,
    fieldContainerProps: PropTypes.any,
    isReturningUser: PropTypes.bool,
    label: PropTypes.string,
    onSubmitCallback: PropTypes.func,
    phoneInputRef: PropTypes.any,
    previewMode: PropTypes.bool,
}

export default React.memo(PhoneField)
