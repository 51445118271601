import React from 'react'
import PropTypes from 'prop-types'
const Refund = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_refund">
                <polygon
                    id="bounds"
                    fill="#FF4440"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <g id="icon_return" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
                    <path
                        d="M11.8833789,18.9932723 C11.3860402,18.9355072 11,18.5128358 11,18 C11,17.4477153 11.4477153,17 12,17 L12,17 L16,17 C18.209139,17 20,15.209139 20,13 C20,10.8578046 18.3160315,9.10892112 16.1996403,9.00489531 L16,9 L5.421,8.99910678 L8.70680912,12.28 C9.09773029,12.6705243 9.09773029,13.3094757 8.70680912,13.7 C8.31588794,14.0905243 7.68629733,14.0905243 7.29537615,13.7 L2.29319088,8.70264069 C1.90226971,8.3121164 1.90226971,7.67895142 2.29319088,7.28842712 L7.29537615,2.29 C7.68629733,1.89947571 8.31588794,1.89947571 8.70680912,2.29 C9.09773029,2.68052429 9.09773029,3.31947571 8.70680912,3.71 L5.413,6.99910678 L16,7 C19.3137085,7 22,9.6862915 22,13 C22,16.3137085 19.3137085,19 16,19 L12,19 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </g>
    </svg>
)

Refund.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Refund
