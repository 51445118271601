import Loader from 'modules/loader'
import Gift from 'modules/gift'

import storeSubscriber from '../storeSubscriber'

const createGift = async ({
    dispatch,
    payload,
    action,
    trackPaymentEvent = () => {},
}) => {
    dispatch(action(payload))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                const didCreateGift = Gift.selectors.didCreateGift(state)
                const creatingGift = Gift.selectors.creatingGift(state)
                const createGiftError = Gift.selectors.createGiftError(state)

                if (!creatingGift && didCreateGift) {
                    return true
                }

                if (!creatingGift && !didCreateGift) {
                    const defaultErrorMessage = 'There was an error creating this gift, please try reloading the page or contacting support@hype.co if this issue persists.'
                    const errorMessage = createGiftError || defaultErrorMessage

                    throw new Error(errorMessage)
                }

                return false
            },
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }

    trackPaymentEvent()
}

export default createGift
