import Loader from 'modules/loader'

import storeSubscriber from '../storeSubscriber'

const awaitGiftResults = async () => {
    const response = await storeSubscriber({
        checks: [
            (state) => {
                const isNotFetching = Loader.selectors.isNotFetching(state)
                const { redeemingGift } = state.gift
                const { redeemGiftSuccess } = state.gift
                const { redeemGiftError } = state.gift
                const { redeemGiftErrorMessage } = state.gift

                if (redeemingGift === true) {
                    return false
                }

                if (redeemGiftSuccess && isNotFetching) {
                    return true
                }

                if (redeemGiftError) {
                    throw new Error(redeemGiftErrorMessage || 'there was an error redeeming this gift')
                }

                return false
            },
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default awaitGiftResults
