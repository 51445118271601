import React from 'react'
import Block from 'piconetworks/block'
import useClaimThisPage from 'lib/useClaimThisPage'
import style from './style.module.scss'

const ClaimThisPage = () => {
    const { showClaimThisPage, username } = useClaimThisPage()
    if (!showClaimThisPage) return null

    return (
        <>
            <Block.Claim 
                className={style.claimBlock}
                href={`${process.env.MARKETING_SITE_URL}/claim?username=${username}`}
                openInNewWindow={true}
            />
        </>
    )
}

export default ClaimThisPage
