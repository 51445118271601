import createSwitchProduct from './createSwitchProduct'
import getSwitchProducts from './getSwitchProducts'
import getSwitchProration from './getSwitchProration'

const getSwitchProductSagas = (duck) => ({
    * createSwitchProduct(payload) {
        yield createSwitchProduct({ ...payload, ...duck })
    },
    * getSwitchProducts(payload) {
        yield getSwitchProducts({ ...payload, ...duck })
    },
    * getSwitchProration(payload) {
        yield getSwitchProration({ ...payload, ...duck })
    },
})

export default getSwitchProductSagas
