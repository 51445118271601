import { takeEvery, takeLatest } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.LOGIN, sagas.login),
    takeEvery(types.LOGOUT, sagas.logout),
    takeEvery(types.USE_REFRESH_TOKEN, sagas.useRefreshToken),
    takeLatest(types.TOKEN_LOGIN, sagas.tokenLogin),
    takeLatest(types.SOCIAL_LOGIN, sagas.socialLogin),
    takeLatest(types.CLAIM_MAGIC_LINK, sagas.claimMagicLink),
    takeLatest(types.REGISTER_CONFIRM, sagas.registerConfirm),
])

export default takes
