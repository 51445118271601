import React from 'react'

export default ({ title = 'brand/stripe' }) => (
    <svg
        height="64px"
        width="64px"
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="Brands"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <g id="brand/stripe">
                <polygon
                    id="bounds"
                    fill="#6772E5"
                    points="0 0 64 0 64 64 0 64"
                />
                <path
                    id="Shape"
                    d="M28.9606299,24.7208791 C28.9606299,23.178022 30.2204724,22.5846154 32.3070866,22.5846154 C35.2992126,22.5846154 39.0787402,23.4945055 42.0708661,25.1164835 L42.0708661,15.8197802 C38.8031496,14.5142857 35.5748031,14 32.3070866,14 C24.3149606,14 19,18.1934066 19,25.1956044 C19,36.1142857 33.9606299,34.3736264 33.9606299,39.0813187 C33.9606299,40.9010989 32.3858268,41.4945055 30.1811024,41.4945055 C26.9133858,41.4945055 22.7401575,40.1494505 19.4330709,38.3296703 L19.4330709,47.7450549 C23.0944882,49.3274725 26.7952756,50 30.1811024,50 C38.3700787,50 44,45.9252747 44,38.843956 C43.9606299,27.0549451 28.9606299,29.1516484 28.9606299,24.7208791 Z"
                    fill="#FFFFFF"
                />
            </g>
        </g>
    </svg>
)
