import PropertyType from "../Modifiers/PropertyType"

// blockquote > div > span > span is because of the weird way the editor wants to render this in dashboard preview
// when ever we get rid of dashboard or if we iframify the preview, we can get rid of this

const ThemePageFontHeading = new PropertyType({
    name: 'PageFontHeading',
    propertyFromApi: 'page-font-heading',
    convertValueToCssPropertiesFunction: (value: string) => `font-family: ${value}; font-weight: 500;`,
    defaultValue: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    selector: 'h1, h2, h3, h4, h5, h6, blockquote, blockquote > div > span > span',
    cssVariable: '--page-font-heading',
},
'theme'
)
const FormPageFontHeading = new PropertyType({
    name: 'PageFontHeading',
    propertyFromApi: 'page-font-heading',
    convertValueToCssPropertiesFunction: (value: string) => `font-family: ${value}; font-weight: 500;`,
    defaultValue: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    selector: 'h1, h2, h3, h4, h5, h6, blockquote, blockquote > div > span > span',
    cssVariable: '--page-font-heading',
},
'form'
)

export {
    ThemePageFontHeading,
    FormPageFontHeading,
}
