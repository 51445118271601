import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './FlexboxContainer.module.scss'

const FlexboxContainer = React.forwardRef(
    ({ alignItems, children, className, flexDirection, flexWrap, inline, justifyContent, ...props }, ref) => {
        return (
            <div
                className={cx(
                    inline ? style.displayInlineFlex : style.displayFlex,
                    alignItems && `align-items-${alignItems}`,
                    flexDirection && `flex-${flexDirection}`,
                    flexWrap && `flex-${flexWrap}`,
                    justifyContent && `justify-content-${justifyContent}`,
                    className,
                )}
                ref={ref}
                {...props}
            >
                {children}
            </div>
        )
    }
)

FlexboxContainer.propTypes = {
    /** This defines the alignment along the cross axis. */
    alignItems: PropTypes.oneOf([ 'stretch', 'center', 'start', 'end', 'baseline', 'initial' ]),
    children: PropTypes.node,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    /** Whether the container is display inline-flex (true) or just flex (false) */
    inline: PropTypes.bool,
    /** Defines direction flex items are placed in the flex container */
    flexDirection: PropTypes.oneOf([ 'row', 'column', 'row-reverse', 'column-reverse' ]),
    /** Whether to wrap to the next line */
    flexWrap: PropTypes.oneOf([ 'wrap', 'nowrap', 'wrap-reverse' ]),
    /** This defines the alignment along the main axis. */
    justifyContent: PropTypes.oneOf([ 'start', 'end', 'center', 'between', 'around', 'initial' ])
}

FlexboxContainer.defaultProps = {
    inline: false,
    flexDirection: 'row',
    flexWrap: 'nowrap'
}

export default FlexboxContainer
