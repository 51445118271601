const Verify = ({
    children = null,
    router = {},
    wizard = {},
}) => {
    if (
        Object.keys(router).length === 0
        || Object.keys(wizard).length === 0
    ) {
        return children
    }

    const {
        replace,
        query,
    } = router

    const {
        id: wizardName,
        short_code,
        company_slug,
    } = query

    const {
        wizards,
    } = wizard

    const wizardExists = !!wizards[wizardName]

    if (!wizardName || wizardExists || wizardName?.includes('LoginWizard') || typeof window === 'undefined') {
        return children
    }
    replace({
        pathname: `/${company_slug}`,
        query: {
            ...(short_code && { short_code }),
        },
    }, {
        pathname: `/${company_slug}`,
        query: {
            ...(short_code && { short_code }),
        },
    })

    return null
}

export default Verify
