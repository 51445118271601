import generateAction from 'piconetworks/pkg-generate-action'

const getProductByOption = generateAction({
    baseActionCreatorName: 'getProductByOption',
    baseActionTypeName: 'GET_PRODUCT_BY_OPTION',
})

const types = [
    ...getProductByOption.types,
]

const creators = (duck) => ({
    ...getProductByOption.creators(duck),
})

export {
    types,
    creators,
}
