import React from 'react'

export default ({
    title = 'removeOption/default',
    color = 'currentcolor',
    width = '18px',
    height = '18px'
}) => (
    <svg
        height={height}
        width={width}
        version="1.1"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="Inputs"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <g id="removeOption/default" fill={color}>
                <path
                    id="Combined-Shape"
                    d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,2 C5.13400675,2 2,5.13400675 2,9 C2,12.8659932 5.13400675,16 9,16 C12.8659932,16 16,12.8659932 16,9 C16,5.13400675 12.8659932,2 9,2 Z M13,8 C13.5522847,8 14,8.44771525 14,9 C14,9.55228475 13.5522847,10 13,10 L5,10 C4.44771525,10 4,9.55228475 4,9 C4,8.44771525 4.44771525,8 5,8 L13,8 Z"
                />
            </g>
        </g>
    </svg>
)
