import redeemGift from './redeemGift'
import createGift from './createGift'
import getGift from './getGift'
import getGiftProduct from './getGiftProduct'
import convertGift from './convertGift'

const sagas = (duck) => ({
    * redeemGift(payload) {
        yield redeemGift({
            ...payload,
            ...duck
        })
    },
    * createGift(payload) {
        yield createGift({
            ...payload,
            ...duck
        })
    },
    * getGift(payload) {
        yield getGift({
            ...payload,
            ...duck
        })
    },
    * getGiftProduct(payload) {
        yield getGiftProduct({
            ...payload,
            ...duck
        })
    },
    * convertGift(payload) {
        yield convertGift({
            ...payload,
            ...duck
        })
    },
})

export default sagas
