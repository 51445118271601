import { FORM_ERROR } from 'final-form'
import ErrorWithCard from 'piconetworks/pkg-error-with-card'

const WIZARD_ERROR = 'WizardError'

class WizardError extends Error {
    constructor(message = 'Please try again.', options = {}) {
        super(message)
        this.name = WIZARD_ERROR
        this.options = options
    }

    static get FORM_ERROR() {
        return FORM_ERROR
    }

    static get Error() {
        return ErrorWithCard
    }

    static isError(error) {
        if (error && error.name === WIZARD_ERROR) {
            return true
        }
        return false
    }
}

export default WizardError
