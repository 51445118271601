const productTypes = [
    'donation.existing.switch',
    'donation.new.oneTime',
    'donation.new.recurring',
    'donation.new.regular',
    'gift.existing.convert',
    'gift.new.pledge',
    'gift.new.regular',
    'group.new.regular',
    'pass.new.regular',
    'subscription.existing.switch',
    'subscription.new.pledge',
    'subscription.new.regular',
    'subscription.new.trial',
]

export default productTypes
