import React from 'react'
import PropTypes from 'prop-types'
const Add = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_add">
                <polygon
                    id="bounds"
                    fill="#F9F4FF"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M16,6 C21.5228475,6 26,10.4771525 26,16 C26,21.5228475 21.5228475,26 16,26 C10.4771525,26 6,21.5228475 6,16 C6,10.4771525 10.4771525,6 16,6 Z M16,10 C15.4477153,10 15,10.4477153 15,11 L15,11 L15,14.999 L11,15 C10.4477153,15 10,15.4477153 10,16 C10,16.5522847 10.4477153,17 11,17 L11,17 L15,16.999 L15,21 C15,21.5522847 15.4477153,22 16,22 C16.5522847,22 17,21.5522847 17,21 L17,21 L17,16.999 L21,17 C21.5522847,17 22,16.5522847 22,16 C22,15.4477153 21.5522847,15 21,15 L21,15 L17,14.999 L17,11 C17,10.4477153 16.5522847,10 16,10 Z"
                    id="Combined-Shape"
                    fill="#7A34FF"
                />
            </g>
        </g>
    </svg>
)

Add.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Add
