import { ThemePageBackgroundValue, FormPageBackgroundValue } from '../Properties/PageBackgroundValue'
import PropertyType from '../Modifiers/PropertyType'
import { colors } from 'piconetworks/pkg-functions'

enum PageBackgroundOptions {
    color = 'color',
    gradient = 'gradient',
    image = 'image',
    video = 'video'
}

const cssToReturn = (backgroundType: string, backgroundValue: string) => {
    // we need to unset anything being set on the theme level in case they have an override for the form level
    switch (backgroundType) {
        case PageBackgroundOptions.color:
            return `
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    background-position: unset;
    background: ${backgroundValue};`
        case PageBackgroundOptions.gradient:
            //  You must add background-attachment after the background properties.
            // If you add it before, it will not work.
            return `background-image: unset;
    background-repeat: unset;
    background-size: unset;
    background-position: unset;
    background: ${backgroundValue};
    background-attachment: fixed;`
        case PageBackgroundOptions.image:
            return `background-image: url('${backgroundValue}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;`
        case PageBackgroundOptions.video:
            return `background: unset;
    background-image: none;
    background-repeat: unset;
    background-size: unset;
    background-position: unset;
    object-fit: cover;
    width: 100%;
    height: 100lvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-attachment: fixed;`
        default:
            return
    }
}

const themeBackgroundTypeFunc = (backgroundType: string) => {
    const backgroundValue = ThemePageBackgroundValue.getValue()
    // console.log('backgroundValue', backgroundValue, backgroundType)
    return cssToReturn(backgroundType, backgroundValue)
}
const formBackgroundTypeFunc = (backgroundType: string) => {
    const backgroundValue = FormPageBackgroundValue.getValue()
    return cssToReturn(backgroundType, backgroundValue)
}

const generateSelector = (backgroundType: string) => {
    if (backgroundType == PageBackgroundOptions.video) {
        return 'video'
    }
    return '.page'
}

const getBackgroundColorHex = (backgroundColor) => {
    let sectionBackgroundColor = backgroundColor

    if (backgroundColor === 'transparent') {
        sectionBackgroundColor = '#FFFFFF'
    }

    if (backgroundColor?.includes('rgba')) {
        const sectionBackgroundColorAlpha = backgroundColor.split('(').pop().split(')')[0]
        const [r, g, b] = sectionBackgroundColorAlpha.split(',').map((x) => parseFloat(x))
        sectionBackgroundColor = colors.RgbToHex(r, g, b)
    }
    return sectionBackgroundColor
}

const getPageBackgroundColor = ({
    backgroundColorProperty,
    backgroundType
}) => {
    if (backgroundColorProperty.getHasBeenModified()) {
        // for video or image
        let sectionBackgroundColor = '#000000'

        if (backgroundType === 'color' || backgroundType === 'gradient') {
            const backgroundColor = backgroundColorProperty.getValue()
            sectionBackgroundColor = getBackgroundColorHex(backgroundColor)
        }
    
        const { r: redTextColor, g: greenTextColor, b: blueTextColor } = colors.hexToRgb(sectionBackgroundColor)
        // console.groupCollapsed(`why page contrast color`)
        // console.log(why)
        // console.groupEnd()
        const textColorRgb = `rgb(${redTextColor}, ${greenTextColor}, ${blueTextColor})`
        // console.log('pageContrastColor', textColorRgb)
        return textColorRgb
    }
}

const ThemePageBackgroundType = new PropertyType({
    name: 'PageBackgroundType',
    propertyFromApi: 'page-background-type',
    convertValueToCssPropertiesFunction: themeBackgroundTypeFunc,
    defaultValue: PageBackgroundOptions.color,
    options: PageBackgroundOptions,
    selectorFunction: (value: string) => generateSelector(value),
    cssVariable: '--page-background-color',
    cssVariableFunction: (value) => getPageBackgroundColor({
        backgroundColorProperty: ThemePageBackgroundValue,
        backgroundType: value,
    }),
    convertToRgb: true,
    isCompounded: true,
    priority: 1
},
'theme'
)

const FormPageBackgroundType = new PropertyType({
    name: 'PageBackgroundType',
    propertyFromApi: 'page-background-type',
    convertValueToCssPropertiesFunction: formBackgroundTypeFunc,
    defaultValue: PageBackgroundOptions.color,
    options: PageBackgroundOptions,
    selectorFunction: (value: string) => generateSelector(value),
    cssVariable: '--page-background-color',
    cssVariableFunction: (value) => getPageBackgroundColor({
        backgroundColorProperty: FormPageBackgroundValue,
        backgroundType: value,
    }),
    convertToRgb: true,
    isCompounded: true,
    priority: 2
},
'form'
)

export {
    ThemePageBackgroundType,
    FormPageBackgroundType,
}
