import { call, put } from 'redux-saga/effects'
import {
    ME_PAYMENT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* deleteGift({
    types,
    payload: giftId
}) {
    try {
        const { status } = yield call(picoAPIHelper, {
            url: `${ME_PAYMENT()}/${giftId}`,
            method: 'delete',
        })

        if (status === 200) {
            yield put({
                type: types.DELETE_GIFT_SUCCESS,
                payload: giftId,
            })
        } else {
            throw new Error(`unable to delete gift, ${giftId}`)
        }
    } catch (error) {
        yield put({
            type: types.DELETE_GIFT_ERROR,
            error: error?.message || 'DELETE_GIFT_ERROR',
        })
    }
}

export default deleteGift
