import purchaseProduct from './purchaseProduct'
import cancelProduct from './cancelProduct'
import reactivateProduct from './reactivateProduct'

const getProductSagas = (duck) => ({
    * purchaseProduct(payload) {
        yield purchaseProduct({ ...payload, ...duck })
    },
    * cancelProduct(payload) {
        yield cancelProduct({ ...payload, ...duck })
    },
    * reactivateProduct(payload) {
        yield reactivateProduct({ ...payload, ...duck })
    },
})

export default getProductSagas
