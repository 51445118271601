import getArticle from './getArticle'
import updateArticle from './updateArticle'
import authenticatedArticleRead from './authenticatedArticleRead'
import authenticatedArticleVisit from './authenticatedArticleVisit'
import guestArticleRead from './guestArticleRead'
import guestArticleVisit from './guestArticleVisit'

const sagas = (duck) => ({
    * getArticle(payload) {
        yield getArticle({ ...payload, ...duck })
    },
    * updateArticle(payload) {
        yield updateArticle({ ...payload, ...duck })
    },
    * authenticatedArticleRead(payload) {
        yield authenticatedArticleRead({ ...payload, ...duck })
    },
    * authenticatedArticleVisit(payload) {
        yield authenticatedArticleVisit({ ...payload, ...duck })
    },
    * guestArticleRead(payload) {
        yield guestArticleRead({ ...payload, ...duck })
    },
    * guestArticleVisit(payload) {
        yield guestArticleVisit({ ...payload, ...duck })
    },
})

export default sagas
