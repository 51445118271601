import HSL from './HSL'
import RGB from './RGB'

const HEX = {
    isHex: (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex),
    toHSL: (hex) => RGB.toHSL(HEX.toRGB(hex)),
    toRGB: (hex, alpha) => {
        if (alpha) {
            const { r, g, b } = HEX.toRGB(hex)
            return `rgba(${r}, ${g}, ${b}, ${alpha})`
        } else {
            const rgb = parseInt(hex.substring(1), 16) // strip # and convert rrggbb to decimal
            const red = (rgb >> 16) & 0xff // extract red
            const green = (rgb >> 8) & 0xff // extract green
            const blue = (rgb >> 0) & 0xff // extract blue
            return { r: red, g: green, b: blue }
        }
    },
    saturate: (hex, amount = 10) => HSL.toHEX(HSL.saturate(HEX.toHSL(hex), amount)),
    desaturate: (hex, amount = 10) => HSL.toHEX(HSL.desaturate(HEX.toHSL(hex), amount)),
    darken: (hex, amount = 10) => HSL.toHEX(HSL.darken(HEX.toHSL(hex), amount)),
    lighten: (hex, amount = 10) => HSL.toHEX(HSL.lighten(HEX.toHSL(hex), amount))
}

export default HEX