import React from 'react'
import PropTypes from 'prop-types'
const Pass = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_pass">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M21,13 L21,25 C21,25.5522847 20.5522847,26 20,26 L18.5,26 L18.5,26 C18.5,24.6192881 17.3807119,23.5 16,23.5 C14.6192881,23.5 13.5,24.6192881 13.5,26 L12,26 C11.4477153,26 11,25.5522847 11,25 L11,13 L21,13 Z M16,15 L14.9713758,17.0842203 L12.6713022,17.4184405 L14.3356511,19.0407797 L13.9427516,21.3315595 L16,20.25 L18.0572484,21.3315595 L17.6643489,19.0407797 L19.3286978,17.4184405 L17.0286242,17.0842203 L16,15 Z M13.5,6 C13.5,7.38071187 14.6192881,8.5 16,8.5 C17.3807119,8.5 18.5,7.38071187 18.5,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,11 L11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 L13.5,6 L13.5,6 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                    transform="translate(16.000000, 16.000000) rotate(-315.000000) translate(-16.000000, -16.000000) "
                />
            </g>
        </g>
    </svg>
)

Pass.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Pass
