import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import '../Heading.module.scss'

const H2 = ({children, className, ...props}) => {
    return (
        <h2 className={cx(className)} {...props}>{children}</h2>
    )
}

H2.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default H2
