import React from 'react'

export default ({ title = 'Hide details', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="12px"
        icon="M.787 9.188a.932.932 0 0 0 1.39 0l3.824-4.122 3.824 4.122a.931.931 0 0 0 1.39 0c.384-.414.377-1.093-.006-1.507L6.702 2.812a.943.943 0 0 0-1.402 0L.793 7.681a1.125 1.125 0 0 0-.006 1.507z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title={title}
        width="12px"
        viewBox="0 0 12 12"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M.787 9.188a.932.932 0 0 0 1.39 0l3.824-4.122 3.824 4.122a.931.931 0 0 0 1.39 0c.384-.414.377-1.093-.006-1.507L6.702 2.812a.943.943 0 0 0-1.402 0L.793 7.681a1.125 1.125 0 0 0-.006 1.507z"
            fill={color}
        />
    </svg>
)
