import { produce } from 'immer'

export const initialState = {
    query_flags: [], // added/removed through query parameter or console
    feature_flags: [], // LaunchDarkly flags
    all_flags: {},
    loading: null,
}

const reducer = (
    state,
    { type, payload },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.ENABLE_FEATURE_FLAG:
            if (payload && !draftState.query_flags.includes(payload)) {
                draftState.query_flags.push((payload || '').toLowerCase())
            }
            break

        case duck.types.DISABLE_FEATURE_FLAG:
            draftState.query_flags = draftState.query_flags.filter(flag =>
                flag !== payload
            )
            break

        case duck.types.GET_FEATURE_FLAGS:
            draftState.loading = true
            draftState.error = false
            break

        case duck.types.GET_FEATURE_FLAGS_SUCCESS:
            draftState.loading = false
            draftState.error = false

            /**
             * This looks like: { flag_name: 'value', other_flag: 'value2' }
             */
            draftState.all_flags = payload

            // This is going to be a problem in the future, if feature flag variations
            // ever have more than a just a true/false setting.
            draftState.feature_flags = Object.keys(payload).filter(key => payload[key])
            break

        case duck.types.GET_FEATURE_FLAGS_ERROR:
            draftState.loading = false
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
