import React from 'react'
import PropTypes from 'prop-types'
const Reverse = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_reverse">
                <polygon
                    id="bounds"
                    fill="#FFFAF1"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M6.89734645,8.46869518 C7.30026834,8.06577329 7.93565211,8.03699316 8.37178283,8.38235478 L8.46869485,8.46869518 L9.63603954,9.63603874 L9.63603954,9.63603874 C13.1507582,6.12132011 18.849243,6.12132011 22.3639616,9.63603874 C25.8786802,13.1507574 25.8786802,18.8492422 22.3639616,22.3639608 C18.849243,25.8786794 13.1507582,25.8786794 9.63603954,22.3639608 C8.39089633,21.1188176 7.58686428,19.599603 7.22394339,18.0005051 L9.29056415,18.0005712 C9.61141153,19.0800955 10.1979745,20.0974686 11.0502531,20.9497472 C13.7839231,23.6834173 18.216078,23.6834173 20.949748,20.9497472 C23.6834181,18.2160772 23.6834181,13.7839223 20.949748,11.0502523 C18.2782069,8.37871112 13.9845074,8.31799427 11.2392318,10.8681018 L11.0488495,11.0502629 L12.5318659,12.5318662 C12.7104719,12.7104723 12.8219927,12.9436233 12.8502107,13.192167 L12.8573028,13.3175404 C12.8573028,13.8902801 12.4239586,14.3618069 11.8672595,14.4221317 L11.7461917,14.4286515 L7.68302065,14.4286515 C7.11028091,14.4286515 6.63875411,13.9953074 6.57842939,13.4386082 L6.57190954,13.3175404 L6.57190954,9.25436938 C6.57190954,8.95968439 6.68897269,8.67706893 6.89734645,8.46869518 Z"
                    id="Path"
                    fill="#FCAF2F"
                />
            </g>
        </g>
    </svg>
)

Reverse.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Reverse
