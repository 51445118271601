import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withHTMLElement from 'piconetworks/pkg-html-elements'
import style from './scss/ContentContainer.module.scss'

const ContentContainer = (props) => {
    const { children } = props
    return <div {...props}>{children}</div>
}

ContentContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

export default withHTMLElement(ContentContainer, {
    defaultStyle: classNames(style.modal),
})
