import Joi from 'joi'

const DashboardCardUpdated = Joi.object()
    .keys({
        card_id: Joi.string().allow(null).description('The id of the card'),
        customer_id: Joi.string().allow(null).description('The id of the customer'),
    })
    .description('dashboard:card:updated event attributes ')
    .meta({ name: 'DashboardCardUpdated', filename: 'dashboard:card:updated.model' })

export default DashboardCardUpdated
