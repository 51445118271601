const types = [
    'ENABLE_FEATURE_FLAG',
    'DISABLE_FEATURE_FLAG',

    'GET_FEATURE_FLAGS',
    'GET_FEATURE_FLAGS_SUCCESS',
    'GET_FEATURE_FLAGS_ERROR',
]

const creators = (duck) => ({
    enableFeatureFlag: (payload) => ({
        type: duck.types.ENABLE_FEATURE_FLAG,
        payload,
    }),
    disableFeatureFlag: (payload) => ({
        type: duck.types.DISABLE_FEATURE_FLAG,
        payload,
    }),
    getFeatureFlags: (payload) => ({ 
        type: duck.types.GET_FEATURE_FLAGS,
        ...payload,
    }),
})

export {
    types,
    creators,
}
