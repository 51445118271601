import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './HorizontalDivider.module.scss'

const HorizontalDivider = ({ children, className, withLine, ...props }) => {
    return (
        <div
            className={cx(
                styles.divider,
                withLine && styles.withLine,
                children && styles.withChildren,
                className
            )}
            {...props}
        >
            <span>{children}</span>
        </div>
    )
}

HorizontalDivider.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Whether there will be text used for the children (which adds a line to the left and right of the text) */
    withLine: PropTypes.bool
}

HorizontalDivider.defaultProps = {
    withLine: false
}

export default HorizontalDivider
