import Joi from 'joi'

const LandingPagePromptConverted = Joi.object()
    .keys({
        userId: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The Hype-assigned id of the user'),
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        prompt_type: Joi.string().valid('payment', 'signup').required().description('Whether the prompt was for signing up or payment')
    })
    .description('landing_page:prompt_converted event attributes ')
    .meta({ name: 'LandingPagePromptConverted', filename: 'landing_page:prompt_converted.model' })

export default LandingPagePromptConverted
