import Loader from 'modules/loader'
import Payment from 'modules/payment'

import storeSubscriber from '../storeSubscriber'

const setDefaultPaymentMethod = async ({
    dispatch,
    payload: {
        sourceId,
        publisherId,
    },
}) => {
    dispatch(Payment.creators.setDefaultPaymentMethod({
        sourceId,
        publisherId,
    }))

    const response = await storeSubscriber({
        checks: [
            (state) => {
                const defaultPaymentErrors = Payment.selectors.defaultPaymentMethodErrors(state)

                if (defaultPaymentErrors) {
                    throw new Error(
                        defaultPaymentErrors,
                    )
                }

                return (
                    Payment.selectors.isDefaultNotBeingSet(state)
                    && Loader.selectors.isNotFetching(state)
                )
            },
            Loader.selectors.isNotFetching,
            Payment.selectors.isDefaultNotBeingSet,
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default setDefaultPaymentMethod
