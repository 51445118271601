// eslint-disable-next-line require-yield
import {
    select,
    put,
} from 'redux-saga/effects'
import Me from 'modules/me'
import Account from 'modules/account'
import logger from 'lib/logger'

function* syncUserDataAfterPasswordRegister({ payload }) {
    try {
        const { auth, wizard, client } = yield select()

        if (auth.is_new_user) {
            const wizardPayload = wizard?.wizards?.[payload.wizardName]?.submit?.payload

            yield put(Account.creators.newsletterSubscribe({
                newsletterIds: wizardPayload?.selectedNewsletters,
                publisherId: client.id,
                sync: true,
                onlyAddNewsletters: true,
            }))

            yield put(Me.creators.updateUserProperties({
                payload: {
                    customProperties: wizardPayload.customProperties,
                    publisherId: client.id,
                },
            }))

            yield put(Me.creators.getCurrentUser({
                payload: {
                    publisherId: client.id,
                },
            }))
        }
    } catch (error) {
        logger.error('syncUserDataAfterPasswordRegister', {}, error)
    }
}

export default syncUserDataAfterPasswordRegister
