import React from 'react'

export default ({ color = '#FF4440', width = '20', height = '20' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm-3.29525886 5.29248734c-.38998312-.38998312-1.02227068-.38998312-1.4122538 0s-.38998312 1.02227068 0 1.4122538l3.29525886 3.29525886-3.29525886 3.2952589c-.38998312.3899831-.38998312 1.0222706 0 1.4122538.38998312.3899831 1.02227068.3899831 1.4122538 0l3.29525886-3.2952589 3.2952589 3.2952589c.3899831.3899831 1.0222706.3899831 1.4122538 0 .3899831-.3899832.3899831-1.0222707 0-1.4122538l-3.2952589-3.2952589 3.2952589-3.29525886c.3899831-.38998312.3899831-1.02227068 0-1.4122538-.3899832-.38998312-1.0222707-.38998312-1.4122538 0l-3.2952589 3.29525886z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
