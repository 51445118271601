import { call, put } from 'redux-saga/effects'
import {
    DOWNLOADS
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getDownloadableFiles({
    types,
    publisherId,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: DOWNLOADS(),
            method: 'get',
            headers: {
                publisherid: publisherId,
            },
        })

        if (data) {
            yield put({
                type: types.GET_DOWNLOADABLE_FILES_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch downloads')
        }
    } catch (error) {
        yield put({
            type: types.GET_DOWNLOADABLE_FILES_ERROR,
            error: error?.message,
        })
    }
}

export default getDownloadableFiles
