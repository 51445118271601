import Joi from 'joi'

const LandingPagePicoLinkView = Joi.object()
    .keys({
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        segment: Joi.boolean().default(true).required().description('An indicator that event was sent to segment'),
        theme_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the theme applied'),
    })
    .description('landing_page:pico_link_view event attributes ')
    .meta({ name: 'LandingPagePicoLinkView', filename: 'landing_page:pico_link_view.model' })

export default LandingPagePicoLinkView
