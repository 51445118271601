import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_GIFT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* updateGift({
    types,
    payload
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${PURCHASE_GIFT()}/${payload.id}`,
            method: 'put',
            data: payload
        })

        if (data) {
            yield put({
                type: types.UPDATE_GIFT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to update gift')
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_GIFT_ERROR,
            error: error?.message || 'UPDATE_GIFT_ERROR',
        })
    }
}

export default updateGift
