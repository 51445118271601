import createDuck from '../baseDuck'
import { creators, types } from './actions'
import reducer, { initialState } from './reducer'
import selectors from './selectors'

export default createDuck({
    store: 'utm',
    initialState,
}).extend({
    types,
    reducer,
    selectors,
    creators,
})
