import PropertyType from "../Modifiers/PropertyType"

const borderValueFunc = (borderValue: string) => {
    return `border-color: ${borderValue};`
}

const ThemeBlockBorderValue = new PropertyType({
    name: 'BlockBorderValue',
    propertyFromApi: 'block-border-value',
    convertValueToCssPropertiesFunction: borderValueFunc,
    selector: '.block',
    cssVariable: '--block-border-color',
},
'theme'
)

const FormBlockBorderValue = new PropertyType({
    name: 'BlockBorderValue',
    propertyFromApi: 'block-border-value',
    convertValueToCssPropertiesFunction: borderValueFunc,
    selector: '.block',
    cssVariable: '--block-border-color',
},
'form'
)

export {
    ThemeBlockBorderValue,
    FormBlockBorderValue,
}
