import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import cx from 'classnames'

import Form from 'piconetworks/pkg-form'
import NewsletterCheckbox from './NewsletterCheckbox'
import styles from './scss/ChooseNewsletter.module.scss'

const FILTER_TYPES = {
    free: 'free',
    preselected: 'preselected',
    paid: 'paid',
    all: 'all'
}

const ChooseNewsletter = ({
    fieldContainerProps,
    description = null,
    required,
    newsletters = [],
    containerType,
    disableGroup,
    newsletterFilter = 'all',
    onHasNewsletters = () => { },
    onHasNoNewsletters = () => { },
    isSingleColumn = true,
    isReadyToMount,
    linkColor = '',
    defaultValue = [],
    previewMode,
    showMore = false,
    ...rest
} = {}) => {
    if (!isReadyToMount) {
        return null
    }

    const [filter, setFilter] = useState(newsletterFilter)

    useEffect(() => {
        if (showMore) {
            setFilter(defaultValue.length ? FILTER_TYPES.preselected : FILTER_TYPES.free)
        }
    }, [])

    const filteredNewsletters = newsletters.filter((newsletter) => {
        if (newsletter === undefined || newsletter === null) {
            return false
        } else {
            const { id, type } = newsletter
            const freeNewsletters = type === FILTER_TYPES.free
            const paidNewsletters = type === FILTER_TYPES.paid
            if (filter === FILTER_TYPES.all) {
                return true
            }
    
            // If no default newsletter id is provided, we show only free newsletters
            if (filter === FILTER_TYPES.free || !defaultValue?.length) {
                return freeNewsletters
            }
    
            if (filter === FILTER_TYPES.preselected) {
                return freeNewsletters && defaultValue?.includes(id)
            }
    
            if (filter === FILTER_TYPES.paid) {
                return paidNewsletters
            }
    
            return false
        }
    })

    let disabledNewsletters = 0

    const Newsletters = filteredNewsletters.map((newsletter, index) => {
        const {
            enabled,
            type
        } = newsletter

        const isDisabled = (
            type !== FILTER_TYPES.free
            && enabled !== true
        )

        if (isDisabled) {
            disabledNewsletters++
        }

        // name is to support ChooseNewsletter, but the semantic naming should be label
        // because Field also takes a name prop
        const revisedNewsletter = {
            ...newsletter,
            label: newsletter.name
        }

        return (
            <Field
                name='newsletters'
                component={NewsletterCheckbox}
                type='checkbox'
                {...(required && {
                    validate: value => (value ? undefined : 'Required')
                })}
                className={cx({ [styles.checkboxMenu]: containerType === 'dropdown' })}
                disabled={isDisabled || disableGroup}
                value={newsletter?.id || newsletter?.value}
                key={index}
                isSingleColumn={isSingleColumn}
                linkColor={linkColor}
                newsletter={revisedNewsletter}
                showIcon={isDisabled}
                noTooltip={previewMode}
                previewMode={previewMode}
                {...rest}
            />
        )
    })

    if (filteredNewsletters.length - disabledNewsletters > 0) {
        onHasNewsletters()

        return (
            <Form.Field
                {...fieldContainerProps}
                style={{
                    marginBottom: 0
                }}
            >
                <p>{description}</p>
                <div>
                    {Newsletters}
                    {showMore && defaultValue.length && newsletters.length > defaultValue.length
                        ? (
                            <div
                                className={styles.showMore}
                                onClick={() => {
                                    setFilter(
                                        filter === FILTER_TYPES.preselected
                                            ? FILTER_TYPES.free
                                            : FILTER_TYPES.preselected
                                    )
                                }}
                            >
                                {(`Show ${filter === FILTER_TYPES.preselected ? 'more' : 'less'}`)}
                                <svg className={cx({ [styles.isInverse]: filter === 'free' })} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><g fill={`${linkColor || '#120A20'}`}><path d="M5.3 8.3C5.7 7.9 6.3 7.9 6.7 8.3L12 13.6 17.3 8.3C17.7 7.9 18.3 7.9 18.7 8.3 19.1 8.7 19.1 9.3 18.7 9.7L12.7 15.7C12.3 16.1 11.7 16.1 11.3 15.7L5.3 9.7C4.9 9.3 4.9 8.7 5.3 8.3Z" /></g></g></svg>
                            </div>
                        )
                        : ''
                    }
                </div>
            </Form.Field>
        )
    } else {
        onHasNoNewsletters()
    }

    return null
}

ChooseNewsletter.propTypes = {
    newsletters: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
        })
    ),
    containerType: PropTypes.string,
    disableGroup: PropTypes.bool,
    newsletterFilter: PropTypes.string,
    onHasNewsletters: PropTypes.func,
    onHasNoNewsletters: PropTypes.func,
    isSingleColumn: PropTypes.bool,
    isReadyToMount: PropTypes.bool.isRequired,
    defaultValue: PropTypes.array,
    linkColor: PropTypes.string,
    fieldContainerProps: PropTypes.shape({
        label: PropTypes.string,
        required: PropTypes.boolean
    }),
    description: PropTypes.string,
    required: PropTypes.func,
    previewMode: PropTypes.bool,
}

export default ChooseNewsletter
