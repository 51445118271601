import { put } from 'redux-saga/effects'

function* setClient({
    types,
    client,
}) {
    try {
        yield put({
            type: types.SET_CLIENT_SUCCESS,
            payload: client,
        })
    } catch (error) {
        yield put({
            type: types.SET_CLIENT_ERROR,
            error: error?.message || 'SET_CLIENT_ERROR',
        })
    }
}

export default setClient
