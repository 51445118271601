import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './P.module.scss'

const P = ({children, className, ...props}) => {
    return (
        <p className={cx(styles.p, className)} {...props}>
            {children}
        </p>
    )
}

P.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default P
