import { produce } from 'immer'

export const initialState = {
    isPaymentMethodUpdating: false,
    isPaymentMethodDeleting: false,
    isDefaultBeingSet: false,
    selectedPaymentMethod: null,
    paymentMethods: {},
    paymentMethodErrors: {},
}

const reducer = (
    state,
    action,
    duck,
) => {
    const { type, payload, error } = action

    return produce(state, (draftState) => {
        switch (type) {
            case duck.types.ADD_PAYMENT_METHOD:
                draftState.isPaymentMethodUpdating = true
                draftState.paymentMethods[action.token.id] = null
                break

            case duck.types.ADD_PAYMENT_METHOD_SUCCESS:
                draftState.isPaymentMethodUpdating = false
                draftState.paymentMethods[payload.token_id] = payload.tokenData || true
                break

            case duck.types.ADD_PAYMENT_METHOD_ERROR:
                draftState.isPaymentMethodUpdating = false
                draftState.paymentMethods[payload.token_id] = false
                draftState.paymentMethodErrors[payload.token_id] = error
                break

            case duck.types.DELETE_PAYMENT_METHOD:
                draftState.isPaymentMethodDeleting = true
                break

            case duck.types.DELETE_PAYMENT_METHOD_SUCCESS:
                draftState.isPaymentMethodDeleting = false
                break

            case duck.types.DELETE_PAYMENT_METHOD_ERROR:
                draftState.isPaymentMethodDeleting = false
                break

            case duck.types.SELECT_PAYMENT_METHOD:
                draftState.selectedPaymentMethod = payload.paymentMethod || null
                break

            case duck.types.SET_DEFAULT_PAYMENT_METHOD:
                draftState.isDefaultBeingSet = true
                break

            case duck.types.SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
                draftState.isDefaultBeingSet = false
                break

            case duck.types.SET_DEFAULT_PAYMENT_METHOD_ERROR:
                draftState.isDefaultBeingSet = false
                break

            default:
        // do nothing
        }
    })
}

export default reducer
