import fireEvent from './utils/fireEvent'

// Example Payload
// {
//     "type": "track",
//     "event": "landing_page:page_view",
//     "properties": {
//         "currentUser": null,
//         "landing_page_id": "24660c91-5a55-4a2d-aa2d-c7b89737bdf1",
//         "publisherId": "c9298a6f-566d-4507-9def-116384264538"
//     },
//     "options": {},
//     "userId": "userID",
//     "anonymousId": "8fe448a6-1e7a-434b-8374-2bb70c960e4f",
//     "meta": {
//         "rid": "54092386-e367-4c08-b689-f6b48b09647c",
//         "ts": 1634856456432,
//         "hasCallback": true
//     }
// }

const track = ({ config, instance, payload }) => {
    // console.log('track patload', payload)
    return fireEvent({ name: payload.event, config, instance, payload: payload.properties })
}

export default track
