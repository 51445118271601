import Regular from './Regular'
import Flexbox from './Flexbox'
import FlexboxPane from './FlexboxPane'
import Scrollable from './Scrollable'
import Grid from './Grid'
import GridPane from './GridPane'

const ContentPane = FlexboxPane //renamed

// duplicate imports to support legacy structure
export {
    Regular,
    Flexbox,
    FlexboxPane,
    ContentPane,
    Scrollable,
    Grid,
    GridPane
}
