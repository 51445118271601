import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PURCHASE_GIFT } from 'piconetworks/pkg-endpoints'

function* createGift({
    types,
    company_slug,
    custom_price,
    note,
    product_option_id,
    publisherId,
    recipient,
    sender,
    tier_id,
    redeemUrl,
    formId,
}) {
    try {
        const data = {
            form_id: formId,
            product_option_id,
            tier_id,
            custom_price,
            note,
            recipient,
            sender,
            adBlocking: false,
            origin: window.location.href,
            post_id: null,
            post_type: 'home',
            prev_page: '',
            publisherId,
            resource_ref: null,
            taxonomies: {},
            redeem_url: redeemUrl || `${process.env.MARKETING_SITE_URL}/${company_slug}/redeem-gift`
        }

        const response = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: PURCHASE_GIFT(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data,
            },
        })

        yield put({
            type: types.CREATE_GIFT_SUCCESS,
            payload: {
                data: response.data,
            },
        })
    } catch (error) {
        yield put({
            type: types.CREATE_GIFT_ERROR,
            error: error.message,
        })
    }
}

export default createGift
