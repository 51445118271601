import Joi from 'joi'

const DashboardCallToActionAccepted = Joi.object()
    .keys({
        cta_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cta session'),
        cta_id: Joi.string().allow(null).description('The id of the cta'),
        app_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the apps'),
        feature_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the features'),
    })
    .description('dashboard:call_to_action:accepted event attributes ')
    .meta({ name: 'DashboardCallToActionAccepted', filename: 'dashboard:call_to_action:accepted.model' })

export default DashboardCallToActionAccepted
