
import { call, put } from 'redux-saga/effects'
import {
    FORMS_LANDING_PAGE
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getDefaultLandingPage({
    types,
    payload: publisherName
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${FORMS_LANDING_PAGE()}/${publisherName}`,
            method: 'get',
        })

        if (data) {
            yield put({
                type: types.GET_DEFAULT_LANDING_PAGE_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch default landing page')
        }
    } catch (error) {
        yield put({
            type: types.GET_DEFAULT_LANDING_PAGE_ERROR,
            error: error?.message || 'GET_DEFAULT_LANDING_PAGE_ERROR',
        })
    }
}

export default getDefaultLandingPage
