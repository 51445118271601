import { takeEvery, select } from 'redux-saga/effects'

import Client from 'modules/client'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_LANDING_PAGE, sagas.getLandingPage),
    takeEvery(types.FETCH_LANDING_PAGE, sagas.fetchLandingPage),
    takeEvery(types.CHECK_IF_SHOW_OFFER_CODE, sagas.checkIfShowOfferCode),
    takeEvery(types.GET_LANDING_PAGE_ERROR, function* () {
        const client = yield select(Client.selectors.client)
        const companySlug = `@${client.username}`

        window.location = `/${companySlug}`
    }),
])

export default takes
