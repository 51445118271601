import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

import styles from './Link.module.scss'

const Link = React.forwardRef(({
    children,
    disabled,
    hideText,
    href,
    icon,
    className,
    openNewWindow,
    onClick,
    preset,
    variant,
    hrefClick,
    ...props
}, ref) => {
    if (onClick) {
        props.onClick = onClick
    } else if (href) {
        props.href = href

        if(hrefClick) {
            props.onClick = hrefClick
        }
    }

    if (openNewWindow) {
        props.rel='noopener noreferrer'
        props.target='_blank'
    }

    const classes = cx(
        styles.link,
        {
            [styles.disabled]: disabled,
            [styles.withIcon]: !!icon,
            [styles[`${preset}Preset`]]: preset
        },
        variant && `link-${variant}`,
        className
    )

    if (icon) {
        return (
            <a className={classes} {...props} ref={ref}>
                {icon} {children && <span className={cx(styles.text, { 'sr-only': hideText })}>{children}</span>}
            </a>
        )
    }

    return (
        <a className={classes} {...props} ref={ref}>
            {children}
        </a>
    )
})

Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    hideText: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.any,
    onClick: PropTypes.func,
    hrefClick: PropTypes.func,
    openNewWindow: PropTypes.bool,
    /** Style preset */
    preset: PropTypes.oneOf([
        'default',
        'pill'
    ]),
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
    ])
}

Link.defaultProps = {
    disabled: false,
    preset: 'default'
}

export default Link
