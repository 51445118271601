import { produce } from 'immer'

export const initialState = {
    error: false,
    data: {},
    loading: false,
}

const reducer = (
    state,
    { type, payload, error },
    { types }
) =>
    produce(state, (draftState) => {
        switch (type) {
            case types.GET_BLOCKS:
                draftState.error = false
                draftState.loading = true
                break

            case types.GET_BLOCKS_SUCCESS:
                draftState.error = false
                draftState.data[payload.formId] = payload.data
                draftState.loading = false
                break

            case types.GET_BLOCKS_ERROR:
                draftState.error = error
                draftState.loading = false
                break

            case types.UPSERT_BLOCK:
                const blocks = draftState?.data?.[payload.formId]
                if (blocks.length) {
                    const index = blocks.findIndex(block => block.id === payload.data.id || block.temp_id === payload.data.temp_id)
                    if (index !== -1) {
                        blocks[index] = payload.data
                    } else {
                        blocks.unshift(payload.data)
                    }
                }
                break

            default:
                return state
        }
    })

export default reducer
