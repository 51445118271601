import { call, put, select } from 'redux-saga/effects'
import {
    PURCHASE_SWITCH
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getSwitchProducts({
    types,
    productId,
    publisherId,
}) {
    try {
        if (!productId) {
            throw new Error('Invalid product id')
        }

        const state = yield select()

        const switchProducts = state?.purchase?.switchProducts

        const currentProductId = switchProducts.switchProductId

        if (currentProductId !== productId) {
            const { data } = yield call(picoAPIHelper, {
                url: `${PURCHASE_SWITCH()}/?current_product_id=${productId}`,
                method: 'get',
                headers: {
                    publisherid: publisherId,
                },
            })

            if (data?.switchable_products) {
                yield put({
                    type: types.GET_SWITCH_PRODUCTS_SUCCESS,
                    payload: {
                        data: data.switchable_products,
                        productId,
                    }
                })
            } else {
                throw new Error('unable to fetch switch products')
            }
        } else {
            const currProducts = switchProducts?.data

            yield put({
                type: types.GET_SWITCH_PRODUCTS_SUCCESS,
                payload: {
                    data: currProducts,
                    productId,
                }
            })
        }
    } catch (error) {
        yield put({
            type: types.GET_SWITCH_PRODUCTS_ERROR,
            error: error?.message || 'GET_SWITCH_PRODUCTS_ERROR',
        })
    }
}

export default getSwitchProducts
