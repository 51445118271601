export const onlyWarnInDevelopmentMode = (func) => {
    try {
        if (process.env.NODE_ENV === 'development') {
            return func()
        }
    } catch (error) {
        console.error(error)
    }
}

export const developmentLog = (message, type = 'warn', enable = false) => {
    if (enable) {
        console.group('propWarnings')
        console[type](message)
        console.log('💅 This is a development-only message. It will be removed in production.')
        console.groupEnd()
    }
}

export const warnOnFieldProps = (fieldProps = {}) => {
    const propsThatCanBePassedToFieldProps = ['className']
    Object.keys(fieldProps).forEach(prop => {
        if (!propsThatCanBePassedToFieldProps.includes(prop)) {
            developmentLog(`Passing a prop named '${prop}' to the fieldProps should only be if you want it to live on the Field-module__field DOM element`)
        }
    })
}

export const warnOfDeprecation = (props = {}) => {
    if (props['formFieldClassName']) {
        developmentLog('Passing a prop named \'formFieldClassName\' should be passed as a fieldProp={{ className: yourClassNameHere }} instead')
    }
}
