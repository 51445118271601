import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_SOCIAL_LINKS, sagas.getSocialLinks),
    takeEvery(types.CREATE_LINK, sagas.createLink),
    takeEvery(types.UPDATE_LINK, sagas.updateLink),
    takeEvery(types.DELETE_LINK, sagas.deleteLink),
])

export default takes
