import {
    put, putResolve,
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'

import {
    PICO_API_URL, USERS_PLAN
} from 'piconetworks/pkg-endpoints'

function* getPlans({
    types,
    publisherId = null,
    gift,
    switching,
}) {
    try {
        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: USERS_PLAN(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
            },
        })

        if (!data) {
            throw Error('no data returned')
        }

        const payload = {
            ...data,
            gift,
            switching,
        }


        yield put({
            type: types.GET_PLANS_SUCCESS,
            payload,
        })
    } catch (error) {
        yield put({
            type: types.GET_PLANS_ERROR,
            error: error.message,
        })
    }
}

export default getPlans
