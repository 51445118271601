import { getTemplateOrDefault } from '../utils'

import requiredRule from '../defaultTemplates/requiredRules'
import optionalRule from '../defaultTemplates/optionalRules'

export const retrieveRulePromptTitle = ({ ruleTemplates, required = false }) => {
    let templateString = ''
    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'prompt.title')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'prompt.title')
    }

    return templateString
}

export const retrieveRulePromptSubtitle = ({ ruleTemplates, required = false }) => {
    let templateString = ''

    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'prompt.subtitle')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'prompt.subtitle')
    }

    return templateString
}

export const retrieveRulePromptDescription = ({ ruleTemplates, required = false }) => {
    let templateString = ''

    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'prompt.description')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'prompt.description')
    }

    return templateString
}

export const retrieveRuleMenuTitle = ({ ruleTemplates, required = false }) => {
    let templateString = ''

    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'menu.title')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'menu.title')
    }

    return templateString
}

export const retrieveRuleMenuSubtitle = ({ ruleTemplates, required = false }) => {
    let templateString = ''

    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'menu.subtitle')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'menu.subtitle')
    }

    return templateString
}

export const retrieveRuleMenuAction = ({ ruleTemplates, required = false }) => {
    let templateString = ''

    if (required) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'menu.button')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'menu.button')
    }

    return templateString
}

export const retrieveRuleConfirmation = ({ ruleTemplates, userFirstName, required = false }) => {
    let templateString = ''

    if (required) {
        if (userFirstName) {
            templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'confirmationWithName')
        } else {
            templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'confirmationWithoutName')
        }
    } else if (userFirstName) {
        templateString = getTemplateOrDefault(ruleTemplates, requiredRule, 'confirmationWithName')
    } else {
        templateString = getTemplateOrDefault(ruleTemplates, optionalRule, 'confirmationWithoutName')
    }

    return templateString
}


export const getHeaderDescription = (loader) => {
    switch (loader && loader.pricing && loader.pricing.monetization && loader.pricing.monetization.type && loader.pricing.monetization.type) {
        case 'pass':
            return 'Already a pass holder?'
        case 'donation':
            return 'Already a donor?'
        case 'subscription':
            if (loader && loader.pricing && loader.pricing.monetization && loader.pricing.monetization.is_pledge) {
                return 'Already a member?'
            }
            return 'Already a subscriber?'

        case 'group':
            return 'Already have an account?'
        default:
            return 'Already have an account?'
    }
}
