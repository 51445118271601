import PropertyType from '../Modifiers/PropertyType'

const ThemePageBackgroundValue = new PropertyType({
    name: 'PageBackgroundValue',
    propertyFromApi: 'page-background-value',
},
'theme'
)

const FormPageBackgroundValue = new PropertyType({
    name: 'PageBackgroundValue',
    propertyFromApi: 'page-background-value'
},
'form'
)

export {
    ThemePageBackgroundValue,
    FormPageBackgroundValue,
}
