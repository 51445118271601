import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Span } from 'piconetworks/pkg-text'
import styles from './Badge.module.scss'

const Badge = ({as, caps, children, className, variant, ...props}) => {
    const Component = as || Span
    return (
        <Component className={cx(styles.badge, variant && styles[variant], caps && styles.caps, className)} {...props}>
            &nbsp;{children}
        </Component>
    )
}

Badge.propTypes = {
    as: PropTypes.any,
    caps: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'dark',
        'light',
        'link',
        'facebook',
        'github',
        'google',
        'linkedin',
        'twitter'
    ])
}

Badge.defaultProps = {
    caps: true
}

export default Badge
