import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Checkbox.module.scss'
import FormField, { FormFieldInputPropTypes, FormFieldPropTypes } from '../FormField'

const Checkbox = (props) => {
    const {
        disabled,
        indeterminated,
        text,
        className,
        label,
        input,
        meta,
        required,
        customTitleClass,
        description,
        customDescriptionClass,
        customContentClass,
        icon,
        outerClassName,
        innerClassName,
        style: { checkMarkInner = {} } = {},
        fieldProps,
        textColor,
        ...args
    } = props
    return (
        <FormField
            label={label}
            required={required}
            meta={meta}
            className={className}
            input={input}
            {...fieldProps}
        >
            <div
                className={classNames(styles.checkbox, {
                    [styles.disabled]: disabled
                })} {...args}
            >
                <input type="checkbox" className={styles.input} {...input} />
                <div
                    className={classNames(
                        styles.content,
                        {
                            [styles.disabled]: disabled
                        },
                        customContentClass
                    )}
                >
                    <div className={styles.checkMark}>
                        <div
                            className={classNames(
                                styles.checkMarkOuter,
                                {
                                    [styles.selected]:
                                        input.checked || indeterminated
                                },
                                outerClassName
                            )}
                        />
                        <div
                            className={classNames(styles.checkMarkInner, {
                                [styles.selected]:
                                    input.checked || indeterminated,
                                [innerClassName]: input.checked
                            })}
                            style={
                                input.checked
                                    ? checkMarkInner.checked
                                    : checkMarkInner.default
                            }
                        >
                            {input.checked ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fill="none"
                                        stroke="white" // $form-check-input-checked-color
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 8.5L6.5 11l6-6"
                                    />
                                </svg>
                            ) : indeterminated ? (
                                <span
                                    className={classNames(
                                        styles.indeterminate,
                                        styles.checkIcon
                                    )}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.checkBoxText,
                            customTitleClass
                        )}
                    >
                        {icon && icon}
                        <label
                            className={classNames(styles.title)}
                            style={textColor ? { color: textColor } : {}}
                        >
                            {text}
                        </label>
                    </div>
                    {description && (
                        <div
                            className={classNames(
                                styles.checkBoxText,
                                customDescriptionClass
                            )}
                        >
                            <label className={classNames(styles.description)}>
                                {description}
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </FormField>
    )
}

Checkbox.defaultProps = {
    disabled: false,
    indeterminated: false,
    innerClassName: '',
    text: ''
}

Checkbox.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customContentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customDescriptionClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customTitleClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.node,
    disabled: PropTypes.bool,
    fieldProps: PropTypes.shape(FormFieldPropTypes),
    input: PropTypes.shape(FormFieldInputPropTypes),
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func
    ]),
    indeterminated: PropTypes.bool,
    innerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** This will be deprecated eventually. Instead pass a label to the fieldProps */
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    name: PropTypes.string,
    outerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    required: PropTypes.bool,
    style: PropTypes.object,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
        PropTypes.node
    ]),
    textColor: PropTypes.string,
}

export default Checkbox
