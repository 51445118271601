// eslint-disable-next-line require-yield
import {
    putResolve,
    put,
    call,
} from 'redux-saga/effects'

import { REMOVE_TOKENS } from 'piconetworks/pkg-network-middleware'

import Claim from 'modules/claim'
import logger from 'lib/logger'

function* logoutAndExchangeClaim({ claim }) {
    try {
        if (!claim) {
            throw new Error('no claim in payload')
        }

        yield putResolve({ type: REMOVE_TOKENS })

        yield put(yield call(Claim.creators.exchangeClaim, {
            claim,
        }))
    } catch (error) {
        logger.error('logoutAndExchangeClaim', {}, error)
    }
}

export default logoutAndExchangeClaim
