import { all, call, put, takeEvery } from 'redux-saga/effects'

import account from './account'
import auth from './auth'
import claim from './claim'
import gift from './gift'
import loader from './loader'
import router from './router'
import payment from './payment'
import wizard from './wizard'
import plan from './plan'
import landingPage from './landing-page'
import client from './client'
import me from './me'
import paymentV2 from './paymentv2'
import article from './article'
import forms from './forms'
import purchase from './purchase'
import composedTakes from './composed-takes'
import text from './text'
import metrics from './metrics'
import downloads from './downloads'
import links from './links'
import utm from './utm'
import newsletter from './newsletter'
import blocks from './blocks'
import iframely from './iframely'
import featureFlags from './feature-flags'
import products from './products'
import apps from './apps'
import utils from './utils'

export default function* rootSaga() {
    yield all([
        ...apps.takes,
        ...account.takes,
        ...auth.takes,
        ...claim.takes,
        ...gift.takes,
        ...loader.takes,
        ...payment.takes,
        ...plan.takes,
        ...router.takes,
        ...wizard.takes,
        ...landingPage.takes,
        ...client.takes,
        ...me.takes,
        ...paymentV2.takes,
        ...article.takes,
        ...forms.takes,
        ...purchase.takes,
        ...composedTakes,
        ...text.takes,
        ...metrics.takes,
        ...downloads.takes,
        ...links.takes,
        ...utm.takes,
        ...newsletter.takes,
        ...blocks.takes,
        ...iframely.takes,
        ...featureFlags.takes,
        ...products.takes,
        ...utils.takes,

        takeEvery([
            loader.types.GET_LOADER_ERROR,
            auth.types.USE_REFRESH_TOKEN_ERROR,
        ], function* (...rest) {
            yield put(yield call(auth.creators.logout))
        }),
    ])
}
