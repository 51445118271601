import qs from 'qs'

import { put, putResolve } from 'redux-saga/effects'

import {
    REQUEST,
    STORE_TOKENS,
} from 'piconetworks/pkg-network-middleware'

import {
    PICO_API_URL,
    USERS_REGISTER_CONFIRM,
} from 'piconetworks/pkg-endpoints'

function* registerConfirm({
    payload: {
        email,
        publisherId,
        verification,
    },
    types,
}) {
    try {
        const response = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: USERS_REGISTER_CONFIRM(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    email,
                    newsletter_ids: [],
                    publisherId,
                    verification,
                },
            },
        })

        if (!response.data) {
            throw new Error('no response data')
        }

        if (response.data.error) {
            throw new Error(response.error)
        }

        if (!response.data.redirect_url) {
            throw new Error('no redirect_url')
        }

        const redirectUrl = response.data.redirect_url
        const splitRedirectUrl = redirectUrl.split('?')

        if (!splitRedirectUrl[1]) {
            throw new Error(`unable to split redirect URL ${redirectUrl}`)
        }

        const parsedRedirectUrl = qs.parse(splitRedirectUrl[1])

        if (!parsedRedirectUrl.pico_access_token || !parsedRedirectUrl.pico_refresh_token) {
            throw new Error(`unable to parse redirect URL: ${parsedRedirectUrl}`)
        }

        yield putResolve({
            type: STORE_TOKENS,
            access_token: parsedRedirectUrl.pico_access_token,
            refresh_token: parsedRedirectUrl.pico_refresh_token,
        })

        yield put({
            type: types.REGISTER_CONFIRM_SUCCESS,
            payload: {
                redirectUrl,
                accessToken: parsedRedirectUrl.pico_access_token,
                refreshToken: parsedRedirectUrl.pico_refresh_token,
            },
        })
    } catch (error) {

        yield put({
            type: types.REGISTER_CONFIRM_ERROR,
            error: error.message,
        })
    }
    //   try {
    //     if (!accessToken) {
    //       throw Error('access token is required for token login');
    //     }

    //     if (!refreshToken) {
    //       throw Error('refresh token is required for token login');
    //     }

    //     yield putResolve({
    //       type: STORE_TOKENS,
    //       access_token: accessToken,
    //       refresh_token: refreshToken,
    //     });

    //     yield put({
    //       type: types.TOKEN_LOGIN_SUCCESS,
    //       payload: {
    //         response: {},
    //       },
    //     });
    //   } catch (error) {

//     yield put({
//       type: types.TOKEN_LOGIN_ERROR,
//       error,
//     });
//   }
}

export default registerConfirm
