/* eslint-disable no-template-curly-in-string */
const donationPlan = {
    customerPromptWithoutName: {
        title: 'Will you renew your support?',
        subtitle: 'Continue funding the content you love.',
        description: null,
    },
    customerPromptWithName: {
        title: 'Will you renew your support, ${first_name}?',
        subtitle: 'Continue funding the content you love.',
        description: null,
    },
    nonDonorPromptWithoutName: {
        title: 'Thanks for being a loyal member of this community.',
        subtitle: 'Become a donor today to show your support.',
        description: null,
    },
    nonDonorPromptWithName: {
        title: 'Thanks for being a loyal member of this community, ${first_name}.',
        subtitle: 'Become a donor today to show your support.',
        description: null,
    },
    featureList: {
        show: false,
        title: null,
        list: null,
    },
    recurringConfirmationWithoutName:
        'Thank you for becoming a sustaining member of our community. Your gift is essential for funding great content on ${publication_name}. When you see our logo in this corner, you can click on it to manage your membership.',
    recurringConfirmationWithName:
        'Thank you for becoming a sustaining member of our community, ${first_name}. Your gift is essential for funding great content on ${publication_name}. When you see our logo in this corner, you can click on it to manage your membership.',
    singleConfirmationWithoutName:
        'Thank you for being a part of our community of supporters. Your gift is essential for funding great content on ${publication_name}.',
    singleConfirmationWithName:
        'Thank you for being a part of our community of supporters, ${first_name}. Your gift is essential for funding great content on ${publication_name}.',
}
/* eslint-enable no-template-curly-in-string */

export default donationPlan
