import generateAction from 'piconetworks/pkg-generate-action'

const setClient = generateAction({
    baseActionCreatorName: 'setClient',
    baseActionTypeName: 'SET_CLIENT',
})

const getClient = generateAction({
    baseActionCreatorName: 'getClient',
    baseActionTypeName: 'GET_CLIENT'
})

const types = [
    ...setClient.types,
    ...getClient.types,
]

const creators = (duck) => ({
    ...setClient.creators(duck),
    ...getClient.creators(duck),
})

export {
    types,
    creators,
}
