import React from 'react'
import classNames from 'classnames'

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

/**
 * Hoc component for merging default component stles to custom styles.
 * @param {Component} WrappedComponent - The Native Component.
 * @param {StyleClass} defaultStyle - The Native Component Default Style.
 */
function withHTMLElement(WrappedComponent, { defaultStyle }) {
    class WithHTMLElement extends React.Component {
        render() {
            // Wraps the input component in a container, without mutating it. Good!
            return (
                <WrappedComponent
                    {...this.props}
                    className={classNames(defaultStyle, this.props.className)}
                />
            )
        }
    }
    WithHTMLElement.displayName = `WithHTMLElement(${getDisplayName(
        WrappedComponent
    )})`
    WithHTMLElement.propTypes = WrappedComponent.propTypes
    WithHTMLElement.defaultProps = WrappedComponent.defaultProps

    return WithHTMLElement
}

export default withHTMLElement
