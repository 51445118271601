import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_PRODUCT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* reactivateProduct({
    types,
    payloadP: productOptionId
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${PURCHASE_PRODUCT()}/${productOptionId}`,
            method: 'put',
        })

        if (data) {
            yield put({
                type: types.REACTIVATE_PRODUCT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error(`unable to reactivate product, ${productOptionId}`)
        }
    } catch (error) {
        yield put({
            type: types.REACTIVATE_PRODUCT_ERROR,
            error: error?.message || 'REACTIVATE_PRODUCT_ERROR',
        })
    }
}

export default reactivateProduct
