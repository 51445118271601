const selectors = {
    isFetchingMarkdown: ({ text } = {}) => text?.isFetchingMarkdown,
    markdownFiles: ({ text } = {}) => text?.markdownFiles,
    isFetchingJson: ({ text } = {}) => text?.isFetchingJson,
    jsonFiles: ({ text } = {}) => text?.jsonFiles,
    getMarkdownFile: (state, url) => {
        const markdownFiles = selectors.markdownFiles(state)
        if(!url) {
            return null
        }
        return markdownFiles[url]
    },
    getJsonFile: (state, url) => {
        const jsonFiles = selectors.jsonFiles(state)
        if(!url) {
            return null
        }
        return jsonFiles[url]
    },
}

export default selectors