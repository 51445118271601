import Joi from 'joi'

const DashboardGuideClicked = Joi.object()
    .keys({
        guide_url: Joi.string().uri({ allowRelative: false }).description('URL of the trypico article/guide being clicked'),
    })
    .description('dashboard:guide:clicked event attributes ')
    .meta({ name: 'DashboardGuideClicked', filename: 'dashboard:guide:clicked.model' })

export default DashboardGuideClicked
