import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_PRODUCT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* purchaseProduct({
    types,
    payload
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${PURCHASE_PRODUCT()}/${payload.id}`,
            method: 'post',
            data: payload
        })

        if (data) {
            yield put({
                type: types.PURCHASE_PRODUCT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to purchase product')
        }
    } catch (error) {
        yield put({
            type: types.PURCHASE_PRODUCT_ERROR,
            error: error?.message || 'PURCHASE_PRODUCT_ERROR',
        })
    }
}

export default purchaseProduct
