import React from 'react'

export default ({ title = 'Edit Profile', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M19.996 19.55v.004A10.97 10.97 0 0 1 12 23a10.97 10.97 0 0 1-7.997-3.447A.003.003 0 0 0 4 19.55h.001L4 19.549V18c0-2.66 5.33-4 8-4s8 1.34 8 4v1.548c0 .001 0 .002-.002.002zM12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title={title}
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M19.996 19.55v.004A10.97 10.97 0 0 1 12 23a10.97 10.97 0 0 1-7.997-3.447A.003.003 0 0 0 4 19.55h.001L4 19.549V18c0-2.66 5.33-4 8-4s8 1.34 8 4v1.548c0 .001 0 .002-.002.002zM12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
            fill={color}
        />
    </svg>
)
