import Badge from './Badge'
import P from './P'
import Span from './Span'
import Small from './Small'
import Tiny from './Tiny'
import Pre from './Pre'
import Code from './Code'

export {
    Badge,
    P,
    Span,
    Small,
    Tiny,
    Pre,
    Code
}
