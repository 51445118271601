import { call, put } from 'redux-saga/effects'
import {
    PURCHASE_GIFT
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* getGift({
    types,
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: PURCHASE_GIFT(),
            method: 'get'
        })

        if (data) {
            yield put({
                type: types.GET_GIFT_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to fetch gifts')
        }
    } catch (error) {
        yield put({
            type: types.GET_GIFT_ERROR,
            error: error?.message || 'GET_GIFT_ERROR',
        })
    }
}

export default getGift
