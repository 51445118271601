import { produce } from 'immer'

export const initialState = {
    files: [],
    error: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_DOWNLOADABLE_FILES:
            draftState.files = initialState.files
            draftState.error = initialState.error
            break

        case duck.types.GET_DOWNLOADABLE_FILES_SUCCESS:
            draftState.files = payload
            break
            
        case duck.types.GET_DOWNLOADABLE_FILE_ERROR:
        case duck.types.GET_DOWNLOADABLE_FILES_ERROR:
            draftState.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
