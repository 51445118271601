import { Claim, exchangeClaimFactory } from 'piconetworks/pkg-module-claim'

// eslint-disable-next-line import/no-cycle
import createDuck from '../baseDuck'

const claimModule = Claim({
    createDuck,
})

const exchangeClaim = exchangeClaimFactory && exchangeClaimFactory(claimModule)

export default claimModule

export {
    exchangeClaim,
}
