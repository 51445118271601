import Joi from 'joi'

const DashboardBlastErrored = Joi.object()
    .keys({
        blast_error: Joi.string().allow(null).description('The error of the blast sent action'),
    })
    .description('dashboard:blast:errored event attributes')
    .meta({ name: 'DashboardBlastErrored', filename: 'dashboard:blast:errored.model' })

export default DashboardBlastErrored
