import CURRENCIES from './currencies'

const normalize = ({ currencyCode = 'USD', amount }) => {
    amount = parseFloat(amount) || 0

    try {
        currencyCode = (currencyCode ?? 'USD').toLocaleUpperCase()
    } catch (error) {
        console.error('Error with converting currency code', error)
        currencyCode = 'USD'
    }

    if (!CURRENCIES[currencyCode]) {
        currencyCode = 'USD'
    }

    const { decimal_digits } = CURRENCIES[currencyCode]

    const precision = Math.pow(10, decimal_digits)

    // Round before parsing to correct any floating-point inaccuracies
    return parseInt(Math.round(amount * precision), 10)
}

export default normalize
