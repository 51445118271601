const generateAction = (slug, baseActionName) => {
    const actions = {
        baseAction: {
            type: baseActionName,
            actionCreatorName: slug,
            creator: (duck) => ({ payload }) => ({
                type: duck.types[baseActionName],
                ...payload,
            }),
        },
        successAction: {
            type: `${baseActionName}_SUCCESS`,
            actionCreatorName: `${slug}Success`,
            creator: (duck) => ({ payload }) => ({
                type: duck.types[`${baseActionName}_SUCCESS`],
                payload,
            }),
        },
        errorAction: {
            type: `${baseActionName}_ERROR`,
            actionCreatorName: `${slug}Error`,
            creator: (duck) => ({ payload, error }) => ({
                type: duck.types[`${baseActionName}_ERROR`],
                payload,
                error,
            }),
        },
    }

    const actionsArray = Object.values(actions)

    const types = actionsArray.map(({ type }) => type)

    const creators = (duck) => actionsArray.reduce((acc, { creator, actionCreatorName }) => {
        /* eslint-disable-next-line no-param-reassign */
        acc[actionCreatorName] = creator(duck)
        return acc
    }, {})

    return {
        types,
        creators,
    }
}

export default generateAction
