import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import '../Heading.module.scss'

const H1 = ({children, className, ...props}) => {
    return (
        <h1 className={cx(className)} {...props}>{children}</h1>
    )
}

H1.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default H1
