import {
    RESET,
    CLOSE_MENU,
    GO_TO_MENU_ROUTE_AND_OPEN,
    OPEN_MENU,
    TOGGLE_MENU,
    GO_TO_MENU_ROUTE  ,
} from './actionTypes'

export const closeMenu = (payload = {}) => {
    return {
        type: CLOSE_MENU,
        payload,
    }
}

export const goToMenuRouteAndOpen = (payload = {}) => {
    return {
        type: GO_TO_MENU_ROUTE_AND_OPEN,
        payload,
    }
}

export const resetMenu = (payload = {}) => {
    return {
        type: RESET,
        payload,
    }
}

export const openMenu = (payload = {}) => {
    return {
        type: OPEN_MENU,
        payload,
    }
}

export const toggleMenu = (payload = {}) => {
    return {
        type: TOGGLE_MENU,
        payload,
    }
}

export const goToMenuRoute = (payload = {}) => {
    return {
        type: GO_TO_MENU_ROUTE,
        payload,
    }
}
