import fireEvent from './utils/fireEvent'

// Example Payload
// {
//     "type":"page",
//     "properties":{
//         "title": "Global Headlines | Hype",
//         "url":"https://trypico.dev.pico.tools/globalheadlines/login/enter-email?id=LoginWizard_globalheadlines",
//         "path":"/globalheadlines/login/enter-email",
//         "hash":"",
//         "search":"?id=LoginWizard_globalheadlines",
//         "width":1920,
//         "height":487,
//         "referrer":"https://trypico.dev.pico.tools/globalheadlines"
//     },
//     "options":{},
//     "userId":"userID",
//     "anonymousId":"8fe448a6-1e7a-434b-8374-2bb70c960e4f",
//     "meta":{
//         "rid":"e5f55441-d4a9-473c-a450-5be500f28381",
//         "ts":1634856456459,
//         "hasCallback":true
//     }
// }

const page = ({ config, instance, payload }) => {
    return fireEvent({ name: 'page_view', config, instance, payload: payload.properties })
}

export default page
