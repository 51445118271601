import React from 'react'

export default ({
    color = 'currentcolor',
    width = '56px',
    height = '56px'
}) => (
    <svg
        height={height}
        width={width}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 280 280"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M180 260.876l-37.468-13.625A16 16 0 0 1 132 232.215V155.42L26.638 59.637C9.672 44.213 0 22.928 0 0h180v260.876zm36 13.091V0h32c8.837 0 16 7.163 16 16v264a8 8 0 0 1-10.734 7.518L216 273.967z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
