import platform from 'platform'
import analyticsModule from '../../module'

const actionWrapper = (action_creator) => ({
    payload: {
        properties: {
            ...properties
        },
        ...rest
    },
    instance: {
        dispatch
    }
}) => dispatch(action_creator({
    properties: {
        ...properties,
        platform
    },
    ...rest,
}))

// const actionWrapper = (action_creator) => (payload) => { console.log('iuwneifun', payload)}

function analyticsPluginDispatchAction() {
    return {
        name: 'analytics-plugin-dispatch-action',
        track: actionWrapper(analyticsModule.creators.trackEvent),
        identify: actionWrapper(analyticsModule.creators.identifyUser),
        page: actionWrapper(analyticsModule.creators.pageVisit),
        reset: actionWrapper(analyticsModule.creators.reset),
        guest: actionWrapper(analyticsModule.creators.setGuest),
        user: actionWrapper(analyticsModule.creators.setUser),
    }
}

export default analyticsPluginDispatchAction
