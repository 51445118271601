import React from 'react'

export default ({ title = 'brand/wordpress' }) => (
    <svg
        height="64px"
        width="64px"
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="Brands"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <g id="brand/wordpress">
                <polygon
                    id="bounds"
                    fill="#00749A"
                    points="0 0 64 0 64 64 0 64"
                />
                <g
                    id="WordPress"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    transform="translate(8.000000, 8.000000)"
                >
                    <path
                        id="Shape"
                        d="M3.3982439,23.9063415 C3.3982439,32.0241951 8.11590244,39.0396098 14.956878,42.3640976 L5.17385366,15.5598049 C4.03590244,18.110439 3.3982439,20.9330732 3.3982439,23.9063415 Z"
                    />
                    <path
                        id="Shape"
                        d="M37.7521951,22.8714146 C37.7521951,20.3367805 36.8417561,18.5814634 36.060878,17.2152195 C35.0212683,15.5258537 34.0468293,14.0952195 34.0468293,12.4058537 C34.0468293,10.5205854 35.4766829,8.76565854 37.4907317,8.76565854 C37.5816585,8.76565854 37.6679024,8.77697561 37.7564878,8.78204878 C34.1077073,5.43921951 29.246439,3.3982439 23.907122,3.3982439 C16.7422439,3.3982439 10.4386341,7.07434146 6.7715122,12.6423415 C7.25268293,12.6567805 7.70614634,12.6669268 8.09131707,12.6669268 C10.2364878,12.6669268 13.5570732,12.4066341 13.5570732,12.4066341 C14.6626341,12.3414634 14.7929756,13.9652683 13.6885854,14.096 C13.6885854,14.096 12.577561,14.2267317 11.3412683,14.2915122 L18.8097561,36.5061463 L23.2979512,23.0454634 L20.1026341,14.2907317 C18.9982439,14.2259512 17.952,14.0952195 17.952,14.0952195 C16.8468293,14.030439 16.9763902,12.3406829 18.081561,12.4058537 C18.081561,12.4058537 21.4684878,12.6661463 23.4837073,12.6661463 C25.6284878,12.6661463 28.9494634,12.4058537 28.9494634,12.4058537 C30.0558049,12.3406829 30.1857561,13.9644878 29.0809756,14.0952195 C29.0809756,14.0952195 27.9676098,14.2259512 26.7336585,14.2907317 L34.1451707,36.3371707 L36.1908293,29.5012683 C37.0774634,26.6645854 37.7521951,24.627122 37.7521951,22.8714146 Z"
                    />
                    <path
                        id="Shape"
                        d="M24.2669268,25.7002927 L18.113561,43.580878 C19.9508293,44.1209756 21.8938537,44.4163902 23.907122,44.4163902 C26.2954146,44.4163902 28.5857561,44.0035122 30.7176585,43.2538537 C30.6626341,43.1660488 30.6126829,43.0727805 30.5717073,42.9713171 L24.2669268,25.7002927 Z"
                    />
                    <path
                        id="Shape"
                        d="M41.9028293,14.0667317 C41.9910244,14.72 42.0409756,15.4212683 42.0409756,16.1756098 C42.0409756,18.2567805 41.6522927,20.5962927 40.481561,23.521561 L34.2169756,41.6339512 C40.3141463,38.078439 44.4152195,31.4727805 44.4152195,23.9067317 C44.4156098,20.3410732 43.5047805,16.9880976 41.9028293,14.0667317 Z"
                    />
                    <path
                        id="Shape"
                        d="M23.907122,0 C10.7250732,0 0,10.7242927 0,23.9063415 C0,37.0899512 10.7250732,47.8138537 23.907122,47.8138537 C37.0887805,47.8138537 47.8154146,37.0899512 47.8154146,23.9063415 C47.8150244,10.7242927 37.0887805,0 23.907122,0 Z M23.907122,46.7180488 C11.329561,46.7180488 1.09619512,36.4846829 1.09619512,23.9063415 C1.09619512,11.3287805 11.3291707,1.09619512 23.907122,1.09619512 C36.4842927,1.09619512 46.716878,11.3287805 46.716878,23.9063415 C46.716878,36.4846829 36.4842927,46.7180488 23.907122,46.7180488 Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
