const types = [
    'SET_UTM_PARAMETERS',
]

const creators = (duck) => ({
    setUtmParameters: (payload) => ({
        type: duck.types.SET_UTM_PARAMETERS,
        data: payload,
    }),
})

export {
    types,
    creators,
}
