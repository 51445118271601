import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Margin.module.scss'

const Margin = ({ children, className, preset, ...props}) => {
    return (
        <div
            className={cx(
                styles.margin,
                styles[`${preset}MarginPreset`],
                className
            )}
            {...props}
        >
            {children}
        </div>
    )
}

Margin.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Required preset to ensure some normalcy */
    preset: PropTypes.oneOf(['default', 'modal', 'spacious', 'narrow']).isRequired
}

Margin.defaultProps = {
    preset: 'default'
}

export default Margin
