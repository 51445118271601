const isTrialSelected = ({ state = {}, router = {} } = {}) => {
    const { loader = {}, wizard = {} } = state
    const scopedLoader = loader.user || loader.guest

    if (!scopedLoader) {
        return null
    }

    const { query = {} } = router
    const { company_slug, creator_username } = query

    if (!company_slug) {
        return null
    }

    const { wizards } = wizard

    const checkoutWizardName = `CheckoutWizard_${creator_username}`
    if (wizards[checkoutWizardName]) {
        const { tiers } = scopedLoader.pricing.monetization
        const optionId = wizards[checkoutWizardName].submit.payload.plan_option_id
        const tierId = wizards[checkoutWizardName].submit.payload.tier_id
        const tier = Array.isArray(tiers) && tiers.find((t) => t.id === tierId) || {}
        const option = Array.isArray(tier.options) && tier.options.find((op) => op.plan_option_id === optionId) || {}
        return Boolean(option.trial_interval)
    }

    return false
}

export default isTrialSelected
