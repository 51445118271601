/**
 * this is the old version of Icon component
 */

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const VariantIcon = ({
    icon,
    height = '56',
    width = '56',
    className,
    variant,
    ...props
}) => {
    if (React.isValidElement(icon)) {
        return (
            <React.Fragment>
                {React.cloneElement(icon, {
                    height,
                    width,
                    className: cx(variant, className),
                    ...props
                })}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {React.createElement(icon, {
                height,
                width,
                className: cx(variant, className),
                ...props
            })}
        </React.Fragment>
    )
}

VariantIcon.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    variant: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default VariantIcon
