import Root from './Root'
import EditProfile from './EditProfile'
import EditName from './EditProfile.EditName'
import EditEmail from './EditProfile.EditEmail'
import EditPassword from './EditProfile.EditPassword'
import DeleteAccount from './EditProfile.DeleteAccount'
import EditPhoneNumber from './EditProfile.EditPhoneNumber'
import Otp from './EditProfile.Otp'
import LogOut from './LogOut'
import ManageUpdates from './ManageUpdates'
import Notification from './Notification'
import NotificationCancelPlan from './Notification.CancelPlan'
import NotificationKeepPlan from './Notification.KeepPlan'
import NotificationSwitchPlanSuccess from './Notification.SwitchPlanSuccess'
import ManagePlan from './ManagePlan'
import CancelPlan from './ManagePlan.CancelPlan'
import Donation from './ManagePlan.Donation'
import Change from './ManagePlan.Donation.Change'
import Gift from './ManagePlan.Gift'
import Membership from './ManagePlan.Membership'
import Pass from './ManagePlan.Pass'
import SwitchPlan from './ManagePlan.SwitchPlan'
import Subscription from './ManagePlan.Subscription'
import Finished from './ManagePlan.Subscription.Finished'
import StopSubscription from './ManagePlan.Subscription.StopSubscription'
import Login from './Login'
import LoginOtp from './Login.Otp'
import LoginError from './Login.Error'
import ManageNewsletters from './ManageNewsletters'

const MenuRoutes = {
    Root,
    EditProfile: {
        Root: EditProfile,
        EditName,
        EditEmail,
        EditPassword,
        EditPhoneNumber,
        DeleteAccount,
        Otp
    },
    Login: {
        Root: Login,
        Otp: LoginOtp,
        Error: LoginError
    },
    LogOut: {
        Root: LogOut,
    },
    ManageUpdates: {
        Root: ManageUpdates,
    },
    ManageNewsletters: {
        Root: ManageNewsletters,
    },
    Notification: {
        Root: Notification,
        CancelPlan: NotificationCancelPlan,
        KeepPlan: NotificationKeepPlan,
        SwitchPlanSuccess: NotificationSwitchPlanSuccess,
    },
    ManagePlan: {
        Root: ManagePlan,
        CancelPlan,
        Donation: {
            Root: Donation,
            Change,
        },
        Gift,
        Membership,
        Pass,
        SwitchPlan,
        Subscription: {
            Root: Subscription,
            Finished,
            StopSubscription,
        },
    },
}

export default MenuRoutes
