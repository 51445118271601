import produce from 'immer'

export const initialState = {
    asPath: null,
    basePath: null,
    pathname: null,
    query: null,
    route: null,
}

const reducer = (
    state,
    { type, payload },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.PUSH:
            break

        case duck.types.UPDATE:
            draftState.asPath = payload.asPath
            draftState.basePath = payload.basePath
            draftState.pathname = payload.pathname
            draftState.query = payload.query
            draftState.route = payload.route
            break

        default:
      // do nothing
    }
})

export default reducer
