import getGift from './getGift'
import createGift from './createGift'
import deleteGift from './deleteGift'
import updateGift from './updateGift'

const getGiftSagas = (duck) => ({
    * getGift(payload) {
        yield getGift({ ...payload, ...duck })
    },
    * createGift(payload) {
        yield createGift({ ...payload, ...duck })
    },
    * deleteGift(payload) {
        yield deleteGift({ ...payload, ...duck })
    },
    * updateGift(payload) {
        yield updateGift({ ...payload, ...duck })
    },
})

export default getGiftSagas
