import React from 'react'

export default ({ color = 'currentcolor', width = '24', height = '24' }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 17a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm-2.5-.67a1 1 0 01.366 1.366l-1.5 2.598a1 1 0 11-1.732-1l1.5-2.598A1 1 0 019.5 16.33zm6.366.366l1.5 2.598a1 1 0 11-1.732 1l-1.5-2.598a1 1 0 011.732-1zM7.67 14.5a1 1 0 01-.366 1.366l-2.598 1.5a1 1 0 11-1-1.732l2.598-1.5a1 1 0 011.366.366zM12 2c5.523 0 10 4.477 10 10a9.954 9.954 0 01-1.34 5.005L13 12.581a2 2 0 01-1-1.732V2zm-6 9a1 1 0 010 2H3a1 1 0 010-2h3zM4.706 6.634l2.598 1.5a1 1 0 11-1 1.732l-2.598-1.5a1 1 0 111-1.732zm3.66-2.928l1.5 2.598a1 1 0 11-1.732 1l-1.5-2.598a1 1 0 111.732-1z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
