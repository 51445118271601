import React from 'react'

export default ({ color = '#FF4440', width = '24', height = '24' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd">
            <path
                d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z"
                fill={color}
            />
            <path
                d="M12,14.098 C12.45,14.098 12.774,13.792 12.828,13.306 L13.602,6.538 C13.656,6.016 13.368,5.62 12.882,5.62 L11.118,5.62 C10.632,5.62 10.344,6.016 10.398,6.538 L11.172,13.306 C11.226,13.792 11.55,14.098 12,14.098 Z M12,18.382 C12.9,18.382 13.566,17.716 13.566,16.852 L13.566,16.816 C13.566,15.952 12.9,15.286 12,15.286 C11.1,15.286 10.434,15.952 10.434,16.816 L10.434,16.852 C10.434,17.716 11.1,18.382 12,18.382 Z"
                fill="#fff"
            />
        </g>
    </svg>
)
