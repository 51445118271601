import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import isEmpty from 'lodash.isempty'

const selectors = () => ({
    me: new Duck.Selector(() => (state) => state?.me),
    getCurrentUser: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data
    )),
    getError: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ error } = {}) => error
    )),
    userFullName: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.full_name
    )),
    userFirstName: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.first_name
    )),
    userLastName: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.last_name
    )),
    userEmail: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.email?.value || data?._email?.value || data?.email
    )),
    userEmailIsShared: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.email?.shared || data?._email?.shared
    )),
    userPhone: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.phone?.value || data?._phone?.value || data?.phone
    )),
    userPhoneIsShared: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.phone?.shared || data?._phone?.shared
    )),
    userId: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.id
    )),
    userStateId: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.user_state_id
    )),
    userHasPassword: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.has_password
    )),
    userAvatarUrl: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.avatar_url
    )),
    userHasMultipleStates: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.has_multiple_user_state
    )),
    userInitials: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => {
            const userInitials = data?.initials || ''
            const email = data?.email?.value || data?._email?.value
            const isEmailShared = data?.email?.shared || data?._email?.shared
            return isEmpty(data?.initials) && email && isEmailShared ? email[0] : userInitials
        }
    )),
    userVerified: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.verified
    )),
    userSyncNewsletters: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.sync
    )),
    getUserNewslettersData: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ newsletters } = {}) => newsletters?.data
    )),
    getUserNewslettersLoading: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ newsletters } = {}) => newsletters?.loading
    )),
    getUserNewslettersError: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ newsletters } = {}) => newsletters?.error
    )),
    getUserPropertiesData: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ properties } = {}) => properties?.data
    )),
    getUserPropertiesLoading: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ properties } = {}) => properties?.loading
    )),
    getUserPropertiesError: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ properties } = {}) => properties?.error
    )),
    userSMSOptIn: new Duck.Selector((selectors) => createSelector(
        selectors.me,
        ({ data } = {}) => data?.sms_opt_in
    )),
})

export default selectors
