import React from 'react'

export default ({ width = '56px', height = '56px' }) => (
    <svg width={width} height={height} viewBox="0 0 420 420" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>brandmark/white/circle</title>
        <g id="brandmark/white/circle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect id="bounds" fill="#7A34FF" x="0" y="0" width="420" height="420" />
            <path
                d="M230.363636,309.791378 L200.420328,298.88779 C195.364691,297.046825 192,292.240777 192,286.860385 L192,225.390656 L192,225.390656 L107.790094,148.729949 C94.2304527,136.385894 86.5,119.350757 86.5,101 L230.363636,101 L230.363636,309.791378 Z M259.136364,320.268709 L259.136364,101 L284.7,101 C291.769245,101 297.5,106.730755 297.5,113.8 L297.5,325.096869 C297.5,328.631491 294.634622,331.496869 291.1,331.496869 C290.353106,331.496869 289.611976,331.36613 288.910164,331.110571 L259.136364,320.268709 L259.136364,320.268709 Z"
                id="Picrow"
                fill="#FFFFFF"
            />
        </g>
    </svg>
)
