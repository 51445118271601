import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import Tooltip from 'piconetworks/pkg-tooltip'
import Form from 'piconetworks/pkg-form'
import styles from './scss/ChooseNewsletter.module.scss'

const NewsletterCheckbox = ({
    className,
    disabled,
    isSingleColumn = false,
    newsletter,
    previewMode,
    showIcon,
    input,
    meta,
    noTooltip,
    ...props
}) => {
    const FieldEl = (
        <div className={styles.field}>
            <Form.Checkbox
                text={newsletter?.label}
                description={newsletter?.description}
                type="checkbox"
                disabled={disabled}
                className={className}
                customTitleClass={styles.checkboxTitle}
                customDescriptionClass={styles.checkboxDesc}
                customContentClass={styles.checkboxContent}
                outerClassName={styles.outerClassName}
                icon={showIcon ? `${process.env.S3_BUCKET}/landing_pages/img/lock_icon.svg` : undefined}
                input={{
                    ...input,
                    ...previewMode && {
                        readOnly: true,
                        checked: newsletter?.checked,
                    },
                }}
                meta={{
                    ...meta
                }}
                {...props}
            />
            {previewMode && <div className={styles.blockOverlay} />}
        </div>

    )

    return (
        <div
            className={cx(
                styles.chooseNewsletterItem,
                {
                    [styles.disabled]: disabled,
                    [styles.singleColumn]: isSingleColumn
                },
            )}
        >
            {
                disabled && !noTooltip ? (
                    <Tooltip
                        className={styles.tooltip}
                        content="Paid membership required."
                        theme={null}
                        placement="top-start"
                    >
                        {FieldEl}
                    </Tooltip>
                ) : FieldEl
            }
        </div>
    )
}

NewsletterCheckbox.defaultProps = {
    input: {},
    meta: {},
    name: 'newsletters',
    previewMode: false,
    showIcon: false
}

NewsletterCheckbox.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    input: PropTypes.object,
    isSingleColumn: PropTypes.bool,
    meta: PropTypes.object,
    name: PropTypes.string.isRequired,
    newsletter: PropTypes.shape({
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        id: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        value: PropTypes.string,
    }).isRequired,
    previewMode: PropTypes.bool,
    showIcon: PropTypes.bool,
    noTooltip: PropTypes.bool,
}

export default NewsletterCheckbox
