import generateAction from 'piconetworks/pkg-generate-action'

const getDefaultLandingPage = generateAction({
    baseActionCreatorName: 'getDefaultLandingPage',
    baseActionTypeName: 'GET_DEFAULT_LANDING_PAGE',
})

const getLandingPage = generateAction({
    baseActionCreatorName: 'getLandingPage',
    baseActionTypeName: 'GET_LANDING_PAGE',
})

const getDefaultPopup = generateAction({
    baseActionCreatorName: 'getDefaultPopup',
    baseActionTypeName: 'GET_DEFAULT_POPUP',
})

const getPopup = generateAction({
    baseActionCreatorName: 'getPopup',
    baseActionTypeName: 'GET_POPUP',
})

const types = [
    ...getDefaultLandingPage.types,
    ...getLandingPage.types,
    ...getDefaultPopup.types,
    ...getPopup.types,
]

const creators = (duck) => ({
    ...getDefaultLandingPage.creators(duck),
    ...getLandingPage.creators(duck),
    ...getDefaultPopup.creators(duck),
    ...getPopup.creators(duck),
})

export {
    types,
    creators,
}
