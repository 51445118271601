import React from 'react'

const ClaimIcon = () => (
    <svg 
        width="20" 
        height="20" 
        viewBox="0 0 20 20" 
        fill='none' 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M8.3745 17.7521L10 16.8136L11.6255 17.7521C11.7653 17.8328 11.915 17.8949 12.0709 17.9366C13.0559 18.2005 14.0683 17.616 14.3322 16.631L14.8179 14.8179L16.631 14.3322C16.7869 14.2904 16.9367 14.2284 17.0764 14.1477C17.9595 13.6378 18.262 12.5086 17.7521 11.6255L16.8136 10L17.7521 8.3745C17.8328 8.23472 17.8949 8.08496 17.9366 7.92906C18.2005 6.94408 17.616 5.93169 16.631 5.66781L14.8179 5.18208L14.3322 3.36901C14.2904 3.2131 14.2284 3.06334 14.1477 2.92356C13.6378 2.04049 12.5086 1.73797 11.6255 2.24786L10 3.18644L8.3745 2.24786C8.23472 2.16715 8.08496 2.10512 7.92905 2.06335C6.94408 1.79947 5.93169 2.38404 5.66781 3.36901L5.18208 5.18208L3.36901 5.66781C3.2131 5.70958 3.06334 5.77161 2.92356 5.85232C2.04049 6.36221 1.73797 7.49143 2.24786 8.3745L3.18643 10L2.24786 11.6255C2.16715 11.7653 2.10512 11.915 2.06335 12.0709C1.79947 13.0559 2.38403 14.0683 3.36901 14.3322L5.18208 14.8179L5.66781 16.631C5.70958 16.7869 5.77161 16.9367 5.85232 17.0764C6.36221 17.9595 7.49143 18.262 8.3745 17.7521ZM6.03425 9.44235C5.72059 9.75601 5.72304 10.2591 6.03487 10.571L8.10563 12.6417C8.42003 12.9561 8.9232 12.9534 9.23522 12.6414L13.7053 8.17132C14.014 7.8626 14.0177 7.35204 13.7063 7.03835C13.3926 6.72246 12.8914 6.72123 12.5819 7.0307L8.67025 10.9423L7.16567 9.44418C6.85312 9.13251 6.34574 9.13086 6.03425 9.44235Z" 
            fill="var(--page-button-contrast-color)"
        />
    </svg>
)

export default ClaimIcon