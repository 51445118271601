import getSocialLinks from './getSocialLinks'
import createLink from './createLink'
import updateLink from './updateLink'
import deleteLink from './deleteLink'

const sagas = (duck) => ({
    * getSocialLinks(payload) {
        yield getSocialLinks({
            ...payload,
            ...duck,
        })
    },
    * createLink(payload) {
        yield createLink({
            ...payload,
            ...duck,
        })
    },
    * updateLink(payload) {
        yield updateLink({
            ...payload,
            ...duck,
        })
    },
    * deleteLink(payload) {
        yield deleteLink({
            ...payload,
            ...duck,
        })
    },
})

export default sagas
