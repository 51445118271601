export const PICO_API_URL = () => process.env.PICO_API_URL
export const LANG_URL = () => 'https://lang.pico.tools'

export const GIFTS = () => '/gifts'

export const GROUPS_USER = () => '/groups/user'

export const NEWSLETTERS_SUBSCRIBE = () => '/newsletters/subscribe'

export const OAUTH_USER = () => '/oauth/user'
export const OAUTH_USER_LOGIN = () => '/oauth/user/login'
export const OAUTH_USER_TOKEN = () => '/oauth/user/token'
export const OAUTH_USER_LINK = () => '/oauth/user/link'
export const OAUTH_USER_RESET = () => '/oauth/user/reset'

export const OAUTH_PUBLISHER = () => '/oauth/publisher'
export const OAUTH_PUBLISHER_LOGIN = () => '/oauth/publisher/login'
export const OAUTH_PUBLISHER_TOKEN = () => '/oauth/publisher/token'
export const OAUTH_PUBLISHER_LINK = () => '/oauth/publisher/link'

export const PURCHASE_PLAN = () => '/purchase/plan'
export const PURCHASE_SWITCH = () => '/purchase/switch'
export const PURCHASE_PLAN_GROUP = () => '/purchase/plan/group'
export const PURCHASE_PLAN_REACTIVATE = () => '/purchase/plan/reactivate'

export const LOADER = () => '/loader'
export const LOADER_GUEST = () => '/loader/guest'

export const LANDING_PAGE_DYNAMIC = ({ publisherName }) => `/landingPage/${publisherName}`
export const LANDING_PAGE_SIGNUP_MODE = ({ publisherName }) => `/landingPage/${publisherName}/confirm`
export const LANDING_PAGE_GROUP = ({ publisherName, shortCode }) => `/landingPage/${publisherName}/group/${shortCode}`
export const LANDING_PAGE_STATIC = ({ publisherName, shortCode }) => `/landingPage/${publisherName}/${shortCode}`

export const PLANS_DISCOUNT = () => '/plans/discount'

export const USERS = ({ id } = {}) => (!id ? '/users' : `/users/${id}`)
export const USERS_EMAIL = () => '/users/email'
export const USERS_PAYMENT = () => '/users/payment'
export const USERS_PAYMENT_DEFAULT = () => '/users/payment/default'
export const USERS_PASSWORD = () => '/users/password'
export const USERS_REGISTER_CONFIRM = () => 'users/register/confirm'

export const HUBSPOT = () => '/publisher/hubspot/setup'

export const SIGNUP = () => '/setup/signup'

export const SETUP = () => '/publisher'
export const SETUP_EMAIL_CHECK = () => '/setup/email'
export const SETUP_PASSWORD_CHECK = () => '/setup/password'
export const SETUP_TWITTER = () => '/setup/twitter'
export const SETUP_INSTAGRAM = () => '/setup/instagram'

export const TWITTER_API_URL = () => process.env.TWITTER_API_URL || 'https://api.twitter.com'
export const TWITTER_OAUTH = () => '/oauth2/token'
export const TWITTER_USER_INFO = (username) => `/labs/2/users/by/username/${username}`
export const INSTAGRAM_API_URL = () => process.env.INSTAGRAM_API_URL

export const PUBLISHER_ID = ({ publisherUsername }) => `/publisher/${publisherUsername}`
export const PUBLISHER = () => '/publisher/'
export const ZIP_CODE = () => '/setup/zipcode'

export const GET_CLIENT = ({ publisherUsername }) => `/client/query/${publisherUsername}`

/**
 *
 * @param {object} params
 * @param {string} params.publisherUsername
 * @param {string?} [params.shortCode=undefined]
 * @returns {string}
 */
export const GET_LANDING_PAGE = ({ publisherUsername, shortCode }) => (shortCode !== 'undefined' && shortCode
    ? `/landing_page/${publisherUsername}/${shortCode}`
    : `/landing_page/${publisherUsername}`)

export const GET_BLOCKS = ({ landingPageId }) => `/blocks/display/${landingPageId}`
export const SOCIAL_LINKS = ({ scope }) => (scope === 'creator' ? '/social_links' : '/social_links/display')
