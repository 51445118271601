import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const SignupUserLogin = Joi.object()
    .keys({
        name: Joi.string().optional().description('Creator Full Name'),
        username: Joi.string().optional().description('Creator Username'),
        page_number: Joi.number().min(1).optional().description('Step in Onboarding Wizard'),
        email: Joi.string().email({ tlds: { allow: false } }).optional().description('Creator email'),
        experiments: experimentsSchema,
        tier: Joi.string().valid('tier_1', 'tier_0').optional().description('Tier of creator'),
        created_at: Joi.date().required().description('Datetime event was sent'),
    })
    .description('This event fires when the user completes the signup step and officially makes an account.')
    .meta({ name: 'SignupUserLogin', filename: 'signup:user_login.model' })

export default SignupUserLogin
    