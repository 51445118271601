import React from 'react'

export default ({ color = '#120A20', width = '24', height = '24' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18,2 C19.1045695,2 20,2.8954305 20,4 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,4 C4,2.8954305 4.8954305,2 6,2 L18,2 Z M7.5,17 C6.67157288,17 6,17.6715729 6,18.5 C6,19.3284271 6.67157288,20 7.5,20 C8.32842712,20 9,19.3284271 9,18.5 C9,17.6715729 8.32842712,17 7.5,17 Z M12,17 C11.1715729,17 10.5,17.6715729 10.5,18.5 C10.5,19.3284271 11.1715729,20 12,20 C12.8284271,20 13.5,19.3284271 13.5,18.5 C13.5,17.6715729 12.8284271,17 12,17 Z M16.5,13 C15.6715729,13 15,13.6715729 15,14.5 L15,14.5 L15,18.5 C15,19.3284271 15.6715729,20 16.5,20 C17.3284271,20 18,19.3284271 18,18.5 L18,18.5 L18,14.5 C18,13.6715729 17.3284271,13 16.5,13 Z M7.5,13 C6.67157288,13 6,13.6715729 6,14.5 C6,15.3284271 6.67157288,16 7.5,16 C8.32842712,16 9,15.3284271 9,14.5 C9,13.6715729 8.32842712,13 7.5,13 Z M12,13 C11.1715729,13 10.5,13.6715729 10.5,14.5 C10.5,15.3284271 11.1715729,16 12,16 C12.8284271,16 13.5,15.3284271 13.5,14.5 C13.5,13.6715729 12.8284271,13 12,13 Z M7.5,9 C6.67157288,9 6,9.67157288 6,10.5 C6,11.3284271 6.67157288,12 7.5,12 C8.32842712,12 9,11.3284271 9,10.5 C9,9.67157288 8.32842712,9 7.5,9 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 C10.5,11.3284271 11.1715729,12 12,12 C12.8284271,12 13.5,11.3284271 13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z M16.5,9 C15.6715729,9 15,9.67157288 15,10.5 C15,11.3284271 15.6715729,12 16.5,12 C17.3284271,12 18,11.3284271 18,10.5 C18,9.67157288 17.3284271,9 16.5,9 Z M17,4 L7,4 C6.44771525,4 6,4.44771525 6,5 L6,5 L6,6 C6,6.55228475 6.44771525,7 7,7 L7,7 L17,7 C17.5522847,7 18,6.55228475 18,6 L18,6 L18,5 C18,4.44771525 17.5522847,4 17,4 L17,4 Z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)
