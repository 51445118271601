import generateAction from 'piconetworks/pkg-generate-action'

const getSocialLinks = generateAction({
    baseActionCreatorName: 'getSocialLinks',
    baseActionTypeName: 'GET_SOCIAL_LINKS',
})

const createLink = generateAction({
    baseActionCreatorName: 'createLink',
    baseActionTypeName: 'CREATE_LINK',
})

const updateLink = generateAction({
    baseActionCreatorName: 'updateLink',
    baseActionTypeName: 'UPDATE_LINK',
})

const deleteLink = generateAction({
    baseActionCreatorName: 'deleteLink',
    baseActionTypeName: 'DELETE_LINK',
})

const types = [
    ...getSocialLinks.types,
    ...createLink.types,
    ...updateLink.types,
    ...deleteLink.types,
]

const creators = (duck) => ({
    ...getSocialLinks.creators(duck),
    ...createLink.creators(duck),
    ...updateLink.creators(duck),
    ...deleteLink.creators(duck),
})

export {
    types,
    creators,
}
