import { takeEvery } from 'redux-saga/effects'
import { types } from '../actions'
import goToStep from './goToStep'
import nextStep from './nextStep'
import submitForm from './submitForm'
import deleteWizards from './deleteWizards'

export const takes = [
    takeEvery(types.GO_TO_STEP, goToStep),
    takeEvery(types.NEXT_STEP, nextStep),
    takeEvery(types.SUBMIT_FORM, submitForm),
    takeEvery(types.DELETE_WIZARDS, deleteWizards),
]

const sagas = {
    goToStep,
    nextStep,
    submitForm,
    deleteWizards,
}

export default sagas
