import CURRENCIES from './currencies'

const symbol = (currencyCode = 'USD') => {

    try {
        currencyCode = (currencyCode ?? 'USD').toLocaleUpperCase()
    } catch (error) {
        console.error('Error with converting currency code', error)
        currencyCode = 'USD'
    }

    if (!CURRENCIES[currencyCode]) {
        currencyCode = 'USD'
    }

    return CURRENCIES[currencyCode].symbol_native
}

export default symbol
