import { getUserLocales } from 'get-user-locale'
import currency from 'currency.js'

import CURRENCIES from './currencies'

const cache = new WeakMap()

const format = ({ 
    currencyCode = 'USD',
    amount,
    locales = getUserLocales(),
    symbol = true,
    zeroDecimals = false
}) => {
    amount = parseInt(amount, 10) || 0

    try {
        currencyCode = (currencyCode ?? 'USD').toLocaleUpperCase()
    } catch (error) {
        console.error('Error with converting currency code', error)
        currencyCode = 'USD'
    }
    
    if (!CURRENCIES[currencyCode]) {
        currencyCode = 'USD'
    }

    if (!Array.isArray(locales)) {
        locales = [locales]
    }

    const { decimal_digits: precision, symbol_native } = CURRENCIES[currencyCode]

    const { value } = currency(amount, { fromCents: true,
        precision })

    let formattedCurrency

    try {
        const options = {
            locales
        }

        if (symbol) {
            options.style = 'currency'
            options.currency = currencyCode
            options.currencyDisplay = 'symbol'
        }

        if (!cache.has(options)) {
            const { locales, ...currencyOptions } = options
            // Sorted locales because of en-US should be first because it's the default for US dollars
            cache.set(options, new Intl.NumberFormat(locales.sort(), currencyOptions))
        }

        const formatter = cache.get(options)

        formattedCurrency = formatter.format(value)

        const conditions = [{
            condition: (currencyString) => {
                const lastThree = currencyString.substring(currencyString.length - 3)
                return lastThree === '.00'
            },
            action: (currencyString) => currencyString.substring(0, currencyString.length - 3)
        }]

        if(!zeroDecimals){
            formattedCurrency = conditions.reduce((currencyString, condition) => {
                if (condition.condition(currencyString)) {
                    return condition.action(currencyString)
                }
                return currencyString
            }, formattedCurrency)
        }
    } catch (error) {
        console.log('Error with formatting currency: ', error)
        formattedCurrency = `${symbol_native}${value}`
    }

    return formattedCurrency
}

export default format
