import Wrapper from './Wrapper'
import Component from './Component'
import StepRoute from './StepRoute'
import Step from './Step'
import Verify from './Verify'
import {
    FieldSubmitError,
    WizardLoading,
    WizardError,
} from './Error'
import wizard from './modules'

const Wizard = {
    Component,
    Step,
    StepRoute,
    Wrapper,
    Verify,
}

export default Wizard

export {
    FieldSubmitError,
    WizardLoading,
    WizardError,
    wizard,
}
