const beacon = (url) => (payload) => {
    return fetch(url, {
        method: 'POST',
        headers: [
            ['Content-Type', 'application/json']
        ],
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify(payload),
        keepalive: true
    }).then(function(response) {
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`)
        }
        return 'Accepted'
    })
}

export default beacon
