import Account from 'modules/account'

import storeSubscriber from '../storeSubscriber'
import getAnalytics from '../../analytics'
import { v4 as uuid } from 'uuid'

const requestOneTimeCode = async ({
    dispatch,
    payload: {
        customProperties,
        email,
        phone,
        newsletters,
        origin,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        utm,
    },
}) => {
    const { track } = getAnalytics()
    const verifyFlowId = uuid()

    //Send event otp_requested
    await track('otp_requested', {
        email,
        phone,
        verify_flow_id: verifyFlowId
    }, {
        plugins: {
            segment: false,
        },
    })

    dispatch(Account.creators.requestOneTimeCode({
        customProperties,
        email,
        phone,
        newsletters,
        origin,
        popupId,
        publisherId,
        redirectUrl,
        referer,
        utm,

        // Analytics
        verifyFlowId
    }))

    let oneTimeCodeSent
    let errorMessage
    let response

    try {
        response = await storeSubscriber({
            checks: [
                (state) => {
                    oneTimeCodeSent = Account.selectors.oneTimeCodeSent(state)
                    errorMessage = Account.selectors.oneTimeCodeError(state)

                    if (errorMessage) {
                        throw new Error(errorMessage)
                    }

                    return (
                        oneTimeCodeSent === true
                    )
                },
            ],
        })
    } catch (error) {
        response = { error }
    }

    //Send event otp_generated
    await track('otp_generated', {
        success: !!oneTimeCodeSent,
        verify_flow_id: verifyFlowId
    }, {
        plugins: {
            segment: false,
        },
    })

    if (response?.error) {
        throw response.error
    }

    return {
        oneTimeCodeSent,
        errorMessage,
    }
}

export default requestOneTimeCode
