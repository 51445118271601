import Joi from 'joi'

const DashboardCartFailed = Joi.object()
    .keys({
        cart_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cart session'),
        cta_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cta session'),
        cta_id: Joi.string().allow(null).description('The id of the cta'),
        app_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the apps'),
        cart_error: Joi.string().allow(null).description('The error of the cart'),
    })
    .description('dashboard:cart:failed event attributes ')
    .meta({ name: 'DashboardCartFailed', filename: 'dashboard:cart:failed.model' })

export default DashboardCartFailed
