import {
    put, putResolve,
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
    OAUTH_USER_RESET,
} from 'piconetworks/pkg-endpoints'

function* requestPasswordReset({
    types, publisherId, email, redirectUrl,
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: OAUTH_USER_RESET(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    redirectUrl,
                    email,
                    publisherId,
                },
            },
        })

        if (!data.success) {
            throw new Error('unable to request password reset')
        }

        yield put({
            type: types.REQUEST_PASSWORD_RESET_SUCCESS,
            payload: {
                ...data,
            },
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.REQUEST_PASSWORD_RESET_ERROR,
                error: error.response.data,
            })
        } else {
            yield put({
                type: types.REQUEST_PASSWORD_RESET_ERROR,
                error: error.message,
            })
        }
    }
}

export default requestPasswordReset
