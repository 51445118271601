import React from 'react'

export default ({ title = 'icon/arrow/down/default', color = '#120A20' }) => (
    <svg
        height="24px"
        width="24px"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="icon/arrow/down/default"
            fill="currentcolor"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <path
                id="shape"
                d="M5.29277221,8.29277221 C5.68313516,7.90240926 6.31182673,7.90240926 6.70218967,8.29277221 L12,13.5890103 L17.2978103,8.29277221 C17.6881733,7.90240926 18.3168648,7.90240926 18.7072278,8.29277221 C19.0975907,8.68313516 19.0975907,9.3218226 18.7072278,9.71218554 L12.7068146,15.7094542 C12.3164517,16.0998172 11.6835483,16.0998172 11.2931854,15.7094542 L5.29277221,9.71218554 C4.90240926,9.3218226 4.90240926,8.68313516 5.29277221,8.29277221 Z"
                fill={color}
            />
        </g>
    </svg>
)
