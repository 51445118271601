import React from 'react'
import cn from 'classnames'
import style from './scss/Account.module.scss'

const PasswordMeter = ({
    score,
}) => (
    <div className={style.backerBar}>
        <div className={cn(style.innerBar, style[`color_${score}`])} />
    </div>
)

export default PasswordMeter
