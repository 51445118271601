import PropertyList from "./PropertyList"
import { ThemeBlockBackgroundType } from "./CompoundedProperties/BlockBackgroundType"
import { ThemeBlockShadowStyle } from "./CompoundedProperties/BlockShadowStyle"
import { ThemePageBackgroundType } from "./CompoundedProperties/PageBackgroundType"
import { ThemePageContrastColor } from "./CompoundedProperties/PageContrastColor"
import { ThemeBlockBackgroundValue } from "./Properties/BlockBackgroundValue"
import { ThemeBlockContrastColor } from "./CompoundedProperties/BlockContrastColor"
import { ThemeBlockBorderRadius } from "./Properties/BlockBorderRadius"
import { ThemeBlockBorderStyle } from "./Properties/BlockBorderStyle"
import { ThemeBlockBorderType } from "./Properties/BlockBorderType"
import { ThemeBlockBorderValue } from "./Properties/BlockBorderValue"
import { ThemeBlockBorderWidth } from "./Properties/BlockBorderWidth"
import { ThemeBlockShadowType } from "./Properties/BlockShadowType"
import { ThemeBlockShadowValue } from "./Properties/BlockShadowValue"
import { ThemePageBackgroundValue } from "./Properties/PageBackgroundValue"
import { ThemePageFontBody } from "./Properties/PageFontBody"
import { ThemePageFontHeading } from "./Properties/PageFontHeading"
import { ThemePageButtonContrastColor } from "./CompoundedProperties/PageButtonContrastColor"
import { ThemeBlockButtonContrastColor } from "./CompoundedProperties/BlockButtonContrastColor"

/**
 * Theme class
 * @class Theme
 * @extends PropertyList
 * @classdesc Theme class
 * @property {string} id - Theme id
 */

class Theme extends PropertyList {
    constructor(id: string) {
        // console.log('enchilada value ThemePagetextvalue', ThemePageTextValue.getAll())
        super({
            id,
            type: 'theme',
            properties:  [
                ThemeBlockBackgroundValue,
                ThemeBlockBorderRadius,
                ThemeBlockBorderStyle,
                ThemeBlockBorderType,
                ThemeBlockBorderValue,
                ThemeBlockBorderWidth,
                ThemeBlockShadowType,
                ThemeBlockShadowValue,
                ThemePageFontBody,
                ThemePageFontHeading,
                ThemePageBackgroundValue,
                ThemeBlockBackgroundType,
                ThemeBlockShadowStyle,
                ThemePageBackgroundType,
                ThemePageContrastColor,
                ThemeBlockContrastColor,
                ThemePageButtonContrastColor,
                ThemeBlockButtonContrastColor
            ]
        })
    }
}

export default Theme