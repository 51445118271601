import React from 'react'
import PropTypes from 'prop-types'
const Jcb = ({ width = '42px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 42 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="0.182516704%" y1="49.95997%" x2="100.273441%" y2="49.95997%" id="linearGradient-1">
                <stop stopColor="#007940" offset="0%" />
                <stop stopColor="#00873F" offset="22.85%" />
                <stop stopColor="#40A737" offset="74.33%" />
                <stop stopColor="#5CB531" offset="100%" />
            </linearGradient>
            <linearGradient x1="-57.5270968%" y1="50.1241953%" x2="232.39121%" y2="50.1241953%" id="linearGradient-2">
                <stop stopColor="#007940" offset="0%" />
                <stop stopColor="#00873F" offset="22.85%" />
                <stop stopColor="#40A737" offset="74.33%" />
                <stop stopColor="#5CB531" offset="100%" />
            </linearGradient>
            <linearGradient x1="-62.8015845%" y1="49.8578253%" x2="253.671294%" y2="49.8578253%" id="linearGradient-3">
                <stop stopColor="#007940" offset="0%" />
                <stop stopColor="#00873F" offset="22.85%" />
                <stop stopColor="#40A737" offset="74.33%" />
                <stop stopColor="#5CB531" offset="100%" />
            </linearGradient>
            <linearGradient x1="0.175556793%" y1="50.0058048%" x2="101.808162%" y2="50.0058048%" id="linearGradient-4">
                <stop stopColor="#1F286F" offset="0%" />
                <stop stopColor="#004E94" offset="47.51%" />
                <stop stopColor="#0066B1" offset="82.61%" />
                <stop stopColor="#006FBC" offset="100%" />
            </linearGradient>
            <linearGradient x1="-0.575855512%" y1="49.9142191%" x2="98.13299%" y2="49.9142191%" id="linearGradient-5">
                <stop stopColor="#6C2C2F" offset="0%" />
                <stop stopColor="#882730" offset="17.35%" />
                <stop stopColor="#BE1833" offset="57.31%" />
                <stop stopColor="#DC0436" offset="85.85%" />
                <stop stopColor="#E60039" offset="100%" />
            </linearGradient>
        </defs>
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_payment/jcb">
                <path
                    d="M31.1539537,2.34459565e-15 C28.9585095,2.34459565e-15 27.1582453,1.78185151 27.1582453,4.00366636 L27.1582453,8.16131989 L32.8005368,8.16131989 C32.9322634,8.16131989 33.0859445,8.16131989 33.1957167,8.18331806 C34.4690743,8.24931256 35.4131153,8.90925756 35.4131153,10.0531622 C35.4131153,10.9550871 34.7764365,11.7250229 33.5908967,11.8790101 L33.5908967,11.9230064 C34.8862087,12.0109991 35.8741585,12.7369386 35.8741585,13.8588451 C35.8741585,15.0687443 34.7764365,15.8606783 33.3274434,15.8606783 L27.1362909,15.8606783 L27.1362909,24 L32.9981267,24 C35.1935709,24 36.9938351,22.2181485 36.9938351,19.9963336 L36.9938351,0 L31.1539537,0 L31.1539537,2.34459565e-15 Z"
                    id="path6349"
                    fill="url(#linearGradient-1)"
                />
                <path
                    d="M29.1121906,14.592105 L31.417407,14.592105 C31.4832703,14.592105 31.6369514,14.5701039 31.7028147,14.5701039 C32.1419035,14.4820995 32.515129,14.0860797 32.515129,13.5360522 C32.515129,13.0080259 32.1419035,12.6120061 31.7028147,12.5020006 C31.6369514,12.4799995 31.5052247,12.4799995 31.417407,12.4799995 L29.1121906,12.4799995 L29.1121906,14.592105 Z"
                    id="path6338"
                    fill="url(#linearGradient-2)"
                />
                <path
                    d="M32.2297213,10.3180487 C32.2297213,9.79002234 31.8564958,9.43800477 31.417407,9.37200148 C31.3734981,9.37200148 31.2637259,9.35000038 31.1978625,9.35000038 L29.1121906,9.35000038 L29.1121906,11.286097 L31.1978625,11.286097 C31.2637259,11.286097 31.3954525,11.286097 31.417407,11.2640959 C31.8564958,11.1980926 32.2297213,10.846075 32.2297213,10.3180487 L32.2297213,10.3180487 Z"
                    id="path6360"
                    fill="url(#linearGradient-3)"
                />
                <path
                    d="M9.01766276,2.34674665e-15 C6.82221863,2.34674665e-15 5.02195444,1.78348624 5.02195444,4.00733945 L5.02195444,13.893578 C6.14163095,14.4440367 7.30521634,14.7963303 8.46880173,14.7963303 C9.85193153,14.7963303 10.5983825,13.959633 10.5983825,12.8146789 L10.5983825,8.14678899 L14.0232754,8.14678899 L14.0232754,12.7926606 C14.0232754,14.5981651 12.9035989,16.0733945 9.10548053,16.0733945 C6.80026419,16.0733945 5,15.5669725 5,15.5669725 L5,24 L10.8618358,24 C13.05728,24 14.8575442,22.2165138 14.8575442,19.9926606 L14.8575442,0 L9.01766276,0 L9.01766276,2.34674665e-15 Z"
                    id="path6371"
                    fill="url(#linearGradient-4)"
                />
                <path
                    d="M20.0638538,0 C17.8684096,0 16.0681455,1.78021978 16.0681455,4 L16.0681455,9.23076923 C17.0780498,8.37362637 18.8344051,7.82417582 21.666528,7.95604396 C23.1813844,8.02197802 24.8060131,8.43956044 24.8060131,8.43956044 L24.8060131,10.1318681 C23.9936988,9.71428571 23.0277034,9.34065934 21.7763002,9.25274725 C19.6247649,9.0989011 18.3294529,10.1538462 18.3294529,12 C18.3294529,13.8681319 19.6247649,14.9230769 21.7763002,14.7472527 C23.0277034,14.6593407 23.9936988,14.2637363 24.8060131,13.8681319 L24.8060131,15.5604396 C24.8060131,15.5604396 23.2033389,15.978022 21.666528,16.043956 C18.8344051,16.1758242 17.0780498,15.6263736 16.0681455,14.7692308 L16.0681455,24 L21.9299813,24 C24.1254254,24 25.9256896,22.2197802 25.9256896,20 L25.9256896,3.90408097e-15 L20.0638538,3.90408097e-15 L20.0638538,0 Z"
                    id="path6384"
                    fill="url(#linearGradient-5)"
                />
            </g>
        </g>
    </svg>
)

Jcb.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Jcb
