import React, { useEffect, useState, useCallback } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Head from 'next/head'
import { showConsoleLogs } from 'piconetworks/tag-generator'

import { withRouter } from 'next/router'
import Router from 'next/router'

import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useAnalytics } from 'piconetworks/pkg-pico-analytics'

import { H1 } from 'piconetworks/pkg-heading'

import Image from 'piconetworks/pkg-image'

import Header from 'piconetworks/pkg-header'
import SocialLinks from 'piconetworks/pkg-social-links'

import inIframe from 'pkgs/inFrame'

import Client from 'modules/client'
import Me from 'modules/me'
import Links from 'modules/links'
import Loader from 'modules/loader'
import LandingPage from 'modules/landing-page'
import Utm from 'modules/utm'
import FeatureFlags from 'modules/feature-flags'
import Blocks from 'modules/blocks'
import Account from 'modules/account'

import Invalid from '../Invalid'
import AccountMenuWrapper from '../AccountMenuWrapper'
import AppContainerContext from './AppContainerContext'

import { useRouter } from 'lib/RouterAdapterDecorator'

import style from './style.module.scss'
import useStyles from 'lib/useStyles'
import ClaimThisPage from '../ClaimThisPage'
import usePagePreview from '../../lib/usePreview'
import Footer from './Footer'
import useClaimThisPage from 'lib/useClaimThisPage'

const AppContainer = (props) => {
    const {
        hideContainer,
        // router,
        children,
        landingPage,
    } = props

    const client = useSelector(Client.selectors.client)
    const dispatch = useDispatch()

    const router = useRouter()
    const { query = {} } = router

    const {
        short_code: shortCode,
        utm_campaign,
        utm_medium,
        utm_source,
        utm_term,
        utm_content,
    } = query

    const utm_parameters = {
        ...(utm_campaign && { utm_campaign }),
        ...(utm_medium && { utm_medium }),
        ...(utm_source && { utm_source }),
        ...(utm_term && { utm_term }),
        ...(utm_content && { utm_content }),
    }

    const {
        identify,
        track,
        page,
        plugins,
    } = useAnalytics()
    const [useCustomPrompt, setUseCustomPrompt] = useState(false)
    const [isReturningUser, setIsReturningUser] = useState(false)
    const [readyToPage, setReadyToPage] = useState(false)

    const scopedLoader = useSelector(Loader.selectors.topScopeLoader, isEqual)
    const publisherId = useSelector(Client.selectors.id, isEqual)
    const landingPageSocialLinks = useSelector(Links.selectors.getSocialLinks, isEqual)
    const userPhone = useSelector(Me.selectors.userPhone, isEqual)
    const currentUser = useSelector(Me.selectors.getCurrentUser, isEqual)
    const isPaying = useSelector(Loader.selectors.userStatus, isEqual) === 'paying'
    const userEmailIsShared = useSelector(Me.selectors.userEmailIsShared, isEqual)

    const blocks = useSelector((state) => Blocks.selectors.getBlocksByFormId(state, landingPage?.id), isEqual)
    const signup_block = blocks?.find((block) => block.type === 'signup')?.signup_block
    const product_block = blocks?.find((block) => block.type === 'product')

    const publisherAuthenticationMethods = useSelector(LandingPage.selectors.publisherAuthenticationMethods)
    const hasPassword = useSelector(Account.selectors.hasPassword)

    const initShowLoginButton = (!!signup_block || !!product_block) && publisherAuthenticationMethods?.length > 0
    const [showLoginButton, setShowLoginButton] = useState(initShowLoginButton)

    useEffect(() => {
        if (Object.keys(utm_parameters)) {
            dispatch(Utm.creators.setUtmParameters(utm_parameters))
        }

        dispatch(FeatureFlags.creators.getFeatureFlags({
            publisherId,
        }))

        Router.events?.on('routeChangeStart', (url) => {
            if (url && !url.includes('/login') && signup_block) setShowLoginButton(initShowLoginButton)
        })
    }, [])

    // only send events to analytics once the guest/user id is set
    // these only include onload events such as page_view
    useEffect(() => {
        const { guest, user } = plugins['analytics-plugin-pico']
        const userId = scopedLoader?.user?.id

        if (!isEmpty(scopedLoader)) {
            if (currentUser) {
                user({
                    id: userId,
                })
            } else {
                guest({
                    id: userId,
                })
            }
            setReadyToPage(true)
        }
    }, [currentUser, landingPage, JSON.stringify(scopedLoader)])

    // the variable hideWhileLoading is essential to protect against rerenders.
    // the dom is hidden when loading because returning props.children
    // here will cause the Nextjs router to rerender. this will therefor
    // cause componentDidMount events to also occur twice.
    // unless the Nextjs Component in _app.js gets wrapped differently,
    // this display:none will be needed to prevent componentDidMount from remounting
    const is404 = router?.pathname?.startsWith?.('/404')
    const {
        icon: avatarImg,
        name: publisherName,
        username: publisherUsername,
    } = client || {}


    const hideWhileLoading = false

    const { linkColor, contrastColor, StyleContainer, RootCssVariables, TagsToRender } = useStyles({ landingPage })

    const coverImg = landingPage?.cover_image
    const displayLogo = landingPage?.display_logo
    const displayBrandName = landingPage?.display_brand_name
    const displaySocialLinks = landingPage?.display_social_links
    const displayBannerImage = landingPage?.display_banner_image
    const shouldDisplayBranding = displayBrandName || displayLogo || displaySocialLinks
    const isSignupLandingPage = landingPage?.type === 'signup_landing_page' || landingPage?.type === 'standard_page'

    const headTitle = (publisherName) ? `${publisherName} | Hype` : 'Hype'
    const headDescription = `Check out ${publisherName} on Hype. Create your own page for free at hype.co.`

    let isNfcOrigin = false
    let isHypeCardOrigin = false
    let isHypeStickerOrigin = false
    if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search)
        isHypeCardOrigin = params.get('utm_source') === 'HypeCard'
        isHypeStickerOrigin = params.get('utm_source') === 'HypeStickers'
        isNfcOrigin = isHypeCardOrigin || isHypeStickerOrigin
    }

    let isPreview = false, previewHandler
    if (typeof window !== 'undefined') {
        isPreview = window.localStorage.getItem("is_ios_preview") ? true : false
        previewHandler = usePagePreview({
            config: {
                bridge: 'ios_preview_bridge',
                isPreview
            },
            landingPage,
            blocks,
            readyToPage: readyToPage
        })
    }

    const referralUTMs = qs.stringify({
        utm_source: 'HypeSite',
        utm_medium: 'Referral',
        utm_campaign: publisherId,
    })

    const remixLink = `${process.env.MARKETING_SITE_URL}/remix?id=${landingPage?.id}&${referralUTMs}&utm_content=Remix`
    const referralLink = `${process.env.MARKETING_SITE_URL}${isNfcOrigin ? '/products/hype-' : ''}${isHypeCardOrigin ? 'card' : 'stickers'}?${referralUTMs}&utm_content=${isHypeCardOrigin ? 'HypeCard' : isHypeStickerOrigin ? 'HypeSticker' : 'Badge'}`

    if (is404) {
        return (
            <Invalid />
        )
    }

    if (is404) {
        return (
            <Invalid />
        )
    }

    const isInIframe = inIframe()

    /**
     * Cleans the meta data of an event.
     * @name Function
     * @param {object} event - The event object.
     * @param {string} event.eventType - The type of the event.
     * @param {string} event.eventName - The name of the event.
     * @param {object} event.meta - The meta data of the event.
     */
    const metaCleaner = ({ eventType, eventName, meta }) => {
        const cleanedMeta = { ...meta }
        if (eventType !== 'page' && cleanedMeta?.theme_id === null) {
            delete cleanedMeta.theme_id
        }
        if (eventName === 'link_click' && cleanedMeta?.theme_id === undefined) {
            cleanedMeta.theme_id = null
        }
        if (cleanedMeta?.link_url) {
            cleanedMeta.link_url = cleanedMeta.link_url.trim()
        }
        return cleanedMeta
    }


    /**
     * Regular analytics events **without segment integration**,
     * Use it only for internal analytic events. *(e.g. picoboo etc.)*
     * @name Function
     * @memberof Analytics
     * @param {object} event - The event object.
     * @param {string} event.eventType - The type of the event.
     * @param {string} event.eventName - The name of the event.
     * @param {object} event.meta - The meta data of the event.
     */
    const sendEvent = ({
        eventType, eventName, meta,
    }) => {
        const cleanedMeta = metaCleaner({ eventType, eventName, meta })
        const data = {
            ...cleanedMeta,
            form_id: landingPage?.id,
        }


        // eslint-disable-next-line default-case
        switch (eventType) {
            case 'track':
                track(eventName, {
                    ...data,
                }, {
                    plugins: {
                        segment: false,
                    },
                })
                break
            case 'identify':
                const user = scopedLoader?.user

                identify(currentUser?.user_id, {
                    email: currentUser?.email || user?.email,
                    phone: currentUser?.phone || user?.phone,
                    paying: isPaying,
                    has_password: !!hasPassword,
                    otp_verified: currentUser?.verified || user?.verified,
                    ...data,
                }, {
                    plugins: {
                        segment: false,
                    },
                })
                break
            case 'page':
                page(data, {
                    plugins: {
                        segment: false,
                    },
                })
                break
        }
    }

    if (showConsoleLogs()) {
        console.count('render AppContainer')
    }

    const [focusCreateAcccount, setFocusCreateAccount] = useState()

    const goToCreateAccount = useCallback(() => {
        setFocusCreateAccount(true)
        setTimeout(() => setFocusCreateAccount(false), 0)
    }, [setFocusCreateAccount])
    const { showClaimThisPage } = useClaimThisPage()

    const urlSubdomain = process.env.STAGE === 'staging' ? 'assets.staging' : 'assets'

    const iconStylesheet = `https://${urlSubdomain}.hype.co/icons/common/style.css`

    return (
        <AppContainerContext.Provider value={{
            setShowLoginButton,
            useCustomPrompt,
            setUseCustomPrompt,
            showLoginButton,
            isReturningUser,
            setIsReturningUser,
            sendEvent,
            readyToPage,
            setReadyToPage,
            focusCreateAcccount,
            goToCreateAccount,
            isPreview,
            previewHandler
        }}
        >
            <div className={classNames(style.layoutContainer, 'page', { [style.noSelect]: isPreview })}>
                <Head>
                    <link
                        rel="stylesheet"
                        href={iconStylesheet}
                    />
                    {RootCssVariables}
                    {process.env.STAGE === 'production' && <script defer data-domain="hype.co" src="https://plausible.io/js/script.js"></script>}
                </Head>
                <div className={classNames(style.mainWrapper, {
                    [style.hideWhileLoading]: hideWhileLoading,
                    [style.nfcSource]: isNfcOrigin,
                    [style.showClaimThisPage]: showClaimThisPage
                })}
                >
                    <ClaimThisPage />
                    {TagsToRender}
                    <StyleContainer />
                    {showLoginButton && !isPreview && (
                        <Header className={classNames(style.headerClass, { [style.isNotAuthed]: !currentUser ? true : false, [style.showClaimThisPage]: showClaimThisPage })}>
                            <AccountMenuWrapper
                                contrastColor={contrastColor}
                                linkColor={linkColor}
                                currentUser={currentUser}
                                userPhone={userPhone}
                                userEmailIsShared={userEmailIsShared}
                                phone
                                showLoginButton={showLoginButton}
                                {...props}
                            />
                        </Header>
                    )}

                    {!hideContainer && (
                        <div
                            className={style.contentContainer}
                            {...(isInIframe && { style: { paddingTop: '0px' } })}
                            {...(currentUser && { style: { marginTop: '5.5rem' } })}
                        >
                            {!isInIframe && (
                                <div className={classNames(style.pageHeader, style.isPreviewWrapper, { [style.isRounded]: currentUser })}>
                                    {isPreview && <div className={style.isPreviewOverlay} onClick={() => previewHandler?.sendMessageToParent({ type: "header_block_tap", body: { tap: true } })}></div>}
                                    {coverImg && (
                                        <Image.CoverPhoto
                                            id="headerCoverImage"
                                            className={displayBannerImage && classNames(style.banner)}
                                            style={{
                                                display: displayBannerImage ? "block" : "none",
                                                backgroundImage: `url(${coverImg})`,
                                            }}
                                        >
                                        </Image.CoverPhoto>
                                    )}



                                    {shouldDisplayBranding && (
                                        <section className={style.brandHeader}>
                                            <div className={style.logo}>
                                                {displayLogo && (
                                                    <Image.Avatar
                                                        id="headerAvatar"
                                                        src={avatarImg}
                                                        className={classNames(
                                                            style.bannerAvatar,
                                                        )}
                                                    />
                                                )}
                                                {displayBrandName && (
                                                    <H1 className={'page-contrast-color-important'}>{publisherName}</H1>
                                                )}
                                            </div>
                                            {displaySocialLinks && !isEmpty(landingPageSocialLinks) && (
                                                <div
                                                    className={style.socialLinks}
                                                >
                                                    <SocialLinks
                                                        links={
                                                            landingPageSocialLinks
                                                        }
                                                        onClick={(meta) => sendEvent({
                                                            eventType: 'track',
                                                            eventName: 'link_click',
                                                            meta: {
                                                                ...meta,
                                                                theme_id: landingPage?.theme_id
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            )}
                                        </section>
                                    )}
                                </div>
                            )}
                            <div className={classNames(style.mainContentArea, { [style.inIFrame]: isInIframe, [style.isRounded]: !coverImg })}>
                                <div className={classNames(style.mainContent)}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {isSignupLandingPage && !isPreview && (
                    <Footer
                        isNfcOrigin={isNfcOrigin}
                        remixLink={remixLink}
                        referralLink={referralLink}
                        isHypeCardOrigin={isHypeCardOrigin}
                    />
                )}
            </div>
        </AppContainerContext.Provider>
    )
}

AppContainer.defaultProps = {
    publisherId: null,
}

AppContainer.propTypes = {
    publisherId: PropTypes.string,
    accessToken: PropTypes.func,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
}

export default withRouter(React.memo(AppContainer))
