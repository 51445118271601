import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-final-form'
// import { withRouter } from 'lib/RouterAdapterDecorator'
import { withRouter } from 'lib/RouterAdapterDecorator'
import { FORM_ERROR } from 'final-form'
import { connect } from 'react-redux'
import { FieldSubmitError, WizardLoading, WizardError } from './Error'

import Wizard from './modules'

class Step extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isWizard: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        const { router, wizardStore, dispatch } = this.props
        const { query } = router || {}
        const { id } = query || 'id'
        const companySlug = query?.company_slug

        const thisWizard = wizardStore.wizards[id]

        if (thisWizard) {
            this.setState({
                isWizard: true,
            }, () => {
                const { step: currentStep } = thisWizard.steps.find(
                    (step) => step.url === router.route.replace(`/${companySlug}/`, '/[company_slug]/'),
                ) || {}

                if (currentStep || currentStep === 0) {
                    dispatch(Wizard.creators.setCurrentStep({
                        payload: {
                            id,
                            currentStep,
                        },
                    }))
                }
            })
        }
    }

    async onSubmit(values, form, callback) {
        const { isWizard } = this.state

        const {
            router: { query: { id, short_code } },
            wizardStore,
            dispatch,
            onSubmit,
            preSubmit,
            postSubmit,
            postSubmitDispatch,
        } = this.props

        const currentWizard = wizardStore.wizards[id] || {}

        const { currentStep, steps } = currentWizard

        let submitWizard
        let goToStep

        const hookPayload = {
            values,
            currentWizard,
            submitForm: ({ short_code: shortCode, id: wizardName }) => dispatch(
                Wizard.creators.submitForm({
                    payload: {
                        id: wizardName,
                        short_code: shortCode,
                    },
                }),
            ),
            goToStep: ({ short_code: shortCode, id: wizardName, step }) => dispatch(
                Wizard.creators.goToStep({
                    payload: {
                        id: wizardName,
                        short_code: shortCode,
                        goToStep: step,
                    },
                }),
            ),
            nextStep: ({ short_code: shortCode, id: wizardName }) => dispatch(
                Wizard.creators.nextStep({
                    payload: {
                        id: wizardName,
                        short_code: shortCode,
                    },
                }),
            ),
        }

        let payload = null
        try {
            /*
            * The preSubmit hook is primarily for server-side
            * validation but any submission validation could be handled here.
            * An example of that is the first step of signup.
            * When a user adds an email/signs in with google we need to make an API request
            * to check if the email is a personal or disposable email.
            *
            * preSubmit is an async function that provides you access to:
            * - currentWizard - the current wizard context,
            * - form - the React Final Form form object,
            * - values - the raw, unfiltered, untouched values of the form,
            *
            * Also a couple of navigation function to exit the submission early:
            * submitForm - Early exits for wizard and calls the parent wizard onSubmit
            * goToStep - Allows redirection to a specific step
            * nextStep - Allows redirection to the next step in the sequence
            * */
            if (preSubmit) {
                await preSubmit({
                    ...hookPayload,
                    form,
                })
            }

            payload = await onSubmit({
                values,
                form,
                callback,
                currentWizard,
                props: this.props,
            })

            submitWizard = payload.submitWizard
            goToStep = payload.goToStep

            if (payload && isWizard) {
                dispatch(Wizard.creators.updatePayload({
                    payload: {
                        id,
                        payload,
                    },
                }))
            }
        } catch (e) {
            if (e instanceof FieldSubmitError) {
                // handle field submit errors as well
                const fieldErrors = {}
                Object.keys(values).forEach((key) => {
                    if (e.field === key) {
                        fieldErrors[key] = e.message
                    }
                })
                return fieldErrors
            } if (e instanceof WizardLoading) {
                // statements to handle any unspecified exceptions
                const errorMessage = e.message || 'Form Error, please contact support'

                return {
                    [FORM_ERROR]: errorMessage,
                    name: e.name,
                    ...e.options,
                }
            } if (e instanceof WizardError) {
                // statements to handle any unspecified exceptions
                const errorMessage = e.message || 'Form Error, please contact support'

                return {
                    [FORM_ERROR]: errorMessage,
                    name: e.name,
                    ...e.options,
                }
            } // statements to handle any unspecified exceptions
            const errorMessage = e.message || 'Form Error, please contact support'
            return { [FORM_ERROR]: errorMessage }
        }

        if (isWizard) {
            /*
            * The postSubmitDispatch hook is primarily for clean up and
            * will not affect wizard navigation
            *
            * postSubmitDispatch includes the same params as postSubmit below
            * */

            if (postSubmitDispatch) {
                await postSubmitDispatch({
                    ...hookPayload,
                    payload,
                })
            }

            /*
            * The postSubmit hook is primarily for wizard navigation.
            * Once the values have been saved to the redux state of the form, where do you go next?
            * If you don't provide a function it will use the old
            * logic of next step unless provided with a goToStep.
            *
            * postSubmit is an async function that provides you access to:
            * - currentWizard - the current wizard context,
            * - payload - the returned object from the onSubmit function,
            * - values - the raw, unfiltered, untouched values of the form,
            *
            * Also a couple of navigation function to help with navigation:
            * submitForm - Early exits for wizard and calls the parent wizard onSubmit
            * goToStep - Allows redirection to a specific step
            * nextStep - Allows redirection to the next step in the sequence
            * */
            if (postSubmit) {
                await postSubmit({
                    ...hookPayload,
                    payload,
                })
            } else {
                const nextStep = steps[currentStep + 1]

                if (!goToStep && (!nextStep || submitWizard)) {
                    hookPayload.submitForm({
                        id,
                        short_code,
                    })
                } else if (goToStep) {
                    hookPayload.goToStep({
                        id,
                        step: goToStep,
                        short_code,
                    })
                } else {
                    hookPayload.nextStep({
                        id,
                        short_code,
                    })
                }
            }
        }

        return true
    }

    render() {
        const {
            children,
            initialValues = {},
            validate = () => ({}),
            render = () => null,
            rffProps = {},
        } = this.props

        return (
            <Form
                initialValues={initialValues}
                validate={validate}
                onSubmit={this.onSubmit}
                {...rffProps}
            >
                {(props) => render({
                    ...props,
                    children,
                })}
            </Form>

        )
    }
}

const mapStateToProps = (state) => ({
    wizardStore: state.wizard,
})

Step.defaultProps = {
    children: null,
    initialValues: {},
    onSubmit: () => {},
    preSubmit: null,
    postSubmit: null,
    postSubmitDispatch: null,
    render: () => null,
    validate: () => {},
    wizardStore: null,
    rffProps: {},
}

Step.propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.objectOf(PropTypes.object),
    onSubmit: PropTypes.func,
    preSubmit: PropTypes.func,
    postSubmit: PropTypes.func,
    postSubmitDispatch: PropTypes.func,
    render: PropTypes.func,
    router: PropTypes.shape({
        route: PropTypes.string,
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    validate: PropTypes.func,
    wizardStore: PropTypes.objectOf(PropTypes.object),
    rffProps: PropTypes.objectOf(PropTypes.object),
}

export default withRouter(connect(mapStateToProps)(Step))
