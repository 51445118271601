import { call, put } from 'redux-saga/effects'
import {
    ME
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* updateUserNewsletters({
    types,
    publisherId,
    newsletters
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: `${ME()}/newsletters`,
            method: 'put',
            headers: {
                publisherid: publisherId,
            },
            data: {
                newsletters
            },
        })

        if (data) {
            yield put({
                type: types.UPDATE_USER_NEWSLETTERS_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Unable to update newsletters')
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_USER_NEWSLETTERS_ERROR,
            error: 'Error updating user newsletters',
        })
    }
}

export default updateUserNewsletters
