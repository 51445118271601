import { takeEvery } from 'redux-saga/effects'
import { types } from '../actions'
import syncUserDataAfterPasswordRegister from './syncUserDataAfterPasswordRegister'
import logoutAndExchangeClaim from './logoutAndExchangeClaim'

export const takes = [
    takeEvery(types.SYNC_USER_DATA_AFTER_PASSWORD_REGISTER, syncUserDataAfterPasswordRegister),
    takeEvery(types.LOGOUT_AND_EXCHANGE_CLAIM, logoutAndExchangeClaim),
]

const sagas = {
    syncUserDataAfterPasswordRegister,
    logoutAndExchangeClaim,
}

export default sagas
