
import { putResolve, put } from 'redux-saga/effects'
import { AUTH_USER_CLAIM, PICO_API_URL } from 'piconetworks/pkg-endpoints'
import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'

function* generateClaim({
    types,
    publisherId,
}) {
    try {

        if (publisherId === null) {
            throw Error('publisherId must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: AUTH_USER_CLAIM(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,

                },
                timeout: 30000,
            },
        })



        yield put({
            type: types.GENERATE_CLAIM_SUCCESS,
            payload: data,
        })

    } catch (error) {
        yield put({
            type: types.GENERATE_CLAIM_ERROR,
            error: error?.message || 'GENERATE_CLAIM_ERROR',
        })
    }
}

export default generateClaim
