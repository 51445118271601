import addPaymentMethod from './addPaymentMethod'
import deletePaymentMethod from './deletePaymentMethod'
import setDefaultPaymentMethod from './setDefaultPaymentMethod'

const sagas = (duck) => ({
    * addPaymentMethod(payload) {
        yield addPaymentMethod({ ...payload,
            ...duck })
    },
    * deletePaymentMethod(payload) {
        yield deletePaymentMethod({ ...payload,
            ...duck })
    },
    * setDefaultPaymentMethod(payload) {
        yield setDefaultPaymentMethod({ ...payload,
            ...duck })
    },
})

export default sagas
