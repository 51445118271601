import {
    put, putResolve, call
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, ME_PAYMENT } from 'piconetworks/pkg-endpoints'

function* addPaymentMethod({
    types,
    token = {},
    publisherId,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!token.id) {
            throw Error('token.id must be provided')
        }

        if (!token.type) {
            throw Error('token.type must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: ME_PAYMENT(),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    ...(token.account_id && { account_id: token.account_id }),
                    token: token.id,
                    source_type: token.type,
                },
            },
        })

        if (data) {
            yield put(
                yield call(loaderInvalidationEvent, {
                    payload: {
                        scope: [
                            'user',
                        ],
                    },
                })
            )
        }

        const cardId = token?.card?.id
        const newPaymentMethod = (data?.paymentMethods?.filter((pm) => {
            if (!cardId) {
                return false
            }

            return pm.sourceId === cardId
        }) || [])[0]

        yield put({
            type: types.ADD_PAYMENT_METHOD_SUCCESS,
            payload: {
                token_id: token.id,
                tokenData: newPaymentMethod,
            },
        })
    } catch (error) {
        const errorMessage = 'There was an issue trying to complete your purchase. Please try again or contact support if this persists.'

        yield put({
            type: types.ADD_PAYMENT_METHOD_ERROR,
            error: errorMessage || error,
            payload: {
                token_id: token.id,
            },
        })
    }
}

export default addPaymentMethod
