import React from 'react'
export default ({ color = 'currentcolor', width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Standard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_info" fill={color}>
                <path
                    d="M8,0 C10.209139,-4.05812251e-16 12,1.790861 12,4 L12,8 C12,10.209139 10.209139,12 8,12 L4,12 C1.790861,12 2.705415e-16,10.209139 0,8 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L8,0 Z M6.65546875,4.6484375 L4.89179688,4.6484375 C4.62226563,4.6484375 4.4328125,4.69726562 4.3234375,4.79492188 C4.2140625,4.89257812 4.159375,5.05664062 4.159375,5.28710938 C4.159375,5.51757812 4.21601563,5.68261719 4.32929688,5.78222656 C4.42369792,5.86523438 4.56963976,5.9136556 4.7671224,5.92749023 L4.89179688,5.93164062 L5.55390625,5.93164062 L5.55390625,8.77929688 L4.29414063,8.77929688 C4.02070313,8.77929688 3.82832031,8.828125 3.71699219,8.92578125 C3.60566406,9.0234375 3.55,9.18554688 3.55,9.41210938 C3.55,9.64257812 3.60761719,9.80859375 3.72285156,9.91015625 C3.81888021,9.99479167 3.96712782,10.0441623 4.1675944,10.0582682 L4.29414063,10.0625 L8.25507813,10.0625 C8.52070313,10.0625 8.71113281,10.0117188 8.82636719,9.91015625 C8.94160156,9.80859375 8.99921875,9.64257812 8.99921875,9.41210938 C8.99921875,9.18554688 8.94257813,9.0234375 8.82929688,8.92578125 C8.73489583,8.84440104 8.58624132,8.79692925 8.38333333,8.78336589 L8.25507813,8.77929688 L6.9953125,8.77929688 L6.9953125,4.90039062 C6.9953125,4.82226562 6.96601563,4.76074219 6.90742188,4.71582031 C6.86347656,4.68212891 6.80524902,4.66107178 6.73273926,4.65264893 L6.65546875,4.6484375 Z M6.10471231,1.75 C5.67652803,1.75 5.40010526,1.82454739 5.27544402,1.97364217 C5.15078277,2.12273695 5.08845215,2.4699148 5.08845215,3.01517572 C5.08845215,3.30910543 5.14942776,3.50505857 5.27137898,3.60303514 C5.3933302,3.70101171 5.66568792,3.75 6.08845215,3.75 C6.52205648,3.75 6.79712424,3.69781683 6.9136554,3.59345048 C7.03018657,3.48908413 7.08845215,3.21964856 7.08845215,2.78514377 C7.08845215,2.33359957 7.0234115,2.04818956 6.8933302,1.92891374 C6.7632489,1.80963791 6.50037627,1.75 6.10471231,1.75 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
)
