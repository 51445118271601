import React from 'react'

export default ({ title = 'Edit', color = 'currentColor' }) => (
    <svg
        className="clickable"
        height="24px"
        icon="M16.75 11L13 7.25l-9 9V20h3.75l9-9zm2.96-2.96a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0L14 6.25 17.75 10l1.96-1.96z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Edit"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M16.75 11L13 7.25l-9 9V20h3.75l9-9zm2.96-2.96a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0L14 6.25 17.75 10l1.96-1.96z"
            fill={color}
        />
    </svg>
)
