import { useCallback, useEffect, useRef, useState } from "react"
import style from './style.module.scss'
import Picon from 'piconetworks/pkg-picon'
import classNames from 'classnames'

const Footer = ({
    isNfcOrigin = false,
    referralLink = "",
    remixLink = "",
    isHypeCardOrigin = false }) => {

    const [isScrolling, setIsScrolling] = useState(false)

    const scrollTimeoutRef = useRef(null)

    const handleScroll = useCallback(() => {
        if (!isScrolling) {
            setIsScrolling(true)
        }
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current)
        }
        scrollTimeoutRef.current = setTimeout(() => {
            if (isScrolling) {
                setIsScrolling(false)
            }
        }, 1000)
    }, [isScrolling]) 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current)
            }
        }
    }, [handleScroll])

    return (
        <footer className={classNames(style.footerContainer, {
            [style.fullWidth]: !isNfcOrigin,
            [style.bounceOutDown]: isScrolling,
            [style.bounceInUp]: !isScrolling,
        })}>
            {isNfcOrigin ?
                <div>
                    <div className={style.poweredByNfc}>POWERED BY</div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={referralLink}
                        className={style.footerButton}
                    >
                        <div className={style.footerButtonShimmer} />
                        <div className={style.footerButtonLabel}>
                            <Picon icon="icon_nfc" size="20" />
                            <Picon icon="hype-wordmark" size="44" className={style.buttonLogo} />
                            <span>{isHypeCardOrigin ? 'Card' : 'Sticker'}</span>
                        </div>
                    </a>
                </div>
                : <div className={style.footerContent}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={remixLink}
                        className={style.footerButton}
                    >
                        <div className={style.footerButtonShimmer} />
                        <div className={style.footerButtonLabel}>
                            <Picon icon="icon_plus-circled" size="20" />
                            <span>Create your own page</span>
                        </div>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={referralLink}
                        className={style.poweredByHype}
                    >
                        <span>Powered by</span>
                        <Picon icon="hype-wordmark" size="44" />
                    </a>
                </div>
            }
        </footer>
    )
}

export default Footer