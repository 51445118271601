import GridContainer from './GridContainer'
import GridRow from './GridRow'
import GridCol from './GridCol'

const Grid = {
    Container: GridContainer,
    Row: GridRow,
    Col: GridCol
}

export default Grid
