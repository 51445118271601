import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import { format } from 'piconetworks/pkg-currency'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanDonation = ({
    Link,
    changeMenuRoute,
    currentBillingIntervalTotalPaid,
    currentSubscriptionStart,
    currentSubscriptionId,
    publisherId,
    closeMenu,
    onClickChangePlan,
    firstDonation,
    nextProductOptionPrice,
    nextSubscriptionStart,
    nextSubscriptionEnd,
    nextStatusNext,
    userLoader,
    guestLoader,
    currentPurchaseCanceled,
    keepPlan,
    currentProductOptionCurrency,
    next,
    utmRootUrl,
    queryOverride,
    currentProductOptionId,
    currentSubscriptionInterval,
    priceText,
    contributionLanguage = 'tip',
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader
    const nextPaymentDate = nextStatusNext ? nextSubscriptionStart : nextSubscriptionEnd
    const isNotCancelled = !currentPurchaseCanceled || (currentPurchaseCanceled && nextStatusNext)

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage {contributionLanguage === 'tip' ? 'Tip' : 'Donation'}</h3>
            </div>
            <div className={style.container}>
                <ul className={style.menuContent} style={{
                    color: linkColor,
                    borderBottom: '1px solid #ddd'
                }}>
                    <li className={style.list}>
                        <p className={style.listTitle}>Contributor Since</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {dayjs(firstDonation).format('LL')}
                            </div>
                        </div>
                    </li>
                    <li className={style.list}>
                        <p className={style.listTitle}>{`${isNotCancelled ? 'Last' : 'Final'} ${contributionLanguage === 'tip' ? 'tip' : 'donation'}`}</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {`${priceText(format({
                                    amount: currentBillingIntervalTotalPaid,
                                    currencyCode: currentProductOptionCurrency,
                                    symbol: false,
                                }), currentProductOptionCurrency)} on ${dayjs(currentSubscriptionStart).format('LL')}`}
                            </div>
                        </div>
                    </li>
                    {isNotCancelled && (<li className={style.list}>
                        <p className={style.listTitle}>Next {contributionLanguage === 'tip' ? 'Tip' : 'Donation'}</p>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>
                                {`${priceText(format({
                                    amount: nextProductOptionPrice,
                                    currencyCode: next?.product_option_currency,
                                    symbol: false,
                                }), next?.product_option_currency)} on ${dayjs(nextPaymentDate).format('LL')}`}
                            </div>
                        </div>
                    </li>)}
                    {!isNotCancelled && (
                        <li className={style.list}>
                            <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                                <div className={classNames(style.regularColor)}>
                                    Your support is set to end on
                                    {' '}
                                    <strong>{dayjs(nextSubscriptionEnd).format('LL')}</strong>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
                <ul className={classNames(style.menuContent, style.planOption, style.btnLinkGroup)}>
                    {isNotCancelled && (
                        <li className={style.mainMenuItem}>
                            <Boutton
                                icon={<Picon icon='icon_switch' size='24' color={linkColor} />}
                                variant="link"
                                onClick={onClickChangePlan ? () => onClickChangePlan(currentProductOptionId) : () => router.push('/checkout')}

                            >
                                Change {contributionLanguage === 'tip' ? 'tip' : 'donation'} amount
                            </Boutton>
                        </li>
                    )}
                    {isNotCancelled && (<Link
                        href="/cards"
                        as={'/cards'}
                    >
                        <a
                            onClick={() => {
                                closeMenu()
                            }}
                        >
                            <li className={style.mainMenuItem}>
                                <Boutton
                                    icon={<Picon icon='icon_card' size='24' color={linkColor} />}
                                    variant="link"
                                >
                                    Update payment method
                                </Boutton>
                            </li>
                        </a>
                    </Link>)}
                    {isNotCancelled && (<li className={style.mainMenuItem} onClick={() => { changeMenuRoute('/managePlan/subscription/stopSubscription') }}>
                        <Boutton
                            icon={<Picon icon='icon_negative' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Cancel {contributionLanguage === 'tip' ? 'tip' : 'donation'}
                        </Boutton>
                    </li>)}
                    {!isNotCancelled && (
                        <li
                            className={style.mainMenuItem}
                            onClick={() => {
                                keepPlan({
                                    productOptionId: currentProductOptionId,
                                    publisherId,
                                    subscriptionId: currentSubscriptionId,
                                })
                            }}
                        >
                            <Boutton
                                icon={<Picon icon='icon_undo' size='24' color={linkColor} />}
                                variant="link"
                            >
                                Resume{' '}
                                {!currentSubscriptionInterval
                                    ? ''
                                    : currentSubscriptionInterval === 'month'
                                        ? 'Monthly'
                                        : 'Yearly'}{' '}
                                {contributionLanguage === 'tip' ? 'Tip' : 'Donation'}
                            </Boutton>
                        </li>
                    )}
                </ul>
            </div>
        </MenuWrapper>
    )
}

ManagePlanDonation.defaultProps = {

}

ManagePlanDonation.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    next: PropTypes.shape({
        product_option_currency: PropTypes.string.isRequired,
    }).isRequired,
    Link: PropTypes.any,
    currentProductOptionCurrency: PropTypes.string.isRequired,
    currentSubscriptionInterval: PropTypes.string,
    utmRootUrl: PropTypes.string,
    queryOverride: PropTypes.object,
    priceText: PropTypes.func.isRequired,
}

export default ManagePlanDonation
