import { call, put } from 'redux-saga/effects'
import {
    SOCIAL_LINKS
} from 'piconetworks/pkg-endpoints'
import { picoAPIHelper } from 'piconetworks/pkg-network-middleware'

function* createLink({
    types,
    publisherId,
    payload: {
        icon,
        link_block_id,
        order,
        title,
        subtitle,
        url,
    },
}) {
    try {
        const { data } = yield call(picoAPIHelper, {
            url: SOCIAL_LINKS({ scope: 'creator' }),
            method: 'post',
            headers: {
                publisherid: publisherId,
            },
            data: {
                icon,
                link_block_id,
                order,
                title,
                subtitle,
                url,
            }
        })

        if (data) {
            yield put({
                type: types.CREATE_LINK_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('unable to create link')
        }
    } catch (error) {
        yield put({
            type: types.CREATE_LINK_ERROR,
            error: error?.message,
        })
    }
}

export default createLink
