import Joi from 'joi'

const validFilterValues = ['today', 'this_week', 'this_month', 'this_year', 'last_7_days', 'last_30_days', 'last_90_days', 'last_365_days', 'all_time']

const DashboardPerformanceFilter = Joi.object()
    .keys({
        filter: Joi.string().valid(...validFilterValues).required().description('The performance filter value the creator is viewing')
    })
    .description('dashboard:performance_filter event attributes ')
    .meta({ name: 'DashboardPerformanceFilter', filename: 'dashboard:performance_filter.model' })

export default DashboardPerformanceFilter
