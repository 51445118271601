import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Flexbox } from 'piconetworks/pkg-box'

import Trigger from './Trigger'

const Container = ({ children, className, disabled, flexboxContainer, onClick, triggerComponent, ...props }) => {
    return (
        <Flexbox.Container className={cx(className)} {...flexboxContainer} {...props}>
            {children}
            {React.isValidElement(triggerComponent) && (
                <Trigger onClick={onClick} disabled={disabled}>
                    {triggerComponent}
                </Trigger>
            )}
        </Flexbox.Container>
    )
}
Container.propTypes = {
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    /**
     * Adding flexbox container props to here,
     */
    flexboxContainer: PropTypes.object,

    /**
     * Disables the onClick Actioon, preventing mouse events,
     */
    disabled: PropTypes.bool,

    children: PropTypes.node.isRequired,

    triggerComponent: PropTypes.any.isRequired,

    /**
     * On click action for bottom of the list button
     */
    onClick: PropTypes.func
}

Container.defaultProps = {
    disabled: false
}

export default Container
