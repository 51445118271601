import React from 'react'
import PropTypes from 'prop-types'
const Trial = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_trial">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M20,11 C20.5522847,11 21,11.4477153 21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 L19,15.9830475 C19,17.1887444 19.5438506,18.3301173 20.4802502,19.0896309 L24.6191694,22.4467083 C25.4770287,23.142518 25.6083959,24.4020156 24.9125862,25.2598749 C24.5328293,25.7280747 23.9621429,26 23.3592944,26 L8.64070555,26 C7.53613605,26 6.64070555,25.1045695 6.64070555,24 C6.64070555,23.3971515 6.91263087,22.8264651 7.38083063,22.4467083 L11.5197498,19.0896309 C12.4561494,18.3301173 13,17.1887444 13,15.9830475 L13,13 L12,13 C11.4477153,13 11,12.5522847 11,12 C11,11.4477153 11.4477153,11 12,11 L20,11 Z M17,13 L15,13 L14.999157,17.938337 C14.9990573,18.5220675 14.743941,19.076622 14.3007398,19.4565088 L14.3007398,19.4565088 L12.499,21 L19.5,21 L17.6975327,19.4547755 C17.2541753,19.0747338 16.9990574,18.5199064 16.9991571,17.9359565 L16.9991571,17.9359565 L17,13 Z M18,8 C18.5522847,8 19,8.44771525 19,9 C19,9.55228475 18.5522847,10 18,10 C17.4477153,10 17,9.55228475 17,9 C17,8.44771525 17.4477153,8 18,8 Z M14.5,6 C15.3284271,6 16,6.67157288 16,7.5 C16,8.32842712 15.3284271,9 14.5,9 C13.6715729,9 13,8.32842712 13,7.5 C13,6.67157288 13.6715729,6 14.5,6 Z M17.5,5 C17.7761424,5 18,5.22385763 18,5.5 C18,5.77614237 17.7761424,6 17.5,6 C17.2238576,6 17,5.77614237 17,5.5 C17,5.22385763 17.2238576,5 17.5,5 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                />
            </g>
        </g>
    </svg>
)

Trial.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Trial
