import Duck from 'extensible-duck'

import auth from '../auth'
import getCheckoutPrice from './selectors/getCheckoutPrice'
import isTrialSelected from './selectors/isTrialSelected'

import { exchangeClaim } from '../claim'

export default function createDuck({ namespace = 'trypico', store, initialState }) {
    return new Duck({
        namespace,
        store,
        initialState,
        selectors: {
            root: (state) => state,
            getCheckoutPrice: ({ state, router }) => getCheckoutPrice({ state, router }),
            isTrialSelected: ({ state, router }) => isTrialSelected({ state, router }),
        },
        exchangeClaim: (claim) => exchangeClaim(claim),
        types: ['INITIALIZE'],
        creators: (duck) => ({
            initialize: (payload = {}) => ({ type: duck.types.INITIALIZE, ...payload }),
        }),
        reducer: (state, { type }, duck) => {
            if (type === auth.types.LOGOUT) {
                switch (duck.store) {
                    case 'landingPage':
                        return state

                    case 'client':
                        return state

                    case 'loader':
                        return state

                    case 'blocks':
                        return state

                    case 'iframely':
                        return state

                    case 'links':
                        return state

                    case 'featureFlags':
                        return state

                    default:
                        return duck.initialState
                }
            }

            if (type === duck.types.INITIALIZE) {
                return duck.initialState
            }

            return state
        },
    })
}
