import PropertyList from "./PropertyList"
import { FormBlockBackgroundType } from "./CompoundedProperties/BlockBackgroundType"
import { FormBlockShadowStyle } from "./CompoundedProperties/BlockShadowStyle"
import { FormPageBackgroundType } from "./CompoundedProperties/PageBackgroundType"
import { FormPageContrastColor } from "./CompoundedProperties/PageContrastColor"
import { FormBlockBackgroundValue } from "./Properties/BlockBackgroundValue"
import { FormBlockContrastColor } from "./CompoundedProperties/BlockContrastColor"
import { FormBlockBorderRadius } from "./Properties/BlockBorderRadius"
import { FormBlockBorderStyle } from "./Properties/BlockBorderStyle"
import { FormBlockBorderType } from "./Properties/BlockBorderType"
import { FormBlockBorderValue } from "./Properties/BlockBorderValue"
import { FormBlockBorderWidth } from "./Properties/BlockBorderWidth"
import { FormBlockShadowType } from "./Properties/BlockShadowType"
import { FormBlockShadowValue } from "./Properties/BlockShadowValue"
import { FormPageBackgroundValue } from "./Properties/PageBackgroundValue"
import { FormPageFontBody } from "./Properties/PageFontBody"
import { FormPageFontHeading } from "./Properties/PageFontHeading"
import { FormPageButtonContrastColor } from "./CompoundedProperties/PageButtonContrastColor"
import { FormBlockButtonContrastColor } from "./CompoundedProperties/BlockButtonContrastColor"

/**
 * Form class
 * @class Form
 * @extends PropertyList
 * @property {string} id - Form id
 */

class Form extends PropertyList {
    constructor(id: string) {
        // console.log('enchilada value FormPageContrastColor', FormPageContrastColor.getAll())
        super({
            id,
            type: 'form',
            properties:  [
                FormBlockBackgroundValue,
                FormBlockBorderRadius,
                FormBlockBorderStyle,
                FormBlockBorderType,
                FormBlockBorderValue,
                FormBlockBorderWidth,
                FormBlockShadowType,
                FormBlockShadowValue,
                FormPageFontBody,
                FormPageFontHeading,
                FormPageBackgroundValue,
                FormBlockBackgroundType,
                FormBlockShadowStyle,
                FormPageBackgroundType,
                FormPageContrastColor,
                FormBlockContrastColor,
                FormPageButtonContrastColor,
                FormBlockButtonContrastColor
            ]
        })
    }
}

export default Form