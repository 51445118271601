import React from 'react'
import PropTypes from 'prop-types'
const Default = ({ width = '42px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 42 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_payment/default" fill="#AAA7B0">
                <path
                    d="M32,2 C33.6568542,2 35,3.34314575 35,5 L35,19 C35,20.6568542 33.6568542,22 32,22 L10,22 C8.34314575,22 7,20.6568542 7,19 L7,5 C7,3.34314575 8.34314575,2 10,2 L32,2 Z M33,12 L9,12 L9,18.5 C9,19.2796961 9.59488808,19.9204487 10.35554,19.9931334 L10.5,20 L31.5,20 C32.3284271,20 33,19.3284271 33,18.5 L33,18.5 L33,12 Z M15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 L12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 L15,16 Z M27,16 C27.5522847,16 28,16.4477153 28,17 C28,17.5522847 27.5522847,18 27,18 L19,18 C18.4477153,18 18,17.5522847 18,17 C18,16.4477153 18.4477153,16 19,16 L27,16 Z M31.5,4 L10.5,4 C9.67157288,4 9,4.67157288 9,5.5 L9,5.5 L9,7 L33,7 L33,5.5 C33,4.72030388 32.4051119,4.07955132 31.64446,4.00686658 L31.5,4 Z"
                    id="shape"
                />
            </g>
        </g>
    </svg>
)

Default.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Default
