import getGiftSagas from './gift'
import getProductSagas from './product'
import getSwitchProductSagas from './switch'

import getPurchaseHistory from './getPurchaseHistory'

const sagas = (duck) => ({
    ...getGiftSagas(duck),
    ...getSwitchProductSagas(duck),
    ...getProductSagas(duck),
    * getPurchaseHistory(payload) {
        yield getPurchaseHistory({ ...payload, ...duck })
    },
})

export default sagas
