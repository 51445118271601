import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './FieldStatus.module.scss'

const FieldStatus = ({ className, message, variant, show, children, ...props }) => {
    const classes = cx(
        style.container,
        variant && `validation-${variant}`,
        {
            [style.visible]: show,
            [style.loading]: variant === 'loading'
        },
        className
    )
    return (
        <div className={classes} data-validation={variant !== 'loading' ? message : null} {...props}>
            {React.cloneElement(children, { isInlineRight: true })}
        </div>
    )
}

FieldStatus.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    /**
     * Inline validation message
     */
    message: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
    show: PropTypes.bool,
    /**
     * Inline validation variant it can be success or danger
     */
    variant: PropTypes.oneOf([ 'success', 'danger', 'warning', 'loading' ])
}

FieldStatus.defaultProps = {
    variant: 'success'
}

export default FieldStatus
