import produce from 'immer'

import { types } from './actions'

export const initialState = {
    wizards: {},
}

const reducer = (state = initialState, { type, payload }) => produce(state, (draftState) => {
    switch (type) {
        case types.SET_CURRENT_STEP:
            draftState.wizards[payload.id] = {
                ...state.wizards[payload.id],
                currentStep: payload.currentStep,
            }
            break

        case types.UPDATE_PAYLOAD:
            // dont update undefined keys
            const formattedPayload = payload.payload
            Object.keys(formattedPayload).forEach(key =>
                formattedPayload[key] === undefined && delete formattedPayload[key]
            )
            draftState.wizards[payload.id].submit.payload = {
                ...draftState.wizards[payload.id].submit.payload,
                ...formattedPayload,
            }
            break

        case types.CREATE_WIZARD:
            draftState.wizards[payload.id] = {
                currentStep: payload.currentStep,
                steps: payload.steps,
                getStepByUrl: (url) => payload.steps.find((step) => step.url === url),
                getStepIndexByUrl: (url) => payload.steps.findIndex((step) => step.url === url),
                getStepByIndex: (index) => payload.steps[index],
                isLastStep: payload.currentStep === payload.steps.length,
                id: payload.id,
                preSubmitHooks: payload.preSubmitHooks,
                submit: {
                    payload: payload.submitPayload,
                    type: payload.submitActionType,
                },
            }
            break

        case types.DELETE_WIZARD:
            delete draftState.wizards[payload.id]
            break

        default:
        // do nothing
    }
})

export default reducer
