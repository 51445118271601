import { compoundedProperty, propertiesByType } from './types'
import { showConsoleLogs } from './utils'

// Takes an array of customizations and returns an object with the css, compoundedProperties, and cssVariables
export const processProperties = (
    customizations,
    isForm,
    serializer,
    isPreview = false,
    isThemePreview = false
): any => {
    let compoundedProperties: compoundedProperty[] = []
    let css = ''
    let cssVariables: any = ''
    try {
        customizations.forEach(async(cust) => {
            return new Promise((resolve, reject) => {
                try {
                    if (cust && cust.hasOwnProperty('property') && cust.hasOwnProperty('value') ) {
                        const serializedProperty = serializer.getByApiPropertyName(cust.property)

                        if (!serializedProperty.isCompounded) {
                            serializedProperty.set(cust.value)
                            if (serializedProperty.cssVariable) {
                                cssVariables += makeCssVariables(serializedProperty)
                            }
                            // if the property has a selector, we want to generate css for it
                            if (serializedProperty.selector) {
                                const cssObject = makeCss(serializedProperty, isForm, isPreview, isThemePreview)
                                if (cssObject) {
                                    css += '\n'
                                    css += cssObject
                                    css += '\n'
                                }
                            }
                            // this was work done for systemizing the generation of additional tags, but was too time consuming  and difficult to explain at the time to implement
                            // if (serializedProperty.hasOwnProperty('generateTagIf') && serializedProperty.generateTagIf(serializedProperty.value)) {
                            //     domTags.push(serializedProperty.generateTagFunction())
                            // }
                        } else {
                            // we add to the compounded properties array to be processed later
                            compoundedProperties.push({
                                serializedProperty,
                                property: cust.property,
                                value: cust.value
                            })
                        }
                        if (showConsoleLogs()) {
                            console.groupCollapsed(`[${isForm ? 'form' : 'theme'}: ${cust.property}]`)
                            console.log('Called with cust', cust)
                            console.log('isForm', isForm)
                            console.log('isPreview', isPreview)
                            console.log('isThemePreview', isThemePreview)
                            console.groupEnd()
                        }
                    }
                    resolve(compoundedProperties)
                        
                } catch (error) {
                    reject(error)
                    console.error(error)
                }
            })
        })
        cssVariables = wrapCssVariables(cssVariables, isForm, isPreview, isThemePreview)

        if (showConsoleLogs()) {
            console.groupCollapsed(`2. [${isForm ? 'form' : 'theme'}] processProperties: [all]`)
            console.count(`${isForm ? 'form' : 'theme'} processProperties`)
            console.log('Called with customizations', customizations)
            console.log('isForm', isForm)
            console.log('Serializer', serializer)
            console.log('isPreview', isPreview)
            console.log('isThemePreview', isThemePreview)
            console.groupEnd()
        }
        return {
            css,
            compoundedProperties,
            cssVariables,
        }
    } catch (error) {
        console.error(error)
    }
}

const sortByPriority = (a, b) => a.priority - b.priority

// Takes an array of compoundedProperties and returns an object with the css and cssVariables
export const processCompoundedProperties = (compoundedProperties, isForm, isPreview = false, isThemePreview = false): any => {
    try {
        let css = ''
        let cssVariables: any = ''
        let prioritiesDone = 0
        const priorities = compoundedProperties.filter((property) => {
            return property.serializedProperty.priority
        }).sort(sortByPriority)
        if (compoundedProperties.length > 0) {
            priorities.forEach((property) => {
                property.serializedProperty.set(property.value)
                if (showConsoleLogs()) {
                    console.groupCollapsed(`[${isForm ? 'form' : 'theme'}: ${property.serializedProperty.name}]`)
                    console.log('property.serializerProperty', property.serializedProperty)
                    console.log('property.value', property.value)
                    console.groupEnd()
                }
                if (property.serializedProperty.cssVariable) {
                    cssVariables += makeCssVariables(property.serializedProperty)
                }
                css += '\n'
                css += makeCss(property.serializedProperty, isForm, isPreview, isThemePreview)
                css += '\n'
                prioritiesDone++
            })
            if (prioritiesDone === priorities.length) {
                compoundedProperties.forEach((property) => {
                    if (property.priority === 0) {
                        property.serializedProperty.set(property.value)
                        if (property.serializedProperty.cssVariable) {
                            cssVariables += makeCssVariables(property.serializedProperty)
                        }
                        css += '\n'
                        css += makeCss(property.serializedProperty, isForm, isPreview, isThemePreview)
                        css += '\n'
                    }
                })
                    
            }
        }
        if (prioritiesDone === priorities.length) {
            cssVariables = wrapCssVariables(cssVariables, isForm, isPreview, isThemePreview)

            if (showConsoleLogs()) {
                console.groupCollapsed(`3. [${isForm ? 'form' : 'theme'}] processCompoundedProperties: [all]`)
                console.count(`${isForm ? 'form' : 'theme'} processCompoundedProperties`)
                console.log('Called with compoundedProperties', compoundedProperties)
                console.log('isForm', isForm)
                console.log('Returning css', css)
                console.log('cssVariables', cssVariables)
                console.log('isPreview', isPreview)
                console.log('isThemePreview', isThemePreview)
                console.groupEnd()
            }

            return {
                compoundedCss: css,
                compoundedCssVariables: cssVariables
            }
        }
            
    } catch (error) {
        console.error(error)
    }
}

// takes a serializedProperty and returns the css for it
export const makeCss = (serializedProperty, isForm, isPreview, isThemePreview): any => {
    try {
        const { selector, cssProperties } = serializedProperty

        if (!selector || !cssProperties) {
            return `/* no selector or cssProperties for ${serializedProperty.propertyFromApi} on ${serializedProperty.type} */
            `
        }
        let css = `${selector} {
    ${cssProperties}
}`

        const prefix = makePrefix(isForm, isPreview, isThemePreview)
        const prefixedSelector = prefixSelector(prefix, selector)

        // check to see if the selector is the same as the prefixed selector
        if (prefixedSelector !== selector) {
            css = `${prefixedSelector} { 
    ${cssProperties} 
}`
        } else {
            if (isForm) {
                css = `#form ${css}`
            }
            if (isPreview) {
                css = `#preview ${css}`
            }
            if (isThemePreview) {
                css = `#theme-preview ${css}`
            }
        }

        if (showConsoleLogs()) {
            console.groupCollapsed(`[${isForm ? 'form' : 'theme'}: ${serializedProperty.propertyFromApi}] makeCss`)
            console.log('Called with serializedProperty', serializedProperty)
            console.log('isForm', isForm)
            console.log('isPreview', isPreview)
            console.log('isThemePreview', isThemePreview)
            console.log('Returning css', css)
            console.groupEnd()
        }
        return css

    } catch (error) {
        console.error(error)
    }
}

// takes whether it's a form, preview, or theme and returns the correct cascade for the css with the appropriate prefix
export const makePrefix = (isForm, isPreview = false, isThemePreview = false) => {
    let prefix = ''
    if (isThemePreview) {
        prefix += '#theme-preview '
    }
    if (isPreview) {
        prefix += '#preview '
    }
    if (isForm) {
        prefix += '#form '
    }
    return prefix
}

// this is for handling things like h1, h2, h3, etc and making sure we don't have any CSS pollution outside of the preview
export const prefixSelector = (prefix, selector) => {
    try {
        if (prefix && selector?.includes(',')) {
            const selectors = selector.split(',')
            return selectors.map(s => prefix + ' ' + s.trim()).join(', ')
        }
        return selector
            
    } catch (error) {
        console.error(error)
    }
}

// takes the serializedProperty and returns the css variables for it, and optionally if we need the stringified rgb version too
export const makeCssVariables = (serializedProperty) => {
    try {
        if (showConsoleLogs()) {
            console.groupCollapsed(`makeCssVariables ${serializedProperty.type}: ${serializedProperty.name}`)
            console.log(serializedProperty)
            console.groupEnd()
        }
        let cssVars = ''
        if (serializedProperty?.convertToRgb && serializedProperty._cssVariableValue && serializedProperty?.value !== 'transparent') {
            cssVars += `
        ${serializedProperty.cssVariable}-rgb: ${serializedProperty?.stringifiedRgb};`
        }
        if (serializedProperty.cssVariable && serializedProperty._cssVariableValue) {
            cssVars += `
        ${serializedProperty.cssVariable}: ${serializedProperty._cssVariableValue};`
        }
        return cssVars
    } catch (error) {
        console.error(error)
    }
}

// TODO: test this
export const wrapCssVariables = (cssVariables, isForm = false, isPreview = false, isThemePreview = false) => {
    try {
        let value = ''
        if (isThemePreview) {
            value = `#theme-preview #form { ${cssVariables} \n}`
            return value
        } else if (isPreview) {
            value = `#preview #form { ${cssVariables} \n}`
            return value
        } else if (isForm) {
            value = `#form { ${cssVariables} \n}`
            return value
        } else {
            value = `:root { ${cssVariables} \n}`
            return value
        }
            
    } catch (error) {
        console.error(error)
    }
}

export const getPropertiesByType = (serializer, customizations) => {
    try {
        let properties: propertiesByType[] = []
        let compoundedProperties: propertiesByType[] = []
        customizations.forEach((cust) => {
            if (cust && cust.hasOwnProperty('property') && cust.hasOwnProperty('value')) {
                const prop = serializer.getByApiPropertyName(cust.property)
                if (prop.isCompounded) {
                    compoundedProperties.push({
                        [cust.property]: {
                            property: prop,
                            value: cust.value
                        }
                    })
                } else {
                    properties.push({
                        [cust.property]: {
                            property: prop,
                            value: cust.value
                        }
                    })
                }
            }
        })
        if (showConsoleLogs()) {
            console.groupCollapsed('getPropertiesByType')
            console.log('Called with serializer', serializer)
            console.log('customizations', customizations)
            console.log('Returning properties', properties)
            console.log('compoundedProperties', compoundedProperties)
            console.groupEnd()
        }
        return {
            properties,
            compoundedProperties
        }
    } catch (error) {
        console.error(error)
    }
}

// export const processPropertiesFromTypedProperties = (serializer, serializedProperties) {

//   let css = "";

//   serializedProperties.forEach(({ customizationName: {
//     property,
//     value
//   }}) => {
//     console.log(customizationName, property, value)
//     property.set(value)

//         // console.log('serialized value ends up being ', serializedProperty)
//         const cssObject = makeCss(property, isForm);
//         if (cssObject) {
//           css += cssObject;
//         }
//         // console.log('FORM', isForm, serializedProperty)
//         // console.log('THEME', isForm, serializedProperty)
//       } else {
//         compoundedProperties.push({
//           serializedProperty,
//           property: cust.value,
//           value: cust.value
//         })
//       }
//     }
//   });

//   return {
//     css,
//     compoundedProperties,
//   };
// };


// export const setValue = (serializedProperty, value) => {
//   serializedProperty.set(value)
//   return serializedProperty
// }
