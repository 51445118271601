import RGB from './RGB'

const HSL = {
    toRGB: ({ h, s, l }) => {
        let red, green, blue
        let saturation = s / 100
        let lightness = l / 100

        if (saturation === 0) {
            red = green = blue = l // achromatic
            return { r: red, g: green, b: blue }
        } else {
            let c = (1 - Math.abs(2 * lightness - 1)) * saturation
            let x = c * (1 - Math.abs((h / 60) % 2 - 1))
            let m = lightness - c/2,
                red = 0,
                green = 0,
                blue = 0

            if (0 <= h && h < 60) {
                red = c
                green = x
                blue = 0
            } else if (60 <= h && h < 120) {
                red = x
                green = c
                blue = 0
            } else if (120 <= h && h < 180) {
                red = 0
                green = c
                blue = x
            } else if (180 <= h && h < 240) {
                red = 0
                green = x
                blue = c
            } else if (240 <= h && h < 300) {
                red = x
                green = 0
                blue = c
            } else if (300 <= h && h < 360) {
                red = c
                green = 0
                blue = x
            }
            red = Math.round((red + m) * 255)
            green = Math.round((green + m) * 255)
            blue = Math.round((blue + m) * 255)
            return { r: red, g: green, b: blue }
        }
    },
    toHEX: (hsl) => RGB.toHEX(HSL.toRGB(hsl)),
    saturate: (hsl, amount = 10) => {
        hsl.s += amount
        hsl.s = Math.min(100, Math.max(0, hsl.s))
        return hsl
    },
    desaturate: (hsl, amount = 10) => {
        hsl.s -= amount
        hsl.s = Math.min(100, Math.max(0, hsl.s))
        return hsl
    },
    darken: (hsl, amount = 10) => {
        hsl.l -= amount
        hsl.l = Math.min(100, Math.max(0, hsl.l))
        return hsl
    },
    lighten: (hsl, amount = 10) => {
        hsl.l += amount
        hsl.l = Math.min(100, Math.max(0, hsl.l))
        return hsl
    }
}

export default HSL
