import React from 'react'
import PropTypes from 'prop-types'
const Abandonment = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_abandonment">
                <polygon
                    id="bounds"
                    fill="#FFEFEE"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M14.5,23 C15.8807119,23 17,24.1192881 17,25.5 C17,26.8807119 15.8807119,28 14.5,28 C13.1192881,28 12,26.8807119 12,25.5 C12,24.1192881 13.1192881,23 14.5,23 Z M23.5,23 C24.8807119,23 26,24.1192881 26,25.5 C26,26.8807119 24.8807119,28 23.5,28 C22.1192881,28 21,26.8807119 21,25.5 C21,24.1192881 22.1192881,23 23.5,23 Z M8,6 C8.4365242,6 8.80772097,6.27969998 8.94415089,6.66966052 C8.96057338,6.70798683 8.97478022,6.74776881 8.98657347,6.78889675 L12.7530258,20 L26,20 C26.5522847,20 27,20.4477153 27,21 C27,21.5522847 26.5522847,22 26,22 L12,22 C11.5274099,22 11.131388,21.6721728 11.0269366,21.2315208 C11.0192549,21.210257 11.0122367,21.1885891 11.0059125,21.1665342 L7.25216668,8 L5,8 C4.44771525,8 4,7.55228475 4,7 C4,6.44771525 4.44771525,6 5,6 L8,6 Z M27.2803895,9 C27.6426498,9 27.8743291,9.25858105 27.8300182,9.59407246 L27.8109814,9.6873405 L25.4390186,18.3126595 C25.344745,18.6554729 24.9994858,18.9430392 24.6389927,18.9924908 L24.5306235,19 L14.4693765,19 C14.1170073,19 13.7408441,18.7456754 13.5969316,18.4142296 L13.5609814,18.3126595 L11.1890186,9.6873405 C11.0939593,9.34167031 11.2850684,9.05217185 11.6237023,9.0063226 L11.7196105,9 L27.2803895,9 Z M16.7924873,11.2924873 C16.4325029,11.6524718 16.4048118,12.2189168 16.709414,12.6106643 L16.7924873,12.7047411 L18.0877462,14 L16.7924873,15.2952589 C16.4025042,15.685242 16.4025042,16.3175295 16.7924873,16.7075127 C17.1524718,17.0674971 17.7189168,17.0951882 18.1106643,16.790586 L18.2047411,16.7075127 L19.5,15.4122538 L20.7952589,16.7075127 C21.185242,17.0974958 21.8175295,17.0974958 22.2075127,16.7075127 C22.5674971,16.3475282 22.5951882,15.7810832 22.290586,15.3893357 L22.2075127,15.2952589 L20.9122538,14 L22.2075127,12.7047411 C22.5974958,12.314758 22.5974958,11.6824705 22.2075127,11.2924873 C21.8475282,10.9325029 21.2810832,10.9048118 20.8893357,11.209414 L20.7952589,11.2924873 L19.5,12.5877462 L18.2047411,11.2924873 C17.814758,10.9025042 17.1824705,10.9025042 16.7924873,11.2924873 Z"
                    id="Combined-Shape"
                    fill="#FF4440"
                />
            </g>
        </g>
    </svg>
)

Abandonment.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Abandonment
