import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Boutton from 'piconetworks/pkg-boutton'
import styles from './LandingWizardArrow.module.scss'
import { Icon } from './Icon'

const noop = () => { }

const LandingWizardArrow = (props) => {
    const {
        className: customClass,
        buttonType = 'submit',
        onClick = noop,
        iconClass,
        done,
        disabled,
        style,
        iconType
    } = props

    return (
        <Boutton
            type={buttonType}
            onClick={onClick}
            style={{ backgroundColor: style.backgroundColor }}
            disabled={!!disabled}
            className={cx(styles.wizardArrow, customClass, { [styles.done]: !!done })}
            size="sm"
            variant="secondary"
            icon={
                <Icon
                    icon={iconType}
                    className={iconClass}
                    width="1rem"
                    height="1rem"
                />
            }
        />
    )
}

LandingWizardArrow.defaultProps = {
    className: '',
    buttonType: 'submit',
    onClick: noop,
    iconClass: null,
    done: false,
    disabled: false,
    style: {
        color: '#834eff',
        backgroundColor: '#ffffff'
    }
}

LandingWizardArrow.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonType: PropTypes.string,
    onClick: PropTypes.func,
    /* this prop is for backward compatibility */
    iconClass: PropTypes.string,
    done: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
    }),
    iconType: PropTypes.oneOf(['left', 'right', 'check'])
}

export default LandingWizardArrow
