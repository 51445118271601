import React, { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Router, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import dynamic from 'next/dynamic'


import Client from 'modules/client'
import LandingPage from 'modules/landing-page'

import { useHistory } from 'lib/RouterAdapterDecorator'

import AppContainer from '../AppContainer'

// GENERATED TEXT - START

// Whenever there is a new file added to the /pages directory
// run './copy_routes.js | copy' in the project root to generate and
// copy the text to your clipboard. Replace all of this generated text with the new text.

const Pages404 = dynamic(() => import('pages/404'))
const PagesIndex = dynamic(() => import('pages/index'))
const Pages_company_slug__short_code_route_ = dynamic(() => import('pages/[company_slug]/[short_code_route]'))
const Pages_company_slug__checkout_with_plan = dynamic(() => import('pages/[company_slug]/_checkout-with-plan'))
const Pages_company_slug__index = dynamic(() => import('pages/[company_slug]/_index'))
const Pages_company_slug__redeem_gift = dynamic(() => import('pages/[company_slug]/_redeem-gift'))
const Pages_company_slug_Checkout_with_gift = dynamic(() => import('pages/[company_slug]/checkout-with-gift'))
const Pages_company_slug_Checkout = dynamic(() => import('pages/[company_slug]/checkout'))
const Pages_company_slug_Confirm_payment = dynamic(() => import('pages/[company_slug]/confirm-payment'))
const Pages_company_slug_Convert_gift = dynamic(() => import('pages/[company_slug]/convert-gift'))
const Pages_company_slug_Create_account = dynamic(() => import('pages/[company_slug]/create-account'))
const Pages_company_slug_Donate = dynamic(() => import('pages/[company_slug]/donate'))
const Pages_company_slug_Downloads = dynamic(() => import('pages/[company_slug]/downloads'))
const Pages_company_slug_Finalize_payment = dynamic(() => import('pages/[company_slug]/finalize-payment'))
const Pages_company_slug_Gift_form = dynamic(() => import('pages/[company_slug]/gift-form'))
const Pages_company_slug_Gift = dynamic(() => import('pages/[company_slug]/gift'))
const Pages_company_slug_Logout = dynamic(() => import('pages/[company_slug]/logout'))
const Pages_company_slug_Manage_cards = dynamic(() => import('pages/[company_slug]/manage-cards'))
const Pages_company_slug_Offer_options = dynamic(() => import('pages/[company_slug]/offer-options'))
const Pages_company_slug_Offer = dynamic(() => import('pages/[company_slug]/offer'))
const Pages_company_slug_Pico_link = dynamic(() => import('pages/[company_slug]/pico-link'))
const Pages_company_slug_Picolink = dynamic(() => import('pages/[company_slug]/picolink'))
const Pages_company_slug_Registration = dynamic(() => import('pages/[company_slug]/registration'))
const Pages_company_slug_Select_donation = dynamic(() => import('pages/[company_slug]/select-donation'))
const Pages_company_slug_Select_newsletters = dynamic(() => import('pages/[company_slug]/select-newsletters'))
const Pages_company_slug_Select_plan = dynamic(() => import('pages/[company_slug]/select-plan'))
const Pages_company_slug_Thank_you_checkout = dynamic(() => import('pages/[company_slug]/thank-you-checkout'))
const Pages_company_slug_Thank_you_gift_redeem = dynamic(() => import('pages/[company_slug]/thank-you-gift-redeem'))
const Pages_company_slug_Thank_you_gift = dynamic(() => import('pages/[company_slug]/thank-you-gift'))
const Pages_company_slug_Thank_you_plan_checkout = dynamic(() => import('pages/[company_slug]/thank-you-plan-checkout'))
const Pages_company_slug_Thank_you = dynamic(() => import('pages/[company_slug]/thank-you'))
const Pages_company_slug_Verify = dynamic(() => import('pages/[company_slug]/verify'))
const Pages_company_slug_CardsAdd = dynamic(() => import('pages/[company_slug]/cards/add'))
const Pages_company_slug_CardsIndex = dynamic(() => import('pages/[company_slug]/cards/index'))
const Pages_company_slug_CardsSelect = dynamic(() => import('pages/[company_slug]/cards/select'))
const Pages_company_slug_GroupConfirm = dynamic(() => import('pages/[company_slug]/group/confirm'))
const Pages_company_slug_GroupIndex = dynamic(() => import('pages/[company_slug]/group/index'))
const Pages_company_slug_GroupJoin = dynamic(() => import('pages/[company_slug]/group/join'))
const Pages_company_slug_GroupPay = dynamic(() => import('pages/[company_slug]/group/pay'))
const Pages_company_slug_LoginEnter_email = dynamic(() => import('pages/[company_slug]/login/enter-email'))
const Pages_company_slug_LoginEnter_password = dynamic(() => import('pages/[company_slug]/login/enter-password'))
const Pages_company_slug_LoginIndex = dynamic(() => import('pages/[company_slug]/login/index'))
const Pages_company_slug_LoginLink_confirmation = dynamic(() => import('pages/[company_slug]/login/link-confirmation'))
const Pages_company_slug_LoginLink = dynamic(() => import('pages/[company_slug]/login/link'))
const Pages_company_slug_LoginReset = dynamic(() => import('pages/[company_slug]/login/reset'))
const Pages_company_slug_LoginSignup_link = dynamic(() => import('pages/[company_slug]/login/signup-link'))
const Pages_company_slug_LoginToken = dynamic(() => import('pages/[company_slug]/login/token'))
const PagesErrorIndex = dynamic(() => import('pages/error/index'))

const routes = {
    '/404': Pages404,
    '/index': PagesIndex,
    '/': PagesIndex,
    '/[company_slug]/[short_code_route]': Pages_company_slug__short_code_route_,
    '/[company_slug]/_checkout-with-plan': Pages_company_slug__checkout_with_plan,
    '/[company_slug]/checkout-with-plan': Pages_company_slug__checkout_with_plan,
    '/[company_slug]/_index': Pages_company_slug__index,
    '/[company_slug]': Pages_company_slug__index,
    '/[company_slug]/index': Pages_company_slug__index,
    '/[company_slug]/_redeem-gift': Pages_company_slug__redeem_gift,
    '/[company_slug]/redeem-gift': Pages_company_slug__redeem_gift,
    '/[company_slug]/checkout-with-gift': Pages_company_slug_Checkout_with_gift,
    '/[company_slug]/checkout': Pages_company_slug_Checkout,
    '/[company_slug]/confirm-payment': Pages_company_slug_Confirm_payment,
    '/[company_slug]/convert-gift': Pages_company_slug_Convert_gift,
    '/[company_slug]/create-account': Pages_company_slug_Create_account,
    '/[company_slug]/donate': Pages_company_slug_Donate,
    '/[company_slug]/downloads': Pages_company_slug_Downloads,
    '/[company_slug]/finalize-payment': Pages_company_slug_Finalize_payment,
    '/[company_slug]/gift-form': Pages_company_slug_Gift_form,
    '/[company_slug]/gift': Pages_company_slug_Gift,
    '/[company_slug]/logout': Pages_company_slug_Logout,
    '/[company_slug]/manage-cards': Pages_company_slug_Manage_cards,
    '/[company_slug]/offer-options': Pages_company_slug_Offer_options,
    '/[company_slug]/offer': Pages_company_slug_Offer,
    '/[company_slug]/pico-link': Pages_company_slug_Pico_link,
    '/[company_slug]/picolink': Pages_company_slug_Picolink,
    '/[company_slug]/registration': Pages_company_slug_Registration,
    '/[company_slug]/select-donation': Pages_company_slug_Select_donation,
    '/[company_slug]/select-newsletters': Pages_company_slug_Select_newsletters,
    '/[company_slug]/select-plan': Pages_company_slug_Select_plan,
    '/[company_slug]/thank-you-checkout': Pages_company_slug_Thank_you_checkout,
    '/[company_slug]/thank-you-gift-redeem': Pages_company_slug_Thank_you_gift_redeem,
    '/[company_slug]/thank-you-gift': Pages_company_slug_Thank_you_gift,
    '/[company_slug]/thank-you-plan-checkout': Pages_company_slug_Thank_you_plan_checkout,
    '/[company_slug]/thank-you': Pages_company_slug_Thank_you,
    '/[company_slug]/verify': Pages_company_slug_Verify,
    '/[company_slug]/cards/add': Pages_company_slug_CardsAdd,
    '/[company_slug]/cards/index': Pages_company_slug_CardsIndex,
    '/[company_slug]/cards': Pages_company_slug_CardsIndex,
    '/[company_slug]/cards/select': Pages_company_slug_CardsSelect,
    '/[company_slug]/group/confirm': Pages_company_slug_GroupConfirm,
    '/[company_slug]/group/index': Pages_company_slug_GroupIndex,
    '/[company_slug]/group': Pages_company_slug_GroupIndex,
    '/[company_slug]/group/join': Pages_company_slug_GroupJoin,
    '/[company_slug]/group/pay': Pages_company_slug_GroupPay,
    '/[company_slug]/login/enter-email': Pages_company_slug_LoginEnter_email,
    '/[company_slug]/login/enter-password': Pages_company_slug_LoginEnter_password,
    '/[company_slug]/login/index': Pages_company_slug_LoginIndex,
    '/[company_slug]/login': Pages_company_slug_LoginIndex,
    '/[company_slug]/login/link-confirmation': Pages_company_slug_LoginLink_confirmation,
    '/[company_slug]/login/link': Pages_company_slug_LoginLink,
    '/[company_slug]/login/reset': Pages_company_slug_LoginReset,
    '/[company_slug]/login/signup-link': Pages_company_slug_LoginSignup_link,
    '/[company_slug]/login/token': Pages_company_slug_LoginToken,
    '/page-404/index': PagesErrorIndex,
    '/page-404': PagesErrorIndex
}

// GENERATED TEXT - END

const Test = (props) => {
    const router = useRouter()

    const shortCodeRoute = router?.query?.short_code_route || router?.query?.short_code
    const optionId = router?.query?.option_id
    const giftId = router?.query?.gift_id
    const claim = router?.query?.claim
    const pathSegment2 = router?.pathname?.replace(`/[company_slug]`, '')?.split('/')?.[1]

    const client = useSelector(Client.selectors.client)
    const publisher = client || {}
    const creatorUserName = publisher?.username
    const companySlug = router?.query?.company_slug
    const publisherId = publisher?.id

    const location = props.location.location || props.location
    const pathname = location.pathname
    const restOfUrl = pathname.replace(`/${companySlug}`, '')

    const urlToFind = pathname === '/' ? '/' : `/[company_slug]${restOfUrl}`

    const Component = routes[urlToFind]

    const getLandingPage = useCallback((state) => LandingPage.selectors.getLandingPage(state, shortCodeRoute), [shortCodeRoute])

    const landingPage = useSelector(getLandingPage, isEqual)

    if (!Component) {
        console.error({ urlToFind, result: 'could not be found' })
    } else {
        console.log({ urlToFind, landingPage: { id: landingPage?.id, name: landingPage?.name } })
    }

    return (
        <>
            {Component && (
                <AppContainer client={client} publisher={publisher} publisherId={publisherId} landingPage={landingPage}>
                    <Component
                        claim={claim}
                        landingPage={landingPage}
                        client={client}
                        publisherId={publisherId}
                        companySlug={companySlug}
                        creatorUserName={creatorUserName}
                        shortCodeRoute={shortCodeRoute}
                        optionId={optionId}
                        pathSegment2={pathSegment2}
                        giftId={giftId}
                    />
                </AppContainer>
            )}
            {!Component && <Pages404 />}
        </>
    )
}

const Routes = () => {
    
    const history = useHistory()

    return (
        <Router history={history}>
            <Switch>
                <Route path="*" component={Test} />
            </Switch>
        </Router>
    )
}

export default Routes
