const authMethods = [
    'google',
    'facebook',
    'github',
    'twitter',
    'linkedin',
    'email',
    'sms'
]

export default authMethods
