import { produce } from 'immer'

export const initialState = {
    links: [],
    loading: false,
    error: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_SOCIAL_LINKS:
            draftState.error = initialState.error
            draftState.loading = true
            break

        case duck.types.CREATE_LINK:
        case duck.types.UPDATE_LINK:
        case duck.types.DELETE_LINK:
            draftState.error = initialState.error
            draftState.loading = true
            break

        case duck.types.GET_SOCIAL_LINKS_SUCCESS:
            draftState.links = payload
            draftState.loading = false
            break

        case duck.types.GET_SOCIAL_LINKS_ERROR:
        case duck.types.CREATE_LINK_ERROR:
        case duck.types.UPDATE_LINK_ERROR:
        case duck.types.DELETE_LINK_ERROR:
            draftState.error = error
            draftState.loading = false
            break

        default:
        // do nothing
    }
})

export default reducer
