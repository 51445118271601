import React from 'react'
import PropTypes from 'prop-types'
const Access = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_access">
                <polygon
                    id="bounds"
                    fill="#EBFAF2"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M9,6 C10.1045695,6 11,6.8954305 11,8 C11,8.7398375 10.5982846,9.38584934 10.0010775,9.73181186 L10,24 C11.0543618,24 11.9181651,24.8158778 11.9945143,25.8507377 L12,26 L6,26 C6,24.8954305 6.8954305,24 8,24 L7.99992752,9.73239368 C7.40216612,9.38657394 7,8.74025244 7,8 C7,6.8954305 7.8954305,6 9,6 Z M23,6 C24.1045695,6 25,6.8954305 25,8 C25,8.7398375 24.5982846,9.38584934 24.0010775,9.73181186 L24,24 C25.0543618,24 25.9181651,24.8158778 25.9945143,25.8507377 L26,26 L20,26 C20,24.8954305 20.8954305,24 22,24 L21.9999275,9.73239368 C21.4021661,9.38657394 21,8.74025244 21,8 C21,6.8954305 21.8954305,6 23,6 Z M21,10.3374332 L21,14.4536884 C19.6731088,16.0371548 17.9208861,17 16,17 C14.0791139,17 12.3268912,16.0371548 11,14.4536884 L11,10.3374332 C11.8696105,13.0892712 13.7813983,15 16,15 C18.2186017,15 20.1303895,13.0892712 21,10.3374332 Z"
                    id="Combined-Shape"
                    fill="#28BF6F"
                />
            </g>
        </g>
    </svg>
)

Access.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Access
