import React from 'react'
import PropTypes from 'prop-types'
import LandingWizardArrow from 'piconetworks/pkg-landing-wizard-arrow'

import style from './scss/ErrorWithCard.module.scss'

const ErrorWithCard = ({ dismissAlert, errorMessage }) => (
    <div className={style.PicoLoaderContainer_modal}>
        <div className={style.PicoLoader_modal}>
            <h3 className={style.topContainer}>
                Hmm. That didn&apos;t seem to work.
            </h3>
            <div className={style.arrowContainer}>
                <LandingWizardArrow
                    onClick={(e) => {
                        e.preventDefault()

                        dismissAlert()
                    }}
                    buttonType="button"
                    iconClass="icon-left"
                />
            </div>
            <h3 className={style.bottomContainer}>{errorMessage}</h3>
        </div>
    </div>
)

ErrorWithCard.defaultProps = {
    errorMessage:
        'We were unable to process your card. Please make sure the card details are correct and try again.',
    dismissAlert: () => {}
}

ErrorWithCard.propTypes = {
    errorMessage: PropTypes.string,
    dismissAlert: PropTypes.func
}

export default ErrorWithCard
