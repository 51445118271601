import React, { useEffect, useRef, useState } from 'react'
import MenuWrapper from './components/MenuWrapper'
import PropTypes from 'prop-types'

import { Form as ReactFinalForm, Field } from 'react-final-form'
import { TabIcons } from './utils/icons'
import styles from './scss/MenuRoutes.module.scss'
import cx from 'classnames'
import Form from 'piconetworks/pkg-form'
import Button from 'piconetworks/pkg-boutton'
import SocialLogin from 'piconetworks/pkg-field-social-login'
import Spacing from 'piconetworks/pkg-spacing'
import PhoneField from './components/phone'

import { fieldValidations, composeValidators } from './utils/validations'

const Login = ({ defaultAuthenticationMethod, ...props }) => {
    const [currTab, setCurrTab] = useState(defaultAuthenticationMethod || 'email')
    const [hasPassword, setHasPassword] = useState(false)
    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)

    const {
        authenticationMethods = [],
        contrastColor,
        linkColor,
        initializeLoginFlow,
        fieldContainerProps,
        showCreateAccount = true,
        origin,
        onLogin = () => { },
        publisherId,
        userId,
        access_token,
        scope = 'user',
        sendExternalLink,
        publisherName,
        utmRootUrl,
        queryOverride,
        onFailureSocialLogin,
        onClickCreateAnAccount,
        menuWrappperClass,
        goToMenuRouteAndOpen,
        userLoader,
        changeMenuRoute,
    } = props

    useEffect(() => {
        if (emailInputRef?.current) {
            emailInputRef.current.focus()
        }

        return () => {
            setHasPassword(false)
        }
    }, [])

    useEffect(() => {
        if (userLoader) {
            changeMenuRoute('/')
        }
    }, [JSON.stringify(userLoader)])

    const socialLoginProps = {
        authenticationMethods,
        fieldContainerProps,
        origin,
        name: 'socialLink',
        onLogin,
        publisherId,
        userId,
        access_token,
        scope,
        linkColor,
        params: {
            is_logging_in: 'true',
        },
    }

    const tabs = [
        ...(authenticationMethods.includes('sms') ? ['sms'] : []),
        ...(authenticationMethods.includes('email') ? ['email'] : []),
    ]
    const socialLoginMethods = authenticationMethods.filter(
        (method) => !['email', 'sms'].includes(method)
    )

    const submitLoginForm = async ({ email, phone, password }) => {
        if (hasPassword ? password : true) {
            const credentials = {
                password,
                hasPassword,
                publisherId,
                setHasPassword,
            }

            if (currTab === 'sms') {
                Object.assign(credentials, { phone })
            } else {
                Object.assign(credentials, { email })
            }

            initializeLoginFlow(credentials)
        }
    }

    const onEnterEmail = (e, values) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            if (hasPassword ? values.password : true) {
                submitLoginForm(values)
            }
        }
    }

    const onPasswordResetRequest = ({ email, phone }) => {
        if (!email && !phone) {
            throw new Error('Email or phone is required')
        }

        goToMenuRouteAndOpen('/login/otp', {
            email,
            phone,
            redirect: '/editProfile/editPassword',
        })
    }

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
            customFooter={null}
            hasCustomFooter
            className={cx(styles.loginHeight, menuWrappperClass)}
            userId={userId}
        >
            <div className={cx(styles.menuHeader, styles.noBottomMargin)}>
                <h3>Log In</h3>
            </div>
            <ReactFinalForm
                initialValues={{
                    publisherId,
                }}
                onSubmit={() => { }}
                keepDirtyOnResubmit={true}
                keepDirtyOnReinitialize
                render={({ values }) => (
                    <form>
                        <div>
                            {tabs.length > 1 && (
                                <div
                                    className={styles.tabs}
                                    data-testid='toggle'
                                >
                                    {tabs.map((tab, idx) => (
                                        <div
                                            key={`${tab}-${idx}`}
                                            className={cx(styles.tab, {
                                                [styles['tab--active']]:
                                                    currTab === tab,
                                            })}
                                            onClick={() => setCurrTab(tab)}
                                        >
                                            {TabIcons[tab]()}
                                            {currTab === tab && (
                                                <div
                                                    className={styles.triangle}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className={styles.loginWrapper}>
                                <div
                                    className={cx(styles.loginContainer, {
                                        [styles.tabsExists]: tabs.length > 1,
                                        [styles['d-none']]: currTab === 'sms',
                                        [styles['d-block']]: currTab === 'email',
                                    })}
                                >
                                    <div
                                        className={cx({
                                            [styles['d-none']]: !tabs.includes('email'),
                                        })}
                                    >
                                        <div>
                                            <Field
                                                formFieldClassName={
                                                    styles.field
                                                }
                                                {...(tabs.length !== 2 && { label: 'Email Address' })}
                                                name='email'
                                                type='text'
                                                placeholder='name@domain.com'
                                                validate={composeValidators(
                                                    fieldValidations.email
                                                )}
                                            >
                                                {({ meta, input, ...rest }) => (
                                                    <Form.Input
                                                        {...rest}
                                                        meta={meta}
                                                        ref={emailInputRef}
                                                        input={input}
                                                        onKeyDown={(e) =>
                                                            onEnterEmail(e, {
                                                                email: values.email,
                                                                password:
                                                                    values.password,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div
                                            className={cx({
                                                [styles['d-none']]: !hasPassword,
                                            })}
                                        >
                                            <Field
                                                formFieldClassName={
                                                    styles.field
                                                }
                                                label='Password'
                                                name='password'
                                                type='password'
                                                placeholder='******'
                                            >
                                                {({ meta, input, ...rest }) => (
                                                    <Form.Input
                                                        {...rest}
                                                        meta={meta}
                                                        ref={emailInputRef}
                                                        input={input}
                                                        themeColor={linkColor}
                                                        onKeyDown={(e) =>
                                                            onEnterEmail(e, {
                                                                email: values.email,
                                                                password:
                                                                    values.password,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </Field>
                                            <p className={styles.passwordReset}>Forgot your password?
                                                <span
                                                    style={{
                                                        color: linkColor,
                                                    }}
                                                    onClick={() => onPasswordResetRequest({ email: values.email })}
                                                >
                                                    Reset your password
                                                </span>
                                            </p>
                                        </div>

                                        <div>
                                            <Button
                                                variant='primary'
                                                onClick={() => {
                                                    submitLoginForm({
                                                        email: values.email,
                                                        password:
                                                            values.password,
                                                    })
                                                }}
                                                block
                                                className={cx(
                                                    styles.signupButton
                                                )}
                                                disabled={hasPassword && !values.password}
                                            >
                                                Log in
                                            </Button>
                                        </div>
                                        {socialLoginMethods.length > 0 && (
                                            <Spacing.HorizontalDivider
                                                className={styles.divider}
                                                withLine
                                            >
                                                OR LOG IN WITH
                                            </Spacing.HorizontalDivider>
                                        )}
                                    </div>
                                    <div className={styles.social}>
                                        <SocialLogin
                                            {...socialLoginProps}
                                            onFailure={onFailureSocialLogin}
                                            fieldContainerProps={{
                                                className:
                                                    styles.socialContainer,
                                            }}
                                            handleClick={() => { }}
                                            block
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx(
                                        styles.loginContainer,
                                        styles.mobile,
                                        {
                                            [styles.tabsExists]:
                                                tabs.length > 1,
                                            [styles['d-none']]: currTab === 'email',
                                            [styles['d-block']]: currTab === 'sms',
                                        }
                                    )}
                                >
                                    <PhoneField
                                        checkPhone={({ phone }) => {
                                            submitLoginForm({
                                                phone: phone,
                                                password: values.password,
                                            })
                                        }}
                                        contrastColor={contrastColor}
                                        description
                                        {...(tabs.length !== 2 && { label: 'Phone number' })}
                                        isReturningUser
                                        linkColor={linkColor}
                                        isLogin
                                        displayTerms={false}
                                        onSubmitCallback={() => { }}
                                        phoneInputRef={phoneInputRef}
                                    />
                                    <div
                                        className={cx({
                                            [styles['d-none']]: !hasPassword,
                                        })}
                                    >
                                        <Field
                                            formFieldClassName={styles.field}
                                            label='Password'
                                            name='password'
                                            type='password'
                                            placeholder='******'
                                        >
                                            {({ meta, input, ...rest }) => (
                                                <Form.Input
                                                    {...rest}
                                                    meta={meta}
                                                    ref={emailInputRef}
                                                    input={input}
                                                    onKeyDown={(e) =>
                                                        onEnterEmail(e, {
                                                            phone: window?.loginIti?.getNumber(),
                                                            password:
                                                                values.password,
                                                        })
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <p className={styles.passwordReset}>Forgot your password?
                                            <span
                                                style={{
                                                    color: linkColor,
                                                }}
                                                onClick={() => onPasswordResetRequest({ phone: values.phone })}
                                            >
                                                Reset your password
                                            </span>
                                        </p>
                                    </div>
                                    <Button
                                        variant='primary'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const phone =
                                                window?.loginIti?.getNumber()
                                            submitLoginForm({
                                                phone,
                                                password: values.password,
                                            })
                                        }}
                                        block
                                        disabled={
                                            typeof phoneInputRef?.current
                                                ?.value === 'undefined' ||
                                            phoneInputRef?.current?.value === ''
                                            || hasPassword && !values.password
                                        }
                                        styles={{
                                            backgroundColor: linkColor,
                                            color: contrastColor,
                                        }}
                                    >
                                        Log in
                                    </Button>
                                </div>
                                <div className={styles.bottomCTA}>
                                    {showCreateAccount && (
                                        <p className={styles.bottomText}>
                                            Don’t have an account?
                                            <span
                                                // style={{ color: linkColor }}
                                                className={styles.bottomLink}
                                                onClick={onClickCreateAnAccount}
                                            >
                                                Create an account.
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
        </MenuWrapper>
    )
}

Login.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null,
}

Login.propTypes = {
    access_token: PropTypes.string,
    authenticationMethods: PropTypes.array,
    contrastColor: PropTypes.string,
    defaultAuthenticationMethod: PropTypes.string,
    fieldContainerProps: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    goToMenuRouteAndOpen: PropTypes.func,
    initializeLoginFlow: PropTypes.func,
    linkColor: PropTypes.string,
    menuWrappperClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClickCreateAnAccount: PropTypes.func,
    onFailureSocialLogin: PropTypes.func,
    onLogin: PropTypes.func,
    origin: PropTypes.string,
    publisherId: PropTypes.string,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    scope: PropTypes.string,
    sendExternalLink: PropTypes.func,
    showCreateAccount: PropTypes.bool,
    userId: PropTypes.string,
    utmRootUrl: PropTypes.string,
}

export default Login
