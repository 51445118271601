import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    payment: new Duck.Selector(() => (state) => state?.paymentv2),
    getPaymentMethods: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ methods } = {}) => methods || []
    )),
    getDefaultPaymentMethod: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ defaultMethod } = {}) => defaultMethod
    )),
    getError: new Duck.Selector((selectors) => createSelector(
        selectors.payment,
        ({ error } = {}) => error
    )),
    paymentMethods: new Duck.Selector((selectors) => createSelector(
        selectors.getPayments,
        (paymentMethods) => paymentMethods,
    )),
    getPaymentMethod: new Duck.Selector((selectors) => (state, id) => {
        return selectors.paymentMethods(state).find(({ sourceId } = {}) => {
            return sourceId === id
        })
    }),
    doesPaymentMethodExist: new Duck.Selector((selectors) => createSelector(
        selectors.getPaymentMethod,
        (paymentMethod) => !!paymentMethod,
    )),
    getPaymentMethodError: new Duck.Selector((selectors) => (state, id) => {
        return selectors.getError(state)
    }),
    doesPaymentMethodErrorExist: new Duck.Selector((selectors) => createSelector(
        selectors.getPaymentMethodError,
        (paymentMethodError) => !!paymentMethodError,
    )),
})

export default selectors
