import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanGift = ({
    changeMenuRoute,
    currentSubscriptionEnd,
    currentSubscriptionPlanTierTitle,
    userLoader,
    guestLoader,
    convertGift = () => {},
    utmRootUrl,
    queryOverride
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Manage Subscription</h3>
            </div>
            <div className={style.container}>
                <ul className={style.menuContent} style={{ color: linkColor,
                    borderBottom: '1px solid #ddd' }}>
                    <li>
                        <div className={classNames(style.mainMenuItem, style.listItemFluid)}>
                            <div className={classNames(style.regularColor)}>Your gift gives you access to {currentSubscriptionPlanTierTitle} until <strong>{dayjs(currentSubscriptionEnd).format('LL')}</strong></div>
                        </div>
                    </li>
                </ul>
                <ul className={classNames(style.menuContent, style.planOption, style.btnLinkGroup)}>
                    <li
                        className={style.mainMenuItem}
                        onClick={() => {
                            convertGift()
                        }}
                    >
                        <Boutton
                            icon={<Picon icon='icon_undo' size='24' color={linkColor} />}
                            variant="link"
                        >
                            Keep subscription
                        </Boutton>
                    </li>
                </ul>
            </div>
        </MenuWrapper>
    )
}

ManagePlanGift.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    currentSubscriptionEnd: PropTypes.string.isRequired,
    currentSubscriptionPlanTierTitle: PropTypes.string.isRequired,
    convertGift: PropTypes.func,
    userLoader: PropTypes.any,
    guestLoader: PropTypes.any,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
}

export default ManagePlanGift
