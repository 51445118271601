import React from 'react'
import PropTypes from 'prop-types'

import MenuWrapper from './components/MenuWrapper'
import { colors } from 'piconetworks/pkg-functions'
import Boutton from 'piconetworks/pkg-boutton'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const ManagePlanSwitchPlan = ({
    Link,
    changeMenuRoute,
    userLoader,
    guestLoader,
    router,
    utmRootUrl,
    queryOverride
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
            styling: {
                linkColor = '',
            } = {},
        } = {},
    } = scopedLoader

    const {
        query: {
            company_slug,
        } = {},
    } = router

    const contrastColor = colors.getContrastColor(linkColor)

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={style.menuHeader}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute('/') }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Log Out</h3>
            </div>
            <div className={style.menuDescription}>
                <span>You may lose access to some content.</span>
            </div>
            <div className={style.approveButtonGroup}>
                <Boutton
                    className={style.cancel}
                    onClick={() => { changeMenuRoute('/') }} 
                    variant="link"
                >
                    Cancel
                </Boutton>
                <span>
                    <Link
                        href="/logout"
                        as={'/logout'}
                    >
                        <a>
                            <Boutton
                                variant="danger"
                            >
                                Log out
                            </Boutton>
                        </a>
                    </Link>
                </span>
            </div>
            {/* Log Out Container */}
        </MenuWrapper>
    )
}

ManagePlanSwitchPlan.defaultProps = {

}

ManagePlanSwitchPlan.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
    userLoader: PropTypes.object,
    guestLoader: PropTypes.object
}

export default ManagePlanSwitchPlan
