import {
    put
} from 'redux-saga/effects'

function* setLoginEmail({
    email,
    types,
}) {
    try {
        yield put({
            type: types.SET_LOGIN_EMAIL_SUCCESS,
            payload: {
                loginEmail: email,
            },
        })

    } catch (error) {
        if (error?.response?.data?.message) {
            yield put({
                type: types.SET_LOGIN_EMAIL_ERROR,
                error: error.response.data,
            })
        } else {
            yield put({
                type: types.SET_LOGIN_EMAIL_ERROR,
                error: error.message,
            })
        }
    }
}

export default setLoginEmail
