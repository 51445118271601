import generateAction from 'piconetworks/pkg-generate-action'

const getDownloadableFile = generateAction({
    baseActionCreatorName: 'getDownloadableFile',
    baseActionTypeName: 'GET_DOWNLOADABLE_FILE',
})

const getDownloadableFiles = generateAction({
    baseActionCreatorName: 'getDownloadableFiles',
    baseActionTypeName: 'GET_DOWNLOADABLE_FILES',
})

const types = [
    ...getDownloadableFile.types,
    ...getDownloadableFiles.types,
]

const creators = (duck) => ({
    ...getDownloadableFile.creators(duck),
    ...getDownloadableFiles.creators(duck),
})

export {
    types,
    creators,
}
