import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../Heading.module.scss'

const H6 = ({ className, children, ...props }) => {
    return (
        <h6 className={cx(className)} {...props}>{children}</h6>
    )
}

H6.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default H6
