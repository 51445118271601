import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import { colors } from 'piconetworks/pkg-functions'

const { getContrastColor, isColor } = colors

const selectors = () => ({
    client: new Duck.Selector(() => (state) => state?.client),
    getClientError: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ error }) => error,
    )),
    id: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ id }) => id,
    )),
    tier: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ tier }) => tier,
    )),
    getContributionType: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ contribution_type }) => contribution_type,
    )),
    name: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ name }) => name,
    )),
    username: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ username }) => username,
    )),
    url: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ url }) => url,
    )),
    clientId: new Duck.Selector((selectors) => createSelector(
        selectors.id,
        (data) => data
    )),
    publisherId: new Duck.Selector((selectors) => createSelector(
        selectors.id,
        (data) => data
    )),
    getAccessTier: new Duck.Selector((selectors) => createSelector(
        selectors.tier,
        (data) => data
    )),
    styling: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ styling }) => styling || {},
    )),
    icon: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ icon }) => icon,
    )),
    supportEmail: new Duck.Selector((selectors) => createSelector(
        selectors.client,
        ({ support_email }) => support_email,
    )),
    picoColor: new Duck.Selector(() => () => '#834eff'),
    linkColor: new Duck.Selector((selectors) => createSelector(
        (state) => ({
            ...selectors.styling(state),
            picoColor: selectors.picoColor()
        }),
        ({ linkColor, picoColor }) => isColor(linkColor) ? linkColor : picoColor,
    )),
    logoColor: new Duck.Selector((selectors) => createSelector(
        (state) => ({
            ...selectors.styling(state),
            picoColor: selectors.picoColor()
        }),
        ({ logoColor, picoColor }) => isColor(logoColor) ? logoColor : picoColor,
    )),
    contrastColor: new Duck.Selector((selectors) => createSelector(
        selectors.linkColor,
        (linkColor) => getContrastColor(linkColor),
    )),
})

export default selectors
