import Joi from 'joi'

const GadgetOtpRequested = Joi.object()
    .keys({
        email: Joi.string().email({ tlds: { allow: false } }).allow(null).optional().description('End user email'),
        phone: Joi.string().allow(null).optional().description('Phone number if they authed with SMS'),
        verify_flow_id: Joi.string().guid({ version: ['uuidv4'] }).required().description(
            `The id that links otp events to an individual & unique verification flow (OTP Request).
            The verify flow id is a uuid created when the initial otp request associated to an event was made.`
        )
    })
    .description('This event is fired when an end user requests a one-time code on signup landing page')
    .meta({ name: 'GadgetOtpRequested', filename: 'gadget:otp_requested.model' })

export default GadgetOtpRequested
