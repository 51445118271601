const StateObserver = ({
    checks,
    reject,
    resolve,
    timeout = 30000,
    endSubscription = () => {},
    getSubscription = () => {},
}) => {
    const timeoutTask = setTimeout(() => {
        const subscription = getSubscription()

        if (subscription && subscription.closed) {
            return
        }

        endSubscription()

        reject(
            new Error(
                'There has been an error. Please try again or contact support if this persists.',
            ),
        )
    }, timeout)

    const isReady = (state) => !!state && checks.every((check) => check(state))

    const done = ({
        error,
        data,
    }) => {
        endSubscription()

        clearTimeout(timeoutTask)

        if (error) {
            reject(error)
        } else {
            resolve(data)
        }
    }

    return {
        state: null,
        next(state) {
            this.state = state

            try {
                if (isReady(this.state)) {
                    this.complete()
                }
            } catch (error) {
                this.error(error)
            }
        },
        error(error) {
            done({
                error,
            })
        },
        complete() {
            done({
                data: this.state,
            })
        },
    }
}

export default StateObserver
