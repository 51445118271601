import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './scss/Footer.module.scss'

const ContentFooter = ({ className, children }) => (
    <div className={cx(styles.contentFooter, className)}>
        {children}
    </div>
)

ContentFooter.defaultProps = {
    className: '',
}

ContentFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default ContentFooter
