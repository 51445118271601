import Joi from 'joi'

const DashboardUserLogin = Joi.object()
    .keys({
        publisher_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the creator'),
        tier: Joi.string().valid('tier_1', 'tier_0').required().description('Tier of creator'),
    })
    .description(
        `This is identify call that should fire whenever any user authenticates on Dashboard. This includes regular email log in,
        OAuth (Social Login), Magic Link claims, and creator authentication when redirected from onboarding.`
    )
    .meta({ name: 'DashboardUserLogin', filename: 'dashboard:user_login.model' })

export default DashboardUserLogin
