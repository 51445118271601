import React from 'react'
import PropTypes from 'prop-types'

const Trigger = React.forwardRef(({ children, onClick, disabled, className }, ref) => {
    return React.cloneElement(children, {
        ref,
        onClick,
        disabled,
        className
    })
})

Trigger.propTypes = {
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func
}

Trigger.defaultProps = {
    disabled: false
}
export default Trigger
