import React from 'react'
import { Analytics } from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import StorageUtils, { hasLocalStorage } from '@analytics/storage-utils'
import { originalSourcePlugin } from '@analytics/original-source-plugin'

import analyticsPluginPico from '../CustomPlugins/PicoPlugin'
import resolveProviderPlugins from '../CustomPlugins/resolveProviderPlugins'
import dispatchAction from '../CustomPlugins/DispatchPlugin'

const { getItem, removeItem, setItem } = StorageUtils

const defaultPlugins = [
    originalSourcePlugin()
]

const analyticsConfig = {
    app: 'pico-analytics',
    debug: process.env.NODE_ENV === 'development' || process.env.STAGE === 'development'
}

if (hasLocalStorage()) {
    analyticsConfig.storage = {
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem
    }
}

const PicoAnalytics = (config) => {
    const { plugins = {}, ...pico_plugin_options } = config

    analyticsConfig.plugins = [
        ...defaultPlugins,
        analyticsPluginPico(pico_plugin_options)
    ]

    !!plugins?.dispatch && analyticsConfig.plugins.push(dispatchAction(config))

    analyticsConfig.plugins.push(...resolveProviderPlugins(plugins, pico_plugin_options))

    return Analytics(analyticsConfig)
}

const PicoAnalyticsProvider = ({ children, config, instance = null, ...props }) => {
    return <AnalyticsProvider instance={instance || PicoAnalytics(config)}>
        {React.cloneElement(children, props)}
    </AnalyticsProvider>
}

export {
    PicoAnalytics,
    PicoAnalyticsProvider
}
