import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './RegularBox.module.scss'

const RegularBox = ({
    children,
    className,
    alignText,
    ...props
}) => {
    return (
        <div className={cx(styles[alignText], className)} {...props}>
            {children}
        </div>
    )
}

RegularBox.propTypes = {
    /** Aligns children using text-align */
    alignText: PropTypes.oneOf(['left', 'center', 'right']),
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

RegularBox.defaultProps = {
    alignText: 'left'
}

export default RegularBox
