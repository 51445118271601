import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    links: new Duck.Selector(() => (state) => state?.links),
    getSocialLinks: new Duck.Selector((selectors) => createSelector(
        selectors.links,
        ({ links } = {}) =>
            links.filter((link) => link.type !== 'standard')
    )),
    getLoading: new Duck.Selector((selectors) => createSelector(
        selectors.links,
        ({ loading } = {}) => loading
    )),
})

export default selectors
