import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-8 -8)">
            <rect height="56" width="56" fill="#F9F4FF" rx="28" x="8" y="8" />
            <path
                d="M49.5 29.7C49.5 28.485 48.4926 27.5 47.25 27.5 46.0074 27.5 45 28.485 45 29.7L45 47.3C45 48.515 46.0074 49.5 47.25 49.5 48.4926 49.5 49.5 48.515 49.5 47.3L49.5 29.7zM42 24.75C42 23.5074 40.9926 22.5 39.75 22.5 38.5074 22.5 37.5 23.5074 37.5 24.75L37.5 47.25C37.5 48.4926 38.5074 49.5 39.75 49.5 40.9926 49.5 42 48.4926 42 47.25L42 24.75z"
                fill="#7A34FF"
            />
            <path
                d="M34.5 33.75C34.5 32.5074 33.4926 31.5 32.25 31.5 31.0074 31.5 30 32.5074 30 33.75L30 47.25C30 48.4926 31.0074 49.5 32.25 49.5 33.4926 49.5 34.5 48.4926 34.5 47.25L34.5 33.75zM27 41.6429C27 40.4594 25.9926 39.5 24.75 39.5 23.5074 39.5 22.5 40.4594 22.5 41.6429L22.5 47.3571C22.5 48.5406 23.5074 49.5 24.75 49.5 25.9926 49.5 27 48.5406 27 47.3571L27 41.6429z"
                fill="#C9ADFF"
            />
        </g>
    </svg>
)
