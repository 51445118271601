import Amex from './amex'
import ApplePay from './applePay'
import Default from './default'
import Discover from './discover'
import Jcb from './jcb'
import Maestro from './maestro'
import MasterCard from './masterCard'
import Visa from './visa'
const Payment = {
    Amex,
    ApplePay,
    Default,
    Discover,
    Jcb,
    Maestro,
    MasterCard,
    Visa
}
export default Payment
