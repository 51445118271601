import Claim from 'modules/claim'

import storeSubscriber from '../storeSubscriber'

const exchangeClaim = async ({
    dispatch,
    payload: {
        claim,
        scope,
        publisherId,
    },
}) => {
    dispatch(Claim.creators.exchangeClaim({
        claim,
        scope,
        publisherId,
    }))

    let hasUserLoader
    let tokens = null

    const response = await storeSubscriber({
        checks: [
            (state) => {
                if (state.loader.user) {
                    hasUserLoader = true
                    return true
                }
                tokens = Claim.selectors.getTokens(state)

                return (
                    tokens !== null
                )
            },
        ],
    })

    if (response?.error) {
        throw response.error
    }

    return {
        hasUserLoader,
        tokens,
    }
}

export default exchangeClaim
