import generateAction from 'piconetworks/pkg-generate-action'

const getUrlMetadata = generateAction({
    baseActionCreatorName: 'getUrlMetadata',
    baseActionTypeName: 'GET_URL_METADATA',
})

const getUrlsMetadata = generateAction({
    baseActionCreatorName: 'getUrlsMetadata',
    baseActionTypeName: 'GET_URLS_METADATA',
})

const types = [
    ...getUrlMetadata.types,
    ...getUrlsMetadata.types,
    'CLEAR_CURRENT_URL'
]

const creators = (duck) => ({
    ...getUrlMetadata.creators(duck),
    ...getUrlsMetadata.creators(duck),
    clearCurrentUrl: () => ({
        type: duck.types.CLEAR_CURRENT_URL
    })
})


export {
    types,
    creators,
}
