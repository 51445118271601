import getDownloadableFile from './getDownloadableFile'
import getDownloadableFiles from './getDownloadableFiles'

const sagas = (duck) => ({
    * getDownloadableFile(payload) {
        yield getDownloadableFile({
            ...payload,
            ...duck,
        })
    },
    * getDownloadableFiles(payload) {
        yield getDownloadableFiles({
            ...payload,
            ...duck,
        })
    },
})

export default sagas
