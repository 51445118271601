import React from 'react'

export default ({
    title = 'loginIcon/facebook',
    color = 'currentcolor',
    width = '30px',
    height = '30px'
}) => (
    <svg
        height={height}
        width={width}
        version="1.1"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="loginIcon/facebook"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <path
                id="Shape"
                d="M30,15 C30,6.71572266 23.2842773,0 15,0 C6.71572266,0 0,6.71572266 0,15 C0,22.4869336 5.4852832,28.6924805 12.65625,29.8177734 L12.65625,19.3359375 L8.84765625,19.3359375 L8.84765625,15 L12.65625,15 L12.65625,11.6953125 C12.65625,7.9359375 14.8956445,5.859375 18.3219727,5.859375 C19.9630957,5.859375 21.6796875,6.15234375 21.6796875,6.15234375 L21.6796875,9.84375 L19.7882227,9.84375 C17.9248535,9.84375 17.34375,11 17.34375,12.1862402 L17.34375,15 L21.5039062,15 L20.8388672,19.3359375 L17.34375,19.3359375 L17.34375,29.8177734 C24.5147168,28.6924805 30,22.4869336 30,15"
                fill={color}
            />
        </g>
    </svg>
)
