import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import Boutton from 'piconetworks/pkg-boutton'

import MenuWrapper from './components/MenuWrapper'
import { Capitalize } from 'piconetworks/pkg-functions'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const ManagePlanSubscriptionStopSubscription = ({
    changeMenuRoute,
    currentSubscriptionEnd,
    currentPurchaseFailed,
    cancelPlan,
    memberType,
    memberVerbPresentParticiple = '',
    publisherId,
    publisherName,
    utmRootUrl,
    queryOverride,
    contributionLanguage = 'tip',
}) => {
    const endsOn = currentPurchaseFailed ? dayjs() : dayjs(currentSubscriptionEnd)

    const descriptionText = (memberType === 'donor')
        ? (
            <span>
                Your monthly {contributionLanguage === 'tip' ? 'tip' : 'donation'} provides critical support to
                {' '}
                {publisherName}
                .
            </span>
        )
        : (
            <span>
                Your plan benefits would stop after the current billing cycle ends on
                {' '}
                <strong>{endsOn.format('LL')}</strong>
                .
            </span>
        )

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={classNames(style.menuHeader, style.withoutIcon)}>
                <h3>
                    Are you sure you want to stop
                    {memberVerbPresentParticiple && ` ${memberVerbPresentParticiple}`}?
                </h3>
            </div>
            <div className={style.container}>
                <div className={style.menuDescription}>
                    <p>{descriptionText}</p>
                </div>
                <div data-menu_route="/logOut">
                    <div className={style.approveButtonGroup}>
                        <Boutton
                            className={style.cancel}
                            onClick={() => { changeMenuRoute('/') }}
                            variant="link"
                        >
                            Cancel
                        </Boutton>
                        <Boutton
                            onClick={() => {
                                cancelPlan({
                                    publisherId
                                })
                            }}
                            variant="danger"
                        >
                            {`Stop ${Capitalize(memberVerbPresentParticiple)}`}
                        </Boutton>
                    </div>
                </div>
            </div>
        </MenuWrapper>
    )
}

ManagePlanSubscriptionStopSubscription.defaultProps = {
    currentSubscriptionEnd: null,
    publisherId: null,
}

ManagePlanSubscriptionStopSubscription.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
    currentSubscriptionEnd: PropTypes.any,
}

export default ManagePlanSubscriptionStopSubscription
