import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_PAYMENTS, sagas.getPayments),
    takeEvery(types.ADD_PAYMENT, sagas.addPayment),
    takeEvery(types.UPDATE_DEFAULT_PAYMENT, sagas.updateDefaultPayment),
    takeEvery(types.DELETE_PAYMENT, sagas.deletePayment),
])

export default takes
