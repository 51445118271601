import { produce } from 'immer'

export const initialState = {
    isFetching: false,
    isVerifyingPayment: false,
    isVerifyingPaymentSucess: null,
    isVerifyingPaymentError: null,
    isVerifyingPaymentErrorMessage: null,
    isReset: true,
    discountCodeSuccess: false,
    verifiedDiscountCode: null,
    verifiedDiscountCodeId: null,
    alternativePlans: {
        gift: {
            tiers: [],
        },
        discount: {
            tiers: [],
        },
        switching: {
            tiers: [],
        },
    },
    canSwitchPlanNow: false,
    prorate_amount: null,
    next_price: null,
    end_date: null,
    oneTimeDonationSuccess: false,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {
        case duck.types.GET_PLANS_SUCCESS:
            if (payload.switching) {
                draftState.alternativePlans.switching = payload
            }

            if (payload.gift) {
                draftState.alternativePlans.gift = payload
            }
            break

        case duck.types.GET_PLANS_ERROR:
            draftState.alternativePlans = initialState.alternativePlans
            break

        case duck.types.PURCHASE_PLAN:
            draftState.isVerifyingPayment = true
            draftState.isReset = false
            draftState.isVerifyingPaymentErrorMessage = initialState.isVerifyingPaymentErrorMessage
            break

        case duck.types.PURCHASE_SWITCH_SUCCESS:
        case duck.types.PURCHASE_PLAN_GROUP_SUCCESS:
        case duck.types.PURCHASE_PLAN_SUCCESS:
            draftState.isVerifyingPayment = false
            draftState.isReset = false

            if (payload.success) {
                draftState.isVerifyingPaymentSucess = payload.success
                draftState.oneTimeDonationSuccess = payload.oneTimeDonationSuccess
            }
            break

        case duck.types.PURCHASE_SWITCH_ERROR:
        case duck.types.PURCHASE_PLAN_GROUP_ERROR:
        case duck.types.PURCHASE_PLAN_ERROR:
            draftState.isVerifyingPayment = false
            draftState.isReset = false

            if (payload.error) {
                draftState.isVerifyingPaymentError = payload.error
                draftState.isVerifyingPaymentErrorMessage = error
            }
            break

        case duck.types.VERIFY_DISCOUNT_CODE:
            draftState.isFetching = true
            draftState.isReset = false
            draftState.discountCodeSuccess = false
            break

        case duck.types.VERIFY_DISCOUNT_CODE_SUCCESS:
            draftState.isFetching = false
            draftState.isReset = false
            draftState.discountCodeSuccess = true
            draftState.verifiedDiscountCode = payload.verifiedDiscountCode
            draftState.verifiedDiscountCodeId = payload.verifiedDiscountCodeId
            draftState.alternativePlans.discount = payload?.plan
            break

        case duck.types.VERIFY_DISCOUNT_CODE_ERROR:
            draftState.isFetching = false
            draftState.isReset = false
            draftState.discountCodeSuccess = false
            break

        case duck.types.RESET:
            draftState.isFetching = duck.initialState.isFetching
            draftState.isVerifyingPayment = duck.initialState.isVerifyingPayment
            draftState.isVerifyingPaymentSucess = duck.initialState.isVerifyingPaymentSucess
            draftState.isVerifyingPaymentError = duck.initialState.isVerifyingPaymentError
            draftState.isReset = duck.initialState.isReset
            draftState.discountCodeSuccess = duck.initialState.discountCodeSuccess
            draftState.verifiedDiscountCode = duck.initialState.verifiedDiscountCode
            draftState.verifiedDiscountCodeId = duck.initialState.verifiedDiscountCodeId
            draftState.oneTimeDonationSuccess = duck.initialState.oneTimeDonationSuccess
            break

        case duck.types.CHECK_SWITCH_PLAN_NOW_SUCCESS:
            draftState.isFetching = false
            draftState.canSwitchPlanNow = payload.canSwitchPlanNow
            draftState.prorate_amount = payload.prorate_amount
            draftState.next_price = payload.next_price
            draftState.end_date = payload.end_date

            draftState.isReset = false

            break

        case duck.types.HANDLE_DIRECT_PLAN:
            draftState.directPlanOptionId = payload.planOptionId
            break

        default:
        // do nothing
    }
})

export default reducer
