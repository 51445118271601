import { put, select } from 'redux-saga/effects'
// import Router from 'lib/RouterAdapterDecorator'
import Router from 'lib/RouterAdapterDecorator'


import { types } from '../actions'
import logger from 'lib/logger'

function* goToStepSaga({
    payload: {
        id, goToStep, short_code,
    },
}) {
    try {
        const state = yield select()
        const { steps } = state.wizard.wizards[id]
        const nextStep = goToStep

        const { url, as, method = 'push' } = steps[nextStep]

        Router[method]({
            pathname: url,
            query: {
                id,
                ...(short_code && { short_code }),
            },
        }, {
            pathname: as,
            query: {
                id,
                ...(short_code && { short_code }),
            },
        })

        yield put({
            type: types.NEXT_STEP_SUCCESS,
            payload: {
                nextStep,
                id,
            },
        })
    } catch (error) {
        logger.error('goToStepSaga', {}, error)
        yield put({
            type: types.NEXT_STEP_ERROR,
            error,
        })
    }
}

export default goToStepSaga
