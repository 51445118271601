import generateAction from 'lib/generateAction'

const getLandingPage = generateAction(
    'getLandingPage',
    'GET_LANDING_PAGE',
)

const fetchLandingPage = generateAction(
    'fetchLandingPage',
    'FETCH_LANDING_PAGE',
)

const checkIfShowOfferCode = generateAction(
    'checkIfShowOfferCode',
    'CHECK_IF_SHOW_OFFER_CODE',
)

const types = [
    ...getLandingPage.types,
    ...fetchLandingPage.types,
    ...checkIfShowOfferCode.types,

    'THEME_CUSTOMIZATION_UPDATE',
    'PAGE_HEADER_UPDATE',
    'UPDATE_FORM_CUSTOMIZATIONS',
    'UPSERT_FIELDS'
]

const creators = (duck) => ({
    ...getLandingPage.creators(duck),
    ...fetchLandingPage.creators(duck),
    ...checkIfShowOfferCode.creators(duck)
})

export {
    types,
    creators,
}
