import {
    put, putResolve, call
} from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL, PURCHASE_SWITCH,
} from 'piconetworks/pkg-endpoints'

function* purchaseSwitch({
    types,
    formId = null,
    productOptionId = null,
    publisherId = null,
    customPrice = null,
    pay_now: payNow = null,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!productOptionId) {
            throw Error('productOptionId not provided')
        }

        if (!publisherId) {
            throw Error('publisherId not provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_SWITCH()}/${productOptionId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    form_id: formId,
                    discount_code_id: null,
                    publisherId,
                    ...(customPrice && { custom_price: customPrice }),
                    ...(payNow !== null && { pay_now: payNow }),
                },
            },
        })

        if (!data) {
            throw Error('no data returned')
        }

        if (!data.success) {
            throw Error('create plan did not succeed')
        }

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.PURCHASE_SWITCH_SUCCESS,
            payload: {
                success: productOptionId,
            },
        })
    } catch (error) {
        console.log(error)

        yield put({
            type: types.PURCHASE_SWITCH_ERROR,
            error: error.message,
            payload: {
                error: productOptionId,
            },
        })
    }
}

export default purchaseSwitch
