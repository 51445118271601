import Joi from 'joi'

const DashboardCallToActionDeclined = Joi.object()
    .keys({
        cta_session_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the cta session'),
        cta_id: Joi.string().allow(null).description('The id of the cta'),
        app_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the apps'),
        feature_ids: Joi.array().items(Joi.string().guid({ version: ['uuidv4'] })).allow(null).description('The ids of the features'),
    })
    .description('dashboard:call_to_action:declined event attributes ')
    .meta({ name: 'DashboardCallToActionDeclined', filename: 'dashboard:call_to_action:declined.model' })

export default DashboardCallToActionDeclined
