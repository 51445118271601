import React from 'react'

export default ({
    title = 'Update Payment Method',
    color = 'currentColor'
}) => (
    <svg
        className="clickable"
        height="24px"
        icon="M6 5h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H6zM5 9h14v3H5V9zm10 5h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2z"
        role="img"
        style={{ cursor: 'pointer', display: 'block', verticalAlign: 'middle' }}
        title="Update Payment Method"
        width="24px"
        viewBox="0 0 24 24"
    >
        <title id="svg-title">{title}</title>
        <path
            d="M6 5h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H6zM5 9h14v3H5V9zm10 5h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2z"
            fill={color}
        />
    </svg>
)
