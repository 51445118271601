import getCurrentUser from './getCurrentUser'
import updateCurrentUser from './updateCurrentUser'
import deleteCurrentUser from './deleteCurrentUser'
import updateUserProperties from './updateUserProperties'
import updateUserNewsletters from './updateUserNewsletters'

const sagas = (duck) => ({
    * getCurrentUser(payload) {
        yield getCurrentUser({ ...payload, ...duck })
    },
    * updateCurrentUser(payload) {
        yield updateCurrentUser({ ...payload, ...duck })
    },
    * deleteCurrentUser(payload) {
        yield deleteCurrentUser({ ...payload, ...duck })
    },
    * updateUserProperties(payload) {
        yield updateUserProperties({ ...payload, ...duck })
    },
    * updateUserNewsletters(payload) {
        yield updateUserNewsletters({ ...payload, ...duck })
    },
})

export default sagas
