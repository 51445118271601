import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Boutton from 'piconetworks/pkg-boutton'

import MenuWrapper from './components/MenuWrapper'

import style from './scss/MenuRoutes.module.scss'

const Logout = ({ 
    Link,
    changeMenuRoute,
    router,
    userLoader,
    guestLoader,
    sendExternalLink,
    utmRootUrl,
    queryOverride
}) => {
    const scopedLoader = userLoader || guestLoader || {}

    const {
        publisher: {
            name: publisherName,
        } = {},
    } = scopedLoader

    const {
        query: {
            company_slug,
        } = {},
    } = router

    return (
        <MenuWrapper publisherName={publisherName} sendExternalLink={sendExternalLink} utmRootUrl={utmRootUrl} queryOverride={queryOverride}>
            <div className={classNames(style.menuHeader, style.withoutIcon)}>
                <h3 style={{paddingBottom: '1rem'}}>Are you sure you want to log out?</h3>
            </div>
            <div className={style.container}>
                <div className={style.menuDescription}>
                    <p>You may lose access to some content.</p>
                </div>
                <div data-menu_route="/logOut">
                    <div className={style.approveButtonGroup}>
                        <Boutton
                            className={style.cancel}
                            onClick={() => { changeMenuRoute('/') }} 
                            variant="link"
                        >
                            Cancel
                        </Boutton>
                        <span>
                            <Link
                                href="/logout"
                                as={'/logout'}
                            >
                                <a>
                                    <Boutton
                                        variant="danger"
                                    >
                                        Log out
                                    </Boutton>
                                </a>
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </MenuWrapper>
    )
}

Logout.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null
}

Logout.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    Link: PropTypes.any,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string,
    queryOverride:PropTypes.object,
}

export default Logout
