import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const SignupPageSubmit = Joi.object()
    .keys({
        page_number: Joi.number().min(1).required().description('Step in Onboarding Wizard'),
        email: Joi.string().email({ tlds: { allow: false } }).optional().description('Creator email'),
        username: Joi.string().optional().description('Creator Username'),
        auth_method: Joi.string().valid('google', 'email').required().description('Authentication method used by onboarding creator'),
        experiments: experimentsSchema,
        name: Joi.string().optional().description('Creator Name'),
        content_categories: Joi.array().items(Joi.string()).optional().min(0).description('Creators content categories selections'),
        twitter_handle: Joi.string().optional().allow(null).description('Creators Twitter Handle'),
        is_brand: Joi.boolean().optional().default(false).description('Describes whether creator represents a brand, or is an individual.'),
        brand_name: Joi.string().optional().allow(null).description('Name of the brand, if the creator onboarding has one.'),
        firstName: Joi.string().optional().allow(null).description('First name of the creator.'),
        hype_kit_ref: Joi.string().optional().allow(null).description('Selected Kit ID.'),
        instagram: Joi.string().optional().allow(null).description('Instagram Link of creator.'),
        tiktok: Joi.string().optional().allow(null).description('Tiktok Link of creator'),
        publisher_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).optional().description('Publisher user logged into their account from'),
    })
    .description('This event fires in every form submit in the onboarding flow, which would be every user submit action.')
    .meta({ name: 'SignupPageSubmit', filename: 'signup:page_submit.model' })

export default SignupPageSubmit
