import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_CURRENT_USER, sagas.getCurrentUser),
    takeEvery(types.UPDATE_CURRENT_USER, sagas.updateCurrentUser),
    takeEvery(types.DELETE_CURRENT_USER, sagas.deleteCurrentUser),
    takeEvery(types.UPDATE_USER_PROPERTIES, sagas.updateUserProperties),
    takeEvery(types.UPDATE_USER_NEWSLETTERS, sagas.updateUserNewsletters),
])

export default takes
