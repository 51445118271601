import Joi from 'joi'

const pageDataSchema = {
    title: Joi.string().optional().allow('').default('').description('Title of page event is fired from. Added from pageData for page_view events.'),
    url: Joi.string().uri({ allowRelative: false }).optional().allow('').default('').description('URL of event source.'),
    path: Joi.string().optional().allow('').default('').description('Path of event source.'),
    hash: Joi.string().optional().allow('').default('').description('Hash params in url, if any exist.'),
    search: Joi.string().optional().allow('').default('').description('Search params in url, if any exist.'),
    width: Joi.number().optional().allow('').default('').description('Width of the screen at the time the event fired.'),
    height: Joi.number().optional().allow('').default('').description('Height of the screen at the time the event fired.'),
    article_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null, '').description('Id of article the end user is currently on'),
    page_name: Joi.string().optional().allow('').default('').description('Name of page event is fired from. Added from pageData for page_view events.'),
}

export default pageDataSchema
