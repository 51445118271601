import { put, putResolve, call } from 'redux-saga/effects'

import { STORE_TOKENS, REQUEST } from 'piconetworks/pkg-network-middleware'
import { PICO_API_URL, PURCHASE_GIFT } from 'piconetworks/pkg-endpoints'

function* redeemGift({
    types,
    giftId,
    publisherId,
    customFields,
    newsletters = [],
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    try {
        if (!giftId) {
            throw Error('giftId must be provided')
        }

        if (!publisherId) {
            throw Error('publisherId must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: `${PURCHASE_GIFT()}/${giftId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    publisherId,
                    status: 'activated',
                    ...(customFields && { custom_properties: customFields }),
                    ...(newsletters.length && { newsletter_ids: newsletters }),
                },
            },
        })

        if (data.status_code === 404) {
            throw 'This gift has already been redeemed.'
        }

        yield putResolve({
            type: STORE_TOKENS,
            access_token: data.token,
            refresh_token: data.refresh_token,
        })

        yield put(
            yield call(loaderInvalidationEvent, {
                payload: {
                    scope: [
                        'user',
                    ],
                },
            })
        )

        yield put({
            type: types.REDEEM_GIFT_SUCCESS,
            payload: {},
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.REDEEM_GIFT_ERROR,
            payload: { error }
        })
    }
}

export default redeemGift
