import {
    OPEN_MENU,
    CLOSE_MENU,
    TOGGLE_MENU,
    GO_TO_MENU_ROUTE,
    GO_TO_MENU_ROUTE_AND_OPEN,
    RESET,
} from './actionTypes'

const initialState = {
    isOpen: false,
    menuRoute: '/',
    text: null,
    backRoute: null,
    data: {},
    mobileMode: false,
    activeMenuId: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MENU:
            return {
                ...state,
                isOpen: true,
                ...(action.payload.menuId && { activeMenuId: action.payload.menuId }),
            }
        case CLOSE_MENU:
            return {
                ...state,
                isOpen: false,
            }
        case TOGGLE_MENU:
            return {
                ...state,
                isOpen: !state.isOpen,
                ...(action.payload.menuId && { activeMenuId: action.payload.menuId }),
            }
        case GO_TO_MENU_ROUTE:
            return {
                ...state,
                menuRoute: action.payload.menuRoute,
            }
        case GO_TO_MENU_ROUTE_AND_OPEN:
            return {
                ...state,
                isOpen: true,
                menuRoute: action.payload.menuRoute,
                text: action.payload.text,
                link: action.payload.link,
                ...(action.payload.backRoute && { backRoute: action.payload.backRoute }),
                data: {
                    ...action.payload.data,
                },
                ...(action.payload.menuId && { activeMenuId: action.payload.menuId }),
            }
        case RESET:
            return initialState
        default:
            return state
    }
}
