import Joi from 'joi'
import productTypes from '../utils/productTypes'


const LandingPageUserPayment = Joi.object()
    .keys({
        userId: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The Hype-assigned id of the user'),
        form_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The id of the landing page in Hype Forms table'),
        product_type: Joi.string().valid(...productTypes).optional().description('The type of product the user paid for'),
        custom_price: Joi.string().optional().description('The custom price of the user\'s purchase'),
        currency: Joi.string().default('USD').optional().description('Currency the user used to pay for product'),
        product_option_id: Joi.string().guid({ version: ['uuidv4'] }).optional().description('The Hype-assigned id for the particular product option')
    })
    .description('landing_page:user_payment event attributes ')
    .meta({ name: 'LandingPageUserPayment', filename: 'landing_page:user_payment.model' })

export default LandingPageUserPayment
