/** This function validates an event payload against a payload passed to that event. If the payload
 * does not meet the structure requirements, we throw a hard error.
 *
 * @param {object} schema Joi schema to validate the given payload against
 * @param {object} payload Event payload to validate
 * @returns {object} Constructed payload
 */

export default (schema, payload) => {
    let validation_results = null

    try {
        validation_results = schema.validate(payload)
    } catch (e) {
        console.log('e', e)
        validation_results = {
            value: payload,
            error: 'Invalid Validation Schema',
        }

    }

    if (!validation_results || validation_results.error || !validation_results.value) {
        throw new Error(`Invalid Validation => ${validation_results.error}`)
    }

    return validation_results.value
}
