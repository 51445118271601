import push from './push'
import replace from './replace'

const sagas = (duck) => ({
    * push(payload) {
        yield push({ ...payload, ...duck })
    },
    * replace(payload) {
        yield replace({ ...payload, ...duck })
    },
})

export default sagas
