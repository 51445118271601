import Loader from 'modules/loader'

import storeSubscriber from '../storeSubscriber'

const awaitLoaderFetching = async () => {
    const response = await storeSubscriber({
        checks: [
            Loader.selectors.isNotFetching,
        ],
    })

    if (response?.error) {
        throw response.error
    }
}

export default awaitLoaderFetching
