/* eslint-disable no-unused-expressions */

import { from, Observable } from 'rxjs'

import { exportedStore } from '../../modules/store'

import StateObserver from './StateObserver'

function getState$(store) {
    return new Observable((observer) => {
        observer.next(store.getState())

        const unsubscribe = store.subscribe(() => {
            observer.next(store.getState())
        })

        return unsubscribe
    })
}

const storeSubscriber = ({
    checks = [],
    timeout,
}) => new Promise((resolve, reject) => {
    let subscription

    try {
        const state$ = from(getState$(exportedStore))

        subscription = state$
            .subscribe(
                StateObserver({
                    checks,
                    getSubscription: () => subscription,
                    endSubscription: () => {
                        subscription?.unsubscribe()
                    },
                    reject,
                    resolve,
                    timeout,
                }),
            )
    } catch (error) {
        subscription?.unsubscribe()

        reject(error)
    }
})

export default storeSubscriber
