import {
    put, putResolve, call
} from 'redux-saga/effects'

import {
    REQUEST,
} from 'piconetworks/pkg-network-middleware'
import {
    OAUTH_USER,
    PICO_API_URL,
    ME,
} from 'piconetworks/pkg-endpoints'

function* update({
    types,
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    oldPassword,
    publisherId,
    hasPasswordResetAuth,
    redirectUrl,
    loaderInvalidationEvent = () => ({ type: 'LOADER_INVALIDATION_EVENT' }),
}) {
    let userData

    const userPayload = {
        ...(firstName && { first_name: firstName }),
        ...(lastName && { last_name: lastName }),
        ...(email && { email }),
        ...(password && !hasPasswordResetAuth && {
            ...(oldPassword && { old_password: oldPassword }),
            ...(password && { new_password: password }),
        }),
        redirect_url: redirectUrl,
    }

    try {
        if (Object.keys(userPayload).length) {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'put',
                    baseURL: PICO_API_URL(),
                    url: ME(),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    timeout: 30000,
                    data: userPayload
                },
            })

            userData = data

            if (!userData) {
                throw Error('no user data')
            }

            yield put(
                yield call(loaderInvalidationEvent, {
                    payload: {
                        scope: [
                            'user',
                        ],
                    },
                })
            )
        }

        if (password && hasPasswordResetAuth) {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'put',
                    baseURL: PICO_API_URL(),
                    url: OAUTH_USER(),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    timeout: 30000,
                    data: {
                        ...(password && { password }),
                        adBlocking: false,
                        origin: window.location.href,
                        prev_page: '',
                        publisherId,
                        taxonomies: {},
                    },
                },
            })

            userData = data

            if (!userData) {
                throw Error('no user data')
            }
        }

        yield put({
            type: types.UPDATE_SUCCESS,
            payload: {
                ...(userData && { userDataUpdated: true }),
                ...(email && { emailDataUpdated: true }),
            },
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_ERROR,
            error: error.response?.data.message || error.message,
            payload: {
                ...((firstName || lastName || password) && { userDataUpdated: false }),
                ...(email && { emailDataUpdated: false }),
            },
        })
    }
}

export default update
