import React from 'react'
import PropTypes from 'prop-types'

export const Icon = ({
    icon,
    color,
    className,
    width = '56',
    height = '56',
    fill = 'currentcolor'
}) => {
    switch (icon) {
        case 'left':
            return (
                <span className={className} style={{ color }}>

                    <svg
                        width={width}
                        height={height}
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            fill={fill}
                            d="M12.586 27.414l-10-10c-0.781-0.781-0.781-2.047 0-2.828l10-10c0.781-0.781 2.047-0.781 2.828 0s0.781 2.047 0 2.828l-6.586 6.586h19.172c1.105 0 2 0.895 2 2s-0.895 2-2 2h-19.172l6.586 6.586c0.39 0.39 0.586 0.902 0.586 1.414s-0.195 1.024-0.586 1.414c-0.781 0.781-2.047 0.781-2.828 0z"
                        />
                    </svg>
                </span>
            )
        case 'right':
            return (
                <span className={className} style={{ color }}>
                    <svg
                        width={width}
                        height={height}
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            fill={fill}
                            d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"
                        ></path>
                    </svg>
                </span>
            )
        case 'check':
            return (
                <span className={className} style={{ color }}>
                    <svg
                        height={height}
                        width={width}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            fill={color}
                            fillRule="evenodd"
                            points="4.41 11.48 8.877 15.944 19.59 5.23 21 6.65 8.879 18.772 3 12.89"
                        />
                    </svg>
                </span>
            )
        default:
            return null
    }
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string   
}