import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.GET_DEFAULT_LANDING_PAGE, sagas.getDefaultLandingPage),
    takeEvery(types.GET_LANDING_PAGE, sagas.getLandingPage),
    takeEvery(types.GET_DEFAULT_POPUP, sagas.getDefaultPopup),
    takeEvery(types.GET_POPUP, sagas.getPopup),
])

export default takes
