import { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { createPortal } from 'react-dom'

const generatePopup = (url, title) => {

    const height = 540
    const width = window.innerWidth > 540 ? 540 : window.innerWidth

    const left = (window.screenLeft || window.screenX) + (window.innerWidth / 2) - (width / 2)
    const top = (window.screenTop || window.screenY) + (window.innerHeight / 2) - (height / 2)

    const options = Object.entries({
        alwaysOnTop: 1,
        alwaysRaised: 1,
        close: 0,
        copyhistory: 0,
        dependent: 1,
        dialog: 1,
        directories: 0,
        height,
        left,
        location: 0,
        menubar: 0,
        minimizable: 0,
        noopener: 0,
        personalbar: 0,
        resizable: 0,
        scrollbars: 0,
        status: 0,
        titlebar: 0,
        toolbar: 0,
        top,
        width,
    }).map(([key, value]) => `${key}=${value}`).join(',')

    const query = qs.parse(url, { skipNulls: true })

    if (query.redirect_uri) {
        return window.top.location.replace(url)
    } else {
        return window.open(url, title, options)
    }
}

class PopupWindow extends Component {
    constructor(props) {
        super(props)

        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div')
        this.externalWindow = null
        this.timer = null
    }

    handleResponseEvent = (e) => {
        const { handleResponse } = this.props
        handleResponse(this.externalWindow)(e)
    }

    componentDidMount() {
        const { url, title, onClose } = this.props

        const closeFunction = onClose || (() => { })

        // STEP 3: open a new browser window and store a reference to it
        this.externalWindow = generatePopup(url, title)

        if (window.addEventListener) {
            window.addEventListener('message', this.handleResponseEvent, false)
        } else if (window.attachEvent) {
            window.attachEvent('onmessage', this.handleResponseEvent)
        } else {
            window.onload = this.handleResponseEvent()
        }

        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        window.document.body.appendChild(this.containerEl)

        this.timer = setInterval(() => {
            if (this?.externalWindow?.closed) {
                clearInterval(this.timer)
                closeFunction()
            }
        }, 1000)
    }

    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window
        if (!this?.externalWindow?.closed) {
            this?.externalWindow?.close()
        }

        if (window.removeEventListener) {
            window.removeEventListener('message', this.handleResponseEvent, false)

        } else if (window.detachEvent) {
            window.detachEvent('onmessage', this.handleResponseEvent)
        }

        clearInterval(this.timer)
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return createPortal(this.props.children, this.containerEl)
    }
}

PopupWindow.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
    handleResponse: PropTypes.func,
    children: PropTypes.node,
}

export default PopupWindow
