import get from 'lodash.get'
import template from 'lodash.template'

const templateCache = {}

export const compile = (templateString, variables = {}) => {
    if (templateString === '') {
        console.warn('template failed')
        return null
    }

    if (!templateCache[templateString]) {
        templateCache[templateString] = template(templateString)
    }

    const compiled = templateCache[templateString]

    return compiled(variables)
}

export const getTemplateOrDefault = (templates, defaults, label) => get(templates, label, get(defaults, label))
