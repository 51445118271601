import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.CREATE_ACCOUNT, sagas.createAccount),
    takeEvery(types.GET_HAS_PASSWORD, sagas.getHasPassword),
    takeEvery(types.REQUEST_LOGIN_LINK, sagas.requestLoginLink),
    takeEvery(types.REQUEST_ONE_TIME_CODE, sagas.requestOneTimeCode),
    takeEvery(types.CONFIRM_ONE_TIME_CODE, sagas.confirmOneTimeCode),
    takeEvery(types.CROSS_VERIFICATION, sagas.crossVerification),
    takeEvery(types.SET_LOGIN_EMAIL, sagas.setLoginEmail),
    takeEvery(types.SET_LOGIN_PHONE, sagas.setLoginPhone),
    takeEvery(types.REQUEST_PASSWORD_RESET, sagas.requestPasswordReset),
    takeEvery(types.NEWSLETTER_SUBSCRIBE, sagas.newsletterSubscribe),
    takeEvery(types.USER_JOIN_GROUP, sagas.userJoinGroup),
    takeEvery(types.UPDATE, sagas.update),
    takeEvery(types.DELETE_ACCOUNT, sagas.deleteAccount),
])

export default takes
