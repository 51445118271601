import Joi from 'joi'
import experimentsSchema from '../utils/experimentsSchema.model'

const DashboardDashboardOpen = Joi.object()
    .keys({
        experiments: experimentsSchema,
    })
    .description('dashboard:dashboard_open event attributes ')
    .meta({ name: 'DashboardDashboardOpen', filename: 'dashboard:dashboard_open.model' })

export default DashboardDashboardOpen
