import { put, putResolve } from 'redux-saga/effects'
import { REQUEST } from 'piconetworks/pkg-network-middleware'
import { IFRAMELY_API_URL } from 'piconetworks/pkg-endpoints'

function* getUrlMetadata({
    duck,
    linkUrl = null
}) {
    try {
        if (!linkUrl) {
            throw new Error('Must provide url to fetch!')
        }

        let url = IFRAMELY_API_URL(linkUrl)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                }
            }
        })

        if (data) {
            yield put({
                type: duck.types.GET_URL_METADATA_SUCCESS,
                payload: {
                    linkUrl,
                    data
                },
            })
        } else {
            yield put({
                type: duck.types.GET_URL_METADATA_ERROR,
                error,
            })        }
    } catch (error) {
        console.log(error)
        yield put({
            type: duck.types.GET_URL_METADATA_ERROR,
            error,
        })
    }
}

export default getUrlMetadata


