import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import GoogleIconSVG from './google'
import styles from './GoogleIcon.module.scss'

const GoogleIcon = ({ className}) => (
    <div className={cx(styles['whiteBackground'], className)}>
        <GoogleIconSVG />
    </div>
)

GoogleIcon.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default GoogleIcon
