import React from 'react'

export default ({ color = 'currentcolor', width = '24', height = '24' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <polygon
            fill={color}
            fillRule="evenodd"
            points="4.41 11.48 8.877 15.944 19.59 5.23 21 6.65 8.879 18.772 3 12.89"
        />
    </svg>
)
