/* eslint-disable no-template-curly-in-string */
const subscriptionPlan = {
    promptWithoutName: {
        title: 'Thanks for being a loyal member of this community.',
        subtitle: 'Subscribe now to keep your exclusive access.',
        description: null,
    },
    promptWithName: {
        title: 'Thanks for being a loyal member of this community, ${first_name}.',
        subtitle: 'Subscribe now to keep your exclusive access.',
        description: null,
    },
    featureList: {
        show: false,
        title: null,
        list: null,
    },
    trialConfirmationWithoutName:
        'Thank you for trying out a subscription to ${publication_name}. When you see our logo in this corner, you can click on it to manage your subscription.',
    trialConfirmationWithName:
        'Thank you for trying out a subscription to ${publication_name}, ${first_name}. When you see our logo in this corner, you can click on it to manage your subscription.',
    confirmationWithoutName:
        'Thank you for subscribing to ${publication_name}. When you see our logo in this corner, you can click on it to manage your subscription.',
    confirmationWithName:
        'Thank you for subscribing to ${publication_name}, ${first_name}. When you see our logo in this corner, you can click on it to manage your subscription.',
}
/* eslint-enable no-template-curly-in-string */

export default subscriptionPlan
