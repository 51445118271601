import React from 'react'

export default ({ width = '56', height = '56' }) => (
    <svg
        height={height}
        width={width}
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" transform="translate(-8 -8)">
            <rect height="56" width="56" fill="#F9F4FF" rx="28" x="8" y="8" />
            <path
                d="M36,51 C44.2843,51 51,44.2843 51,36 C51,27.7157 44.2843,21 36,21 C27.7157,21 21,27.7157 21,36 C21,44.2843 27.7157,51 36,51 Z M30.732,29.6869 C30.244,30.4557 30,31.2166 30,31.9696 C30,32.3342 30.152,32.675 30.456,32.9921 C30.768,33.3012 31.148,33.4557 31.596,33.4557 C32.356,33.4557 32.872,33.0079 33.144,32.1123 C33.432,31.2563 33.784,30.7886 34.2,30.3527 C34.616,29.9089 35.264,29.6869 36.144,29.6869 C36.896,29.6869 37.508,29.9049 37.98,30.3408 C38.46,30.7768 38.7,31.1334 38.7,31.7675 C38.7,32.0925 38.62,32.3937 38.46,32.6711 C38.308,32.9485 38.116,33.2021 37.884,33.432 C37.66,33.6539 37.292,33.9868 36.78,34.4306 C36.196,34.9379 35.732,35.3778 35.388,35.7503 C35.044,36.1149 34.768,36.5429 34.56,37.0343 C34.352,37.5178 34.248,38.0925 34.248,38.7583 C34.248,39.2893 34.388,39.6896 34.668,39.959 C34.956,40.2285 35.308,40.3633 35.724,40.3633 C36.524,40.3633 37,39.9511 37.152,39.1268 C37.24,38.7384 37.304,38.469 37.344,38.3184 C37.392,38.1598 37.456,38.0053 37.536,37.8547 C37.616,37.6962 37.736,37.5258 37.896,37.3435 C38.064,37.1532 38.284,36.9353 38.556,36.6896 C39.54,35.8177 40.22,35.1995 40.596,34.8349 C40.98,34.4624 41.308,34.0225 41.58,33.5152 C41.86,33.0079 42,32.4174 42,31.7437 C42,30.8877 41.756,30.0951 41.268,29.3659 C40.788,28.6367 40.104,28.0621 39.216,27.642 C38.328,27.214 37.304,27 36.144,27 C34.896,27 33.804,27.2536 32.868,27.7609 C31.932,28.2682 31.22,28.9102 30.732,29.6869 Z M34.572,44.5363 C34.94,44.8454 35.368,45 35.856,45 C36.328,45 36.744,44.8415 37.104,44.5244 C37.464,44.2074 37.644,43.7715 37.644,43.2166 C37.644,42.7173 37.472,42.2972 37.128,41.9564 C36.784,41.6156 36.36,41.4452 35.856,41.4452 C35.344,41.4452 34.912,41.6156 34.56,41.9564 C34.208,42.2972 34.032,42.7173 34.032,43.2166 C34.032,43.7794 34.212,44.2193 34.572,44.5363 Z"
                fill="#7A34FF"
            />
        </g>
    </svg>
)
