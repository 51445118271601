import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FocusWithin from 'react-focus-within'

import styles from './FormInput.module.scss'

import FormField, { FormFieldInputPropTypes, FormFieldPropTypes } from '../FormField'
import { onlyWarnInDevelopmentMode, warnOfDeprecation, warnOnFieldProps } from '../utils/propWarnings'

const FormInput = React.forwardRef(({
    autoFocus = false,
    className,
    disabled = false,
    formFieldClassName,
    isInlineLeft = false,
    isInlineRight = false,
    label,
    step,
    max,
    input,
    maxLength = '',
    meta,
    min,
    placeholder,
    prefix,
    prefixStyle,
    prefixValue,
    required,
    type,
    themeColor = '#7A34FF',
    fieldProps,
    // eventually we want the props spread to go elsewhere inside the component but this is causing too many issues
    // so for now we are spreading it on the Form.Field as well
    ...props
}, ref) => {
    onlyWarnInDevelopmentMode(() => warnOnFieldProps(fieldProps))
    onlyWarnInDevelopmentMode(() => warnOfDeprecation({ ...formFieldClassName && {'formFieldClassName': formFieldClassName} }))
    
    const haveError = meta.touched &&
        (meta.error || meta.submitError) &&
        !meta.dirtySinceLastSubmit &&
        !meta.submitting
    return (
        <FormField
            className={formFieldClassName}
            label={label}
            input={input}
            required={required}
            meta={meta}
            {...props}
            {...fieldProps}
        >
            <FocusWithin>
                {({ isFocused, focusProps }) => (
                    <div
                        {...focusProps}
                        className={cx(styles.container, className)}
                    >
                        {(prefix && prefixValue) && (
                            <div
                                className={cx(styles.prefix, {
                                    [styles.prefixError]: haveError,
                                    [styles.prefixDisabled]: disabled,
                                    [styles.prefixInline]: prefixStyle === 'inline'
                                })}
                                style={
                                    isFocused
                                        ? { borderColor: themeColor }
                                        : {}
                                }
                            >
                                <span>{prefixValue}</span>
                            </div>
                        )}
                        <input
                            className={cx(styles.input, {
                                [styles.inputIsPrefix]: prefix && prefixValue,
                                [styles.inputIsInlineLeft]: isInlineLeft,
                                [styles.inputIsInlineRight]: isInlineRight,
                                [styles.inputError]: haveError,
                                [styles.inputDisabled]: disabled
                            })}
                            disabled={disabled}
                            type={type}
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                            id={input.name}
                            min={min}
                            max={max}
                            step={step}
                            maxLength={maxLength}
                            {...input}
                            {...focusProps}
                            ref={ref}
                            style={
                                isFocused
                                    ? { borderColor: themeColor }
                                    : {}
                            }
                        />
                    </div>
                )}
            </FocusWithin>
        </FormField>
    )
})

FormInput.displayName = 'FormInput'

FormInput.propTypes = {
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    disabled: PropTypes.bool,
    fieldProps: PropTypes.shape(FormFieldPropTypes),
    /** This will be deprecated eventually. Instead pass className to the fieldProps */
    formFieldClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    input: PropTypes.shape(FormFieldInputPropTypes),
    isInlineLeft: PropTypes.bool,
    isInlineRight: PropTypes.bool,
    label: PropTypes.node,
    meta: PropTypes.object,
    prefix: PropTypes.bool,
    prefixStyle: PropTypes.string,
    prefixValue: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    required: PropTypes.bool,
    type: PropTypes.oneOf(['password', 'email', 'text', 'number']),
    step: PropTypes.string,
    themeColor: PropTypes.string
}

FormInput.defaultProps = {
    step: null,
    meta: {
        touched: false
    }
}

export default FormInput
