import { produce } from 'immer'

export const initialState = {
    landingPages: {
        data: [],
        error: null
    },
    popups: {
        data: [],
        error: null,
    },
    defaultPopup: null,
    defaultLandingPage: null,
    defaultPaymentLandingPage: null,
}

const reducer = (
    state,
    { type, payload, error },
    duck,
) => produce(state, (draftState) => {
    switch (type) {

        case duck.types.GET_LANDING_PAGE_SUCCESS:
            draftState.landingPages.data = payload
            break

        case duck.types.GET_DEFAULT_LANDING_PAGE_SUCCESS:
            draftState.defaultLandingPage = payload.defaultLandingPage
            draftState.defaultPaymentLandingPage = payload.defaultLandingPage
            break

        case duck.types.GET_LANDING_PAGE_ERROR:
            draftState.landingPages.error = error
            break

        case duck.types.GET_DEFAULT_POPUP_SUCCESS:
            draftState.defaultPopup = payload.defaultPopup
            break

        case duck.types.GET_POPUP_SUCCESS:
            draftState.popups.data = payload
            break

        case duck.types.GET_POPUP_ERROR:
            draftState.popups.error = error
            break

        default:
        // do nothing
    }
})

export default reducer
