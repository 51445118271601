import cancelPlan from './cancelPlan'
import keepPlan from './keepPlan'
import purchasePlan from './purchasePlan'
import purchasePlanGroup from './purchasePlanGroup'
import purchaseSwitch from './purchaseSwitch'
import verifyDiscountCode from './verifyDiscountCode'
import checkCanSwitchPlanNow from './checkCanSwitchPlanNow'
import getPlans from './getPlans'

const sagas = (duck) => ({
    * cancelPlan(payload) {
        yield cancelPlan({ ...payload,
            ...duck })
    },
    * keepPlan(payload) {
        yield keepPlan({ ...payload,
            ...duck })
    },
    * purchasePlan(payload) {
        yield purchasePlan({ ...payload,
            ...duck })
    },
    * purchasePlanGroup(payload) {
        yield purchasePlanGroup({ ...payload,
            ...duck })
    },
    * purchaseSwitch(payload) {
        yield purchaseSwitch({ ...payload,
            ...duck })
    },
    * verifyDiscountCode(payload) {
        yield verifyDiscountCode({ ...payload,
            ...duck })
    },
    * checkCanSwitchPlanNow(payload) {
        yield checkCanSwitchPlanNow({ ...payload,
            ...duck })
    },
    * getPlans(payload) {
        yield getPlans({ ...payload,
            ...duck })
    },
})

export default sagas
