import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from 'piconetworks/pkg-network-middleware'
import {
    PICO_API_URL,
} from 'piconetworks/pkg-endpoints'

function* getClient({
    types,
    publisherId
}) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                baseURL: PICO_API_URL(),
                url: `/client/query/${publisherId}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
            },
        })
        if (!data){
            throw 'Could not fetch client information'
        }
        yield put({
            type: types.GET_CLIENT_SUCCESS,
            payload: data,
        })
    } catch (error) {
        yield put({
            type: types.GET_CLIENT_ERROR,
            error: error?.message || 'SET_CLIENT_ERROR',
        })
    }
}

export default getClient
