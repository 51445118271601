import SegmentPlugin from '@analytics/segment'
import {
    TwitterPixelPlugin
} from './Pixels'

const PLUGIN_REQUIRED_ARGS = {
    segment: ['writeKey'],
    twitter: []
}

const PLUGINS = {
    segment: SegmentPlugin,
    twitter: TwitterPixelPlugin
}

const resolveProviderPlugins = (plugins = {}, pico_plugin_options) => {
    const pluginsToUse = []

    Object.keys(plugins)
        .filter((pluginName) => pluginName in PLUGIN_REQUIRED_ARGS)
        .forEach((plugin) => {
            const pluginConfig = plugins[plugin]

            // Make sure all required arguments are passed at instantiation
            if (PLUGIN_REQUIRED_ARGS[plugin].every((reqArg) => !!pluginConfig[reqArg])) {
                pluginsToUse.push(PLUGINS[plugin]({ ...pluginConfig, ...pico_plugin_options }))
            }
        })

    return pluginsToUse
}

export default resolveProviderPlugins
