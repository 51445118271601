import React from 'react'
import PropTypes from 'prop-types'
import IcomoonReact, { iconList } from 'icomoon-react'
import selection from './selection.json'

const Picon = React.forwardRef(({ color, size, icon, className, children, iconAfter, ...props }, ref) => {
    if (children) {
        if (iconAfter === true) {
            return (
                <React.Fragment>
                    {children}
                    <IcomoonReact
                        className={className}
                        iconSet={selection}
                        color={color}
                        size={size}
                        icon={icon}
                        ref={ref}
                        {...props}
                    />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <IcomoonReact
                        className={className}
                        iconSet={selection}
                        color={color}
                        size={size}
                        icon={icon}
                        ref={ref}
                        {...props}
                    />
                    {children}
                </React.Fragment>
            )
        }
    }

    return (
        <IcomoonReact
            className={className}
            iconSet={selection}
            color={color}
            size={size}
            icon={icon}
            ref={ref}
            {...props}
        />
    )
})

// const PiconIcons = iconList(selection).sort()

Picon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    /** If there are children, whether to render the icon after the children */
    iconAfter: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Picon.defaultProps = {
    iconAfter: false,
    size: '56'
}

Picon.displayName = 'Picon'

// export { PiconIcons }
export default Picon
