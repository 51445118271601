import React from 'react'

export default ({ title = 'brand/webhooks' }) => (
    <svg
        height="64px"
        width="64px"
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g
            id="Brands"
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        >
            <g id="brand/webhooks">
                <polygon
                    id="bounds"
                    fill="#EEEDEF"
                    points="0 0 64 0 64 64 0 64"
                />
                <g id="webhooks" transform="translate(8.000000, 9.000000)">
                    <path
                        id="Path"
                        d="M22.494731,18.7389728 C20.4981684,22.0953056 18.5853447,25.3455063 16.6326275,28.571244 C16.1311346,29.3994129 15.8829285,30.074029 16.2835583,31.1266936 C17.3896653,34.0347877 15.829298,36.8647882 12.8967408,37.6329285 C10.1310969,38.3575999 7.4365842,36.539994 6.88785879,33.579022 C6.40160829,30.9582748 8.43543005,28.3890882 11.3250828,27.9794259 C11.567079,27.9448013 11.8143441,27.9408496 12.2211838,27.9103648 L16.6166324,20.5398309 C13.8521176,17.7909349 12.2066941,14.5778051 12.5708174,10.5959705 C12.8282442,7.78140054 13.9351039,5.34901897 15.9580113,3.35546718 C19.8325848,-0.462276696 25.7436145,-1.08043958 30.3057883,1.84985949 C34.6873118,4.66461762 36.694036,10.1473555 34.9835031,14.8399363 C33.6937349,14.4903026 32.3949343,14.1376581 30.967232,13.7503889 C31.5042904,11.1413087 31.1070479,8.7984996 29.3474006,6.79139904 C28.1848404,5.46625351 26.6929697,4.77169059 24.9967383,4.51576927 C21.5959956,4.00204486 18.2571633,6.18678493 17.2664091,9.52467633 C16.1418607,13.3126882 17.8439256,16.4072662 22.494731,18.739161 L22.494731,18.7389728 Z"
                        fill="#7A34FF"
                    />
                    <path
                        id="Path"
                        d="M28.1966956,14.7697461 C29.603322,17.251242 31.0315887,19.7701852 32.4470594,22.2659825 C39.6015656,20.0524513 44.9958601,24.0130219 46.9310769,28.2532241 C49.268617,33.3752258 47.6706142,39.4416901 43.0800256,42.6017538 C38.3680625,45.8455565 32.4090476,45.2913739 28.2343311,41.1243727 C29.2982863,40.2337289 30.3675105,39.3391334 31.5099358,38.3833802 C35.6332798,41.0539944 39.2397004,40.9282919 41.917089,37.7657818 C44.2002459,35.0678819 44.1507552,31.0452128 41.8013599,28.4039542 C39.0900994,25.3560443 35.458463,25.2630846 31.0686597,28.1888674 C29.2476666,24.9582372 27.3950597,21.7533872 25.6316489,18.4994229 C25.0370082,17.4027248 24.3806453,16.7663087 23.0404456,16.5342859 C20.8024513,16.1462641 19.3576249,14.2244079 19.2708752,12.0712816 C19.1856308,9.94186572 20.4402098,8.0169987 22.4010187,7.2671116 C24.3433862,6.5239989 26.6227795,7.12372039 27.9292955,8.7751656 C28.9970143,10.1243978 29.3362982,11.6429898 28.7744003,13.3070429 C28.6182131,13.7710884 28.4159223,14.2200798 28.1966956,14.7699343 L28.1966956,14.7697461 Z"
                        fill="#77747C"
                    />
                    <path
                        id="Path"
                        d="M31.5586737,35.055274 L22.9457924,35.055274 C22.1202579,38.4507477 20.3370885,41.1921166 17.2647155,42.9353924 C14.8761792,44.29027 12.3019119,44.7496111 9.56204837,44.3072059 C4.5173876,43.4937149 0.392538137,38.9524287 0.0293556804,33.838895 C-0.381623846,28.0460407 3.60002258,22.8969415 8.90719089,21.740403 C9.27357236,23.0710056 9.64390556,24.4144044 10.010287,25.741808 C5.14100763,28.2261266 3.45569049,31.3562701 4.8184715,35.2703608 C6.01810267,38.7147606 9.42580791,40.6027449 13.1259409,39.8726164 C16.9045439,39.1270574 18.8096522,35.9867523 18.5770649,30.9469841 C22.1592107,30.9469841 25.7443672,30.9099132 29.3268893,30.9652374 C30.7258004,30.9872541 31.8057507,30.8421693 32.8595444,29.6088544 C34.5945403,27.5795489 37.7880997,27.7626455 39.6567016,29.6792327 C41.5663263,31.6379717 41.474872,34.7895674 39.4540345,36.6656965 C37.5043281,38.4759635 34.4240516,38.3792403 32.5998595,36.4285929 C32.22501,36.0266459 31.9295715,35.5492398 31.5586737,35.055274 Z"
                        fill="#77747C"
                    />
                </g>
            </g>
        </g>
    </svg>
)
