import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getSvgImage } from 'piconetworks/pkg-functions'
import MenuWrapper from './components/MenuWrapper'
import Otp from 'piconetworks/pkg-field-otp'
import { Form as ReactFinalForm } from 'react-final-form'
import cx from 'classnames'
import styles from './scss/MenuRoutes.module.scss'

const EditProfileOTP = ({
    changeMenuRoute,
    sendExternalLink,
    publisherName,
    utmRootUrl,
    queryOverride,
    linkColor,
    menuPayload: { phone, email, verify = 'phone' },
    contrastColor,
    fieldContainerProps,
    oneTimeCodeError,
    oneTimeCodeSent,
    resendOneTimeCode,
    publisherId,
    origin,
    requestOneTimeCode,
    confirmPhoneOrEmailUpdate,
}) => {
    const [codeSent, setCodeSent] = useState(false)

    useEffect(() => {
        if (codeSent === false) {
            requestOneTimeCode({
                ...(verify === 'phone' ? { phone } : { email }),
                publisherId,
                origin,
            })
            setCodeSent(true)
        }

        return () => setCodeSent(false)
    }, [])

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            utmRootUrl={utmRootUrl}
            queryOverride={queryOverride}
            customFooter={null}
            hasCustomFooter
            className={styles.loginHeight}
        >
            <div
                className={classNames(styles.menuHeader, styles.noBottomMargin)}
            >
                <div
                    className={styles.backIcon}
                    onClick={() => {
                        changeMenuRoute(`/editProfile/${verify === 'phone' ? 'editPhone' : 'editEmail'}`)
                    }}
                    style={{ color: linkColor }}
                >
                    {getSvgImage(linkColor, 'back-icon')}
                </div>

                <h3>Verify {verify === 'email' ? 'Email Address' : 'Phone Number'}</h3>
            </div>
            <ReactFinalForm
                onSubmit={() => {
                    return {}
                }}
                keepDirtyOnResubmit={true}
                keepDirtyOnReinitialize
                render={() => (
                    <form>
                        <div
                            className={cx(styles.loginContainer, styles.white)}
                        >
                            <Otp
                                checkPhone={resendOneTimeCode}
                                confirmCode={({ code }) =>
                                    confirmPhoneOrEmailUpdate({
                                        code,
                                        email,
                                        phone,
                                        origin,
                                        publisherId,
                                    })
                                }
                                contrastColor={contrastColor}
                                phoneNumber={phone}
                                email={email}
                                fieldContainerProps={fieldContainerProps}
                                linkColor={linkColor}
                                oneTimeCodeError={oneTimeCodeError}
                                oneTimeCodeSent={oneTimeCodeSent}
                                resendOneTimeCode={resendOneTimeCode}
                                allowSkipOtp={false}
                                isNewRegistration
                            />
                        </div>
                    </form>
                )}
            />
        </MenuWrapper>
    )
}

EditProfileOTP.defaultProps = {
    sendExternalLink: null,
    utmRootUrl: null,
}

EditProfileOTP.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    checkEmail: PropTypes.func,
    confirmLoginCode: PropTypes.func,
    confirmPhoneOrEmailUpdate: PropTypes.func,
    contrastColor: PropTypes.string,
    fieldContainerProps: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    linkColor: PropTypes.string,
    menuPayload: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string,
        verify: PropTypes.string,
    }),
    oneTimeCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    oneTimeCodeSent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    origin: PropTypes.string,
    publisherId: PropTypes.string,
    publisherName: PropTypes.string,
    queryOverride: PropTypes.object,
    requestOneTimeCode: PropTypes.func,
    resendOneTimeCode: PropTypes.func,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string,
}

export default EditProfileOTP
