import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import MenuWrapper from './components/MenuWrapper'
import { colors } from 'piconetworks/pkg-functions'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

dayjs.extend(localizedFormat)

const NotificationMenuPage = (props) => {
    const {
        backRoute,
        data,
        changeMenuRoute,
        linkColor,
        next,
        nextSubscriptionEnd,
        nextProductOptionPrice,
        nextProductOptionInterval,
        publisherName,
        utmRootUrl,
        queryOverride
    } = props

    const { switchNow } = data || {}

    const nextPaymentStart = next?.subscription_start || nextSubscriptionEnd

    const descriptionText = switchNow
        ? `Your plan will switch to ${nextProductOptionPrice}/${nextProductOptionInterval} on ${dayjs(nextSubscriptionEnd).format('LL')}.`
        : `Your plan has been successfully updated. Your next payment is scheduled for ${dayjs(nextPaymentStart).format('LL')}.`

    return (
        <MenuWrapper publisherName={publisherName} utmRootUrl={utmRootUrl} queryOverride={queryOverride} hideBackground>
            <div className={classNames(style.menuHeader, style.shadow, style.notificationHeader)}>
                <div className={style.backIcon} onClick={() => { changeMenuRoute(!backRoute ? '/' : backRoute) }} style={{ color: linkColor }}>
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Notifications</h3>
            </div>
            <div className={classNames(style.background)} style={{ backgroundColor: colors.getBgColor(linkColor, 0.15) }}>
                <div className={style.notification}>
                    {descriptionText}
                </div>
            </div>
        </MenuWrapper>
    )
}

NotificationMenuPage.defaultProps = {
    publisherId: null,
}

NotificationMenuPage.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    publisherId: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    utmRootUrl: PropTypes.string,
    next: PropTypes.any,
    queryOverride:PropTypes.object,
}

export default NotificationMenuPage
