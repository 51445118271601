import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Pre.module.scss'

const Pre = ({children, className, ...props}) => {
    return (
        <pre className={cx(styles.pre, className)} {...props}>
            {children}
        </pre>
    )
}

Pre.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Pre
