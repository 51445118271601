import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = () => ({
    purchase: new Duck.Selector(() => (state) => state?.purchase),
    getPurchases: new Duck.Selector((selectors) => createSelector(
        selectors.purchase,
        ({ purchases } = {}) => purchases
    )),
    getProduct: new Duck.Selector((selectors) => createSelector(
        selectors.purchase,
        ({ product } = {}) => product
    )),
    getSwitchProducts: new Duck.Selector((selectors) => createSelector(
        selectors.purchase,
        ({ switchProducts } = {}) => switchProducts
    )),
    getSwitchableProducts: new Duck.Selector((selectors) => createSelector(
        selectors.getSwitchProducts,
        ({ data } = []) => data
    )),
    getSwitchProductsLoading: new Duck.Selector((selectors) => createSelector(
        selectors.getSwitchProducts,
        ({ loading } = {}) => loading
    )),
    getSwitchProration: new Duck.Selector((selectors) => createSelector(
        selectors.purchase,
        ({ switchProration } = {}) => switchProration
    )),
    getGift: new Duck.Selector((selectors) => createSelector(
        selectors.purchase,
        ({ gift } = {}) => gift
    )),
    userHasCurrentPurchases: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => !!Object.keys(data?.current || {}).length
    )),
    nextSubscriptionStart: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.next?.subscription_start
    )),
    nextSubscriptionEnd: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.next?.subscription_end
    )),
    nextStatusNext: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.next?.status === 'NEXT'
    )),
    nextProductOptionPrice: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.next?.product_option_price
    )),
    nextProductOptionInterval: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.next?.product_option_interval
    )),
    firstDonation: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.first_donation
    )),
    currentBillingIntervalTotalPaid: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.current_billing_interval_total_paid
    )),
    originalEnrollmentDate: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.history?.originalEnrollmentDate
    )),
    next: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => Object.keys(data?.history?.next || {}).length ? history.next : null
    )),
    currentSubscriptionPrice: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_price
    )),
    currentSubscriptionCurrency: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_currency
    )),
    currentSubscriptionType: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_type
    )),
    currentSubscriptionTypeIsPass: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_type === 'pass'
    )),
    currentSubscriptionTypeIsRecurringDonation: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_type === 'recurring-donation'
    )),
    currentSubscriptionTypeIsSubscription: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_type === 'subscription'
    )),
    currentSubscriptionIsTrial: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => !!data?.current?.subscription_trial_end
    )),
    currentSubscriptionStart: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_start
    )),
    currentProductOptionId: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.product_option_id
    )),
    currentProductId: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.product_id
    )),
    currentProductOptionCurrency: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.product_option_currency
    )),
    currentSubscriptionId: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_id
    )),
    currentSubscriptionIdIsGift: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_id === 'GIFT'
    )),
    currentSubscriptionEnd: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.subscription_end
    )),
    currentSubscriptionPlanTierTitle: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.plan_tier_title
    )),
    currentPurchaseCanceled: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.status === 'CANCELED'
    )),
    currentPurchaseActive: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.status === 'ACTIVE'
    )),
    currentPurchaseFailed: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.status === 'FAILED'
    )),
    // group
    isGroupAdmin: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => !!data?.current?.group?.admin
    )),
    isGroupMember: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => !!data?.current?.group?.member
    )),
    groupName: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.group?.name
    )),
    groupSize: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => data?.current?.group?.size
    )),
    userHasGroupData: new Duck.Selector((selectors) => createSelector(
        selectors.getPurchases,
        ({ data } = {}) => !!Object.keys(data?.current?.group || {}).length
    )),
})

export default selectors
