import HSL from './HSL'

const RGB = {
    isRGB: (value) => /rgb\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*\)/.test(value),
    isRGBA: (value) => /rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)\s*\)/.test(value),
    toHEX: ({ r, g, b }) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }).join(''),
    toHSL: ({ r, g, b }) => {
        try {
            const red = r / 255
            const green = g / 255
            const blue = b / 255

            const max = Math.max(red, green, blue)
            const min = Math.min(red, green, blue)

            let hue, saturation
            let lightness = (max + min) / 2

            if (max === min) {
                hue = 0
                saturation = 0
            } else {
                const range = max - min

                saturation = range / (1 - Math.abs(2 * lightness - 1))

                switch (max) {
                    case red:
                        hue = ((green - blue) / range) % 6
                        break
                    case green:
                        hue = (blue - red) / range + 2
                        break
                    case blue:
                        hue = (red - green) / range + 4
                        break
                    default:
                        hue = (max + min) / 2
                        break
                }

                hue = Math.round(hue * 60)
                saturation = +(saturation * 100).toFixed(1)
                lightness = +(lightness * 100).toFixed(1)
            }
            return { h: hue, s: saturation, l: lightness }
                
        } catch (error) {
            console.error(error)
        }
    },
    saturate: (rgb, amount = 10) => HSL.toRGB(HSL.saturate(RGB.toHSL(rgb), amount)),
    desaturate: (rgb, amount = 10) => HSL.toRGB(HSL.desaturate(RGB.toHSL(rgb), amount)),
    darken: (rgb, amount = 10) => HSL.toRGB(HSL.darken(RGB.toHSL(rgb), amount)),
    lighten: (rgb, amount = 10) => HSL.toRGB(HSL.lighten(RGB.toHSL(rgb), amount))
}

export default RGB
