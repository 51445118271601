import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

import { symbol, format } from 'piconetworks/pkg-currency'
import { colors } from 'piconetworks/pkg-functions'
const { getContrastColor, isColor } = colors

import { retrievePlanPrompt } from '../langTemplates/generators/planTemplate'

import {
    retrieveRulePromptTitle,
    retrieveRulePromptSubtitle,
    retrieveRulePromptDescription,
    retrieveRuleMenuTitle,
    retrieveRuleMenuSubtitle,
    retrieveRuleMenuAction,
    getHeaderDescription,
} from '../langTemplates/generators/ruleTemplate'
import { compile } from '../langTemplates/utils'

const sortByKey = (items, key) => items.slice().sort((a, b) => a[key] - b[key])

dayjs.extend(localizedFormat)

import popups from './popups'

export default (duck) => {
    const popupsSelectors = popups(duck)

    return ({
        ...popupsSelectors,
        picoColor: () => '#834eff',
        loader: (state) => state?.loader,
        topScopeLoader: (state) => state?.[duck.store]?.user || state?.[duck.store]?.guest || {},
        hasGuestLoader: (state) => !!state[duck.store].guest,
        hasUserLoader: (state) => !!state[duck.store].user,
        memberType: (form) => {
            const isPledge = duck.selectors.monetizationIsPledge(form)
            if (isPledge) {
                return 'member'
            }

            const isSubscription = duck.selectors.monetizationTypeIsSubscription(form)
            if (isSubscription) {
                return 'subscriber'
            }

            const isPass = duck.selectors.monetizationTypeIsPass(form)
            if (isPass) {
                return 'pass holder'
            }

            const isDonation = duck.selectors.monetizationTypeIsDonation(form)
            if (isDonation) {
                return 'donor'
            }

            return null
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        intervalAdverb: (interval) => {
            switch (interval) {
                case 'Month':
                    return 'Monthly'
                case 'Year':
                    return 'Yearly'
                case 'month':
                    return 'monthly'
                case 'year':
                    return 'yearly'
            }

            return ''
        },
        priceText: (price, optionCurrency = 'USD', showCurrencyCode = true) => optionCurrency === 'USD'
            ? `${symbol(optionCurrency)}${price} ${optionCurrency}`
            : showCurrencyCode
                ? `${price} ${symbol(optionCurrency)} ${optionCurrency}`
                : price,
        orderSummary: (state, { productId, optionId, customPrice }) => {
            if (!productId || !optionId) {
                return null
            }

            const product = duck.selectors.popupGetProductById(state, productId)
            const option = duck.selectors.popupGetOptionById(state, optionId)
            const priceText = duck.selectors.priceText

            //const showCurrencyCode = option.currency !== 'USD'
            const showCurrencyCode = true

            if (!option || !product) {
                return null
            }

            const isPledge = duck.selectors.popupIsPledge(state, { productId })

            const intervalAdverb = duck.selectors.capitalizeFirstLetter(duck.selectors.intervalAdverb(option.interval))

            const formattedOptionType = isPledge ? 'membership' : option.type

            const productTitle = product.title || 'No Title'
            const optionTypeAndInterval = `${intervalAdverb} ${duck.selectors.memberNounFromString(formattedOptionType)}`
            const renewalDate = dayjs().add(option.interval_quantity, option.interval).format('LL')
            const trialFinishDate = dayjs().add(option.trial_quantity, option.trial_interval).format('LL')
            const optionPrice = format({
                currencyCode: option?.currency,
                amount: option?.price,
                symbol: !showCurrencyCode,
            })
            const optionInterval = option.interval

            const currentSubscriptionPrice = duck.selectors.currentSubscriptionPrice(state)
            const currentSubscriptionCurrency = duck.selectors.currentSubscriptionCurrency(state)

            const oldOptionPrice = (
                currentSubscriptionPrice
                && currentSubscriptionCurrency
            )
                ? format({
                    currencyCode: currentSubscriptionCurrency,
                    amount: currentSubscriptionPrice,
                    symbol: !showCurrencyCode,
                })
                : null

            const parsedCustomPrice = parseInt(customPrice)
            const optionCustomPrice = !Number.isNaN(parsedCustomPrice) && format({
                currencyCode: option?.currency,
                amount: parsedCustomPrice,
                symbol: !showCurrencyCode,
            })

            const trialPrice = format({
                currencyCode: option?.currency,
                amount: option?.trial_price,
                symbol: !showCurrencyCode,
            })
            const isTrial = !!option?.trial_price || !!option?.trial_interval
            const isFree = isTrial && !((option?.trial_price || 0) > 0)

            const trialDuration = isTrial
                && `${option.trial_quantity} ${option.trial_interval}${(option.trial_quantity > 1) ? 's' : ''}`

            const passDuration = (option.type === 'pass')
                && `${option.interval_quantity} ${option.interval}${(option.interval_quantity > 1) ? 's' : ''}`

            return {
                productTitle,
                optionTypeAndInterval,
                renewalDate,
                optionPrice: priceText(optionPrice, option?.currency, showCurrencyCode),
                optionInterval,
                ...(oldOptionPrice && {
                    oldOptionPrice: priceText(oldOptionPrice, currentSubscriptionCurrency, showCurrencyCode),
                }),
                ...(optionCustomPrice && {
                    optionCustomPrice: priceText(optionCustomPrice, option?.currency, showCurrencyCode),
                }),
                ...(passDuration && { passDuration }),
                trialPrice: isTrial && priceText(trialPrice, option?.currency, showCurrencyCode),
                trialDuration,
                trialFinishDate,
                isTrial,
                isFree
            }
        },
        userWindowObject: (state) => {
            const id = duck.selectors.userId(state)
            const email = duck.selectors.userEmail(state)
            const authenticated = duck.selectors.userAuth(state)
            const first_name = duck.selectors.userFirstName(state)
            const last_name = duck.selectors.userLastName(state)
            const membership_type = duck.selectors.currentSubscriptionType(state)
            const tier = duck.selectors.currentSubscriptionPlanTierTitle(state)
            const group_name = duck.selectors.groupName(state)
            const total_paid = duck.selectors.currentBillingIntervalTotalPaid(state)
            const recurring_payment = duck.selectors.currentSubscriptionPrice(state)
            const verified = duck.selectors.userVerified(state)

            return {
                id,
                authenticated,
                email,
                verified,
                first_name,
                last_name,
                membership_type,
                tier,
                group_name,
                total_paid,
                recurring_payment,
            }
        },
        // -----
        // Used In
        // Account menu - Notification.KeepPlan.js (pass holder excluded)
        // -----
        memberNoun: (form) => {
            const memberType = duck.selectors.memberType(form)

            switch (memberType) {
                case 'member':
                    return 'membership'

                case 'subscriber':
                    return 'subscription'

                case 'pass holder':
                    return 'pass'

                case 'donor':
                    return 'contribution'

                default:
                // do nothing
            }

            return ''
        },
        memberNounFromString: (string) => {
            const isPledge = string === 'membership'
            if (isPledge) {
                return 'membership'
            }

            const isSubscription = string === 'subscription'
            if (isSubscription) {
                return 'subscription'
            }

            const isPass = string === 'pass'
            if (isPass) {
                return 'pass'
            }

            const isDonation = (
                string === 'recurring-donation'
                || string === 'single-donation'
            )
            if (isDonation) {
                return 'contribution'
            }

            return null
        },
        // -----
        // Used In
        // Account menu - ManagePlan.Subscription.StopSubscription (pass holder excluded)
        // -----
        memberVerbPresentParticiple: (state) => {
            const memberType = duck.selectors.memberType(state)

            switch (memberType) {
                case 'member':
                    return 'your membership'

                case 'subscriber':
                    return 'subscribing'

                case 'pass holder':
                    return 'your pass'

                case 'donor':
                    return 'contributing'

                default:
                // do nothing
            }

            return ''
        },
        isFetching: (state) => state.loader.isFetching,
        isNotFetching: (state) => !state.loader.isFetching,
        publisherId: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.id
            ) {
                return null
            }

            return loader.publisher.id
        },
        publisherName: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.name
            ) {
                return null
            }

            return loader.publisher.name
        },
        publisherUsername: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.username
            ) {
                return null
            }

            return loader.publisher.username
        },
        publisherIcon: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.icon
            ) {
                return null
            }

            return loader.publisher.icon
        },
        publisherUrl: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.url
            ) {
                return null
            }

            return loader.publisher.url
        },
        supportEmail: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader.publisher
                || !loader.publisher.support_email
            ) {
                return null
            }

            return loader.publisher.support_email
        },
        userFullName: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.full_name
        },
        userFirstName: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.first_name
        },
        userLastName: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.last_name
        },
        userEmail: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.email
        },
        userAuth: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return false
            }

            return state[duck.store].user.user.auth
        },
        userId: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.id
        },
        userHasPassword: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.has_password
        },
        userAvatarUrl: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            return state[duck.store].user.user.avatar_url
        },
        userInitials: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return null
            }

            const user = state[duck.store].user.user
            const userInitials = user?.initials || ''

            return isEmpty(user?.initials) ? user?.email?.[0] : userInitials
        },
        userStatus: (state) => {
            const has_purchase = duck.selectors.userHasCurrentPurchases(state)
            const registered = duck.selectors.userId(state)
            const is_group_member = duck.selectors.isGroupMember(state)

            if (has_purchase || is_group_member) {
                return 'paying'
            }
            else if (registered) {
                return 'registered'
            }
            return 'anonymous'
        },
        userVerified: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return false
            }

            return state[duck.store].user.user.verified
        },
        userSyncNewsletters: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.user
            ) {
                return false
            }

            return state[duck.store].user.user.sync
        },
        currentSubscriptionPrice: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_price
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_price
        },
        currentSubscriptionCurrency: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_currency
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_currency
        },
        currentSubscriptionType: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_type
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_type
        },
        currentSubscriptionTypeIsPass: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_type
            ) {
                return false
            }

            return state[duck.store].user.purchases.current.subscription_type === 'pass'
        },
        currentSubscriptionTypeIsRecurringDonation: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_type
            ) {
                return false
            }

            return state[duck.store].user.purchases.current.subscription_type === 'recurring-donation'
        },
        currentSubscriptionInterval: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_option_interval
            ) {
                return false
            }

            return state[duck.store].user.purchases.current.product_option_interval
        },
        currentSubscriptionTypeIsSubscription: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_type
            ) {
                return false
            }

            return state[duck.store].user.purchases.current.subscription_type === 'subscription'
        },
        currentSubscriptionIsTrial: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
            ) {
                return false
            }

            return state[duck.store].user.purchases.current.subscription_trial_end !== null
        },
        currentSubscriptionStart: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_start
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_start
        },
        currentProductTitle: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_title
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.product_title
        },
        currentProductId: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_id
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.product_id
        },
        currentProductOptionId: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_option_id
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.product_option_id
        },
        currentProductOptionCurrency: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_option_currency
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.product_option_currency
        },
        currentSubscriptionId: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_id
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_id
        },
        currentSubscriptionIdIsGift: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_id
            ) {
                return false
            }

            return (
                state[duck.store].user.purchases.current.subscription_id === 'GIFT'
            )
        },
        currentSubscriptionEnd: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.subscription_end
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.subscription_end
        },
        currentSubscriptionPlanTierTitle: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.product_title
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.product_title
        },
        currentPurchaseCanceled: (state) => {
            const {
                current: currentPurchase,
                history: {
                    next: nextPurchase,
                    changes,
                } = {}
            } = state[duck.store]?.user?.purchases || {}

            // purchase is cancelled if current and next are both active trials
            // and user has not reactivated
            // and subscriptions canceled
            const isCancelledTrial = (currentPurchase?.subscription_trial_end || currentPurchase?.subscription_trial_price)
                && (nextPurchase?.subscription_trial_end || nextPurchase?.subscription_trial_price)
                && (changes[0]?.logType !== 'USER_REACTIVATED')
                && (currentPurchase?.subscription_canceled || nextPurchase?.subscription_canceled)

            return currentPurchase?.status === 'CANCELED' || isCancelledTrial || false
        },
        currentPurchaseActive: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.status
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.status === 'ACTIVE'
        },
        currentPurchaseFailed: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
                || !state[duck.store].user.purchases.current.status
            ) {
                return null
            }

            return state[duck.store].user.purchases.current.status === 'FAILED'
        },
        discordLink: (state) => {
            return state[duck.store]?.user?.purchases?.current?.discord_link
        },
        updatePaymentMethodText: (state) => {
            const memberType = duck.selectors.memberType(state)

            switch (memberType) {
                case 'member':
                case 'subscriber':
                    return 'Please update your payment method to restore subscription benefits.'

                case 'donor':
                    return 'Please update your payment method to restore your donation.'

                default:
                // do nothing
            }

            return ''
        },
        badgePaymentMethod: (state) => {
            const firstPaymentMethod = state?.[duck.store]?.user?.paymentMethods?.[0]
            if (
                !firstPaymentMethod
                || !firstPaymentMethod?.cardType
                || !firstPaymentMethod?.last4
            ) {
                return null
            }

            return {
                cardType: firstPaymentMethod.cardType,
                last4: firstPaymentMethod.last4,
            }
        },
        nextSubscriptionStart: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || !state[duck.store].user.purchases.history.next.subscription_start
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.next.subscription_start
        },
        nextSubscriptionEnd: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || !state[duck.store].user.purchases.history.next.subscription_end
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.next.subscription_end
        },
        nextStatusNext: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || !state[duck.store].user.purchases.history.next.status
            ) {
                return false
            }

            return state[duck.store].user.purchases.history.next.status === 'NEXT'
        },
        nextProductOptionPrice: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || !state[duck.store].user.purchases.history.next.product_option_price
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.next.product_option_price
        },
        nextProductOptionInterval: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || !state[duck.store].user.purchases.history.next.product_option_interval
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.next.product_option_interval
        },
        allNewsletters: (state) => state[duck.store]?.user?.newsletters || state[duck.store]?.guest?.newsletters || [],
        subscribedNewsletters: (state) => {
            const newsletters = duck.selectors.allNewsletters(state)
            return newsletters.filter(({ subscribed }) => subscribed)
        },
        firstDonation: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.first_donation
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.first_donation
        },
        currentBillingIntervalTotalPaid: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.current_billing_interval_total_paid
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.current_billing_interval_total_paid
        },
        originalEnrollmentDate: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.originalEnrollmentDate
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.originalEnrollmentDate
        },
        next: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.history
                || !state[duck.store].user.purchases.history.next
                || Object.keys(state[duck.store].user.purchases.history.next).length === 0
            ) {
                return null
            }

            return state[duck.store].user.purchases.history.next
        },
        monetizationBillingStatus: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            return loader?.purchases?.history?.has_purchased ? 'PAID' : null
        },
        pricingHasMonetization: (form) => {
            return (form?.products || []).some(product =>
                !!product?.options?.length
            )
        },
        userHasCurrentPurchases: (state) => {
            if (
                !state[duck.store].user
                || !state[duck.store].user.purchases
                || !state[duck.store].user.purchases.current
            ) {
                return false
            }

            return Object.keys(state[duck.store].user.purchases.current).length > 0
        },
        monetizationIsPledge: (form) => {
            const orderType = duck.selectors.getOrderType(
                form?.products?.length && form.products[0]
            ) || ''

            return orderType.includes('pledge')
        },
        monetizationTypeIsSubscription: (form) => {
            const orderType = duck.selectors.getOrderType(
                form?.products?.length && form.products[0]
            ) || ''
            return orderType.includes('subscription')
        },
        monetizationTypeIsDonation: (form) => {
            const orderType = duck.selectors.getOrderType(
                form?.products?.length && form.products[0]
            ) || ''
            return orderType.includes('donation')
        },
        monetizationTypeIsGroup: (form) => {
            const orderType = duck.selectors.getOrderType(
                form?.products?.length && form.products[0]
            ) || ''
            return orderType.includes('group')
        },
        monetizationTypeIsPass: (form) => {
            const orderType = duck.selectors.getOrderType(
                form?.products?.length && form.products[0]
            ) || ''
            return orderType.includes('pass')
        },
        isGroupAdmin: new Duck.Selector((selectors) => createSelector(
            selectors.topScopeLoader,
            ({
                purchases: {
                    current = {}
                } = {}
            } = {}
            ) => current.billing_admin,
        )),
        isGroupMember: new Duck.Selector((selectors) => createSelector(
            selectors.topScopeLoader,
            ({
                purchases: {
                    current = {}
                } = {}
            } = {}
            ) => current.subscription_type === 'group',
        )),
        groupName: new Duck.Selector((selectors) => createSelector(
            selectors.topScopeLoader,
            ({
                purchases: {
                    current = {}
                } = {}
            } = {}
            ) => (current.subscription_type || '').includes('group') ? current.product_title : '',
        )),
        groupSize: (state) => {
            const loader = duck.selectors.topScopeLoader(state)

            if (
                !loader
                || !loader.group
                || !loader.group.size
            ) {
                return null
            }

            return loader.group.size
        },
        userHasGroupData: new Duck.Selector((selectors) => createSelector(
            selectors.isGroupMember,
            selectors.isGroupAdmin,
            (isMember, isAdmin) => isMember || isAdmin
        )),
        linkColor: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            const picoColor = duck.selectors.picoColor()
            const linkColor = loader?.publisher?.styling?.linkColor

            if (
                !loader.publisher
                || !loader.publisher.styling
                || !loader.publisher.styling.linkColor
            ) {
                return picoColor
            }

            return isColor(linkColor) ? linkColor : picoColor
        },
        contrastColor: (state) => {
            const linkColor = duck.selectors.linkColor(state)
            const contrastColor = getContrastColor(linkColor)
            return contrastColor
        },
        scopedLoader: (state, scope) => state.loader[scope],
        tiers: new Duck.Selector((selectors) => (state, scope = 'guest') => get(selectors.scopedLoader(state, scope), 'pricing.monetization.tiers', [])),
        tiersSorted: new Duck.Selector((selectors) => createSelector(
            selectors.tiers,
            (items) => sortByKey(items, 'tierOrder'),
        )),
        planTexts: new Duck.Selector((selectors) => (state, scope = 'guest', form) => {
            const loader = selectors.scopedLoader(state, scope)

            if (loader) {
                const monetizationType = form?.products?.length && form.products[0]?.type
                const hasPurchased = loader.purchases
                    && loader.purchases.history
                    && loader.purchases.history.has_purchased
                const planTemplates = loader?.lang?.plan
                const userFirstName = loader.user.first_name
                const texts = retrievePlanPrompt({
                    planTemplates,
                    userFirstName,
                    monetizationType,
                    hasPurchased,
                })
                return texts
            }

            return null
        }),
        ruleTexts: new Duck.Selector((selectors) => (state, scope = 'guest') => {
            const loader = selectors.scopedLoader(state, scope)

            if (loader) {
                const values = {
                    prompt: {
                        title: '',
                        subTitle: '',
                        description: '',
                    },
                    menu: {
                        title: '',
                        subTitle: '',
                        button: '',
                    },
                    ...(scope === 'guest' && { headerDescription: getHeaderDescription(loader) }),
                }

                const ruleTemplates = loader.lang.rule
                const publicationName = loader.publisher.name
                values.prompt.title = compile(
                    retrieveRulePromptTitle({ ruleTemplates }),
                    { publication_name: publicationName },
                )
                values.prompt.subTitle = retrieveRulePromptSubtitle({ ruleTemplates })
                values.prompt.description = retrieveRulePromptDescription({ ruleTemplates })
                values.menu.title = compile(
                    retrieveRuleMenuTitle({ ruleTemplates }),
                    { publication_name: publicationName },
                )
                values.menu.subTitle = retrieveRuleMenuSubtitle({ ruleTemplates })
                values.menu.button = retrieveRuleMenuAction({ ruleTemplates })
                return values
            }

            return null
        }),
        featureList: new Duck.Selector((selectors) => (state, scope = 'guest') => get(selectors.scopedLoader(state, scope), 'lang.plan.tiers', [])),
        pubPriceChangeDate: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            const changes = loader?.purchases?.history?.changes

            if (
                !changes
            ) {
                return null
            }

            const changeDate = changes.reduce((acc, { logType, loggedAt } = {}) => {
                if (acc) {
                    return acc
                }

                if (logType === 'PUB_PRICE_CHANGE') {
                    return loggedAt
                }

                return acc
            }, null)

            return changeDate
        },
        articleId: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            const articleId = loader?.article?.id
            return articleId ? articleId : null
        },
        promptTriggerRuleId: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            const ruleId = loader?.pricing?.identification?.identification_rule_id
            return ruleId ? ruleId : null
        },
        promptTriggerPlanId: (state) => {
            const loader = duck.selectors.topScopeLoader(state)
            const planId = loader?.pricing?.monetization?.plan_id
            return planId ? planId : null
        },
        getOrderType: (product, _option) => {
            let productType = null
            const option = _option
                || (product?.options?.length ? product.options[0] : {})

            const { type } = option


            if (type === 'subscription') {
                const isPledgeMode = product.pledge_mode
                const isTrial = (
                    (!!option.trial_price || option.trial_price === null)
                    && !!option.trial_interval
                    && !!option.trial_quantity
                )

                if (isPledgeMode) {
                    productType = 'pledge'
                } else if (isTrial) {
                    productType = 'trial'
                } else {
                    productType = type
                }
            } else {
                productType = type
            }

            return productType
        }
    })
}
