import Failure from './failure'
import Success from './success'
import CardSettings from './settings'
import Warning from './warning'
import Calculator from './calculator'
import Calendar from './calendar'
import Calendar2 from './calendar2'
import Checkmark from './checkmark'
import Clock from './clock'
import Guide from './guide'
import Help from './help'
import Issue from './issue'
import Info from './info'
import Return from './return'
import LoadingIcon from '../LoadingIcon'
import FacebookIcon from './facebook'
import GoogleIcon from '../GoogleIcon'
import OverflowIcon from './overflow'
import PicoIcon from './pico'
import PicoIcon2 from './pico2'
import Plus from './plus'
import Minus from './minus'
import TwitterIcon from './twitter'
import Tada from './tada'
import Timer from './timer'
// make sure you add fill="currentcolor" to svg that should match the text color
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import ArrowDown from './ArrowDown'
import ArrowUp from './ArrowUp'
import ArrowLeft2 from './ArrowLeft2'
import ArrowRight2 from './ArrowRight2'
import Clear from './clear'
import Drag from './drag'
import Filter from './filter'
import Search from './search'
import Verified from './verified'
import Unverified from './unverified'
import Social from '../Social'

const DefaultIcon = {
    ArrowDown,
    ArrowLeft,
    ArrowLeft2,
    ArrowRight,
    ArrowRight2,
    ArrowUp,
    Calculator,
    Calendar,
    Calendar2,
    CardSettings,
    Checkmark,
    Clear,
    Clock,
    Drag,
    Facebook: FacebookIcon,
    Failure,
    Filter,
    Google: GoogleIcon,
    Guide,
    Help,
    Issue,
    Info,
    Loading: LoadingIcon,
    Minus,
    Overflow: OverflowIcon,
    Pico: PicoIcon,
    Pico2: PicoIcon2,
    Plus,
    Return,
    Search,
    SocialApple_music: Social.Applemusic,
    SocialApple_podcasts: Social.Applepodcasts,
    SocialCash_app: Social.Cashapp,
    SocialDiscord: Social.Discord,
    SocialEmail: Social.Email,
    SocialEtsy: Social.Etsy,
    SocialGithub: Social.Github,
    SocialGoogle: Social.Google,
    SocialInstagram: Social.Instagram,
    SocialFacebook: Social.Facebook,
    SocialLinkedin: Social.Linkedin,
    SocialPinterest: Social.Pinterest,
    SocialSnapchat: Social.Snapchat,
    SocialSpotify: Social.Spotify,
    SocialThreads: Social.Threads,
    SocialTiktok: Social.Tiktok,
    SocialTwitch: Social.Twitch,
    SocialTwitter: Social.Twitter,
    SocialVenmo: Social.Venmo,
    SocialWebsite: Social.Website,
    SocialWhatsapp: Social.Whatsapp,
    SocialYoutube: Social.Youtube,
    Success,
    Tada,
    Timer,
    Twitter: TwitterIcon,
    Unverified,
    Verified,
    Warning,
}

export default DefaultIcon
