import PropertyType from "../Modifiers/PropertyType"

// this isn't being used anywhere for now because we don't have any other border types other than color
const ThemeBlockBorderType = new PropertyType({
    name: 'BlockBorderType',
    propertyFromApi: 'block-border-type',
    defaultValue: 'color',
},
'theme'
)

// this isn't being used anywhere for now because we don't have any other border types other than color
const FormBlockBorderType = new PropertyType({
    name: 'BlockBorderType',
    propertyFromApi: 'block-border-type',
    defaultValue: 'color',
},
'form'
)

export {
    ThemeBlockBorderType,
    FormBlockBorderType,
}
