import React from 'react'
import PropTypes from 'prop-types'
const Cancel = ({ width = '32px', height = '32px' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Oversized" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_activity_cancel">
                <polygon
                    id="bounds"
                    fill="#FFEFEE"
                    points="-5.55555592e-07 -5.55555557e-07 31.9999994 -5.55555557e-07 31.9999994 31.9999994 -5.55555592e-07 31.9999994"
                />
                <path
                    d="M16,6 C18.4008955,6 20.6041735,6.8461012 22.3279248,8.25639439 L22.363961,8.22182541 L23.7781746,9.63603897 L23.7436056,9.6720752 C25.1538988,11.3958265 26,13.5991045 26,16 C26,21.5228475 21.5228475,26 16,26 C13.5991045,26 11.3958265,25.1538988 9.6720752,23.7436056 L9.63603897,23.7781746 L8.22182541,22.363961 L8.25639439,22.3279248 C6.8461012,20.6041735 6,18.4008955 6,16 C6,10.4771525 10.4771525,6 16,6 Z M22.3207287,11.0954772 L11.0954772,22.3207287 C12.449979,23.3732643 14.1517985,24 16,24 C20.418278,24 24,20.418278 24,16 C24,14.1517985 23.3732643,12.449979 22.3207287,11.0954772 Z M16,8 C11.581722,8 8,11.581722 8,16 C8,17.8482015 8.62673571,19.550021 9.67927125,20.9045228 L20.9045228,9.67927125 C19.550021,8.62673571 17.8482015,8 16,8 Z"
                    id="Shape"
                    fill="#FF4440"
                />
            </g>
        </g>
    </svg>
)

Cancel.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
}

export default Cancel
