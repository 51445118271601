import { colors } from 'piconetworks/pkg-functions'
import { showConsoleLogs } from '../utils'

export interface IPropertySerializerOptions {
    /**
     * Property name and options
     * @type {string}
     * @readonly
     * @example 'BlockBorderRadius'
     */
    readonly name: string

    /**
     * List of options
     * @type {object}
     * @readonly
     * @example { "option1": "value1", "option2": "value2" }
     */
    readonly options?: object

    /**
     * Property being returned from API
     * @type {string}
     * @readonly
     * @example 'block-border-radius'
     */

    readonly propertyFromApi: string

    /**
     * Gets or sets the value. Formerly called "propertyValue"
     * This is what the user has selected in the UI and is the value coming from the DB
     * @type {string}
     * @example 'rounded'
     */
    value?: string

    /**
     * Sets the default value
     * @type {string}
     * @example 'very-rounded'
     */
    readonly defaultValue?: string

    /**
     * Returns the CSS properties. Formerly called "serializedValue"
     * @type {string}
     * @example 'border-radius: 3.5rem;'
     */
    cssProperties?: string

    /**
     * Function to serialize the value to what becomes the cssProperties. Formerly called "serializeFunction"
     * @returns {string}
     * @example (value: string) => `color: ${value};`
     * @readonly
     */
    readonly convertValueToCssPropertiesFunction?: <Type extends string>(arg: Type) => string

    /**
     * Selector to insert the serialized value into
     * If there's no selector, we will not generate CSS for this property
     * @returns {string}
     * @readonly
     * @example '.block'
     */
    readonly selector?: string

    /**
     * Function to return selector to insert the serialized value into
     * @returns {string}
     * @readonly
     */
    readonly selectorFunction?: <Type extends string>(arg: Type) => string

    /**
     * Is the style compounded from more than one property?
     * If true, we will wait to process it until all non compounded properties are set
     * For example: the background type is compounded from the background type and the background value
     * and the *background type* is considered to be isCompounded: true, because it's serialized value
     * will take the *background value* into account.
     * @returns {boolean}
     * @readonly
     */
    readonly isCompounded?: boolean

    /**
     * The CSS variable to use for this property
     *
     * @type {string}
     * @example '--block-border-radius'
     * @readonly
     */
    readonly cssVariable?: string

    /**
     * Run a function on the property value to determine what the css variable value should be
     * This is needed for block border radius for example, where the css variable value is not the same as the property value
     * @type {string}
     * @readonly
     */
    readonly cssVariableFunction?: <Type extends string>(arg: Type) => string

    /**
     * Sets the RGB value of a css variable if true
     * @type {boolean}
     * @example true
     */
    readonly convertToRgb?: boolean

    /**
     * Sets the RGB value of a css variable if convertToRgb is true and css variable is defined
     * @type {string}
     * @example '255, 255, 255'
     * 
     */
    stringifiedRgb?: string

    /**
     * If the property should run before others
     * This is only being used on compouned properties for now
     * This is needed for things where the order of the CSS matters, like page-contrast-color vs block-contrast-color
     * @type {number}
     * @example 1
     * @readonly
     */
    readonly priority?: number
}

/**
 * Takes the name, propertyFromApi and serializes values from the FormCustomizations and ThemeCustomizations table to CSS
 */
export class PropertySerializer implements IPropertySerializerOptions {

    name: IPropertySerializerOptions['name']
    options?: IPropertySerializerOptions['options']
    propertyFromApi: IPropertySerializerOptions['propertyFromApi']
    defaultValue?: IPropertySerializerOptions['defaultValue']
    protected _hasBeenModified: boolean
    value?: IPropertySerializerOptions['value']
    cssProperties?: IPropertySerializerOptions['cssProperties']
    convertValueToCssPropertiesFunction?: IPropertySerializerOptions['convertValueToCssPropertiesFunction']
    selector?: IPropertySerializerOptions['selector']
    selectorFunction?: IPropertySerializerOptions['selectorFunction']
    isCompounded?: IPropertySerializerOptions['isCompounded']
    cssVariable?: IPropertySerializerOptions['cssVariable']
    cssVariableFunction?: IPropertySerializerOptions['cssVariableFunction']
    protected _cssVariableValue: string
    convertToRgb?: IPropertySerializerOptions['convertToRgb']
    stringifiedRgb?: IPropertySerializerOptions['stringifiedRgb']
    priority?: IPropertySerializerOptions['priority']

    constructor(opts: {
        name: IPropertySerializerOptions['name'],
        options?: IPropertySerializerOptions['options'],
        propertyFromApi: IPropertySerializerOptions['propertyFromApi'],
        defaultValue?: IPropertySerializerOptions['defaultValue'],
        value?: IPropertySerializerOptions['value'],
        cssProperties?: IPropertySerializerOptions['cssProperties'],
        convertValueToCssPropertiesFunction?: IPropertySerializerOptions['convertValueToCssPropertiesFunction'],
        selector?: IPropertySerializerOptions['selector'],
        selectorFunction?: IPropertySerializerOptions['selectorFunction'],
        isCompounded?: IPropertySerializerOptions['isCompounded'],
        cssVariable?: IPropertySerializerOptions['cssVariable'],
        cssVariableFunction?: IPropertySerializerOptions['cssVariableFunction']
        convertToRgb?: IPropertySerializerOptions['convertToRgb']
        stringifiedRgb?: IPropertySerializerOptions['stringifiedRgb']
        priority?: IPropertySerializerOptions['priority']
    }) {
        this.name = opts.name
        this.propertyFromApi = opts.propertyFromApi
        this.priority = opts.priority || 100
        this.convertToRgb = opts.convertToRgb || false
        // this._hasBeenModified = false

        if (opts.convertValueToCssPropertiesFunction !== undefined) {
            this.convertValueToCssPropertiesFunction = opts.convertValueToCssPropertiesFunction
        } else {
            this.convertValueToCssPropertiesFunction = <Type>(value: Type) => value
        }

        this.options = opts.options || undefined
        if (opts.selectorFunction !== undefined) {
            this.selectorFunction = opts.selectorFunction
        } else if (opts.selector !== undefined && opts.selectorFunction === undefined) {
            this.selector = opts.selector
        }

        if (opts.cssVariable !== undefined) {
            this.cssVariable = opts.cssVariable
            if (opts.cssVariableFunction !== undefined) {
                this.cssVariableFunction = opts.cssVariableFunction
            }
        }

        if (opts.value !== undefined) {
            this.value = opts.value
            this.set(opts.value, true)
        } else if (opts.defaultValue !== undefined) {
            this.defaultValue = opts.defaultValue
            this.set(opts.defaultValue, false)
        }

        this.isCompounded = opts.isCompounded || false
    }

    reset() {
        if (this.defaultValue) {
            this.set(this.defaultValue, false)
        } else {
            this.value = ''
            this.cssProperties = ''
            this._hasBeenModified = false
        }
        return this
    }

    getValue() {
        return this.value
    }

    getCssVariableValue() {
        return this._cssVariableValue
    }

    getCssProperties() {
        return this.cssProperties
    }

    getHasBeenModified() {
        return this._hasBeenModified
    }

    getAll() {
        return this
    }

    get() {
        return this.cssProperties
    }

    set(value: string, modified = true) {
        this.value = value
        if (this.convertValueToCssPropertiesFunction !== undefined) {
            if (showConsoleLogs()) {
                console.log(`convertValueToCssPropertiesFunction() ${this.propertyFromApi}`)
            }
            const cssProperties = this.convertValueToCssPropertiesFunction(value)
            if (cssProperties !== undefined) {
                this.cssProperties = cssProperties
            }
        }

        if (this.selectorFunction !== undefined) {
            this.selector = this.selectorFunction(value)
        }
        
        if (this.cssVariableFunction !== undefined) {
            this._cssVariableValue = this.cssVariableFunction(value)
        } else if (this.cssVariable !== undefined && this.cssVariableFunction === undefined) {
            this._cssVariableValue = value
        }

        if (this.convertToRgb && value !== 'transparent' && this._cssVariableValue !== undefined) {
            this.stringifiedRgb = this.serializeRgb()
        }

        this._hasBeenModified = modified

        return this
    }

    serializeRgb() {
        const colorValue = this._cssVariableValue
        try {
            let rgbValue = { r: null, g: null, b: null }
            if (colors.isHex(colorValue)) {
                rgbValue = colors.hexToRgb(colorValue)
            } else if (colors.isRGBA(colorValue)) {
                rgbValue = colors.rgbaToRgb(colorValue)
            } else if (colors.isRGB(colorValue)) {
                rgbValue = colors.rgbToObject(colorValue)  
            }

            if (rgbValue.r === null || rgbValue.g === null || rgbValue.b === null) {
                throw new Error(`We couldn't convert the color ${colorValue} to rgb`)
            }
            return `${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}`
        } catch (error) {
            console.error(`error converting color ${colorValue} to rgb:`, error)
        }
    }
}