import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MenuWrapper from './components/MenuWrapper'
import { colors } from 'piconetworks/pkg-functions'
import { RemoteMarkdown } from 'piconetworks/pkg-remote-markdown'
import Picon from 'piconetworks/pkg-picon'

import style from './scss/MenuRoutes.module.scss'

const NotificationMenuPage = (props) => {
    const {
        changeMenuRoute,
        linkColor,
        publisherName,
        text = '',
        sendExternalLink,
        utmRootUrl,
        queryOverride,
        link,
        goToMenuRouteAndOpen,
        backRoute,
    } = props


    let Component = false
    let customText = false

    try {
        customText = JSON.parse(text)
    } catch (error) {

    }

    if (customText?.custom) {
        const linkComponent = (
            <span
                style={{ color: linkColor, cursor: 'pointer' }}
                onClick={() =>
                    goToMenuRouteAndOpen(`${customText.link}`)
                }
            >
                {' '}here{' '}
            </span>
        )

        Component = customText?.component
            ?.split('{here}')
            ?.flatMap((item) => [item, linkComponent])

        Component?.pop()
    }

    return (
        <MenuWrapper
            publisherName={publisherName}
            sendExternalLink={sendExternalLink}
            queryOverride={queryOverride}
            utmRootUrl={utmRootUrl}
            hideBackground
        >
            <div
                className={classNames(
                    style.menuHeader,
                    style.shadow,
                    style.notificationHeader
                )}
            >
                <div
                    className={style.backIcon}
                    onClick={() => {
                        changeMenuRoute(backRoute || '/')
                    }}
                    style={{ color: linkColor }}
                >
                    <Picon color={linkColor} icon='arrow-left' size='24' />
                </div>
                <h3>Notifications</h3>
            </div>
            <div

                className={classNames(style.background)}

                style={{ backgroundColor: colors.getBgColor(linkColor, 0.15) }}

            >
                <div className={style.notification}>
                    {Component ? (
                        Component.map((item) => item)
                    ) : link ? (
                        (
                            <RemoteMarkdown url={link} linkColor={linkColor} className={style.markdown} />
                        )
                    ) : (
                        (
                            text
                        )
                    )}
                </div>
            </div>
        </MenuWrapper>
    )
}

NotificationMenuPage.propTypes = {
    sendExternalLink: null,
    utmRootUrl: null,
}

NotificationMenuPage.propTypes = {
    backRoute: PropTypes.string,
    changeMenuRoute: PropTypes.func.isRequired,
    goToMenuRouteAndOpen: PropTypes.func,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string,
    queryOverride: PropTypes.object,
}

export default NotificationMenuPage
