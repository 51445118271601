import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Span } from 'piconetworks/pkg-text'

import { utmLink } from 'piconetworks/pkg-functions'

import style from '../scss/MenuRoutes.module.scss'

const MenuWrapper = (props) => {
    const { children, hideBackground, router: { query }, sendExternalLink, utmRootUrl, queryOverride } = props

    const { trypico_link: trypicoLink } = utmLink({
        ...(queryOverride || query),
        utmRootUrl
    })

    return (
        <div className={cx(style.menuContainer, { [style.hideBackground]: hideBackground })}>
            <div className={style.menuWrapper}>
                {children}
            </div>
            <div className={cx(style.contentFooter, style.menuFooterContent)}>
                <Span>Your profile is powered by {' '}
                    <a
                        onClick={(e) => {
                            e.preventDefault()
                            if (sendExternalLink) {
                                sendExternalLink(trypicoLink)
                            } else {
                                window.open(trypicoLink)
                            }
                        }}
                        href={trypicoLink}
                    >{' '}Hype</a>.
                </Span>
            </div>
        </div>
    )
}

MenuWrapper.defaultProps = {
    hideBackground: false,
    router: {},
    sendExternalLink: null,
    utmRootUrl: null
}

MenuWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    hideBackground: PropTypes.bool,
    queryOverride: PropTypes.object,
    router: PropTypes.shape({
        query: PropTypes.shape({
            company_slug: PropTypes.string,
        }),
    }).isRequired,
    sendExternalLink: PropTypes.func,
    utmRootUrl: PropTypes.string
}

export default MenuWrapper
