import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Grid from '../Grid'
import { GridContainerPropTypes } from '../Grid/GridContainer.js'
import { GridRowPropTypes } from '../Grid/GridRow.js'
import styles from './GridPane.module.scss'

const GridPaneContainer = ({
    children,
    gridContainer,
    gridRow,
    ...props
}) => {
    return (
        <Grid.Container className={cx(styles.gridPaneContainer)} {...gridContainer} {...props}>
            <Grid.Row {...gridRow}>
                {children}
            </Grid.Row>
        </Grid.Container>
    )
}

GridPaneContainer.propTypes = {
    children: PropTypes.node,
    gridContainer: PropTypes.shape({...GridContainerPropTypes}),
    gridRow: PropTypes.shape({...GridRowPropTypes})
}

export default GridPaneContainer
