import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import MenuWrapper from './components/MenuWrapper'
import Picon from 'piconetworks/pkg-picon'
import PhoneField from './components/phone'
import { Form as ReactFinalForm } from 'react-final-form'
import Button from 'piconetworks/pkg-boutton'

import style from './scss/MenuRoutes.module.scss'

const EditPhoneNumber = ({
    publisherId,
    userPhone,
    userPhoneIsShared,
    contrastColor,
    changeMenuRoute,
    userLoader,
    guestLoader,
    utmRootUrl,
    goToMenuRouteAndOpen,
    userHasMultipleState,
    queryOverride,
}) => {
    const scopedLoader = userLoader || guestLoader || {}
    const phoneInputRef = useRef(null)
    let text = ''
    let description = false
    let showTerms = false
    let confirmShow = false
    let shouldCheckPhone = false

    const {
        publisher: {
            name: publisherName,
            styling: { linkColor = '' } = {},
        } = {},
    } = scopedLoader

    if (!userPhone) {
        text = 'Add Phone Number'
        showTerms = true
        description = false
        shouldCheckPhone = false
    }

    if (userPhone && userPhoneIsShared && userHasMultipleState) {
        text = 'Edit Phone Number'
        description = true
        shouldCheckPhone = false
    }

    if (userPhone && userPhoneIsShared && !userHasMultipleState) {
        text = 'Edit Phone Number'
        description = false
        shouldCheckPhone = false
    }

    if (userPhone && !userPhoneIsShared && userHasMultipleState) {
        text = 'Confirm Phone Number'
        confirmShow = true
        showTerms = true
        shouldCheckPhone = true
    }



    return (
        <MenuWrapper
            publisherName={ publisherName }
            utmRootUrl={ utmRootUrl }
            queryOverride={ queryOverride }
        >
            <div className={ style.editPhoneContainer }>
                <div className={ style.menuHeader }>
                    <div
                        className={ style.backIcon }
                        onClick={ () => {
                            changeMenuRoute('/editProfile')
                        } }
                    >
                        <Picon color={ linkColor } icon='arrow-left' size='24' />
                    </div>
                    <h3>{ text }</h3>
                </div>
                <div className={ style.container }>
                    <ReactFinalForm
                        initialValues={ {
                            publisherId,
                        } }
                        onSubmit={ () => { } }
                        keepDirtyOnResubmit={ true }
                        keepDirtyOnReinitialize
                        render={ () => {
                            let isDisabled = false

                            if (shouldCheckPhone) {
                                isDisabled = userPhone !== window?.loginIti?.getNumber()
                            }

                            return (
                                <form>
                                    { confirmShow && (
                                        <p className={style.showTermsText}>
                                            A phone number ending in { userPhone ? userPhone.substr(userPhone.length - 4) : ''} is
                                            already associated with your account.
                                            Enter the complete phone number below to
                                            receive text messages from{ ' ' }
                                            { publisherName }.
                                        </p>
                                    ) }
                                    <PhoneField
                                        checkPhone={ ({ phone }) => {
                                            if (!isDisabled) {
                                                goToMenuRouteAndOpen(
                                                    '/editProfile/otp',
                                                    {
                                                        phone,
                                                    }
                                                )
                                            }
                                        } }
                                        contrastColor={ contrastColor }
                                        description
                                        label='Phone number'
                                        isReturningUser
                                        linkColor={ linkColor }
                                        isLogin
                                        displayTerms={ false }
                                        onSubmitCallback={ () => { } }
                                        phoneInputRef={ phoneInputRef }
                                    />
                                    <div>
                                        { showTerms && (
                                            <p className={ style.tinyText }>
                                                By clicking &ldquo;Add,&rdquo; you agree that { publisherName } may contact the phone number provided 
                                                with marketing materials by text message or phone (including via an automated system). 
                                                Consent is not a condition of purchase. Message & data rates may apply.
                                            </p>
                                        ) }
                                    </div>
                                    <div className={ style.buttonContainer }>
                                        <Button
                                            variant='link'
                                            onClick={ () =>
                                                changeMenuRoute('/editProfile')
                                            }
                                            className={ style.button }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant='primary'
                                            onClick={ () => {
                                                const phone = window?.loginIti?.getNumber()
                                                if (!isDisabled) {
                                                    goToMenuRouteAndOpen(
                                                        '/editProfile/otp',
                                                        {
                                                            phone,
                                                        }
                                                    )
                                                }
                                            } }
                                            disabled={isDisabled}
                                        >
                                            { !userPhone ? 'Add' : 'Update' }
                                        </Button>
                                    </div>
                                    <div className={ style.description }>
                                        { description && (
                                            <p className={ style.disclaimer }>
                                                <b>NOTE:</b> Updating this phone
                                                number will update all of the
                                                profiles associated with your Hype
                                                ID.
                                            </p>
                                        ) }
                                    </div>
                                </form>
                            )}
                        }
                    />
                </div>
            </div>
        </MenuWrapper>
    )
}

EditPhoneNumber.defaultProps = {
    publisherId: null,
}

EditPhoneNumber.propTypes = {
    changeMenuRoute: PropTypes.func.isRequired,
    contrastColor: PropTypes.string,
    goToMenuRouteAndOpen: PropTypes.func,
    guestLoader: PropTypes.any.isRequired,
    publisherId: PropTypes.string,
    queryOverride: PropTypes.object,
    userHasMultipleState: PropTypes.bool,
    userLoader: PropTypes.any.isRequired,
    userPhone: PropTypes.string,
    userPhoneIsShared: PropTypes.bool,
    utmRootUrl: PropTypes.string,
}

export default EditPhoneNumber
